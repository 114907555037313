.mainContainer {
  width: 700px;
  height: 767px;
  background: #ffffff;
}

.closeIcon {
  height: 35px;
  width: 35px;

  background: rgba(245, 102, 102, 0.12);

  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #f56666;
}

.closeIcon:hover {
  color: #ffffff;
  background: #f56666;
}

.headerIcon {
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  width: 70px;
  height: 70px;

  background: #8ac04a;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  color: #222222;
}

.subHeaderText {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  text-align: center;

  color: #7d7d7d;
}

.describeInput {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 150px;
  resize: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.describeInput::placeholder,
.preferredDay::placeholder,
.preferredTime::placeholder {
  color: #b7b8b7;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #272828;
}

.preferredDay {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.preferredTime {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.buttonRequest {
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;

  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
}

.buttonRequest:hover {
  background: rgba(138, 192, 74, 0.85);
}
