.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown:hover
  > .subMenu1 {
  display: block;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .subMenu1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 230px;
  display: none;
  z-index: 9;
  padding-top: 25px;
  margin-top: 35px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu1
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu1
  .subMenuItem {
  background-color: #8ac04a;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu1
  .subMenuItem
  a {
  color: #ffffff !important;
  line-height: 32px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu1
  .subMenuItem.active,
.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu1
  .subMenuItem:hover {
  background: #92c457;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu .subMenu {
  display: none;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu1
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid #c35591;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu1
  .subMenuItem
  .subMenuItemText {
  color: #ffffff;
  background: #8ac04a;
  width: 100%;
  display: block;
  padding: 25px 0;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu1
  .subMenuItem
  .subMenuItemText:hover {
  background: #92c457;
}
