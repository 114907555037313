.MinimumWidthWarning_mainContainer__sFuAE {
  display: flex;
  align-items: center;
  height: 100vh;
}

.MinimumWidthWarning_cardContainer__23Mj0 {
  max-width: 500px;
  margin: auto;
  padding: 40px;
  background-color: white;
}

.MinimumWidthWarning_infoIcon__1xZPI {
  width: 130px;
  margin: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 2.3rem 2rem;
  margin-bottom: 1.5rem;
  background-color: #feeded;
}

.MinimumWidthWarning_infoText__3ZpXY {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-weight: normal;
  display: block;
}

@media only screen and (max-width: 450px) {
  .MinimumWidthWarning_cardContainer__23Mj0 {
    margin: auto 2rem auto 2rem;
  }
}
.BookAMentor_mainContainer__1Ma1k {
  width: 700px;
  height: 767px;
  background: #ffffff;
}

.BookAMentor_closeIcon__2EsaP {
  height: 35px;
  width: 35px;
  background: rgba(245, 102, 102, 0.12);
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #f56666;
}

.BookAMentor_closeIcon__2EsaP:hover {
  color: #ffffff;
  background: #f56666;
}

.BookAMentor_headerIcon__2SIcH {
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: 70px;
  height: 70px;
  background: #8ac04a;
}

.BookAMentor_headerText__1FB2K {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */
  color: #222222;
}

.BookAMentor_subHeaderText__yZYY4 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */
  text-align: center;
  color: #7d7d7d;
}

.BookAMentor_describeInput__2fk9j {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 150px;
  resize: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.BookAMentor_describeInput__2fk9j::-webkit-input-placeholder, .BookAMentor_preferredDay__QEkJs::-webkit-input-placeholder, .BookAMentor_preferredTime__1m0U8::-webkit-input-placeholder {
  color: #b7b8b7;
}

.BookAMentor_describeInput__2fk9j:-ms-input-placeholder, .BookAMentor_preferredDay__QEkJs:-ms-input-placeholder, .BookAMentor_preferredTime__1m0U8:-ms-input-placeholder {
  color: #b7b8b7;
}

.BookAMentor_describeInput__2fk9j::placeholder,
.BookAMentor_preferredDay__QEkJs::placeholder,
.BookAMentor_preferredTime__1m0U8::placeholder {
  color: #b7b8b7;
}

.BookAMentor_label__3lPod {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #272828;
}

.BookAMentor_preferredDay__QEkJs {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.BookAMentor_preferredTime__1m0U8 {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  height: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.BookAMentor_buttonRequest__Y1lM_ {
  height: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
}

.BookAMentor_buttonRequest__Y1lM_:hover {
  background: rgba(138, 192, 74, 0.85);
}

.Search_inputContainer__EbkiH {
  width: 50%;
  border: 1px solid #dedddd;
  box-sizing: border-box;
  border-radius: 3px;
}

.Search_inputSearch__27CCb {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */
  display: flex;
  align-items: center;
  border: none !important;
  color: #222222;
}

.Search_inputSearch__27CCb::-webkit-input-placeholder {
  color: #b0b0b0;
}

.Search_inputSearch__27CCb:-ms-input-placeholder {
  color: #b0b0b0;
}

.Search_inputSearch__27CCb::placeholder {
  color: #b0b0b0;
}

.Search_searchContainer__3-fPE {
  width: 48px;
  height: 48px;
  background: #8ac04a;
  border-radius: 2px;
}

.Search_searchContainer__3-fPE:hover {
  opacity: .8;
}

.Search_search__3B9KY {
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
}

.Search_cancelContainer__1jZdF {
  width: 48px;
  height: 48px;
  background: rgb(34, 34, 34, 0.05);
  border-radius: 9999px;
}

.Search_cancelContainer__1jZdF:hover {
  color: #ffffff;
  background: #8ac04a;
}

.Search_cancel__3qqky {
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height */
  text-align: center;
  color: #222222;
}

.ChooseService_closeIcon__rEa52 {
  height: 35px;
  width: 35px;
  background: rgba(245, 102, 102, 0.12);
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #f56666;
}

.ChooseService_closeIcon__rEa52:hover {
  color: #ffffff;
  background: #f56666;
}

.ChooseService_headerText__3muBo {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.ChooseService_serviceItem__2SLUX {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #b0b0b0;
  height: 50px;
  border: 1px solid #ebebeb;
  background: #f9f9f9;
  box-sizing: border-box;
  border-radius: 3px;
}

.ChooseService_serviceItem__2SLUX:hover {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.ChooseService_serviceItem__2SLUX.ChooseService_selected__3Rl3o {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.ChooseService_overdue__3r5Kj {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  color: #f56666;
  height: 50px;
  box-sizing: border-box;
  border-radius: 3px;
  background: rgba(245, 102, 102, 0.1);
  border: 1px solid rgba(245, 102, 102, 0.2);
}

.ChooseService_buttonOverdue__2yONX {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
  box-sizing: border-box;
  border-radius: 1px;
}

.ChooseService_addServiceButton__dq30m {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: #388cef;
  border-radius: 3px;
}

.ConfirmRedirectModal_modalContainer__38v-I {
  display: flex;
  padding: 4rem 5rem;
  flex-direction: column;
}

.ConfirmRedirectModal_headerText__4eeH5 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  /* or 135% */
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
}

.ConfirmRedirectModal_buttonGroup__2yIsb {
  display: flex;
  align-self: center;
}

.ConfirmRedirectModal_nextTipButton__1eiiU {
  border: none;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 1rem 3rem;
  color: #8bc334;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: #f1f8e7;
  outline: none;
}

.ConfirmRedirectModal_nextTipButton__1eiiU:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.ConfirmRedirectModal_closeButton__3toOr {
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.ConfirmRedirectModal_closeButton__3toOr:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.GreenButton_buttonContainer__2H9bC {
  color: #f9f9f9;
  background: #8ac04a;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border: none;
  border-radius: 3px;
  padding: 1.3rem 3rem;
  cursor: pointer;
  outline: none;
}

/* .buttonContainer:hover {
  color: #ffffff;
  background-color: #388cef;
} */

/* .buttonContainer svg {
  fill: #388cef;
}

.buttonContainer svg:hover {
  fill: white;
} */

.SkipButton_buttonContainer__1RW8M {
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  color: #f56666;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  background-color: #feeded;
  outline: none;
}

.SkipButton_buttonContainer__1RW8M:hover {
  color: #ffffff;
  background-color: #f56666;
}

.SkipButton_disabled__2M0Nw {
  border: none;
  border-radius: 5px;
  padding: 1.3rem 3rem;
  color: #4b4848;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  background-color: #cacaca;
  outline: none;
  cursor: default !important;
}

.LogoutModal_modalContainer__3B2Wa {
  display: flex;
  padding: 2rem 4rem;
  align-items: center;
  flex-direction: column;
}

.LogoutModal_closeButton__1q5eh {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  color: #f56666 !important;
  border-radius: none !important;
  background-color: #feeded !important;
}

.LogoutModal_closeButton__1q5eh:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}

.LogoutModal_logoutText__30zib {
  margin: 1rem 0 2rem;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: normal;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown:hover
  > .subMenu1 {
  display: block;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .subMenu1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 230px;
  display: none;
  z-index: 9;
  padding-top: 25px;
  margin-top: 35px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu1
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu1
  .subMenuItem {
  background-color: #8ac04a;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu1
  .subMenuItem
  a {
  color: #ffffff !important;
  line-height: 32px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu1
  .subMenuItem.active,
.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu1
  .subMenuItem:hover {
  background: #92c457;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu .subMenu {
  display: none;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu1
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid #c35591;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu1
  .subMenuItem
  .subMenuItemText {
  color: #ffffff;
  background: #8ac04a;
  width: 100%;
  display: block;
  padding: 25px 0;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu1
  .subMenuItem
  .subMenuItemText:hover {
  background: #92c457;
}

.ViewerInfoBar_mainContainer__1QyK8 {
  height: 50px;
  width: 100%;
  background: #8f5fe8;
}

.ViewerInfoBar_container__2s7Bo {
  width: 1500px;
}

.ViewerInfoBar_infoIcon__3-DPz {
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
}

.ViewerInfoBar_infoText__3vh5m {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #ffffff;
}

.ViewerInfoBar_logoutButton__XVz-b {
  border: none;
  cursor: pointer;
  color: #ffffff !important;
  width: 102px !important;
  height: 36px !important;
  font-size: 17px !important;
  border-radius: 5px;
  text-decoration: none;
  background: #a57fed;
  outline: none;
}

.ViewerInfoBar_closeButton__1dOgu {
  border: none;
  cursor: pointer;
  color: #ffffff !important;
  width: 86px !important;
  height: 36px !important;
  font-size: 17px !important;
  border-radius: 5px;
  text-decoration: none;
  background: #ee6565;
  outline: none;
}

.ViewerInfoBar_buttonText__1GRvs {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.ViewerInfoBar_buttonIcon__33CWk {
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
}

.LogoutRedirect_mainContainer__7dYri {
  background: #ffffff;
}

.LogoutRedirect_header__2YOeg {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  line-height: 50px;
  /* identical to box height, or 128% */
  text-align: center;
  letter-spacing: -1px;
  color: #272827 !important;
}

.LogoutRedirect_subHeader__3ukqz {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */
  text-align: center;
  color: #272828;
}

.LogoutRedirect_buttonRedirect__KKzAe {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #8bc334;
  border-radius: 5px;
}

.LogoutRedirect_buttonRedirect__KKzAe:hover {
  color: #ffffff !important;
}

*, ::before, ::after{
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.container{
  width: 100%;
}

@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){
  .container{
    max-width: 1536px;
  }
}

.sr-only{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.not-sr-only{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.focus-within\:sr-only:focus-within{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.focus-within\:not-sr-only:focus-within{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.focus\:sr-only:focus{
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.focus\:not-sr-only:focus{
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.pointer-events-none{
  pointer-events: none !important;
}

.pointer-events-auto{
  pointer-events: auto !important;
}

.visible{
  visibility: visible !important;
}

.invisible{
  visibility: hidden !important;
}

.static{
  position: static !important;
}

.fixed{
  position: fixed !important;
}

.absolute{
  position: absolute !important;
}

.relative{
  position: relative !important;
}

.sticky{
  position: -webkit-sticky !important;
  position: sticky !important;
}

.inset-0{
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.inset-1{
  top: 0.25rem !important;
  right: 0.25rem !important;
  bottom: 0.25rem !important;
  left: 0.25rem !important;
}

.inset-2{
  top: 0.5rem !important;
  right: 0.5rem !important;
  bottom: 0.5rem !important;
  left: 0.5rem !important;
}

.inset-3{
  top: 0.75rem !important;
  right: 0.75rem !important;
  bottom: 0.75rem !important;
  left: 0.75rem !important;
}

.inset-4{
  top: 1rem !important;
  right: 1rem !important;
  bottom: 1rem !important;
  left: 1rem !important;
}

.inset-5{
  top: 1.25rem !important;
  right: 1.25rem !important;
  bottom: 1.25rem !important;
  left: 1.25rem !important;
}

.inset-6{
  top: 1.5rem !important;
  right: 1.5rem !important;
  bottom: 1.5rem !important;
  left: 1.5rem !important;
}

.inset-7{
  top: 1.75rem !important;
  right: 1.75rem !important;
  bottom: 1.75rem !important;
  left: 1.75rem !important;
}

.inset-8{
  top: 2rem !important;
  right: 2rem !important;
  bottom: 2rem !important;
  left: 2rem !important;
}

.inset-9{
  top: 2.25rem !important;
  right: 2.25rem !important;
  bottom: 2.25rem !important;
  left: 2.25rem !important;
}

.inset-10{
  top: 2.5rem !important;
  right: 2.5rem !important;
  bottom: 2.5rem !important;
  left: 2.5rem !important;
}

.inset-11{
  top: 2.75rem !important;
  right: 2.75rem !important;
  bottom: 2.75rem !important;
  left: 2.75rem !important;
}

.inset-12{
  top: 3rem !important;
  right: 3rem !important;
  bottom: 3rem !important;
  left: 3rem !important;
}

.inset-14{
  top: 3.5rem !important;
  right: 3.5rem !important;
  bottom: 3.5rem !important;
  left: 3.5rem !important;
}

.inset-16{
  top: 4rem !important;
  right: 4rem !important;
  bottom: 4rem !important;
  left: 4rem !important;
}

.inset-20{
  top: 5rem !important;
  right: 5rem !important;
  bottom: 5rem !important;
  left: 5rem !important;
}

.inset-24{
  top: 6rem !important;
  right: 6rem !important;
  bottom: 6rem !important;
  left: 6rem !important;
}

.inset-28{
  top: 7rem !important;
  right: 7rem !important;
  bottom: 7rem !important;
  left: 7rem !important;
}

.inset-32{
  top: 8rem !important;
  right: 8rem !important;
  bottom: 8rem !important;
  left: 8rem !important;
}

.inset-36{
  top: 9rem !important;
  right: 9rem !important;
  bottom: 9rem !important;
  left: 9rem !important;
}

.inset-40{
  top: 10rem !important;
  right: 10rem !important;
  bottom: 10rem !important;
  left: 10rem !important;
}

.inset-44{
  top: 11rem !important;
  right: 11rem !important;
  bottom: 11rem !important;
  left: 11rem !important;
}

.inset-48{
  top: 12rem !important;
  right: 12rem !important;
  bottom: 12rem !important;
  left: 12rem !important;
}

.inset-52{
  top: 13rem !important;
  right: 13rem !important;
  bottom: 13rem !important;
  left: 13rem !important;
}

.inset-56{
  top: 14rem !important;
  right: 14rem !important;
  bottom: 14rem !important;
  left: 14rem !important;
}

.inset-60{
  top: 15rem !important;
  right: 15rem !important;
  bottom: 15rem !important;
  left: 15rem !important;
}

.inset-64{
  top: 16rem !important;
  right: 16rem !important;
  bottom: 16rem !important;
  left: 16rem !important;
}

.inset-72{
  top: 18rem !important;
  right: 18rem !important;
  bottom: 18rem !important;
  left: 18rem !important;
}

.inset-80{
  top: 20rem !important;
  right: 20rem !important;
  bottom: 20rem !important;
  left: 20rem !important;
}

.inset-96{
  top: 24rem !important;
  right: 24rem !important;
  bottom: 24rem !important;
  left: 24rem !important;
}

.inset-auto{
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.inset-px{
  top: 1px !important;
  right: 1px !important;
  bottom: 1px !important;
  left: 1px !important;
}

.inset-0\.5{
  top: 0.125rem !important;
  right: 0.125rem !important;
  bottom: 0.125rem !important;
  left: 0.125rem !important;
}

.inset-1\.5{
  top: 0.375rem !important;
  right: 0.375rem !important;
  bottom: 0.375rem !important;
  left: 0.375rem !important;
}

.inset-2\.5{
  top: 0.625rem !important;
  right: 0.625rem !important;
  bottom: 0.625rem !important;
  left: 0.625rem !important;
}

.inset-3\.5{
  top: 0.875rem !important;
  right: 0.875rem !important;
  bottom: 0.875rem !important;
  left: 0.875rem !important;
}

.-inset-0{
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.-inset-1{
  top: -0.25rem !important;
  right: -0.25rem !important;
  bottom: -0.25rem !important;
  left: -0.25rem !important;
}

.-inset-2{
  top: -0.5rem !important;
  right: -0.5rem !important;
  bottom: -0.5rem !important;
  left: -0.5rem !important;
}

.-inset-3{
  top: -0.75rem !important;
  right: -0.75rem !important;
  bottom: -0.75rem !important;
  left: -0.75rem !important;
}

.-inset-4{
  top: -1rem !important;
  right: -1rem !important;
  bottom: -1rem !important;
  left: -1rem !important;
}

.-inset-5{
  top: -1.25rem !important;
  right: -1.25rem !important;
  bottom: -1.25rem !important;
  left: -1.25rem !important;
}

.-inset-6{
  top: -1.5rem !important;
  right: -1.5rem !important;
  bottom: -1.5rem !important;
  left: -1.5rem !important;
}

.-inset-7{
  top: -1.75rem !important;
  right: -1.75rem !important;
  bottom: -1.75rem !important;
  left: -1.75rem !important;
}

.-inset-8{
  top: -2rem !important;
  right: -2rem !important;
  bottom: -2rem !important;
  left: -2rem !important;
}

.-inset-9{
  top: -2.25rem !important;
  right: -2.25rem !important;
  bottom: -2.25rem !important;
  left: -2.25rem !important;
}

.-inset-10{
  top: -2.5rem !important;
  right: -2.5rem !important;
  bottom: -2.5rem !important;
  left: -2.5rem !important;
}

.-inset-11{
  top: -2.75rem !important;
  right: -2.75rem !important;
  bottom: -2.75rem !important;
  left: -2.75rem !important;
}

.-inset-12{
  top: -3rem !important;
  right: -3rem !important;
  bottom: -3rem !important;
  left: -3rem !important;
}

.-inset-14{
  top: -3.5rem !important;
  right: -3.5rem !important;
  bottom: -3.5rem !important;
  left: -3.5rem !important;
}

.-inset-16{
  top: -4rem !important;
  right: -4rem !important;
  bottom: -4rem !important;
  left: -4rem !important;
}

.-inset-20{
  top: -5rem !important;
  right: -5rem !important;
  bottom: -5rem !important;
  left: -5rem !important;
}

.-inset-24{
  top: -6rem !important;
  right: -6rem !important;
  bottom: -6rem !important;
  left: -6rem !important;
}

.-inset-28{
  top: -7rem !important;
  right: -7rem !important;
  bottom: -7rem !important;
  left: -7rem !important;
}

.-inset-32{
  top: -8rem !important;
  right: -8rem !important;
  bottom: -8rem !important;
  left: -8rem !important;
}

.-inset-36{
  top: -9rem !important;
  right: -9rem !important;
  bottom: -9rem !important;
  left: -9rem !important;
}

.-inset-40{
  top: -10rem !important;
  right: -10rem !important;
  bottom: -10rem !important;
  left: -10rem !important;
}

.-inset-44{
  top: -11rem !important;
  right: -11rem !important;
  bottom: -11rem !important;
  left: -11rem !important;
}

.-inset-48{
  top: -12rem !important;
  right: -12rem !important;
  bottom: -12rem !important;
  left: -12rem !important;
}

.-inset-52{
  top: -13rem !important;
  right: -13rem !important;
  bottom: -13rem !important;
  left: -13rem !important;
}

.-inset-56{
  top: -14rem !important;
  right: -14rem !important;
  bottom: -14rem !important;
  left: -14rem !important;
}

.-inset-60{
  top: -15rem !important;
  right: -15rem !important;
  bottom: -15rem !important;
  left: -15rem !important;
}

.-inset-64{
  top: -16rem !important;
  right: -16rem !important;
  bottom: -16rem !important;
  left: -16rem !important;
}

.-inset-72{
  top: -18rem !important;
  right: -18rem !important;
  bottom: -18rem !important;
  left: -18rem !important;
}

.-inset-80{
  top: -20rem !important;
  right: -20rem !important;
  bottom: -20rem !important;
  left: -20rem !important;
}

.-inset-96{
  top: -24rem !important;
  right: -24rem !important;
  bottom: -24rem !important;
  left: -24rem !important;
}

.-inset-px{
  top: -1px !important;
  right: -1px !important;
  bottom: -1px !important;
  left: -1px !important;
}

.-inset-0\.5{
  top: -0.125rem !important;
  right: -0.125rem !important;
  bottom: -0.125rem !important;
  left: -0.125rem !important;
}

.-inset-1\.5{
  top: -0.375rem !important;
  right: -0.375rem !important;
  bottom: -0.375rem !important;
  left: -0.375rem !important;
}

.-inset-2\.5{
  top: -0.625rem !important;
  right: -0.625rem !important;
  bottom: -0.625rem !important;
  left: -0.625rem !important;
}

.-inset-3\.5{
  top: -0.875rem !important;
  right: -0.875rem !important;
  bottom: -0.875rem !important;
  left: -0.875rem !important;
}

.inset-1\/2{
  top: 50% !important;
  right: 50% !important;
  bottom: 50% !important;
  left: 50% !important;
}

.inset-1\/3{
  top: 33.333333% !important;
  right: 33.333333% !important;
  bottom: 33.333333% !important;
  left: 33.333333% !important;
}

.inset-2\/3{
  top: 66.666667% !important;
  right: 66.666667% !important;
  bottom: 66.666667% !important;
  left: 66.666667% !important;
}

.inset-1\/4{
  top: 25% !important;
  right: 25% !important;
  bottom: 25% !important;
  left: 25% !important;
}

.inset-2\/4{
  top: 50% !important;
  right: 50% !important;
  bottom: 50% !important;
  left: 50% !important;
}

.inset-3\/4{
  top: 75% !important;
  right: 75% !important;
  bottom: 75% !important;
  left: 75% !important;
}

.inset-full{
  top: 100% !important;
  right: 100% !important;
  bottom: 100% !important;
  left: 100% !important;
}

.-inset-1\/2{
  top: -50% !important;
  right: -50% !important;
  bottom: -50% !important;
  left: -50% !important;
}

.-inset-1\/3{
  top: -33.333333% !important;
  right: -33.333333% !important;
  bottom: -33.333333% !important;
  left: -33.333333% !important;
}

.-inset-2\/3{
  top: -66.666667% !important;
  right: -66.666667% !important;
  bottom: -66.666667% !important;
  left: -66.666667% !important;
}

.-inset-1\/4{
  top: -25% !important;
  right: -25% !important;
  bottom: -25% !important;
  left: -25% !important;
}

.-inset-2\/4{
  top: -50% !important;
  right: -50% !important;
  bottom: -50% !important;
  left: -50% !important;
}

.-inset-3\/4{
  top: -75% !important;
  right: -75% !important;
  bottom: -75% !important;
  left: -75% !important;
}

.-inset-full{
  top: -100% !important;
  right: -100% !important;
  bottom: -100% !important;
  left: -100% !important;
}

.inset-x-0{
  left: 0px !important;
  right: 0px !important;
}

.inset-x-1{
  left: 0.25rem !important;
  right: 0.25rem !important;
}

.inset-x-2{
  left: 0.5rem !important;
  right: 0.5rem !important;
}

.inset-x-3{
  left: 0.75rem !important;
  right: 0.75rem !important;
}

.inset-x-4{
  left: 1rem !important;
  right: 1rem !important;
}

.inset-x-5{
  left: 1.25rem !important;
  right: 1.25rem !important;
}

.inset-x-6{
  left: 1.5rem !important;
  right: 1.5rem !important;
}

.inset-x-7{
  left: 1.75rem !important;
  right: 1.75rem !important;
}

.inset-x-8{
  left: 2rem !important;
  right: 2rem !important;
}

.inset-x-9{
  left: 2.25rem !important;
  right: 2.25rem !important;
}

.inset-x-10{
  left: 2.5rem !important;
  right: 2.5rem !important;
}

.inset-x-11{
  left: 2.75rem !important;
  right: 2.75rem !important;
}

.inset-x-12{
  left: 3rem !important;
  right: 3rem !important;
}

.inset-x-14{
  left: 3.5rem !important;
  right: 3.5rem !important;
}

.inset-x-16{
  left: 4rem !important;
  right: 4rem !important;
}

.inset-x-20{
  left: 5rem !important;
  right: 5rem !important;
}

.inset-x-24{
  left: 6rem !important;
  right: 6rem !important;
}

.inset-x-28{
  left: 7rem !important;
  right: 7rem !important;
}

.inset-x-32{
  left: 8rem !important;
  right: 8rem !important;
}

.inset-x-36{
  left: 9rem !important;
  right: 9rem !important;
}

.inset-x-40{
  left: 10rem !important;
  right: 10rem !important;
}

.inset-x-44{
  left: 11rem !important;
  right: 11rem !important;
}

.inset-x-48{
  left: 12rem !important;
  right: 12rem !important;
}

.inset-x-52{
  left: 13rem !important;
  right: 13rem !important;
}

.inset-x-56{
  left: 14rem !important;
  right: 14rem !important;
}

.inset-x-60{
  left: 15rem !important;
  right: 15rem !important;
}

.inset-x-64{
  left: 16rem !important;
  right: 16rem !important;
}

.inset-x-72{
  left: 18rem !important;
  right: 18rem !important;
}

.inset-x-80{
  left: 20rem !important;
  right: 20rem !important;
}

.inset-x-96{
  left: 24rem !important;
  right: 24rem !important;
}

.inset-x-auto{
  left: auto !important;
  right: auto !important;
}

.inset-x-px{
  left: 1px !important;
  right: 1px !important;
}

.inset-x-0\.5{
  left: 0.125rem !important;
  right: 0.125rem !important;
}

.inset-x-1\.5{
  left: 0.375rem !important;
  right: 0.375rem !important;
}

.inset-x-2\.5{
  left: 0.625rem !important;
  right: 0.625rem !important;
}

.inset-x-3\.5{
  left: 0.875rem !important;
  right: 0.875rem !important;
}

.-inset-x-0{
  left: 0px !important;
  right: 0px !important;
}

.-inset-x-1{
  left: -0.25rem !important;
  right: -0.25rem !important;
}

.-inset-x-2{
  left: -0.5rem !important;
  right: -0.5rem !important;
}

.-inset-x-3{
  left: -0.75rem !important;
  right: -0.75rem !important;
}

.-inset-x-4{
  left: -1rem !important;
  right: -1rem !important;
}

.-inset-x-5{
  left: -1.25rem !important;
  right: -1.25rem !important;
}

.-inset-x-6{
  left: -1.5rem !important;
  right: -1.5rem !important;
}

.-inset-x-7{
  left: -1.75rem !important;
  right: -1.75rem !important;
}

.-inset-x-8{
  left: -2rem !important;
  right: -2rem !important;
}

.-inset-x-9{
  left: -2.25rem !important;
  right: -2.25rem !important;
}

.-inset-x-10{
  left: -2.5rem !important;
  right: -2.5rem !important;
}

.-inset-x-11{
  left: -2.75rem !important;
  right: -2.75rem !important;
}

.-inset-x-12{
  left: -3rem !important;
  right: -3rem !important;
}

.-inset-x-14{
  left: -3.5rem !important;
  right: -3.5rem !important;
}

.-inset-x-16{
  left: -4rem !important;
  right: -4rem !important;
}

.-inset-x-20{
  left: -5rem !important;
  right: -5rem !important;
}

.-inset-x-24{
  left: -6rem !important;
  right: -6rem !important;
}

.-inset-x-28{
  left: -7rem !important;
  right: -7rem !important;
}

.-inset-x-32{
  left: -8rem !important;
  right: -8rem !important;
}

.-inset-x-36{
  left: -9rem !important;
  right: -9rem !important;
}

.-inset-x-40{
  left: -10rem !important;
  right: -10rem !important;
}

.-inset-x-44{
  left: -11rem !important;
  right: -11rem !important;
}

.-inset-x-48{
  left: -12rem !important;
  right: -12rem !important;
}

.-inset-x-52{
  left: -13rem !important;
  right: -13rem !important;
}

.-inset-x-56{
  left: -14rem !important;
  right: -14rem !important;
}

.-inset-x-60{
  left: -15rem !important;
  right: -15rem !important;
}

.-inset-x-64{
  left: -16rem !important;
  right: -16rem !important;
}

.-inset-x-72{
  left: -18rem !important;
  right: -18rem !important;
}

.-inset-x-80{
  left: -20rem !important;
  right: -20rem !important;
}

.-inset-x-96{
  left: -24rem !important;
  right: -24rem !important;
}

.-inset-x-px{
  left: -1px !important;
  right: -1px !important;
}

.-inset-x-0\.5{
  left: -0.125rem !important;
  right: -0.125rem !important;
}

.-inset-x-1\.5{
  left: -0.375rem !important;
  right: -0.375rem !important;
}

.-inset-x-2\.5{
  left: -0.625rem !important;
  right: -0.625rem !important;
}

.-inset-x-3\.5{
  left: -0.875rem !important;
  right: -0.875rem !important;
}

.inset-x-1\/2{
  left: 50% !important;
  right: 50% !important;
}

.inset-x-1\/3{
  left: 33.333333% !important;
  right: 33.333333% !important;
}

.inset-x-2\/3{
  left: 66.666667% !important;
  right: 66.666667% !important;
}

.inset-x-1\/4{
  left: 25% !important;
  right: 25% !important;
}

.inset-x-2\/4{
  left: 50% !important;
  right: 50% !important;
}

.inset-x-3\/4{
  left: 75% !important;
  right: 75% !important;
}

.inset-x-full{
  left: 100% !important;
  right: 100% !important;
}

.-inset-x-1\/2{
  left: -50% !important;
  right: -50% !important;
}

.-inset-x-1\/3{
  left: -33.333333% !important;
  right: -33.333333% !important;
}

.-inset-x-2\/3{
  left: -66.666667% !important;
  right: -66.666667% !important;
}

.-inset-x-1\/4{
  left: -25% !important;
  right: -25% !important;
}

.-inset-x-2\/4{
  left: -50% !important;
  right: -50% !important;
}

.-inset-x-3\/4{
  left: -75% !important;
  right: -75% !important;
}

.-inset-x-full{
  left: -100% !important;
  right: -100% !important;
}

.inset-y-0{
  top: 0px !important;
  bottom: 0px !important;
}

.inset-y-1{
  top: 0.25rem !important;
  bottom: 0.25rem !important;
}

.inset-y-2{
  top: 0.5rem !important;
  bottom: 0.5rem !important;
}

.inset-y-3{
  top: 0.75rem !important;
  bottom: 0.75rem !important;
}

.inset-y-4{
  top: 1rem !important;
  bottom: 1rem !important;
}

.inset-y-5{
  top: 1.25rem !important;
  bottom: 1.25rem !important;
}

.inset-y-6{
  top: 1.5rem !important;
  bottom: 1.5rem !important;
}

.inset-y-7{
  top: 1.75rem !important;
  bottom: 1.75rem !important;
}

.inset-y-8{
  top: 2rem !important;
  bottom: 2rem !important;
}

.inset-y-9{
  top: 2.25rem !important;
  bottom: 2.25rem !important;
}

.inset-y-10{
  top: 2.5rem !important;
  bottom: 2.5rem !important;
}

.inset-y-11{
  top: 2.75rem !important;
  bottom: 2.75rem !important;
}

.inset-y-12{
  top: 3rem !important;
  bottom: 3rem !important;
}

.inset-y-14{
  top: 3.5rem !important;
  bottom: 3.5rem !important;
}

.inset-y-16{
  top: 4rem !important;
  bottom: 4rem !important;
}

.inset-y-20{
  top: 5rem !important;
  bottom: 5rem !important;
}

.inset-y-24{
  top: 6rem !important;
  bottom: 6rem !important;
}

.inset-y-28{
  top: 7rem !important;
  bottom: 7rem !important;
}

.inset-y-32{
  top: 8rem !important;
  bottom: 8rem !important;
}

.inset-y-36{
  top: 9rem !important;
  bottom: 9rem !important;
}

.inset-y-40{
  top: 10rem !important;
  bottom: 10rem !important;
}

.inset-y-44{
  top: 11rem !important;
  bottom: 11rem !important;
}

.inset-y-48{
  top: 12rem !important;
  bottom: 12rem !important;
}

.inset-y-52{
  top: 13rem !important;
  bottom: 13rem !important;
}

.inset-y-56{
  top: 14rem !important;
  bottom: 14rem !important;
}

.inset-y-60{
  top: 15rem !important;
  bottom: 15rem !important;
}

.inset-y-64{
  top: 16rem !important;
  bottom: 16rem !important;
}

.inset-y-72{
  top: 18rem !important;
  bottom: 18rem !important;
}

.inset-y-80{
  top: 20rem !important;
  bottom: 20rem !important;
}

.inset-y-96{
  top: 24rem !important;
  bottom: 24rem !important;
}

.inset-y-auto{
  top: auto !important;
  bottom: auto !important;
}

.inset-y-px{
  top: 1px !important;
  bottom: 1px !important;
}

.inset-y-0\.5{
  top: 0.125rem !important;
  bottom: 0.125rem !important;
}

.inset-y-1\.5{
  top: 0.375rem !important;
  bottom: 0.375rem !important;
}

.inset-y-2\.5{
  top: 0.625rem !important;
  bottom: 0.625rem !important;
}

.inset-y-3\.5{
  top: 0.875rem !important;
  bottom: 0.875rem !important;
}

.-inset-y-0{
  top: 0px !important;
  bottom: 0px !important;
}

.-inset-y-1{
  top: -0.25rem !important;
  bottom: -0.25rem !important;
}

.-inset-y-2{
  top: -0.5rem !important;
  bottom: -0.5rem !important;
}

.-inset-y-3{
  top: -0.75rem !important;
  bottom: -0.75rem !important;
}

.-inset-y-4{
  top: -1rem !important;
  bottom: -1rem !important;
}

.-inset-y-5{
  top: -1.25rem !important;
  bottom: -1.25rem !important;
}

.-inset-y-6{
  top: -1.5rem !important;
  bottom: -1.5rem !important;
}

.-inset-y-7{
  top: -1.75rem !important;
  bottom: -1.75rem !important;
}

.-inset-y-8{
  top: -2rem !important;
  bottom: -2rem !important;
}

.-inset-y-9{
  top: -2.25rem !important;
  bottom: -2.25rem !important;
}

.-inset-y-10{
  top: -2.5rem !important;
  bottom: -2.5rem !important;
}

.-inset-y-11{
  top: -2.75rem !important;
  bottom: -2.75rem !important;
}

.-inset-y-12{
  top: -3rem !important;
  bottom: -3rem !important;
}

.-inset-y-14{
  top: -3.5rem !important;
  bottom: -3.5rem !important;
}

.-inset-y-16{
  top: -4rem !important;
  bottom: -4rem !important;
}

.-inset-y-20{
  top: -5rem !important;
  bottom: -5rem !important;
}

.-inset-y-24{
  top: -6rem !important;
  bottom: -6rem !important;
}

.-inset-y-28{
  top: -7rem !important;
  bottom: -7rem !important;
}

.-inset-y-32{
  top: -8rem !important;
  bottom: -8rem !important;
}

.-inset-y-36{
  top: -9rem !important;
  bottom: -9rem !important;
}

.-inset-y-40{
  top: -10rem !important;
  bottom: -10rem !important;
}

.-inset-y-44{
  top: -11rem !important;
  bottom: -11rem !important;
}

.-inset-y-48{
  top: -12rem !important;
  bottom: -12rem !important;
}

.-inset-y-52{
  top: -13rem !important;
  bottom: -13rem !important;
}

.-inset-y-56{
  top: -14rem !important;
  bottom: -14rem !important;
}

.-inset-y-60{
  top: -15rem !important;
  bottom: -15rem !important;
}

.-inset-y-64{
  top: -16rem !important;
  bottom: -16rem !important;
}

.-inset-y-72{
  top: -18rem !important;
  bottom: -18rem !important;
}

.-inset-y-80{
  top: -20rem !important;
  bottom: -20rem !important;
}

.-inset-y-96{
  top: -24rem !important;
  bottom: -24rem !important;
}

.-inset-y-px{
  top: -1px !important;
  bottom: -1px !important;
}

.-inset-y-0\.5{
  top: -0.125rem !important;
  bottom: -0.125rem !important;
}

.-inset-y-1\.5{
  top: -0.375rem !important;
  bottom: -0.375rem !important;
}

.-inset-y-2\.5{
  top: -0.625rem !important;
  bottom: -0.625rem !important;
}

.-inset-y-3\.5{
  top: -0.875rem !important;
  bottom: -0.875rem !important;
}

.inset-y-1\/2{
  top: 50% !important;
  bottom: 50% !important;
}

.inset-y-1\/3{
  top: 33.333333% !important;
  bottom: 33.333333% !important;
}

.inset-y-2\/3{
  top: 66.666667% !important;
  bottom: 66.666667% !important;
}

.inset-y-1\/4{
  top: 25% !important;
  bottom: 25% !important;
}

.inset-y-2\/4{
  top: 50% !important;
  bottom: 50% !important;
}

.inset-y-3\/4{
  top: 75% !important;
  bottom: 75% !important;
}

.inset-y-full{
  top: 100% !important;
  bottom: 100% !important;
}

.-inset-y-1\/2{
  top: -50% !important;
  bottom: -50% !important;
}

.-inset-y-1\/3{
  top: -33.333333% !important;
  bottom: -33.333333% !important;
}

.-inset-y-2\/3{
  top: -66.666667% !important;
  bottom: -66.666667% !important;
}

.-inset-y-1\/4{
  top: -25% !important;
  bottom: -25% !important;
}

.-inset-y-2\/4{
  top: -50% !important;
  bottom: -50% !important;
}

.-inset-y-3\/4{
  top: -75% !important;
  bottom: -75% !important;
}

.-inset-y-full{
  top: -100% !important;
  bottom: -100% !important;
}

.top-0{
  top: 0px !important;
}

.top-1{
  top: 0.25rem !important;
}

.top-2{
  top: 0.5rem !important;
}

.top-3{
  top: 0.75rem !important;
}

.top-4{
  top: 1rem !important;
}

.top-5{
  top: 1.25rem !important;
}

.top-6{
  top: 1.5rem !important;
}

.top-7{
  top: 1.75rem !important;
}

.top-8{
  top: 2rem !important;
}

.top-9{
  top: 2.25rem !important;
}

.top-10{
  top: 2.5rem !important;
}

.top-11{
  top: 2.75rem !important;
}

.top-12{
  top: 3rem !important;
}

.top-14{
  top: 3.5rem !important;
}

.top-16{
  top: 4rem !important;
}

.top-20{
  top: 5rem !important;
}

.top-24{
  top: 6rem !important;
}

.top-28{
  top: 7rem !important;
}

.top-32{
  top: 8rem !important;
}

.top-36{
  top: 9rem !important;
}

.top-40{
  top: 10rem !important;
}

.top-44{
  top: 11rem !important;
}

.top-48{
  top: 12rem !important;
}

.top-52{
  top: 13rem !important;
}

.top-56{
  top: 14rem !important;
}

.top-60{
  top: 15rem !important;
}

.top-64{
  top: 16rem !important;
}

.top-72{
  top: 18rem !important;
}

.top-80{
  top: 20rem !important;
}

.top-96{
  top: 24rem !important;
}

.top-auto{
  top: auto !important;
}

.top-px{
  top: 1px !important;
}

.top-0\.5{
  top: 0.125rem !important;
}

.top-1\.5{
  top: 0.375rem !important;
}

.top-2\.5{
  top: 0.625rem !important;
}

.top-3\.5{
  top: 0.875rem !important;
}

.-top-0{
  top: 0px !important;
}

.-top-1{
  top: -0.25rem !important;
}

.-top-2{
  top: -0.5rem !important;
}

.-top-3{
  top: -0.75rem !important;
}

.-top-4{
  top: -1rem !important;
}

.-top-5{
  top: -1.25rem !important;
}

.-top-6{
  top: -1.5rem !important;
}

.-top-7{
  top: -1.75rem !important;
}

.-top-8{
  top: -2rem !important;
}

.-top-9{
  top: -2.25rem !important;
}

.-top-10{
  top: -2.5rem !important;
}

.-top-11{
  top: -2.75rem !important;
}

.-top-12{
  top: -3rem !important;
}

.-top-14{
  top: -3.5rem !important;
}

.-top-16{
  top: -4rem !important;
}

.-top-20{
  top: -5rem !important;
}

.-top-24{
  top: -6rem !important;
}

.-top-28{
  top: -7rem !important;
}

.-top-32{
  top: -8rem !important;
}

.-top-36{
  top: -9rem !important;
}

.-top-40{
  top: -10rem !important;
}

.-top-44{
  top: -11rem !important;
}

.-top-48{
  top: -12rem !important;
}

.-top-52{
  top: -13rem !important;
}

.-top-56{
  top: -14rem !important;
}

.-top-60{
  top: -15rem !important;
}

.-top-64{
  top: -16rem !important;
}

.-top-72{
  top: -18rem !important;
}

.-top-80{
  top: -20rem !important;
}

.-top-96{
  top: -24rem !important;
}

.-top-px{
  top: -1px !important;
}

.-top-0\.5{
  top: -0.125rem !important;
}

.-top-1\.5{
  top: -0.375rem !important;
}

.-top-2\.5{
  top: -0.625rem !important;
}

.-top-3\.5{
  top: -0.875rem !important;
}

.top-1\/2{
  top: 50% !important;
}

.top-1\/3{
  top: 33.333333% !important;
}

.top-2\/3{
  top: 66.666667% !important;
}

.top-1\/4{
  top: 25% !important;
}

.top-2\/4{
  top: 50% !important;
}

.top-3\/4{
  top: 75% !important;
}

.top-full{
  top: 100% !important;
}

.-top-1\/2{
  top: -50% !important;
}

.-top-1\/3{
  top: -33.333333% !important;
}

.-top-2\/3{
  top: -66.666667% !important;
}

.-top-1\/4{
  top: -25% !important;
}

.-top-2\/4{
  top: -50% !important;
}

.-top-3\/4{
  top: -75% !important;
}

.-top-full{
  top: -100% !important;
}

.right-0{
  right: 0px !important;
}

.right-1{
  right: 0.25rem !important;
}

.right-2{
  right: 0.5rem !important;
}

.right-3{
  right: 0.75rem !important;
}

.right-4{
  right: 1rem !important;
}

.right-5{
  right: 1.25rem !important;
}

.right-6{
  right: 1.5rem !important;
}

.right-7{
  right: 1.75rem !important;
}

.right-8{
  right: 2rem !important;
}

.right-9{
  right: 2.25rem !important;
}

.right-10{
  right: 2.5rem !important;
}

.right-11{
  right: 2.75rem !important;
}

.right-12{
  right: 3rem !important;
}

.right-14{
  right: 3.5rem !important;
}

.right-16{
  right: 4rem !important;
}

.right-20{
  right: 5rem !important;
}

.right-24{
  right: 6rem !important;
}

.right-28{
  right: 7rem !important;
}

.right-32{
  right: 8rem !important;
}

.right-36{
  right: 9rem !important;
}

.right-40{
  right: 10rem !important;
}

.right-44{
  right: 11rem !important;
}

.right-48{
  right: 12rem !important;
}

.right-52{
  right: 13rem !important;
}

.right-56{
  right: 14rem !important;
}

.right-60{
  right: 15rem !important;
}

.right-64{
  right: 16rem !important;
}

.right-72{
  right: 18rem !important;
}

.right-80{
  right: 20rem !important;
}

.right-96{
  right: 24rem !important;
}

.right-auto{
  right: auto !important;
}

.right-px{
  right: 1px !important;
}

.right-0\.5{
  right: 0.125rem !important;
}

.right-1\.5{
  right: 0.375rem !important;
}

.right-2\.5{
  right: 0.625rem !important;
}

.right-3\.5{
  right: 0.875rem !important;
}

.-right-0{
  right: 0px !important;
}

.-right-1{
  right: -0.25rem !important;
}

.-right-2{
  right: -0.5rem !important;
}

.-right-3{
  right: -0.75rem !important;
}

.-right-4{
  right: -1rem !important;
}

.-right-5{
  right: -1.25rem !important;
}

.-right-6{
  right: -1.5rem !important;
}

.-right-7{
  right: -1.75rem !important;
}

.-right-8{
  right: -2rem !important;
}

.-right-9{
  right: -2.25rem !important;
}

.-right-10{
  right: -2.5rem !important;
}

.-right-11{
  right: -2.75rem !important;
}

.-right-12{
  right: -3rem !important;
}

.-right-14{
  right: -3.5rem !important;
}

.-right-16{
  right: -4rem !important;
}

.-right-20{
  right: -5rem !important;
}

.-right-24{
  right: -6rem !important;
}

.-right-28{
  right: -7rem !important;
}

.-right-32{
  right: -8rem !important;
}

.-right-36{
  right: -9rem !important;
}

.-right-40{
  right: -10rem !important;
}

.-right-44{
  right: -11rem !important;
}

.-right-48{
  right: -12rem !important;
}

.-right-52{
  right: -13rem !important;
}

.-right-56{
  right: -14rem !important;
}

.-right-60{
  right: -15rem !important;
}

.-right-64{
  right: -16rem !important;
}

.-right-72{
  right: -18rem !important;
}

.-right-80{
  right: -20rem !important;
}

.-right-96{
  right: -24rem !important;
}

.-right-px{
  right: -1px !important;
}

.-right-0\.5{
  right: -0.125rem !important;
}

.-right-1\.5{
  right: -0.375rem !important;
}

.-right-2\.5{
  right: -0.625rem !important;
}

.-right-3\.5{
  right: -0.875rem !important;
}

.right-1\/2{
  right: 50% !important;
}

.right-1\/3{
  right: 33.333333% !important;
}

.right-2\/3{
  right: 66.666667% !important;
}

.right-1\/4{
  right: 25% !important;
}

.right-2\/4{
  right: 50% !important;
}

.right-3\/4{
  right: 75% !important;
}

.right-full{
  right: 100% !important;
}

.-right-1\/2{
  right: -50% !important;
}

.-right-1\/3{
  right: -33.333333% !important;
}

.-right-2\/3{
  right: -66.666667% !important;
}

.-right-1\/4{
  right: -25% !important;
}

.-right-2\/4{
  right: -50% !important;
}

.-right-3\/4{
  right: -75% !important;
}

.-right-full{
  right: -100% !important;
}

.bottom-0{
  bottom: 0px !important;
}

.bottom-1{
  bottom: 0.25rem !important;
}

.bottom-2{
  bottom: 0.5rem !important;
}

.bottom-3{
  bottom: 0.75rem !important;
}

.bottom-4{
  bottom: 1rem !important;
}

.bottom-5{
  bottom: 1.25rem !important;
}

.bottom-6{
  bottom: 1.5rem !important;
}

.bottom-7{
  bottom: 1.75rem !important;
}

.bottom-8{
  bottom: 2rem !important;
}

.bottom-9{
  bottom: 2.25rem !important;
}

.bottom-10{
  bottom: 2.5rem !important;
}

.bottom-11{
  bottom: 2.75rem !important;
}

.bottom-12{
  bottom: 3rem !important;
}

.bottom-14{
  bottom: 3.5rem !important;
}

.bottom-16{
  bottom: 4rem !important;
}

.bottom-20{
  bottom: 5rem !important;
}

.bottom-24{
  bottom: 6rem !important;
}

.bottom-28{
  bottom: 7rem !important;
}

.bottom-32{
  bottom: 8rem !important;
}

.bottom-36{
  bottom: 9rem !important;
}

.bottom-40{
  bottom: 10rem !important;
}

.bottom-44{
  bottom: 11rem !important;
}

.bottom-48{
  bottom: 12rem !important;
}

.bottom-52{
  bottom: 13rem !important;
}

.bottom-56{
  bottom: 14rem !important;
}

.bottom-60{
  bottom: 15rem !important;
}

.bottom-64{
  bottom: 16rem !important;
}

.bottom-72{
  bottom: 18rem !important;
}

.bottom-80{
  bottom: 20rem !important;
}

.bottom-96{
  bottom: 24rem !important;
}

.bottom-auto{
  bottom: auto !important;
}

.bottom-px{
  bottom: 1px !important;
}

.bottom-0\.5{
  bottom: 0.125rem !important;
}

.bottom-1\.5{
  bottom: 0.375rem !important;
}

.bottom-2\.5{
  bottom: 0.625rem !important;
}

.bottom-3\.5{
  bottom: 0.875rem !important;
}

.-bottom-0{
  bottom: 0px !important;
}

.-bottom-1{
  bottom: -0.25rem !important;
}

.-bottom-2{
  bottom: -0.5rem !important;
}

.-bottom-3{
  bottom: -0.75rem !important;
}

.-bottom-4{
  bottom: -1rem !important;
}

.-bottom-5{
  bottom: -1.25rem !important;
}

.-bottom-6{
  bottom: -1.5rem !important;
}

.-bottom-7{
  bottom: -1.75rem !important;
}

.-bottom-8{
  bottom: -2rem !important;
}

.-bottom-9{
  bottom: -2.25rem !important;
}

.-bottom-10{
  bottom: -2.5rem !important;
}

.-bottom-11{
  bottom: -2.75rem !important;
}

.-bottom-12{
  bottom: -3rem !important;
}

.-bottom-14{
  bottom: -3.5rem !important;
}

.-bottom-16{
  bottom: -4rem !important;
}

.-bottom-20{
  bottom: -5rem !important;
}

.-bottom-24{
  bottom: -6rem !important;
}

.-bottom-28{
  bottom: -7rem !important;
}

.-bottom-32{
  bottom: -8rem !important;
}

.-bottom-36{
  bottom: -9rem !important;
}

.-bottom-40{
  bottom: -10rem !important;
}

.-bottom-44{
  bottom: -11rem !important;
}

.-bottom-48{
  bottom: -12rem !important;
}

.-bottom-52{
  bottom: -13rem !important;
}

.-bottom-56{
  bottom: -14rem !important;
}

.-bottom-60{
  bottom: -15rem !important;
}

.-bottom-64{
  bottom: -16rem !important;
}

.-bottom-72{
  bottom: -18rem !important;
}

.-bottom-80{
  bottom: -20rem !important;
}

.-bottom-96{
  bottom: -24rem !important;
}

.-bottom-px{
  bottom: -1px !important;
}

.-bottom-0\.5{
  bottom: -0.125rem !important;
}

.-bottom-1\.5{
  bottom: -0.375rem !important;
}

.-bottom-2\.5{
  bottom: -0.625rem !important;
}

.-bottom-3\.5{
  bottom: -0.875rem !important;
}

.bottom-1\/2{
  bottom: 50% !important;
}

.bottom-1\/3{
  bottom: 33.333333% !important;
}

.bottom-2\/3{
  bottom: 66.666667% !important;
}

.bottom-1\/4{
  bottom: 25% !important;
}

.bottom-2\/4{
  bottom: 50% !important;
}

.bottom-3\/4{
  bottom: 75% !important;
}

.bottom-full{
  bottom: 100% !important;
}

.-bottom-1\/2{
  bottom: -50% !important;
}

.-bottom-1\/3{
  bottom: -33.333333% !important;
}

.-bottom-2\/3{
  bottom: -66.666667% !important;
}

.-bottom-1\/4{
  bottom: -25% !important;
}

.-bottom-2\/4{
  bottom: -50% !important;
}

.-bottom-3\/4{
  bottom: -75% !important;
}

.-bottom-full{
  bottom: -100% !important;
}

.left-0{
  left: 0px !important;
}

.left-1{
  left: 0.25rem !important;
}

.left-2{
  left: 0.5rem !important;
}

.left-3{
  left: 0.75rem !important;
}

.left-4{
  left: 1rem !important;
}

.left-5{
  left: 1.25rem !important;
}

.left-6{
  left: 1.5rem !important;
}

.left-7{
  left: 1.75rem !important;
}

.left-8{
  left: 2rem !important;
}

.left-9{
  left: 2.25rem !important;
}

.left-10{
  left: 2.5rem !important;
}

.left-11{
  left: 2.75rem !important;
}

.left-12{
  left: 3rem !important;
}

.left-14{
  left: 3.5rem !important;
}

.left-16{
  left: 4rem !important;
}

.left-20{
  left: 5rem !important;
}

.left-24{
  left: 6rem !important;
}

.left-28{
  left: 7rem !important;
}

.left-32{
  left: 8rem !important;
}

.left-36{
  left: 9rem !important;
}

.left-40{
  left: 10rem !important;
}

.left-44{
  left: 11rem !important;
}

.left-48{
  left: 12rem !important;
}

.left-52{
  left: 13rem !important;
}

.left-56{
  left: 14rem !important;
}

.left-60{
  left: 15rem !important;
}

.left-64{
  left: 16rem !important;
}

.left-72{
  left: 18rem !important;
}

.left-80{
  left: 20rem !important;
}

.left-96{
  left: 24rem !important;
}

.left-auto{
  left: auto !important;
}

.left-px{
  left: 1px !important;
}

.left-0\.5{
  left: 0.125rem !important;
}

.left-1\.5{
  left: 0.375rem !important;
}

.left-2\.5{
  left: 0.625rem !important;
}

.left-3\.5{
  left: 0.875rem !important;
}

.-left-0{
  left: 0px !important;
}

.-left-1{
  left: -0.25rem !important;
}

.-left-2{
  left: -0.5rem !important;
}

.-left-3{
  left: -0.75rem !important;
}

.-left-4{
  left: -1rem !important;
}

.-left-5{
  left: -1.25rem !important;
}

.-left-6{
  left: -1.5rem !important;
}

.-left-7{
  left: -1.75rem !important;
}

.-left-8{
  left: -2rem !important;
}

.-left-9{
  left: -2.25rem !important;
}

.-left-10{
  left: -2.5rem !important;
}

.-left-11{
  left: -2.75rem !important;
}

.-left-12{
  left: -3rem !important;
}

.-left-14{
  left: -3.5rem !important;
}

.-left-16{
  left: -4rem !important;
}

.-left-20{
  left: -5rem !important;
}

.-left-24{
  left: -6rem !important;
}

.-left-28{
  left: -7rem !important;
}

.-left-32{
  left: -8rem !important;
}

.-left-36{
  left: -9rem !important;
}

.-left-40{
  left: -10rem !important;
}

.-left-44{
  left: -11rem !important;
}

.-left-48{
  left: -12rem !important;
}

.-left-52{
  left: -13rem !important;
}

.-left-56{
  left: -14rem !important;
}

.-left-60{
  left: -15rem !important;
}

.-left-64{
  left: -16rem !important;
}

.-left-72{
  left: -18rem !important;
}

.-left-80{
  left: -20rem !important;
}

.-left-96{
  left: -24rem !important;
}

.-left-px{
  left: -1px !important;
}

.-left-0\.5{
  left: -0.125rem !important;
}

.-left-1\.5{
  left: -0.375rem !important;
}

.-left-2\.5{
  left: -0.625rem !important;
}

.-left-3\.5{
  left: -0.875rem !important;
}

.left-1\/2{
  left: 50% !important;
}

.left-1\/3{
  left: 33.333333% !important;
}

.left-2\/3{
  left: 66.666667% !important;
}

.left-1\/4{
  left: 25% !important;
}

.left-2\/4{
  left: 50% !important;
}

.left-3\/4{
  left: 75% !important;
}

.left-full{
  left: 100% !important;
}

.-left-1\/2{
  left: -50% !important;
}

.-left-1\/3{
  left: -33.333333% !important;
}

.-left-2\/3{
  left: -66.666667% !important;
}

.-left-1\/4{
  left: -25% !important;
}

.-left-2\/4{
  left: -50% !important;
}

.-left-3\/4{
  left: -75% !important;
}

.-left-full{
  left: -100% !important;
}

.isolate{
  isolation: isolate !important;
}

.isolation-auto{
  isolation: auto !important;
}

.z-0{
  z-index: 0 !important;
}

.z-10{
  z-index: 10 !important;
}

.z-20{
  z-index: 20 !important;
}

.z-30{
  z-index: 30 !important;
}

.z-40{
  z-index: 40 !important;
}

.z-50{
  z-index: 50 !important;
}

.z-auto{
  z-index: auto !important;
}

.focus-within\:z-0:focus-within{
  z-index: 0 !important;
}

.focus-within\:z-10:focus-within{
  z-index: 10 !important;
}

.focus-within\:z-20:focus-within{
  z-index: 20 !important;
}

.focus-within\:z-30:focus-within{
  z-index: 30 !important;
}

.focus-within\:z-40:focus-within{
  z-index: 40 !important;
}

.focus-within\:z-50:focus-within{
  z-index: 50 !important;
}

.focus-within\:z-auto:focus-within{
  z-index: auto !important;
}

.focus\:z-0:focus{
  z-index: 0 !important;
}

.focus\:z-10:focus{
  z-index: 10 !important;
}

.focus\:z-20:focus{
  z-index: 20 !important;
}

.focus\:z-30:focus{
  z-index: 30 !important;
}

.focus\:z-40:focus{
  z-index: 40 !important;
}

.focus\:z-50:focus{
  z-index: 50 !important;
}

.focus\:z-auto:focus{
  z-index: auto !important;
}

.order-1{
  order: 1 !important;
}

.order-2{
  order: 2 !important;
}

.order-3{
  order: 3 !important;
}

.order-4{
  order: 4 !important;
}

.order-5{
  order: 5 !important;
}

.order-6{
  order: 6 !important;
}

.order-7{
  order: 7 !important;
}

.order-8{
  order: 8 !important;
}

.order-9{
  order: 9 !important;
}

.order-10{
  order: 10 !important;
}

.order-11{
  order: 11 !important;
}

.order-12{
  order: 12 !important;
}

.order-first{
  order: -9999 !important;
}

.order-last{
  order: 9999 !important;
}

.order-none{
  order: 0 !important;
}

.col-auto{
  grid-column: auto !important;
}

.col-span-1{
  grid-column: span 1 / span 1 !important;
}

.col-span-2{
  grid-column: span 2 / span 2 !important;
}

.col-span-3{
  grid-column: span 3 / span 3 !important;
}

.col-span-4{
  grid-column: span 4 / span 4 !important;
}

.col-span-5{
  grid-column: span 5 / span 5 !important;
}

.col-span-6{
  grid-column: span 6 / span 6 !important;
}

.col-span-7{
  grid-column: span 7 / span 7 !important;
}

.col-span-8{
  grid-column: span 8 / span 8 !important;
}

.col-span-9{
  grid-column: span 9 / span 9 !important;
}

.col-span-10{
  grid-column: span 10 / span 10 !important;
}

.col-span-11{
  grid-column: span 11 / span 11 !important;
}

.col-span-12{
  grid-column: span 12 / span 12 !important;
}

.col-span-full{
  grid-column: 1 / -1 !important;
}

.col-start-1{
  grid-column-start: 1 !important;
}

.col-start-2{
  grid-column-start: 2 !important;
}

.col-start-3{
  grid-column-start: 3 !important;
}

.col-start-4{
  grid-column-start: 4 !important;
}

.col-start-5{
  grid-column-start: 5 !important;
}

.col-start-6{
  grid-column-start: 6 !important;
}

.col-start-7{
  grid-column-start: 7 !important;
}

.col-start-8{
  grid-column-start: 8 !important;
}

.col-start-9{
  grid-column-start: 9 !important;
}

.col-start-10{
  grid-column-start: 10 !important;
}

.col-start-11{
  grid-column-start: 11 !important;
}

.col-start-12{
  grid-column-start: 12 !important;
}

.col-start-13{
  grid-column-start: 13 !important;
}

.col-start-auto{
  grid-column-start: auto !important;
}

.col-end-1{
  grid-column-end: 1 !important;
}

.col-end-2{
  grid-column-end: 2 !important;
}

.col-end-3{
  grid-column-end: 3 !important;
}

.col-end-4{
  grid-column-end: 4 !important;
}

.col-end-5{
  grid-column-end: 5 !important;
}

.col-end-6{
  grid-column-end: 6 !important;
}

.col-end-7{
  grid-column-end: 7 !important;
}

.col-end-8{
  grid-column-end: 8 !important;
}

.col-end-9{
  grid-column-end: 9 !important;
}

.col-end-10{
  grid-column-end: 10 !important;
}

.col-end-11{
  grid-column-end: 11 !important;
}

.col-end-12{
  grid-column-end: 12 !important;
}

.col-end-13{
  grid-column-end: 13 !important;
}

.col-end-auto{
  grid-column-end: auto !important;
}

.row-auto{
  grid-row: auto !important;
}

.row-span-1{
  grid-row: span 1 / span 1 !important;
}

.row-span-2{
  grid-row: span 2 / span 2 !important;
}

.row-span-3{
  grid-row: span 3 / span 3 !important;
}

.row-span-4{
  grid-row: span 4 / span 4 !important;
}

.row-span-5{
  grid-row: span 5 / span 5 !important;
}

.row-span-6{
  grid-row: span 6 / span 6 !important;
}

.row-span-full{
  grid-row: 1 / -1 !important;
}

.row-start-1{
  grid-row-start: 1 !important;
}

.row-start-2{
  grid-row-start: 2 !important;
}

.row-start-3{
  grid-row-start: 3 !important;
}

.row-start-4{
  grid-row-start: 4 !important;
}

.row-start-5{
  grid-row-start: 5 !important;
}

.row-start-6{
  grid-row-start: 6 !important;
}

.row-start-7{
  grid-row-start: 7 !important;
}

.row-start-auto{
  grid-row-start: auto !important;
}

.row-end-1{
  grid-row-end: 1 !important;
}

.row-end-2{
  grid-row-end: 2 !important;
}

.row-end-3{
  grid-row-end: 3 !important;
}

.row-end-4{
  grid-row-end: 4 !important;
}

.row-end-5{
  grid-row-end: 5 !important;
}

.row-end-6{
  grid-row-end: 6 !important;
}

.row-end-7{
  grid-row-end: 7 !important;
}

.row-end-auto{
  grid-row-end: auto !important;
}

.float-right{
  float: right !important;
}

.float-left{
  float: left !important;
}

.float-none{
  float: none !important;
}

.clear-left{
  clear: left !important;
}

.clear-right{
  clear: right !important;
}

.clear-both{
  clear: both !important;
}

.clear-none{
  clear: none !important;
}

.m-0{
  margin: 0px !important;
}

.m-1{
  margin: 0.25rem !important;
}

.m-2{
  margin: 0.5rem !important;
}

.m-3{
  margin: 0.75rem !important;
}

.m-4{
  margin: 1rem !important;
}

.m-5{
  margin: 1.25rem !important;
}

.m-6{
  margin: 1.5rem !important;
}

.m-7{
  margin: 1.75rem !important;
}

.m-8{
  margin: 2rem !important;
}

.m-9{
  margin: 2.25rem !important;
}

.m-10{
  margin: 2.5rem !important;
}

.m-11{
  margin: 2.75rem !important;
}

.m-12{
  margin: 3rem !important;
}

.m-14{
  margin: 3.5rem !important;
}

.m-16{
  margin: 4rem !important;
}

.m-20{
  margin: 5rem !important;
}

.m-24{
  margin: 6rem !important;
}

.m-28{
  margin: 7rem !important;
}

.m-32{
  margin: 8rem !important;
}

.m-36{
  margin: 9rem !important;
}

.m-40{
  margin: 10rem !important;
}

.m-44{
  margin: 11rem !important;
}

.m-48{
  margin: 12rem !important;
}

.m-52{
  margin: 13rem !important;
}

.m-56{
  margin: 14rem !important;
}

.m-60{
  margin: 15rem !important;
}

.m-64{
  margin: 16rem !important;
}

.m-72{
  margin: 18rem !important;
}

.m-80{
  margin: 20rem !important;
}

.m-96{
  margin: 24rem !important;
}

.m-auto{
  margin: auto !important;
}

.m-px{
  margin: 1px !important;
}

.m-0\.5{
  margin: 0.125rem !important;
}

.m-1\.5{
  margin: 0.375rem !important;
}

.m-2\.5{
  margin: 0.625rem !important;
}

.m-3\.5{
  margin: 0.875rem !important;
}

.-m-0{
  margin: 0px !important;
}

.-m-1{
  margin: -0.25rem !important;
}

.-m-2{
  margin: -0.5rem !important;
}

.-m-3{
  margin: -0.75rem !important;
}

.-m-4{
  margin: -1rem !important;
}

.-m-5{
  margin: -1.25rem !important;
}

.-m-6{
  margin: -1.5rem !important;
}

.-m-7{
  margin: -1.75rem !important;
}

.-m-8{
  margin: -2rem !important;
}

.-m-9{
  margin: -2.25rem !important;
}

.-m-10{
  margin: -2.5rem !important;
}

.-m-11{
  margin: -2.75rem !important;
}

.-m-12{
  margin: -3rem !important;
}

.-m-14{
  margin: -3.5rem !important;
}

.-m-16{
  margin: -4rem !important;
}

.-m-20{
  margin: -5rem !important;
}

.-m-24{
  margin: -6rem !important;
}

.-m-28{
  margin: -7rem !important;
}

.-m-32{
  margin: -8rem !important;
}

.-m-36{
  margin: -9rem !important;
}

.-m-40{
  margin: -10rem !important;
}

.-m-44{
  margin: -11rem !important;
}

.-m-48{
  margin: -12rem !important;
}

.-m-52{
  margin: -13rem !important;
}

.-m-56{
  margin: -14rem !important;
}

.-m-60{
  margin: -15rem !important;
}

.-m-64{
  margin: -16rem !important;
}

.-m-72{
  margin: -18rem !important;
}

.-m-80{
  margin: -20rem !important;
}

.-m-96{
  margin: -24rem !important;
}

.-m-px{
  margin: -1px !important;
}

.-m-0\.5{
  margin: -0.125rem !important;
}

.-m-1\.5{
  margin: -0.375rem !important;
}

.-m-2\.5{
  margin: -0.625rem !important;
}

.-m-3\.5{
  margin: -0.875rem !important;
}

.mx-0{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-1{
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3{
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.mx-4{
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-5{
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.mx-6{
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-7{
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.mx-8{
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-9{
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.mx-10{
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-11{
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.mx-12{
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-14{
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-16{
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-20{
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-24{
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-28{
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}

.mx-32{
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.mx-36{
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.mx-40{
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.mx-44{
  margin-left: 11rem !important;
  margin-right: 11rem !important;
}

.mx-48{
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.mx-52{
  margin-left: 13rem !important;
  margin-right: 13rem !important;
}

.mx-56{
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.mx-60{
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.mx-64{
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.mx-72{
  margin-left: 18rem !important;
  margin-right: 18rem !important;
}

.mx-80{
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.mx-96{
  margin-left: 24rem !important;
  margin-right: 24rem !important;
}

.mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-px{
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mx-0\.5{
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}

.mx-1\.5{
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}

.mx-2\.5{
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.mx-3\.5{
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important;
}

.-mx-0{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.-mx-1{
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mx-2{
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mx-3{
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-mx-4{
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-5{
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-mx-6{
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-7{
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.-mx-8{
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-9{
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.-mx-10{
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-mx-11{
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.-mx-12{
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-14{
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.-mx-16{
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-20{
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-mx-24{
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-mx-28{
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.-mx-32{
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-mx-36{
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.-mx-40{
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-mx-44{
  margin-left: -11rem !important;
  margin-right: -11rem !important;
}

.-mx-48{
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-mx-52{
  margin-left: -13rem !important;
  margin-right: -13rem !important;
}

.-mx-56{
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-mx-60{
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.-mx-64{
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-mx-72{
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.-mx-80{
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.-mx-96{
  margin-left: -24rem !important;
  margin-right: -24rem !important;
}

.-mx-px{
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.-mx-0\.5{
  margin-left: -0.125rem !important;
  margin-right: -0.125rem !important;
}

.-mx-1\.5{
  margin-left: -0.375rem !important;
  margin-right: -0.375rem !important;
}

.-mx-2\.5{
  margin-left: -0.625rem !important;
  margin-right: -0.625rem !important;
}

.-mx-3\.5{
  margin-left: -0.875rem !important;
  margin-right: -0.875rem !important;
}

.my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-1{
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2{
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3{
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5{
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7{
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8{
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9{
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10{
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11{
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12{
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-14{
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-16{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-20{
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-24{
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-28{
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-32{
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-36{
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.my-40{
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-44{
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.my-48{
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.my-52{
  margin-top: 13rem !important;
  margin-bottom: 13rem !important;
}

.my-56{
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-60{
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-64{
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.my-72{
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.my-80{
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-96{
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.my-px{
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.my-0\.5{
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}

.my-1\.5{
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-2\.5{
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3\.5{
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.-my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.-my-1{
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-my-2{
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-my-3{
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.-my-4{
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-5{
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-my-6{
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-7{
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.-my-8{
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-9{
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.-my-10{
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-my-11{
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.-my-12{
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-14{
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.-my-16{
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-20{
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-my-24{
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-my-28{
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.-my-32{
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-my-36{
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.-my-40{
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-my-44{
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.-my-48{
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-my-52{
  margin-top: -13rem !important;
  margin-bottom: -13rem !important;
}

.-my-56{
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-my-60{
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.-my-64{
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-my-72{
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.-my-80{
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.-my-96{
  margin-top: -24rem !important;
  margin-bottom: -24rem !important;
}

.-my-px{
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.-my-0\.5{
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important;
}

.-my-1\.5{
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.-my-2\.5{
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.-my-3\.5{
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.mt-0{
  margin-top: 0px !important;
}

.mt-1{
  margin-top: 0.25rem !important;
}

.mt-2{
  margin-top: 0.5rem !important;
}

.mt-3{
  margin-top: 0.75rem !important;
}

.mt-4{
  margin-top: 1rem !important;
}

.mt-5{
  margin-top: 1.25rem !important;
}

.mt-6{
  margin-top: 1.5rem !important;
}

.mt-7{
  margin-top: 1.75rem !important;
}

.mt-8{
  margin-top: 2rem !important;
}

.mt-9{
  margin-top: 2.25rem !important;
}

.mt-10{
  margin-top: 2.5rem !important;
}

.mt-11{
  margin-top: 2.75rem !important;
}

.mt-12{
  margin-top: 3rem !important;
}

.mt-14{
  margin-top: 3.5rem !important;
}

.mt-16{
  margin-top: 4rem !important;
}

.mt-20{
  margin-top: 5rem !important;
}

.mt-24{
  margin-top: 6rem !important;
}

.mt-28{
  margin-top: 7rem !important;
}

.mt-32{
  margin-top: 8rem !important;
}

.mt-36{
  margin-top: 9rem !important;
}

.mt-40{
  margin-top: 10rem !important;
}

.mt-44{
  margin-top: 11rem !important;
}

.mt-48{
  margin-top: 12rem !important;
}

.mt-52{
  margin-top: 13rem !important;
}

.mt-56{
  margin-top: 14rem !important;
}

.mt-60{
  margin-top: 15rem !important;
}

.mt-64{
  margin-top: 16rem !important;
}

.mt-72{
  margin-top: 18rem !important;
}

.mt-80{
  margin-top: 20rem !important;
}

.mt-96{
  margin-top: 24rem !important;
}

.mt-auto{
  margin-top: auto !important;
}

.mt-px{
  margin-top: 1px !important;
}

.mt-0\.5{
  margin-top: 0.125rem !important;
}

.mt-1\.5{
  margin-top: 0.375rem !important;
}

.mt-2\.5{
  margin-top: 0.625rem !important;
}

.mt-3\.5{
  margin-top: 0.875rem !important;
}

.-mt-0{
  margin-top: 0px !important;
}

.-mt-1{
  margin-top: -0.25rem !important;
}

.-mt-2{
  margin-top: -0.5rem !important;
}

.-mt-3{
  margin-top: -0.75rem !important;
}

.-mt-4{
  margin-top: -1rem !important;
}

.-mt-5{
  margin-top: -1.25rem !important;
}

.-mt-6{
  margin-top: -1.5rem !important;
}

.-mt-7{
  margin-top: -1.75rem !important;
}

.-mt-8{
  margin-top: -2rem !important;
}

.-mt-9{
  margin-top: -2.25rem !important;
}

.-mt-10{
  margin-top: -2.5rem !important;
}

.-mt-11{
  margin-top: -2.75rem !important;
}

.-mt-12{
  margin-top: -3rem !important;
}

.-mt-14{
  margin-top: -3.5rem !important;
}

.-mt-16{
  margin-top: -4rem !important;
}

.-mt-20{
  margin-top: -5rem !important;
}

.-mt-24{
  margin-top: -6rem !important;
}

.-mt-28{
  margin-top: -7rem !important;
}

.-mt-32{
  margin-top: -8rem !important;
}

.-mt-36{
  margin-top: -9rem !important;
}

.-mt-40{
  margin-top: -10rem !important;
}

.-mt-44{
  margin-top: -11rem !important;
}

.-mt-48{
  margin-top: -12rem !important;
}

.-mt-52{
  margin-top: -13rem !important;
}

.-mt-56{
  margin-top: -14rem !important;
}

.-mt-60{
  margin-top: -15rem !important;
}

.-mt-64{
  margin-top: -16rem !important;
}

.-mt-72{
  margin-top: -18rem !important;
}

.-mt-80{
  margin-top: -20rem !important;
}

.-mt-96{
  margin-top: -24rem !important;
}

.-mt-px{
  margin-top: -1px !important;
}

.-mt-0\.5{
  margin-top: -0.125rem !important;
}

.-mt-1\.5{
  margin-top: -0.375rem !important;
}

.-mt-2\.5{
  margin-top: -0.625rem !important;
}

.-mt-3\.5{
  margin-top: -0.875rem !important;
}

.mr-0{
  margin-right: 0px !important;
}

.mr-1{
  margin-right: 0.25rem !important;
}

.mr-2{
  margin-right: 0.5rem !important;
}

.mr-3{
  margin-right: 0.75rem !important;
}

.mr-4{
  margin-right: 1rem !important;
}

.mr-5{
  margin-right: 1.25rem !important;
}

.mr-6{
  margin-right: 1.5rem !important;
}

.mr-7{
  margin-right: 1.75rem !important;
}

.mr-8{
  margin-right: 2rem !important;
}

.mr-9{
  margin-right: 2.25rem !important;
}

.mr-10{
  margin-right: 2.5rem !important;
}

.mr-11{
  margin-right: 2.75rem !important;
}

.mr-12{
  margin-right: 3rem !important;
}

.mr-14{
  margin-right: 3.5rem !important;
}

.mr-16{
  margin-right: 4rem !important;
}

.mr-20{
  margin-right: 5rem !important;
}

.mr-24{
  margin-right: 6rem !important;
}

.mr-28{
  margin-right: 7rem !important;
}

.mr-32{
  margin-right: 8rem !important;
}

.mr-36{
  margin-right: 9rem !important;
}

.mr-40{
  margin-right: 10rem !important;
}

.mr-44{
  margin-right: 11rem !important;
}

.mr-48{
  margin-right: 12rem !important;
}

.mr-52{
  margin-right: 13rem !important;
}

.mr-56{
  margin-right: 14rem !important;
}

.mr-60{
  margin-right: 15rem !important;
}

.mr-64{
  margin-right: 16rem !important;
}

.mr-72{
  margin-right: 18rem !important;
}

.mr-80{
  margin-right: 20rem !important;
}

.mr-96{
  margin-right: 24rem !important;
}

.mr-auto{
  margin-right: auto !important;
}

.mr-px{
  margin-right: 1px !important;
}

.mr-0\.5{
  margin-right: 0.125rem !important;
}

.mr-1\.5{
  margin-right: 0.375rem !important;
}

.mr-2\.5{
  margin-right: 0.625rem !important;
}

.mr-3\.5{
  margin-right: 0.875rem !important;
}

.-mr-0{
  margin-right: 0px !important;
}

.-mr-1{
  margin-right: -0.25rem !important;
}

.-mr-2{
  margin-right: -0.5rem !important;
}

.-mr-3{
  margin-right: -0.75rem !important;
}

.-mr-4{
  margin-right: -1rem !important;
}

.-mr-5{
  margin-right: -1.25rem !important;
}

.-mr-6{
  margin-right: -1.5rem !important;
}

.-mr-7{
  margin-right: -1.75rem !important;
}

.-mr-8{
  margin-right: -2rem !important;
}

.-mr-9{
  margin-right: -2.25rem !important;
}

.-mr-10{
  margin-right: -2.5rem !important;
}

.-mr-11{
  margin-right: -2.75rem !important;
}

.-mr-12{
  margin-right: -3rem !important;
}

.-mr-14{
  margin-right: -3.5rem !important;
}

.-mr-16{
  margin-right: -4rem !important;
}

.-mr-20{
  margin-right: -5rem !important;
}

.-mr-24{
  margin-right: -6rem !important;
}

.-mr-28{
  margin-right: -7rem !important;
}

.-mr-32{
  margin-right: -8rem !important;
}

.-mr-36{
  margin-right: -9rem !important;
}

.-mr-40{
  margin-right: -10rem !important;
}

.-mr-44{
  margin-right: -11rem !important;
}

.-mr-48{
  margin-right: -12rem !important;
}

.-mr-52{
  margin-right: -13rem !important;
}

.-mr-56{
  margin-right: -14rem !important;
}

.-mr-60{
  margin-right: -15rem !important;
}

.-mr-64{
  margin-right: -16rem !important;
}

.-mr-72{
  margin-right: -18rem !important;
}

.-mr-80{
  margin-right: -20rem !important;
}

.-mr-96{
  margin-right: -24rem !important;
}

.-mr-px{
  margin-right: -1px !important;
}

.-mr-0\.5{
  margin-right: -0.125rem !important;
}

.-mr-1\.5{
  margin-right: -0.375rem !important;
}

.-mr-2\.5{
  margin-right: -0.625rem !important;
}

.-mr-3\.5{
  margin-right: -0.875rem !important;
}

.mb-0{
  margin-bottom: 0px !important;
}

.mb-1{
  margin-bottom: 0.25rem !important;
}

.mb-2{
  margin-bottom: 0.5rem !important;
}

.mb-3{
  margin-bottom: 0.75rem !important;
}

.mb-4{
  margin-bottom: 1rem !important;
}

.mb-5{
  margin-bottom: 1.25rem !important;
}

.mb-6{
  margin-bottom: 1.5rem !important;
}

.mb-7{
  margin-bottom: 1.75rem !important;
}

.mb-8{
  margin-bottom: 2rem !important;
}

.mb-9{
  margin-bottom: 2.25rem !important;
}

.mb-10{
  margin-bottom: 2.5rem !important;
}

.mb-11{
  margin-bottom: 2.75rem !important;
}

.mb-12{
  margin-bottom: 3rem !important;
}

.mb-14{
  margin-bottom: 3.5rem !important;
}

.mb-16{
  margin-bottom: 4rem !important;
}

.mb-20{
  margin-bottom: 5rem !important;
}

.mb-24{
  margin-bottom: 6rem !important;
}

.mb-28{
  margin-bottom: 7rem !important;
}

.mb-32{
  margin-bottom: 8rem !important;
}

.mb-36{
  margin-bottom: 9rem !important;
}

.mb-40{
  margin-bottom: 10rem !important;
}

.mb-44{
  margin-bottom: 11rem !important;
}

.mb-48{
  margin-bottom: 12rem !important;
}

.mb-52{
  margin-bottom: 13rem !important;
}

.mb-56{
  margin-bottom: 14rem !important;
}

.mb-60{
  margin-bottom: 15rem !important;
}

.mb-64{
  margin-bottom: 16rem !important;
}

.mb-72{
  margin-bottom: 18rem !important;
}

.mb-80{
  margin-bottom: 20rem !important;
}

.mb-96{
  margin-bottom: 24rem !important;
}

.mb-auto{
  margin-bottom: auto !important;
}

.mb-px{
  margin-bottom: 1px !important;
}

.mb-0\.5{
  margin-bottom: 0.125rem !important;
}

.mb-1\.5{
  margin-bottom: 0.375rem !important;
}

.mb-2\.5{
  margin-bottom: 0.625rem !important;
}

.mb-3\.5{
  margin-bottom: 0.875rem !important;
}

.-mb-0{
  margin-bottom: 0px !important;
}

.-mb-1{
  margin-bottom: -0.25rem !important;
}

.-mb-2{
  margin-bottom: -0.5rem !important;
}

.-mb-3{
  margin-bottom: -0.75rem !important;
}

.-mb-4{
  margin-bottom: -1rem !important;
}

.-mb-5{
  margin-bottom: -1.25rem !important;
}

.-mb-6{
  margin-bottom: -1.5rem !important;
}

.-mb-7{
  margin-bottom: -1.75rem !important;
}

.-mb-8{
  margin-bottom: -2rem !important;
}

.-mb-9{
  margin-bottom: -2.25rem !important;
}

.-mb-10{
  margin-bottom: -2.5rem !important;
}

.-mb-11{
  margin-bottom: -2.75rem !important;
}

.-mb-12{
  margin-bottom: -3rem !important;
}

.-mb-14{
  margin-bottom: -3.5rem !important;
}

.-mb-16{
  margin-bottom: -4rem !important;
}

.-mb-20{
  margin-bottom: -5rem !important;
}

.-mb-24{
  margin-bottom: -6rem !important;
}

.-mb-28{
  margin-bottom: -7rem !important;
}

.-mb-32{
  margin-bottom: -8rem !important;
}

.-mb-36{
  margin-bottom: -9rem !important;
}

.-mb-40{
  margin-bottom: -10rem !important;
}

.-mb-44{
  margin-bottom: -11rem !important;
}

.-mb-48{
  margin-bottom: -12rem !important;
}

.-mb-52{
  margin-bottom: -13rem !important;
}

.-mb-56{
  margin-bottom: -14rem !important;
}

.-mb-60{
  margin-bottom: -15rem !important;
}

.-mb-64{
  margin-bottom: -16rem !important;
}

.-mb-72{
  margin-bottom: -18rem !important;
}

.-mb-80{
  margin-bottom: -20rem !important;
}

.-mb-96{
  margin-bottom: -24rem !important;
}

.-mb-px{
  margin-bottom: -1px !important;
}

.-mb-0\.5{
  margin-bottom: -0.125rem !important;
}

.-mb-1\.5{
  margin-bottom: -0.375rem !important;
}

.-mb-2\.5{
  margin-bottom: -0.625rem !important;
}

.-mb-3\.5{
  margin-bottom: -0.875rem !important;
}

.ml-0{
  margin-left: 0px !important;
}

.ml-1{
  margin-left: 0.25rem !important;
}

.ml-2{
  margin-left: 0.5rem !important;
}

.ml-3{
  margin-left: 0.75rem !important;
}

.ml-4{
  margin-left: 1rem !important;
}

.ml-5{
  margin-left: 1.25rem !important;
}

.ml-6{
  margin-left: 1.5rem !important;
}

.ml-7{
  margin-left: 1.75rem !important;
}

.ml-8{
  margin-left: 2rem !important;
}

.ml-9{
  margin-left: 2.25rem !important;
}

.ml-10{
  margin-left: 2.5rem !important;
}

.ml-11{
  margin-left: 2.75rem !important;
}

.ml-12{
  margin-left: 3rem !important;
}

.ml-14{
  margin-left: 3.5rem !important;
}

.ml-16{
  margin-left: 4rem !important;
}

.ml-20{
  margin-left: 5rem !important;
}

.ml-24{
  margin-left: 6rem !important;
}

.ml-28{
  margin-left: 7rem !important;
}

.ml-32{
  margin-left: 8rem !important;
}

.ml-36{
  margin-left: 9rem !important;
}

.ml-40{
  margin-left: 10rem !important;
}

.ml-44{
  margin-left: 11rem !important;
}

.ml-48{
  margin-left: 12rem !important;
}

.ml-52{
  margin-left: 13rem !important;
}

.ml-56{
  margin-left: 14rem !important;
}

.ml-60{
  margin-left: 15rem !important;
}

.ml-64{
  margin-left: 16rem !important;
}

.ml-72{
  margin-left: 18rem !important;
}

.ml-80{
  margin-left: 20rem !important;
}

.ml-96{
  margin-left: 24rem !important;
}

.ml-auto{
  margin-left: auto !important;
}

.ml-px{
  margin-left: 1px !important;
}

.ml-0\.5{
  margin-left: 0.125rem !important;
}

.ml-1\.5{
  margin-left: 0.375rem !important;
}

.ml-2\.5{
  margin-left: 0.625rem !important;
}

.ml-3\.5{
  margin-left: 0.875rem !important;
}

.-ml-0{
  margin-left: 0px !important;
}

.-ml-1{
  margin-left: -0.25rem !important;
}

.-ml-2{
  margin-left: -0.5rem !important;
}

.-ml-3{
  margin-left: -0.75rem !important;
}

.-ml-4{
  margin-left: -1rem !important;
}

.-ml-5{
  margin-left: -1.25rem !important;
}

.-ml-6{
  margin-left: -1.5rem !important;
}

.-ml-7{
  margin-left: -1.75rem !important;
}

.-ml-8{
  margin-left: -2rem !important;
}

.-ml-9{
  margin-left: -2.25rem !important;
}

.-ml-10{
  margin-left: -2.5rem !important;
}

.-ml-11{
  margin-left: -2.75rem !important;
}

.-ml-12{
  margin-left: -3rem !important;
}

.-ml-14{
  margin-left: -3.5rem !important;
}

.-ml-16{
  margin-left: -4rem !important;
}

.-ml-20{
  margin-left: -5rem !important;
}

.-ml-24{
  margin-left: -6rem !important;
}

.-ml-28{
  margin-left: -7rem !important;
}

.-ml-32{
  margin-left: -8rem !important;
}

.-ml-36{
  margin-left: -9rem !important;
}

.-ml-40{
  margin-left: -10rem !important;
}

.-ml-44{
  margin-left: -11rem !important;
}

.-ml-48{
  margin-left: -12rem !important;
}

.-ml-52{
  margin-left: -13rem !important;
}

.-ml-56{
  margin-left: -14rem !important;
}

.-ml-60{
  margin-left: -15rem !important;
}

.-ml-64{
  margin-left: -16rem !important;
}

.-ml-72{
  margin-left: -18rem !important;
}

.-ml-80{
  margin-left: -20rem !important;
}

.-ml-96{
  margin-left: -24rem !important;
}

.-ml-px{
  margin-left: -1px !important;
}

.-ml-0\.5{
  margin-left: -0.125rem !important;
}

.-ml-1\.5{
  margin-left: -0.375rem !important;
}

.-ml-2\.5{
  margin-left: -0.625rem !important;
}

.-ml-3\.5{
  margin-left: -0.875rem !important;
}

.box-border{
  box-sizing: border-box !important;
}

.box-content{
  box-sizing: content-box !important;
}

.block{
  display: block !important;
}

.inline-block{
  display: inline-block !important;
}

.inline{
  display: inline !important;
}

.flex{
  display: flex !important;
}

.inline-flex{
  display: inline-flex !important;
}

.table{
  display: table !important;
}

.inline-table{
  display: inline-table !important;
}

.table-caption{
  display: table-caption !important;
}

.table-cell{
  display: table-cell !important;
}

.table-column{
  display: table-column !important;
}

.table-column-group{
  display: table-column-group !important;
}

.table-footer-group{
  display: table-footer-group !important;
}

.table-header-group{
  display: table-header-group !important;
}

.table-row-group{
  display: table-row-group !important;
}

.table-row{
  display: table-row !important;
}

.flow-root{
  display: flow-root !important;
}

.grid{
  display: grid !important;
}

.inline-grid{
  display: inline-grid !important;
}

.contents{
  display: contents !important;
}

.list-item{
  display: list-item !important;
}

.hidden{
  display: none !important;
}

.h-0{
  height: 0px !important;
}

.h-1{
  height: 0.25rem !important;
}

.h-2{
  height: 0.5rem !important;
}

.h-3{
  height: 0.75rem !important;
}

.h-4{
  height: 1rem !important;
}

.h-5{
  height: 1.25rem !important;
}

.h-6{
  height: 1.5rem !important;
}

.h-7{
  height: 1.75rem !important;
}

.h-8{
  height: 2rem !important;
}

.h-9{
  height: 2.25rem !important;
}

.h-10{
  height: 2.5rem !important;
}

.h-11{
  height: 2.75rem !important;
}

.h-12{
  height: 3rem !important;
}

.h-14{
  height: 3.5rem !important;
}

.h-16{
  height: 4rem !important;
}

.h-20{
  height: 5rem !important;
}

.h-24{
  height: 6rem !important;
}

.h-28{
  height: 7rem !important;
}

.h-32{
  height: 8rem !important;
}

.h-36{
  height: 9rem !important;
}

.h-40{
  height: 10rem !important;
}

.h-44{
  height: 11rem !important;
}

.h-48{
  height: 12rem !important;
}

.h-52{
  height: 13rem !important;
}

.h-56{
  height: 14rem !important;
}

.h-60{
  height: 15rem !important;
}

.h-64{
  height: 16rem !important;
}

.h-72{
  height: 18rem !important;
}

.h-80{
  height: 20rem !important;
}

.h-96{
  height: 24rem !important;
}

.h-auto{
  height: auto !important;
}

.h-px{
  height: 1px !important;
}

.h-0\.5{
  height: 0.125rem !important;
}

.h-1\.5{
  height: 0.375rem !important;
}

.h-2\.5{
  height: 0.625rem !important;
}

.h-3\.5{
  height: 0.875rem !important;
}

.h-1\/2{
  height: 50% !important;
}

.h-1\/3{
  height: 33.333333% !important;
}

.h-2\/3{
  height: 66.666667% !important;
}

.h-1\/4{
  height: 25% !important;
}

.h-2\/4{
  height: 50% !important;
}

.h-3\/4{
  height: 75% !important;
}

.h-1\/5{
  height: 20% !important;
}

.h-2\/5{
  height: 40% !important;
}

.h-3\/5{
  height: 60% !important;
}

.h-4\/5{
  height: 80% !important;
}

.h-1\/6{
  height: 16.666667% !important;
}

.h-2\/6{
  height: 33.333333% !important;
}

.h-3\/6{
  height: 50% !important;
}

.h-4\/6{
  height: 66.666667% !important;
}

.h-5\/6{
  height: 83.333333% !important;
}

.h-full{
  height: 100% !important;
}

.h-screen{
  height: 100vh !important;
}

.max-h-0{
  max-height: 0px !important;
}

.max-h-1{
  max-height: 0.25rem !important;
}

.max-h-2{
  max-height: 0.5rem !important;
}

.max-h-3{
  max-height: 0.75rem !important;
}

.max-h-4{
  max-height: 1rem !important;
}

.max-h-5{
  max-height: 1.25rem !important;
}

.max-h-6{
  max-height: 1.5rem !important;
}

.max-h-7{
  max-height: 1.75rem !important;
}

.max-h-8{
  max-height: 2rem !important;
}

.max-h-9{
  max-height: 2.25rem !important;
}

.max-h-10{
  max-height: 2.5rem !important;
}

.max-h-11{
  max-height: 2.75rem !important;
}

.max-h-12{
  max-height: 3rem !important;
}

.max-h-14{
  max-height: 3.5rem !important;
}

.max-h-16{
  max-height: 4rem !important;
}

.max-h-20{
  max-height: 5rem !important;
}

.max-h-24{
  max-height: 6rem !important;
}

.max-h-28{
  max-height: 7rem !important;
}

.max-h-32{
  max-height: 8rem !important;
}

.max-h-36{
  max-height: 9rem !important;
}

.max-h-40{
  max-height: 10rem !important;
}

.max-h-44{
  max-height: 11rem !important;
}

.max-h-48{
  max-height: 12rem !important;
}

.max-h-52{
  max-height: 13rem !important;
}

.max-h-56{
  max-height: 14rem !important;
}

.max-h-60{
  max-height: 15rem !important;
}

.max-h-64{
  max-height: 16rem !important;
}

.max-h-72{
  max-height: 18rem !important;
}

.max-h-80{
  max-height: 20rem !important;
}

.max-h-96{
  max-height: 24rem !important;
}

.max-h-px{
  max-height: 1px !important;
}

.max-h-0\.5{
  max-height: 0.125rem !important;
}

.max-h-1\.5{
  max-height: 0.375rem !important;
}

.max-h-2\.5{
  max-height: 0.625rem !important;
}

.max-h-3\.5{
  max-height: 0.875rem !important;
}

.max-h-full{
  max-height: 100% !important;
}

.max-h-screen{
  max-height: 100vh !important;
}

.min-h-0{
  min-height: 0px !important;
}

.min-h-full{
  min-height: 100% !important;
}

.min-h-screen{
  min-height: 100vh !important;
}

.w-0{
  width: 0px !important;
}

.w-1{
  width: 0.25rem !important;
}

.w-2{
  width: 0.5rem !important;
}

.w-3{
  width: 0.75rem !important;
}

.w-4{
  width: 1rem !important;
}

.w-5{
  width: 1.25rem !important;
}

.w-6{
  width: 1.5rem !important;
}

.w-7{
  width: 1.75rem !important;
}

.w-8{
  width: 2rem !important;
}

.w-9{
  width: 2.25rem !important;
}

.w-10{
  width: 2.5rem !important;
}

.w-11{
  width: 2.75rem !important;
}

.w-12{
  width: 3rem !important;
}

.w-14{
  width: 3.5rem !important;
}

.w-16{
  width: 4rem !important;
}

.w-20{
  width: 5rem !important;
}

.w-24{
  width: 6rem !important;
}

.w-28{
  width: 7rem !important;
}

.w-32{
  width: 8rem !important;
}

.w-36{
  width: 9rem !important;
}

.w-40{
  width: 10rem !important;
}

.w-44{
  width: 11rem !important;
}

.w-48{
  width: 12rem !important;
}

.w-52{
  width: 13rem !important;
}

.w-56{
  width: 14rem !important;
}

.w-60{
  width: 15rem !important;
}

.w-64{
  width: 16rem !important;
}

.w-72{
  width: 18rem !important;
}

.w-80{
  width: 20rem !important;
}

.w-96{
  width: 24rem !important;
}

.w-auto{
  width: auto !important;
}

.w-px{
  width: 1px !important;
}

.w-0\.5{
  width: 0.125rem !important;
}

.w-1\.5{
  width: 0.375rem !important;
}

.w-2\.5{
  width: 0.625rem !important;
}

.w-3\.5{
  width: 0.875rem !important;
}

.w-1\/2{
  width: 50% !important;
}

.w-1\/3{
  width: 33.333333% !important;
}

.w-2\/3{
  width: 66.666667% !important;
}

.w-1\/4{
  width: 25% !important;
}

.w-2\/4{
  width: 50% !important;
}

.w-3\/4{
  width: 75% !important;
}

.w-1\/5{
  width: 20% !important;
}

.w-2\/5{
  width: 40% !important;
}

.w-3\/5{
  width: 60% !important;
}

.w-4\/5{
  width: 80% !important;
}

.w-1\/6{
  width: 16.666667% !important;
}

.w-2\/6{
  width: 33.333333% !important;
}

.w-3\/6{
  width: 50% !important;
}

.w-4\/6{
  width: 66.666667% !important;
}

.w-5\/6{
  width: 83.333333% !important;
}

.w-1\/12{
  width: 8.333333% !important;
}

.w-2\/12{
  width: 16.666667% !important;
}

.w-3\/12{
  width: 25% !important;
}

.w-4\/12{
  width: 33.333333% !important;
}

.w-5\/12{
  width: 41.666667% !important;
}

.w-6\/12{
  width: 50% !important;
}

.w-7\/12{
  width: 58.333333% !important;
}

.w-8\/12{
  width: 66.666667% !important;
}

.w-9\/12{
  width: 75% !important;
}

.w-10\/12{
  width: 83.333333% !important;
}

.w-11\/12{
  width: 91.666667% !important;
}

.w-full{
  width: 100% !important;
}

.w-screen{
  width: 100vw !important;
}

.w-min{
  width: -webkit-min-content !important;
  width: -moz-min-content !important;
  width: min-content !important;
}

.w-max{
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}

.min-w-0{
  min-width: 0px !important;
}

.min-w-full{
  min-width: 100% !important;
}

.min-w-min{
  min-width: -webkit-min-content !important;
  min-width: -moz-min-content !important;
  min-width: min-content !important;
}

.min-w-max{
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
}

.max-w-0{
  max-width: 0rem !important;
}

.max-w-none{
  max-width: none !important;
}

.max-w-xs{
  max-width: 20rem !important;
}

.max-w-sm{
  max-width: 24rem !important;
}

.max-w-md{
  max-width: 28rem !important;
}

.max-w-lg{
  max-width: 32rem !important;
}

.max-w-xl{
  max-width: 36rem !important;
}

.max-w-2xl{
  max-width: 42rem !important;
}

.max-w-3xl{
  max-width: 48rem !important;
}

.max-w-4xl{
  max-width: 56rem !important;
}

.max-w-5xl{
  max-width: 64rem !important;
}

.max-w-6xl{
  max-width: 72rem !important;
}

.max-w-7xl{
  max-width: 80rem !important;
}

.max-w-full{
  max-width: 100% !important;
}

.max-w-min{
  max-width: -webkit-min-content !important;
  max-width: -moz-min-content !important;
  max-width: min-content !important;
}

.max-w-max{
  max-width: -webkit-max-content !important;
  max-width: -moz-max-content !important;
  max-width: max-content !important;
}

.max-w-prose{
  max-width: 65ch !important;
}

.max-w-screen-sm{
  max-width: 640px !important;
}

.max-w-screen-md{
  max-width: 768px !important;
}

.max-w-screen-lg{
  max-width: 1024px !important;
}

.max-w-screen-xl{
  max-width: 1280px !important;
}

.max-w-screen-2xl{
  max-width: 1536px !important;
}

.flex-1{
  flex: 1 1 0% !important;
}

.flex-auto{
  flex: 1 1 auto !important;
}

.flex-initial{
  flex: 0 1 auto !important;
}

.flex-none{
  flex: none !important;
}

.flex-shrink-0{
  flex-shrink: 0 !important;
}

.flex-shrink{
  flex-shrink: 1 !important;
}

.flex-grow-0{
  flex-grow: 0 !important;
}

.flex-grow{
  flex-grow: 1 !important;
}

.table-auto{
  table-layout: auto !important;
}

.table-fixed{
  table-layout: fixed !important;
}

.border-collapse{
  border-collapse: collapse !important;
}

.border-separate{
  border-collapse: separate !important;
}

.origin-center{
  transform-origin: center !important;
}

.origin-top{
  transform-origin: top !important;
}

.origin-top-right{
  transform-origin: top right !important;
}

.origin-right{
  transform-origin: right !important;
}

.origin-bottom-right{
  transform-origin: bottom right !important;
}

.origin-bottom{
  transform-origin: bottom !important;
}

.origin-bottom-left{
  transform-origin: bottom left !important;
}

.origin-left{
  transform-origin: left !important;
}

.origin-top-left{
  transform-origin: top left !important;
}

.transform{
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform-gpu{
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform-none{
  transform: none !important;
}

.translate-x-0{
  --tw-translate-x: 0px !important;
}

.translate-x-1{
  --tw-translate-x: 0.25rem !important;
}

.translate-x-2{
  --tw-translate-x: 0.5rem !important;
}

.translate-x-3{
  --tw-translate-x: 0.75rem !important;
}

.translate-x-4{
  --tw-translate-x: 1rem !important;
}

.translate-x-5{
  --tw-translate-x: 1.25rem !important;
}

.translate-x-6{
  --tw-translate-x: 1.5rem !important;
}

.translate-x-7{
  --tw-translate-x: 1.75rem !important;
}

.translate-x-8{
  --tw-translate-x: 2rem !important;
}

.translate-x-9{
  --tw-translate-x: 2.25rem !important;
}

.translate-x-10{
  --tw-translate-x: 2.5rem !important;
}

.translate-x-11{
  --tw-translate-x: 2.75rem !important;
}

.translate-x-12{
  --tw-translate-x: 3rem !important;
}

.translate-x-14{
  --tw-translate-x: 3.5rem !important;
}

.translate-x-16{
  --tw-translate-x: 4rem !important;
}

.translate-x-20{
  --tw-translate-x: 5rem !important;
}

.translate-x-24{
  --tw-translate-x: 6rem !important;
}

.translate-x-28{
  --tw-translate-x: 7rem !important;
}

.translate-x-32{
  --tw-translate-x: 8rem !important;
}

.translate-x-36{
  --tw-translate-x: 9rem !important;
}

.translate-x-40{
  --tw-translate-x: 10rem !important;
}

.translate-x-44{
  --tw-translate-x: 11rem !important;
}

.translate-x-48{
  --tw-translate-x: 12rem !important;
}

.translate-x-52{
  --tw-translate-x: 13rem !important;
}

.translate-x-56{
  --tw-translate-x: 14rem !important;
}

.translate-x-60{
  --tw-translate-x: 15rem !important;
}

.translate-x-64{
  --tw-translate-x: 16rem !important;
}

.translate-x-72{
  --tw-translate-x: 18rem !important;
}

.translate-x-80{
  --tw-translate-x: 20rem !important;
}

.translate-x-96{
  --tw-translate-x: 24rem !important;
}

.translate-x-px{
  --tw-translate-x: 1px !important;
}

.translate-x-0\.5{
  --tw-translate-x: 0.125rem !important;
}

.translate-x-1\.5{
  --tw-translate-x: 0.375rem !important;
}

.translate-x-2\.5{
  --tw-translate-x: 0.625rem !important;
}

.translate-x-3\.5{
  --tw-translate-x: 0.875rem !important;
}

.-translate-x-0{
  --tw-translate-x: 0px !important;
}

.-translate-x-1{
  --tw-translate-x: -0.25rem !important;
}

.-translate-x-2{
  --tw-translate-x: -0.5rem !important;
}

.-translate-x-3{
  --tw-translate-x: -0.75rem !important;
}

.-translate-x-4{
  --tw-translate-x: -1rem !important;
}

.-translate-x-5{
  --tw-translate-x: -1.25rem !important;
}

.-translate-x-6{
  --tw-translate-x: -1.5rem !important;
}

.-translate-x-7{
  --tw-translate-x: -1.75rem !important;
}

.-translate-x-8{
  --tw-translate-x: -2rem !important;
}

.-translate-x-9{
  --tw-translate-x: -2.25rem !important;
}

.-translate-x-10{
  --tw-translate-x: -2.5rem !important;
}

.-translate-x-11{
  --tw-translate-x: -2.75rem !important;
}

.-translate-x-12{
  --tw-translate-x: -3rem !important;
}

.-translate-x-14{
  --tw-translate-x: -3.5rem !important;
}

.-translate-x-16{
  --tw-translate-x: -4rem !important;
}

.-translate-x-20{
  --tw-translate-x: -5rem !important;
}

.-translate-x-24{
  --tw-translate-x: -6rem !important;
}

.-translate-x-28{
  --tw-translate-x: -7rem !important;
}

.-translate-x-32{
  --tw-translate-x: -8rem !important;
}

.-translate-x-36{
  --tw-translate-x: -9rem !important;
}

.-translate-x-40{
  --tw-translate-x: -10rem !important;
}

.-translate-x-44{
  --tw-translate-x: -11rem !important;
}

.-translate-x-48{
  --tw-translate-x: -12rem !important;
}

.-translate-x-52{
  --tw-translate-x: -13rem !important;
}

.-translate-x-56{
  --tw-translate-x: -14rem !important;
}

.-translate-x-60{
  --tw-translate-x: -15rem !important;
}

.-translate-x-64{
  --tw-translate-x: -16rem !important;
}

.-translate-x-72{
  --tw-translate-x: -18rem !important;
}

.-translate-x-80{
  --tw-translate-x: -20rem !important;
}

.-translate-x-96{
  --tw-translate-x: -24rem !important;
}

.-translate-x-px{
  --tw-translate-x: -1px !important;
}

.-translate-x-0\.5{
  --tw-translate-x: -0.125rem !important;
}

.-translate-x-1\.5{
  --tw-translate-x: -0.375rem !important;
}

.-translate-x-2\.5{
  --tw-translate-x: -0.625rem !important;
}

.-translate-x-3\.5{
  --tw-translate-x: -0.875rem !important;
}

.translate-x-1\/2{
  --tw-translate-x: 50% !important;
}

.translate-x-1\/3{
  --tw-translate-x: 33.333333% !important;
}

.translate-x-2\/3{
  --tw-translate-x: 66.666667% !important;
}

.translate-x-1\/4{
  --tw-translate-x: 25% !important;
}

.translate-x-2\/4{
  --tw-translate-x: 50% !important;
}

.translate-x-3\/4{
  --tw-translate-x: 75% !important;
}

.translate-x-full{
  --tw-translate-x: 100% !important;
}

.-translate-x-1\/2{
  --tw-translate-x: -50% !important;
}

.-translate-x-1\/3{
  --tw-translate-x: -33.333333% !important;
}

.-translate-x-2\/3{
  --tw-translate-x: -66.666667% !important;
}

.-translate-x-1\/4{
  --tw-translate-x: -25% !important;
}

.-translate-x-2\/4{
  --tw-translate-x: -50% !important;
}

.-translate-x-3\/4{
  --tw-translate-x: -75% !important;
}

.-translate-x-full{
  --tw-translate-x: -100% !important;
}

.translate-y-0{
  --tw-translate-y: 0px !important;
}

.translate-y-1{
  --tw-translate-y: 0.25rem !important;
}

.translate-y-2{
  --tw-translate-y: 0.5rem !important;
}

.translate-y-3{
  --tw-translate-y: 0.75rem !important;
}

.translate-y-4{
  --tw-translate-y: 1rem !important;
}

.translate-y-5{
  --tw-translate-y: 1.25rem !important;
}

.translate-y-6{
  --tw-translate-y: 1.5rem !important;
}

.translate-y-7{
  --tw-translate-y: 1.75rem !important;
}

.translate-y-8{
  --tw-translate-y: 2rem !important;
}

.translate-y-9{
  --tw-translate-y: 2.25rem !important;
}

.translate-y-10{
  --tw-translate-y: 2.5rem !important;
}

.translate-y-11{
  --tw-translate-y: 2.75rem !important;
}

.translate-y-12{
  --tw-translate-y: 3rem !important;
}

.translate-y-14{
  --tw-translate-y: 3.5rem !important;
}

.translate-y-16{
  --tw-translate-y: 4rem !important;
}

.translate-y-20{
  --tw-translate-y: 5rem !important;
}

.translate-y-24{
  --tw-translate-y: 6rem !important;
}

.translate-y-28{
  --tw-translate-y: 7rem !important;
}

.translate-y-32{
  --tw-translate-y: 8rem !important;
}

.translate-y-36{
  --tw-translate-y: 9rem !important;
}

.translate-y-40{
  --tw-translate-y: 10rem !important;
}

.translate-y-44{
  --tw-translate-y: 11rem !important;
}

.translate-y-48{
  --tw-translate-y: 12rem !important;
}

.translate-y-52{
  --tw-translate-y: 13rem !important;
}

.translate-y-56{
  --tw-translate-y: 14rem !important;
}

.translate-y-60{
  --tw-translate-y: 15rem !important;
}

.translate-y-64{
  --tw-translate-y: 16rem !important;
}

.translate-y-72{
  --tw-translate-y: 18rem !important;
}

.translate-y-80{
  --tw-translate-y: 20rem !important;
}

.translate-y-96{
  --tw-translate-y: 24rem !important;
}

.translate-y-px{
  --tw-translate-y: 1px !important;
}

.translate-y-0\.5{
  --tw-translate-y: 0.125rem !important;
}

.translate-y-1\.5{
  --tw-translate-y: 0.375rem !important;
}

.translate-y-2\.5{
  --tw-translate-y: 0.625rem !important;
}

.translate-y-3\.5{
  --tw-translate-y: 0.875rem !important;
}

.-translate-y-0{
  --tw-translate-y: 0px !important;
}

.-translate-y-1{
  --tw-translate-y: -0.25rem !important;
}

.-translate-y-2{
  --tw-translate-y: -0.5rem !important;
}

.-translate-y-3{
  --tw-translate-y: -0.75rem !important;
}

.-translate-y-4{
  --tw-translate-y: -1rem !important;
}

.-translate-y-5{
  --tw-translate-y: -1.25rem !important;
}

.-translate-y-6{
  --tw-translate-y: -1.5rem !important;
}

.-translate-y-7{
  --tw-translate-y: -1.75rem !important;
}

.-translate-y-8{
  --tw-translate-y: -2rem !important;
}

.-translate-y-9{
  --tw-translate-y: -2.25rem !important;
}

.-translate-y-10{
  --tw-translate-y: -2.5rem !important;
}

.-translate-y-11{
  --tw-translate-y: -2.75rem !important;
}

.-translate-y-12{
  --tw-translate-y: -3rem !important;
}

.-translate-y-14{
  --tw-translate-y: -3.5rem !important;
}

.-translate-y-16{
  --tw-translate-y: -4rem !important;
}

.-translate-y-20{
  --tw-translate-y: -5rem !important;
}

.-translate-y-24{
  --tw-translate-y: -6rem !important;
}

.-translate-y-28{
  --tw-translate-y: -7rem !important;
}

.-translate-y-32{
  --tw-translate-y: -8rem !important;
}

.-translate-y-36{
  --tw-translate-y: -9rem !important;
}

.-translate-y-40{
  --tw-translate-y: -10rem !important;
}

.-translate-y-44{
  --tw-translate-y: -11rem !important;
}

.-translate-y-48{
  --tw-translate-y: -12rem !important;
}

.-translate-y-52{
  --tw-translate-y: -13rem !important;
}

.-translate-y-56{
  --tw-translate-y: -14rem !important;
}

.-translate-y-60{
  --tw-translate-y: -15rem !important;
}

.-translate-y-64{
  --tw-translate-y: -16rem !important;
}

.-translate-y-72{
  --tw-translate-y: -18rem !important;
}

.-translate-y-80{
  --tw-translate-y: -20rem !important;
}

.-translate-y-96{
  --tw-translate-y: -24rem !important;
}

.-translate-y-px{
  --tw-translate-y: -1px !important;
}

.-translate-y-0\.5{
  --tw-translate-y: -0.125rem !important;
}

.-translate-y-1\.5{
  --tw-translate-y: -0.375rem !important;
}

.-translate-y-2\.5{
  --tw-translate-y: -0.625rem !important;
}

.-translate-y-3\.5{
  --tw-translate-y: -0.875rem !important;
}

.translate-y-1\/2{
  --tw-translate-y: 50% !important;
}

.translate-y-1\/3{
  --tw-translate-y: 33.333333% !important;
}

.translate-y-2\/3{
  --tw-translate-y: 66.666667% !important;
}

.translate-y-1\/4{
  --tw-translate-y: 25% !important;
}

.translate-y-2\/4{
  --tw-translate-y: 50% !important;
}

.translate-y-3\/4{
  --tw-translate-y: 75% !important;
}

.translate-y-full{
  --tw-translate-y: 100% !important;
}

.-translate-y-1\/2{
  --tw-translate-y: -50% !important;
}

.-translate-y-1\/3{
  --tw-translate-y: -33.333333% !important;
}

.-translate-y-2\/3{
  --tw-translate-y: -66.666667% !important;
}

.-translate-y-1\/4{
  --tw-translate-y: -25% !important;
}

.-translate-y-2\/4{
  --tw-translate-y: -50% !important;
}

.-translate-y-3\/4{
  --tw-translate-y: -75% !important;
}

.-translate-y-full{
  --tw-translate-y: -100% !important;
}

.hover\:translate-x-0:hover{
  --tw-translate-x: 0px !important;
}

.hover\:translate-x-1:hover{
  --tw-translate-x: 0.25rem !important;
}

.hover\:translate-x-2:hover{
  --tw-translate-x: 0.5rem !important;
}

.hover\:translate-x-3:hover{
  --tw-translate-x: 0.75rem !important;
}

.hover\:translate-x-4:hover{
  --tw-translate-x: 1rem !important;
}

.hover\:translate-x-5:hover{
  --tw-translate-x: 1.25rem !important;
}

.hover\:translate-x-6:hover{
  --tw-translate-x: 1.5rem !important;
}

.hover\:translate-x-7:hover{
  --tw-translate-x: 1.75rem !important;
}

.hover\:translate-x-8:hover{
  --tw-translate-x: 2rem !important;
}

.hover\:translate-x-9:hover{
  --tw-translate-x: 2.25rem !important;
}

.hover\:translate-x-10:hover{
  --tw-translate-x: 2.5rem !important;
}

.hover\:translate-x-11:hover{
  --tw-translate-x: 2.75rem !important;
}

.hover\:translate-x-12:hover{
  --tw-translate-x: 3rem !important;
}

.hover\:translate-x-14:hover{
  --tw-translate-x: 3.5rem !important;
}

.hover\:translate-x-16:hover{
  --tw-translate-x: 4rem !important;
}

.hover\:translate-x-20:hover{
  --tw-translate-x: 5rem !important;
}

.hover\:translate-x-24:hover{
  --tw-translate-x: 6rem !important;
}

.hover\:translate-x-28:hover{
  --tw-translate-x: 7rem !important;
}

.hover\:translate-x-32:hover{
  --tw-translate-x: 8rem !important;
}

.hover\:translate-x-36:hover{
  --tw-translate-x: 9rem !important;
}

.hover\:translate-x-40:hover{
  --tw-translate-x: 10rem !important;
}

.hover\:translate-x-44:hover{
  --tw-translate-x: 11rem !important;
}

.hover\:translate-x-48:hover{
  --tw-translate-x: 12rem !important;
}

.hover\:translate-x-52:hover{
  --tw-translate-x: 13rem !important;
}

.hover\:translate-x-56:hover{
  --tw-translate-x: 14rem !important;
}

.hover\:translate-x-60:hover{
  --tw-translate-x: 15rem !important;
}

.hover\:translate-x-64:hover{
  --tw-translate-x: 16rem !important;
}

.hover\:translate-x-72:hover{
  --tw-translate-x: 18rem !important;
}

.hover\:translate-x-80:hover{
  --tw-translate-x: 20rem !important;
}

.hover\:translate-x-96:hover{
  --tw-translate-x: 24rem !important;
}

.hover\:translate-x-px:hover{
  --tw-translate-x: 1px !important;
}

.hover\:translate-x-0\.5:hover{
  --tw-translate-x: 0.125rem !important;
}

.hover\:translate-x-1\.5:hover{
  --tw-translate-x: 0.375rem !important;
}

.hover\:translate-x-2\.5:hover{
  --tw-translate-x: 0.625rem !important;
}

.hover\:translate-x-3\.5:hover{
  --tw-translate-x: 0.875rem !important;
}

.hover\:-translate-x-0:hover{
  --tw-translate-x: 0px !important;
}

.hover\:-translate-x-1:hover{
  --tw-translate-x: -0.25rem !important;
}

.hover\:-translate-x-2:hover{
  --tw-translate-x: -0.5rem !important;
}

.hover\:-translate-x-3:hover{
  --tw-translate-x: -0.75rem !important;
}

.hover\:-translate-x-4:hover{
  --tw-translate-x: -1rem !important;
}

.hover\:-translate-x-5:hover{
  --tw-translate-x: -1.25rem !important;
}

.hover\:-translate-x-6:hover{
  --tw-translate-x: -1.5rem !important;
}

.hover\:-translate-x-7:hover{
  --tw-translate-x: -1.75rem !important;
}

.hover\:-translate-x-8:hover{
  --tw-translate-x: -2rem !important;
}

.hover\:-translate-x-9:hover{
  --tw-translate-x: -2.25rem !important;
}

.hover\:-translate-x-10:hover{
  --tw-translate-x: -2.5rem !important;
}

.hover\:-translate-x-11:hover{
  --tw-translate-x: -2.75rem !important;
}

.hover\:-translate-x-12:hover{
  --tw-translate-x: -3rem !important;
}

.hover\:-translate-x-14:hover{
  --tw-translate-x: -3.5rem !important;
}

.hover\:-translate-x-16:hover{
  --tw-translate-x: -4rem !important;
}

.hover\:-translate-x-20:hover{
  --tw-translate-x: -5rem !important;
}

.hover\:-translate-x-24:hover{
  --tw-translate-x: -6rem !important;
}

.hover\:-translate-x-28:hover{
  --tw-translate-x: -7rem !important;
}

.hover\:-translate-x-32:hover{
  --tw-translate-x: -8rem !important;
}

.hover\:-translate-x-36:hover{
  --tw-translate-x: -9rem !important;
}

.hover\:-translate-x-40:hover{
  --tw-translate-x: -10rem !important;
}

.hover\:-translate-x-44:hover{
  --tw-translate-x: -11rem !important;
}

.hover\:-translate-x-48:hover{
  --tw-translate-x: -12rem !important;
}

.hover\:-translate-x-52:hover{
  --tw-translate-x: -13rem !important;
}

.hover\:-translate-x-56:hover{
  --tw-translate-x: -14rem !important;
}

.hover\:-translate-x-60:hover{
  --tw-translate-x: -15rem !important;
}

.hover\:-translate-x-64:hover{
  --tw-translate-x: -16rem !important;
}

.hover\:-translate-x-72:hover{
  --tw-translate-x: -18rem !important;
}

.hover\:-translate-x-80:hover{
  --tw-translate-x: -20rem !important;
}

.hover\:-translate-x-96:hover{
  --tw-translate-x: -24rem !important;
}

.hover\:-translate-x-px:hover{
  --tw-translate-x: -1px !important;
}

.hover\:-translate-x-0\.5:hover{
  --tw-translate-x: -0.125rem !important;
}

.hover\:-translate-x-1\.5:hover{
  --tw-translate-x: -0.375rem !important;
}

.hover\:-translate-x-2\.5:hover{
  --tw-translate-x: -0.625rem !important;
}

.hover\:-translate-x-3\.5:hover{
  --tw-translate-x: -0.875rem !important;
}

.hover\:translate-x-1\/2:hover{
  --tw-translate-x: 50% !important;
}

.hover\:translate-x-1\/3:hover{
  --tw-translate-x: 33.333333% !important;
}

.hover\:translate-x-2\/3:hover{
  --tw-translate-x: 66.666667% !important;
}

.hover\:translate-x-1\/4:hover{
  --tw-translate-x: 25% !important;
}

.hover\:translate-x-2\/4:hover{
  --tw-translate-x: 50% !important;
}

.hover\:translate-x-3\/4:hover{
  --tw-translate-x: 75% !important;
}

.hover\:translate-x-full:hover{
  --tw-translate-x: 100% !important;
}

.hover\:-translate-x-1\/2:hover{
  --tw-translate-x: -50% !important;
}

.hover\:-translate-x-1\/3:hover{
  --tw-translate-x: -33.333333% !important;
}

.hover\:-translate-x-2\/3:hover{
  --tw-translate-x: -66.666667% !important;
}

.hover\:-translate-x-1\/4:hover{
  --tw-translate-x: -25% !important;
}

.hover\:-translate-x-2\/4:hover{
  --tw-translate-x: -50% !important;
}

.hover\:-translate-x-3\/4:hover{
  --tw-translate-x: -75% !important;
}

.hover\:-translate-x-full:hover{
  --tw-translate-x: -100% !important;
}

.hover\:translate-y-0:hover{
  --tw-translate-y: 0px !important;
}

.hover\:translate-y-1:hover{
  --tw-translate-y: 0.25rem !important;
}

.hover\:translate-y-2:hover{
  --tw-translate-y: 0.5rem !important;
}

.hover\:translate-y-3:hover{
  --tw-translate-y: 0.75rem !important;
}

.hover\:translate-y-4:hover{
  --tw-translate-y: 1rem !important;
}

.hover\:translate-y-5:hover{
  --tw-translate-y: 1.25rem !important;
}

.hover\:translate-y-6:hover{
  --tw-translate-y: 1.5rem !important;
}

.hover\:translate-y-7:hover{
  --tw-translate-y: 1.75rem !important;
}

.hover\:translate-y-8:hover{
  --tw-translate-y: 2rem !important;
}

.hover\:translate-y-9:hover{
  --tw-translate-y: 2.25rem !important;
}

.hover\:translate-y-10:hover{
  --tw-translate-y: 2.5rem !important;
}

.hover\:translate-y-11:hover{
  --tw-translate-y: 2.75rem !important;
}

.hover\:translate-y-12:hover{
  --tw-translate-y: 3rem !important;
}

.hover\:translate-y-14:hover{
  --tw-translate-y: 3.5rem !important;
}

.hover\:translate-y-16:hover{
  --tw-translate-y: 4rem !important;
}

.hover\:translate-y-20:hover{
  --tw-translate-y: 5rem !important;
}

.hover\:translate-y-24:hover{
  --tw-translate-y: 6rem !important;
}

.hover\:translate-y-28:hover{
  --tw-translate-y: 7rem !important;
}

.hover\:translate-y-32:hover{
  --tw-translate-y: 8rem !important;
}

.hover\:translate-y-36:hover{
  --tw-translate-y: 9rem !important;
}

.hover\:translate-y-40:hover{
  --tw-translate-y: 10rem !important;
}

.hover\:translate-y-44:hover{
  --tw-translate-y: 11rem !important;
}

.hover\:translate-y-48:hover{
  --tw-translate-y: 12rem !important;
}

.hover\:translate-y-52:hover{
  --tw-translate-y: 13rem !important;
}

.hover\:translate-y-56:hover{
  --tw-translate-y: 14rem !important;
}

.hover\:translate-y-60:hover{
  --tw-translate-y: 15rem !important;
}

.hover\:translate-y-64:hover{
  --tw-translate-y: 16rem !important;
}

.hover\:translate-y-72:hover{
  --tw-translate-y: 18rem !important;
}

.hover\:translate-y-80:hover{
  --tw-translate-y: 20rem !important;
}

.hover\:translate-y-96:hover{
  --tw-translate-y: 24rem !important;
}

.hover\:translate-y-px:hover{
  --tw-translate-y: 1px !important;
}

.hover\:translate-y-0\.5:hover{
  --tw-translate-y: 0.125rem !important;
}

.hover\:translate-y-1\.5:hover{
  --tw-translate-y: 0.375rem !important;
}

.hover\:translate-y-2\.5:hover{
  --tw-translate-y: 0.625rem !important;
}

.hover\:translate-y-3\.5:hover{
  --tw-translate-y: 0.875rem !important;
}

.hover\:-translate-y-0:hover{
  --tw-translate-y: 0px !important;
}

.hover\:-translate-y-1:hover{
  --tw-translate-y: -0.25rem !important;
}

.hover\:-translate-y-2:hover{
  --tw-translate-y: -0.5rem !important;
}

.hover\:-translate-y-3:hover{
  --tw-translate-y: -0.75rem !important;
}

.hover\:-translate-y-4:hover{
  --tw-translate-y: -1rem !important;
}

.hover\:-translate-y-5:hover{
  --tw-translate-y: -1.25rem !important;
}

.hover\:-translate-y-6:hover{
  --tw-translate-y: -1.5rem !important;
}

.hover\:-translate-y-7:hover{
  --tw-translate-y: -1.75rem !important;
}

.hover\:-translate-y-8:hover{
  --tw-translate-y: -2rem !important;
}

.hover\:-translate-y-9:hover{
  --tw-translate-y: -2.25rem !important;
}

.hover\:-translate-y-10:hover{
  --tw-translate-y: -2.5rem !important;
}

.hover\:-translate-y-11:hover{
  --tw-translate-y: -2.75rem !important;
}

.hover\:-translate-y-12:hover{
  --tw-translate-y: -3rem !important;
}

.hover\:-translate-y-14:hover{
  --tw-translate-y: -3.5rem !important;
}

.hover\:-translate-y-16:hover{
  --tw-translate-y: -4rem !important;
}

.hover\:-translate-y-20:hover{
  --tw-translate-y: -5rem !important;
}

.hover\:-translate-y-24:hover{
  --tw-translate-y: -6rem !important;
}

.hover\:-translate-y-28:hover{
  --tw-translate-y: -7rem !important;
}

.hover\:-translate-y-32:hover{
  --tw-translate-y: -8rem !important;
}

.hover\:-translate-y-36:hover{
  --tw-translate-y: -9rem !important;
}

.hover\:-translate-y-40:hover{
  --tw-translate-y: -10rem !important;
}

.hover\:-translate-y-44:hover{
  --tw-translate-y: -11rem !important;
}

.hover\:-translate-y-48:hover{
  --tw-translate-y: -12rem !important;
}

.hover\:-translate-y-52:hover{
  --tw-translate-y: -13rem !important;
}

.hover\:-translate-y-56:hover{
  --tw-translate-y: -14rem !important;
}

.hover\:-translate-y-60:hover{
  --tw-translate-y: -15rem !important;
}

.hover\:-translate-y-64:hover{
  --tw-translate-y: -16rem !important;
}

.hover\:-translate-y-72:hover{
  --tw-translate-y: -18rem !important;
}

.hover\:-translate-y-80:hover{
  --tw-translate-y: -20rem !important;
}

.hover\:-translate-y-96:hover{
  --tw-translate-y: -24rem !important;
}

.hover\:-translate-y-px:hover{
  --tw-translate-y: -1px !important;
}

.hover\:-translate-y-0\.5:hover{
  --tw-translate-y: -0.125rem !important;
}

.hover\:-translate-y-1\.5:hover{
  --tw-translate-y: -0.375rem !important;
}

.hover\:-translate-y-2\.5:hover{
  --tw-translate-y: -0.625rem !important;
}

.hover\:-translate-y-3\.5:hover{
  --tw-translate-y: -0.875rem !important;
}

.hover\:translate-y-1\/2:hover{
  --tw-translate-y: 50% !important;
}

.hover\:translate-y-1\/3:hover{
  --tw-translate-y: 33.333333% !important;
}

.hover\:translate-y-2\/3:hover{
  --tw-translate-y: 66.666667% !important;
}

.hover\:translate-y-1\/4:hover{
  --tw-translate-y: 25% !important;
}

.hover\:translate-y-2\/4:hover{
  --tw-translate-y: 50% !important;
}

.hover\:translate-y-3\/4:hover{
  --tw-translate-y: 75% !important;
}

.hover\:translate-y-full:hover{
  --tw-translate-y: 100% !important;
}

.hover\:-translate-y-1\/2:hover{
  --tw-translate-y: -50% !important;
}

.hover\:-translate-y-1\/3:hover{
  --tw-translate-y: -33.333333% !important;
}

.hover\:-translate-y-2\/3:hover{
  --tw-translate-y: -66.666667% !important;
}

.hover\:-translate-y-1\/4:hover{
  --tw-translate-y: -25% !important;
}

.hover\:-translate-y-2\/4:hover{
  --tw-translate-y: -50% !important;
}

.hover\:-translate-y-3\/4:hover{
  --tw-translate-y: -75% !important;
}

.hover\:-translate-y-full:hover{
  --tw-translate-y: -100% !important;
}

.focus\:translate-x-0:focus{
  --tw-translate-x: 0px !important;
}

.focus\:translate-x-1:focus{
  --tw-translate-x: 0.25rem !important;
}

.focus\:translate-x-2:focus{
  --tw-translate-x: 0.5rem !important;
}

.focus\:translate-x-3:focus{
  --tw-translate-x: 0.75rem !important;
}

.focus\:translate-x-4:focus{
  --tw-translate-x: 1rem !important;
}

.focus\:translate-x-5:focus{
  --tw-translate-x: 1.25rem !important;
}

.focus\:translate-x-6:focus{
  --tw-translate-x: 1.5rem !important;
}

.focus\:translate-x-7:focus{
  --tw-translate-x: 1.75rem !important;
}

.focus\:translate-x-8:focus{
  --tw-translate-x: 2rem !important;
}

.focus\:translate-x-9:focus{
  --tw-translate-x: 2.25rem !important;
}

.focus\:translate-x-10:focus{
  --tw-translate-x: 2.5rem !important;
}

.focus\:translate-x-11:focus{
  --tw-translate-x: 2.75rem !important;
}

.focus\:translate-x-12:focus{
  --tw-translate-x: 3rem !important;
}

.focus\:translate-x-14:focus{
  --tw-translate-x: 3.5rem !important;
}

.focus\:translate-x-16:focus{
  --tw-translate-x: 4rem !important;
}

.focus\:translate-x-20:focus{
  --tw-translate-x: 5rem !important;
}

.focus\:translate-x-24:focus{
  --tw-translate-x: 6rem !important;
}

.focus\:translate-x-28:focus{
  --tw-translate-x: 7rem !important;
}

.focus\:translate-x-32:focus{
  --tw-translate-x: 8rem !important;
}

.focus\:translate-x-36:focus{
  --tw-translate-x: 9rem !important;
}

.focus\:translate-x-40:focus{
  --tw-translate-x: 10rem !important;
}

.focus\:translate-x-44:focus{
  --tw-translate-x: 11rem !important;
}

.focus\:translate-x-48:focus{
  --tw-translate-x: 12rem !important;
}

.focus\:translate-x-52:focus{
  --tw-translate-x: 13rem !important;
}

.focus\:translate-x-56:focus{
  --tw-translate-x: 14rem !important;
}

.focus\:translate-x-60:focus{
  --tw-translate-x: 15rem !important;
}

.focus\:translate-x-64:focus{
  --tw-translate-x: 16rem !important;
}

.focus\:translate-x-72:focus{
  --tw-translate-x: 18rem !important;
}

.focus\:translate-x-80:focus{
  --tw-translate-x: 20rem !important;
}

.focus\:translate-x-96:focus{
  --tw-translate-x: 24rem !important;
}

.focus\:translate-x-px:focus{
  --tw-translate-x: 1px !important;
}

.focus\:translate-x-0\.5:focus{
  --tw-translate-x: 0.125rem !important;
}

.focus\:translate-x-1\.5:focus{
  --tw-translate-x: 0.375rem !important;
}

.focus\:translate-x-2\.5:focus{
  --tw-translate-x: 0.625rem !important;
}

.focus\:translate-x-3\.5:focus{
  --tw-translate-x: 0.875rem !important;
}

.focus\:-translate-x-0:focus{
  --tw-translate-x: 0px !important;
}

.focus\:-translate-x-1:focus{
  --tw-translate-x: -0.25rem !important;
}

.focus\:-translate-x-2:focus{
  --tw-translate-x: -0.5rem !important;
}

.focus\:-translate-x-3:focus{
  --tw-translate-x: -0.75rem !important;
}

.focus\:-translate-x-4:focus{
  --tw-translate-x: -1rem !important;
}

.focus\:-translate-x-5:focus{
  --tw-translate-x: -1.25rem !important;
}

.focus\:-translate-x-6:focus{
  --tw-translate-x: -1.5rem !important;
}

.focus\:-translate-x-7:focus{
  --tw-translate-x: -1.75rem !important;
}

.focus\:-translate-x-8:focus{
  --tw-translate-x: -2rem !important;
}

.focus\:-translate-x-9:focus{
  --tw-translate-x: -2.25rem !important;
}

.focus\:-translate-x-10:focus{
  --tw-translate-x: -2.5rem !important;
}

.focus\:-translate-x-11:focus{
  --tw-translate-x: -2.75rem !important;
}

.focus\:-translate-x-12:focus{
  --tw-translate-x: -3rem !important;
}

.focus\:-translate-x-14:focus{
  --tw-translate-x: -3.5rem !important;
}

.focus\:-translate-x-16:focus{
  --tw-translate-x: -4rem !important;
}

.focus\:-translate-x-20:focus{
  --tw-translate-x: -5rem !important;
}

.focus\:-translate-x-24:focus{
  --tw-translate-x: -6rem !important;
}

.focus\:-translate-x-28:focus{
  --tw-translate-x: -7rem !important;
}

.focus\:-translate-x-32:focus{
  --tw-translate-x: -8rem !important;
}

.focus\:-translate-x-36:focus{
  --tw-translate-x: -9rem !important;
}

.focus\:-translate-x-40:focus{
  --tw-translate-x: -10rem !important;
}

.focus\:-translate-x-44:focus{
  --tw-translate-x: -11rem !important;
}

.focus\:-translate-x-48:focus{
  --tw-translate-x: -12rem !important;
}

.focus\:-translate-x-52:focus{
  --tw-translate-x: -13rem !important;
}

.focus\:-translate-x-56:focus{
  --tw-translate-x: -14rem !important;
}

.focus\:-translate-x-60:focus{
  --tw-translate-x: -15rem !important;
}

.focus\:-translate-x-64:focus{
  --tw-translate-x: -16rem !important;
}

.focus\:-translate-x-72:focus{
  --tw-translate-x: -18rem !important;
}

.focus\:-translate-x-80:focus{
  --tw-translate-x: -20rem !important;
}

.focus\:-translate-x-96:focus{
  --tw-translate-x: -24rem !important;
}

.focus\:-translate-x-px:focus{
  --tw-translate-x: -1px !important;
}

.focus\:-translate-x-0\.5:focus{
  --tw-translate-x: -0.125rem !important;
}

.focus\:-translate-x-1\.5:focus{
  --tw-translate-x: -0.375rem !important;
}

.focus\:-translate-x-2\.5:focus{
  --tw-translate-x: -0.625rem !important;
}

.focus\:-translate-x-3\.5:focus{
  --tw-translate-x: -0.875rem !important;
}

.focus\:translate-x-1\/2:focus{
  --tw-translate-x: 50% !important;
}

.focus\:translate-x-1\/3:focus{
  --tw-translate-x: 33.333333% !important;
}

.focus\:translate-x-2\/3:focus{
  --tw-translate-x: 66.666667% !important;
}

.focus\:translate-x-1\/4:focus{
  --tw-translate-x: 25% !important;
}

.focus\:translate-x-2\/4:focus{
  --tw-translate-x: 50% !important;
}

.focus\:translate-x-3\/4:focus{
  --tw-translate-x: 75% !important;
}

.focus\:translate-x-full:focus{
  --tw-translate-x: 100% !important;
}

.focus\:-translate-x-1\/2:focus{
  --tw-translate-x: -50% !important;
}

.focus\:-translate-x-1\/3:focus{
  --tw-translate-x: -33.333333% !important;
}

.focus\:-translate-x-2\/3:focus{
  --tw-translate-x: -66.666667% !important;
}

.focus\:-translate-x-1\/4:focus{
  --tw-translate-x: -25% !important;
}

.focus\:-translate-x-2\/4:focus{
  --tw-translate-x: -50% !important;
}

.focus\:-translate-x-3\/4:focus{
  --tw-translate-x: -75% !important;
}

.focus\:-translate-x-full:focus{
  --tw-translate-x: -100% !important;
}

.focus\:translate-y-0:focus{
  --tw-translate-y: 0px !important;
}

.focus\:translate-y-1:focus{
  --tw-translate-y: 0.25rem !important;
}

.focus\:translate-y-2:focus{
  --tw-translate-y: 0.5rem !important;
}

.focus\:translate-y-3:focus{
  --tw-translate-y: 0.75rem !important;
}

.focus\:translate-y-4:focus{
  --tw-translate-y: 1rem !important;
}

.focus\:translate-y-5:focus{
  --tw-translate-y: 1.25rem !important;
}

.focus\:translate-y-6:focus{
  --tw-translate-y: 1.5rem !important;
}

.focus\:translate-y-7:focus{
  --tw-translate-y: 1.75rem !important;
}

.focus\:translate-y-8:focus{
  --tw-translate-y: 2rem !important;
}

.focus\:translate-y-9:focus{
  --tw-translate-y: 2.25rem !important;
}

.focus\:translate-y-10:focus{
  --tw-translate-y: 2.5rem !important;
}

.focus\:translate-y-11:focus{
  --tw-translate-y: 2.75rem !important;
}

.focus\:translate-y-12:focus{
  --tw-translate-y: 3rem !important;
}

.focus\:translate-y-14:focus{
  --tw-translate-y: 3.5rem !important;
}

.focus\:translate-y-16:focus{
  --tw-translate-y: 4rem !important;
}

.focus\:translate-y-20:focus{
  --tw-translate-y: 5rem !important;
}

.focus\:translate-y-24:focus{
  --tw-translate-y: 6rem !important;
}

.focus\:translate-y-28:focus{
  --tw-translate-y: 7rem !important;
}

.focus\:translate-y-32:focus{
  --tw-translate-y: 8rem !important;
}

.focus\:translate-y-36:focus{
  --tw-translate-y: 9rem !important;
}

.focus\:translate-y-40:focus{
  --tw-translate-y: 10rem !important;
}

.focus\:translate-y-44:focus{
  --tw-translate-y: 11rem !important;
}

.focus\:translate-y-48:focus{
  --tw-translate-y: 12rem !important;
}

.focus\:translate-y-52:focus{
  --tw-translate-y: 13rem !important;
}

.focus\:translate-y-56:focus{
  --tw-translate-y: 14rem !important;
}

.focus\:translate-y-60:focus{
  --tw-translate-y: 15rem !important;
}

.focus\:translate-y-64:focus{
  --tw-translate-y: 16rem !important;
}

.focus\:translate-y-72:focus{
  --tw-translate-y: 18rem !important;
}

.focus\:translate-y-80:focus{
  --tw-translate-y: 20rem !important;
}

.focus\:translate-y-96:focus{
  --tw-translate-y: 24rem !important;
}

.focus\:translate-y-px:focus{
  --tw-translate-y: 1px !important;
}

.focus\:translate-y-0\.5:focus{
  --tw-translate-y: 0.125rem !important;
}

.focus\:translate-y-1\.5:focus{
  --tw-translate-y: 0.375rem !important;
}

.focus\:translate-y-2\.5:focus{
  --tw-translate-y: 0.625rem !important;
}

.focus\:translate-y-3\.5:focus{
  --tw-translate-y: 0.875rem !important;
}

.focus\:-translate-y-0:focus{
  --tw-translate-y: 0px !important;
}

.focus\:-translate-y-1:focus{
  --tw-translate-y: -0.25rem !important;
}

.focus\:-translate-y-2:focus{
  --tw-translate-y: -0.5rem !important;
}

.focus\:-translate-y-3:focus{
  --tw-translate-y: -0.75rem !important;
}

.focus\:-translate-y-4:focus{
  --tw-translate-y: -1rem !important;
}

.focus\:-translate-y-5:focus{
  --tw-translate-y: -1.25rem !important;
}

.focus\:-translate-y-6:focus{
  --tw-translate-y: -1.5rem !important;
}

.focus\:-translate-y-7:focus{
  --tw-translate-y: -1.75rem !important;
}

.focus\:-translate-y-8:focus{
  --tw-translate-y: -2rem !important;
}

.focus\:-translate-y-9:focus{
  --tw-translate-y: -2.25rem !important;
}

.focus\:-translate-y-10:focus{
  --tw-translate-y: -2.5rem !important;
}

.focus\:-translate-y-11:focus{
  --tw-translate-y: -2.75rem !important;
}

.focus\:-translate-y-12:focus{
  --tw-translate-y: -3rem !important;
}

.focus\:-translate-y-14:focus{
  --tw-translate-y: -3.5rem !important;
}

.focus\:-translate-y-16:focus{
  --tw-translate-y: -4rem !important;
}

.focus\:-translate-y-20:focus{
  --tw-translate-y: -5rem !important;
}

.focus\:-translate-y-24:focus{
  --tw-translate-y: -6rem !important;
}

.focus\:-translate-y-28:focus{
  --tw-translate-y: -7rem !important;
}

.focus\:-translate-y-32:focus{
  --tw-translate-y: -8rem !important;
}

.focus\:-translate-y-36:focus{
  --tw-translate-y: -9rem !important;
}

.focus\:-translate-y-40:focus{
  --tw-translate-y: -10rem !important;
}

.focus\:-translate-y-44:focus{
  --tw-translate-y: -11rem !important;
}

.focus\:-translate-y-48:focus{
  --tw-translate-y: -12rem !important;
}

.focus\:-translate-y-52:focus{
  --tw-translate-y: -13rem !important;
}

.focus\:-translate-y-56:focus{
  --tw-translate-y: -14rem !important;
}

.focus\:-translate-y-60:focus{
  --tw-translate-y: -15rem !important;
}

.focus\:-translate-y-64:focus{
  --tw-translate-y: -16rem !important;
}

.focus\:-translate-y-72:focus{
  --tw-translate-y: -18rem !important;
}

.focus\:-translate-y-80:focus{
  --tw-translate-y: -20rem !important;
}

.focus\:-translate-y-96:focus{
  --tw-translate-y: -24rem !important;
}

.focus\:-translate-y-px:focus{
  --tw-translate-y: -1px !important;
}

.focus\:-translate-y-0\.5:focus{
  --tw-translate-y: -0.125rem !important;
}

.focus\:-translate-y-1\.5:focus{
  --tw-translate-y: -0.375rem !important;
}

.focus\:-translate-y-2\.5:focus{
  --tw-translate-y: -0.625rem !important;
}

.focus\:-translate-y-3\.5:focus{
  --tw-translate-y: -0.875rem !important;
}

.focus\:translate-y-1\/2:focus{
  --tw-translate-y: 50% !important;
}

.focus\:translate-y-1\/3:focus{
  --tw-translate-y: 33.333333% !important;
}

.focus\:translate-y-2\/3:focus{
  --tw-translate-y: 66.666667% !important;
}

.focus\:translate-y-1\/4:focus{
  --tw-translate-y: 25% !important;
}

.focus\:translate-y-2\/4:focus{
  --tw-translate-y: 50% !important;
}

.focus\:translate-y-3\/4:focus{
  --tw-translate-y: 75% !important;
}

.focus\:translate-y-full:focus{
  --tw-translate-y: 100% !important;
}

.focus\:-translate-y-1\/2:focus{
  --tw-translate-y: -50% !important;
}

.focus\:-translate-y-1\/3:focus{
  --tw-translate-y: -33.333333% !important;
}

.focus\:-translate-y-2\/3:focus{
  --tw-translate-y: -66.666667% !important;
}

.focus\:-translate-y-1\/4:focus{
  --tw-translate-y: -25% !important;
}

.focus\:-translate-y-2\/4:focus{
  --tw-translate-y: -50% !important;
}

.focus\:-translate-y-3\/4:focus{
  --tw-translate-y: -75% !important;
}

.focus\:-translate-y-full:focus{
  --tw-translate-y: -100% !important;
}

.rotate-0{
  --tw-rotate: 0deg !important;
}

.rotate-1{
  --tw-rotate: 1deg !important;
}

.rotate-2{
  --tw-rotate: 2deg !important;
}

.rotate-3{
  --tw-rotate: 3deg !important;
}

.rotate-6{
  --tw-rotate: 6deg !important;
}

.rotate-12{
  --tw-rotate: 12deg !important;
}

.rotate-45{
  --tw-rotate: 45deg !important;
}

.rotate-90{
  --tw-rotate: 90deg !important;
}

.rotate-180{
  --tw-rotate: 180deg !important;
}

.-rotate-180{
  --tw-rotate: -180deg !important;
}

.-rotate-90{
  --tw-rotate: -90deg !important;
}

.-rotate-45{
  --tw-rotate: -45deg !important;
}

.-rotate-12{
  --tw-rotate: -12deg !important;
}

.-rotate-6{
  --tw-rotate: -6deg !important;
}

.-rotate-3{
  --tw-rotate: -3deg !important;
}

.-rotate-2{
  --tw-rotate: -2deg !important;
}

.-rotate-1{
  --tw-rotate: -1deg !important;
}

.hover\:rotate-0:hover{
  --tw-rotate: 0deg !important;
}

.hover\:rotate-1:hover{
  --tw-rotate: 1deg !important;
}

.hover\:rotate-2:hover{
  --tw-rotate: 2deg !important;
}

.hover\:rotate-3:hover{
  --tw-rotate: 3deg !important;
}

.hover\:rotate-6:hover{
  --tw-rotate: 6deg !important;
}

.hover\:rotate-12:hover{
  --tw-rotate: 12deg !important;
}

.hover\:rotate-45:hover{
  --tw-rotate: 45deg !important;
}

.hover\:rotate-90:hover{
  --tw-rotate: 90deg !important;
}

.hover\:rotate-180:hover{
  --tw-rotate: 180deg !important;
}

.hover\:-rotate-180:hover{
  --tw-rotate: -180deg !important;
}

.hover\:-rotate-90:hover{
  --tw-rotate: -90deg !important;
}

.hover\:-rotate-45:hover{
  --tw-rotate: -45deg !important;
}

.hover\:-rotate-12:hover{
  --tw-rotate: -12deg !important;
}

.hover\:-rotate-6:hover{
  --tw-rotate: -6deg !important;
}

.hover\:-rotate-3:hover{
  --tw-rotate: -3deg !important;
}

.hover\:-rotate-2:hover{
  --tw-rotate: -2deg !important;
}

.hover\:-rotate-1:hover{
  --tw-rotate: -1deg !important;
}

.focus\:rotate-0:focus{
  --tw-rotate: 0deg !important;
}

.focus\:rotate-1:focus{
  --tw-rotate: 1deg !important;
}

.focus\:rotate-2:focus{
  --tw-rotate: 2deg !important;
}

.focus\:rotate-3:focus{
  --tw-rotate: 3deg !important;
}

.focus\:rotate-6:focus{
  --tw-rotate: 6deg !important;
}

.focus\:rotate-12:focus{
  --tw-rotate: 12deg !important;
}

.focus\:rotate-45:focus{
  --tw-rotate: 45deg !important;
}

.focus\:rotate-90:focus{
  --tw-rotate: 90deg !important;
}

.focus\:rotate-180:focus{
  --tw-rotate: 180deg !important;
}

.focus\:-rotate-180:focus{
  --tw-rotate: -180deg !important;
}

.focus\:-rotate-90:focus{
  --tw-rotate: -90deg !important;
}

.focus\:-rotate-45:focus{
  --tw-rotate: -45deg !important;
}

.focus\:-rotate-12:focus{
  --tw-rotate: -12deg !important;
}

.focus\:-rotate-6:focus{
  --tw-rotate: -6deg !important;
}

.focus\:-rotate-3:focus{
  --tw-rotate: -3deg !important;
}

.focus\:-rotate-2:focus{
  --tw-rotate: -2deg !important;
}

.focus\:-rotate-1:focus{
  --tw-rotate: -1deg !important;
}

.skew-x-0{
  --tw-skew-x: 0deg !important;
}

.skew-x-1{
  --tw-skew-x: 1deg !important;
}

.skew-x-2{
  --tw-skew-x: 2deg !important;
}

.skew-x-3{
  --tw-skew-x: 3deg !important;
}

.skew-x-6{
  --tw-skew-x: 6deg !important;
}

.skew-x-12{
  --tw-skew-x: 12deg !important;
}

.-skew-x-12{
  --tw-skew-x: -12deg !important;
}

.-skew-x-6{
  --tw-skew-x: -6deg !important;
}

.-skew-x-3{
  --tw-skew-x: -3deg !important;
}

.-skew-x-2{
  --tw-skew-x: -2deg !important;
}

.-skew-x-1{
  --tw-skew-x: -1deg !important;
}

.skew-y-0{
  --tw-skew-y: 0deg !important;
}

.skew-y-1{
  --tw-skew-y: 1deg !important;
}

.skew-y-2{
  --tw-skew-y: 2deg !important;
}

.skew-y-3{
  --tw-skew-y: 3deg !important;
}

.skew-y-6{
  --tw-skew-y: 6deg !important;
}

.skew-y-12{
  --tw-skew-y: 12deg !important;
}

.-skew-y-12{
  --tw-skew-y: -12deg !important;
}

.-skew-y-6{
  --tw-skew-y: -6deg !important;
}

.-skew-y-3{
  --tw-skew-y: -3deg !important;
}

.-skew-y-2{
  --tw-skew-y: -2deg !important;
}

.-skew-y-1{
  --tw-skew-y: -1deg !important;
}

.hover\:skew-x-0:hover{
  --tw-skew-x: 0deg !important;
}

.hover\:skew-x-1:hover{
  --tw-skew-x: 1deg !important;
}

.hover\:skew-x-2:hover{
  --tw-skew-x: 2deg !important;
}

.hover\:skew-x-3:hover{
  --tw-skew-x: 3deg !important;
}

.hover\:skew-x-6:hover{
  --tw-skew-x: 6deg !important;
}

.hover\:skew-x-12:hover{
  --tw-skew-x: 12deg !important;
}

.hover\:-skew-x-12:hover{
  --tw-skew-x: -12deg !important;
}

.hover\:-skew-x-6:hover{
  --tw-skew-x: -6deg !important;
}

.hover\:-skew-x-3:hover{
  --tw-skew-x: -3deg !important;
}

.hover\:-skew-x-2:hover{
  --tw-skew-x: -2deg !important;
}

.hover\:-skew-x-1:hover{
  --tw-skew-x: -1deg !important;
}

.hover\:skew-y-0:hover{
  --tw-skew-y: 0deg !important;
}

.hover\:skew-y-1:hover{
  --tw-skew-y: 1deg !important;
}

.hover\:skew-y-2:hover{
  --tw-skew-y: 2deg !important;
}

.hover\:skew-y-3:hover{
  --tw-skew-y: 3deg !important;
}

.hover\:skew-y-6:hover{
  --tw-skew-y: 6deg !important;
}

.hover\:skew-y-12:hover{
  --tw-skew-y: 12deg !important;
}

.hover\:-skew-y-12:hover{
  --tw-skew-y: -12deg !important;
}

.hover\:-skew-y-6:hover{
  --tw-skew-y: -6deg !important;
}

.hover\:-skew-y-3:hover{
  --tw-skew-y: -3deg !important;
}

.hover\:-skew-y-2:hover{
  --tw-skew-y: -2deg !important;
}

.hover\:-skew-y-1:hover{
  --tw-skew-y: -1deg !important;
}

.focus\:skew-x-0:focus{
  --tw-skew-x: 0deg !important;
}

.focus\:skew-x-1:focus{
  --tw-skew-x: 1deg !important;
}

.focus\:skew-x-2:focus{
  --tw-skew-x: 2deg !important;
}

.focus\:skew-x-3:focus{
  --tw-skew-x: 3deg !important;
}

.focus\:skew-x-6:focus{
  --tw-skew-x: 6deg !important;
}

.focus\:skew-x-12:focus{
  --tw-skew-x: 12deg !important;
}

.focus\:-skew-x-12:focus{
  --tw-skew-x: -12deg !important;
}

.focus\:-skew-x-6:focus{
  --tw-skew-x: -6deg !important;
}

.focus\:-skew-x-3:focus{
  --tw-skew-x: -3deg !important;
}

.focus\:-skew-x-2:focus{
  --tw-skew-x: -2deg !important;
}

.focus\:-skew-x-1:focus{
  --tw-skew-x: -1deg !important;
}

.focus\:skew-y-0:focus{
  --tw-skew-y: 0deg !important;
}

.focus\:skew-y-1:focus{
  --tw-skew-y: 1deg !important;
}

.focus\:skew-y-2:focus{
  --tw-skew-y: 2deg !important;
}

.focus\:skew-y-3:focus{
  --tw-skew-y: 3deg !important;
}

.focus\:skew-y-6:focus{
  --tw-skew-y: 6deg !important;
}

.focus\:skew-y-12:focus{
  --tw-skew-y: 12deg !important;
}

.focus\:-skew-y-12:focus{
  --tw-skew-y: -12deg !important;
}

.focus\:-skew-y-6:focus{
  --tw-skew-y: -6deg !important;
}

.focus\:-skew-y-3:focus{
  --tw-skew-y: -3deg !important;
}

.focus\:-skew-y-2:focus{
  --tw-skew-y: -2deg !important;
}

.focus\:-skew-y-1:focus{
  --tw-skew-y: -1deg !important;
}

.scale-0{
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
}

.scale-50{
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
}

.scale-75{
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
}

.scale-90{
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
}

.scale-95{
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
}

.scale-100{
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
}

.scale-105{
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
}

.scale-110{
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
}

.scale-125{
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
}

.scale-150{
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
}

.hover\:scale-0:hover{
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
}

.hover\:scale-50:hover{
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
}

.hover\:scale-75:hover{
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
}

.hover\:scale-90:hover{
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
}

.hover\:scale-95:hover{
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
}

.hover\:scale-100:hover{
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
}

.hover\:scale-105:hover{
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
}

.hover\:scale-110:hover{
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
}

.hover\:scale-125:hover{
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
}

.hover\:scale-150:hover{
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
}

.focus\:scale-0:focus{
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
}

.focus\:scale-50:focus{
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
}

.focus\:scale-75:focus{
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
}

.focus\:scale-90:focus{
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
}

.focus\:scale-95:focus{
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
}

.focus\:scale-100:focus{
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
}

.focus\:scale-105:focus{
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
}

.focus\:scale-110:focus{
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
}

.focus\:scale-125:focus{
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
}

.focus\:scale-150:focus{
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
}

.scale-x-0{
  --tw-scale-x: 0 !important;
}

.scale-x-50{
  --tw-scale-x: .5 !important;
}

.scale-x-75{
  --tw-scale-x: .75 !important;
}

.scale-x-90{
  --tw-scale-x: .9 !important;
}

.scale-x-95{
  --tw-scale-x: .95 !important;
}

.scale-x-100{
  --tw-scale-x: 1 !important;
}

.scale-x-105{
  --tw-scale-x: 1.05 !important;
}

.scale-x-110{
  --tw-scale-x: 1.1 !important;
}

.scale-x-125{
  --tw-scale-x: 1.25 !important;
}

.scale-x-150{
  --tw-scale-x: 1.5 !important;
}

.scale-y-0{
  --tw-scale-y: 0 !important;
}

.scale-y-50{
  --tw-scale-y: .5 !important;
}

.scale-y-75{
  --tw-scale-y: .75 !important;
}

.scale-y-90{
  --tw-scale-y: .9 !important;
}

.scale-y-95{
  --tw-scale-y: .95 !important;
}

.scale-y-100{
  --tw-scale-y: 1 !important;
}

.scale-y-105{
  --tw-scale-y: 1.05 !important;
}

.scale-y-110{
  --tw-scale-y: 1.1 !important;
}

.scale-y-125{
  --tw-scale-y: 1.25 !important;
}

.scale-y-150{
  --tw-scale-y: 1.5 !important;
}

.hover\:scale-x-0:hover{
  --tw-scale-x: 0 !important;
}

.hover\:scale-x-50:hover{
  --tw-scale-x: .5 !important;
}

.hover\:scale-x-75:hover{
  --tw-scale-x: .75 !important;
}

.hover\:scale-x-90:hover{
  --tw-scale-x: .9 !important;
}

.hover\:scale-x-95:hover{
  --tw-scale-x: .95 !important;
}

.hover\:scale-x-100:hover{
  --tw-scale-x: 1 !important;
}

.hover\:scale-x-105:hover{
  --tw-scale-x: 1.05 !important;
}

.hover\:scale-x-110:hover{
  --tw-scale-x: 1.1 !important;
}

.hover\:scale-x-125:hover{
  --tw-scale-x: 1.25 !important;
}

.hover\:scale-x-150:hover{
  --tw-scale-x: 1.5 !important;
}

.hover\:scale-y-0:hover{
  --tw-scale-y: 0 !important;
}

.hover\:scale-y-50:hover{
  --tw-scale-y: .5 !important;
}

.hover\:scale-y-75:hover{
  --tw-scale-y: .75 !important;
}

.hover\:scale-y-90:hover{
  --tw-scale-y: .9 !important;
}

.hover\:scale-y-95:hover{
  --tw-scale-y: .95 !important;
}

.hover\:scale-y-100:hover{
  --tw-scale-y: 1 !important;
}

.hover\:scale-y-105:hover{
  --tw-scale-y: 1.05 !important;
}

.hover\:scale-y-110:hover{
  --tw-scale-y: 1.1 !important;
}

.hover\:scale-y-125:hover{
  --tw-scale-y: 1.25 !important;
}

.hover\:scale-y-150:hover{
  --tw-scale-y: 1.5 !important;
}

.focus\:scale-x-0:focus{
  --tw-scale-x: 0 !important;
}

.focus\:scale-x-50:focus{
  --tw-scale-x: .5 !important;
}

.focus\:scale-x-75:focus{
  --tw-scale-x: .75 !important;
}

.focus\:scale-x-90:focus{
  --tw-scale-x: .9 !important;
}

.focus\:scale-x-95:focus{
  --tw-scale-x: .95 !important;
}

.focus\:scale-x-100:focus{
  --tw-scale-x: 1 !important;
}

.focus\:scale-x-105:focus{
  --tw-scale-x: 1.05 !important;
}

.focus\:scale-x-110:focus{
  --tw-scale-x: 1.1 !important;
}

.focus\:scale-x-125:focus{
  --tw-scale-x: 1.25 !important;
}

.focus\:scale-x-150:focus{
  --tw-scale-x: 1.5 !important;
}

.focus\:scale-y-0:focus{
  --tw-scale-y: 0 !important;
}

.focus\:scale-y-50:focus{
  --tw-scale-y: .5 !important;
}

.focus\:scale-y-75:focus{
  --tw-scale-y: .75 !important;
}

.focus\:scale-y-90:focus{
  --tw-scale-y: .9 !important;
}

.focus\:scale-y-95:focus{
  --tw-scale-y: .95 !important;
}

.focus\:scale-y-100:focus{
  --tw-scale-y: 1 !important;
}

.focus\:scale-y-105:focus{
  --tw-scale-y: 1.05 !important;
}

.focus\:scale-y-110:focus{
  --tw-scale-y: 1.1 !important;
}

.focus\:scale-y-125:focus{
  --tw-scale-y: 1.25 !important;
}

.focus\:scale-y-150:focus{
  --tw-scale-y: 1.5 !important;
}

@keyframes spin{
  to{
    transform: rotate(360deg);
  }
}

@keyframes ping{
  75%, 100%{
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes bounce{
  0%, 100%{
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.animate-none{
  animation: none !important;
}

.animate-spin{
  animation: spin 1s linear infinite !important;
}

.animate-ping{
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.animate-pulse{
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

.animate-bounce{
  animation: bounce 1s infinite !important;
}

.cursor-auto{
  cursor: auto !important;
}

.cursor-default{
  cursor: default !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.cursor-wait{
  cursor: wait !important;
}

.cursor-text{
  cursor: text !important;
}

.cursor-move{
  cursor: move !important;
}

.cursor-help{
  cursor: help !important;
}

.cursor-not-allowed{
  cursor: not-allowed !important;
}

.select-none{
  -webkit-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.select-text{
  -webkit-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}

.select-all{
  -webkit-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.select-auto{
  -webkit-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.resize-none{
  resize: none !important;
}

.resize-y{
  resize: vertical !important;
}

.resize-x{
  resize: horizontal !important;
}

.resize{
  resize: both !important;
}

.list-inside{
  list-style-position: inside !important;
}

.list-outside{
  list-style-position: outside !important;
}

.list-none{
  list-style-type: none !important;
}

.list-disc{
  list-style-type: disc !important;
}

.list-decimal{
  list-style-type: decimal !important;
}

.appearance-none{
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
}

.auto-cols-auto{
  grid-auto-columns: auto !important;
}

.auto-cols-min{
  grid-auto-columns: -webkit-min-content !important;
  grid-auto-columns: min-content !important;
}

.auto-cols-max{
  grid-auto-columns: -webkit-max-content !important;
  grid-auto-columns: max-content !important;
}

.auto-cols-fr{
  grid-auto-columns: minmax(0, 1fr) !important;
}

.grid-flow-row{
  grid-auto-flow: row !important;
}

.grid-flow-col{
  grid-auto-flow: column !important;
}

.grid-flow-row-dense{
  grid-auto-flow: row dense !important;
}

.grid-flow-col-dense{
  grid-auto-flow: column dense !important;
}

.auto-rows-auto{
  grid-auto-rows: auto !important;
}

.auto-rows-min{
  grid-auto-rows: -webkit-min-content !important;
  grid-auto-rows: min-content !important;
}

.auto-rows-max{
  grid-auto-rows: -webkit-max-content !important;
  grid-auto-rows: max-content !important;
}

.auto-rows-fr{
  grid-auto-rows: minmax(0, 1fr) !important;
}

.grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7{
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8{
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9{
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10{
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11{
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.grid-cols-12{
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.grid-cols-none{
  grid-template-columns: none !important;
}

.grid-rows-1{
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.grid-rows-2{
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.grid-rows-4{
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.grid-rows-5{
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.grid-rows-6{
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.grid-rows-none{
  grid-template-rows: none !important;
}

.flex-row{
  flex-direction: row !important;
}

.flex-row-reverse{
  flex-direction: row-reverse !important;
}

.flex-col{
  flex-direction: column !important;
}

.flex-col-reverse{
  flex-direction: column-reverse !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.flex-wrap-reverse{
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap{
  flex-wrap: nowrap !important;
}

.place-content-center{
  place-content: center !important;
}

.place-content-start{
  place-content: start !important;
}

.place-content-end{
  place-content: end !important;
}

.place-content-between{
  place-content: space-between !important;
}

.place-content-around{
  place-content: space-around !important;
}

.place-content-evenly{
  place-content: space-evenly !important;
}

.place-content-stretch{
  place-content: stretch !important;
}

.place-items-start{
  place-items: start !important;
}

.place-items-end{
  place-items: end !important;
}

.place-items-center{
  place-items: center !important;
}

.place-items-stretch{
  place-items: stretch !important;
}

.content-center{
  align-content: center !important;
}

.content-start{
  align-content: flex-start !important;
}

.content-end{
  align-content: flex-end !important;
}

.content-between{
  align-content: space-between !important;
}

.content-around{
  align-content: space-around !important;
}

.content-evenly{
  align-content: space-evenly !important;
}

.items-start{
  align-items: flex-start !important;
}

.items-end{
  align-items: flex-end !important;
}

.items-center{
  align-items: center !important;
}

.items-baseline{
  align-items: baseline !important;
}

.items-stretch{
  align-items: stretch !important;
}

.justify-start{
  justify-content: flex-start !important;
}

.justify-end{
  justify-content: flex-end !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.justify-around{
  justify-content: space-around !important;
}

.justify-evenly{
  justify-content: space-evenly !important;
}

.justify-items-start{
  justify-items: start !important;
}

.justify-items-end{
  justify-items: end !important;
}

.justify-items-center{
  justify-items: center !important;
}

.justify-items-stretch{
  justify-items: stretch !important;
}

.gap-0{
  gap: 0px !important;
}

.gap-1{
  gap: 0.25rem !important;
}

.gap-2{
  gap: 0.5rem !important;
}

.gap-3{
  gap: 0.75rem !important;
}

.gap-4{
  gap: 1rem !important;
}

.gap-5{
  gap: 1.25rem !important;
}

.gap-6{
  gap: 1.5rem !important;
}

.gap-7{
  gap: 1.75rem !important;
}

.gap-8{
  gap: 2rem !important;
}

.gap-9{
  gap: 2.25rem !important;
}

.gap-10{
  gap: 2.5rem !important;
}

.gap-11{
  gap: 2.75rem !important;
}

.gap-12{
  gap: 3rem !important;
}

.gap-14{
  gap: 3.5rem !important;
}

.gap-16{
  gap: 4rem !important;
}

.gap-20{
  gap: 5rem !important;
}

.gap-24{
  gap: 6rem !important;
}

.gap-28{
  gap: 7rem !important;
}

.gap-32{
  gap: 8rem !important;
}

.gap-36{
  gap: 9rem !important;
}

.gap-40{
  gap: 10rem !important;
}

.gap-44{
  gap: 11rem !important;
}

.gap-48{
  gap: 12rem !important;
}

.gap-52{
  gap: 13rem !important;
}

.gap-56{
  gap: 14rem !important;
}

.gap-60{
  gap: 15rem !important;
}

.gap-64{
  gap: 16rem !important;
}

.gap-72{
  gap: 18rem !important;
}

.gap-80{
  gap: 20rem !important;
}

.gap-96{
  gap: 24rem !important;
}

.gap-px{
  gap: 1px !important;
}

.gap-0\.5{
  gap: 0.125rem !important;
}

.gap-1\.5{
  gap: 0.375rem !important;
}

.gap-2\.5{
  gap: 0.625rem !important;
}

.gap-3\.5{
  gap: 0.875rem !important;
}

.gap-x-0{
  -webkit-column-gap: 0px !important;
          column-gap: 0px !important;
}

.gap-x-1{
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}

.gap-x-2{
  -webkit-column-gap: 0.5rem !important;
          column-gap: 0.5rem !important;
}

.gap-x-3{
  -webkit-column-gap: 0.75rem !important;
          column-gap: 0.75rem !important;
}

.gap-x-4{
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}

.gap-x-5{
  -webkit-column-gap: 1.25rem !important;
          column-gap: 1.25rem !important;
}

.gap-x-6{
  -webkit-column-gap: 1.5rem !important;
          column-gap: 1.5rem !important;
}

.gap-x-7{
  -webkit-column-gap: 1.75rem !important;
          column-gap: 1.75rem !important;
}

.gap-x-8{
  -webkit-column-gap: 2rem !important;
          column-gap: 2rem !important;
}

.gap-x-9{
  -webkit-column-gap: 2.25rem !important;
          column-gap: 2.25rem !important;
}

.gap-x-10{
  -webkit-column-gap: 2.5rem !important;
          column-gap: 2.5rem !important;
}

.gap-x-11{
  -webkit-column-gap: 2.75rem !important;
          column-gap: 2.75rem !important;
}

.gap-x-12{
  -webkit-column-gap: 3rem !important;
          column-gap: 3rem !important;
}

.gap-x-14{
  -webkit-column-gap: 3.5rem !important;
          column-gap: 3.5rem !important;
}

.gap-x-16{
  -webkit-column-gap: 4rem !important;
          column-gap: 4rem !important;
}

.gap-x-20{
  -webkit-column-gap: 5rem !important;
          column-gap: 5rem !important;
}

.gap-x-24{
  -webkit-column-gap: 6rem !important;
          column-gap: 6rem !important;
}

.gap-x-28{
  -webkit-column-gap: 7rem !important;
          column-gap: 7rem !important;
}

.gap-x-32{
  -webkit-column-gap: 8rem !important;
          column-gap: 8rem !important;
}

.gap-x-36{
  -webkit-column-gap: 9rem !important;
          column-gap: 9rem !important;
}

.gap-x-40{
  -webkit-column-gap: 10rem !important;
          column-gap: 10rem !important;
}

.gap-x-44{
  -webkit-column-gap: 11rem !important;
          column-gap: 11rem !important;
}

.gap-x-48{
  -webkit-column-gap: 12rem !important;
          column-gap: 12rem !important;
}

.gap-x-52{
  -webkit-column-gap: 13rem !important;
          column-gap: 13rem !important;
}

.gap-x-56{
  -webkit-column-gap: 14rem !important;
          column-gap: 14rem !important;
}

.gap-x-60{
  -webkit-column-gap: 15rem !important;
          column-gap: 15rem !important;
}

.gap-x-64{
  -webkit-column-gap: 16rem !important;
          column-gap: 16rem !important;
}

.gap-x-72{
  -webkit-column-gap: 18rem !important;
          column-gap: 18rem !important;
}

.gap-x-80{
  -webkit-column-gap: 20rem !important;
          column-gap: 20rem !important;
}

.gap-x-96{
  -webkit-column-gap: 24rem !important;
          column-gap: 24rem !important;
}

.gap-x-px{
  -webkit-column-gap: 1px !important;
          column-gap: 1px !important;
}

.gap-x-0\.5{
  -webkit-column-gap: 0.125rem !important;
          column-gap: 0.125rem !important;
}

.gap-x-1\.5{
  -webkit-column-gap: 0.375rem !important;
          column-gap: 0.375rem !important;
}

.gap-x-2\.5{
  -webkit-column-gap: 0.625rem !important;
          column-gap: 0.625rem !important;
}

.gap-x-3\.5{
  -webkit-column-gap: 0.875rem !important;
          column-gap: 0.875rem !important;
}

.gap-y-0{
  row-gap: 0px !important;
}

.gap-y-1{
  row-gap: 0.25rem !important;
}

.gap-y-2{
  row-gap: 0.5rem !important;
}

.gap-y-3{
  row-gap: 0.75rem !important;
}

.gap-y-4{
  row-gap: 1rem !important;
}

.gap-y-5{
  row-gap: 1.25rem !important;
}

.gap-y-6{
  row-gap: 1.5rem !important;
}

.gap-y-7{
  row-gap: 1.75rem !important;
}

.gap-y-8{
  row-gap: 2rem !important;
}

.gap-y-9{
  row-gap: 2.25rem !important;
}

.gap-y-10{
  row-gap: 2.5rem !important;
}

.gap-y-11{
  row-gap: 2.75rem !important;
}

.gap-y-12{
  row-gap: 3rem !important;
}

.gap-y-14{
  row-gap: 3.5rem !important;
}

.gap-y-16{
  row-gap: 4rem !important;
}

.gap-y-20{
  row-gap: 5rem !important;
}

.gap-y-24{
  row-gap: 6rem !important;
}

.gap-y-28{
  row-gap: 7rem !important;
}

.gap-y-32{
  row-gap: 8rem !important;
}

.gap-y-36{
  row-gap: 9rem !important;
}

.gap-y-40{
  row-gap: 10rem !important;
}

.gap-y-44{
  row-gap: 11rem !important;
}

.gap-y-48{
  row-gap: 12rem !important;
}

.gap-y-52{
  row-gap: 13rem !important;
}

.gap-y-56{
  row-gap: 14rem !important;
}

.gap-y-60{
  row-gap: 15rem !important;
}

.gap-y-64{
  row-gap: 16rem !important;
}

.gap-y-72{
  row-gap: 18rem !important;
}

.gap-y-80{
  row-gap: 20rem !important;
}

.gap-y-96{
  row-gap: 24rem !important;
}

.gap-y-px{
  row-gap: 1px !important;
}

.gap-y-0\.5{
  row-gap: 0.125rem !important;
}

.gap-y-1\.5{
  row-gap: 0.375rem !important;
}

.gap-y-2\.5{
  row-gap: 0.625rem !important;
}

.gap-y-3\.5{
  row-gap: 0.875rem !important;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
}

.space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}

.space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
}

.space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
}

.space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
}

.space-y-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
}

.space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
}

.space-y-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
}

.space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
}

.space-y-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
}

.space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
}

.space-y-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
}

.space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
}

.space-y-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
}

.space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
}

.space-y-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
}

.space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
}

.space-y-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
}

.space-y-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
}

.space-y-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
}

.space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
}

.space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
}

.space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
}

.space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-7 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-9 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-10 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-11 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-14 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-16 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-20 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-24 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-28 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-32 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-36 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-40 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-44 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-48 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-52 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-56 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-60 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-64 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-72 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-80 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-96 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-px > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
}

.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
}

.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse: 1 !important;
}

.space-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse: 1 !important;
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-x > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
}

.divide-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
}

.divide-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
}

.divide-y > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.divide-y-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-y-reverse: 1 !important;
}

.divide-x-reverse > :not([hidden]) ~ :not([hidden]){
  --tw-divide-x-reverse: 1 !important;
}

.divide-solid > :not([hidden]) ~ :not([hidden]){
  border-style: solid !important;
}

.divide-dashed > :not([hidden]) ~ :not([hidden]){
  border-style: dashed !important;
}

.divide-dotted > :not([hidden]) ~ :not([hidden]){
  border-style: dotted !important;
}

.divide-double > :not([hidden]) ~ :not([hidden]){
  border-style: double !important;
}

.divide-none > :not([hidden]) ~ :not([hidden]){
  border-style: none !important;
}

.divide-transparent > :not([hidden]) ~ :not([hidden]){
  border-color: transparent !important;
}

.divide-current > :not([hidden]) ~ :not([hidden]){
  border-color: currentColor !important;
}

.divide-black > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
}

.divide-white > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
}

.divide-gray-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
}

.divide-gray-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
}

.divide-gray-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
}

.divide-gray-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
}

.divide-gray-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
}

.divide-gray-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
}

.divide-gray-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
}

.divide-red-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
}

.divide-red-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
}

.divide-red-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
}

.divide-red-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
}

.divide-red-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
}

.divide-red-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
}

.divide-red-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
}

.divide-red-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
}

.divide-red-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
}

.divide-red-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
}

.divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
}

.divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
}

.divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
}

.divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
}

.divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
}

.divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
}

.divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
}

.divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
}

.divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
}

.divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
}

.divide-green-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
}

.divide-green-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
}

.divide-green-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
}

.divide-green-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
}

.divide-green-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
}

.divide-green-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
}

.divide-green-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
}

.divide-green-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
}

.divide-green-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
}

.divide-green-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
}

.divide-blue-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
}

.divide-blue-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
}

.divide-blue-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
}

.divide-blue-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
}

.divide-blue-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
}

.divide-blue-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
}

.divide-blue-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
}

.divide-blue-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
}

.divide-blue-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
}

.divide-blue-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
}

.divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
}

.divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
}

.divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
}

.divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
}

.divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
}

.divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
}

.divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
}

.divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
}

.divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
}

.divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
}

.divide-purple-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
}

.divide-purple-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
}

.divide-purple-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
}

.divide-purple-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
}

.divide-purple-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
}

.divide-purple-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
}

.divide-purple-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
}

.divide-purple-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
}

.divide-purple-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
}

.divide-purple-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
}

.divide-pink-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
}

.divide-pink-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
}

.divide-pink-200 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
}

.divide-pink-300 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
}

.divide-pink-400 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
}

.divide-pink-500 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
}

.divide-pink-600 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
}

.divide-pink-700 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
}

.divide-pink-800 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
}

.divide-pink-900 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
}

.divide-qipGreen > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(139, 195, 52, var(--tw-divide-opacity)) !important;
}

.divide-portalGreen > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
  border-color: rgba(138, 192, 74, var(--tw-divide-opacity)) !important;
}

.divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0 !important;
}

.divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.05 !important;
}

.divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.1 !important;
}

.divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.2 !important;
}

.divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.25 !important;
}

.divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.3 !important;
}

.divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.4 !important;
}

.divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.5 !important;
}

.divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.6 !important;
}

.divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.7 !important;
}

.divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.75 !important;
}

.divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.8 !important;
}

.divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.9 !important;
}

.divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 0.95 !important;
}

.divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
  --tw-divide-opacity: 1 !important;
}

.place-self-auto{
  place-self: auto !important;
}

.place-self-start{
  place-self: start !important;
}

.place-self-end{
  place-self: end !important;
}

.place-self-center{
  place-self: center !important;
}

.place-self-stretch{
  place-self: stretch !important;
}

.self-auto{
  align-self: auto !important;
}

.self-start{
  align-self: flex-start !important;
}

.self-end{
  align-self: flex-end !important;
}

.self-center{
  align-self: center !important;
}

.self-stretch{
  align-self: stretch !important;
}

.self-baseline{
  align-self: baseline !important;
}

.justify-self-auto{
  justify-self: auto !important;
}

.justify-self-start{
  justify-self: start !important;
}

.justify-self-end{
  justify-self: end !important;
}

.justify-self-center{
  justify-self: center !important;
}

.justify-self-stretch{
  justify-self: stretch !important;
}

.overflow-auto{
  overflow: auto !important;
}

.overflow-hidden{
  overflow: hidden !important;
}

.overflow-visible{
  overflow: visible !important;
}

.overflow-scroll{
  overflow: scroll !important;
}

.overflow-x-auto{
  overflow-x: auto !important;
}

.overflow-y-auto{
  overflow-y: auto !important;
}

.overflow-x-hidden{
  overflow-x: hidden !important;
}

.overflow-y-hidden{
  overflow-y: hidden !important;
}

.overflow-x-visible{
  overflow-x: visible !important;
}

.overflow-y-visible{
  overflow-y: visible !important;
}

.overflow-x-scroll{
  overflow-x: scroll !important;
}

.overflow-y-scroll{
  overflow-y: scroll !important;
}

.overscroll-auto{
  -ms-scroll-chaining: chained !important;
      overscroll-behavior: auto !important;
}

.overscroll-contain{
  -ms-scroll-chaining: none !important;
      overscroll-behavior: contain !important;
}

.overscroll-none{
  -ms-scroll-chaining: none !important;
      overscroll-behavior: none !important;
}

.overscroll-y-auto{
  overscroll-behavior-y: auto !important;
}

.overscroll-y-contain{
  overscroll-behavior-y: contain !important;
}

.overscroll-y-none{
  overscroll-behavior-y: none !important;
}

.overscroll-x-auto{
  overscroll-behavior-x: auto !important;
}

.overscroll-x-contain{
  overscroll-behavior-x: contain !important;
}

.overscroll-x-none{
  overscroll-behavior-x: none !important;
}

.truncate{
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.overflow-ellipsis{
  text-overflow: ellipsis !important;
}

.overflow-clip{
  text-overflow: clip !important;
}

.whitespace-normal{
  white-space: normal !important;
}

.whitespace-nowrap{
  white-space: nowrap !important;
}

.whitespace-pre{
  white-space: pre !important;
}

.whitespace-pre-line{
  white-space: pre-line !important;
}

.whitespace-pre-wrap{
  white-space: pre-wrap !important;
}

.break-normal{
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-words{
  overflow-wrap: break-word !important;
}

.break-all{
  word-break: break-all !important;
}

.rounded-none{
  border-radius: 0px !important;
}

.rounded-sm{
  border-radius: 0.125rem !important;
}

.rounded{
  border-radius: 0.25rem !important;
}

.rounded-md{
  border-radius: 0.375rem !important;
}

.rounded-lg{
  border-radius: 0.5rem !important;
}

.rounded-xl{
  border-radius: 0.75rem !important;
}

.rounded-2xl{
  border-radius: 1rem !important;
}

.rounded-3xl{
  border-radius: 1.5rem !important;
}

.rounded-full{
  border-radius: 9999px !important;
}

.rounded-t-none{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.rounded-t-sm{
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-t{
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-t-md{
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-t-lg{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-t-xl{
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-t-2xl{
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-t-3xl{
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.rounded-t-full{
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.rounded-r-none{
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-r-sm{
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-r{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-r-md{
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-r-lg{
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-r-xl{
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-r-2xl{
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.rounded-r-3xl{
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-r-full{
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.rounded-b-none{
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-b-sm{
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-b{
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-b-md{
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-b-lg{
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-b-xl{
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-b-2xl{
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-b-3xl{
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-b-full{
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-l-none{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-l-sm{
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-l{
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-l-md{
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-l-lg{
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-l-xl{
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-l-2xl{
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-l-3xl{
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-l-full{
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-tl-none{
  border-top-left-radius: 0px !important;
}

.rounded-tl-sm{
  border-top-left-radius: 0.125rem !important;
}

.rounded-tl{
  border-top-left-radius: 0.25rem !important;
}

.rounded-tl-md{
  border-top-left-radius: 0.375rem !important;
}

.rounded-tl-lg{
  border-top-left-radius: 0.5rem !important;
}

.rounded-tl-xl{
  border-top-left-radius: 0.75rem !important;
}

.rounded-tl-2xl{
  border-top-left-radius: 1rem !important;
}

.rounded-tl-3xl{
  border-top-left-radius: 1.5rem !important;
}

.rounded-tl-full{
  border-top-left-radius: 9999px !important;
}

.rounded-tr-none{
  border-top-right-radius: 0px !important;
}

.rounded-tr-sm{
  border-top-right-radius: 0.125rem !important;
}

.rounded-tr{
  border-top-right-radius: 0.25rem !important;
}

.rounded-tr-md{
  border-top-right-radius: 0.375rem !important;
}

.rounded-tr-lg{
  border-top-right-radius: 0.5rem !important;
}

.rounded-tr-xl{
  border-top-right-radius: 0.75rem !important;
}

.rounded-tr-2xl{
  border-top-right-radius: 1rem !important;
}

.rounded-tr-3xl{
  border-top-right-radius: 1.5rem !important;
}

.rounded-tr-full{
  border-top-right-radius: 9999px !important;
}

.rounded-br-none{
  border-bottom-right-radius: 0px !important;
}

.rounded-br-sm{
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-br{
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-br-md{
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-br-lg{
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-br-xl{
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-br-2xl{
  border-bottom-right-radius: 1rem !important;
}

.rounded-br-3xl{
  border-bottom-right-radius: 1.5rem !important;
}

.rounded-br-full{
  border-bottom-right-radius: 9999px !important;
}

.rounded-bl-none{
  border-bottom-left-radius: 0px !important;
}

.rounded-bl-sm{
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-bl{
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-bl-md{
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-bl-lg{
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-bl-xl{
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-bl-2xl{
  border-bottom-left-radius: 1rem !important;
}

.rounded-bl-3xl{
  border-bottom-left-radius: 1.5rem !important;
}

.rounded-bl-full{
  border-bottom-left-radius: 9999px !important;
}

.border-0{
  border-width: 0px !important;
}

.border-2{
  border-width: 2px !important;
}

.border-4{
  border-width: 4px !important;
}

.border-8{
  border-width: 8px !important;
}

.border{
  border-width: 1px !important;
}

.border-t-0{
  border-top-width: 0px !important;
}

.border-t-2{
  border-top-width: 2px !important;
}

.border-t-4{
  border-top-width: 4px !important;
}

.border-t-8{
  border-top-width: 8px !important;
}

.border-t{
  border-top-width: 1px !important;
}

.border-r-0{
  border-right-width: 0px !important;
}

.border-r-2{
  border-right-width: 2px !important;
}

.border-r-4{
  border-right-width: 4px !important;
}

.border-r-8{
  border-right-width: 8px !important;
}

.border-r{
  border-right-width: 1px !important;
}

.border-b-0{
  border-bottom-width: 0px !important;
}

.border-b-2{
  border-bottom-width: 2px !important;
}

.border-b-4{
  border-bottom-width: 4px !important;
}

.border-b-8{
  border-bottom-width: 8px !important;
}

.border-b{
  border-bottom-width: 1px !important;
}

.border-l-0{
  border-left-width: 0px !important;
}

.border-l-2{
  border-left-width: 2px !important;
}

.border-l-4{
  border-left-width: 4px !important;
}

.border-l-8{
  border-left-width: 8px !important;
}

.border-l{
  border-left-width: 1px !important;
}

.border-solid{
  border-style: solid !important;
}

.border-dashed{
  border-style: dashed !important;
}

.border-dotted{
  border-style: dotted !important;
}

.border-double{
  border-style: double !important;
}

.border-none{
  border-style: none !important;
}

.border-transparent{
  border-color: transparent !important;
}

.border-current{
  border-color: currentColor !important;
}

.border-black{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.border-white{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.border-gray-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.border-gray-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.border-gray-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.border-gray-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.border-gray-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.border-gray-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.border-gray-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.border-gray-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.border-gray-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.border-gray-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.border-red-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.border-red-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.border-red-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.border-red-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.border-red-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.border-red-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.border-red-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.border-red-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.border-red-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.border-red-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.border-yellow-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.border-yellow-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.border-yellow-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.border-yellow-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.border-yellow-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.border-yellow-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.border-yellow-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.border-yellow-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.border-yellow-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.border-yellow-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.border-green-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.border-green-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.border-green-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.border-green-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.border-green-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.border-green-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.border-green-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.border-green-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.border-green-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.border-green-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.border-blue-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.border-blue-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.border-blue-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.border-blue-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.border-blue-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.border-blue-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.border-blue-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.border-blue-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.border-blue-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.border-blue-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.border-indigo-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.border-indigo-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.border-indigo-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.border-indigo-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.border-indigo-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.border-indigo-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.border-indigo-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.border-indigo-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.border-indigo-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.border-indigo-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.border-purple-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.border-purple-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.border-purple-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.border-purple-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.border-purple-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.border-purple-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.border-purple-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.border-purple-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.border-purple-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.border-purple-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.border-pink-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.border-pink-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.border-pink-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.border-pink-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.border-pink-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.border-pink-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.border-pink-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.border-pink-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.border-pink-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.border-pink-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.border-qipGreen{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
}

.border-portalGreen{
  --tw-border-opacity: 1 !important;
  border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-transparent{
  border-color: transparent !important;
}

.group:hover .group-hover\:border-current{
  border-color: currentColor !important;
}

.group:hover .group-hover\:border-black{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-white{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-gray-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-red-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-yellow-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-green-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-blue-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-indigo-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-purple-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-50{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-100{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-200{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-300{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-400{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-500{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-600{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-700{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-800{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-pink-900{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-qipGreen{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
}

.group:hover .group-hover\:border-portalGreen{
  --tw-border-opacity: 1 !important;
  border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
}

.focus-within\:border-transparent:focus-within{
  border-color: transparent !important;
}

.focus-within\:border-current:focus-within{
  border-color: currentColor !important;
}

.focus-within\:border-black:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.focus-within\:border-white:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.focus-within\:border-gray-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.focus-within\:border-red-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.focus-within\:border-yellow-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.focus-within\:border-green-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.focus-within\:border-blue-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.focus-within\:border-indigo-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.focus-within\:border-purple-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-50:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-100:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-200:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-300:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-400:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-500:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-600:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-700:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-800:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.focus-within\:border-pink-900:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.focus-within\:border-qipGreen:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
}

.focus-within\:border-portalGreen:focus-within{
  --tw-border-opacity: 1 !important;
  border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
}

.hover\:border-transparent:hover{
  border-color: transparent !important;
}

.hover\:border-current:hover{
  border-color: currentColor !important;
}

.hover\:border-black:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.hover\:border-white:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.hover\:border-gray-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.hover\:border-red-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.hover\:border-red-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.hover\:border-red-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.hover\:border-red-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.hover\:border-red-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.hover\:border-red-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.hover\:border-red-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.hover\:border-red-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.hover\:border-red-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.hover\:border-red-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.hover\:border-yellow-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.hover\:border-green-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.hover\:border-green-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.hover\:border-green-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.hover\:border-green-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.hover\:border-green-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.hover\:border-green-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.hover\:border-green-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.hover\:border-green-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.hover\:border-green-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.hover\:border-green-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.hover\:border-blue-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.hover\:border-indigo-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.hover\:border-purple-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-50:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-100:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-200:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-300:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-400:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-500:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-600:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-700:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-800:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.hover\:border-pink-900:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.hover\:border-qipGreen:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
}

.hover\:border-portalGreen:hover{
  --tw-border-opacity: 1 !important;
  border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
}

.focus\:border-transparent:focus{
  border-color: transparent !important;
}

.focus\:border-current:focus{
  border-color: currentColor !important;
}

.focus\:border-black:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
}

.focus\:border-white:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
}

.focus\:border-gray-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
}

.focus\:border-red-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
}

.focus\:border-red-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
}

.focus\:border-red-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
}

.focus\:border-red-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
}

.focus\:border-red-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
}

.focus\:border-red-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
}

.focus\:border-red-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
}

.focus\:border-red-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.focus\:border-red-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
}

.focus\:border-red-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
}

.focus\:border-yellow-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
}

.focus\:border-green-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
}

.focus\:border-green-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
}

.focus\:border-green-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
}

.focus\:border-green-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
}

.focus\:border-green-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
}

.focus\:border-green-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
}

.focus\:border-green-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
}

.focus\:border-green-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
}

.focus\:border-green-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
}

.focus\:border-green-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
}

.focus\:border-blue-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
}

.focus\:border-indigo-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
}

.focus\:border-purple-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-50:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-100:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-200:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-300:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-400:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-500:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-600:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-700:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-800:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
}

.focus\:border-pink-900:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
}

.focus\:border-qipGreen:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
}

.focus\:border-portalGreen:focus{
  --tw-border-opacity: 1 !important;
  border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
}

.border-opacity-0{
  --tw-border-opacity: 0 !important;
}

.border-opacity-5{
  --tw-border-opacity: 0.05 !important;
}

.border-opacity-10{
  --tw-border-opacity: 0.1 !important;
}

.border-opacity-20{
  --tw-border-opacity: 0.2 !important;
}

.border-opacity-25{
  --tw-border-opacity: 0.25 !important;
}

.border-opacity-30{
  --tw-border-opacity: 0.3 !important;
}

.border-opacity-40{
  --tw-border-opacity: 0.4 !important;
}

.border-opacity-50{
  --tw-border-opacity: 0.5 !important;
}

.border-opacity-60{
  --tw-border-opacity: 0.6 !important;
}

.border-opacity-70{
  --tw-border-opacity: 0.7 !important;
}

.border-opacity-75{
  --tw-border-opacity: 0.75 !important;
}

.border-opacity-80{
  --tw-border-opacity: 0.8 !important;
}

.border-opacity-90{
  --tw-border-opacity: 0.9 !important;
}

.border-opacity-95{
  --tw-border-opacity: 0.95 !important;
}

.border-opacity-100{
  --tw-border-opacity: 1 !important;
}

.group:hover .group-hover\:border-opacity-0{
  --tw-border-opacity: 0 !important;
}

.group:hover .group-hover\:border-opacity-5{
  --tw-border-opacity: 0.05 !important;
}

.group:hover .group-hover\:border-opacity-10{
  --tw-border-opacity: 0.1 !important;
}

.group:hover .group-hover\:border-opacity-20{
  --tw-border-opacity: 0.2 !important;
}

.group:hover .group-hover\:border-opacity-25{
  --tw-border-opacity: 0.25 !important;
}

.group:hover .group-hover\:border-opacity-30{
  --tw-border-opacity: 0.3 !important;
}

.group:hover .group-hover\:border-opacity-40{
  --tw-border-opacity: 0.4 !important;
}

.group:hover .group-hover\:border-opacity-50{
  --tw-border-opacity: 0.5 !important;
}

.group:hover .group-hover\:border-opacity-60{
  --tw-border-opacity: 0.6 !important;
}

.group:hover .group-hover\:border-opacity-70{
  --tw-border-opacity: 0.7 !important;
}

.group:hover .group-hover\:border-opacity-75{
  --tw-border-opacity: 0.75 !important;
}

.group:hover .group-hover\:border-opacity-80{
  --tw-border-opacity: 0.8 !important;
}

.group:hover .group-hover\:border-opacity-90{
  --tw-border-opacity: 0.9 !important;
}

.group:hover .group-hover\:border-opacity-95{
  --tw-border-opacity: 0.95 !important;
}

.group:hover .group-hover\:border-opacity-100{
  --tw-border-opacity: 1 !important;
}

.focus-within\:border-opacity-0:focus-within{
  --tw-border-opacity: 0 !important;
}

.focus-within\:border-opacity-5:focus-within{
  --tw-border-opacity: 0.05 !important;
}

.focus-within\:border-opacity-10:focus-within{
  --tw-border-opacity: 0.1 !important;
}

.focus-within\:border-opacity-20:focus-within{
  --tw-border-opacity: 0.2 !important;
}

.focus-within\:border-opacity-25:focus-within{
  --tw-border-opacity: 0.25 !important;
}

.focus-within\:border-opacity-30:focus-within{
  --tw-border-opacity: 0.3 !important;
}

.focus-within\:border-opacity-40:focus-within{
  --tw-border-opacity: 0.4 !important;
}

.focus-within\:border-opacity-50:focus-within{
  --tw-border-opacity: 0.5 !important;
}

.focus-within\:border-opacity-60:focus-within{
  --tw-border-opacity: 0.6 !important;
}

.focus-within\:border-opacity-70:focus-within{
  --tw-border-opacity: 0.7 !important;
}

.focus-within\:border-opacity-75:focus-within{
  --tw-border-opacity: 0.75 !important;
}

.focus-within\:border-opacity-80:focus-within{
  --tw-border-opacity: 0.8 !important;
}

.focus-within\:border-opacity-90:focus-within{
  --tw-border-opacity: 0.9 !important;
}

.focus-within\:border-opacity-95:focus-within{
  --tw-border-opacity: 0.95 !important;
}

.focus-within\:border-opacity-100:focus-within{
  --tw-border-opacity: 1 !important;
}

.hover\:border-opacity-0:hover{
  --tw-border-opacity: 0 !important;
}

.hover\:border-opacity-5:hover{
  --tw-border-opacity: 0.05 !important;
}

.hover\:border-opacity-10:hover{
  --tw-border-opacity: 0.1 !important;
}

.hover\:border-opacity-20:hover{
  --tw-border-opacity: 0.2 !important;
}

.hover\:border-opacity-25:hover{
  --tw-border-opacity: 0.25 !important;
}

.hover\:border-opacity-30:hover{
  --tw-border-opacity: 0.3 !important;
}

.hover\:border-opacity-40:hover{
  --tw-border-opacity: 0.4 !important;
}

.hover\:border-opacity-50:hover{
  --tw-border-opacity: 0.5 !important;
}

.hover\:border-opacity-60:hover{
  --tw-border-opacity: 0.6 !important;
}

.hover\:border-opacity-70:hover{
  --tw-border-opacity: 0.7 !important;
}

.hover\:border-opacity-75:hover{
  --tw-border-opacity: 0.75 !important;
}

.hover\:border-opacity-80:hover{
  --tw-border-opacity: 0.8 !important;
}

.hover\:border-opacity-90:hover{
  --tw-border-opacity: 0.9 !important;
}

.hover\:border-opacity-95:hover{
  --tw-border-opacity: 0.95 !important;
}

.hover\:border-opacity-100:hover{
  --tw-border-opacity: 1 !important;
}

.focus\:border-opacity-0:focus{
  --tw-border-opacity: 0 !important;
}

.focus\:border-opacity-5:focus{
  --tw-border-opacity: 0.05 !important;
}

.focus\:border-opacity-10:focus{
  --tw-border-opacity: 0.1 !important;
}

.focus\:border-opacity-20:focus{
  --tw-border-opacity: 0.2 !important;
}

.focus\:border-opacity-25:focus{
  --tw-border-opacity: 0.25 !important;
}

.focus\:border-opacity-30:focus{
  --tw-border-opacity: 0.3 !important;
}

.focus\:border-opacity-40:focus{
  --tw-border-opacity: 0.4 !important;
}

.focus\:border-opacity-50:focus{
  --tw-border-opacity: 0.5 !important;
}

.focus\:border-opacity-60:focus{
  --tw-border-opacity: 0.6 !important;
}

.focus\:border-opacity-70:focus{
  --tw-border-opacity: 0.7 !important;
}

.focus\:border-opacity-75:focus{
  --tw-border-opacity: 0.75 !important;
}

.focus\:border-opacity-80:focus{
  --tw-border-opacity: 0.8 !important;
}

.focus\:border-opacity-90:focus{
  --tw-border-opacity: 0.9 !important;
}

.focus\:border-opacity-95:focus{
  --tw-border-opacity: 0.95 !important;
}

.focus\:border-opacity-100:focus{
  --tw-border-opacity: 1 !important;
}

.bg-transparent{
  background-color: transparent !important;
}

.bg-current{
  background-color: currentColor !important;
}

.bg-black{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.bg-gray-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.bg-gray-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.bg-gray-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.bg-gray-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.bg-gray-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.bg-gray-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.bg-gray-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.bg-gray-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.bg-gray-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.bg-gray-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.bg-red-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.bg-red-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.bg-red-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.bg-red-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.bg-red-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.bg-red-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.bg-red-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.bg-red-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.bg-red-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.bg-red-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.bg-yellow-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.bg-yellow-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.bg-yellow-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.bg-yellow-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.bg-yellow-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.bg-yellow-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.bg-yellow-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.bg-yellow-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.bg-yellow-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.bg-yellow-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.bg-green-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.bg-green-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.bg-green-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.bg-green-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.bg-green-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.bg-green-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.bg-green-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.bg-green-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.bg-green-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.bg-green-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.bg-blue-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.bg-blue-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.bg-blue-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.bg-blue-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.bg-blue-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.bg-blue-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.bg-blue-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.bg-blue-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.bg-blue-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.bg-blue-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.bg-indigo-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.bg-indigo-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.bg-indigo-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.bg-indigo-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.bg-indigo-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.bg-indigo-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.bg-indigo-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.bg-indigo-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.bg-indigo-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.bg-indigo-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.bg-purple-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.bg-purple-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.bg-purple-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.bg-purple-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.bg-purple-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.bg-purple-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.bg-purple-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.bg-purple-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.bg-purple-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.bg-purple-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.bg-pink-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.bg-pink-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.bg-pink-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.bg-pink-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.bg-pink-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.bg-pink-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.bg-pink-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.bg-pink-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.bg-pink-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.bg-pink-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.bg-qipGreen{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
}

.bg-portalGreen{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-transparent{
  background-color: transparent !important;
}

.group:hover .group-hover\:bg-current{
  background-color: currentColor !important;
}

.group:hover .group-hover\:bg-black{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-white{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-gray-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-red-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-yellow-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-green-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-blue-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-indigo-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-purple-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-50{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-100{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-400{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-600{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-700{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-800{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-pink-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-qipGreen{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
}

.group:hover .group-hover\:bg-portalGreen{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-transparent:focus-within{
  background-color: transparent !important;
}

.focus-within\:bg-current:focus-within{
  background-color: currentColor !important;
}

.focus-within\:bg-black:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-white:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-gray-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-red-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-yellow-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-green-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-blue-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-indigo-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-purple-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-50:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-100:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-200:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-300:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-400:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-500:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-600:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-700:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-800:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-pink-900:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-qipGreen:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
}

.focus-within\:bg-portalGreen:focus-within{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
}

.hover\:bg-transparent:hover{
  background-color: transparent !important;
}

.hover\:bg-current:hover{
  background-color: currentColor !important;
}

.hover\:bg-black:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.hover\:bg-white:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.hover\:bg-gray-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.hover\:bg-red-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.hover\:bg-yellow-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.hover\:bg-green-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.hover\:bg-blue-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.hover\:bg-indigo-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.hover\:bg-purple-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-50:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-100:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-200:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-300:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-400:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-500:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-600:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-700:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-800:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.hover\:bg-pink-900:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.hover\:bg-qipGreen:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
}

.hover\:bg-portalGreen:hover{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
}

.focus\:bg-transparent:focus{
  background-color: transparent !important;
}

.focus\:bg-current:focus{
  background-color: currentColor !important;
}

.focus\:bg-black:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
}

.focus\:bg-white:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
}

.focus\:bg-gray-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
}

.focus\:bg-red-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
}

.focus\:bg-yellow-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
}

.focus\:bg-green-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
}

.focus\:bg-blue-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
}

.focus\:bg-indigo-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
}

.focus\:bg-purple-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-50:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-100:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-200:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-300:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-400:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-500:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-600:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-700:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-800:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
}

.focus\:bg-pink-900:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
}

.focus\:bg-qipGreen:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
}

.focus\:bg-portalGreen:focus{
  --tw-bg-opacity: 1 !important;
  background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
}

.bg-opacity-0{
  --tw-bg-opacity: 0 !important;
}

.bg-opacity-5{
  --tw-bg-opacity: 0.05 !important;
}

.bg-opacity-10{
  --tw-bg-opacity: 0.1 !important;
}

.bg-opacity-20{
  --tw-bg-opacity: 0.2 !important;
}

.bg-opacity-25{
  --tw-bg-opacity: 0.25 !important;
}

.bg-opacity-30{
  --tw-bg-opacity: 0.3 !important;
}

.bg-opacity-40{
  --tw-bg-opacity: 0.4 !important;
}

.bg-opacity-50{
  --tw-bg-opacity: 0.5 !important;
}

.bg-opacity-60{
  --tw-bg-opacity: 0.6 !important;
}

.bg-opacity-70{
  --tw-bg-opacity: 0.7 !important;
}

.bg-opacity-75{
  --tw-bg-opacity: 0.75 !important;
}

.bg-opacity-80{
  --tw-bg-opacity: 0.8 !important;
}

.bg-opacity-90{
  --tw-bg-opacity: 0.9 !important;
}

.bg-opacity-95{
  --tw-bg-opacity: 0.95 !important;
}

.bg-opacity-100{
  --tw-bg-opacity: 1 !important;
}

.group:hover .group-hover\:bg-opacity-0{
  --tw-bg-opacity: 0 !important;
}

.group:hover .group-hover\:bg-opacity-5{
  --tw-bg-opacity: 0.05 !important;
}

.group:hover .group-hover\:bg-opacity-10{
  --tw-bg-opacity: 0.1 !important;
}

.group:hover .group-hover\:bg-opacity-20{
  --tw-bg-opacity: 0.2 !important;
}

.group:hover .group-hover\:bg-opacity-25{
  --tw-bg-opacity: 0.25 !important;
}

.group:hover .group-hover\:bg-opacity-30{
  --tw-bg-opacity: 0.3 !important;
}

.group:hover .group-hover\:bg-opacity-40{
  --tw-bg-opacity: 0.4 !important;
}

.group:hover .group-hover\:bg-opacity-50{
  --tw-bg-opacity: 0.5 !important;
}

.group:hover .group-hover\:bg-opacity-60{
  --tw-bg-opacity: 0.6 !important;
}

.group:hover .group-hover\:bg-opacity-70{
  --tw-bg-opacity: 0.7 !important;
}

.group:hover .group-hover\:bg-opacity-75{
  --tw-bg-opacity: 0.75 !important;
}

.group:hover .group-hover\:bg-opacity-80{
  --tw-bg-opacity: 0.8 !important;
}

.group:hover .group-hover\:bg-opacity-90{
  --tw-bg-opacity: 0.9 !important;
}

.group:hover .group-hover\:bg-opacity-95{
  --tw-bg-opacity: 0.95 !important;
}

.group:hover .group-hover\:bg-opacity-100{
  --tw-bg-opacity: 1 !important;
}

.focus-within\:bg-opacity-0:focus-within{
  --tw-bg-opacity: 0 !important;
}

.focus-within\:bg-opacity-5:focus-within{
  --tw-bg-opacity: 0.05 !important;
}

.focus-within\:bg-opacity-10:focus-within{
  --tw-bg-opacity: 0.1 !important;
}

.focus-within\:bg-opacity-20:focus-within{
  --tw-bg-opacity: 0.2 !important;
}

.focus-within\:bg-opacity-25:focus-within{
  --tw-bg-opacity: 0.25 !important;
}

.focus-within\:bg-opacity-30:focus-within{
  --tw-bg-opacity: 0.3 !important;
}

.focus-within\:bg-opacity-40:focus-within{
  --tw-bg-opacity: 0.4 !important;
}

.focus-within\:bg-opacity-50:focus-within{
  --tw-bg-opacity: 0.5 !important;
}

.focus-within\:bg-opacity-60:focus-within{
  --tw-bg-opacity: 0.6 !important;
}

.focus-within\:bg-opacity-70:focus-within{
  --tw-bg-opacity: 0.7 !important;
}

.focus-within\:bg-opacity-75:focus-within{
  --tw-bg-opacity: 0.75 !important;
}

.focus-within\:bg-opacity-80:focus-within{
  --tw-bg-opacity: 0.8 !important;
}

.focus-within\:bg-opacity-90:focus-within{
  --tw-bg-opacity: 0.9 !important;
}

.focus-within\:bg-opacity-95:focus-within{
  --tw-bg-opacity: 0.95 !important;
}

.focus-within\:bg-opacity-100:focus-within{
  --tw-bg-opacity: 1 !important;
}

.hover\:bg-opacity-0:hover{
  --tw-bg-opacity: 0 !important;
}

.hover\:bg-opacity-5:hover{
  --tw-bg-opacity: 0.05 !important;
}

.hover\:bg-opacity-10:hover{
  --tw-bg-opacity: 0.1 !important;
}

.hover\:bg-opacity-20:hover{
  --tw-bg-opacity: 0.2 !important;
}

.hover\:bg-opacity-25:hover{
  --tw-bg-opacity: 0.25 !important;
}

.hover\:bg-opacity-30:hover{
  --tw-bg-opacity: 0.3 !important;
}

.hover\:bg-opacity-40:hover{
  --tw-bg-opacity: 0.4 !important;
}

.hover\:bg-opacity-50:hover{
  --tw-bg-opacity: 0.5 !important;
}

.hover\:bg-opacity-60:hover{
  --tw-bg-opacity: 0.6 !important;
}

.hover\:bg-opacity-70:hover{
  --tw-bg-opacity: 0.7 !important;
}

.hover\:bg-opacity-75:hover{
  --tw-bg-opacity: 0.75 !important;
}

.hover\:bg-opacity-80:hover{
  --tw-bg-opacity: 0.8 !important;
}

.hover\:bg-opacity-90:hover{
  --tw-bg-opacity: 0.9 !important;
}

.hover\:bg-opacity-95:hover{
  --tw-bg-opacity: 0.95 !important;
}

.hover\:bg-opacity-100:hover{
  --tw-bg-opacity: 1 !important;
}

.focus\:bg-opacity-0:focus{
  --tw-bg-opacity: 0 !important;
}

.focus\:bg-opacity-5:focus{
  --tw-bg-opacity: 0.05 !important;
}

.focus\:bg-opacity-10:focus{
  --tw-bg-opacity: 0.1 !important;
}

.focus\:bg-opacity-20:focus{
  --tw-bg-opacity: 0.2 !important;
}

.focus\:bg-opacity-25:focus{
  --tw-bg-opacity: 0.25 !important;
}

.focus\:bg-opacity-30:focus{
  --tw-bg-opacity: 0.3 !important;
}

.focus\:bg-opacity-40:focus{
  --tw-bg-opacity: 0.4 !important;
}

.focus\:bg-opacity-50:focus{
  --tw-bg-opacity: 0.5 !important;
}

.focus\:bg-opacity-60:focus{
  --tw-bg-opacity: 0.6 !important;
}

.focus\:bg-opacity-70:focus{
  --tw-bg-opacity: 0.7 !important;
}

.focus\:bg-opacity-75:focus{
  --tw-bg-opacity: 0.75 !important;
}

.focus\:bg-opacity-80:focus{
  --tw-bg-opacity: 0.8 !important;
}

.focus\:bg-opacity-90:focus{
  --tw-bg-opacity: 0.9 !important;
}

.focus\:bg-opacity-95:focus{
  --tw-bg-opacity: 0.95 !important;
}

.focus\:bg-opacity-100:focus{
  --tw-bg-opacity: 1 !important;
}

.bg-none{
  background-image: none !important;
}

.bg-gradient-to-t{
  background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-tr{
  background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-b{
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-bl{
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-l{
  background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
}

.bg-gradient-to-tl{
  background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
}

.from-transparent{
  --tw-gradient-from: transparent !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.from-current{
  --tw-gradient-from: currentColor !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.from-black{
  --tw-gradient-from: #000 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.from-white{
  --tw-gradient-from: #fff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.from-gray-50{
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.from-gray-100{
  --tw-gradient-from: #f3f4f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.from-gray-200{
  --tw-gradient-from: #e5e7eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.from-gray-300{
  --tw-gradient-from: #d1d5db !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.from-gray-400{
  --tw-gradient-from: #9ca3af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.from-gray-500{
  --tw-gradient-from: #6b7280 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.from-gray-600{
  --tw-gradient-from: #4b5563 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.from-gray-700{
  --tw-gradient-from: #374151 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.from-gray-800{
  --tw-gradient-from: #1f2937 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.from-gray-900{
  --tw-gradient-from: #111827 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.from-red-50{
  --tw-gradient-from: #fef2f2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.from-red-100{
  --tw-gradient-from: #fee2e2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.from-red-200{
  --tw-gradient-from: #fecaca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.from-red-300{
  --tw-gradient-from: #fca5a5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.from-red-400{
  --tw-gradient-from: #f87171 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.from-red-500{
  --tw-gradient-from: #ef4444 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.from-red-600{
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.from-red-700{
  --tw-gradient-from: #b91c1c !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.from-red-800{
  --tw-gradient-from: #991b1b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.from-red-900{
  --tw-gradient-from: #7f1d1d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.from-yellow-50{
  --tw-gradient-from: #fffbeb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.from-yellow-100{
  --tw-gradient-from: #fef3c7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.from-yellow-200{
  --tw-gradient-from: #fde68a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.from-yellow-300{
  --tw-gradient-from: #fcd34d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.from-yellow-400{
  --tw-gradient-from: #fbbf24 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.from-yellow-500{
  --tw-gradient-from: #f59e0b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.from-yellow-600{
  --tw-gradient-from: #d97706 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.from-yellow-700{
  --tw-gradient-from: #b45309 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.from-yellow-800{
  --tw-gradient-from: #92400e !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.from-yellow-900{
  --tw-gradient-from: #78350f !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.from-green-50{
  --tw-gradient-from: #ecfdf5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.from-green-100{
  --tw-gradient-from: #d1fae5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.from-green-200{
  --tw-gradient-from: #a7f3d0 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.from-green-300{
  --tw-gradient-from: #6ee7b7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.from-green-400{
  --tw-gradient-from: #34d399 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.from-green-500{
  --tw-gradient-from: #10b981 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.from-green-600{
  --tw-gradient-from: #059669 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.from-green-700{
  --tw-gradient-from: #047857 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.from-green-800{
  --tw-gradient-from: #065f46 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.from-green-900{
  --tw-gradient-from: #064e3b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.from-blue-50{
  --tw-gradient-from: #eff6ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.from-blue-100{
  --tw-gradient-from: #dbeafe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.from-blue-200{
  --tw-gradient-from: #bfdbfe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.from-blue-300{
  --tw-gradient-from: #93c5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.from-blue-400{
  --tw-gradient-from: #60a5fa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.from-blue-500{
  --tw-gradient-from: #3b82f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.from-blue-600{
  --tw-gradient-from: #2563eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.from-blue-700{
  --tw-gradient-from: #1d4ed8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.from-blue-800{
  --tw-gradient-from: #1e40af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.from-blue-900{
  --tw-gradient-from: #1e3a8a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.from-indigo-50{
  --tw-gradient-from: #eef2ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.from-indigo-100{
  --tw-gradient-from: #e0e7ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.from-indigo-200{
  --tw-gradient-from: #c7d2fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.from-indigo-300{
  --tw-gradient-from: #a5b4fc !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.from-indigo-400{
  --tw-gradient-from: #818cf8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.from-indigo-500{
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.from-indigo-600{
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.from-indigo-700{
  --tw-gradient-from: #4338ca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.from-indigo-800{
  --tw-gradient-from: #3730a3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.from-indigo-900{
  --tw-gradient-from: #312e81 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.from-purple-50{
  --tw-gradient-from: #f5f3ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.from-purple-100{
  --tw-gradient-from: #ede9fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.from-purple-200{
  --tw-gradient-from: #ddd6fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.from-purple-300{
  --tw-gradient-from: #c4b5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.from-purple-400{
  --tw-gradient-from: #a78bfa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.from-purple-500{
  --tw-gradient-from: #8b5cf6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.from-purple-600{
  --tw-gradient-from: #7c3aed !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.from-purple-700{
  --tw-gradient-from: #6d28d9 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.from-purple-800{
  --tw-gradient-from: #5b21b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.from-purple-900{
  --tw-gradient-from: #4c1d95 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.from-pink-50{
  --tw-gradient-from: #fdf2f8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.from-pink-100{
  --tw-gradient-from: #fce7f3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.from-pink-200{
  --tw-gradient-from: #fbcfe8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.from-pink-300{
  --tw-gradient-from: #f9a8d4 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.from-pink-400{
  --tw-gradient-from: #f472b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.from-pink-500{
  --tw-gradient-from: #ec4899 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.from-pink-600{
  --tw-gradient-from: #db2777 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.from-pink-700{
  --tw-gradient-from: #be185d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.from-pink-800{
  --tw-gradient-from: #9d174d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.from-pink-900{
  --tw-gradient-from: #831843 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.from-qipGreen{
  --tw-gradient-from: #8bc334 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
}

.from-portalGreen{
  --tw-gradient-from: #8ac04a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
}

.hover\:from-transparent:hover{
  --tw-gradient-from: transparent !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:from-current:hover{
  --tw-gradient-from: currentColor !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:from-black:hover{
  --tw-gradient-from: #000 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:from-white:hover{
  --tw-gradient-from: #fff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:from-gray-50:hover{
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.hover\:from-gray-100:hover{
  --tw-gradient-from: #f3f4f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.hover\:from-gray-200:hover{
  --tw-gradient-from: #e5e7eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.hover\:from-gray-300:hover{
  --tw-gradient-from: #d1d5db !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.hover\:from-gray-400:hover{
  --tw-gradient-from: #9ca3af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.hover\:from-gray-500:hover{
  --tw-gradient-from: #6b7280 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.hover\:from-gray-600:hover{
  --tw-gradient-from: #4b5563 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.hover\:from-gray-700:hover{
  --tw-gradient-from: #374151 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.hover\:from-gray-800:hover{
  --tw-gradient-from: #1f2937 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.hover\:from-gray-900:hover{
  --tw-gradient-from: #111827 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.hover\:from-red-50:hover{
  --tw-gradient-from: #fef2f2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.hover\:from-red-100:hover{
  --tw-gradient-from: #fee2e2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.hover\:from-red-200:hover{
  --tw-gradient-from: #fecaca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.hover\:from-red-300:hover{
  --tw-gradient-from: #fca5a5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.hover\:from-red-400:hover{
  --tw-gradient-from: #f87171 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.hover\:from-red-500:hover{
  --tw-gradient-from: #ef4444 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.hover\:from-red-600:hover{
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.hover\:from-red-700:hover{
  --tw-gradient-from: #b91c1c !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.hover\:from-red-800:hover{
  --tw-gradient-from: #991b1b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.hover\:from-red-900:hover{
  --tw-gradient-from: #7f1d1d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.hover\:from-yellow-50:hover{
  --tw-gradient-from: #fffbeb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.hover\:from-yellow-100:hover{
  --tw-gradient-from: #fef3c7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.hover\:from-yellow-200:hover{
  --tw-gradient-from: #fde68a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.hover\:from-yellow-300:hover{
  --tw-gradient-from: #fcd34d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.hover\:from-yellow-400:hover{
  --tw-gradient-from: #fbbf24 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.hover\:from-yellow-500:hover{
  --tw-gradient-from: #f59e0b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.hover\:from-yellow-600:hover{
  --tw-gradient-from: #d97706 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.hover\:from-yellow-700:hover{
  --tw-gradient-from: #b45309 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.hover\:from-yellow-800:hover{
  --tw-gradient-from: #92400e !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.hover\:from-yellow-900:hover{
  --tw-gradient-from: #78350f !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.hover\:from-green-50:hover{
  --tw-gradient-from: #ecfdf5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.hover\:from-green-100:hover{
  --tw-gradient-from: #d1fae5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.hover\:from-green-200:hover{
  --tw-gradient-from: #a7f3d0 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.hover\:from-green-300:hover{
  --tw-gradient-from: #6ee7b7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.hover\:from-green-400:hover{
  --tw-gradient-from: #34d399 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.hover\:from-green-500:hover{
  --tw-gradient-from: #10b981 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.hover\:from-green-600:hover{
  --tw-gradient-from: #059669 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.hover\:from-green-700:hover{
  --tw-gradient-from: #047857 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.hover\:from-green-800:hover{
  --tw-gradient-from: #065f46 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.hover\:from-green-900:hover{
  --tw-gradient-from: #064e3b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.hover\:from-blue-50:hover{
  --tw-gradient-from: #eff6ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.hover\:from-blue-100:hover{
  --tw-gradient-from: #dbeafe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.hover\:from-blue-200:hover{
  --tw-gradient-from: #bfdbfe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.hover\:from-blue-300:hover{
  --tw-gradient-from: #93c5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.hover\:from-blue-400:hover{
  --tw-gradient-from: #60a5fa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.hover\:from-blue-500:hover{
  --tw-gradient-from: #3b82f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.hover\:from-blue-600:hover{
  --tw-gradient-from: #2563eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.hover\:from-blue-700:hover{
  --tw-gradient-from: #1d4ed8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.hover\:from-blue-800:hover{
  --tw-gradient-from: #1e40af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.hover\:from-blue-900:hover{
  --tw-gradient-from: #1e3a8a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.hover\:from-indigo-50:hover{
  --tw-gradient-from: #eef2ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.hover\:from-indigo-100:hover{
  --tw-gradient-from: #e0e7ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.hover\:from-indigo-200:hover{
  --tw-gradient-from: #c7d2fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.hover\:from-indigo-300:hover{
  --tw-gradient-from: #a5b4fc !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.hover\:from-indigo-400:hover{
  --tw-gradient-from: #818cf8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.hover\:from-indigo-500:hover{
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.hover\:from-indigo-600:hover{
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.hover\:from-indigo-700:hover{
  --tw-gradient-from: #4338ca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.hover\:from-indigo-800:hover{
  --tw-gradient-from: #3730a3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.hover\:from-indigo-900:hover{
  --tw-gradient-from: #312e81 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.hover\:from-purple-50:hover{
  --tw-gradient-from: #f5f3ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.hover\:from-purple-100:hover{
  --tw-gradient-from: #ede9fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.hover\:from-purple-200:hover{
  --tw-gradient-from: #ddd6fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.hover\:from-purple-300:hover{
  --tw-gradient-from: #c4b5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.hover\:from-purple-400:hover{
  --tw-gradient-from: #a78bfa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.hover\:from-purple-500:hover{
  --tw-gradient-from: #8b5cf6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.hover\:from-purple-600:hover{
  --tw-gradient-from: #7c3aed !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.hover\:from-purple-700:hover{
  --tw-gradient-from: #6d28d9 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.hover\:from-purple-800:hover{
  --tw-gradient-from: #5b21b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.hover\:from-purple-900:hover{
  --tw-gradient-from: #4c1d95 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.hover\:from-pink-50:hover{
  --tw-gradient-from: #fdf2f8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.hover\:from-pink-100:hover{
  --tw-gradient-from: #fce7f3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.hover\:from-pink-200:hover{
  --tw-gradient-from: #fbcfe8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.hover\:from-pink-300:hover{
  --tw-gradient-from: #f9a8d4 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.hover\:from-pink-400:hover{
  --tw-gradient-from: #f472b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.hover\:from-pink-500:hover{
  --tw-gradient-from: #ec4899 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.hover\:from-pink-600:hover{
  --tw-gradient-from: #db2777 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.hover\:from-pink-700:hover{
  --tw-gradient-from: #be185d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.hover\:from-pink-800:hover{
  --tw-gradient-from: #9d174d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.hover\:from-pink-900:hover{
  --tw-gradient-from: #831843 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.hover\:from-qipGreen:hover{
  --tw-gradient-from: #8bc334 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
}

.hover\:from-portalGreen:hover{
  --tw-gradient-from: #8ac04a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
}

.focus\:from-transparent:focus{
  --tw-gradient-from: transparent !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:from-current:focus{
  --tw-gradient-from: currentColor !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:from-black:focus{
  --tw-gradient-from: #000 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:from-white:focus{
  --tw-gradient-from: #fff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:from-gray-50:focus{
  --tw-gradient-from: #f9fafb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.focus\:from-gray-100:focus{
  --tw-gradient-from: #f3f4f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.focus\:from-gray-200:focus{
  --tw-gradient-from: #e5e7eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.focus\:from-gray-300:focus{
  --tw-gradient-from: #d1d5db !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.focus\:from-gray-400:focus{
  --tw-gradient-from: #9ca3af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.focus\:from-gray-500:focus{
  --tw-gradient-from: #6b7280 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.focus\:from-gray-600:focus{
  --tw-gradient-from: #4b5563 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.focus\:from-gray-700:focus{
  --tw-gradient-from: #374151 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.focus\:from-gray-800:focus{
  --tw-gradient-from: #1f2937 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.focus\:from-gray-900:focus{
  --tw-gradient-from: #111827 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.focus\:from-red-50:focus{
  --tw-gradient-from: #fef2f2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.focus\:from-red-100:focus{
  --tw-gradient-from: #fee2e2 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.focus\:from-red-200:focus{
  --tw-gradient-from: #fecaca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.focus\:from-red-300:focus{
  --tw-gradient-from: #fca5a5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.focus\:from-red-400:focus{
  --tw-gradient-from: #f87171 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.focus\:from-red-500:focus{
  --tw-gradient-from: #ef4444 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.focus\:from-red-600:focus{
  --tw-gradient-from: #dc2626 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.focus\:from-red-700:focus{
  --tw-gradient-from: #b91c1c !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.focus\:from-red-800:focus{
  --tw-gradient-from: #991b1b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.focus\:from-red-900:focus{
  --tw-gradient-from: #7f1d1d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.focus\:from-yellow-50:focus{
  --tw-gradient-from: #fffbeb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.focus\:from-yellow-100:focus{
  --tw-gradient-from: #fef3c7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.focus\:from-yellow-200:focus{
  --tw-gradient-from: #fde68a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.focus\:from-yellow-300:focus{
  --tw-gradient-from: #fcd34d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.focus\:from-yellow-400:focus{
  --tw-gradient-from: #fbbf24 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.focus\:from-yellow-500:focus{
  --tw-gradient-from: #f59e0b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.focus\:from-yellow-600:focus{
  --tw-gradient-from: #d97706 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.focus\:from-yellow-700:focus{
  --tw-gradient-from: #b45309 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.focus\:from-yellow-800:focus{
  --tw-gradient-from: #92400e !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.focus\:from-yellow-900:focus{
  --tw-gradient-from: #78350f !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.focus\:from-green-50:focus{
  --tw-gradient-from: #ecfdf5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.focus\:from-green-100:focus{
  --tw-gradient-from: #d1fae5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.focus\:from-green-200:focus{
  --tw-gradient-from: #a7f3d0 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.focus\:from-green-300:focus{
  --tw-gradient-from: #6ee7b7 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.focus\:from-green-400:focus{
  --tw-gradient-from: #34d399 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.focus\:from-green-500:focus{
  --tw-gradient-from: #10b981 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.focus\:from-green-600:focus{
  --tw-gradient-from: #059669 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.focus\:from-green-700:focus{
  --tw-gradient-from: #047857 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.focus\:from-green-800:focus{
  --tw-gradient-from: #065f46 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.focus\:from-green-900:focus{
  --tw-gradient-from: #064e3b !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.focus\:from-blue-50:focus{
  --tw-gradient-from: #eff6ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.focus\:from-blue-100:focus{
  --tw-gradient-from: #dbeafe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.focus\:from-blue-200:focus{
  --tw-gradient-from: #bfdbfe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.focus\:from-blue-300:focus{
  --tw-gradient-from: #93c5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.focus\:from-blue-400:focus{
  --tw-gradient-from: #60a5fa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.focus\:from-blue-500:focus{
  --tw-gradient-from: #3b82f6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.focus\:from-blue-600:focus{
  --tw-gradient-from: #2563eb !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.focus\:from-blue-700:focus{
  --tw-gradient-from: #1d4ed8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.focus\:from-blue-800:focus{
  --tw-gradient-from: #1e40af !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.focus\:from-blue-900:focus{
  --tw-gradient-from: #1e3a8a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.focus\:from-indigo-50:focus{
  --tw-gradient-from: #eef2ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.focus\:from-indigo-100:focus{
  --tw-gradient-from: #e0e7ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.focus\:from-indigo-200:focus{
  --tw-gradient-from: #c7d2fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.focus\:from-indigo-300:focus{
  --tw-gradient-from: #a5b4fc !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.focus\:from-indigo-400:focus{
  --tw-gradient-from: #818cf8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.focus\:from-indigo-500:focus{
  --tw-gradient-from: #6366f1 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.focus\:from-indigo-600:focus{
  --tw-gradient-from: #4f46e5 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.focus\:from-indigo-700:focus{
  --tw-gradient-from: #4338ca !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.focus\:from-indigo-800:focus{
  --tw-gradient-from: #3730a3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.focus\:from-indigo-900:focus{
  --tw-gradient-from: #312e81 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.focus\:from-purple-50:focus{
  --tw-gradient-from: #f5f3ff !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.focus\:from-purple-100:focus{
  --tw-gradient-from: #ede9fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.focus\:from-purple-200:focus{
  --tw-gradient-from: #ddd6fe !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.focus\:from-purple-300:focus{
  --tw-gradient-from: #c4b5fd !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.focus\:from-purple-400:focus{
  --tw-gradient-from: #a78bfa !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.focus\:from-purple-500:focus{
  --tw-gradient-from: #8b5cf6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.focus\:from-purple-600:focus{
  --tw-gradient-from: #7c3aed !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.focus\:from-purple-700:focus{
  --tw-gradient-from: #6d28d9 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.focus\:from-purple-800:focus{
  --tw-gradient-from: #5b21b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.focus\:from-purple-900:focus{
  --tw-gradient-from: #4c1d95 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.focus\:from-pink-50:focus{
  --tw-gradient-from: #fdf2f8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.focus\:from-pink-100:focus{
  --tw-gradient-from: #fce7f3 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.focus\:from-pink-200:focus{
  --tw-gradient-from: #fbcfe8 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.focus\:from-pink-300:focus{
  --tw-gradient-from: #f9a8d4 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.focus\:from-pink-400:focus{
  --tw-gradient-from: #f472b6 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.focus\:from-pink-500:focus{
  --tw-gradient-from: #ec4899 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.focus\:from-pink-600:focus{
  --tw-gradient-from: #db2777 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.focus\:from-pink-700:focus{
  --tw-gradient-from: #be185d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.focus\:from-pink-800:focus{
  --tw-gradient-from: #9d174d !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.focus\:from-pink-900:focus{
  --tw-gradient-from: #831843 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.focus\:from-qipGreen:focus{
  --tw-gradient-from: #8bc334 !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
}

.focus\:from-portalGreen:focus{
  --tw-gradient-from: #8ac04a !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
}

.via-transparent{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.via-current{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.via-black{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.via-white{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.via-gray-50{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.via-gray-100{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.via-gray-200{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.via-gray-300{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.via-gray-400{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.via-gray-500{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.via-gray-600{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.via-gray-700{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.via-gray-800{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.via-gray-900{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.via-red-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.via-red-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.via-red-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.via-red-300{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.via-red-400{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.via-red-500{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.via-red-600{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.via-red-700{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.via-red-800{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.via-red-900{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.via-yellow-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.via-yellow-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.via-yellow-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.via-yellow-300{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.via-yellow-400{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.via-yellow-500{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.via-yellow-600{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.via-yellow-700{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.via-yellow-800{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.via-yellow-900{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.via-green-50{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.via-green-100{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.via-green-200{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.via-green-300{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.via-green-400{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.via-green-500{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.via-green-600{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.via-green-700{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.via-green-800{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.via-green-900{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.via-blue-50{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.via-blue-100{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.via-blue-200{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.via-blue-300{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.via-blue-400{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.via-blue-500{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.via-blue-600{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.via-blue-700{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.via-blue-800{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.via-blue-900{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.via-indigo-50{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.via-indigo-100{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.via-indigo-200{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.via-indigo-300{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.via-indigo-400{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.via-indigo-500{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.via-indigo-600{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.via-indigo-700{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.via-indigo-800{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.via-indigo-900{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.via-purple-50{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.via-purple-100{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.via-purple-200{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.via-purple-300{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.via-purple-400{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.via-purple-500{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.via-purple-600{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.via-purple-700{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.via-purple-800{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.via-purple-900{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.via-pink-50{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.via-pink-100{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.via-pink-200{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.via-pink-300{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.via-pink-400{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.via-pink-500{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.via-pink-600{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.via-pink-700{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.via-pink-800{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.via-pink-900{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.via-qipGreen{
  --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
}

.via-portalGreen{
  --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
}

.hover\:via-transparent:hover{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:via-current:hover{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:via-black:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.hover\:via-white:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.hover\:via-gray-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.hover\:via-gray-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.hover\:via-gray-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.hover\:via-gray-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.hover\:via-gray-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.hover\:via-gray-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.hover\:via-gray-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.hover\:via-gray-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.hover\:via-gray-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.hover\:via-gray-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.hover\:via-red-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.hover\:via-red-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.hover\:via-red-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.hover\:via-red-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.hover\:via-red-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.hover\:via-red-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.hover\:via-red-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.hover\:via-red-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.hover\:via-red-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.hover\:via-red-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.hover\:via-yellow-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.hover\:via-yellow-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.hover\:via-yellow-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.hover\:via-yellow-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.hover\:via-yellow-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.hover\:via-yellow-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.hover\:via-yellow-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.hover\:via-yellow-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.hover\:via-yellow-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.hover\:via-yellow-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.hover\:via-green-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.hover\:via-green-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.hover\:via-green-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.hover\:via-green-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.hover\:via-green-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.hover\:via-green-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.hover\:via-green-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.hover\:via-green-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.hover\:via-green-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.hover\:via-green-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.hover\:via-blue-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.hover\:via-blue-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.hover\:via-blue-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.hover\:via-blue-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.hover\:via-blue-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.hover\:via-blue-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.hover\:via-blue-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.hover\:via-blue-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.hover\:via-blue-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.hover\:via-blue-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.hover\:via-indigo-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.hover\:via-indigo-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.hover\:via-indigo-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.hover\:via-indigo-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.hover\:via-indigo-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.hover\:via-indigo-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.hover\:via-indigo-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.hover\:via-indigo-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.hover\:via-indigo-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.hover\:via-indigo-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.hover\:via-purple-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.hover\:via-purple-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.hover\:via-purple-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.hover\:via-purple-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.hover\:via-purple-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.hover\:via-purple-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.hover\:via-purple-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.hover\:via-purple-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.hover\:via-purple-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.hover\:via-purple-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.hover\:via-pink-50:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.hover\:via-pink-100:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.hover\:via-pink-200:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.hover\:via-pink-300:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.hover\:via-pink-400:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.hover\:via-pink-500:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.hover\:via-pink-600:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.hover\:via-pink-700:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.hover\:via-pink-800:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.hover\:via-pink-900:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.hover\:via-qipGreen:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
}

.hover\:via-portalGreen:hover{
  --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
}

.focus\:via-transparent:focus{
  --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:via-current:focus{
  --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:via-black:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
}

.focus\:via-white:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
}

.focus\:via-gray-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
}

.focus\:via-gray-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
}

.focus\:via-gray-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
}

.focus\:via-gray-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
}

.focus\:via-gray-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
}

.focus\:via-gray-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
}

.focus\:via-gray-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
}

.focus\:via-gray-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
}

.focus\:via-gray-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
}

.focus\:via-gray-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
}

.focus\:via-red-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
}

.focus\:via-red-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
}

.focus\:via-red-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
}

.focus\:via-red-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
}

.focus\:via-red-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
}

.focus\:via-red-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
}

.focus\:via-red-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
}

.focus\:via-red-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
}

.focus\:via-red-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
}

.focus\:via-red-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
}

.focus\:via-yellow-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
}

.focus\:via-yellow-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
}

.focus\:via-yellow-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
}

.focus\:via-yellow-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
}

.focus\:via-yellow-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
}

.focus\:via-yellow-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
}

.focus\:via-yellow-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
}

.focus\:via-yellow-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
}

.focus\:via-yellow-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
}

.focus\:via-yellow-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
}

.focus\:via-green-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
}

.focus\:via-green-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
}

.focus\:via-green-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
}

.focus\:via-green-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
}

.focus\:via-green-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
}

.focus\:via-green-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
}

.focus\:via-green-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
}

.focus\:via-green-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
}

.focus\:via-green-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
}

.focus\:via-green-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
}

.focus\:via-blue-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
}

.focus\:via-blue-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
}

.focus\:via-blue-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
}

.focus\:via-blue-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
}

.focus\:via-blue-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
}

.focus\:via-blue-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
}

.focus\:via-blue-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
}

.focus\:via-blue-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
}

.focus\:via-blue-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
}

.focus\:via-blue-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
}

.focus\:via-indigo-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
}

.focus\:via-indigo-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
}

.focus\:via-indigo-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
}

.focus\:via-indigo-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
}

.focus\:via-indigo-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
}

.focus\:via-indigo-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
}

.focus\:via-indigo-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
}

.focus\:via-indigo-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
}

.focus\:via-indigo-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
}

.focus\:via-indigo-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
}

.focus\:via-purple-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
}

.focus\:via-purple-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
}

.focus\:via-purple-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
}

.focus\:via-purple-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
}

.focus\:via-purple-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
}

.focus\:via-purple-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
}

.focus\:via-purple-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
}

.focus\:via-purple-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
}

.focus\:via-purple-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
}

.focus\:via-purple-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
}

.focus\:via-pink-50:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
}

.focus\:via-pink-100:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
}

.focus\:via-pink-200:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
}

.focus\:via-pink-300:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
}

.focus\:via-pink-400:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
}

.focus\:via-pink-500:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
}

.focus\:via-pink-600:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
}

.focus\:via-pink-700:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
}

.focus\:via-pink-800:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
}

.focus\:via-pink-900:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
}

.focus\:via-qipGreen:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
}

.focus\:via-portalGreen:focus{
  --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
}

.to-transparent{
  --tw-gradient-to: transparent !important;
}

.to-current{
  --tw-gradient-to: currentColor !important;
}

.to-black{
  --tw-gradient-to: #000 !important;
}

.to-white{
  --tw-gradient-to: #fff !important;
}

.to-gray-50{
  --tw-gradient-to: #f9fafb !important;
}

.to-gray-100{
  --tw-gradient-to: #f3f4f6 !important;
}

.to-gray-200{
  --tw-gradient-to: #e5e7eb !important;
}

.to-gray-300{
  --tw-gradient-to: #d1d5db !important;
}

.to-gray-400{
  --tw-gradient-to: #9ca3af !important;
}

.to-gray-500{
  --tw-gradient-to: #6b7280 !important;
}

.to-gray-600{
  --tw-gradient-to: #4b5563 !important;
}

.to-gray-700{
  --tw-gradient-to: #374151 !important;
}

.to-gray-800{
  --tw-gradient-to: #1f2937 !important;
}

.to-gray-900{
  --tw-gradient-to: #111827 !important;
}

.to-red-50{
  --tw-gradient-to: #fef2f2 !important;
}

.to-red-100{
  --tw-gradient-to: #fee2e2 !important;
}

.to-red-200{
  --tw-gradient-to: #fecaca !important;
}

.to-red-300{
  --tw-gradient-to: #fca5a5 !important;
}

.to-red-400{
  --tw-gradient-to: #f87171 !important;
}

.to-red-500{
  --tw-gradient-to: #ef4444 !important;
}

.to-red-600{
  --tw-gradient-to: #dc2626 !important;
}

.to-red-700{
  --tw-gradient-to: #b91c1c !important;
}

.to-red-800{
  --tw-gradient-to: #991b1b !important;
}

.to-red-900{
  --tw-gradient-to: #7f1d1d !important;
}

.to-yellow-50{
  --tw-gradient-to: #fffbeb !important;
}

.to-yellow-100{
  --tw-gradient-to: #fef3c7 !important;
}

.to-yellow-200{
  --tw-gradient-to: #fde68a !important;
}

.to-yellow-300{
  --tw-gradient-to: #fcd34d !important;
}

.to-yellow-400{
  --tw-gradient-to: #fbbf24 !important;
}

.to-yellow-500{
  --tw-gradient-to: #f59e0b !important;
}

.to-yellow-600{
  --tw-gradient-to: #d97706 !important;
}

.to-yellow-700{
  --tw-gradient-to: #b45309 !important;
}

.to-yellow-800{
  --tw-gradient-to: #92400e !important;
}

.to-yellow-900{
  --tw-gradient-to: #78350f !important;
}

.to-green-50{
  --tw-gradient-to: #ecfdf5 !important;
}

.to-green-100{
  --tw-gradient-to: #d1fae5 !important;
}

.to-green-200{
  --tw-gradient-to: #a7f3d0 !important;
}

.to-green-300{
  --tw-gradient-to: #6ee7b7 !important;
}

.to-green-400{
  --tw-gradient-to: #34d399 !important;
}

.to-green-500{
  --tw-gradient-to: #10b981 !important;
}

.to-green-600{
  --tw-gradient-to: #059669 !important;
}

.to-green-700{
  --tw-gradient-to: #047857 !important;
}

.to-green-800{
  --tw-gradient-to: #065f46 !important;
}

.to-green-900{
  --tw-gradient-to: #064e3b !important;
}

.to-blue-50{
  --tw-gradient-to: #eff6ff !important;
}

.to-blue-100{
  --tw-gradient-to: #dbeafe !important;
}

.to-blue-200{
  --tw-gradient-to: #bfdbfe !important;
}

.to-blue-300{
  --tw-gradient-to: #93c5fd !important;
}

.to-blue-400{
  --tw-gradient-to: #60a5fa !important;
}

.to-blue-500{
  --tw-gradient-to: #3b82f6 !important;
}

.to-blue-600{
  --tw-gradient-to: #2563eb !important;
}

.to-blue-700{
  --tw-gradient-to: #1d4ed8 !important;
}

.to-blue-800{
  --tw-gradient-to: #1e40af !important;
}

.to-blue-900{
  --tw-gradient-to: #1e3a8a !important;
}

.to-indigo-50{
  --tw-gradient-to: #eef2ff !important;
}

.to-indigo-100{
  --tw-gradient-to: #e0e7ff !important;
}

.to-indigo-200{
  --tw-gradient-to: #c7d2fe !important;
}

.to-indigo-300{
  --tw-gradient-to: #a5b4fc !important;
}

.to-indigo-400{
  --tw-gradient-to: #818cf8 !important;
}

.to-indigo-500{
  --tw-gradient-to: #6366f1 !important;
}

.to-indigo-600{
  --tw-gradient-to: #4f46e5 !important;
}

.to-indigo-700{
  --tw-gradient-to: #4338ca !important;
}

.to-indigo-800{
  --tw-gradient-to: #3730a3 !important;
}

.to-indigo-900{
  --tw-gradient-to: #312e81 !important;
}

.to-purple-50{
  --tw-gradient-to: #f5f3ff !important;
}

.to-purple-100{
  --tw-gradient-to: #ede9fe !important;
}

.to-purple-200{
  --tw-gradient-to: #ddd6fe !important;
}

.to-purple-300{
  --tw-gradient-to: #c4b5fd !important;
}

.to-purple-400{
  --tw-gradient-to: #a78bfa !important;
}

.to-purple-500{
  --tw-gradient-to: #8b5cf6 !important;
}

.to-purple-600{
  --tw-gradient-to: #7c3aed !important;
}

.to-purple-700{
  --tw-gradient-to: #6d28d9 !important;
}

.to-purple-800{
  --tw-gradient-to: #5b21b6 !important;
}

.to-purple-900{
  --tw-gradient-to: #4c1d95 !important;
}

.to-pink-50{
  --tw-gradient-to: #fdf2f8 !important;
}

.to-pink-100{
  --tw-gradient-to: #fce7f3 !important;
}

.to-pink-200{
  --tw-gradient-to: #fbcfe8 !important;
}

.to-pink-300{
  --tw-gradient-to: #f9a8d4 !important;
}

.to-pink-400{
  --tw-gradient-to: #f472b6 !important;
}

.to-pink-500{
  --tw-gradient-to: #ec4899 !important;
}

.to-pink-600{
  --tw-gradient-to: #db2777 !important;
}

.to-pink-700{
  --tw-gradient-to: #be185d !important;
}

.to-pink-800{
  --tw-gradient-to: #9d174d !important;
}

.to-pink-900{
  --tw-gradient-to: #831843 !important;
}

.to-qipGreen{
  --tw-gradient-to: #8bc334 !important;
}

.to-portalGreen{
  --tw-gradient-to: #8ac04a !important;
}

.hover\:to-transparent:hover{
  --tw-gradient-to: transparent !important;
}

.hover\:to-current:hover{
  --tw-gradient-to: currentColor !important;
}

.hover\:to-black:hover{
  --tw-gradient-to: #000 !important;
}

.hover\:to-white:hover{
  --tw-gradient-to: #fff !important;
}

.hover\:to-gray-50:hover{
  --tw-gradient-to: #f9fafb !important;
}

.hover\:to-gray-100:hover{
  --tw-gradient-to: #f3f4f6 !important;
}

.hover\:to-gray-200:hover{
  --tw-gradient-to: #e5e7eb !important;
}

.hover\:to-gray-300:hover{
  --tw-gradient-to: #d1d5db !important;
}

.hover\:to-gray-400:hover{
  --tw-gradient-to: #9ca3af !important;
}

.hover\:to-gray-500:hover{
  --tw-gradient-to: #6b7280 !important;
}

.hover\:to-gray-600:hover{
  --tw-gradient-to: #4b5563 !important;
}

.hover\:to-gray-700:hover{
  --tw-gradient-to: #374151 !important;
}

.hover\:to-gray-800:hover{
  --tw-gradient-to: #1f2937 !important;
}

.hover\:to-gray-900:hover{
  --tw-gradient-to: #111827 !important;
}

.hover\:to-red-50:hover{
  --tw-gradient-to: #fef2f2 !important;
}

.hover\:to-red-100:hover{
  --tw-gradient-to: #fee2e2 !important;
}

.hover\:to-red-200:hover{
  --tw-gradient-to: #fecaca !important;
}

.hover\:to-red-300:hover{
  --tw-gradient-to: #fca5a5 !important;
}

.hover\:to-red-400:hover{
  --tw-gradient-to: #f87171 !important;
}

.hover\:to-red-500:hover{
  --tw-gradient-to: #ef4444 !important;
}

.hover\:to-red-600:hover{
  --tw-gradient-to: #dc2626 !important;
}

.hover\:to-red-700:hover{
  --tw-gradient-to: #b91c1c !important;
}

.hover\:to-red-800:hover{
  --tw-gradient-to: #991b1b !important;
}

.hover\:to-red-900:hover{
  --tw-gradient-to: #7f1d1d !important;
}

.hover\:to-yellow-50:hover{
  --tw-gradient-to: #fffbeb !important;
}

.hover\:to-yellow-100:hover{
  --tw-gradient-to: #fef3c7 !important;
}

.hover\:to-yellow-200:hover{
  --tw-gradient-to: #fde68a !important;
}

.hover\:to-yellow-300:hover{
  --tw-gradient-to: #fcd34d !important;
}

.hover\:to-yellow-400:hover{
  --tw-gradient-to: #fbbf24 !important;
}

.hover\:to-yellow-500:hover{
  --tw-gradient-to: #f59e0b !important;
}

.hover\:to-yellow-600:hover{
  --tw-gradient-to: #d97706 !important;
}

.hover\:to-yellow-700:hover{
  --tw-gradient-to: #b45309 !important;
}

.hover\:to-yellow-800:hover{
  --tw-gradient-to: #92400e !important;
}

.hover\:to-yellow-900:hover{
  --tw-gradient-to: #78350f !important;
}

.hover\:to-green-50:hover{
  --tw-gradient-to: #ecfdf5 !important;
}

.hover\:to-green-100:hover{
  --tw-gradient-to: #d1fae5 !important;
}

.hover\:to-green-200:hover{
  --tw-gradient-to: #a7f3d0 !important;
}

.hover\:to-green-300:hover{
  --tw-gradient-to: #6ee7b7 !important;
}

.hover\:to-green-400:hover{
  --tw-gradient-to: #34d399 !important;
}

.hover\:to-green-500:hover{
  --tw-gradient-to: #10b981 !important;
}

.hover\:to-green-600:hover{
  --tw-gradient-to: #059669 !important;
}

.hover\:to-green-700:hover{
  --tw-gradient-to: #047857 !important;
}

.hover\:to-green-800:hover{
  --tw-gradient-to: #065f46 !important;
}

.hover\:to-green-900:hover{
  --tw-gradient-to: #064e3b !important;
}

.hover\:to-blue-50:hover{
  --tw-gradient-to: #eff6ff !important;
}

.hover\:to-blue-100:hover{
  --tw-gradient-to: #dbeafe !important;
}

.hover\:to-blue-200:hover{
  --tw-gradient-to: #bfdbfe !important;
}

.hover\:to-blue-300:hover{
  --tw-gradient-to: #93c5fd !important;
}

.hover\:to-blue-400:hover{
  --tw-gradient-to: #60a5fa !important;
}

.hover\:to-blue-500:hover{
  --tw-gradient-to: #3b82f6 !important;
}

.hover\:to-blue-600:hover{
  --tw-gradient-to: #2563eb !important;
}

.hover\:to-blue-700:hover{
  --tw-gradient-to: #1d4ed8 !important;
}

.hover\:to-blue-800:hover{
  --tw-gradient-to: #1e40af !important;
}

.hover\:to-blue-900:hover{
  --tw-gradient-to: #1e3a8a !important;
}

.hover\:to-indigo-50:hover{
  --tw-gradient-to: #eef2ff !important;
}

.hover\:to-indigo-100:hover{
  --tw-gradient-to: #e0e7ff !important;
}

.hover\:to-indigo-200:hover{
  --tw-gradient-to: #c7d2fe !important;
}

.hover\:to-indigo-300:hover{
  --tw-gradient-to: #a5b4fc !important;
}

.hover\:to-indigo-400:hover{
  --tw-gradient-to: #818cf8 !important;
}

.hover\:to-indigo-500:hover{
  --tw-gradient-to: #6366f1 !important;
}

.hover\:to-indigo-600:hover{
  --tw-gradient-to: #4f46e5 !important;
}

.hover\:to-indigo-700:hover{
  --tw-gradient-to: #4338ca !important;
}

.hover\:to-indigo-800:hover{
  --tw-gradient-to: #3730a3 !important;
}

.hover\:to-indigo-900:hover{
  --tw-gradient-to: #312e81 !important;
}

.hover\:to-purple-50:hover{
  --tw-gradient-to: #f5f3ff !important;
}

.hover\:to-purple-100:hover{
  --tw-gradient-to: #ede9fe !important;
}

.hover\:to-purple-200:hover{
  --tw-gradient-to: #ddd6fe !important;
}

.hover\:to-purple-300:hover{
  --tw-gradient-to: #c4b5fd !important;
}

.hover\:to-purple-400:hover{
  --tw-gradient-to: #a78bfa !important;
}

.hover\:to-purple-500:hover{
  --tw-gradient-to: #8b5cf6 !important;
}

.hover\:to-purple-600:hover{
  --tw-gradient-to: #7c3aed !important;
}

.hover\:to-purple-700:hover{
  --tw-gradient-to: #6d28d9 !important;
}

.hover\:to-purple-800:hover{
  --tw-gradient-to: #5b21b6 !important;
}

.hover\:to-purple-900:hover{
  --tw-gradient-to: #4c1d95 !important;
}

.hover\:to-pink-50:hover{
  --tw-gradient-to: #fdf2f8 !important;
}

.hover\:to-pink-100:hover{
  --tw-gradient-to: #fce7f3 !important;
}

.hover\:to-pink-200:hover{
  --tw-gradient-to: #fbcfe8 !important;
}

.hover\:to-pink-300:hover{
  --tw-gradient-to: #f9a8d4 !important;
}

.hover\:to-pink-400:hover{
  --tw-gradient-to: #f472b6 !important;
}

.hover\:to-pink-500:hover{
  --tw-gradient-to: #ec4899 !important;
}

.hover\:to-pink-600:hover{
  --tw-gradient-to: #db2777 !important;
}

.hover\:to-pink-700:hover{
  --tw-gradient-to: #be185d !important;
}

.hover\:to-pink-800:hover{
  --tw-gradient-to: #9d174d !important;
}

.hover\:to-pink-900:hover{
  --tw-gradient-to: #831843 !important;
}

.hover\:to-qipGreen:hover{
  --tw-gradient-to: #8bc334 !important;
}

.hover\:to-portalGreen:hover{
  --tw-gradient-to: #8ac04a !important;
}

.focus\:to-transparent:focus{
  --tw-gradient-to: transparent !important;
}

.focus\:to-current:focus{
  --tw-gradient-to: currentColor !important;
}

.focus\:to-black:focus{
  --tw-gradient-to: #000 !important;
}

.focus\:to-white:focus{
  --tw-gradient-to: #fff !important;
}

.focus\:to-gray-50:focus{
  --tw-gradient-to: #f9fafb !important;
}

.focus\:to-gray-100:focus{
  --tw-gradient-to: #f3f4f6 !important;
}

.focus\:to-gray-200:focus{
  --tw-gradient-to: #e5e7eb !important;
}

.focus\:to-gray-300:focus{
  --tw-gradient-to: #d1d5db !important;
}

.focus\:to-gray-400:focus{
  --tw-gradient-to: #9ca3af !important;
}

.focus\:to-gray-500:focus{
  --tw-gradient-to: #6b7280 !important;
}

.focus\:to-gray-600:focus{
  --tw-gradient-to: #4b5563 !important;
}

.focus\:to-gray-700:focus{
  --tw-gradient-to: #374151 !important;
}

.focus\:to-gray-800:focus{
  --tw-gradient-to: #1f2937 !important;
}

.focus\:to-gray-900:focus{
  --tw-gradient-to: #111827 !important;
}

.focus\:to-red-50:focus{
  --tw-gradient-to: #fef2f2 !important;
}

.focus\:to-red-100:focus{
  --tw-gradient-to: #fee2e2 !important;
}

.focus\:to-red-200:focus{
  --tw-gradient-to: #fecaca !important;
}

.focus\:to-red-300:focus{
  --tw-gradient-to: #fca5a5 !important;
}

.focus\:to-red-400:focus{
  --tw-gradient-to: #f87171 !important;
}

.focus\:to-red-500:focus{
  --tw-gradient-to: #ef4444 !important;
}

.focus\:to-red-600:focus{
  --tw-gradient-to: #dc2626 !important;
}

.focus\:to-red-700:focus{
  --tw-gradient-to: #b91c1c !important;
}

.focus\:to-red-800:focus{
  --tw-gradient-to: #991b1b !important;
}

.focus\:to-red-900:focus{
  --tw-gradient-to: #7f1d1d !important;
}

.focus\:to-yellow-50:focus{
  --tw-gradient-to: #fffbeb !important;
}

.focus\:to-yellow-100:focus{
  --tw-gradient-to: #fef3c7 !important;
}

.focus\:to-yellow-200:focus{
  --tw-gradient-to: #fde68a !important;
}

.focus\:to-yellow-300:focus{
  --tw-gradient-to: #fcd34d !important;
}

.focus\:to-yellow-400:focus{
  --tw-gradient-to: #fbbf24 !important;
}

.focus\:to-yellow-500:focus{
  --tw-gradient-to: #f59e0b !important;
}

.focus\:to-yellow-600:focus{
  --tw-gradient-to: #d97706 !important;
}

.focus\:to-yellow-700:focus{
  --tw-gradient-to: #b45309 !important;
}

.focus\:to-yellow-800:focus{
  --tw-gradient-to: #92400e !important;
}

.focus\:to-yellow-900:focus{
  --tw-gradient-to: #78350f !important;
}

.focus\:to-green-50:focus{
  --tw-gradient-to: #ecfdf5 !important;
}

.focus\:to-green-100:focus{
  --tw-gradient-to: #d1fae5 !important;
}

.focus\:to-green-200:focus{
  --tw-gradient-to: #a7f3d0 !important;
}

.focus\:to-green-300:focus{
  --tw-gradient-to: #6ee7b7 !important;
}

.focus\:to-green-400:focus{
  --tw-gradient-to: #34d399 !important;
}

.focus\:to-green-500:focus{
  --tw-gradient-to: #10b981 !important;
}

.focus\:to-green-600:focus{
  --tw-gradient-to: #059669 !important;
}

.focus\:to-green-700:focus{
  --tw-gradient-to: #047857 !important;
}

.focus\:to-green-800:focus{
  --tw-gradient-to: #065f46 !important;
}

.focus\:to-green-900:focus{
  --tw-gradient-to: #064e3b !important;
}

.focus\:to-blue-50:focus{
  --tw-gradient-to: #eff6ff !important;
}

.focus\:to-blue-100:focus{
  --tw-gradient-to: #dbeafe !important;
}

.focus\:to-blue-200:focus{
  --tw-gradient-to: #bfdbfe !important;
}

.focus\:to-blue-300:focus{
  --tw-gradient-to: #93c5fd !important;
}

.focus\:to-blue-400:focus{
  --tw-gradient-to: #60a5fa !important;
}

.focus\:to-blue-500:focus{
  --tw-gradient-to: #3b82f6 !important;
}

.focus\:to-blue-600:focus{
  --tw-gradient-to: #2563eb !important;
}

.focus\:to-blue-700:focus{
  --tw-gradient-to: #1d4ed8 !important;
}

.focus\:to-blue-800:focus{
  --tw-gradient-to: #1e40af !important;
}

.focus\:to-blue-900:focus{
  --tw-gradient-to: #1e3a8a !important;
}

.focus\:to-indigo-50:focus{
  --tw-gradient-to: #eef2ff !important;
}

.focus\:to-indigo-100:focus{
  --tw-gradient-to: #e0e7ff !important;
}

.focus\:to-indigo-200:focus{
  --tw-gradient-to: #c7d2fe !important;
}

.focus\:to-indigo-300:focus{
  --tw-gradient-to: #a5b4fc !important;
}

.focus\:to-indigo-400:focus{
  --tw-gradient-to: #818cf8 !important;
}

.focus\:to-indigo-500:focus{
  --tw-gradient-to: #6366f1 !important;
}

.focus\:to-indigo-600:focus{
  --tw-gradient-to: #4f46e5 !important;
}

.focus\:to-indigo-700:focus{
  --tw-gradient-to: #4338ca !important;
}

.focus\:to-indigo-800:focus{
  --tw-gradient-to: #3730a3 !important;
}

.focus\:to-indigo-900:focus{
  --tw-gradient-to: #312e81 !important;
}

.focus\:to-purple-50:focus{
  --tw-gradient-to: #f5f3ff !important;
}

.focus\:to-purple-100:focus{
  --tw-gradient-to: #ede9fe !important;
}

.focus\:to-purple-200:focus{
  --tw-gradient-to: #ddd6fe !important;
}

.focus\:to-purple-300:focus{
  --tw-gradient-to: #c4b5fd !important;
}

.focus\:to-purple-400:focus{
  --tw-gradient-to: #a78bfa !important;
}

.focus\:to-purple-500:focus{
  --tw-gradient-to: #8b5cf6 !important;
}

.focus\:to-purple-600:focus{
  --tw-gradient-to: #7c3aed !important;
}

.focus\:to-purple-700:focus{
  --tw-gradient-to: #6d28d9 !important;
}

.focus\:to-purple-800:focus{
  --tw-gradient-to: #5b21b6 !important;
}

.focus\:to-purple-900:focus{
  --tw-gradient-to: #4c1d95 !important;
}

.focus\:to-pink-50:focus{
  --tw-gradient-to: #fdf2f8 !important;
}

.focus\:to-pink-100:focus{
  --tw-gradient-to: #fce7f3 !important;
}

.focus\:to-pink-200:focus{
  --tw-gradient-to: #fbcfe8 !important;
}

.focus\:to-pink-300:focus{
  --tw-gradient-to: #f9a8d4 !important;
}

.focus\:to-pink-400:focus{
  --tw-gradient-to: #f472b6 !important;
}

.focus\:to-pink-500:focus{
  --tw-gradient-to: #ec4899 !important;
}

.focus\:to-pink-600:focus{
  --tw-gradient-to: #db2777 !important;
}

.focus\:to-pink-700:focus{
  --tw-gradient-to: #be185d !important;
}

.focus\:to-pink-800:focus{
  --tw-gradient-to: #9d174d !important;
}

.focus\:to-pink-900:focus{
  --tw-gradient-to: #831843 !important;
}

.focus\:to-qipGreen:focus{
  --tw-gradient-to: #8bc334 !important;
}

.focus\:to-portalGreen:focus{
  --tw-gradient-to: #8ac04a !important;
}

.decoration-slice{
  -webkit-box-decoration-break: slice !important;
          box-decoration-break: slice !important;
}

.decoration-clone{
  -webkit-box-decoration-break: clone !important;
          box-decoration-break: clone !important;
}

.bg-auto{
  background-size: auto !important;
}

.bg-cover{
  background-size: cover !important;
}

.bg-contain{
  background-size: contain !important;
}

.bg-fixed{
  background-attachment: fixed !important;
}

.bg-local{
  background-attachment: local !important;
}

.bg-scroll{
  background-attachment: scroll !important;
}

.bg-clip-border{
  background-clip: border-box !important;
}

.bg-clip-padding{
  background-clip: padding-box !important;
}

.bg-clip-content{
  background-clip: content-box !important;
}

.bg-clip-text{
  -webkit-background-clip: text !important;
          background-clip: text !important;
}

.bg-bottom{
  background-position: bottom !important;
}

.bg-center{
  background-position: center !important;
}

.bg-left{
  background-position: left !important;
}

.bg-left-bottom{
  background-position: left bottom !important;
}

.bg-left-top{
  background-position: left top !important;
}

.bg-right{
  background-position: right !important;
}

.bg-right-bottom{
  background-position: right bottom !important;
}

.bg-right-top{
  background-position: right top !important;
}

.bg-top{
  background-position: top !important;
}

.bg-repeat{
  background-repeat: repeat !important;
}

.bg-no-repeat{
  background-repeat: no-repeat !important;
}

.bg-repeat-x{
  background-repeat: repeat-x !important;
}

.bg-repeat-y{
  background-repeat: repeat-y !important;
}

.bg-repeat-round{
  background-repeat: round !important;
}

.bg-repeat-space{
  background-repeat: space !important;
}

.bg-origin-border{
  background-origin: border-box !important;
}

.bg-origin-padding{
  background-origin: padding-box !important;
}

.bg-origin-content{
  background-origin: content-box !important;
}

.fill-current{
  fill: currentColor !important;
}

.stroke-current{
  stroke: currentColor !important;
}

.stroke-0{
  stroke-width: 0 !important;
}

.stroke-1{
  stroke-width: 1 !important;
}

.stroke-2{
  stroke-width: 2 !important;
}

.object-contain{
  object-fit: contain !important;
}

.object-cover{
  object-fit: cover !important;
}

.object-fill{
  object-fit: fill !important;
}

.object-none{
  object-fit: none !important;
}

.object-scale-down{
  object-fit: scale-down !important;
}

.object-bottom{
  object-position: bottom !important;
}

.object-center{
  object-position: center !important;
}

.object-left{
  object-position: left !important;
}

.object-left-bottom{
  object-position: left bottom !important;
}

.object-left-top{
  object-position: left top !important;
}

.object-right{
  object-position: right !important;
}

.object-right-bottom{
  object-position: right bottom !important;
}

.object-right-top{
  object-position: right top !important;
}

.object-top{
  object-position: top !important;
}

.p-0{
  padding: 0px !important;
}

.p-1{
  padding: 0.25rem !important;
}

.p-2{
  padding: 0.5rem !important;
}

.p-3{
  padding: 0.75rem !important;
}

.p-4{
  padding: 1rem !important;
}

.p-5{
  padding: 1.25rem !important;
}

.p-6{
  padding: 1.5rem !important;
}

.p-7{
  padding: 1.75rem !important;
}

.p-8{
  padding: 2rem !important;
}

.p-9{
  padding: 2.25rem !important;
}

.p-10{
  padding: 2.5rem !important;
}

.p-11{
  padding: 2.75rem !important;
}

.p-12{
  padding: 3rem !important;
}

.p-14{
  padding: 3.5rem !important;
}

.p-16{
  padding: 4rem !important;
}

.p-20{
  padding: 5rem !important;
}

.p-24{
  padding: 6rem !important;
}

.p-28{
  padding: 7rem !important;
}

.p-32{
  padding: 8rem !important;
}

.p-36{
  padding: 9rem !important;
}

.p-40{
  padding: 10rem !important;
}

.p-44{
  padding: 11rem !important;
}

.p-48{
  padding: 12rem !important;
}

.p-52{
  padding: 13rem !important;
}

.p-56{
  padding: 14rem !important;
}

.p-60{
  padding: 15rem !important;
}

.p-64{
  padding: 16rem !important;
}

.p-72{
  padding: 18rem !important;
}

.p-80{
  padding: 20rem !important;
}

.p-96{
  padding: 24rem !important;
}

.p-px{
  padding: 1px !important;
}

.p-0\.5{
  padding: 0.125rem !important;
}

.p-1\.5{
  padding: 0.375rem !important;
}

.p-2\.5{
  padding: 0.625rem !important;
}

.p-3\.5{
  padding: 0.875rem !important;
}

.px-0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-1{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-7{
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.px-8{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-9{
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.px-10{
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-11{
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.px-12{
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-14{
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-16{
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-20{
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.px-24{
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.px-28{
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.px-32{
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.px-36{
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.px-40{
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.px-44{
  padding-left: 11rem !important;
  padding-right: 11rem !important;
}

.px-48{
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.px-52{
  padding-left: 13rem !important;
  padding-right: 13rem !important;
}

.px-56{
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.px-60{
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.px-64{
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.px-72{
  padding-left: 18rem !important;
  padding-right: 18rem !important;
}

.px-80{
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.px-96{
  padding-left: 24rem !important;
  padding-right: 24rem !important;
}

.px-px{
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.px-0\.5{
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}

.px-1\.5{
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.px-2\.5{
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.px-3\.5{
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

.py-0{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1{
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2{
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5{
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6{
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7{
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8{
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9{
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10{
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11{
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-14{
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-16{
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-20{
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-24{
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-28{
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-32{
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-36{
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.py-40{
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-44{
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.py-48{
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.py-52{
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.py-56{
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.py-60{
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.py-64{
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.py-72{
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.py-80{
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-96{
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.py-px{
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.py-0\.5{
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.py-1\.5{
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-2\.5{
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3\.5{
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.pt-0{
  padding-top: 0px !important;
}

.pt-1{
  padding-top: 0.25rem !important;
}

.pt-2{
  padding-top: 0.5rem !important;
}

.pt-3{
  padding-top: 0.75rem !important;
}

.pt-4{
  padding-top: 1rem !important;
}

.pt-5{
  padding-top: 1.25rem !important;
}

.pt-6{
  padding-top: 1.5rem !important;
}

.pt-7{
  padding-top: 1.75rem !important;
}

.pt-8{
  padding-top: 2rem !important;
}

.pt-9{
  padding-top: 2.25rem !important;
}

.pt-10{
  padding-top: 2.5rem !important;
}

.pt-11{
  padding-top: 2.75rem !important;
}

.pt-12{
  padding-top: 3rem !important;
}

.pt-14{
  padding-top: 3.5rem !important;
}

.pt-16{
  padding-top: 4rem !important;
}

.pt-20{
  padding-top: 5rem !important;
}

.pt-24{
  padding-top: 6rem !important;
}

.pt-28{
  padding-top: 7rem !important;
}

.pt-32{
  padding-top: 8rem !important;
}

.pt-36{
  padding-top: 9rem !important;
}

.pt-40{
  padding-top: 10rem !important;
}

.pt-44{
  padding-top: 11rem !important;
}

.pt-48{
  padding-top: 12rem !important;
}

.pt-52{
  padding-top: 13rem !important;
}

.pt-56{
  padding-top: 14rem !important;
}

.pt-60{
  padding-top: 15rem !important;
}

.pt-64{
  padding-top: 16rem !important;
}

.pt-72{
  padding-top: 18rem !important;
}

.pt-80{
  padding-top: 20rem !important;
}

.pt-96{
  padding-top: 24rem !important;
}

.pt-px{
  padding-top: 1px !important;
}

.pt-0\.5{
  padding-top: 0.125rem !important;
}

.pt-1\.5{
  padding-top: 0.375rem !important;
}

.pt-2\.5{
  padding-top: 0.625rem !important;
}

.pt-3\.5{
  padding-top: 0.875rem !important;
}

.pr-0{
  padding-right: 0px !important;
}

.pr-1{
  padding-right: 0.25rem !important;
}

.pr-2{
  padding-right: 0.5rem !important;
}

.pr-3{
  padding-right: 0.75rem !important;
}

.pr-4{
  padding-right: 1rem !important;
}

.pr-5{
  padding-right: 1.25rem !important;
}

.pr-6{
  padding-right: 1.5rem !important;
}

.pr-7{
  padding-right: 1.75rem !important;
}

.pr-8{
  padding-right: 2rem !important;
}

.pr-9{
  padding-right: 2.25rem !important;
}

.pr-10{
  padding-right: 2.5rem !important;
}

.pr-11{
  padding-right: 2.75rem !important;
}

.pr-12{
  padding-right: 3rem !important;
}

.pr-14{
  padding-right: 3.5rem !important;
}

.pr-16{
  padding-right: 4rem !important;
}

.pr-20{
  padding-right: 5rem !important;
}

.pr-24{
  padding-right: 6rem !important;
}

.pr-28{
  padding-right: 7rem !important;
}

.pr-32{
  padding-right: 8rem !important;
}

.pr-36{
  padding-right: 9rem !important;
}

.pr-40{
  padding-right: 10rem !important;
}

.pr-44{
  padding-right: 11rem !important;
}

.pr-48{
  padding-right: 12rem !important;
}

.pr-52{
  padding-right: 13rem !important;
}

.pr-56{
  padding-right: 14rem !important;
}

.pr-60{
  padding-right: 15rem !important;
}

.pr-64{
  padding-right: 16rem !important;
}

.pr-72{
  padding-right: 18rem !important;
}

.pr-80{
  padding-right: 20rem !important;
}

.pr-96{
  padding-right: 24rem !important;
}

.pr-px{
  padding-right: 1px !important;
}

.pr-0\.5{
  padding-right: 0.125rem !important;
}

.pr-1\.5{
  padding-right: 0.375rem !important;
}

.pr-2\.5{
  padding-right: 0.625rem !important;
}

.pr-3\.5{
  padding-right: 0.875rem !important;
}

.pb-0{
  padding-bottom: 0px !important;
}

.pb-1{
  padding-bottom: 0.25rem !important;
}

.pb-2{
  padding-bottom: 0.5rem !important;
}

.pb-3{
  padding-bottom: 0.75rem !important;
}

.pb-4{
  padding-bottom: 1rem !important;
}

.pb-5{
  padding-bottom: 1.25rem !important;
}

.pb-6{
  padding-bottom: 1.5rem !important;
}

.pb-7{
  padding-bottom: 1.75rem !important;
}

.pb-8{
  padding-bottom: 2rem !important;
}

.pb-9{
  padding-bottom: 2.25rem !important;
}

.pb-10{
  padding-bottom: 2.5rem !important;
}

.pb-11{
  padding-bottom: 2.75rem !important;
}

.pb-12{
  padding-bottom: 3rem !important;
}

.pb-14{
  padding-bottom: 3.5rem !important;
}

.pb-16{
  padding-bottom: 4rem !important;
}

.pb-20{
  padding-bottom: 5rem !important;
}

.pb-24{
  padding-bottom: 6rem !important;
}

.pb-28{
  padding-bottom: 7rem !important;
}

.pb-32{
  padding-bottom: 8rem !important;
}

.pb-36{
  padding-bottom: 9rem !important;
}

.pb-40{
  padding-bottom: 10rem !important;
}

.pb-44{
  padding-bottom: 11rem !important;
}

.pb-48{
  padding-bottom: 12rem !important;
}

.pb-52{
  padding-bottom: 13rem !important;
}

.pb-56{
  padding-bottom: 14rem !important;
}

.pb-60{
  padding-bottom: 15rem !important;
}

.pb-64{
  padding-bottom: 16rem !important;
}

.pb-72{
  padding-bottom: 18rem !important;
}

.pb-80{
  padding-bottom: 20rem !important;
}

.pb-96{
  padding-bottom: 24rem !important;
}

.pb-px{
  padding-bottom: 1px !important;
}

.pb-0\.5{
  padding-bottom: 0.125rem !important;
}

.pb-1\.5{
  padding-bottom: 0.375rem !important;
}

.pb-2\.5{
  padding-bottom: 0.625rem !important;
}

.pb-3\.5{
  padding-bottom: 0.875rem !important;
}

.pl-0{
  padding-left: 0px !important;
}

.pl-1{
  padding-left: 0.25rem !important;
}

.pl-2{
  padding-left: 0.5rem !important;
}

.pl-3{
  padding-left: 0.75rem !important;
}

.pl-4{
  padding-left: 1rem !important;
}

.pl-5{
  padding-left: 1.25rem !important;
}

.pl-6{
  padding-left: 1.5rem !important;
}

.pl-7{
  padding-left: 1.75rem !important;
}

.pl-8{
  padding-left: 2rem !important;
}

.pl-9{
  padding-left: 2.25rem !important;
}

.pl-10{
  padding-left: 2.5rem !important;
}

.pl-11{
  padding-left: 2.75rem !important;
}

.pl-12{
  padding-left: 3rem !important;
}

.pl-14{
  padding-left: 3.5rem !important;
}

.pl-16{
  padding-left: 4rem !important;
}

.pl-20{
  padding-left: 5rem !important;
}

.pl-24{
  padding-left: 6rem !important;
}

.pl-28{
  padding-left: 7rem !important;
}

.pl-32{
  padding-left: 8rem !important;
}

.pl-36{
  padding-left: 9rem !important;
}

.pl-40{
  padding-left: 10rem !important;
}

.pl-44{
  padding-left: 11rem !important;
}

.pl-48{
  padding-left: 12rem !important;
}

.pl-52{
  padding-left: 13rem !important;
}

.pl-56{
  padding-left: 14rem !important;
}

.pl-60{
  padding-left: 15rem !important;
}

.pl-64{
  padding-left: 16rem !important;
}

.pl-72{
  padding-left: 18rem !important;
}

.pl-80{
  padding-left: 20rem !important;
}

.pl-96{
  padding-left: 24rem !important;
}

.pl-px{
  padding-left: 1px !important;
}

.pl-0\.5{
  padding-left: 0.125rem !important;
}

.pl-1\.5{
  padding-left: 0.375rem !important;
}

.pl-2\.5{
  padding-left: 0.625rem !important;
}

.pl-3\.5{
  padding-left: 0.875rem !important;
}

.text-left{
  text-align: left !important;
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.text-justify{
  text-align: justify !important;
}

.align-baseline{
  vertical-align: baseline !important;
}

.align-top{
  vertical-align: top !important;
}

.align-middle{
  vertical-align: middle !important;
}

.align-bottom{
  vertical-align: bottom !important;
}

.align-text-top{
  vertical-align: text-top !important;
}

.align-text-bottom{
  vertical-align: text-bottom !important;
}

.font-sans{
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.font-serif{
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.font-mono{
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-xs{
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-sm{
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-base{
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg{
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-xl{
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-2xl{
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-3xl{
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-4xl{
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-5xl{
  font-size: 3rem !important;
  line-height: 1 !important;
}

.text-6xl{
  font-size: 3.75rem !important;
  line-height: 1 !important;
}

.text-7xl{
  font-size: 4.5rem !important;
  line-height: 1 !important;
}

.text-8xl{
  font-size: 6rem !important;
  line-height: 1 !important;
}

.text-9xl{
  font-size: 8rem !important;
  line-height: 1 !important;
}

.font-thin{
  font-weight: 100 !important;
}

.font-extralight{
  font-weight: 200 !important;
}

.font-light{
  font-weight: 300 !important;
}

.font-normal{
  font-weight: 400 !important;
}

.font-medium{
  font-weight: 500 !important;
}

.font-semibold{
  font-weight: 600 !important;
}

.font-bold{
  font-weight: 700 !important;
}

.font-extrabold{
  font-weight: 800 !important;
}

.font-black{
  font-weight: 900 !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.lowercase{
  text-transform: lowercase !important;
}

.capitalize{
  text-transform: capitalize !important;
}

.normal-case{
  text-transform: none !important;
}

.italic{
  font-style: italic !important;
}

.not-italic{
  font-style: normal !important;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions{
  --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.normal-nums{
  font-variant-numeric: normal !important;
}

.ordinal{
  --tw-ordinal: ordinal !important;
}

.slashed-zero{
  --tw-slashed-zero: slashed-zero !important;
}

.lining-nums{
  --tw-numeric-figure: lining-nums !important;
}

.oldstyle-nums{
  --tw-numeric-figure: oldstyle-nums !important;
}

.proportional-nums{
  --tw-numeric-spacing: proportional-nums !important;
}

.tabular-nums{
  --tw-numeric-spacing: tabular-nums !important;
}

.diagonal-fractions{
  --tw-numeric-fraction: diagonal-fractions !important;
}

.stacked-fractions{
  --tw-numeric-fraction: stacked-fractions !important;
}

.leading-3{
  line-height: .75rem !important;
}

.leading-4{
  line-height: 1rem !important;
}

.leading-5{
  line-height: 1.25rem !important;
}

.leading-6{
  line-height: 1.5rem !important;
}

.leading-7{
  line-height: 1.75rem !important;
}

.leading-8{
  line-height: 2rem !important;
}

.leading-9{
  line-height: 2.25rem !important;
}

.leading-10{
  line-height: 2.5rem !important;
}

.leading-none{
  line-height: 1 !important;
}

.leading-tight{
  line-height: 1.25 !important;
}

.leading-snug{
  line-height: 1.375 !important;
}

.leading-normal{
  line-height: 1.5 !important;
}

.leading-relaxed{
  line-height: 1.625 !important;
}

.leading-loose{
  line-height: 2 !important;
}

.tracking-tighter{
  letter-spacing: -0.05em !important;
}

.tracking-tight{
  letter-spacing: -0.025em !important;
}

.tracking-normal{
  letter-spacing: 0em !important;
}

.tracking-wide{
  letter-spacing: 0.025em !important;
}

.tracking-wider{
  letter-spacing: 0.05em !important;
}

.tracking-widest{
  letter-spacing: 0.1em !important;
}

.text-transparent{
  color: transparent !important;
}

.text-current{
  color: currentColor !important;
}

.text-black{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.text-white{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-gray-50{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.text-gray-100{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.text-gray-200{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.text-gray-400{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.text-gray-700{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.text-gray-800{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.text-gray-900{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.text-red-50{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.text-red-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.text-red-200{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.text-red-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.text-red-400{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.text-red-600{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.text-red-700{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.text-red-800{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.text-red-900{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.text-yellow-50{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.text-yellow-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.text-yellow-200{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.text-yellow-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.text-yellow-400{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.text-yellow-500{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.text-yellow-600{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.text-yellow-700{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.text-yellow-800{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.text-yellow-900{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.text-green-50{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.text-green-100{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.text-green-200{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.text-green-300{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.text-green-400{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.text-green-500{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.text-green-600{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.text-green-700{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.text-green-800{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.text-green-900{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.text-blue-50{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.text-blue-100{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.text-blue-200{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.text-blue-300{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.text-blue-400{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.text-blue-500{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.text-blue-600{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.text-blue-700{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.text-blue-800{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.text-blue-900{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.text-indigo-50{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.text-indigo-100{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.text-indigo-200{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.text-indigo-300{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.text-indigo-400{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.text-indigo-500{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.text-indigo-600{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.text-indigo-700{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.text-indigo-800{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.text-indigo-900{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.text-purple-50{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.text-purple-100{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.text-purple-200{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.text-purple-300{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.text-purple-400{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.text-purple-500{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.text-purple-600{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.text-purple-700{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.text-purple-800{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.text-purple-900{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.text-pink-50{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.text-pink-100{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.text-pink-200{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.text-pink-300{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.text-pink-400{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.text-pink-500{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.text-pink-600{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.text-pink-700{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.text-pink-800{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.text-pink-900{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.text-qipGreen{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
}

.text-portalGreen{
  --tw-text-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-transparent{
  color: transparent !important;
}

.group:hover .group-hover\:text-current{
  color: currentColor !important;
}

.group:hover .group-hover\:text-black{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-white{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-50{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-100{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-200{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-300{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-400{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-500{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-600{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-700{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-800{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-gray-900{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-50{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-200{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-400{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-600{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-700{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-800{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-red-900{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-50{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-100{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-200{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-300{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-400{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-500{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-600{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-700{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-800{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-yellow-900{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-50{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-100{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-200{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-300{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-400{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-500{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-600{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-700{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-800{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-green-900{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-50{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-100{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-200{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-300{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-400{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-500{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-600{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-700{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-800{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-blue-900{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-50{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-100{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-200{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-300{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-400{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-500{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-600{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-700{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-800{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-indigo-900{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-50{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-100{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-200{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-300{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-400{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-500{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-600{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-700{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-800{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-purple-900{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-50{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-100{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-200{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-300{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-400{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-500{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-600{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-700{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-800{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-pink-900{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-qipGreen{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
}

.group:hover .group-hover\:text-portalGreen{
  --tw-text-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
}

.focus-within\:text-transparent:focus-within{
  color: transparent !important;
}

.focus-within\:text-current:focus-within{
  color: currentColor !important;
}

.focus-within\:text-black:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.focus-within\:text-white:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.focus-within\:text-gray-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.focus-within\:text-red-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.focus-within\:text-yellow-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.focus-within\:text-green-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.focus-within\:text-blue-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.focus-within\:text-indigo-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.focus-within\:text-purple-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-50:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-100:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-200:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-300:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-400:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-500:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-600:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-700:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-800:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.focus-within\:text-pink-900:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.focus-within\:text-qipGreen:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
}

.focus-within\:text-portalGreen:focus-within{
  --tw-text-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
}

.hover\:text-transparent:hover{
  color: transparent !important;
}

.hover\:text-current:hover{
  color: currentColor !important;
}

.hover\:text-black:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.hover\:text-white:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.hover\:text-gray-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.hover\:text-red-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.hover\:text-red-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.hover\:text-red-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.hover\:text-red-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.hover\:text-red-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.hover\:text-red-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.hover\:text-red-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.hover\:text-red-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.hover\:text-red-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.hover\:text-red-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.hover\:text-yellow-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.hover\:text-green-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.hover\:text-green-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.hover\:text-green-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.hover\:text-green-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.hover\:text-green-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.hover\:text-green-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.hover\:text-green-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.hover\:text-green-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.hover\:text-green-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.hover\:text-green-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.hover\:text-blue-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.hover\:text-indigo-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.hover\:text-purple-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-50:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-100:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-200:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-300:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-400:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-500:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-600:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-700:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-800:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.hover\:text-pink-900:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.hover\:text-qipGreen:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
}

.hover\:text-portalGreen:hover{
  --tw-text-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
}

.focus\:text-transparent:focus{
  color: transparent !important;
}

.focus\:text-current:focus{
  color: currentColor !important;
}

.focus\:text-black:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.focus\:text-white:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.focus\:text-gray-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
}

.focus\:text-red-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
}

.focus\:text-red-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
}

.focus\:text-red-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
}

.focus\:text-red-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
}

.focus\:text-red-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.focus\:text-red-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.focus\:text-red-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.focus\:text-red-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.focus\:text-red-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
}

.focus\:text-red-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
}

.focus\:text-yellow-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
}

.focus\:text-green-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
}

.focus\:text-green-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
}

.focus\:text-green-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
}

.focus\:text-green-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
}

.focus\:text-green-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
}

.focus\:text-green-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
}

.focus\:text-green-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.focus\:text-green-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
}

.focus\:text-green-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
}

.focus\:text-green-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
}

.focus\:text-blue-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
}

.focus\:text-indigo-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
}

.focus\:text-purple-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-50:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-100:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-200:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-300:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-400:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-500:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-600:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-700:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-800:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
}

.focus\:text-pink-900:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
}

.focus\:text-qipGreen:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
}

.focus\:text-portalGreen:focus{
  --tw-text-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
}

.text-opacity-0{
  --tw-text-opacity: 0 !important;
}

.text-opacity-5{
  --tw-text-opacity: 0.05 !important;
}

.text-opacity-10{
  --tw-text-opacity: 0.1 !important;
}

.text-opacity-20{
  --tw-text-opacity: 0.2 !important;
}

.text-opacity-25{
  --tw-text-opacity: 0.25 !important;
}

.text-opacity-30{
  --tw-text-opacity: 0.3 !important;
}

.text-opacity-40{
  --tw-text-opacity: 0.4 !important;
}

.text-opacity-50{
  --tw-text-opacity: 0.5 !important;
}

.text-opacity-60{
  --tw-text-opacity: 0.6 !important;
}

.text-opacity-70{
  --tw-text-opacity: 0.7 !important;
}

.text-opacity-75{
  --tw-text-opacity: 0.75 !important;
}

.text-opacity-80{
  --tw-text-opacity: 0.8 !important;
}

.text-opacity-90{
  --tw-text-opacity: 0.9 !important;
}

.text-opacity-95{
  --tw-text-opacity: 0.95 !important;
}

.text-opacity-100{
  --tw-text-opacity: 1 !important;
}

.group:hover .group-hover\:text-opacity-0{
  --tw-text-opacity: 0 !important;
}

.group:hover .group-hover\:text-opacity-5{
  --tw-text-opacity: 0.05 !important;
}

.group:hover .group-hover\:text-opacity-10{
  --tw-text-opacity: 0.1 !important;
}

.group:hover .group-hover\:text-opacity-20{
  --tw-text-opacity: 0.2 !important;
}

.group:hover .group-hover\:text-opacity-25{
  --tw-text-opacity: 0.25 !important;
}

.group:hover .group-hover\:text-opacity-30{
  --tw-text-opacity: 0.3 !important;
}

.group:hover .group-hover\:text-opacity-40{
  --tw-text-opacity: 0.4 !important;
}

.group:hover .group-hover\:text-opacity-50{
  --tw-text-opacity: 0.5 !important;
}

.group:hover .group-hover\:text-opacity-60{
  --tw-text-opacity: 0.6 !important;
}

.group:hover .group-hover\:text-opacity-70{
  --tw-text-opacity: 0.7 !important;
}

.group:hover .group-hover\:text-opacity-75{
  --tw-text-opacity: 0.75 !important;
}

.group:hover .group-hover\:text-opacity-80{
  --tw-text-opacity: 0.8 !important;
}

.group:hover .group-hover\:text-opacity-90{
  --tw-text-opacity: 0.9 !important;
}

.group:hover .group-hover\:text-opacity-95{
  --tw-text-opacity: 0.95 !important;
}

.group:hover .group-hover\:text-opacity-100{
  --tw-text-opacity: 1 !important;
}

.focus-within\:text-opacity-0:focus-within{
  --tw-text-opacity: 0 !important;
}

.focus-within\:text-opacity-5:focus-within{
  --tw-text-opacity: 0.05 !important;
}

.focus-within\:text-opacity-10:focus-within{
  --tw-text-opacity: 0.1 !important;
}

.focus-within\:text-opacity-20:focus-within{
  --tw-text-opacity: 0.2 !important;
}

.focus-within\:text-opacity-25:focus-within{
  --tw-text-opacity: 0.25 !important;
}

.focus-within\:text-opacity-30:focus-within{
  --tw-text-opacity: 0.3 !important;
}

.focus-within\:text-opacity-40:focus-within{
  --tw-text-opacity: 0.4 !important;
}

.focus-within\:text-opacity-50:focus-within{
  --tw-text-opacity: 0.5 !important;
}

.focus-within\:text-opacity-60:focus-within{
  --tw-text-opacity: 0.6 !important;
}

.focus-within\:text-opacity-70:focus-within{
  --tw-text-opacity: 0.7 !important;
}

.focus-within\:text-opacity-75:focus-within{
  --tw-text-opacity: 0.75 !important;
}

.focus-within\:text-opacity-80:focus-within{
  --tw-text-opacity: 0.8 !important;
}

.focus-within\:text-opacity-90:focus-within{
  --tw-text-opacity: 0.9 !important;
}

.focus-within\:text-opacity-95:focus-within{
  --tw-text-opacity: 0.95 !important;
}

.focus-within\:text-opacity-100:focus-within{
  --tw-text-opacity: 1 !important;
}

.hover\:text-opacity-0:hover{
  --tw-text-opacity: 0 !important;
}

.hover\:text-opacity-5:hover{
  --tw-text-opacity: 0.05 !important;
}

.hover\:text-opacity-10:hover{
  --tw-text-opacity: 0.1 !important;
}

.hover\:text-opacity-20:hover{
  --tw-text-opacity: 0.2 !important;
}

.hover\:text-opacity-25:hover{
  --tw-text-opacity: 0.25 !important;
}

.hover\:text-opacity-30:hover{
  --tw-text-opacity: 0.3 !important;
}

.hover\:text-opacity-40:hover{
  --tw-text-opacity: 0.4 !important;
}

.hover\:text-opacity-50:hover{
  --tw-text-opacity: 0.5 !important;
}

.hover\:text-opacity-60:hover{
  --tw-text-opacity: 0.6 !important;
}

.hover\:text-opacity-70:hover{
  --tw-text-opacity: 0.7 !important;
}

.hover\:text-opacity-75:hover{
  --tw-text-opacity: 0.75 !important;
}

.hover\:text-opacity-80:hover{
  --tw-text-opacity: 0.8 !important;
}

.hover\:text-opacity-90:hover{
  --tw-text-opacity: 0.9 !important;
}

.hover\:text-opacity-95:hover{
  --tw-text-opacity: 0.95 !important;
}

.hover\:text-opacity-100:hover{
  --tw-text-opacity: 1 !important;
}

.focus\:text-opacity-0:focus{
  --tw-text-opacity: 0 !important;
}

.focus\:text-opacity-5:focus{
  --tw-text-opacity: 0.05 !important;
}

.focus\:text-opacity-10:focus{
  --tw-text-opacity: 0.1 !important;
}

.focus\:text-opacity-20:focus{
  --tw-text-opacity: 0.2 !important;
}

.focus\:text-opacity-25:focus{
  --tw-text-opacity: 0.25 !important;
}

.focus\:text-opacity-30:focus{
  --tw-text-opacity: 0.3 !important;
}

.focus\:text-opacity-40:focus{
  --tw-text-opacity: 0.4 !important;
}

.focus\:text-opacity-50:focus{
  --tw-text-opacity: 0.5 !important;
}

.focus\:text-opacity-60:focus{
  --tw-text-opacity: 0.6 !important;
}

.focus\:text-opacity-70:focus{
  --tw-text-opacity: 0.7 !important;
}

.focus\:text-opacity-75:focus{
  --tw-text-opacity: 0.75 !important;
}

.focus\:text-opacity-80:focus{
  --tw-text-opacity: 0.8 !important;
}

.focus\:text-opacity-90:focus{
  --tw-text-opacity: 0.9 !important;
}

.focus\:text-opacity-95:focus{
  --tw-text-opacity: 0.95 !important;
}

.focus\:text-opacity-100:focus{
  --tw-text-opacity: 1 !important;
}

.underline{
  text-decoration: underline !important;
}

.line-through{
  text-decoration: line-through !important;
}

.no-underline{
  text-decoration: none !important;
}

.group:hover .group-hover\:underline{
  text-decoration: underline !important;
}

.group:hover .group-hover\:line-through{
  text-decoration: line-through !important;
}

.group:hover .group-hover\:no-underline{
  text-decoration: none !important;
}

.focus-within\:underline:focus-within{
  text-decoration: underline !important;
}

.focus-within\:line-through:focus-within{
  text-decoration: line-through !important;
}

.focus-within\:no-underline:focus-within{
  text-decoration: none !important;
}

.hover\:underline:hover{
  text-decoration: underline !important;
}

.hover\:line-through:hover{
  text-decoration: line-through !important;
}

.hover\:no-underline:hover{
  text-decoration: none !important;
}

.focus\:underline:focus{
  text-decoration: underline !important;
}

.focus\:line-through:focus{
  text-decoration: line-through !important;
}

.focus\:no-underline:focus{
  text-decoration: none !important;
}

.antialiased{
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.subpixel-antialiased{
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.placeholder-transparent::-webkit-input-placeholder{
  color: transparent !important;
}

.placeholder-transparent:-ms-input-placeholder{
  color: transparent !important;
}

.placeholder-transparent::placeholder{
  color: transparent !important;
}

.placeholder-current::-webkit-input-placeholder{
  color: currentColor !important;
}

.placeholder-current:-ms-input-placeholder{
  color: currentColor !important;
}

.placeholder-current::placeholder{
  color: currentColor !important;
}

.placeholder-black::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.placeholder-black:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.placeholder-black::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.placeholder-white::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-white:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-white::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.placeholder-gray-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.placeholder-red-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.placeholder-yellow-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.placeholder-green-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.placeholder-blue-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.placeholder-indigo-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.placeholder-purple-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-50::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-200::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-200:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-200::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-300::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-300:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-300::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-400::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-400:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-400::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-500::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-600::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-600:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-600::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-700::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-700:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-700::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-800::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-800:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-800::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-900::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-900:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.placeholder-pink-900::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.placeholder-qipGreen::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
}

.placeholder-qipGreen:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
}

.placeholder-qipGreen::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
}

.placeholder-portalGreen::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
}

.placeholder-portalGreen:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
}

.placeholder-portalGreen::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-transparent:focus::-webkit-input-placeholder{
  color: transparent !important;
}

.focus\:placeholder-transparent:focus:-ms-input-placeholder{
  color: transparent !important;
}

.focus\:placeholder-transparent:focus::placeholder{
  color: transparent !important;
}

.focus\:placeholder-current:focus::-webkit-input-placeholder{
  color: currentColor !important;
}

.focus\:placeholder-current:focus:-ms-input-placeholder{
  color: currentColor !important;
}

.focus\:placeholder-current:focus::placeholder{
  color: currentColor !important;
}

.focus\:placeholder-black:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-black:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-black:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-white:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-white:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-white:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-gray-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-red-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-yellow-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-green-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-blue-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-indigo-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-purple-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-50:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-200:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-200:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-200:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-300:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-300:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-300:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-400:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-500:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-500:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-500:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-600:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-600:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-600:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-700:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-700:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-700:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-800:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-800:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-800:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-900:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-900:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-pink-900:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-qipGreen:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-qipGreen:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-qipGreen:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-portalGreen:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-portalGreen:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
}

.focus\:placeholder-portalGreen:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
  color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
}

.placeholder-opacity-0::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.placeholder-opacity-0:-ms-input-placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.placeholder-opacity-0::placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.placeholder-opacity-5::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.placeholder-opacity-5:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.placeholder-opacity-5::placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.placeholder-opacity-10::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.placeholder-opacity-10:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.placeholder-opacity-10::placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.placeholder-opacity-20::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.placeholder-opacity-20:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.placeholder-opacity-20::placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.placeholder-opacity-25::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25::placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-30::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.placeholder-opacity-30:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.placeholder-opacity-30::placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.placeholder-opacity-40::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.placeholder-opacity-40:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.placeholder-opacity-40::placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.placeholder-opacity-50::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50::placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-60::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.placeholder-opacity-60:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.placeholder-opacity-60::placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.placeholder-opacity-70::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.placeholder-opacity-70:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.placeholder-opacity-70::placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.placeholder-opacity-75::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75::placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-80::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.placeholder-opacity-80:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.placeholder-opacity-80::placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.placeholder-opacity-90::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.placeholder-opacity-90:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.placeholder-opacity-90::placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.placeholder-opacity-95::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.placeholder-opacity-95:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.placeholder-opacity-95::placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.placeholder-opacity-100::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.placeholder-opacity-100:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.placeholder-opacity-100::placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-0:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus::placeholder{
  --tw-placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-5:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.focus\:placeholder-opacity-5:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.focus\:placeholder-opacity-5:focus::placeholder{
  --tw-placeholder-opacity: 0.05 !important;
}

.focus\:placeholder-opacity-10:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.focus\:placeholder-opacity-10:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.focus\:placeholder-opacity-10:focus::placeholder{
  --tw-placeholder-opacity: 0.1 !important;
}

.focus\:placeholder-opacity-20:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.focus\:placeholder-opacity-20:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.focus\:placeholder-opacity-20:focus::placeholder{
  --tw-placeholder-opacity: 0.2 !important;
}

.focus\:placeholder-opacity-25:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus::placeholder{
  --tw-placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-30:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.focus\:placeholder-opacity-30:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.focus\:placeholder-opacity-30:focus::placeholder{
  --tw-placeholder-opacity: 0.3 !important;
}

.focus\:placeholder-opacity-40:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.focus\:placeholder-opacity-40:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.focus\:placeholder-opacity-40:focus::placeholder{
  --tw-placeholder-opacity: 0.4 !important;
}

.focus\:placeholder-opacity-50:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus::placeholder{
  --tw-placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-60:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.focus\:placeholder-opacity-60:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.focus\:placeholder-opacity-60:focus::placeholder{
  --tw-placeholder-opacity: 0.6 !important;
}

.focus\:placeholder-opacity-70:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.focus\:placeholder-opacity-70:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.focus\:placeholder-opacity-70:focus::placeholder{
  --tw-placeholder-opacity: 0.7 !important;
}

.focus\:placeholder-opacity-75:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus::placeholder{
  --tw-placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-80:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.focus\:placeholder-opacity-80:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.focus\:placeholder-opacity-80:focus::placeholder{
  --tw-placeholder-opacity: 0.8 !important;
}

.focus\:placeholder-opacity-90:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.focus\:placeholder-opacity-90:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.focus\:placeholder-opacity-90:focus::placeholder{
  --tw-placeholder-opacity: 0.9 !important;
}

.focus\:placeholder-opacity-95:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.focus\:placeholder-opacity-95:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.focus\:placeholder-opacity-95:focus::placeholder{
  --tw-placeholder-opacity: 0.95 !important;
}

.focus\:placeholder-opacity-100:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus::placeholder{
  --tw-placeholder-opacity: 1 !important;
}

.caret-transparent{
  caret-color: transparent !important;
}

.caret-current{
  caret-color: currentColor !important;
}

.caret-black{
  caret-color: #000 !important;
}

.caret-white{
  caret-color: #fff !important;
}

.caret-gray-50{
  caret-color: #f9fafb !important;
}

.caret-gray-100{
  caret-color: #f3f4f6 !important;
}

.caret-gray-200{
  caret-color: #e5e7eb !important;
}

.caret-gray-300{
  caret-color: #d1d5db !important;
}

.caret-gray-400{
  caret-color: #9ca3af !important;
}

.caret-gray-500{
  caret-color: #6b7280 !important;
}

.caret-gray-600{
  caret-color: #4b5563 !important;
}

.caret-gray-700{
  caret-color: #374151 !important;
}

.caret-gray-800{
  caret-color: #1f2937 !important;
}

.caret-gray-900{
  caret-color: #111827 !important;
}

.caret-red-50{
  caret-color: #fef2f2 !important;
}

.caret-red-100{
  caret-color: #fee2e2 !important;
}

.caret-red-200{
  caret-color: #fecaca !important;
}

.caret-red-300{
  caret-color: #fca5a5 !important;
}

.caret-red-400{
  caret-color: #f87171 !important;
}

.caret-red-500{
  caret-color: #ef4444 !important;
}

.caret-red-600{
  caret-color: #dc2626 !important;
}

.caret-red-700{
  caret-color: #b91c1c !important;
}

.caret-red-800{
  caret-color: #991b1b !important;
}

.caret-red-900{
  caret-color: #7f1d1d !important;
}

.caret-yellow-50{
  caret-color: #fffbeb !important;
}

.caret-yellow-100{
  caret-color: #fef3c7 !important;
}

.caret-yellow-200{
  caret-color: #fde68a !important;
}

.caret-yellow-300{
  caret-color: #fcd34d !important;
}

.caret-yellow-400{
  caret-color: #fbbf24 !important;
}

.caret-yellow-500{
  caret-color: #f59e0b !important;
}

.caret-yellow-600{
  caret-color: #d97706 !important;
}

.caret-yellow-700{
  caret-color: #b45309 !important;
}

.caret-yellow-800{
  caret-color: #92400e !important;
}

.caret-yellow-900{
  caret-color: #78350f !important;
}

.caret-green-50{
  caret-color: #ecfdf5 !important;
}

.caret-green-100{
  caret-color: #d1fae5 !important;
}

.caret-green-200{
  caret-color: #a7f3d0 !important;
}

.caret-green-300{
  caret-color: #6ee7b7 !important;
}

.caret-green-400{
  caret-color: #34d399 !important;
}

.caret-green-500{
  caret-color: #10b981 !important;
}

.caret-green-600{
  caret-color: #059669 !important;
}

.caret-green-700{
  caret-color: #047857 !important;
}

.caret-green-800{
  caret-color: #065f46 !important;
}

.caret-green-900{
  caret-color: #064e3b !important;
}

.caret-blue-50{
  caret-color: #eff6ff !important;
}

.caret-blue-100{
  caret-color: #dbeafe !important;
}

.caret-blue-200{
  caret-color: #bfdbfe !important;
}

.caret-blue-300{
  caret-color: #93c5fd !important;
}

.caret-blue-400{
  caret-color: #60a5fa !important;
}

.caret-blue-500{
  caret-color: #3b82f6 !important;
}

.caret-blue-600{
  caret-color: #2563eb !important;
}

.caret-blue-700{
  caret-color: #1d4ed8 !important;
}

.caret-blue-800{
  caret-color: #1e40af !important;
}

.caret-blue-900{
  caret-color: #1e3a8a !important;
}

.caret-indigo-50{
  caret-color: #eef2ff !important;
}

.caret-indigo-100{
  caret-color: #e0e7ff !important;
}

.caret-indigo-200{
  caret-color: #c7d2fe !important;
}

.caret-indigo-300{
  caret-color: #a5b4fc !important;
}

.caret-indigo-400{
  caret-color: #818cf8 !important;
}

.caret-indigo-500{
  caret-color: #6366f1 !important;
}

.caret-indigo-600{
  caret-color: #4f46e5 !important;
}

.caret-indigo-700{
  caret-color: #4338ca !important;
}

.caret-indigo-800{
  caret-color: #3730a3 !important;
}

.caret-indigo-900{
  caret-color: #312e81 !important;
}

.caret-purple-50{
  caret-color: #f5f3ff !important;
}

.caret-purple-100{
  caret-color: #ede9fe !important;
}

.caret-purple-200{
  caret-color: #ddd6fe !important;
}

.caret-purple-300{
  caret-color: #c4b5fd !important;
}

.caret-purple-400{
  caret-color: #a78bfa !important;
}

.caret-purple-500{
  caret-color: #8b5cf6 !important;
}

.caret-purple-600{
  caret-color: #7c3aed !important;
}

.caret-purple-700{
  caret-color: #6d28d9 !important;
}

.caret-purple-800{
  caret-color: #5b21b6 !important;
}

.caret-purple-900{
  caret-color: #4c1d95 !important;
}

.caret-pink-50{
  caret-color: #fdf2f8 !important;
}

.caret-pink-100{
  caret-color: #fce7f3 !important;
}

.caret-pink-200{
  caret-color: #fbcfe8 !important;
}

.caret-pink-300{
  caret-color: #f9a8d4 !important;
}

.caret-pink-400{
  caret-color: #f472b6 !important;
}

.caret-pink-500{
  caret-color: #ec4899 !important;
}

.caret-pink-600{
  caret-color: #db2777 !important;
}

.caret-pink-700{
  caret-color: #be185d !important;
}

.caret-pink-800{
  caret-color: #9d174d !important;
}

.caret-pink-900{
  caret-color: #831843 !important;
}

.caret-qipGreen{
  caret-color: #8bc334 !important;
}

.caret-portalGreen{
  caret-color: #8ac04a !important;
}

.opacity-0{
  opacity: 0 !important;
}

.opacity-5{
  opacity: 0.05 !important;
}

.opacity-10{
  opacity: 0.1 !important;
}

.opacity-20{
  opacity: 0.2 !important;
}

.opacity-25{
  opacity: 0.25 !important;
}

.opacity-30{
  opacity: 0.3 !important;
}

.opacity-40{
  opacity: 0.4 !important;
}

.opacity-50{
  opacity: 0.5 !important;
}

.opacity-60{
  opacity: 0.6 !important;
}

.opacity-70{
  opacity: 0.7 !important;
}

.opacity-75{
  opacity: 0.75 !important;
}

.opacity-80{
  opacity: 0.8 !important;
}

.opacity-90{
  opacity: 0.9 !important;
}

.opacity-95{
  opacity: 0.95 !important;
}

.opacity-100{
  opacity: 1 !important;
}

.group:hover .group-hover\:opacity-0{
  opacity: 0 !important;
}

.group:hover .group-hover\:opacity-5{
  opacity: 0.05 !important;
}

.group:hover .group-hover\:opacity-10{
  opacity: 0.1 !important;
}

.group:hover .group-hover\:opacity-20{
  opacity: 0.2 !important;
}

.group:hover .group-hover\:opacity-25{
  opacity: 0.25 !important;
}

.group:hover .group-hover\:opacity-30{
  opacity: 0.3 !important;
}

.group:hover .group-hover\:opacity-40{
  opacity: 0.4 !important;
}

.group:hover .group-hover\:opacity-50{
  opacity: 0.5 !important;
}

.group:hover .group-hover\:opacity-60{
  opacity: 0.6 !important;
}

.group:hover .group-hover\:opacity-70{
  opacity: 0.7 !important;
}

.group:hover .group-hover\:opacity-75{
  opacity: 0.75 !important;
}

.group:hover .group-hover\:opacity-80{
  opacity: 0.8 !important;
}

.group:hover .group-hover\:opacity-90{
  opacity: 0.9 !important;
}

.group:hover .group-hover\:opacity-95{
  opacity: 0.95 !important;
}

.group:hover .group-hover\:opacity-100{
  opacity: 1 !important;
}

.focus-within\:opacity-0:focus-within{
  opacity: 0 !important;
}

.focus-within\:opacity-5:focus-within{
  opacity: 0.05 !important;
}

.focus-within\:opacity-10:focus-within{
  opacity: 0.1 !important;
}

.focus-within\:opacity-20:focus-within{
  opacity: 0.2 !important;
}

.focus-within\:opacity-25:focus-within{
  opacity: 0.25 !important;
}

.focus-within\:opacity-30:focus-within{
  opacity: 0.3 !important;
}

.focus-within\:opacity-40:focus-within{
  opacity: 0.4 !important;
}

.focus-within\:opacity-50:focus-within{
  opacity: 0.5 !important;
}

.focus-within\:opacity-60:focus-within{
  opacity: 0.6 !important;
}

.focus-within\:opacity-70:focus-within{
  opacity: 0.7 !important;
}

.focus-within\:opacity-75:focus-within{
  opacity: 0.75 !important;
}

.focus-within\:opacity-80:focus-within{
  opacity: 0.8 !important;
}

.focus-within\:opacity-90:focus-within{
  opacity: 0.9 !important;
}

.focus-within\:opacity-95:focus-within{
  opacity: 0.95 !important;
}

.focus-within\:opacity-100:focus-within{
  opacity: 1 !important;
}

.hover\:opacity-0:hover{
  opacity: 0 !important;
}

.hover\:opacity-5:hover{
  opacity: 0.05 !important;
}

.hover\:opacity-10:hover{
  opacity: 0.1 !important;
}

.hover\:opacity-20:hover{
  opacity: 0.2 !important;
}

.hover\:opacity-25:hover{
  opacity: 0.25 !important;
}

.hover\:opacity-30:hover{
  opacity: 0.3 !important;
}

.hover\:opacity-40:hover{
  opacity: 0.4 !important;
}

.hover\:opacity-50:hover{
  opacity: 0.5 !important;
}

.hover\:opacity-60:hover{
  opacity: 0.6 !important;
}

.hover\:opacity-70:hover{
  opacity: 0.7 !important;
}

.hover\:opacity-75:hover{
  opacity: 0.75 !important;
}

.hover\:opacity-80:hover{
  opacity: 0.8 !important;
}

.hover\:opacity-90:hover{
  opacity: 0.9 !important;
}

.hover\:opacity-95:hover{
  opacity: 0.95 !important;
}

.hover\:opacity-100:hover{
  opacity: 1 !important;
}

.focus\:opacity-0:focus{
  opacity: 0 !important;
}

.focus\:opacity-5:focus{
  opacity: 0.05 !important;
}

.focus\:opacity-10:focus{
  opacity: 0.1 !important;
}

.focus\:opacity-20:focus{
  opacity: 0.2 !important;
}

.focus\:opacity-25:focus{
  opacity: 0.25 !important;
}

.focus\:opacity-30:focus{
  opacity: 0.3 !important;
}

.focus\:opacity-40:focus{
  opacity: 0.4 !important;
}

.focus\:opacity-50:focus{
  opacity: 0.5 !important;
}

.focus\:opacity-60:focus{
  opacity: 0.6 !important;
}

.focus\:opacity-70:focus{
  opacity: 0.7 !important;
}

.focus\:opacity-75:focus{
  opacity: 0.75 !important;
}

.focus\:opacity-80:focus{
  opacity: 0.8 !important;
}

.focus\:opacity-90:focus{
  opacity: 0.9 !important;
}

.focus\:opacity-95:focus{
  opacity: 0.95 !important;
}

.focus\:opacity-100:focus{
  opacity: 1 !important;
}

.bg-blend-normal{
  background-blend-mode: normal !important;
}

.bg-blend-multiply{
  background-blend-mode: multiply !important;
}

.bg-blend-screen{
  background-blend-mode: screen !important;
}

.bg-blend-overlay{
  background-blend-mode: overlay !important;
}

.bg-blend-darken{
  background-blend-mode: darken !important;
}

.bg-blend-lighten{
  background-blend-mode: lighten !important;
}

.bg-blend-color-dodge{
  background-blend-mode: color-dodge !important;
}

.bg-blend-color-burn{
  background-blend-mode: color-burn !important;
}

.bg-blend-hard-light{
  background-blend-mode: hard-light !important;
}

.bg-blend-soft-light{
  background-blend-mode: soft-light !important;
}

.bg-blend-difference{
  background-blend-mode: difference !important;
}

.bg-blend-exclusion{
  background-blend-mode: exclusion !important;
}

.bg-blend-hue{
  background-blend-mode: hue !important;
}

.bg-blend-saturation{
  background-blend-mode: saturation !important;
}

.bg-blend-color{
  background-blend-mode: color !important;
}

.bg-blend-luminosity{
  background-blend-mode: luminosity !important;
}

.mix-blend-normal{
  mix-blend-mode: normal !important;
}

.mix-blend-multiply{
  mix-blend-mode: multiply !important;
}

.mix-blend-screen{
  mix-blend-mode: screen !important;
}

.mix-blend-overlay{
  mix-blend-mode: overlay !important;
}

.mix-blend-darken{
  mix-blend-mode: darken !important;
}

.mix-blend-lighten{
  mix-blend-mode: lighten !important;
}

.mix-blend-color-dodge{
  mix-blend-mode: color-dodge !important;
}

.mix-blend-color-burn{
  mix-blend-mode: color-burn !important;
}

.mix-blend-hard-light{
  mix-blend-mode: hard-light !important;
}

.mix-blend-soft-light{
  mix-blend-mode: soft-light !important;
}

.mix-blend-difference{
  mix-blend-mode: difference !important;
}

.mix-blend-exclusion{
  mix-blend-mode: exclusion !important;
}

.mix-blend-hue{
  mix-blend-mode: hue !important;
}

.mix-blend-saturation{
  mix-blend-mode: saturation !important;
}

.mix-blend-color{
  mix-blend-mode: color !important;
}

.mix-blend-luminosity{
  mix-blend-mode: luminosity !important;
}

*, ::before, ::after{
  --tw-shadow: 0 0 #0000;
}

.shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow-none{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-md{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-xl{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-2xl{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-inner{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.group:hover .group-hover\:shadow-none{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-sm:focus-within{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow:focus-within{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-md:focus-within{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-lg:focus-within{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-xl:focus-within{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-2xl:focus-within{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-inner:focus-within{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus-within\:shadow-none:focus-within{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-sm:hover{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow:hover{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-md:hover{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-lg:hover{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-xl:hover{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-2xl:hover{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-inner:hover{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-none:hover{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-sm:focus{
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow:focus{
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-md:focus{
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-lg:focus{
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-xl:focus{
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-2xl:focus{
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-inner:focus{
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.focus\:shadow-none:focus{
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.outline-none{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.outline-white{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.outline-black{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

.focus-within\:outline-none:focus-within{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus-within\:outline-white:focus-within{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.focus-within\:outline-black:focus-within{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

.focus\:outline-none:focus{
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus\:outline-white:focus{
  outline: 2px dotted white !important;
  outline-offset: 2px !important;
}

.focus\:outline-black:focus{
  outline: 2px dotted black !important;
  outline-offset: 2px !important;
}

*, ::before, ::after{
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-0{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring-1{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring-2{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring-4{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring-8{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:ring-0:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:ring-1:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:ring-2:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:ring-4:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:ring-8:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-within\:ring:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-1:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-4:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring-8:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:ring:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.ring-inset{
  --tw-ring-inset: inset !important;
}

.focus-within\:ring-inset:focus-within{
  --tw-ring-inset: inset !important;
}

.focus\:ring-inset:focus{
  --tw-ring-inset: inset !important;
}

.ring-transparent{
  --tw-ring-color: transparent !important;
}

.ring-current{
  --tw-ring-color: currentColor !important;
}

.ring-black{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.ring-white{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
}

.ring-gray-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
}

.ring-gray-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
}

.ring-gray-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
}

.ring-gray-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
}

.ring-gray-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
}

.ring-gray-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
}

.ring-gray-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
}

.ring-gray-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
}

.ring-gray-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
}

.ring-gray-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
}

.ring-red-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
}

.ring-red-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
}

.ring-red-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
}

.ring-red-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
}

.ring-red-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
}

.ring-red-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
}

.ring-red-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
}

.ring-red-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
}

.ring-red-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
}

.ring-red-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
}

.ring-yellow-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
}

.ring-yellow-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
}

.ring-yellow-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
}

.ring-yellow-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
}

.ring-yellow-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
}

.ring-yellow-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
}

.ring-yellow-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
}

.ring-yellow-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
}

.ring-yellow-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
}

.ring-yellow-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
}

.ring-green-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
}

.ring-green-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
}

.ring-green-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
}

.ring-green-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
}

.ring-green-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
}

.ring-green-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
}

.ring-green-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
}

.ring-green-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
}

.ring-green-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
}

.ring-green-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
}

.ring-blue-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
}

.ring-blue-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
}

.ring-blue-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
}

.ring-blue-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
}

.ring-blue-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
}

.ring-blue-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
}

.ring-blue-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
}

.ring-blue-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
}

.ring-blue-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
}

.ring-blue-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
}

.ring-indigo-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
}

.ring-indigo-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
}

.ring-indigo-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
}

.ring-indigo-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
}

.ring-indigo-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
}

.ring-indigo-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
}

.ring-indigo-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
}

.ring-indigo-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
}

.ring-indigo-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
}

.ring-indigo-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
}

.ring-purple-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
}

.ring-purple-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
}

.ring-purple-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
}

.ring-purple-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
}

.ring-purple-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
}

.ring-purple-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
}

.ring-purple-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
}

.ring-purple-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
}

.ring-purple-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
}

.ring-purple-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
}

.ring-pink-50{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
}

.ring-pink-100{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
}

.ring-pink-200{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
}

.ring-pink-300{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
}

.ring-pink-400{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
}

.ring-pink-500{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
}

.ring-pink-600{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
}

.ring-pink-700{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
}

.ring-pink-800{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
}

.ring-pink-900{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
}

.ring-qipGreen{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
}

.ring-portalGreen{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-transparent:focus-within{
  --tw-ring-color: transparent !important;
}

.focus-within\:ring-current:focus-within{
  --tw-ring-color: currentColor !important;
}

.focus-within\:ring-black:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-white:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-gray-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-red-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-yellow-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-green-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-blue-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-indigo-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-purple-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-50:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-100:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-200:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-300:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-400:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-500:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-600:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-700:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-800:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-pink-900:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-qipGreen:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
}

.focus-within\:ring-portalGreen:focus-within{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
}

.focus\:ring-transparent:focus{
  --tw-ring-color: transparent !important;
}

.focus\:ring-current:focus{
  --tw-ring-color: currentColor !important;
}

.focus\:ring-black:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
}

.focus\:ring-white:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
}

.focus\:ring-gray-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
}

.focus\:ring-red-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
}

.focus\:ring-yellow-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
}

.focus\:ring-green-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
}

.focus\:ring-blue-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
}

.focus\:ring-indigo-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
}

.focus\:ring-purple-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-50:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-100:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-200:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-300:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-400:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-500:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-600:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-700:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-800:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
}

.focus\:ring-pink-900:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
}

.focus\:ring-qipGreen:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
}

.focus\:ring-portalGreen:focus{
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
}

.ring-opacity-0{
  --tw-ring-opacity: 0 !important;
}

.ring-opacity-5{
  --tw-ring-opacity: 0.05 !important;
}

.ring-opacity-10{
  --tw-ring-opacity: 0.1 !important;
}

.ring-opacity-20{
  --tw-ring-opacity: 0.2 !important;
}

.ring-opacity-25{
  --tw-ring-opacity: 0.25 !important;
}

.ring-opacity-30{
  --tw-ring-opacity: 0.3 !important;
}

.ring-opacity-40{
  --tw-ring-opacity: 0.4 !important;
}

.ring-opacity-50{
  --tw-ring-opacity: 0.5 !important;
}

.ring-opacity-60{
  --tw-ring-opacity: 0.6 !important;
}

.ring-opacity-70{
  --tw-ring-opacity: 0.7 !important;
}

.ring-opacity-75{
  --tw-ring-opacity: 0.75 !important;
}

.ring-opacity-80{
  --tw-ring-opacity: 0.8 !important;
}

.ring-opacity-90{
  --tw-ring-opacity: 0.9 !important;
}

.ring-opacity-95{
  --tw-ring-opacity: 0.95 !important;
}

.ring-opacity-100{
  --tw-ring-opacity: 1 !important;
}

.focus-within\:ring-opacity-0:focus-within{
  --tw-ring-opacity: 0 !important;
}

.focus-within\:ring-opacity-5:focus-within{
  --tw-ring-opacity: 0.05 !important;
}

.focus-within\:ring-opacity-10:focus-within{
  --tw-ring-opacity: 0.1 !important;
}

.focus-within\:ring-opacity-20:focus-within{
  --tw-ring-opacity: 0.2 !important;
}

.focus-within\:ring-opacity-25:focus-within{
  --tw-ring-opacity: 0.25 !important;
}

.focus-within\:ring-opacity-30:focus-within{
  --tw-ring-opacity: 0.3 !important;
}

.focus-within\:ring-opacity-40:focus-within{
  --tw-ring-opacity: 0.4 !important;
}

.focus-within\:ring-opacity-50:focus-within{
  --tw-ring-opacity: 0.5 !important;
}

.focus-within\:ring-opacity-60:focus-within{
  --tw-ring-opacity: 0.6 !important;
}

.focus-within\:ring-opacity-70:focus-within{
  --tw-ring-opacity: 0.7 !important;
}

.focus-within\:ring-opacity-75:focus-within{
  --tw-ring-opacity: 0.75 !important;
}

.focus-within\:ring-opacity-80:focus-within{
  --tw-ring-opacity: 0.8 !important;
}

.focus-within\:ring-opacity-90:focus-within{
  --tw-ring-opacity: 0.9 !important;
}

.focus-within\:ring-opacity-95:focus-within{
  --tw-ring-opacity: 0.95 !important;
}

.focus-within\:ring-opacity-100:focus-within{
  --tw-ring-opacity: 1 !important;
}

.focus\:ring-opacity-0:focus{
  --tw-ring-opacity: 0 !important;
}

.focus\:ring-opacity-5:focus{
  --tw-ring-opacity: 0.05 !important;
}

.focus\:ring-opacity-10:focus{
  --tw-ring-opacity: 0.1 !important;
}

.focus\:ring-opacity-20:focus{
  --tw-ring-opacity: 0.2 !important;
}

.focus\:ring-opacity-25:focus{
  --tw-ring-opacity: 0.25 !important;
}

.focus\:ring-opacity-30:focus{
  --tw-ring-opacity: 0.3 !important;
}

.focus\:ring-opacity-40:focus{
  --tw-ring-opacity: 0.4 !important;
}

.focus\:ring-opacity-50:focus{
  --tw-ring-opacity: 0.5 !important;
}

.focus\:ring-opacity-60:focus{
  --tw-ring-opacity: 0.6 !important;
}

.focus\:ring-opacity-70:focus{
  --tw-ring-opacity: 0.7 !important;
}

.focus\:ring-opacity-75:focus{
  --tw-ring-opacity: 0.75 !important;
}

.focus\:ring-opacity-80:focus{
  --tw-ring-opacity: 0.8 !important;
}

.focus\:ring-opacity-90:focus{
  --tw-ring-opacity: 0.9 !important;
}

.focus\:ring-opacity-95:focus{
  --tw-ring-opacity: 0.95 !important;
}

.focus\:ring-opacity-100:focus{
  --tw-ring-opacity: 1 !important;
}

.ring-offset-0{
  --tw-ring-offset-width: 0px !important;
}

.ring-offset-1{
  --tw-ring-offset-width: 1px !important;
}

.ring-offset-2{
  --tw-ring-offset-width: 2px !important;
}

.ring-offset-4{
  --tw-ring-offset-width: 4px !important;
}

.ring-offset-8{
  --tw-ring-offset-width: 8px !important;
}

.focus-within\:ring-offset-0:focus-within{
  --tw-ring-offset-width: 0px !important;
}

.focus-within\:ring-offset-1:focus-within{
  --tw-ring-offset-width: 1px !important;
}

.focus-within\:ring-offset-2:focus-within{
  --tw-ring-offset-width: 2px !important;
}

.focus-within\:ring-offset-4:focus-within{
  --tw-ring-offset-width: 4px !important;
}

.focus-within\:ring-offset-8:focus-within{
  --tw-ring-offset-width: 8px !important;
}

.focus\:ring-offset-0:focus{
  --tw-ring-offset-width: 0px !important;
}

.focus\:ring-offset-1:focus{
  --tw-ring-offset-width: 1px !important;
}

.focus\:ring-offset-2:focus{
  --tw-ring-offset-width: 2px !important;
}

.focus\:ring-offset-4:focus{
  --tw-ring-offset-width: 4px !important;
}

.focus\:ring-offset-8:focus{
  --tw-ring-offset-width: 8px !important;
}

.ring-offset-transparent{
  --tw-ring-offset-color: transparent !important;
}

.ring-offset-current{
  --tw-ring-offset-color: currentColor !important;
}

.ring-offset-black{
  --tw-ring-offset-color: #000 !important;
}

.ring-offset-white{
  --tw-ring-offset-color: #fff !important;
}

.ring-offset-gray-50{
  --tw-ring-offset-color: #f9fafb !important;
}

.ring-offset-gray-100{
  --tw-ring-offset-color: #f3f4f6 !important;
}

.ring-offset-gray-200{
  --tw-ring-offset-color: #e5e7eb !important;
}

.ring-offset-gray-300{
  --tw-ring-offset-color: #d1d5db !important;
}

.ring-offset-gray-400{
  --tw-ring-offset-color: #9ca3af !important;
}

.ring-offset-gray-500{
  --tw-ring-offset-color: #6b7280 !important;
}

.ring-offset-gray-600{
  --tw-ring-offset-color: #4b5563 !important;
}

.ring-offset-gray-700{
  --tw-ring-offset-color: #374151 !important;
}

.ring-offset-gray-800{
  --tw-ring-offset-color: #1f2937 !important;
}

.ring-offset-gray-900{
  --tw-ring-offset-color: #111827 !important;
}

.ring-offset-red-50{
  --tw-ring-offset-color: #fef2f2 !important;
}

.ring-offset-red-100{
  --tw-ring-offset-color: #fee2e2 !important;
}

.ring-offset-red-200{
  --tw-ring-offset-color: #fecaca !important;
}

.ring-offset-red-300{
  --tw-ring-offset-color: #fca5a5 !important;
}

.ring-offset-red-400{
  --tw-ring-offset-color: #f87171 !important;
}

.ring-offset-red-500{
  --tw-ring-offset-color: #ef4444 !important;
}

.ring-offset-red-600{
  --tw-ring-offset-color: #dc2626 !important;
}

.ring-offset-red-700{
  --tw-ring-offset-color: #b91c1c !important;
}

.ring-offset-red-800{
  --tw-ring-offset-color: #991b1b !important;
}

.ring-offset-red-900{
  --tw-ring-offset-color: #7f1d1d !important;
}

.ring-offset-yellow-50{
  --tw-ring-offset-color: #fffbeb !important;
}

.ring-offset-yellow-100{
  --tw-ring-offset-color: #fef3c7 !important;
}

.ring-offset-yellow-200{
  --tw-ring-offset-color: #fde68a !important;
}

.ring-offset-yellow-300{
  --tw-ring-offset-color: #fcd34d !important;
}

.ring-offset-yellow-400{
  --tw-ring-offset-color: #fbbf24 !important;
}

.ring-offset-yellow-500{
  --tw-ring-offset-color: #f59e0b !important;
}

.ring-offset-yellow-600{
  --tw-ring-offset-color: #d97706 !important;
}

.ring-offset-yellow-700{
  --tw-ring-offset-color: #b45309 !important;
}

.ring-offset-yellow-800{
  --tw-ring-offset-color: #92400e !important;
}

.ring-offset-yellow-900{
  --tw-ring-offset-color: #78350f !important;
}

.ring-offset-green-50{
  --tw-ring-offset-color: #ecfdf5 !important;
}

.ring-offset-green-100{
  --tw-ring-offset-color: #d1fae5 !important;
}

.ring-offset-green-200{
  --tw-ring-offset-color: #a7f3d0 !important;
}

.ring-offset-green-300{
  --tw-ring-offset-color: #6ee7b7 !important;
}

.ring-offset-green-400{
  --tw-ring-offset-color: #34d399 !important;
}

.ring-offset-green-500{
  --tw-ring-offset-color: #10b981 !important;
}

.ring-offset-green-600{
  --tw-ring-offset-color: #059669 !important;
}

.ring-offset-green-700{
  --tw-ring-offset-color: #047857 !important;
}

.ring-offset-green-800{
  --tw-ring-offset-color: #065f46 !important;
}

.ring-offset-green-900{
  --tw-ring-offset-color: #064e3b !important;
}

.ring-offset-blue-50{
  --tw-ring-offset-color: #eff6ff !important;
}

.ring-offset-blue-100{
  --tw-ring-offset-color: #dbeafe !important;
}

.ring-offset-blue-200{
  --tw-ring-offset-color: #bfdbfe !important;
}

.ring-offset-blue-300{
  --tw-ring-offset-color: #93c5fd !important;
}

.ring-offset-blue-400{
  --tw-ring-offset-color: #60a5fa !important;
}

.ring-offset-blue-500{
  --tw-ring-offset-color: #3b82f6 !important;
}

.ring-offset-blue-600{
  --tw-ring-offset-color: #2563eb !important;
}

.ring-offset-blue-700{
  --tw-ring-offset-color: #1d4ed8 !important;
}

.ring-offset-blue-800{
  --tw-ring-offset-color: #1e40af !important;
}

.ring-offset-blue-900{
  --tw-ring-offset-color: #1e3a8a !important;
}

.ring-offset-indigo-50{
  --tw-ring-offset-color: #eef2ff !important;
}

.ring-offset-indigo-100{
  --tw-ring-offset-color: #e0e7ff !important;
}

.ring-offset-indigo-200{
  --tw-ring-offset-color: #c7d2fe !important;
}

.ring-offset-indigo-300{
  --tw-ring-offset-color: #a5b4fc !important;
}

.ring-offset-indigo-400{
  --tw-ring-offset-color: #818cf8 !important;
}

.ring-offset-indigo-500{
  --tw-ring-offset-color: #6366f1 !important;
}

.ring-offset-indigo-600{
  --tw-ring-offset-color: #4f46e5 !important;
}

.ring-offset-indigo-700{
  --tw-ring-offset-color: #4338ca !important;
}

.ring-offset-indigo-800{
  --tw-ring-offset-color: #3730a3 !important;
}

.ring-offset-indigo-900{
  --tw-ring-offset-color: #312e81 !important;
}

.ring-offset-purple-50{
  --tw-ring-offset-color: #f5f3ff !important;
}

.ring-offset-purple-100{
  --tw-ring-offset-color: #ede9fe !important;
}

.ring-offset-purple-200{
  --tw-ring-offset-color: #ddd6fe !important;
}

.ring-offset-purple-300{
  --tw-ring-offset-color: #c4b5fd !important;
}

.ring-offset-purple-400{
  --tw-ring-offset-color: #a78bfa !important;
}

.ring-offset-purple-500{
  --tw-ring-offset-color: #8b5cf6 !important;
}

.ring-offset-purple-600{
  --tw-ring-offset-color: #7c3aed !important;
}

.ring-offset-purple-700{
  --tw-ring-offset-color: #6d28d9 !important;
}

.ring-offset-purple-800{
  --tw-ring-offset-color: #5b21b6 !important;
}

.ring-offset-purple-900{
  --tw-ring-offset-color: #4c1d95 !important;
}

.ring-offset-pink-50{
  --tw-ring-offset-color: #fdf2f8 !important;
}

.ring-offset-pink-100{
  --tw-ring-offset-color: #fce7f3 !important;
}

.ring-offset-pink-200{
  --tw-ring-offset-color: #fbcfe8 !important;
}

.ring-offset-pink-300{
  --tw-ring-offset-color: #f9a8d4 !important;
}

.ring-offset-pink-400{
  --tw-ring-offset-color: #f472b6 !important;
}

.ring-offset-pink-500{
  --tw-ring-offset-color: #ec4899 !important;
}

.ring-offset-pink-600{
  --tw-ring-offset-color: #db2777 !important;
}

.ring-offset-pink-700{
  --tw-ring-offset-color: #be185d !important;
}

.ring-offset-pink-800{
  --tw-ring-offset-color: #9d174d !important;
}

.ring-offset-pink-900{
  --tw-ring-offset-color: #831843 !important;
}

.ring-offset-qipGreen{
  --tw-ring-offset-color: #8bc334 !important;
}

.ring-offset-portalGreen{
  --tw-ring-offset-color: #8ac04a !important;
}

.focus-within\:ring-offset-transparent:focus-within{
  --tw-ring-offset-color: transparent !important;
}

.focus-within\:ring-offset-current:focus-within{
  --tw-ring-offset-color: currentColor !important;
}

.focus-within\:ring-offset-black:focus-within{
  --tw-ring-offset-color: #000 !important;
}

.focus-within\:ring-offset-white:focus-within{
  --tw-ring-offset-color: #fff !important;
}

.focus-within\:ring-offset-gray-50:focus-within{
  --tw-ring-offset-color: #f9fafb !important;
}

.focus-within\:ring-offset-gray-100:focus-within{
  --tw-ring-offset-color: #f3f4f6 !important;
}

.focus-within\:ring-offset-gray-200:focus-within{
  --tw-ring-offset-color: #e5e7eb !important;
}

.focus-within\:ring-offset-gray-300:focus-within{
  --tw-ring-offset-color: #d1d5db !important;
}

.focus-within\:ring-offset-gray-400:focus-within{
  --tw-ring-offset-color: #9ca3af !important;
}

.focus-within\:ring-offset-gray-500:focus-within{
  --tw-ring-offset-color: #6b7280 !important;
}

.focus-within\:ring-offset-gray-600:focus-within{
  --tw-ring-offset-color: #4b5563 !important;
}

.focus-within\:ring-offset-gray-700:focus-within{
  --tw-ring-offset-color: #374151 !important;
}

.focus-within\:ring-offset-gray-800:focus-within{
  --tw-ring-offset-color: #1f2937 !important;
}

.focus-within\:ring-offset-gray-900:focus-within{
  --tw-ring-offset-color: #111827 !important;
}

.focus-within\:ring-offset-red-50:focus-within{
  --tw-ring-offset-color: #fef2f2 !important;
}

.focus-within\:ring-offset-red-100:focus-within{
  --tw-ring-offset-color: #fee2e2 !important;
}

.focus-within\:ring-offset-red-200:focus-within{
  --tw-ring-offset-color: #fecaca !important;
}

.focus-within\:ring-offset-red-300:focus-within{
  --tw-ring-offset-color: #fca5a5 !important;
}

.focus-within\:ring-offset-red-400:focus-within{
  --tw-ring-offset-color: #f87171 !important;
}

.focus-within\:ring-offset-red-500:focus-within{
  --tw-ring-offset-color: #ef4444 !important;
}

.focus-within\:ring-offset-red-600:focus-within{
  --tw-ring-offset-color: #dc2626 !important;
}

.focus-within\:ring-offset-red-700:focus-within{
  --tw-ring-offset-color: #b91c1c !important;
}

.focus-within\:ring-offset-red-800:focus-within{
  --tw-ring-offset-color: #991b1b !important;
}

.focus-within\:ring-offset-red-900:focus-within{
  --tw-ring-offset-color: #7f1d1d !important;
}

.focus-within\:ring-offset-yellow-50:focus-within{
  --tw-ring-offset-color: #fffbeb !important;
}

.focus-within\:ring-offset-yellow-100:focus-within{
  --tw-ring-offset-color: #fef3c7 !important;
}

.focus-within\:ring-offset-yellow-200:focus-within{
  --tw-ring-offset-color: #fde68a !important;
}

.focus-within\:ring-offset-yellow-300:focus-within{
  --tw-ring-offset-color: #fcd34d !important;
}

.focus-within\:ring-offset-yellow-400:focus-within{
  --tw-ring-offset-color: #fbbf24 !important;
}

.focus-within\:ring-offset-yellow-500:focus-within{
  --tw-ring-offset-color: #f59e0b !important;
}

.focus-within\:ring-offset-yellow-600:focus-within{
  --tw-ring-offset-color: #d97706 !important;
}

.focus-within\:ring-offset-yellow-700:focus-within{
  --tw-ring-offset-color: #b45309 !important;
}

.focus-within\:ring-offset-yellow-800:focus-within{
  --tw-ring-offset-color: #92400e !important;
}

.focus-within\:ring-offset-yellow-900:focus-within{
  --tw-ring-offset-color: #78350f !important;
}

.focus-within\:ring-offset-green-50:focus-within{
  --tw-ring-offset-color: #ecfdf5 !important;
}

.focus-within\:ring-offset-green-100:focus-within{
  --tw-ring-offset-color: #d1fae5 !important;
}

.focus-within\:ring-offset-green-200:focus-within{
  --tw-ring-offset-color: #a7f3d0 !important;
}

.focus-within\:ring-offset-green-300:focus-within{
  --tw-ring-offset-color: #6ee7b7 !important;
}

.focus-within\:ring-offset-green-400:focus-within{
  --tw-ring-offset-color: #34d399 !important;
}

.focus-within\:ring-offset-green-500:focus-within{
  --tw-ring-offset-color: #10b981 !important;
}

.focus-within\:ring-offset-green-600:focus-within{
  --tw-ring-offset-color: #059669 !important;
}

.focus-within\:ring-offset-green-700:focus-within{
  --tw-ring-offset-color: #047857 !important;
}

.focus-within\:ring-offset-green-800:focus-within{
  --tw-ring-offset-color: #065f46 !important;
}

.focus-within\:ring-offset-green-900:focus-within{
  --tw-ring-offset-color: #064e3b !important;
}

.focus-within\:ring-offset-blue-50:focus-within{
  --tw-ring-offset-color: #eff6ff !important;
}

.focus-within\:ring-offset-blue-100:focus-within{
  --tw-ring-offset-color: #dbeafe !important;
}

.focus-within\:ring-offset-blue-200:focus-within{
  --tw-ring-offset-color: #bfdbfe !important;
}

.focus-within\:ring-offset-blue-300:focus-within{
  --tw-ring-offset-color: #93c5fd !important;
}

.focus-within\:ring-offset-blue-400:focus-within{
  --tw-ring-offset-color: #60a5fa !important;
}

.focus-within\:ring-offset-blue-500:focus-within{
  --tw-ring-offset-color: #3b82f6 !important;
}

.focus-within\:ring-offset-blue-600:focus-within{
  --tw-ring-offset-color: #2563eb !important;
}

.focus-within\:ring-offset-blue-700:focus-within{
  --tw-ring-offset-color: #1d4ed8 !important;
}

.focus-within\:ring-offset-blue-800:focus-within{
  --tw-ring-offset-color: #1e40af !important;
}

.focus-within\:ring-offset-blue-900:focus-within{
  --tw-ring-offset-color: #1e3a8a !important;
}

.focus-within\:ring-offset-indigo-50:focus-within{
  --tw-ring-offset-color: #eef2ff !important;
}

.focus-within\:ring-offset-indigo-100:focus-within{
  --tw-ring-offset-color: #e0e7ff !important;
}

.focus-within\:ring-offset-indigo-200:focus-within{
  --tw-ring-offset-color: #c7d2fe !important;
}

.focus-within\:ring-offset-indigo-300:focus-within{
  --tw-ring-offset-color: #a5b4fc !important;
}

.focus-within\:ring-offset-indigo-400:focus-within{
  --tw-ring-offset-color: #818cf8 !important;
}

.focus-within\:ring-offset-indigo-500:focus-within{
  --tw-ring-offset-color: #6366f1 !important;
}

.focus-within\:ring-offset-indigo-600:focus-within{
  --tw-ring-offset-color: #4f46e5 !important;
}

.focus-within\:ring-offset-indigo-700:focus-within{
  --tw-ring-offset-color: #4338ca !important;
}

.focus-within\:ring-offset-indigo-800:focus-within{
  --tw-ring-offset-color: #3730a3 !important;
}

.focus-within\:ring-offset-indigo-900:focus-within{
  --tw-ring-offset-color: #312e81 !important;
}

.focus-within\:ring-offset-purple-50:focus-within{
  --tw-ring-offset-color: #f5f3ff !important;
}

.focus-within\:ring-offset-purple-100:focus-within{
  --tw-ring-offset-color: #ede9fe !important;
}

.focus-within\:ring-offset-purple-200:focus-within{
  --tw-ring-offset-color: #ddd6fe !important;
}

.focus-within\:ring-offset-purple-300:focus-within{
  --tw-ring-offset-color: #c4b5fd !important;
}

.focus-within\:ring-offset-purple-400:focus-within{
  --tw-ring-offset-color: #a78bfa !important;
}

.focus-within\:ring-offset-purple-500:focus-within{
  --tw-ring-offset-color: #8b5cf6 !important;
}

.focus-within\:ring-offset-purple-600:focus-within{
  --tw-ring-offset-color: #7c3aed !important;
}

.focus-within\:ring-offset-purple-700:focus-within{
  --tw-ring-offset-color: #6d28d9 !important;
}

.focus-within\:ring-offset-purple-800:focus-within{
  --tw-ring-offset-color: #5b21b6 !important;
}

.focus-within\:ring-offset-purple-900:focus-within{
  --tw-ring-offset-color: #4c1d95 !important;
}

.focus-within\:ring-offset-pink-50:focus-within{
  --tw-ring-offset-color: #fdf2f8 !important;
}

.focus-within\:ring-offset-pink-100:focus-within{
  --tw-ring-offset-color: #fce7f3 !important;
}

.focus-within\:ring-offset-pink-200:focus-within{
  --tw-ring-offset-color: #fbcfe8 !important;
}

.focus-within\:ring-offset-pink-300:focus-within{
  --tw-ring-offset-color: #f9a8d4 !important;
}

.focus-within\:ring-offset-pink-400:focus-within{
  --tw-ring-offset-color: #f472b6 !important;
}

.focus-within\:ring-offset-pink-500:focus-within{
  --tw-ring-offset-color: #ec4899 !important;
}

.focus-within\:ring-offset-pink-600:focus-within{
  --tw-ring-offset-color: #db2777 !important;
}

.focus-within\:ring-offset-pink-700:focus-within{
  --tw-ring-offset-color: #be185d !important;
}

.focus-within\:ring-offset-pink-800:focus-within{
  --tw-ring-offset-color: #9d174d !important;
}

.focus-within\:ring-offset-pink-900:focus-within{
  --tw-ring-offset-color: #831843 !important;
}

.focus-within\:ring-offset-qipGreen:focus-within{
  --tw-ring-offset-color: #8bc334 !important;
}

.focus-within\:ring-offset-portalGreen:focus-within{
  --tw-ring-offset-color: #8ac04a !important;
}

.focus\:ring-offset-transparent:focus{
  --tw-ring-offset-color: transparent !important;
}

.focus\:ring-offset-current:focus{
  --tw-ring-offset-color: currentColor !important;
}

.focus\:ring-offset-black:focus{
  --tw-ring-offset-color: #000 !important;
}

.focus\:ring-offset-white:focus{
  --tw-ring-offset-color: #fff !important;
}

.focus\:ring-offset-gray-50:focus{
  --tw-ring-offset-color: #f9fafb !important;
}

.focus\:ring-offset-gray-100:focus{
  --tw-ring-offset-color: #f3f4f6 !important;
}

.focus\:ring-offset-gray-200:focus{
  --tw-ring-offset-color: #e5e7eb !important;
}

.focus\:ring-offset-gray-300:focus{
  --tw-ring-offset-color: #d1d5db !important;
}

.focus\:ring-offset-gray-400:focus{
  --tw-ring-offset-color: #9ca3af !important;
}

.focus\:ring-offset-gray-500:focus{
  --tw-ring-offset-color: #6b7280 !important;
}

.focus\:ring-offset-gray-600:focus{
  --tw-ring-offset-color: #4b5563 !important;
}

.focus\:ring-offset-gray-700:focus{
  --tw-ring-offset-color: #374151 !important;
}

.focus\:ring-offset-gray-800:focus{
  --tw-ring-offset-color: #1f2937 !important;
}

.focus\:ring-offset-gray-900:focus{
  --tw-ring-offset-color: #111827 !important;
}

.focus\:ring-offset-red-50:focus{
  --tw-ring-offset-color: #fef2f2 !important;
}

.focus\:ring-offset-red-100:focus{
  --tw-ring-offset-color: #fee2e2 !important;
}

.focus\:ring-offset-red-200:focus{
  --tw-ring-offset-color: #fecaca !important;
}

.focus\:ring-offset-red-300:focus{
  --tw-ring-offset-color: #fca5a5 !important;
}

.focus\:ring-offset-red-400:focus{
  --tw-ring-offset-color: #f87171 !important;
}

.focus\:ring-offset-red-500:focus{
  --tw-ring-offset-color: #ef4444 !important;
}

.focus\:ring-offset-red-600:focus{
  --tw-ring-offset-color: #dc2626 !important;
}

.focus\:ring-offset-red-700:focus{
  --tw-ring-offset-color: #b91c1c !important;
}

.focus\:ring-offset-red-800:focus{
  --tw-ring-offset-color: #991b1b !important;
}

.focus\:ring-offset-red-900:focus{
  --tw-ring-offset-color: #7f1d1d !important;
}

.focus\:ring-offset-yellow-50:focus{
  --tw-ring-offset-color: #fffbeb !important;
}

.focus\:ring-offset-yellow-100:focus{
  --tw-ring-offset-color: #fef3c7 !important;
}

.focus\:ring-offset-yellow-200:focus{
  --tw-ring-offset-color: #fde68a !important;
}

.focus\:ring-offset-yellow-300:focus{
  --tw-ring-offset-color: #fcd34d !important;
}

.focus\:ring-offset-yellow-400:focus{
  --tw-ring-offset-color: #fbbf24 !important;
}

.focus\:ring-offset-yellow-500:focus{
  --tw-ring-offset-color: #f59e0b !important;
}

.focus\:ring-offset-yellow-600:focus{
  --tw-ring-offset-color: #d97706 !important;
}

.focus\:ring-offset-yellow-700:focus{
  --tw-ring-offset-color: #b45309 !important;
}

.focus\:ring-offset-yellow-800:focus{
  --tw-ring-offset-color: #92400e !important;
}

.focus\:ring-offset-yellow-900:focus{
  --tw-ring-offset-color: #78350f !important;
}

.focus\:ring-offset-green-50:focus{
  --tw-ring-offset-color: #ecfdf5 !important;
}

.focus\:ring-offset-green-100:focus{
  --tw-ring-offset-color: #d1fae5 !important;
}

.focus\:ring-offset-green-200:focus{
  --tw-ring-offset-color: #a7f3d0 !important;
}

.focus\:ring-offset-green-300:focus{
  --tw-ring-offset-color: #6ee7b7 !important;
}

.focus\:ring-offset-green-400:focus{
  --tw-ring-offset-color: #34d399 !important;
}

.focus\:ring-offset-green-500:focus{
  --tw-ring-offset-color: #10b981 !important;
}

.focus\:ring-offset-green-600:focus{
  --tw-ring-offset-color: #059669 !important;
}

.focus\:ring-offset-green-700:focus{
  --tw-ring-offset-color: #047857 !important;
}

.focus\:ring-offset-green-800:focus{
  --tw-ring-offset-color: #065f46 !important;
}

.focus\:ring-offset-green-900:focus{
  --tw-ring-offset-color: #064e3b !important;
}

.focus\:ring-offset-blue-50:focus{
  --tw-ring-offset-color: #eff6ff !important;
}

.focus\:ring-offset-blue-100:focus{
  --tw-ring-offset-color: #dbeafe !important;
}

.focus\:ring-offset-blue-200:focus{
  --tw-ring-offset-color: #bfdbfe !important;
}

.focus\:ring-offset-blue-300:focus{
  --tw-ring-offset-color: #93c5fd !important;
}

.focus\:ring-offset-blue-400:focus{
  --tw-ring-offset-color: #60a5fa !important;
}

.focus\:ring-offset-blue-500:focus{
  --tw-ring-offset-color: #3b82f6 !important;
}

.focus\:ring-offset-blue-600:focus{
  --tw-ring-offset-color: #2563eb !important;
}

.focus\:ring-offset-blue-700:focus{
  --tw-ring-offset-color: #1d4ed8 !important;
}

.focus\:ring-offset-blue-800:focus{
  --tw-ring-offset-color: #1e40af !important;
}

.focus\:ring-offset-blue-900:focus{
  --tw-ring-offset-color: #1e3a8a !important;
}

.focus\:ring-offset-indigo-50:focus{
  --tw-ring-offset-color: #eef2ff !important;
}

.focus\:ring-offset-indigo-100:focus{
  --tw-ring-offset-color: #e0e7ff !important;
}

.focus\:ring-offset-indigo-200:focus{
  --tw-ring-offset-color: #c7d2fe !important;
}

.focus\:ring-offset-indigo-300:focus{
  --tw-ring-offset-color: #a5b4fc !important;
}

.focus\:ring-offset-indigo-400:focus{
  --tw-ring-offset-color: #818cf8 !important;
}

.focus\:ring-offset-indigo-500:focus{
  --tw-ring-offset-color: #6366f1 !important;
}

.focus\:ring-offset-indigo-600:focus{
  --tw-ring-offset-color: #4f46e5 !important;
}

.focus\:ring-offset-indigo-700:focus{
  --tw-ring-offset-color: #4338ca !important;
}

.focus\:ring-offset-indigo-800:focus{
  --tw-ring-offset-color: #3730a3 !important;
}

.focus\:ring-offset-indigo-900:focus{
  --tw-ring-offset-color: #312e81 !important;
}

.focus\:ring-offset-purple-50:focus{
  --tw-ring-offset-color: #f5f3ff !important;
}

.focus\:ring-offset-purple-100:focus{
  --tw-ring-offset-color: #ede9fe !important;
}

.focus\:ring-offset-purple-200:focus{
  --tw-ring-offset-color: #ddd6fe !important;
}

.focus\:ring-offset-purple-300:focus{
  --tw-ring-offset-color: #c4b5fd !important;
}

.focus\:ring-offset-purple-400:focus{
  --tw-ring-offset-color: #a78bfa !important;
}

.focus\:ring-offset-purple-500:focus{
  --tw-ring-offset-color: #8b5cf6 !important;
}

.focus\:ring-offset-purple-600:focus{
  --tw-ring-offset-color: #7c3aed !important;
}

.focus\:ring-offset-purple-700:focus{
  --tw-ring-offset-color: #6d28d9 !important;
}

.focus\:ring-offset-purple-800:focus{
  --tw-ring-offset-color: #5b21b6 !important;
}

.focus\:ring-offset-purple-900:focus{
  --tw-ring-offset-color: #4c1d95 !important;
}

.focus\:ring-offset-pink-50:focus{
  --tw-ring-offset-color: #fdf2f8 !important;
}

.focus\:ring-offset-pink-100:focus{
  --tw-ring-offset-color: #fce7f3 !important;
}

.focus\:ring-offset-pink-200:focus{
  --tw-ring-offset-color: #fbcfe8 !important;
}

.focus\:ring-offset-pink-300:focus{
  --tw-ring-offset-color: #f9a8d4 !important;
}

.focus\:ring-offset-pink-400:focus{
  --tw-ring-offset-color: #f472b6 !important;
}

.focus\:ring-offset-pink-500:focus{
  --tw-ring-offset-color: #ec4899 !important;
}

.focus\:ring-offset-pink-600:focus{
  --tw-ring-offset-color: #db2777 !important;
}

.focus\:ring-offset-pink-700:focus{
  --tw-ring-offset-color: #be185d !important;
}

.focus\:ring-offset-pink-800:focus{
  --tw-ring-offset-color: #9d174d !important;
}

.focus\:ring-offset-pink-900:focus{
  --tw-ring-offset-color: #831843 !important;
}

.focus\:ring-offset-qipGreen:focus{
  --tw-ring-offset-color: #8bc334 !important;
}

.focus\:ring-offset-portalGreen:focus{
  --tw-ring-offset-color: #8ac04a !important;
}

.filter{
  --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter-none{
  -webkit-filter: none !important;
          filter: none !important;
}

.blur-0{
  --tw-blur: blur(0) !important;
}

.blur-none{
  --tw-blur: blur(0) !important;
}

.blur-sm{
  --tw-blur: blur(4px) !important;
}

.blur{
  --tw-blur: blur(8px) !important;
}

.blur-md{
  --tw-blur: blur(12px) !important;
}

.blur-lg{
  --tw-blur: blur(16px) !important;
}

.blur-xl{
  --tw-blur: blur(24px) !important;
}

.blur-2xl{
  --tw-blur: blur(40px) !important;
}

.blur-3xl{
  --tw-blur: blur(64px) !important;
}

.brightness-0{
  --tw-brightness: brightness(0) !important;
}

.brightness-50{
  --tw-brightness: brightness(.5) !important;
}

.brightness-75{
  --tw-brightness: brightness(.75) !important;
}

.brightness-90{
  --tw-brightness: brightness(.9) !important;
}

.brightness-95{
  --tw-brightness: brightness(.95) !important;
}

.brightness-100{
  --tw-brightness: brightness(1) !important;
}

.brightness-105{
  --tw-brightness: brightness(1.05) !important;
}

.brightness-110{
  --tw-brightness: brightness(1.1) !important;
}

.brightness-125{
  --tw-brightness: brightness(1.25) !important;
}

.brightness-150{
  --tw-brightness: brightness(1.5) !important;
}

.brightness-200{
  --tw-brightness: brightness(2) !important;
}

.contrast-0{
  --tw-contrast: contrast(0) !important;
}

.contrast-50{
  --tw-contrast: contrast(.5) !important;
}

.contrast-75{
  --tw-contrast: contrast(.75) !important;
}

.contrast-100{
  --tw-contrast: contrast(1) !important;
}

.contrast-125{
  --tw-contrast: contrast(1.25) !important;
}

.contrast-150{
  --tw-contrast: contrast(1.5) !important;
}

.contrast-200{
  --tw-contrast: contrast(2) !important;
}

.drop-shadow-sm{
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
}

.drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
}

.drop-shadow-md{
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
}

.drop-shadow-lg{
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
}

.drop-shadow-xl{
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
}

.drop-shadow-2xl{
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
}

.drop-shadow-none{
  --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
}

.grayscale-0{
  --tw-grayscale: grayscale(0) !important;
}

.grayscale{
  --tw-grayscale: grayscale(100%) !important;
}

.hue-rotate-0{
  --tw-hue-rotate: hue-rotate(0deg) !important;
}

.hue-rotate-15{
  --tw-hue-rotate: hue-rotate(15deg) !important;
}

.hue-rotate-30{
  --tw-hue-rotate: hue-rotate(30deg) !important;
}

.hue-rotate-60{
  --tw-hue-rotate: hue-rotate(60deg) !important;
}

.hue-rotate-90{
  --tw-hue-rotate: hue-rotate(90deg) !important;
}

.hue-rotate-180{
  --tw-hue-rotate: hue-rotate(180deg) !important;
}

.-hue-rotate-180{
  --tw-hue-rotate: hue-rotate(-180deg) !important;
}

.-hue-rotate-90{
  --tw-hue-rotate: hue-rotate(-90deg) !important;
}

.-hue-rotate-60{
  --tw-hue-rotate: hue-rotate(-60deg) !important;
}

.-hue-rotate-30{
  --tw-hue-rotate: hue-rotate(-30deg) !important;
}

.-hue-rotate-15{
  --tw-hue-rotate: hue-rotate(-15deg) !important;
}

.invert-0{
  --tw-invert: invert(0) !important;
}

.invert{
  --tw-invert: invert(100%) !important;
}

.saturate-0{
  --tw-saturate: saturate(0) !important;
}

.saturate-50{
  --tw-saturate: saturate(.5) !important;
}

.saturate-100{
  --tw-saturate: saturate(1) !important;
}

.saturate-150{
  --tw-saturate: saturate(1.5) !important;
}

.saturate-200{
  --tw-saturate: saturate(2) !important;
}

.sepia-0{
  --tw-sepia: sepia(0) !important;
}

.sepia{
  --tw-sepia: sepia(100%) !important;
}

.backdrop-filter{
  --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
  --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.backdrop-filter-none{
  -webkit-backdrop-filter: none !important;
          backdrop-filter: none !important;
}

.backdrop-blur-0{
  --tw-backdrop-blur: blur(0) !important;
}

.backdrop-blur-none{
  --tw-backdrop-blur: blur(0) !important;
}

.backdrop-blur-sm{
  --tw-backdrop-blur: blur(4px) !important;
}

.backdrop-blur{
  --tw-backdrop-blur: blur(8px) !important;
}

.backdrop-blur-md{
  --tw-backdrop-blur: blur(12px) !important;
}

.backdrop-blur-lg{
  --tw-backdrop-blur: blur(16px) !important;
}

.backdrop-blur-xl{
  --tw-backdrop-blur: blur(24px) !important;
}

.backdrop-blur-2xl{
  --tw-backdrop-blur: blur(40px) !important;
}

.backdrop-blur-3xl{
  --tw-backdrop-blur: blur(64px) !important;
}

.backdrop-brightness-0{
  --tw-backdrop-brightness: brightness(0) !important;
}

.backdrop-brightness-50{
  --tw-backdrop-brightness: brightness(.5) !important;
}

.backdrop-brightness-75{
  --tw-backdrop-brightness: brightness(.75) !important;
}

.backdrop-brightness-90{
  --tw-backdrop-brightness: brightness(.9) !important;
}

.backdrop-brightness-95{
  --tw-backdrop-brightness: brightness(.95) !important;
}

.backdrop-brightness-100{
  --tw-backdrop-brightness: brightness(1) !important;
}

.backdrop-brightness-105{
  --tw-backdrop-brightness: brightness(1.05) !important;
}

.backdrop-brightness-110{
  --tw-backdrop-brightness: brightness(1.1) !important;
}

.backdrop-brightness-125{
  --tw-backdrop-brightness: brightness(1.25) !important;
}

.backdrop-brightness-150{
  --tw-backdrop-brightness: brightness(1.5) !important;
}

.backdrop-brightness-200{
  --tw-backdrop-brightness: brightness(2) !important;
}

.backdrop-contrast-0{
  --tw-backdrop-contrast: contrast(0) !important;
}

.backdrop-contrast-50{
  --tw-backdrop-contrast: contrast(.5) !important;
}

.backdrop-contrast-75{
  --tw-backdrop-contrast: contrast(.75) !important;
}

.backdrop-contrast-100{
  --tw-backdrop-contrast: contrast(1) !important;
}

.backdrop-contrast-125{
  --tw-backdrop-contrast: contrast(1.25) !important;
}

.backdrop-contrast-150{
  --tw-backdrop-contrast: contrast(1.5) !important;
}

.backdrop-contrast-200{
  --tw-backdrop-contrast: contrast(2) !important;
}

.backdrop-grayscale-0{
  --tw-backdrop-grayscale: grayscale(0) !important;
}

.backdrop-grayscale{
  --tw-backdrop-grayscale: grayscale(100%) !important;
}

.backdrop-hue-rotate-0{
  --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
}

.backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
}

.backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
}

.backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
}

.backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
}

.backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
}

.-backdrop-hue-rotate-180{
  --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
}

.-backdrop-hue-rotate-90{
  --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
}

.-backdrop-hue-rotate-60{
  --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
}

.-backdrop-hue-rotate-30{
  --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
}

.-backdrop-hue-rotate-15{
  --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
}

.backdrop-invert-0{
  --tw-backdrop-invert: invert(0) !important;
}

.backdrop-invert{
  --tw-backdrop-invert: invert(100%) !important;
}

.backdrop-opacity-0{
  --tw-backdrop-opacity: opacity(0) !important;
}

.backdrop-opacity-5{
  --tw-backdrop-opacity: opacity(0.05) !important;
}

.backdrop-opacity-10{
  --tw-backdrop-opacity: opacity(0.1) !important;
}

.backdrop-opacity-20{
  --tw-backdrop-opacity: opacity(0.2) !important;
}

.backdrop-opacity-25{
  --tw-backdrop-opacity: opacity(0.25) !important;
}

.backdrop-opacity-30{
  --tw-backdrop-opacity: opacity(0.3) !important;
}

.backdrop-opacity-40{
  --tw-backdrop-opacity: opacity(0.4) !important;
}

.backdrop-opacity-50{
  --tw-backdrop-opacity: opacity(0.5) !important;
}

.backdrop-opacity-60{
  --tw-backdrop-opacity: opacity(0.6) !important;
}

.backdrop-opacity-70{
  --tw-backdrop-opacity: opacity(0.7) !important;
}

.backdrop-opacity-75{
  --tw-backdrop-opacity: opacity(0.75) !important;
}

.backdrop-opacity-80{
  --tw-backdrop-opacity: opacity(0.8) !important;
}

.backdrop-opacity-90{
  --tw-backdrop-opacity: opacity(0.9) !important;
}

.backdrop-opacity-95{
  --tw-backdrop-opacity: opacity(0.95) !important;
}

.backdrop-opacity-100{
  --tw-backdrop-opacity: opacity(1) !important;
}

.backdrop-saturate-0{
  --tw-backdrop-saturate: saturate(0) !important;
}

.backdrop-saturate-50{
  --tw-backdrop-saturate: saturate(.5) !important;
}

.backdrop-saturate-100{
  --tw-backdrop-saturate: saturate(1) !important;
}

.backdrop-saturate-150{
  --tw-backdrop-saturate: saturate(1.5) !important;
}

.backdrop-saturate-200{
  --tw-backdrop-saturate: saturate(2) !important;
}

.backdrop-sepia-0{
  --tw-backdrop-sepia: sepia(0) !important;
}

.backdrop-sepia{
  --tw-backdrop-sepia: sepia(100%) !important;
}

.transition-none{
  transition-property: none !important;
}

.transition-all{
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.transition-colors{
  transition-property: background-color, border-color, color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.transition-opacity{
  transition-property: opacity !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.transition-shadow{
  transition-property: box-shadow !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.transition-transform{
  transition-property: transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.delay-75{
  transition-delay: 75ms !important;
}

.delay-100{
  transition-delay: 100ms !important;
}

.delay-150{
  transition-delay: 150ms !important;
}

.delay-200{
  transition-delay: 200ms !important;
}

.delay-300{
  transition-delay: 300ms !important;
}

.delay-500{
  transition-delay: 500ms !important;
}

.delay-700{
  transition-delay: 700ms !important;
}

.delay-1000{
  transition-delay: 1000ms !important;
}

.duration-75{
  transition-duration: 75ms !important;
}

.duration-100{
  transition-duration: 100ms !important;
}

.duration-150{
  transition-duration: 150ms !important;
}

.duration-200{
  transition-duration: 200ms !important;
}

.duration-300{
  transition-duration: 300ms !important;
}

.duration-500{
  transition-duration: 500ms !important;
}

.duration-700{
  transition-duration: 700ms !important;
}

.duration-1000{
  transition-duration: 1000ms !important;
}

.ease-linear{
  transition-timing-function: linear !important;
}

.ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.content-none{
  content: none !important;
}

body {
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-weight: 400 !important;
  padding: 0;
  margin: 0;
  background-color: #e5e5e5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

@font-face {
  font-family: "Rubik";

  src: url(https://aj-devapp.holisticportal.com.au/static/media/Rubik-VariableFont_wght.ea640765.ttf);

  font-weight: 100 900;
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.mr-2 {
  margin-right: 0.5rem;
}

.main-content {
  max-width: 1440px;
  margin: 40px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  min-height: 426px;
}

.searchBar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  padding: 42px 40px;
  z-index: 9;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.2s ease;
}

.searchBar.on {
  transform: translateY(0);
  opacity: 1;
}

.searchBar .search {
  padding: 2px 4px 2px 2px;
  width: 600px;
  /* margin: auto; */
  border: 1px solid #dedddd;
  box-sizing: border-box;
  border-radius: 3px;
}

.searchBar input {
  font-size: 20px;
  line-height: 32px;
  color: #222222;
  padding: 10px 10px 10px 25px;
  border: unset;
  outline: none;
  width: 100%;
  background-color: unset;
}

.searchBar input::-webkit-input-placeholder {
  color: #b0b0b0;
}

.searchBar input:-ms-input-placeholder {
  color: #b0b0b0;
}

.searchBar input::placeholder {
  color: #b0b0b0;
}

.searchBar button {
  font-size: 18px;
  height: 48px;
  width: 48px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.closeSearch {
  width: 48px;
  height: 48px;
  font-size: 18px;
  color: #222222;
  background-color: rgb(34 34 34 / 5%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.closeSearch:hover {
  color: #ffffff;
  background-color: #8ac04a;
}

.headerRow,
.portalFooter .footerRow {
  max-width: 1440px;
  margin: auto;
}

.bottomHeader .headerRow {
  padding: 10px 10px 10px 0px;
}

.headerRow {
  padding: 10px;
  box-sizing: border-box;
}

.header .topHeader {
  background-color: #222222;
  position: relative;
}

/* .header .topHeader .headerRow { */

/* padding: 8px 0;	 */

/* } */

.topHeader .topHeaderLeft .topHeaderLogo {
  margin-right: 20px;
}

.topHeader .topHeaderLeft .topHeaderLogo a {
  display: flex;
}

.topHeader .topHeaderLeft .topHeaderLogo img {
  width: 160px;
}

.topHeader .topHeaderLeft .topHeaderMenu li:not(:last-child) {
  margin-right: 10px;
}

.topHeader .topHeaderLeft .topHeaderMenu li a {
  font-size: 14px;
  line-height: 28px;
  color: rgb(255 255 255 / 60%);
}

.topHeader .topHeaderLeft .topHeaderMenu li a:hover {
  color: #ffffff;
}

.topHeader .topHeaderRight {
  display: flex;
  align-items: center;
}

.topHeader .topHeaderRight .LogoutBtn {
  margin-left: 10px;
}

.topHeader .topHeaderRight .LogoutBtn button {
  font-size: 16px;
  color: rgb(255 255 255 / 60%);
  background-color: rgb(255 255 255 / 20%);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topHeader .topHeaderRight .LogoutBtn button:hover {
  color: #ffffff;
}

.topHeader .topHeaderRight .services .selectedService {
  color: #ffffff;
}

.topHeader .topHeaderRight .services .changeService {
  color: rgb(255 255 255 / 60%);
  cursor: pointer;
}

.topHeader .topHeaderRight .services .changeService:hover {
  color: #ffffff;
}

.topHeader .topHeaderRight .services {
  font-size: 13px;
  background: rgb(255 255 255 / 20%);
  border-radius: 3px;
  padding: 0 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topHeader .topHeaderRight .services .selectedService {
  margin-right: 8px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.topHeader .topHeaderRight .services > span:last-child svg {
  margin-left: 5px;
}

/*
.topHeader .topHeaderMenu > ul > li:not(:last-child):not(:nth-last-child(2)) {
	padding: 17px 15px;
	position: relative;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)):hover {
	background-color: #222222;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) * {
	font-size: 16px;
	color: rgb(255 255 255 / 60%);
}

.topHeader .topHeaderMenu ul li .messages {
	font-size: 13px !important;
    color: #ffffff !important;
    background-color: #FF5F5F;
    border-radius: 2px;
    padding: 2px 6px;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) svg {
	font-size: 13px;
}

.topHeader .topHeaderMenu ul li:hover > .sub-menu {
    clip: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.topHeader .topHeaderMenu ul li .sub-menu {
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0.1;
    transition: transform .5s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
    transform: translateY(-10px) scaleY(0.5);
    transform-origin: top;
    list-style: none;
    margin: 0;
    padding: 10px 15px;
	width: 130px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #222222;
    z-index: 99;
}

.topHeader .topHeaderMenu ul li .sub-menu li a {
    padding: 10px;
    display: block;
}

.topHeader .userIcon {
	background-color: #8BC334;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 0.625rem;
}

.topHeader .userIcon svg {
	color: #ffffff;
}

.topHeader .topHeaderMenu > ul > li:nth-last-child(2) {
	padding: 5px 25px 5px 5px;
    border-radius: 22px;
}
*/

/* .topHeader .topHeaderMenu > ul > li:last-child, */

/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2), */

/* .topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *, */

/* .topHeader .services * { */

/* font-size: 13px; */

/* } */

/* .topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *:first-child, */

/* .topHeader .topHeader .topHeaderMenu ul li:last-child svg, */

/* .services *:first-child { */

/* color: #ffffff; */

/* line-height: 1.5;  */

/* } */

/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2) .userInfo *:last-child, */

/* .topHeader .topHeaderMenu > ul > li:last-child, */

/* .topHeader .services *:last-child { */

/* color: rgb(255 255 255 / 60%); */

/* cursor: pointer; */

/* line-height: 1; */

/* } */

/*
.topHeader .topHeaderMenu > ul > li:last-child {
    width: 42px;
    height: 42px;
	border-radius: 50%;
	margin-left: 5px;
}

.topHeader .topHeaderMenu ul li:last-child svg {
	color: #ffffff;
	font-size: 17px;
}
*/

.header .bottomHeader {
  background-color: #ffffff;
  position: relative;
}

/* .header .bottomHeader .headerRow { */

/* padding: 10px 0; */

/* } */

/* .bottomHeader .logo { */

/* padding-left: 5px; */

/* } */

.mobileMenu {
  display: flex;
  justify-content: flex-end;
}

.mobileMenu .menuBars {
  width: 60px;
  height: 60px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.mobileMenu .menuBars .bars {
  position: relative;
  width: 30px;
  height: 22px;
  border-top: 3px solid;
  border-bottom: 3px solid;
  transition: all 0.2s ease;
  margin-right: 0 !important;
}

.mobileMenu .menuBars .bars:after,
.mobileMenu .menuBars .bars:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #222222;
  transition: all 0.2s ease;
}

.mobileMenu .menuBars.on .bars:before {
  transform: rotate(45deg);
}

.mobileMenu .menuBars.on .bars:after {
  transform: rotate(-45deg);
}

.mobileMenu .menuBars.on .bars {
  border-color: transparent;
}

.bottomHeader .logo img {
  width: 100%;
  max-width: 245px;
}

.bottomHeader .logo a {
  display: flex;
}

.bottomHeader .headerMenu {
  display: flex;
  align-items: center;
}

.bottomHeader .headerMenu .searchBtn {
  margin-left: 10px;
  display: none !important;
}

.bottomHeader .headerMenu .searchBtn button {
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background: rgb(34 34 34 / 6%);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.bottomHeader .headerMenu .searchBtn button:hover {
  color: #ffffff;
  background-color: #8ac04a;
}

.bottomHeader .headerMenu .mainHeaderMenu {
  display: none;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem {
  position: relative;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem:not(:last-child) {
  margin-right: 5px;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
  font-size: 17px;
  text-transform: uppercase;
  color: #222222;
  padding: 10px 15px;
  display: block;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown:hover
  > .subMenu {
  display: block;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .subMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  display: none;
  z-index: 9;
  padding-top: 25px;
  margin-top: 35px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu
  .subMenuItem {
  background-color: #8ac04a;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu
  .subMenuItem
  a {
  color: #ffffff !important;
  line-height: 32px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu
  .subMenuItem.active,
.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu
  .subMenuItem:hover {
  background: #92c457;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem.active .menuItemText,
.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border-radius: 3px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown.bg-portalGreen
  .menuItemText,
.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown:hover
  > .menuItemText {
  color: #8ac04a !important;
  background-color: #e8f2db;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu {
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  z-index: 9;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.5s cubic-bezier(0.2, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu.slideDown {
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li {
  border-top: 1px solid #ebebeb;
  /* padding: 15px 0; */
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu .subMenu {
  display: none;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid #c35591;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu
  .subMenuItem
  .subMenuItemText {
  color: #ffffff;
  background: #8ac04a;
  width: 100%;
  display: block;
  padding: 25px 0;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu
  .subMenuItem
  .subMenuItemText:hover {
  background: #92c457;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu li > a {
  font-size: 20px;
  text-transform: uppercase;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > a {
  display: inline-block;
  padding: 10px 15px;
  margin: 15px 0;
  color: #222222;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > ul li:not(:last-child) {
  border-bottom: 1px solid rgb(138 192 74 / 60%);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > ul li a {
  color: #ffffff;
  background: #8ac04a;
  width: 100%;
  display: block;
  padding: 25px 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.active a,
.bottomHeader .mobileMenu .mainHeaderMobileMenu > li a:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border-radius: 3px;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.hasDropdown.active a,
.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.hasDropdown a:hover {
  color: #8ac04a;
  background-color: #f3f9ed;
}

.portalFooter h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 30px;
}

.portalFooter .footerTop {
  background-color: #222222;
}

.portalFooter .footerTop .footerRow {
  padding: 35px 50px 45px 50px;
  box-sizing: border-box;
}

.products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.products .product {
  background: rgb(255 255 255 / 10%);
  border-radius: 3px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.products .product svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255 255 255 / 40%);
  font-size: 14px;
}

.products .product span {
  font-size: 16px;
  line-height: 28px;
  color: rgb(255 255 255 / 40%);
}

.products .product a {
  /* font-size: 16px; */
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 5px;
}

.products .product.commingSoon a {
  background-color: #ff8888;
  cursor: not-allowed;
}

.products .product.visit a {
  background-color: #8ac04a;
}

.products .product.subscribed a {
  background-color: #8ac04a;
}

.products .product:not(.commingSoon):hover,
.products .product.subscribed.active {
  background-color: #8ac04a;
}

.products .product:not(.commingSoon):hover svg,
.products .product:not(.commingSoon):hover span,
.products .product.subscribed.active svg,
.products .product.subscribed.active span {
  color: #ffffff;
}

.products .product:not(.commingSoon):hover a,
.products .product.subscribed.active a {
  color: #8ac04a;
  background-color: #ffffff;
}

.portalFooter .footerBottom {
  background-color: #8ac04a;
}

.footerBottom .footerMenu ul li:not(:last-child) {
  margin-right: 15px;
  border-right: 1px solid rgb(255 255 255 / 60%);
  padding-right: 15px;
}

.footerBottom .footerCopyright span,
.footerBottom .footerMenu li .footerMenuText {
  font-size: 16px;
  line-height: 19px;
  color: rgb(255 255 255 / 60%);
}

.footerBottom .footerMenu li .footerMenuText:hover {
  color: #ffffff;
}

.contentHeader {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  /* margin-bottom: 16px; */
  /* width: 100%; */
}

.backBtn a {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgb(34 34 34 / 20%);
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
}

.backBtn a:hover {
  color: #222222;
}

.backBtn a > * {
  margin-right: 8px;
}

.topHeaderContainer.links-page {
  margin-bottom: 10px;
}

.topHeaderContainer {
  margin-bottom: 30px;
  padding-left: 30px;
}

.topHeaderContainer .search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  /* width: 330px; */
}

.topHeaderContainer .search input {
  font-size: 16px;
  line-height: 10px;
  color: #b0b0b0;
  padding: 10px 15px;
  border: unset;
  outline: none;
  width: 190px;
}

.topHeaderContainer .search button {
  height: 33px;
  width: 33px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.topHeaderContainer .search button:hover {
  opacity: 0.8;
}

.tabHeader {
  background-color: #ffffff;
}

.tabHeader .menu {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.tabHeader .menu li:not(:last-child) {
  border-right: 4px solid #e5e5e5;
}

.tabHeader .menu li {
  border-bottom: 4px solid #e5e5e5;
}

.tabHeader .menu li:hover,
.tabHeader .menu li.active {
  font-weight: 400;
  color: #222222;
}

.tabHeader .menu li.active {
  border-bottom: 4px solid #ffffff;
}

.tabHeader ul li:nth-child(1):hover::before,
.tabHeader ul li:nth-child(1).active::before {
  background-color: #61a447;
}

.tabHeader ul li:nth-child(2):hover::before,
.tabHeader ul li:nth-child(2).active::before {
  background-color: #fbd539;
}

.tabHeader ul li:nth-child(3):hover::before,
.tabHeader ul li:nth-child(3).active::before {
  background-color: #e395a5;
}

.tabHeader ul li:nth-child(4):hover::before,
.tabHeader ul li:nth-child(4).active::before {
  background-color: #accf6b;
}

.tabHeader ul li:nth-child(5):hover::before,
.tabHeader ul li:nth-child(5).active::before {
  background-color: #f3a23e;
}

.tabHeader ul li:nth-child(6):hover::before,
.tabHeader ul li:nth-child(6).active::before {
  background-color: #db308f;
}

.tabHeader ul li:nth-child(7):hover::before,
.tabHeader ul li:nth-child(7).active::before {
  background-color: #3a96cf;
}

.tabHeader li:hover::before,
.tabHeader li.active::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  transform: translate(-370%, -50%);
}

.tabHeader .menu .tabMenuItem {
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  padding: 10px 0;
  text-align: center;
  display: block;
  color: rgb(34 34 34 / 40%);
  position: relative;
  cursor: pointer;
}

.documentsList {
  background-color: #ffffff;
  padding: 30px;
  min-height: 400px;
}

.documentsListFilter {
  position: relative;
}

.documentsListFilter > * {
  width: 100%;
}

.documentsListFilter h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.documentsListFilter .btnLetter {
  font-size: 12px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  order: 3;
  height: 30px;
  width: 130px;
  padding: 0 12px;
}

.documentsListFilter .categoriesNav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 20px;
  order: 1;
  width: auto;
}

.documentsListFilter .categoriesNav li {
  width: 110px;
}

.documentsListFilter .categoriesNav li a:hover,
.documentsListFilter .categoriesNav li.active a {
  color: #ffffff;
  position: relative;
}

.documentsListFilter.filterNQA1 .categoriesNav li a:hover,
.documentsListFilter.filterNQA1 .categoriesNav li.active a {
  background: #61a447;
}

.documentsListFilter.filterNQA2 .categoriesNav li a:hover,
.documentsListFilter.filterNQA2 .categoriesNav li.active a {
  background: #fbd539;
}

.documentsListFilter.filterNQA3 .categoriesNav li a:hover,
.documentsListFilter.filterNQA3 .categoriesNav li.active a {
  background: #e395a5;
}

.documentsListFilter.filterNQA4 .categoriesNav li a:hover,
.documentsListFilter.filterNQA4 .categoriesNav li.active a {
  background: #accf6b;
}

.documentsListFilter.filterNQA5 .categoriesNav li a:hover,
.documentsListFilter.filterNQA5 .categoriesNav li.active a {
  background: #f3a23e;
}

.documentsListFilter.filterNQA6 .categoriesNav li a:hover,
.documentsListFilter.filterNQA6 .categoriesNav li.active a {
  background: #db308f;
}

.documentsListFilter.filterNQA7 .categoriesNav li a:hover,
.documentsListFilter.filterNQA7 .categoriesNav li.active a {
  background: #3a96cf;
}

.documentsListFilter .categoriesNav li.active a::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: auto;
}

.documentsListFilter.filterNQA1 .categoriesNav li.active a::after {
  border-top: 10px solid #61a447;
}

.documentsListFilter.filterNQA2 .categoriesNav li.active a::after {
  border-top: 10px solid #fbd539;
}

.documentsListFilter.filterNQA3 .categoriesNav li.active a::after {
  border-top: 10px solid #e395a5;
}

.documentsListFilter.filterNQA4 .categoriesNav li.active a::after {
  border-top: 10px solid #accf6b;
}

.documentsListFilter.filterNQA5 .categoriesNav li.active a::after {
  border-top: 10px solid #f3a23e;
}

.documentsListFilter.filterNQA6 .categoriesNav li.active a::after {
  border-top: 10px solid #db308f;
}

.documentsListFilter.filterNQA7 .categoriesNav li.active a::after {
  border-top: 10px solid #3a96cf;
}

.documentsListFilter .categoriesNav li a {
  font-size: 12px;
  line-height: 28px;
  color: #b0b0b0;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  display: block;
  text-align: center;
}

.documentsListFilter .letterNav {
  display: none !important;
}

.documentsListFilter .letterNav li {
  width: 3.704%;
}

.documentsListFilter .letterNav li:not(:last-child) a {
  border-right: 1px solid #ebebeb;
}

.documentsListFilter .letterNav li a:hover,
.documentsListFilter .letterNav li.active a {
  color: #ffffff;
  background-color: #61a447;
}

.documentsListFilter .letterNav li a {
  font-size: 16px;
  transition: all 250ms ease-in-out;
  padding: 5px 0;
  color: #b0b0b0;
  background: #f9f9f9;
  box-sizing: border-box;
}

.documentsListFilter .letterNavMobile {
  position: absolute;
  bottom: -107px;
  width: 100%;
  max-width: 940px;
  background-color: #ffffff;
  box-shadow: 0 0px 10px 0 rgb(0 0 0 / 20%), 0 0px 15px 0 rgb(0 0 0 / 19%);
  display: none;
}

.documentsListFilter .letterNavMobile .letterNavright {
  width: 840px;
  border-left: 1px solid #ebebeb;
}

.letterNavMobile .letterNavright .letterNav {
  display: flex;
  width: 100%;
  height: 50px;
}

.letterNavMobile .letterNavright .letterNav li {
  width: 7.69%;
}

.letterNavMobile .letterNavright .letterNav a {
  background-color: unset !important;
  border-right: unset !important;
}

.letterNavMobile .letterNavright .letterNav a.active {
  color: #ffffff;
  background-color: #61a447 !important;
  border-radius: 50px;
  height: 30px;
  width: 30px;
}

.documentsListFilter .letterNavright > ul:last-child {
  border-top: 1px solid #ebebeb;
}

.documentsListFilter .letterNavMobile .letterNavLeft {
  width: 100px;
  padding: 20px;
  box-sizing: border-box;
}

.documentsListFilter .letterNavMobile .letterNavLeft a.active {
  height: 60px;
  width: 60px;
  background-color: #61a447;
  border-radius: 50%;
  color: #ffffff;
}

.documentsListContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 9px 0 0 0;
}

.documentFiles {
  margin-top: 25px;
  border-bottom: 1px solid #e7e7e7;
}

.documentFiles .file {
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
}

.documentFiles .file .fileContainer {
  padding-right: 30px;
}

.documentFiles .file .filelogo {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #ffffff;
}

.documentFiles .file .filelogo.wordFile {
  background-color: #61a447;
}

.documentFiles .file .filelogo.pdfFile {
  background-color: #fbd539;
}

.documentFiles .file .filelogo.imageFile {
  background-color: #db308f;
}

.documentFiles .file .filelogo.videoFile {
  background-color: #accf6b;
}

.documentFiles .file .filelogo.excelFile {
  background-color: #3a96cf;
}

.documentFiles .file .filelogo.powerPointfile {
  background-color: #e395a5;
}

.documentFiles .file .filelogo.archiveFile {
  background-color: #f3a23e;
}

.documentFiles .file .filelogo.audioFile {
  background-color: #accf6b;
}

.documentFiles .file .filelogo.randomFile {
  background-color: #db308f;
}

.documentFiles .file .filelogo.urlFile {
  background-color: #388cef;
}

.documentFiles .file .fileContent {
  margin-left: 15px;
}

.documentFiles .file .fileContent .filename {
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.documentFiles .file .fileContent .fileinfo span {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
}

.documentFiles .file .btnContainer a {
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
}

.documentFiles .file .btnContainer a:hover {
  opacity: 0.8;
}

.documentFiles .file .btnContainer a svg {
  margin-right: 8px;
}

.documentFiles .file .btnContainer .blue {
  color: #388cef;
  background-color: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  padding: 15px 25px;
  width: unset;
}

.documentFiles .file .btnContainer .red {
  color: #f56666;
  background-color: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  padding: 15px 25px;
  width: unset;
}

.pagination {
  margin-top: 25px;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}

.pagination a {
  font-size: 16px;
  width: 68px;
  height: 60px;
  color: #222222;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.info {
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 20px;
  max-width: 750px;
  margin-bottom: 25px;
  margin-left: 30px;
}

.info > span {
  color: #bbbbbb;
  margin-right: 20px;
}

.info p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 0;
}

/* Modal */

/* #servicesListModal .modal-container { */

/* padding: 30px 35px 35px 35px; */

/* max-width: 500px; */

/* } */

#bookMentorSessionModal .modalContainer {
  max-width: 700px;
  padding: 70px 50px;
}

#bookMentorSessionModal .iconContainer {
  text-align: center;
}

#bookMentorSessionModal .iconContainer span {
  color: #ffffff;
  background: #8ac04a;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#bookMentorSessionModal .modalContent h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  margin-top: 15px;
}

#bookMentorSessionModal .modalContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7d7d7d;
  margin: 8px 0 20px 0;
}

#bookMentorSessionModal .modalContent .form .twoCol {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin: 20px 0 50px 0;
}

#bookMentorSessionModal .modalContent .form .inputGroup input,
#bookMentorSessionModal .modalContent .form .inputGroup textarea {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  width: 100%;
  outline: none;
}

#bookMentorSessionModal .modalContent .form .inputBtn {
  text-align: center;
}

#bookMentorSessionModal .modalContent .form .inputBtn button {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 20px;
}

#bookMentorSessionModal .modalContent .form .inputBtn button:hover {
  opacity: 0.8;
  cursor: pointer;
}

#bookMentorSessionModal .modalContent .form .inputGroup input::-webkit-input-placeholder, #bookMentorSessionModal .modalContent .form .inputGroup textarea::-webkit-input-placeholder {
  color: #b7b8b7;
}

#bookMentorSessionModal .modalContent .form .inputGroup input:-ms-input-placeholder, #bookMentorSessionModal .modalContent .form .inputGroup textarea:-ms-input-placeholder {
  color: #b7b8b7;
}

#bookMentorSessionModal .modalContent .form .inputGroup input::placeholder,
#bookMentorSessionModal .modalContent .form .inputGroup textarea::placeholder {
  color: #b7b8b7;
}

#bookMentorSessionModal .modalContent .form .inputGroup label {
  font-size: 16px;
  line-height: 19px;
  color: #272828;
  display: block;
  margin-bottom: 5px;
}

#videoFileModal .modalContainer .videoContent {
  margin-top: 40px;
}

#videoFileModal .modalContainer .videoContent > div {
  width: 50%;
}

#videoFileModal .modalContainer .video {
  position: relative;
}

#videoFileModal .modalContainer .video::after,
#videoFileModal .modalContainer .video::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#videoFileModal .modalContainer .video::after {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 40px solid #ffffff;
  border-bottom: 20px solid transparent;
}

#videoFileModal .modalContainer .video::before {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #222222;
}

#videoFileModal .modalContainer .video img {
  width: 100%;
}

#videoFileModal .modalContainer .videoContent .videoTitle {
  padding-right: 30px;
  box-sizing: border-box;
}

#videoFileModal .modalContainer .videoContent .videoTitle h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
  text-align: unset;
}

#videoFileModal .modalContainer .videoContent .downloadBtn {
  margin-top: 25px;
}

#videoFileModal .modalContainer .videoContent .downloadBtn a {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  background: #404040;
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  padding: 15px 12px;
}

#videoFileModal .modalContainer .videoContent .downloadBtn a > * {
  margin-right: 5px;
}

#videoFileModal .modalContainer .videoContent .downloadBtn span {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  display: block;
  margin-top: 5px;
}

#videoFileModal .modalContainer .videoContent .videoDesc p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin: 0;
}

#videoFileModal .modalContainer .videoContent .videoDesc > *:not(:last-child) {
  margin-bottom: 30px;
}

#videoFileModal .modalContainer .close {
  background-color: #3d3d3d;
}

#videoFileModal .modalContainer i {
  color: #ffffff;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  height: unset;
  overflow: auto;
  background-color: rgb(34 34 34 / 40%);
  display: none;
  z-index: 9999999;
}

#videoFileModal .modalContainer {
  background: #222222;
  max-width: 1000px;
}

.modalContainer {
  background-color: #ffffff;
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}

#videoFileModal .closeModal {
  color: #ffffff;
  background-color: rgb(255 255 255 / 12%);
}

.closeModal {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  color: #f56666;
  background-color: rgb(245 102 102 / 12%);
  border: unset;
  outline: none;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.closeModal:hover {
  color: #ffffff;
  background-color: #f56666;
}

#servicesListModal h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
}

#servicesListModal .servicesList {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

#servicesListModal .servicesList li:not(:last-child) {
  margin-bottom: 10px;
}

#servicesListModal .servicesList li a {
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  display: block;
  padding: 10px;
  text-align: center;
}

#servicesListModal .servicesList li a:hover,
#servicesListModal .servicesList li.active a {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.yearContainer {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.yearContainer ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}

.yearContainer ul li:hover,
.yearContainer ul li.active {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.yearContainer ul li {
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.monthContainer ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
}

.monthContainer ul li {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 360px;
}

.monthContainer ul li.activeFocus:hover {
  background: #8ac04a;
  cursor: pointer;
}

.monthContainer ul li.activeFocus:hover p,
.monthContainer ul li.activeFocus:hover .month {
  color: #ffffff !important;
}

.monthContainer ul li.activeFocus:hover .focus {
  color: #8ac04a;
  background: #ffffff;
}

.monthContainer ul li.emptyFocus {
  align-items: center;
}

.monthContainer ul li.emptyFocus .activeFocusContent {
  padding: 0 20px 0 20px;
}

.monthContainer ul li .activeFocusContent {
  padding: 70px 10px 0 10px;
}

.monthContainer ul li.activeFocus {
  position: relative;
}

.monthContainer ul li.activeFocus .activeFocusBtnContent {
  width: 100%;
  position: absolute;
  bottom: 4px;
}

.monthContainer ul li.activeFocus .activeFocusBtnContent .activeFocusBtn {
  margin-right: 4px;
  margin-left: 4px;
}

.monthContainer ul li.emptyFocus .month {
  color: #808080;
}

.monthContainer ul li .month {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
}

.monthContainer ul li.activeFocus .focus {
  background: #8ac04a;
}

.monthContainer ul li .focus {
  font-size: 12px;
  line-height: 28px;
  color: #f9f9f9;
  background: #7d7d7d;
  border-radius: 2px;
  padding: 4px 15px;
  margin-bottom: 30px;
  display: inline-block;
}

.monthContainer ul li p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* .monthContainer ul .activeFocus { */

/* } */

.monthContainer ul li.activeFocus:hover .activeFocusBtn {
  color: #8ac04a;
  background: #ffffff;
}

.monthContainer ul li.activeFocus .activeFocusBtn {
  font-size: 12px;
  line-height: 28px;
  color: #f9f9f9;
  background: #8ac04a;
  border-radius: 3px 3px 3px 2px;
  padding: 8px;
  display: block;
  /* width: 100%; */
  /* position: absolute; */
  /* left: 0; */
}

.dashboardBanner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 90px 40px;
  box-sizing: border-box;
}

.dashboardContent {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

.dashboardContent .contentHeader {
  color: #ffffff;
  margin-bottom: 10px;
}

.dashboardContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin: 0;
}

.innerPagesContainer {
  padding: 40px 30px 70px 30px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.serviceLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.serviceLogo span {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 50px;
  display: inline-block;
}

.serviceLogo span img {
  width: 100%;
  max-width: 150px;
}

.innerPagesContainer .searchContainer {
  max-width: 335px;
  margin: auto;
  display: none;
}

.innerPagesContainer .searchContainer .search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
}

.innerPagesContainer .searchContainer input::-webkit-input-placeholder {
  color: #b0b0b0;
}

.innerPagesContainer .searchContainer input:-ms-input-placeholder {
  color: #b0b0b0;
}

.innerPagesContainer .searchContainer input::placeholder {
  color: #b0b0b0;
}

.innerPagesContainer .searchContainer input {
  font-size: 16px;
  line-height: 10px;
  color: #222222;
  padding: 10px 15px;
  border: unset;
  outline: none;
  width: 260px;
}

.innerPagesContainer .searchContainer button {
  height: 33px;
  width: 33px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.innerPagesContainer .searchContainer button:hover {
  opacity: 0.8;
}

.innerPagesContainer .searchContainer button > * {
  font-size: 17px;
}

.innerPagesContainer .helper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  color: #7d7d7d;
  margin-bottom: 15px;
}

.innerPagesContainer .blueBtn {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  border-radius: 3px;
  cursor: pointer;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.innerPagesContainer .blueButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.innerPagesList {
  max-width: 670px;
  margin: 40px auto 0 auto;
}

.innerPagesList ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.innerPagesList li a {
  padding: 35px 15px;
  text-align: center;
  display: block;
}

.innerPagesList li:hover {
  background: #8ac04a;
  border: 1px solid #7bae3e;
}

.innerPagesList ul li {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
}

.innerPagesList .dashboardRight {
  margin-left: 30px;
}

.innerPagesList .dashboardIcon {
  display: flex;
  justify-content: center;
}

.innerPagesList li:hover .dashboardIcon span {
  color: #8ac04a;
  background: #ffffff;
}

.innerPagesList .dashboardIcon span {
  color: #ffffff;
  background: #8ac04a;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerPagesList .dashboardName {
  margin-top: 15px;
}

.innerPagesList li:hover .dashboardName {
  color: #ffffff;
}

.innerPagesList .dashboardName {
  font-size: 20px;
  line-height: 32px;
  color: #222222;
  display: block;
  margin-bottom: 5px;
}

.innerPagesList li:hover .dashboardDetails {
  color: #ebebeb;
}

.innerPagesList .dashboardDetails {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  display: block;
}

.serviceDetails {
  background-color: #ffffff;
  padding: 55px 30px 40px 30px;
}

.serviceDetailsContent {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 40px;
}

.serviceDetailsContent h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #222222;
}

.serviceDetailsContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin: 8px 0 0 0;
}

.serviceDetailsFilter {
  margin-top: 45px;
}

.serviceDetailsFilter .label {
  color: #000000;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-bottom: 5px;
}

.serviceDetailsFilter > ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
}

.serviceDetailsFilter > ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 25px 20px;
}

.serviceDetailsFilter > ul li.active,
.serviceDetailsFilter > ul li:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.serviceDetailsFilter > ul li.active > svg,
.serviceDetailsFilter > ul li:hover > svg {
  fill: #ffffff;
}

.serviceDetailsFilter > ul li svg {
  fill: #b0b0b0;
  width: 45px;
}

.serviceDetailsFilter > ul li span {
  margin-left: 15px;
}

.serviceDetails .inputRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
}

.serviceDetails .inputContainer > .inputRow {
  margin-bottom: 20px;
}

.serviceDetails .inputGroup label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  display: block;
}

.serviceDetails .inputGroup input,
.serviceDetails .inputGroup textarea {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 20px;
  outline: none;
  width: 100%;
}

.serviceDetails .inputGroup input::-webkit-input-placeholder, .serviceDetails .inputGroup textarea::-webkit-input-placeholder {
  color: #b7b8b7;
}

.serviceDetails .inputGroup input:-ms-input-placeholder, .serviceDetails .inputGroup textarea:-ms-input-placeholder {
  color: #b7b8b7;
}

.serviceDetails .inputGroup input::placeholder,
.serviceDetails .inputGroup textarea::placeholder {
  color: #b7b8b7;
}

.serviceDetails .inputContainer .inputActionButton {
  text-align: center;
  margin-top: 25px;
}

.serviceDetails .blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.serviceDetails .inputContainer {
  margin-top: 35px;
}

.serviceDetails .inputGroup textarea {
  height: 225px;
}

.branding {
  padding: 70px 0 0 0;
}

.branding .brandingContent {
  text-align: center;
}

.branding .brandingContent {
  max-width: 640px;
  margin: 0 auto 40px auto;
  text-align: center;
}

.branding .brandingContent h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #272828;
}

.branding .brandingContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 15px 0 0 0;
}

.branding .uploadFile {
  background: #f9f9f9;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 90px;
  text-align: center;
}

.branding .uploadFile .uploadFileText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #7d7d7d;
  opacity: 0.4;
  display: block;
  margin-bottom: 18px;
}

.branding .btnSaveBranding {
  border-top: 1px solid #ebebeb;
  text-align: center;
  padding-top: 40px;
  margin-top: 30px;
}

.serviceDetails .blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.serviceDetails .blueButton:hover {
  color: #ffffff;
  background: #388cef;
  border: 1px solid #388cef;
}

.branding .uploadFile .blueButton input {
  display: none;
}

.branding .photoUploaded {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  padding: 20px 40px 20px 20px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.branding .photoUploaded .btnRemove button {
  font-weight: 400;
  font-size: 16px;
  color: #f56666;
  background: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.branding .photoUploaded .btnRemove button:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
}

.branding .photoUploaded .btnRemove button > * {
  margin-left: 5px;
}

.branding .photoUploadedContent .photoInfo {
  margin-left: 20px;
}

.branding .photoUploadedContent .photoInfo > span {
  display: block;
}

.branding .photoUploadedContent .photoInfo .photoName {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
}

.branding .photoUploadedContent .photoInfo .photoSize {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
}

.branding .photoUploadedContent {
  display: flex;
  align-items: center;
}

.branding .photoUploadedContent img {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 150px;
}

@media screen and (max-width: 1250px) {
  .portalFooter .footerBottom .footerRow {
    flex-wrap: wrap;
  }

  .portalFooter .footerBottom .footerMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
  }

  .portalFooter .footerBottom .footerCopyright {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
}

@media screen and (max-width: 1024px) {
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: flex;
  }

  .mobileMenu {
    display: none;
  }

  .bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
    font-size: 12px;
  }

  #videoFileModal .modalContainer {
    max-width: 1400px;
  }

  .monthContainer ul li .activeFocusContent {
    padding: 70px 20px 0 20px;
  }

  .topHeader .topHeaderLeft .topHeaderMenu li:not(:last-child) {
    margin-right: 20px;
  }

  .topHeader .topHeaderLeft .topHeaderMenu li a {
    font-size: 16px;
    line-height: 32px;
  }
}

@media screen and (min-width: 1100px) {
  .documentsListFilter .letterNav {
    display: flex !important;
  }

  .documentsListFilter h2 {
    margin: 35px 0 20px 0;
  }

  .documentsListFilter .btnLetter {
    display: none !important;
  }

  .documentsListFilter .categoriesNav {
    order: unset;
    width: auto;
  }

  .bottomHeader .headerMenu .searchBtn {
    display: block;
  }

  .bottomHeader .headerRow {
    padding: 10px 20px 10px 10px;
  }

  .headerRow {
    padding: 10px 20px;
  }

  .topHeader .topHeaderRight .services .selectedService {
    width: unset;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }

  .documentsListFilter .categoriesNav {
    grid-template-columns: repeat(5, 1fr);
  }

  .documentsListFilter .categoriesNav li {
    width: 160px;
  }

  .documentsListFilter .categoriesNav li a {
    font-size: 16px;
    line-height: 32px;
  }

  .documentsListFilter .btnLetter {
    font-size: 16px;
    height: 40px;
    width: 165px;
  }

  .tabHeader .menu .tabMenuItem {
    font-size: 16px;
  }
}

/*Search Results*/

.documentsList.searchResults {
  padding: unset;
}

.documentsList.searchResults .searchResultList {
  padding: 60px 30px 50px 30px;
}

.documentsList.searchResults .typeOfDocuments:not(:first-child) {
  margin-top: 50px;
}

.documentsList.searchResults .documentFiles {
  border-bottom: unset;
}

.documentsList.searchResults .documentFiles .file:not(:last-child) {
  margin-bottom: 10px;
}

.documentsList.searchResults .documentFiles .file {
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
}

.documentsList.searchResults .searchResultFooter {
  padding: 50px 30px;
  border-top: 1px solid #e7e7e7;
}

.documentsList.searchResults .searchResultFooter .pagination {
  margin-top: 0;
}

.documentsList.searchResults .btnContainer {
  display: flex;
  align-items: center;
}

.documentsList.searchResults .nqaType {
  margin-right: 35px;
}

.documentsList.searchResults .nqaType span {
  font-size: 16px;
  line-height: 28px;
  color: #8ac04a;
  background: rgb(138 192 74 / 20%);
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 5px;
}

.documentsList .trialPrompt {
  border-top: 1px solid #e7e7e7;
  margin-top: 25px;
  padding-top: 25px;
}

.documentsList .trialPrompt .contentPrompt {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e7e7;
  background: #f9f9f9;
  font-weight: 900;
  min-height: 300px;
}

@media screen and (min-width: 1150px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
    font-size: 15px;
  }
}

@media screen and (min-width: 1225px) {
  .products {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 1251px) {
  .portalFooter .footerBottom .footerRow {
    padding: 15px 25px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1270px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
    font-size: 17px;
  }

  .documentsListFilter .categoriesNav {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (min-width: 1440px) {
  #videoFileModal .modal-container {
    max-width: 1440px;
  }
}

@media (min-width: 640px){
  .sm\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .sm\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .sm\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .sm\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .sm\:container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .sm\:container{
      max-width: 1536px;
    }
  }

  .sm\:sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:focus-within\:sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:focus-within\:not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:focus\:sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:focus\:not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:pointer-events-none{
    pointer-events: none !important;
  }

  .sm\:pointer-events-auto{
    pointer-events: auto !important;
  }

  .sm\:visible{
    visibility: visible !important;
  }

  .sm\:invisible{
    visibility: hidden !important;
  }

  .sm\:static{
    position: static !important;
  }

  .sm\:fixed{
    position: fixed !important;
  }

  .sm\:absolute{
    position: absolute !important;
  }

  .sm\:relative{
    position: relative !important;
  }

  .sm\:sticky{
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .sm\:inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .sm\:inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .sm\:inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .sm\:inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .sm\:inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .sm\:inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .sm\:inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .sm\:inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .sm\:inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .sm\:inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .sm\:inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .sm\:inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .sm\:inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .sm\:inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .sm\:inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .sm\:inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .sm\:inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .sm\:inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .sm\:inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .sm\:inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .sm\:inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .sm\:inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .sm\:inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .sm\:inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .sm\:inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .sm\:inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .sm\:inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .sm\:inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .sm\:inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .sm\:inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .sm\:inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .sm\:inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .sm\:inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .sm\:inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .sm\:inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .sm\:inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .sm\:-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .sm\:-inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .sm\:-inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .sm\:-inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .sm\:-inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .sm\:-inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .sm\:-inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .sm\:-inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .sm\:-inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .sm\:-inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .sm\:-inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .sm\:-inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .sm\:-inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .sm\:-inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .sm\:-inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .sm\:-inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .sm\:-inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .sm\:-inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .sm\:-inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .sm\:-inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .sm\:-inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .sm\:-inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .sm\:-inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .sm\:-inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .sm\:-inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .sm\:-inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .sm\:-inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .sm\:-inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .sm\:-inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .sm\:-inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .sm\:-inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .sm\:-inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .sm\:-inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .sm\:-inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .sm\:-inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .sm\:inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .sm\:inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .sm\:inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .sm\:inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .sm\:inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .sm\:inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .sm\:inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .sm\:-inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .sm\:-inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .sm\:-inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .sm\:-inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .sm\:-inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .sm\:-inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .sm\:-inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .sm\:inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .sm\:inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .sm\:inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .sm\:inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .sm\:inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .sm\:inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .sm\:inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .sm\:inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .sm\:inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .sm\:inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .sm\:inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .sm\:inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .sm\:inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .sm\:inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .sm\:inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .sm\:inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .sm\:inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .sm\:inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .sm\:inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .sm\:inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .sm\:inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .sm\:inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .sm\:inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .sm\:inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .sm\:inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .sm\:inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .sm\:inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .sm\:inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .sm\:inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .sm\:inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .sm\:inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .sm\:inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .sm\:inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .sm\:inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .sm\:inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .sm\:inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .sm\:-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .sm\:-inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .sm\:-inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .sm\:-inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .sm\:-inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .sm\:-inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .sm\:-inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .sm\:-inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .sm\:-inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .sm\:-inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .sm\:-inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .sm\:-inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .sm\:-inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .sm\:-inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .sm\:-inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .sm\:-inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .sm\:-inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .sm\:-inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .sm\:-inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .sm\:-inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .sm\:-inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .sm\:-inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .sm\:-inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .sm\:-inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .sm\:-inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .sm\:-inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .sm\:-inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .sm\:-inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .sm\:-inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .sm\:-inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .sm\:-inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .sm\:-inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .sm\:-inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .sm\:-inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .sm\:-inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .sm\:inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .sm\:inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .sm\:inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .sm\:inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .sm\:inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .sm\:inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .sm\:inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .sm\:-inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .sm\:-inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .sm\:-inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .sm\:-inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .sm\:-inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .sm\:-inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .sm\:-inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .sm\:inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .sm\:inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .sm\:inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .sm\:inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .sm\:inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .sm\:inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .sm\:inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .sm\:inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .sm\:inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .sm\:inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .sm\:inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .sm\:inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .sm\:inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .sm\:inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .sm\:inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .sm\:inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .sm\:inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .sm\:inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .sm\:inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .sm\:inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .sm\:inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .sm\:inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .sm\:inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .sm\:inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .sm\:inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .sm\:inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .sm\:inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .sm\:inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .sm\:inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .sm\:inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .sm\:inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .sm\:inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .sm\:inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .sm\:inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .sm\:inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .sm\:inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .sm\:-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .sm\:-inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .sm\:-inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .sm\:-inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .sm\:-inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .sm\:-inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .sm\:-inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .sm\:-inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .sm\:-inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .sm\:-inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .sm\:-inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .sm\:-inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .sm\:-inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .sm\:-inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .sm\:-inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .sm\:-inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .sm\:-inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .sm\:-inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .sm\:-inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .sm\:-inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .sm\:-inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .sm\:-inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .sm\:-inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .sm\:-inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .sm\:-inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .sm\:-inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .sm\:-inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .sm\:-inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .sm\:-inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .sm\:-inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .sm\:-inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .sm\:-inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .sm\:-inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .sm\:-inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .sm\:-inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .sm\:inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .sm\:inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .sm\:inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .sm\:inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .sm\:inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .sm\:inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .sm\:inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .sm\:-inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .sm\:-inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .sm\:-inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .sm\:-inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .sm\:-inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .sm\:-inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .sm\:-inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .sm\:top-0{
    top: 0px !important;
  }

  .sm\:top-1{
    top: 0.25rem !important;
  }

  .sm\:top-2{
    top: 0.5rem !important;
  }

  .sm\:top-3{
    top: 0.75rem !important;
  }

  .sm\:top-4{
    top: 1rem !important;
  }

  .sm\:top-5{
    top: 1.25rem !important;
  }

  .sm\:top-6{
    top: 1.5rem !important;
  }

  .sm\:top-7{
    top: 1.75rem !important;
  }

  .sm\:top-8{
    top: 2rem !important;
  }

  .sm\:top-9{
    top: 2.25rem !important;
  }

  .sm\:top-10{
    top: 2.5rem !important;
  }

  .sm\:top-11{
    top: 2.75rem !important;
  }

  .sm\:top-12{
    top: 3rem !important;
  }

  .sm\:top-14{
    top: 3.5rem !important;
  }

  .sm\:top-16{
    top: 4rem !important;
  }

  .sm\:top-20{
    top: 5rem !important;
  }

  .sm\:top-24{
    top: 6rem !important;
  }

  .sm\:top-28{
    top: 7rem !important;
  }

  .sm\:top-32{
    top: 8rem !important;
  }

  .sm\:top-36{
    top: 9rem !important;
  }

  .sm\:top-40{
    top: 10rem !important;
  }

  .sm\:top-44{
    top: 11rem !important;
  }

  .sm\:top-48{
    top: 12rem !important;
  }

  .sm\:top-52{
    top: 13rem !important;
  }

  .sm\:top-56{
    top: 14rem !important;
  }

  .sm\:top-60{
    top: 15rem !important;
  }

  .sm\:top-64{
    top: 16rem !important;
  }

  .sm\:top-72{
    top: 18rem !important;
  }

  .sm\:top-80{
    top: 20rem !important;
  }

  .sm\:top-96{
    top: 24rem !important;
  }

  .sm\:top-auto{
    top: auto !important;
  }

  .sm\:top-px{
    top: 1px !important;
  }

  .sm\:top-0\.5{
    top: 0.125rem !important;
  }

  .sm\:top-1\.5{
    top: 0.375rem !important;
  }

  .sm\:top-2\.5{
    top: 0.625rem !important;
  }

  .sm\:top-3\.5{
    top: 0.875rem !important;
  }

  .sm\:-top-0{
    top: 0px !important;
  }

  .sm\:-top-1{
    top: -0.25rem !important;
  }

  .sm\:-top-2{
    top: -0.5rem !important;
  }

  .sm\:-top-3{
    top: -0.75rem !important;
  }

  .sm\:-top-4{
    top: -1rem !important;
  }

  .sm\:-top-5{
    top: -1.25rem !important;
  }

  .sm\:-top-6{
    top: -1.5rem !important;
  }

  .sm\:-top-7{
    top: -1.75rem !important;
  }

  .sm\:-top-8{
    top: -2rem !important;
  }

  .sm\:-top-9{
    top: -2.25rem !important;
  }

  .sm\:-top-10{
    top: -2.5rem !important;
  }

  .sm\:-top-11{
    top: -2.75rem !important;
  }

  .sm\:-top-12{
    top: -3rem !important;
  }

  .sm\:-top-14{
    top: -3.5rem !important;
  }

  .sm\:-top-16{
    top: -4rem !important;
  }

  .sm\:-top-20{
    top: -5rem !important;
  }

  .sm\:-top-24{
    top: -6rem !important;
  }

  .sm\:-top-28{
    top: -7rem !important;
  }

  .sm\:-top-32{
    top: -8rem !important;
  }

  .sm\:-top-36{
    top: -9rem !important;
  }

  .sm\:-top-40{
    top: -10rem !important;
  }

  .sm\:-top-44{
    top: -11rem !important;
  }

  .sm\:-top-48{
    top: -12rem !important;
  }

  .sm\:-top-52{
    top: -13rem !important;
  }

  .sm\:-top-56{
    top: -14rem !important;
  }

  .sm\:-top-60{
    top: -15rem !important;
  }

  .sm\:-top-64{
    top: -16rem !important;
  }

  .sm\:-top-72{
    top: -18rem !important;
  }

  .sm\:-top-80{
    top: -20rem !important;
  }

  .sm\:-top-96{
    top: -24rem !important;
  }

  .sm\:-top-px{
    top: -1px !important;
  }

  .sm\:-top-0\.5{
    top: -0.125rem !important;
  }

  .sm\:-top-1\.5{
    top: -0.375rem !important;
  }

  .sm\:-top-2\.5{
    top: -0.625rem !important;
  }

  .sm\:-top-3\.5{
    top: -0.875rem !important;
  }

  .sm\:top-1\/2{
    top: 50% !important;
  }

  .sm\:top-1\/3{
    top: 33.333333% !important;
  }

  .sm\:top-2\/3{
    top: 66.666667% !important;
  }

  .sm\:top-1\/4{
    top: 25% !important;
  }

  .sm\:top-2\/4{
    top: 50% !important;
  }

  .sm\:top-3\/4{
    top: 75% !important;
  }

  .sm\:top-full{
    top: 100% !important;
  }

  .sm\:-top-1\/2{
    top: -50% !important;
  }

  .sm\:-top-1\/3{
    top: -33.333333% !important;
  }

  .sm\:-top-2\/3{
    top: -66.666667% !important;
  }

  .sm\:-top-1\/4{
    top: -25% !important;
  }

  .sm\:-top-2\/4{
    top: -50% !important;
  }

  .sm\:-top-3\/4{
    top: -75% !important;
  }

  .sm\:-top-full{
    top: -100% !important;
  }

  .sm\:right-0{
    right: 0px !important;
  }

  .sm\:right-1{
    right: 0.25rem !important;
  }

  .sm\:right-2{
    right: 0.5rem !important;
  }

  .sm\:right-3{
    right: 0.75rem !important;
  }

  .sm\:right-4{
    right: 1rem !important;
  }

  .sm\:right-5{
    right: 1.25rem !important;
  }

  .sm\:right-6{
    right: 1.5rem !important;
  }

  .sm\:right-7{
    right: 1.75rem !important;
  }

  .sm\:right-8{
    right: 2rem !important;
  }

  .sm\:right-9{
    right: 2.25rem !important;
  }

  .sm\:right-10{
    right: 2.5rem !important;
  }

  .sm\:right-11{
    right: 2.75rem !important;
  }

  .sm\:right-12{
    right: 3rem !important;
  }

  .sm\:right-14{
    right: 3.5rem !important;
  }

  .sm\:right-16{
    right: 4rem !important;
  }

  .sm\:right-20{
    right: 5rem !important;
  }

  .sm\:right-24{
    right: 6rem !important;
  }

  .sm\:right-28{
    right: 7rem !important;
  }

  .sm\:right-32{
    right: 8rem !important;
  }

  .sm\:right-36{
    right: 9rem !important;
  }

  .sm\:right-40{
    right: 10rem !important;
  }

  .sm\:right-44{
    right: 11rem !important;
  }

  .sm\:right-48{
    right: 12rem !important;
  }

  .sm\:right-52{
    right: 13rem !important;
  }

  .sm\:right-56{
    right: 14rem !important;
  }

  .sm\:right-60{
    right: 15rem !important;
  }

  .sm\:right-64{
    right: 16rem !important;
  }

  .sm\:right-72{
    right: 18rem !important;
  }

  .sm\:right-80{
    right: 20rem !important;
  }

  .sm\:right-96{
    right: 24rem !important;
  }

  .sm\:right-auto{
    right: auto !important;
  }

  .sm\:right-px{
    right: 1px !important;
  }

  .sm\:right-0\.5{
    right: 0.125rem !important;
  }

  .sm\:right-1\.5{
    right: 0.375rem !important;
  }

  .sm\:right-2\.5{
    right: 0.625rem !important;
  }

  .sm\:right-3\.5{
    right: 0.875rem !important;
  }

  .sm\:-right-0{
    right: 0px !important;
  }

  .sm\:-right-1{
    right: -0.25rem !important;
  }

  .sm\:-right-2{
    right: -0.5rem !important;
  }

  .sm\:-right-3{
    right: -0.75rem !important;
  }

  .sm\:-right-4{
    right: -1rem !important;
  }

  .sm\:-right-5{
    right: -1.25rem !important;
  }

  .sm\:-right-6{
    right: -1.5rem !important;
  }

  .sm\:-right-7{
    right: -1.75rem !important;
  }

  .sm\:-right-8{
    right: -2rem !important;
  }

  .sm\:-right-9{
    right: -2.25rem !important;
  }

  .sm\:-right-10{
    right: -2.5rem !important;
  }

  .sm\:-right-11{
    right: -2.75rem !important;
  }

  .sm\:-right-12{
    right: -3rem !important;
  }

  .sm\:-right-14{
    right: -3.5rem !important;
  }

  .sm\:-right-16{
    right: -4rem !important;
  }

  .sm\:-right-20{
    right: -5rem !important;
  }

  .sm\:-right-24{
    right: -6rem !important;
  }

  .sm\:-right-28{
    right: -7rem !important;
  }

  .sm\:-right-32{
    right: -8rem !important;
  }

  .sm\:-right-36{
    right: -9rem !important;
  }

  .sm\:-right-40{
    right: -10rem !important;
  }

  .sm\:-right-44{
    right: -11rem !important;
  }

  .sm\:-right-48{
    right: -12rem !important;
  }

  .sm\:-right-52{
    right: -13rem !important;
  }

  .sm\:-right-56{
    right: -14rem !important;
  }

  .sm\:-right-60{
    right: -15rem !important;
  }

  .sm\:-right-64{
    right: -16rem !important;
  }

  .sm\:-right-72{
    right: -18rem !important;
  }

  .sm\:-right-80{
    right: -20rem !important;
  }

  .sm\:-right-96{
    right: -24rem !important;
  }

  .sm\:-right-px{
    right: -1px !important;
  }

  .sm\:-right-0\.5{
    right: -0.125rem !important;
  }

  .sm\:-right-1\.5{
    right: -0.375rem !important;
  }

  .sm\:-right-2\.5{
    right: -0.625rem !important;
  }

  .sm\:-right-3\.5{
    right: -0.875rem !important;
  }

  .sm\:right-1\/2{
    right: 50% !important;
  }

  .sm\:right-1\/3{
    right: 33.333333% !important;
  }

  .sm\:right-2\/3{
    right: 66.666667% !important;
  }

  .sm\:right-1\/4{
    right: 25% !important;
  }

  .sm\:right-2\/4{
    right: 50% !important;
  }

  .sm\:right-3\/4{
    right: 75% !important;
  }

  .sm\:right-full{
    right: 100% !important;
  }

  .sm\:-right-1\/2{
    right: -50% !important;
  }

  .sm\:-right-1\/3{
    right: -33.333333% !important;
  }

  .sm\:-right-2\/3{
    right: -66.666667% !important;
  }

  .sm\:-right-1\/4{
    right: -25% !important;
  }

  .sm\:-right-2\/4{
    right: -50% !important;
  }

  .sm\:-right-3\/4{
    right: -75% !important;
  }

  .sm\:-right-full{
    right: -100% !important;
  }

  .sm\:bottom-0{
    bottom: 0px !important;
  }

  .sm\:bottom-1{
    bottom: 0.25rem !important;
  }

  .sm\:bottom-2{
    bottom: 0.5rem !important;
  }

  .sm\:bottom-3{
    bottom: 0.75rem !important;
  }

  .sm\:bottom-4{
    bottom: 1rem !important;
  }

  .sm\:bottom-5{
    bottom: 1.25rem !important;
  }

  .sm\:bottom-6{
    bottom: 1.5rem !important;
  }

  .sm\:bottom-7{
    bottom: 1.75rem !important;
  }

  .sm\:bottom-8{
    bottom: 2rem !important;
  }

  .sm\:bottom-9{
    bottom: 2.25rem !important;
  }

  .sm\:bottom-10{
    bottom: 2.5rem !important;
  }

  .sm\:bottom-11{
    bottom: 2.75rem !important;
  }

  .sm\:bottom-12{
    bottom: 3rem !important;
  }

  .sm\:bottom-14{
    bottom: 3.5rem !important;
  }

  .sm\:bottom-16{
    bottom: 4rem !important;
  }

  .sm\:bottom-20{
    bottom: 5rem !important;
  }

  .sm\:bottom-24{
    bottom: 6rem !important;
  }

  .sm\:bottom-28{
    bottom: 7rem !important;
  }

  .sm\:bottom-32{
    bottom: 8rem !important;
  }

  .sm\:bottom-36{
    bottom: 9rem !important;
  }

  .sm\:bottom-40{
    bottom: 10rem !important;
  }

  .sm\:bottom-44{
    bottom: 11rem !important;
  }

  .sm\:bottom-48{
    bottom: 12rem !important;
  }

  .sm\:bottom-52{
    bottom: 13rem !important;
  }

  .sm\:bottom-56{
    bottom: 14rem !important;
  }

  .sm\:bottom-60{
    bottom: 15rem !important;
  }

  .sm\:bottom-64{
    bottom: 16rem !important;
  }

  .sm\:bottom-72{
    bottom: 18rem !important;
  }

  .sm\:bottom-80{
    bottom: 20rem !important;
  }

  .sm\:bottom-96{
    bottom: 24rem !important;
  }

  .sm\:bottom-auto{
    bottom: auto !important;
  }

  .sm\:bottom-px{
    bottom: 1px !important;
  }

  .sm\:bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .sm\:bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .sm\:bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .sm\:bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .sm\:-bottom-0{
    bottom: 0px !important;
  }

  .sm\:-bottom-1{
    bottom: -0.25rem !important;
  }

  .sm\:-bottom-2{
    bottom: -0.5rem !important;
  }

  .sm\:-bottom-3{
    bottom: -0.75rem !important;
  }

  .sm\:-bottom-4{
    bottom: -1rem !important;
  }

  .sm\:-bottom-5{
    bottom: -1.25rem !important;
  }

  .sm\:-bottom-6{
    bottom: -1.5rem !important;
  }

  .sm\:-bottom-7{
    bottom: -1.75rem !important;
  }

  .sm\:-bottom-8{
    bottom: -2rem !important;
  }

  .sm\:-bottom-9{
    bottom: -2.25rem !important;
  }

  .sm\:-bottom-10{
    bottom: -2.5rem !important;
  }

  .sm\:-bottom-11{
    bottom: -2.75rem !important;
  }

  .sm\:-bottom-12{
    bottom: -3rem !important;
  }

  .sm\:-bottom-14{
    bottom: -3.5rem !important;
  }

  .sm\:-bottom-16{
    bottom: -4rem !important;
  }

  .sm\:-bottom-20{
    bottom: -5rem !important;
  }

  .sm\:-bottom-24{
    bottom: -6rem !important;
  }

  .sm\:-bottom-28{
    bottom: -7rem !important;
  }

  .sm\:-bottom-32{
    bottom: -8rem !important;
  }

  .sm\:-bottom-36{
    bottom: -9rem !important;
  }

  .sm\:-bottom-40{
    bottom: -10rem !important;
  }

  .sm\:-bottom-44{
    bottom: -11rem !important;
  }

  .sm\:-bottom-48{
    bottom: -12rem !important;
  }

  .sm\:-bottom-52{
    bottom: -13rem !important;
  }

  .sm\:-bottom-56{
    bottom: -14rem !important;
  }

  .sm\:-bottom-60{
    bottom: -15rem !important;
  }

  .sm\:-bottom-64{
    bottom: -16rem !important;
  }

  .sm\:-bottom-72{
    bottom: -18rem !important;
  }

  .sm\:-bottom-80{
    bottom: -20rem !important;
  }

  .sm\:-bottom-96{
    bottom: -24rem !important;
  }

  .sm\:-bottom-px{
    bottom: -1px !important;
  }

  .sm\:-bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .sm\:-bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .sm\:-bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .sm\:-bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .sm\:bottom-1\/2{
    bottom: 50% !important;
  }

  .sm\:bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .sm\:bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .sm\:bottom-1\/4{
    bottom: 25% !important;
  }

  .sm\:bottom-2\/4{
    bottom: 50% !important;
  }

  .sm\:bottom-3\/4{
    bottom: 75% !important;
  }

  .sm\:bottom-full{
    bottom: 100% !important;
  }

  .sm\:-bottom-1\/2{
    bottom: -50% !important;
  }

  .sm\:-bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .sm\:-bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .sm\:-bottom-1\/4{
    bottom: -25% !important;
  }

  .sm\:-bottom-2\/4{
    bottom: -50% !important;
  }

  .sm\:-bottom-3\/4{
    bottom: -75% !important;
  }

  .sm\:-bottom-full{
    bottom: -100% !important;
  }

  .sm\:left-0{
    left: 0px !important;
  }

  .sm\:left-1{
    left: 0.25rem !important;
  }

  .sm\:left-2{
    left: 0.5rem !important;
  }

  .sm\:left-3{
    left: 0.75rem !important;
  }

  .sm\:left-4{
    left: 1rem !important;
  }

  .sm\:left-5{
    left: 1.25rem !important;
  }

  .sm\:left-6{
    left: 1.5rem !important;
  }

  .sm\:left-7{
    left: 1.75rem !important;
  }

  .sm\:left-8{
    left: 2rem !important;
  }

  .sm\:left-9{
    left: 2.25rem !important;
  }

  .sm\:left-10{
    left: 2.5rem !important;
  }

  .sm\:left-11{
    left: 2.75rem !important;
  }

  .sm\:left-12{
    left: 3rem !important;
  }

  .sm\:left-14{
    left: 3.5rem !important;
  }

  .sm\:left-16{
    left: 4rem !important;
  }

  .sm\:left-20{
    left: 5rem !important;
  }

  .sm\:left-24{
    left: 6rem !important;
  }

  .sm\:left-28{
    left: 7rem !important;
  }

  .sm\:left-32{
    left: 8rem !important;
  }

  .sm\:left-36{
    left: 9rem !important;
  }

  .sm\:left-40{
    left: 10rem !important;
  }

  .sm\:left-44{
    left: 11rem !important;
  }

  .sm\:left-48{
    left: 12rem !important;
  }

  .sm\:left-52{
    left: 13rem !important;
  }

  .sm\:left-56{
    left: 14rem !important;
  }

  .sm\:left-60{
    left: 15rem !important;
  }

  .sm\:left-64{
    left: 16rem !important;
  }

  .sm\:left-72{
    left: 18rem !important;
  }

  .sm\:left-80{
    left: 20rem !important;
  }

  .sm\:left-96{
    left: 24rem !important;
  }

  .sm\:left-auto{
    left: auto !important;
  }

  .sm\:left-px{
    left: 1px !important;
  }

  .sm\:left-0\.5{
    left: 0.125rem !important;
  }

  .sm\:left-1\.5{
    left: 0.375rem !important;
  }

  .sm\:left-2\.5{
    left: 0.625rem !important;
  }

  .sm\:left-3\.5{
    left: 0.875rem !important;
  }

  .sm\:-left-0{
    left: 0px !important;
  }

  .sm\:-left-1{
    left: -0.25rem !important;
  }

  .sm\:-left-2{
    left: -0.5rem !important;
  }

  .sm\:-left-3{
    left: -0.75rem !important;
  }

  .sm\:-left-4{
    left: -1rem !important;
  }

  .sm\:-left-5{
    left: -1.25rem !important;
  }

  .sm\:-left-6{
    left: -1.5rem !important;
  }

  .sm\:-left-7{
    left: -1.75rem !important;
  }

  .sm\:-left-8{
    left: -2rem !important;
  }

  .sm\:-left-9{
    left: -2.25rem !important;
  }

  .sm\:-left-10{
    left: -2.5rem !important;
  }

  .sm\:-left-11{
    left: -2.75rem !important;
  }

  .sm\:-left-12{
    left: -3rem !important;
  }

  .sm\:-left-14{
    left: -3.5rem !important;
  }

  .sm\:-left-16{
    left: -4rem !important;
  }

  .sm\:-left-20{
    left: -5rem !important;
  }

  .sm\:-left-24{
    left: -6rem !important;
  }

  .sm\:-left-28{
    left: -7rem !important;
  }

  .sm\:-left-32{
    left: -8rem !important;
  }

  .sm\:-left-36{
    left: -9rem !important;
  }

  .sm\:-left-40{
    left: -10rem !important;
  }

  .sm\:-left-44{
    left: -11rem !important;
  }

  .sm\:-left-48{
    left: -12rem !important;
  }

  .sm\:-left-52{
    left: -13rem !important;
  }

  .sm\:-left-56{
    left: -14rem !important;
  }

  .sm\:-left-60{
    left: -15rem !important;
  }

  .sm\:-left-64{
    left: -16rem !important;
  }

  .sm\:-left-72{
    left: -18rem !important;
  }

  .sm\:-left-80{
    left: -20rem !important;
  }

  .sm\:-left-96{
    left: -24rem !important;
  }

  .sm\:-left-px{
    left: -1px !important;
  }

  .sm\:-left-0\.5{
    left: -0.125rem !important;
  }

  .sm\:-left-1\.5{
    left: -0.375rem !important;
  }

  .sm\:-left-2\.5{
    left: -0.625rem !important;
  }

  .sm\:-left-3\.5{
    left: -0.875rem !important;
  }

  .sm\:left-1\/2{
    left: 50% !important;
  }

  .sm\:left-1\/3{
    left: 33.333333% !important;
  }

  .sm\:left-2\/3{
    left: 66.666667% !important;
  }

  .sm\:left-1\/4{
    left: 25% !important;
  }

  .sm\:left-2\/4{
    left: 50% !important;
  }

  .sm\:left-3\/4{
    left: 75% !important;
  }

  .sm\:left-full{
    left: 100% !important;
  }

  .sm\:-left-1\/2{
    left: -50% !important;
  }

  .sm\:-left-1\/3{
    left: -33.333333% !important;
  }

  .sm\:-left-2\/3{
    left: -66.666667% !important;
  }

  .sm\:-left-1\/4{
    left: -25% !important;
  }

  .sm\:-left-2\/4{
    left: -50% !important;
  }

  .sm\:-left-3\/4{
    left: -75% !important;
  }

  .sm\:-left-full{
    left: -100% !important;
  }

  .sm\:isolate{
    isolation: isolate !important;
  }

  .sm\:isolation-auto{
    isolation: auto !important;
  }

  .sm\:z-0{
    z-index: 0 !important;
  }

  .sm\:z-10{
    z-index: 10 !important;
  }

  .sm\:z-20{
    z-index: 20 !important;
  }

  .sm\:z-30{
    z-index: 30 !important;
  }

  .sm\:z-40{
    z-index: 40 !important;
  }

  .sm\:z-50{
    z-index: 50 !important;
  }

  .sm\:z-auto{
    z-index: auto !important;
  }

  .sm\:focus-within\:z-0:focus-within{
    z-index: 0 !important;
  }

  .sm\:focus-within\:z-10:focus-within{
    z-index: 10 !important;
  }

  .sm\:focus-within\:z-20:focus-within{
    z-index: 20 !important;
  }

  .sm\:focus-within\:z-30:focus-within{
    z-index: 30 !important;
  }

  .sm\:focus-within\:z-40:focus-within{
    z-index: 40 !important;
  }

  .sm\:focus-within\:z-50:focus-within{
    z-index: 50 !important;
  }

  .sm\:focus-within\:z-auto:focus-within{
    z-index: auto !important;
  }

  .sm\:focus\:z-0:focus{
    z-index: 0 !important;
  }

  .sm\:focus\:z-10:focus{
    z-index: 10 !important;
  }

  .sm\:focus\:z-20:focus{
    z-index: 20 !important;
  }

  .sm\:focus\:z-30:focus{
    z-index: 30 !important;
  }

  .sm\:focus\:z-40:focus{
    z-index: 40 !important;
  }

  .sm\:focus\:z-50:focus{
    z-index: 50 !important;
  }

  .sm\:focus\:z-auto:focus{
    z-index: auto !important;
  }

  .sm\:order-1{
    order: 1 !important;
  }

  .sm\:order-2{
    order: 2 !important;
  }

  .sm\:order-3{
    order: 3 !important;
  }

  .sm\:order-4{
    order: 4 !important;
  }

  .sm\:order-5{
    order: 5 !important;
  }

  .sm\:order-6{
    order: 6 !important;
  }

  .sm\:order-7{
    order: 7 !important;
  }

  .sm\:order-8{
    order: 8 !important;
  }

  .sm\:order-9{
    order: 9 !important;
  }

  .sm\:order-10{
    order: 10 !important;
  }

  .sm\:order-11{
    order: 11 !important;
  }

  .sm\:order-12{
    order: 12 !important;
  }

  .sm\:order-first{
    order: -9999 !important;
  }

  .sm\:order-last{
    order: 9999 !important;
  }

  .sm\:order-none{
    order: 0 !important;
  }

  .sm\:col-auto{
    grid-column: auto !important;
  }

  .sm\:col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .sm\:col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .sm\:col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .sm\:col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .sm\:col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .sm\:col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .sm\:col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .sm\:col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .sm\:col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .sm\:col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .sm\:col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .sm\:col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .sm\:col-span-full{
    grid-column: 1 / -1 !important;
  }

  .sm\:col-start-1{
    grid-column-start: 1 !important;
  }

  .sm\:col-start-2{
    grid-column-start: 2 !important;
  }

  .sm\:col-start-3{
    grid-column-start: 3 !important;
  }

  .sm\:col-start-4{
    grid-column-start: 4 !important;
  }

  .sm\:col-start-5{
    grid-column-start: 5 !important;
  }

  .sm\:col-start-6{
    grid-column-start: 6 !important;
  }

  .sm\:col-start-7{
    grid-column-start: 7 !important;
  }

  .sm\:col-start-8{
    grid-column-start: 8 !important;
  }

  .sm\:col-start-9{
    grid-column-start: 9 !important;
  }

  .sm\:col-start-10{
    grid-column-start: 10 !important;
  }

  .sm\:col-start-11{
    grid-column-start: 11 !important;
  }

  .sm\:col-start-12{
    grid-column-start: 12 !important;
  }

  .sm\:col-start-13{
    grid-column-start: 13 !important;
  }

  .sm\:col-start-auto{
    grid-column-start: auto !important;
  }

  .sm\:col-end-1{
    grid-column-end: 1 !important;
  }

  .sm\:col-end-2{
    grid-column-end: 2 !important;
  }

  .sm\:col-end-3{
    grid-column-end: 3 !important;
  }

  .sm\:col-end-4{
    grid-column-end: 4 !important;
  }

  .sm\:col-end-5{
    grid-column-end: 5 !important;
  }

  .sm\:col-end-6{
    grid-column-end: 6 !important;
  }

  .sm\:col-end-7{
    grid-column-end: 7 !important;
  }

  .sm\:col-end-8{
    grid-column-end: 8 !important;
  }

  .sm\:col-end-9{
    grid-column-end: 9 !important;
  }

  .sm\:col-end-10{
    grid-column-end: 10 !important;
  }

  .sm\:col-end-11{
    grid-column-end: 11 !important;
  }

  .sm\:col-end-12{
    grid-column-end: 12 !important;
  }

  .sm\:col-end-13{
    grid-column-end: 13 !important;
  }

  .sm\:col-end-auto{
    grid-column-end: auto !important;
  }

  .sm\:row-auto{
    grid-row: auto !important;
  }

  .sm\:row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .sm\:row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .sm\:row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .sm\:row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .sm\:row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .sm\:row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .sm\:row-span-full{
    grid-row: 1 / -1 !important;
  }

  .sm\:row-start-1{
    grid-row-start: 1 !important;
  }

  .sm\:row-start-2{
    grid-row-start: 2 !important;
  }

  .sm\:row-start-3{
    grid-row-start: 3 !important;
  }

  .sm\:row-start-4{
    grid-row-start: 4 !important;
  }

  .sm\:row-start-5{
    grid-row-start: 5 !important;
  }

  .sm\:row-start-6{
    grid-row-start: 6 !important;
  }

  .sm\:row-start-7{
    grid-row-start: 7 !important;
  }

  .sm\:row-start-auto{
    grid-row-start: auto !important;
  }

  .sm\:row-end-1{
    grid-row-end: 1 !important;
  }

  .sm\:row-end-2{
    grid-row-end: 2 !important;
  }

  .sm\:row-end-3{
    grid-row-end: 3 !important;
  }

  .sm\:row-end-4{
    grid-row-end: 4 !important;
  }

  .sm\:row-end-5{
    grid-row-end: 5 !important;
  }

  .sm\:row-end-6{
    grid-row-end: 6 !important;
  }

  .sm\:row-end-7{
    grid-row-end: 7 !important;
  }

  .sm\:row-end-auto{
    grid-row-end: auto !important;
  }

  .sm\:float-right{
    float: right !important;
  }

  .sm\:float-left{
    float: left !important;
  }

  .sm\:float-none{
    float: none !important;
  }

  .sm\:clear-left{
    clear: left !important;
  }

  .sm\:clear-right{
    clear: right !important;
  }

  .sm\:clear-both{
    clear: both !important;
  }

  .sm\:clear-none{
    clear: none !important;
  }

  .sm\:m-0{
    margin: 0px !important;
  }

  .sm\:m-1{
    margin: 0.25rem !important;
  }

  .sm\:m-2{
    margin: 0.5rem !important;
  }

  .sm\:m-3{
    margin: 0.75rem !important;
  }

  .sm\:m-4{
    margin: 1rem !important;
  }

  .sm\:m-5{
    margin: 1.25rem !important;
  }

  .sm\:m-6{
    margin: 1.5rem !important;
  }

  .sm\:m-7{
    margin: 1.75rem !important;
  }

  .sm\:m-8{
    margin: 2rem !important;
  }

  .sm\:m-9{
    margin: 2.25rem !important;
  }

  .sm\:m-10{
    margin: 2.5rem !important;
  }

  .sm\:m-11{
    margin: 2.75rem !important;
  }

  .sm\:m-12{
    margin: 3rem !important;
  }

  .sm\:m-14{
    margin: 3.5rem !important;
  }

  .sm\:m-16{
    margin: 4rem !important;
  }

  .sm\:m-20{
    margin: 5rem !important;
  }

  .sm\:m-24{
    margin: 6rem !important;
  }

  .sm\:m-28{
    margin: 7rem !important;
  }

  .sm\:m-32{
    margin: 8rem !important;
  }

  .sm\:m-36{
    margin: 9rem !important;
  }

  .sm\:m-40{
    margin: 10rem !important;
  }

  .sm\:m-44{
    margin: 11rem !important;
  }

  .sm\:m-48{
    margin: 12rem !important;
  }

  .sm\:m-52{
    margin: 13rem !important;
  }

  .sm\:m-56{
    margin: 14rem !important;
  }

  .sm\:m-60{
    margin: 15rem !important;
  }

  .sm\:m-64{
    margin: 16rem !important;
  }

  .sm\:m-72{
    margin: 18rem !important;
  }

  .sm\:m-80{
    margin: 20rem !important;
  }

  .sm\:m-96{
    margin: 24rem !important;
  }

  .sm\:m-auto{
    margin: auto !important;
  }

  .sm\:m-px{
    margin: 1px !important;
  }

  .sm\:m-0\.5{
    margin: 0.125rem !important;
  }

  .sm\:m-1\.5{
    margin: 0.375rem !important;
  }

  .sm\:m-2\.5{
    margin: 0.625rem !important;
  }

  .sm\:m-3\.5{
    margin: 0.875rem !important;
  }

  .sm\:-m-0{
    margin: 0px !important;
  }

  .sm\:-m-1{
    margin: -0.25rem !important;
  }

  .sm\:-m-2{
    margin: -0.5rem !important;
  }

  .sm\:-m-3{
    margin: -0.75rem !important;
  }

  .sm\:-m-4{
    margin: -1rem !important;
  }

  .sm\:-m-5{
    margin: -1.25rem !important;
  }

  .sm\:-m-6{
    margin: -1.5rem !important;
  }

  .sm\:-m-7{
    margin: -1.75rem !important;
  }

  .sm\:-m-8{
    margin: -2rem !important;
  }

  .sm\:-m-9{
    margin: -2.25rem !important;
  }

  .sm\:-m-10{
    margin: -2.5rem !important;
  }

  .sm\:-m-11{
    margin: -2.75rem !important;
  }

  .sm\:-m-12{
    margin: -3rem !important;
  }

  .sm\:-m-14{
    margin: -3.5rem !important;
  }

  .sm\:-m-16{
    margin: -4rem !important;
  }

  .sm\:-m-20{
    margin: -5rem !important;
  }

  .sm\:-m-24{
    margin: -6rem !important;
  }

  .sm\:-m-28{
    margin: -7rem !important;
  }

  .sm\:-m-32{
    margin: -8rem !important;
  }

  .sm\:-m-36{
    margin: -9rem !important;
  }

  .sm\:-m-40{
    margin: -10rem !important;
  }

  .sm\:-m-44{
    margin: -11rem !important;
  }

  .sm\:-m-48{
    margin: -12rem !important;
  }

  .sm\:-m-52{
    margin: -13rem !important;
  }

  .sm\:-m-56{
    margin: -14rem !important;
  }

  .sm\:-m-60{
    margin: -15rem !important;
  }

  .sm\:-m-64{
    margin: -16rem !important;
  }

  .sm\:-m-72{
    margin: -18rem !important;
  }

  .sm\:-m-80{
    margin: -20rem !important;
  }

  .sm\:-m-96{
    margin: -24rem !important;
  }

  .sm\:-m-px{
    margin: -1px !important;
  }

  .sm\:-m-0\.5{
    margin: -0.125rem !important;
  }

  .sm\:-m-1\.5{
    margin: -0.375rem !important;
  }

  .sm\:-m-2\.5{
    margin: -0.625rem !important;
  }

  .sm\:-m-3\.5{
    margin: -0.875rem !important;
  }

  .sm\:mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sm\:mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .sm\:mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .sm\:mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .sm\:mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .sm\:mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .sm\:mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .sm\:mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .sm\:mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .sm\:mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .sm\:mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .sm\:mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .sm\:mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .sm\:mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .sm\:mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .sm\:mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .sm\:mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .sm\:mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .sm\:mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .sm\:mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .sm\:mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .sm\:mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .sm\:mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .sm\:mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .sm\:mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .sm\:mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .sm\:mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .sm\:mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .sm\:-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .sm\:-mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .sm\:-mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .sm\:-mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .sm\:-mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .sm\:-mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:-mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .sm\:-mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .sm\:-mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .sm\:-mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .sm\:-mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .sm\:-mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:-mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .sm\:-mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .sm\:-mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .sm\:-mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .sm\:-mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .sm\:-mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .sm\:-mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .sm\:-mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .sm\:-mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .sm\:-mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .sm\:-mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .sm\:-mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .sm\:-mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .sm\:-mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .sm\:-mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .sm\:-mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .sm\:-mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .sm\:-mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .sm\:-mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .sm\:my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm\:my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .sm\:my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sm\:my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .sm\:my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .sm\:my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .sm\:my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .sm\:my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .sm\:my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .sm\:my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .sm\:my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .sm\:my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .sm\:my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .sm\:my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .sm\:my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .sm\:my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .sm\:my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .sm\:my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .sm\:my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .sm\:my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .sm\:my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .sm\:my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .sm\:my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .sm\:my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .sm\:my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sm\:my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .sm\:my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .sm\:my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .sm\:my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .sm\:my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .sm\:-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .sm\:-my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .sm\:-my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .sm\:-my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .sm\:-my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:-my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .sm\:-my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:-my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .sm\:-my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:-my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .sm\:-my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .sm\:-my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .sm\:-my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:-my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .sm\:-my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:-my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:-my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .sm\:-my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .sm\:-my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .sm\:-my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .sm\:-my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .sm\:-my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .sm\:-my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .sm\:-my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .sm\:-my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .sm\:-my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .sm\:-my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .sm\:-my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .sm\:-my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .sm\:-my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .sm\:-my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .sm\:-my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .sm\:-my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .sm\:-my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .sm\:-my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .sm\:mt-0{
    margin-top: 0px !important;
  }

  .sm\:mt-1{
    margin-top: 0.25rem !important;
  }

  .sm\:mt-2{
    margin-top: 0.5rem !important;
  }

  .sm\:mt-3{
    margin-top: 0.75rem !important;
  }

  .sm\:mt-4{
    margin-top: 1rem !important;
  }

  .sm\:mt-5{
    margin-top: 1.25rem !important;
  }

  .sm\:mt-6{
    margin-top: 1.5rem !important;
  }

  .sm\:mt-7{
    margin-top: 1.75rem !important;
  }

  .sm\:mt-8{
    margin-top: 2rem !important;
  }

  .sm\:mt-9{
    margin-top: 2.25rem !important;
  }

  .sm\:mt-10{
    margin-top: 2.5rem !important;
  }

  .sm\:mt-11{
    margin-top: 2.75rem !important;
  }

  .sm\:mt-12{
    margin-top: 3rem !important;
  }

  .sm\:mt-14{
    margin-top: 3.5rem !important;
  }

  .sm\:mt-16{
    margin-top: 4rem !important;
  }

  .sm\:mt-20{
    margin-top: 5rem !important;
  }

  .sm\:mt-24{
    margin-top: 6rem !important;
  }

  .sm\:mt-28{
    margin-top: 7rem !important;
  }

  .sm\:mt-32{
    margin-top: 8rem !important;
  }

  .sm\:mt-36{
    margin-top: 9rem !important;
  }

  .sm\:mt-40{
    margin-top: 10rem !important;
  }

  .sm\:mt-44{
    margin-top: 11rem !important;
  }

  .sm\:mt-48{
    margin-top: 12rem !important;
  }

  .sm\:mt-52{
    margin-top: 13rem !important;
  }

  .sm\:mt-56{
    margin-top: 14rem !important;
  }

  .sm\:mt-60{
    margin-top: 15rem !important;
  }

  .sm\:mt-64{
    margin-top: 16rem !important;
  }

  .sm\:mt-72{
    margin-top: 18rem !important;
  }

  .sm\:mt-80{
    margin-top: 20rem !important;
  }

  .sm\:mt-96{
    margin-top: 24rem !important;
  }

  .sm\:mt-auto{
    margin-top: auto !important;
  }

  .sm\:mt-px{
    margin-top: 1px !important;
  }

  .sm\:mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .sm\:mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .sm\:mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .sm\:mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .sm\:-mt-0{
    margin-top: 0px !important;
  }

  .sm\:-mt-1{
    margin-top: -0.25rem !important;
  }

  .sm\:-mt-2{
    margin-top: -0.5rem !important;
  }

  .sm\:-mt-3{
    margin-top: -0.75rem !important;
  }

  .sm\:-mt-4{
    margin-top: -1rem !important;
  }

  .sm\:-mt-5{
    margin-top: -1.25rem !important;
  }

  .sm\:-mt-6{
    margin-top: -1.5rem !important;
  }

  .sm\:-mt-7{
    margin-top: -1.75rem !important;
  }

  .sm\:-mt-8{
    margin-top: -2rem !important;
  }

  .sm\:-mt-9{
    margin-top: -2.25rem !important;
  }

  .sm\:-mt-10{
    margin-top: -2.5rem !important;
  }

  .sm\:-mt-11{
    margin-top: -2.75rem !important;
  }

  .sm\:-mt-12{
    margin-top: -3rem !important;
  }

  .sm\:-mt-14{
    margin-top: -3.5rem !important;
  }

  .sm\:-mt-16{
    margin-top: -4rem !important;
  }

  .sm\:-mt-20{
    margin-top: -5rem !important;
  }

  .sm\:-mt-24{
    margin-top: -6rem !important;
  }

  .sm\:-mt-28{
    margin-top: -7rem !important;
  }

  .sm\:-mt-32{
    margin-top: -8rem !important;
  }

  .sm\:-mt-36{
    margin-top: -9rem !important;
  }

  .sm\:-mt-40{
    margin-top: -10rem !important;
  }

  .sm\:-mt-44{
    margin-top: -11rem !important;
  }

  .sm\:-mt-48{
    margin-top: -12rem !important;
  }

  .sm\:-mt-52{
    margin-top: -13rem !important;
  }

  .sm\:-mt-56{
    margin-top: -14rem !important;
  }

  .sm\:-mt-60{
    margin-top: -15rem !important;
  }

  .sm\:-mt-64{
    margin-top: -16rem !important;
  }

  .sm\:-mt-72{
    margin-top: -18rem !important;
  }

  .sm\:-mt-80{
    margin-top: -20rem !important;
  }

  .sm\:-mt-96{
    margin-top: -24rem !important;
  }

  .sm\:-mt-px{
    margin-top: -1px !important;
  }

  .sm\:-mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .sm\:-mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .sm\:-mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .sm\:-mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .sm\:mr-0{
    margin-right: 0px !important;
  }

  .sm\:mr-1{
    margin-right: 0.25rem !important;
  }

  .sm\:mr-2{
    margin-right: 0.5rem !important;
  }

  .sm\:mr-3{
    margin-right: 0.75rem !important;
  }

  .sm\:mr-4{
    margin-right: 1rem !important;
  }

  .sm\:mr-5{
    margin-right: 1.25rem !important;
  }

  .sm\:mr-6{
    margin-right: 1.5rem !important;
  }

  .sm\:mr-7{
    margin-right: 1.75rem !important;
  }

  .sm\:mr-8{
    margin-right: 2rem !important;
  }

  .sm\:mr-9{
    margin-right: 2.25rem !important;
  }

  .sm\:mr-10{
    margin-right: 2.5rem !important;
  }

  .sm\:mr-11{
    margin-right: 2.75rem !important;
  }

  .sm\:mr-12{
    margin-right: 3rem !important;
  }

  .sm\:mr-14{
    margin-right: 3.5rem !important;
  }

  .sm\:mr-16{
    margin-right: 4rem !important;
  }

  .sm\:mr-20{
    margin-right: 5rem !important;
  }

  .sm\:mr-24{
    margin-right: 6rem !important;
  }

  .sm\:mr-28{
    margin-right: 7rem !important;
  }

  .sm\:mr-32{
    margin-right: 8rem !important;
  }

  .sm\:mr-36{
    margin-right: 9rem !important;
  }

  .sm\:mr-40{
    margin-right: 10rem !important;
  }

  .sm\:mr-44{
    margin-right: 11rem !important;
  }

  .sm\:mr-48{
    margin-right: 12rem !important;
  }

  .sm\:mr-52{
    margin-right: 13rem !important;
  }

  .sm\:mr-56{
    margin-right: 14rem !important;
  }

  .sm\:mr-60{
    margin-right: 15rem !important;
  }

  .sm\:mr-64{
    margin-right: 16rem !important;
  }

  .sm\:mr-72{
    margin-right: 18rem !important;
  }

  .sm\:mr-80{
    margin-right: 20rem !important;
  }

  .sm\:mr-96{
    margin-right: 24rem !important;
  }

  .sm\:mr-auto{
    margin-right: auto !important;
  }

  .sm\:mr-px{
    margin-right: 1px !important;
  }

  .sm\:mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .sm\:mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .sm\:mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .sm\:mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .sm\:-mr-0{
    margin-right: 0px !important;
  }

  .sm\:-mr-1{
    margin-right: -0.25rem !important;
  }

  .sm\:-mr-2{
    margin-right: -0.5rem !important;
  }

  .sm\:-mr-3{
    margin-right: -0.75rem !important;
  }

  .sm\:-mr-4{
    margin-right: -1rem !important;
  }

  .sm\:-mr-5{
    margin-right: -1.25rem !important;
  }

  .sm\:-mr-6{
    margin-right: -1.5rem !important;
  }

  .sm\:-mr-7{
    margin-right: -1.75rem !important;
  }

  .sm\:-mr-8{
    margin-right: -2rem !important;
  }

  .sm\:-mr-9{
    margin-right: -2.25rem !important;
  }

  .sm\:-mr-10{
    margin-right: -2.5rem !important;
  }

  .sm\:-mr-11{
    margin-right: -2.75rem !important;
  }

  .sm\:-mr-12{
    margin-right: -3rem !important;
  }

  .sm\:-mr-14{
    margin-right: -3.5rem !important;
  }

  .sm\:-mr-16{
    margin-right: -4rem !important;
  }

  .sm\:-mr-20{
    margin-right: -5rem !important;
  }

  .sm\:-mr-24{
    margin-right: -6rem !important;
  }

  .sm\:-mr-28{
    margin-right: -7rem !important;
  }

  .sm\:-mr-32{
    margin-right: -8rem !important;
  }

  .sm\:-mr-36{
    margin-right: -9rem !important;
  }

  .sm\:-mr-40{
    margin-right: -10rem !important;
  }

  .sm\:-mr-44{
    margin-right: -11rem !important;
  }

  .sm\:-mr-48{
    margin-right: -12rem !important;
  }

  .sm\:-mr-52{
    margin-right: -13rem !important;
  }

  .sm\:-mr-56{
    margin-right: -14rem !important;
  }

  .sm\:-mr-60{
    margin-right: -15rem !important;
  }

  .sm\:-mr-64{
    margin-right: -16rem !important;
  }

  .sm\:-mr-72{
    margin-right: -18rem !important;
  }

  .sm\:-mr-80{
    margin-right: -20rem !important;
  }

  .sm\:-mr-96{
    margin-right: -24rem !important;
  }

  .sm\:-mr-px{
    margin-right: -1px !important;
  }

  .sm\:-mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .sm\:-mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .sm\:-mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .sm\:-mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .sm\:mb-0{
    margin-bottom: 0px !important;
  }

  .sm\:mb-1{
    margin-bottom: 0.25rem !important;
  }

  .sm\:mb-2{
    margin-bottom: 0.5rem !important;
  }

  .sm\:mb-3{
    margin-bottom: 0.75rem !important;
  }

  .sm\:mb-4{
    margin-bottom: 1rem !important;
  }

  .sm\:mb-5{
    margin-bottom: 1.25rem !important;
  }

  .sm\:mb-6{
    margin-bottom: 1.5rem !important;
  }

  .sm\:mb-7{
    margin-bottom: 1.75rem !important;
  }

  .sm\:mb-8{
    margin-bottom: 2rem !important;
  }

  .sm\:mb-9{
    margin-bottom: 2.25rem !important;
  }

  .sm\:mb-10{
    margin-bottom: 2.5rem !important;
  }

  .sm\:mb-11{
    margin-bottom: 2.75rem !important;
  }

  .sm\:mb-12{
    margin-bottom: 3rem !important;
  }

  .sm\:mb-14{
    margin-bottom: 3.5rem !important;
  }

  .sm\:mb-16{
    margin-bottom: 4rem !important;
  }

  .sm\:mb-20{
    margin-bottom: 5rem !important;
  }

  .sm\:mb-24{
    margin-bottom: 6rem !important;
  }

  .sm\:mb-28{
    margin-bottom: 7rem !important;
  }

  .sm\:mb-32{
    margin-bottom: 8rem !important;
  }

  .sm\:mb-36{
    margin-bottom: 9rem !important;
  }

  .sm\:mb-40{
    margin-bottom: 10rem !important;
  }

  .sm\:mb-44{
    margin-bottom: 11rem !important;
  }

  .sm\:mb-48{
    margin-bottom: 12rem !important;
  }

  .sm\:mb-52{
    margin-bottom: 13rem !important;
  }

  .sm\:mb-56{
    margin-bottom: 14rem !important;
  }

  .sm\:mb-60{
    margin-bottom: 15rem !important;
  }

  .sm\:mb-64{
    margin-bottom: 16rem !important;
  }

  .sm\:mb-72{
    margin-bottom: 18rem !important;
  }

  .sm\:mb-80{
    margin-bottom: 20rem !important;
  }

  .sm\:mb-96{
    margin-bottom: 24rem !important;
  }

  .sm\:mb-auto{
    margin-bottom: auto !important;
  }

  .sm\:mb-px{
    margin-bottom: 1px !important;
  }

  .sm\:mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .sm\:mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .sm\:mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .sm\:mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .sm\:-mb-0{
    margin-bottom: 0px !important;
  }

  .sm\:-mb-1{
    margin-bottom: -0.25rem !important;
  }

  .sm\:-mb-2{
    margin-bottom: -0.5rem !important;
  }

  .sm\:-mb-3{
    margin-bottom: -0.75rem !important;
  }

  .sm\:-mb-4{
    margin-bottom: -1rem !important;
  }

  .sm\:-mb-5{
    margin-bottom: -1.25rem !important;
  }

  .sm\:-mb-6{
    margin-bottom: -1.5rem !important;
  }

  .sm\:-mb-7{
    margin-bottom: -1.75rem !important;
  }

  .sm\:-mb-8{
    margin-bottom: -2rem !important;
  }

  .sm\:-mb-9{
    margin-bottom: -2.25rem !important;
  }

  .sm\:-mb-10{
    margin-bottom: -2.5rem !important;
  }

  .sm\:-mb-11{
    margin-bottom: -2.75rem !important;
  }

  .sm\:-mb-12{
    margin-bottom: -3rem !important;
  }

  .sm\:-mb-14{
    margin-bottom: -3.5rem !important;
  }

  .sm\:-mb-16{
    margin-bottom: -4rem !important;
  }

  .sm\:-mb-20{
    margin-bottom: -5rem !important;
  }

  .sm\:-mb-24{
    margin-bottom: -6rem !important;
  }

  .sm\:-mb-28{
    margin-bottom: -7rem !important;
  }

  .sm\:-mb-32{
    margin-bottom: -8rem !important;
  }

  .sm\:-mb-36{
    margin-bottom: -9rem !important;
  }

  .sm\:-mb-40{
    margin-bottom: -10rem !important;
  }

  .sm\:-mb-44{
    margin-bottom: -11rem !important;
  }

  .sm\:-mb-48{
    margin-bottom: -12rem !important;
  }

  .sm\:-mb-52{
    margin-bottom: -13rem !important;
  }

  .sm\:-mb-56{
    margin-bottom: -14rem !important;
  }

  .sm\:-mb-60{
    margin-bottom: -15rem !important;
  }

  .sm\:-mb-64{
    margin-bottom: -16rem !important;
  }

  .sm\:-mb-72{
    margin-bottom: -18rem !important;
  }

  .sm\:-mb-80{
    margin-bottom: -20rem !important;
  }

  .sm\:-mb-96{
    margin-bottom: -24rem !important;
  }

  .sm\:-mb-px{
    margin-bottom: -1px !important;
  }

  .sm\:-mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .sm\:-mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .sm\:-mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .sm\:-mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .sm\:ml-0{
    margin-left: 0px !important;
  }

  .sm\:ml-1{
    margin-left: 0.25rem !important;
  }

  .sm\:ml-2{
    margin-left: 0.5rem !important;
  }

  .sm\:ml-3{
    margin-left: 0.75rem !important;
  }

  .sm\:ml-4{
    margin-left: 1rem !important;
  }

  .sm\:ml-5{
    margin-left: 1.25rem !important;
  }

  .sm\:ml-6{
    margin-left: 1.5rem !important;
  }

  .sm\:ml-7{
    margin-left: 1.75rem !important;
  }

  .sm\:ml-8{
    margin-left: 2rem !important;
  }

  .sm\:ml-9{
    margin-left: 2.25rem !important;
  }

  .sm\:ml-10{
    margin-left: 2.5rem !important;
  }

  .sm\:ml-11{
    margin-left: 2.75rem !important;
  }

  .sm\:ml-12{
    margin-left: 3rem !important;
  }

  .sm\:ml-14{
    margin-left: 3.5rem !important;
  }

  .sm\:ml-16{
    margin-left: 4rem !important;
  }

  .sm\:ml-20{
    margin-left: 5rem !important;
  }

  .sm\:ml-24{
    margin-left: 6rem !important;
  }

  .sm\:ml-28{
    margin-left: 7rem !important;
  }

  .sm\:ml-32{
    margin-left: 8rem !important;
  }

  .sm\:ml-36{
    margin-left: 9rem !important;
  }

  .sm\:ml-40{
    margin-left: 10rem !important;
  }

  .sm\:ml-44{
    margin-left: 11rem !important;
  }

  .sm\:ml-48{
    margin-left: 12rem !important;
  }

  .sm\:ml-52{
    margin-left: 13rem !important;
  }

  .sm\:ml-56{
    margin-left: 14rem !important;
  }

  .sm\:ml-60{
    margin-left: 15rem !important;
  }

  .sm\:ml-64{
    margin-left: 16rem !important;
  }

  .sm\:ml-72{
    margin-left: 18rem !important;
  }

  .sm\:ml-80{
    margin-left: 20rem !important;
  }

  .sm\:ml-96{
    margin-left: 24rem !important;
  }

  .sm\:ml-auto{
    margin-left: auto !important;
  }

  .sm\:ml-px{
    margin-left: 1px !important;
  }

  .sm\:ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .sm\:ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .sm\:ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .sm\:ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .sm\:-ml-0{
    margin-left: 0px !important;
  }

  .sm\:-ml-1{
    margin-left: -0.25rem !important;
  }

  .sm\:-ml-2{
    margin-left: -0.5rem !important;
  }

  .sm\:-ml-3{
    margin-left: -0.75rem !important;
  }

  .sm\:-ml-4{
    margin-left: -1rem !important;
  }

  .sm\:-ml-5{
    margin-left: -1.25rem !important;
  }

  .sm\:-ml-6{
    margin-left: -1.5rem !important;
  }

  .sm\:-ml-7{
    margin-left: -1.75rem !important;
  }

  .sm\:-ml-8{
    margin-left: -2rem !important;
  }

  .sm\:-ml-9{
    margin-left: -2.25rem !important;
  }

  .sm\:-ml-10{
    margin-left: -2.5rem !important;
  }

  .sm\:-ml-11{
    margin-left: -2.75rem !important;
  }

  .sm\:-ml-12{
    margin-left: -3rem !important;
  }

  .sm\:-ml-14{
    margin-left: -3.5rem !important;
  }

  .sm\:-ml-16{
    margin-left: -4rem !important;
  }

  .sm\:-ml-20{
    margin-left: -5rem !important;
  }

  .sm\:-ml-24{
    margin-left: -6rem !important;
  }

  .sm\:-ml-28{
    margin-left: -7rem !important;
  }

  .sm\:-ml-32{
    margin-left: -8rem !important;
  }

  .sm\:-ml-36{
    margin-left: -9rem !important;
  }

  .sm\:-ml-40{
    margin-left: -10rem !important;
  }

  .sm\:-ml-44{
    margin-left: -11rem !important;
  }

  .sm\:-ml-48{
    margin-left: -12rem !important;
  }

  .sm\:-ml-52{
    margin-left: -13rem !important;
  }

  .sm\:-ml-56{
    margin-left: -14rem !important;
  }

  .sm\:-ml-60{
    margin-left: -15rem !important;
  }

  .sm\:-ml-64{
    margin-left: -16rem !important;
  }

  .sm\:-ml-72{
    margin-left: -18rem !important;
  }

  .sm\:-ml-80{
    margin-left: -20rem !important;
  }

  .sm\:-ml-96{
    margin-left: -24rem !important;
  }

  .sm\:-ml-px{
    margin-left: -1px !important;
  }

  .sm\:-ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .sm\:-ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .sm\:-ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .sm\:-ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .sm\:box-border{
    box-sizing: border-box !important;
  }

  .sm\:box-content{
    box-sizing: content-box !important;
  }

  .sm\:block{
    display: block !important;
  }

  .sm\:inline-block{
    display: inline-block !important;
  }

  .sm\:inline{
    display: inline !important;
  }

  .sm\:flex{
    display: flex !important;
  }

  .sm\:inline-flex{
    display: inline-flex !important;
  }

  .sm\:table{
    display: table !important;
  }

  .sm\:inline-table{
    display: inline-table !important;
  }

  .sm\:table-caption{
    display: table-caption !important;
  }

  .sm\:table-cell{
    display: table-cell !important;
  }

  .sm\:table-column{
    display: table-column !important;
  }

  .sm\:table-column-group{
    display: table-column-group !important;
  }

  .sm\:table-footer-group{
    display: table-footer-group !important;
  }

  .sm\:table-header-group{
    display: table-header-group !important;
  }

  .sm\:table-row-group{
    display: table-row-group !important;
  }

  .sm\:table-row{
    display: table-row !important;
  }

  .sm\:flow-root{
    display: flow-root !important;
  }

  .sm\:grid{
    display: grid !important;
  }

  .sm\:inline-grid{
    display: inline-grid !important;
  }

  .sm\:contents{
    display: contents !important;
  }

  .sm\:list-item{
    display: list-item !important;
  }

  .sm\:hidden{
    display: none !important;
  }

  .sm\:h-0{
    height: 0px !important;
  }

  .sm\:h-1{
    height: 0.25rem !important;
  }

  .sm\:h-2{
    height: 0.5rem !important;
  }

  .sm\:h-3{
    height: 0.75rem !important;
  }

  .sm\:h-4{
    height: 1rem !important;
  }

  .sm\:h-5{
    height: 1.25rem !important;
  }

  .sm\:h-6{
    height: 1.5rem !important;
  }

  .sm\:h-7{
    height: 1.75rem !important;
  }

  .sm\:h-8{
    height: 2rem !important;
  }

  .sm\:h-9{
    height: 2.25rem !important;
  }

  .sm\:h-10{
    height: 2.5rem !important;
  }

  .sm\:h-11{
    height: 2.75rem !important;
  }

  .sm\:h-12{
    height: 3rem !important;
  }

  .sm\:h-14{
    height: 3.5rem !important;
  }

  .sm\:h-16{
    height: 4rem !important;
  }

  .sm\:h-20{
    height: 5rem !important;
  }

  .sm\:h-24{
    height: 6rem !important;
  }

  .sm\:h-28{
    height: 7rem !important;
  }

  .sm\:h-32{
    height: 8rem !important;
  }

  .sm\:h-36{
    height: 9rem !important;
  }

  .sm\:h-40{
    height: 10rem !important;
  }

  .sm\:h-44{
    height: 11rem !important;
  }

  .sm\:h-48{
    height: 12rem !important;
  }

  .sm\:h-52{
    height: 13rem !important;
  }

  .sm\:h-56{
    height: 14rem !important;
  }

  .sm\:h-60{
    height: 15rem !important;
  }

  .sm\:h-64{
    height: 16rem !important;
  }

  .sm\:h-72{
    height: 18rem !important;
  }

  .sm\:h-80{
    height: 20rem !important;
  }

  .sm\:h-96{
    height: 24rem !important;
  }

  .sm\:h-auto{
    height: auto !important;
  }

  .sm\:h-px{
    height: 1px !important;
  }

  .sm\:h-0\.5{
    height: 0.125rem !important;
  }

  .sm\:h-1\.5{
    height: 0.375rem !important;
  }

  .sm\:h-2\.5{
    height: 0.625rem !important;
  }

  .sm\:h-3\.5{
    height: 0.875rem !important;
  }

  .sm\:h-1\/2{
    height: 50% !important;
  }

  .sm\:h-1\/3{
    height: 33.333333% !important;
  }

  .sm\:h-2\/3{
    height: 66.666667% !important;
  }

  .sm\:h-1\/4{
    height: 25% !important;
  }

  .sm\:h-2\/4{
    height: 50% !important;
  }

  .sm\:h-3\/4{
    height: 75% !important;
  }

  .sm\:h-1\/5{
    height: 20% !important;
  }

  .sm\:h-2\/5{
    height: 40% !important;
  }

  .sm\:h-3\/5{
    height: 60% !important;
  }

  .sm\:h-4\/5{
    height: 80% !important;
  }

  .sm\:h-1\/6{
    height: 16.666667% !important;
  }

  .sm\:h-2\/6{
    height: 33.333333% !important;
  }

  .sm\:h-3\/6{
    height: 50% !important;
  }

  .sm\:h-4\/6{
    height: 66.666667% !important;
  }

  .sm\:h-5\/6{
    height: 83.333333% !important;
  }

  .sm\:h-full{
    height: 100% !important;
  }

  .sm\:h-screen{
    height: 100vh !important;
  }

  .sm\:max-h-0{
    max-height: 0px !important;
  }

  .sm\:max-h-1{
    max-height: 0.25rem !important;
  }

  .sm\:max-h-2{
    max-height: 0.5rem !important;
  }

  .sm\:max-h-3{
    max-height: 0.75rem !important;
  }

  .sm\:max-h-4{
    max-height: 1rem !important;
  }

  .sm\:max-h-5{
    max-height: 1.25rem !important;
  }

  .sm\:max-h-6{
    max-height: 1.5rem !important;
  }

  .sm\:max-h-7{
    max-height: 1.75rem !important;
  }

  .sm\:max-h-8{
    max-height: 2rem !important;
  }

  .sm\:max-h-9{
    max-height: 2.25rem !important;
  }

  .sm\:max-h-10{
    max-height: 2.5rem !important;
  }

  .sm\:max-h-11{
    max-height: 2.75rem !important;
  }

  .sm\:max-h-12{
    max-height: 3rem !important;
  }

  .sm\:max-h-14{
    max-height: 3.5rem !important;
  }

  .sm\:max-h-16{
    max-height: 4rem !important;
  }

  .sm\:max-h-20{
    max-height: 5rem !important;
  }

  .sm\:max-h-24{
    max-height: 6rem !important;
  }

  .sm\:max-h-28{
    max-height: 7rem !important;
  }

  .sm\:max-h-32{
    max-height: 8rem !important;
  }

  .sm\:max-h-36{
    max-height: 9rem !important;
  }

  .sm\:max-h-40{
    max-height: 10rem !important;
  }

  .sm\:max-h-44{
    max-height: 11rem !important;
  }

  .sm\:max-h-48{
    max-height: 12rem !important;
  }

  .sm\:max-h-52{
    max-height: 13rem !important;
  }

  .sm\:max-h-56{
    max-height: 14rem !important;
  }

  .sm\:max-h-60{
    max-height: 15rem !important;
  }

  .sm\:max-h-64{
    max-height: 16rem !important;
  }

  .sm\:max-h-72{
    max-height: 18rem !important;
  }

  .sm\:max-h-80{
    max-height: 20rem !important;
  }

  .sm\:max-h-96{
    max-height: 24rem !important;
  }

  .sm\:max-h-px{
    max-height: 1px !important;
  }

  .sm\:max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .sm\:max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .sm\:max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .sm\:max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .sm\:max-h-full{
    max-height: 100% !important;
  }

  .sm\:max-h-screen{
    max-height: 100vh !important;
  }

  .sm\:min-h-0{
    min-height: 0px !important;
  }

  .sm\:min-h-full{
    min-height: 100% !important;
  }

  .sm\:min-h-screen{
    min-height: 100vh !important;
  }

  .sm\:w-0{
    width: 0px !important;
  }

  .sm\:w-1{
    width: 0.25rem !important;
  }

  .sm\:w-2{
    width: 0.5rem !important;
  }

  .sm\:w-3{
    width: 0.75rem !important;
  }

  .sm\:w-4{
    width: 1rem !important;
  }

  .sm\:w-5{
    width: 1.25rem !important;
  }

  .sm\:w-6{
    width: 1.5rem !important;
  }

  .sm\:w-7{
    width: 1.75rem !important;
  }

  .sm\:w-8{
    width: 2rem !important;
  }

  .sm\:w-9{
    width: 2.25rem !important;
  }

  .sm\:w-10{
    width: 2.5rem !important;
  }

  .sm\:w-11{
    width: 2.75rem !important;
  }

  .sm\:w-12{
    width: 3rem !important;
  }

  .sm\:w-14{
    width: 3.5rem !important;
  }

  .sm\:w-16{
    width: 4rem !important;
  }

  .sm\:w-20{
    width: 5rem !important;
  }

  .sm\:w-24{
    width: 6rem !important;
  }

  .sm\:w-28{
    width: 7rem !important;
  }

  .sm\:w-32{
    width: 8rem !important;
  }

  .sm\:w-36{
    width: 9rem !important;
  }

  .sm\:w-40{
    width: 10rem !important;
  }

  .sm\:w-44{
    width: 11rem !important;
  }

  .sm\:w-48{
    width: 12rem !important;
  }

  .sm\:w-52{
    width: 13rem !important;
  }

  .sm\:w-56{
    width: 14rem !important;
  }

  .sm\:w-60{
    width: 15rem !important;
  }

  .sm\:w-64{
    width: 16rem !important;
  }

  .sm\:w-72{
    width: 18rem !important;
  }

  .sm\:w-80{
    width: 20rem !important;
  }

  .sm\:w-96{
    width: 24rem !important;
  }

  .sm\:w-auto{
    width: auto !important;
  }

  .sm\:w-px{
    width: 1px !important;
  }

  .sm\:w-0\.5{
    width: 0.125rem !important;
  }

  .sm\:w-1\.5{
    width: 0.375rem !important;
  }

  .sm\:w-2\.5{
    width: 0.625rem !important;
  }

  .sm\:w-3\.5{
    width: 0.875rem !important;
  }

  .sm\:w-1\/2{
    width: 50% !important;
  }

  .sm\:w-1\/3{
    width: 33.333333% !important;
  }

  .sm\:w-2\/3{
    width: 66.666667% !important;
  }

  .sm\:w-1\/4{
    width: 25% !important;
  }

  .sm\:w-2\/4{
    width: 50% !important;
  }

  .sm\:w-3\/4{
    width: 75% !important;
  }

  .sm\:w-1\/5{
    width: 20% !important;
  }

  .sm\:w-2\/5{
    width: 40% !important;
  }

  .sm\:w-3\/5{
    width: 60% !important;
  }

  .sm\:w-4\/5{
    width: 80% !important;
  }

  .sm\:w-1\/6{
    width: 16.666667% !important;
  }

  .sm\:w-2\/6{
    width: 33.333333% !important;
  }

  .sm\:w-3\/6{
    width: 50% !important;
  }

  .sm\:w-4\/6{
    width: 66.666667% !important;
  }

  .sm\:w-5\/6{
    width: 83.333333% !important;
  }

  .sm\:w-1\/12{
    width: 8.333333% !important;
  }

  .sm\:w-2\/12{
    width: 16.666667% !important;
  }

  .sm\:w-3\/12{
    width: 25% !important;
  }

  .sm\:w-4\/12{
    width: 33.333333% !important;
  }

  .sm\:w-5\/12{
    width: 41.666667% !important;
  }

  .sm\:w-6\/12{
    width: 50% !important;
  }

  .sm\:w-7\/12{
    width: 58.333333% !important;
  }

  .sm\:w-8\/12{
    width: 66.666667% !important;
  }

  .sm\:w-9\/12{
    width: 75% !important;
  }

  .sm\:w-10\/12{
    width: 83.333333% !important;
  }

  .sm\:w-11\/12{
    width: 91.666667% !important;
  }

  .sm\:w-full{
    width: 100% !important;
  }

  .sm\:w-screen{
    width: 100vw !important;
  }

  .sm\:w-min{
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .sm\:w-max{
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .sm\:min-w-0{
    min-width: 0px !important;
  }

  .sm\:min-w-full{
    min-width: 100% !important;
  }

  .sm\:min-w-min{
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important;
  }

  .sm\:min-w-max{
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
  }

  .sm\:max-w-0{
    max-width: 0rem !important;
  }

  .sm\:max-w-none{
    max-width: none !important;
  }

  .sm\:max-w-xs{
    max-width: 20rem !important;
  }

  .sm\:max-w-sm{
    max-width: 24rem !important;
  }

  .sm\:max-w-md{
    max-width: 28rem !important;
  }

  .sm\:max-w-lg{
    max-width: 32rem !important;
  }

  .sm\:max-w-xl{
    max-width: 36rem !important;
  }

  .sm\:max-w-2xl{
    max-width: 42rem !important;
  }

  .sm\:max-w-3xl{
    max-width: 48rem !important;
  }

  .sm\:max-w-4xl{
    max-width: 56rem !important;
  }

  .sm\:max-w-5xl{
    max-width: 64rem !important;
  }

  .sm\:max-w-6xl{
    max-width: 72rem !important;
  }

  .sm\:max-w-7xl{
    max-width: 80rem !important;
  }

  .sm\:max-w-full{
    max-width: 100% !important;
  }

  .sm\:max-w-min{
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .sm\:max-w-max{
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .sm\:max-w-prose{
    max-width: 65ch !important;
  }

  .sm\:max-w-screen-sm{
    max-width: 640px !important;
  }

  .sm\:max-w-screen-md{
    max-width: 768px !important;
  }

  .sm\:max-w-screen-lg{
    max-width: 1024px !important;
  }

  .sm\:max-w-screen-xl{
    max-width: 1280px !important;
  }

  .sm\:max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .sm\:flex-1{
    flex: 1 1 0% !important;
  }

  .sm\:flex-auto{
    flex: 1 1 auto !important;
  }

  .sm\:flex-initial{
    flex: 0 1 auto !important;
  }

  .sm\:flex-none{
    flex: none !important;
  }

  .sm\:flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .sm\:flex-shrink{
    flex-shrink: 1 !important;
  }

  .sm\:flex-grow-0{
    flex-grow: 0 !important;
  }

  .sm\:flex-grow{
    flex-grow: 1 !important;
  }

  .sm\:table-auto{
    table-layout: auto !important;
  }

  .sm\:table-fixed{
    table-layout: fixed !important;
  }

  .sm\:border-collapse{
    border-collapse: collapse !important;
  }

  .sm\:border-separate{
    border-collapse: separate !important;
  }

  .sm\:origin-center{
    transform-origin: center !important;
  }

  .sm\:origin-top{
    transform-origin: top !important;
  }

  .sm\:origin-top-right{
    transform-origin: top right !important;
  }

  .sm\:origin-right{
    transform-origin: right !important;
  }

  .sm\:origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .sm\:origin-bottom{
    transform-origin: bottom !important;
  }

  .sm\:origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .sm\:origin-left{
    transform-origin: left !important;
  }

  .sm\:origin-top-left{
    transform-origin: top left !important;
  }

  .sm\:transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .sm\:transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .sm\:transform-none{
    transform: none !important;
  }

  .sm\:translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .sm\:translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .sm\:translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .sm\:translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .sm\:translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .sm\:translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .sm\:translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .sm\:translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .sm\:translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .sm\:translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .sm\:translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .sm\:translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .sm\:translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .sm\:translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .sm\:translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .sm\:translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .sm\:translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .sm\:translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .sm\:translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .sm\:translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .sm\:translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .sm\:translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .sm\:translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .sm\:translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .sm\:translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .sm\:translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .sm\:translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .sm\:translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .sm\:translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .sm\:translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .sm\:translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .sm\:translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .sm\:translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .sm\:translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .sm\:translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .sm\:-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .sm\:-translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .sm\:-translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .sm\:-translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .sm\:-translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .sm\:-translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .sm\:-translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .sm\:-translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .sm\:-translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .sm\:-translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .sm\:-translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .sm\:-translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .sm\:-translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .sm\:-translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .sm\:-translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .sm\:-translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .sm\:-translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .sm\:-translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .sm\:-translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .sm\:-translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .sm\:-translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .sm\:-translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .sm\:-translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .sm\:-translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .sm\:-translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .sm\:-translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .sm\:-translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .sm\:-translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .sm\:-translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .sm\:-translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .sm\:-translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .sm\:-translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .sm\:-translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .sm\:-translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .sm\:-translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .sm\:translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .sm\:translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .sm\:translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .sm\:translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .sm\:translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .sm\:translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .sm\:translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .sm\:-translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .sm\:-translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .sm\:-translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .sm\:-translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .sm\:-translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .sm\:-translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .sm\:-translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .sm\:translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .sm\:translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .sm\:translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .sm\:translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .sm\:translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .sm\:translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .sm\:translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .sm\:translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .sm\:translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .sm\:translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .sm\:translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .sm\:translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .sm\:translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .sm\:translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .sm\:translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .sm\:translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .sm\:translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .sm\:translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .sm\:translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .sm\:translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .sm\:translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .sm\:translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .sm\:translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .sm\:translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .sm\:translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .sm\:translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .sm\:translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .sm\:translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .sm\:translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .sm\:translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .sm\:translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .sm\:translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .sm\:translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .sm\:translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .sm\:translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .sm\:-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .sm\:-translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .sm\:-translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .sm\:-translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .sm\:-translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .sm\:-translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .sm\:-translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .sm\:-translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .sm\:-translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .sm\:-translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .sm\:-translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .sm\:-translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .sm\:-translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .sm\:-translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .sm\:-translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .sm\:-translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .sm\:-translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .sm\:-translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .sm\:-translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .sm\:-translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .sm\:-translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .sm\:-translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .sm\:-translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .sm\:-translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .sm\:-translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .sm\:-translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .sm\:-translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .sm\:-translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .sm\:-translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .sm\:-translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .sm\:-translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .sm\:-translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .sm\:-translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .sm\:-translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .sm\:-translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .sm\:translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .sm\:translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .sm\:translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .sm\:translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .sm\:translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .sm\:translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .sm\:translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .sm\:-translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .sm\:-translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .sm\:-translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .sm\:-translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .sm\:-translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .sm\:-translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .sm\:-translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .sm\:hover\:translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .sm\:hover\:translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .sm\:hover\:translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .sm\:hover\:translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .sm\:hover\:translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .sm\:hover\:translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .sm\:hover\:translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .sm\:hover\:translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .sm\:hover\:translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .sm\:hover\:translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .sm\:hover\:translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .sm\:hover\:translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .sm\:hover\:translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .sm\:hover\:translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .sm\:hover\:translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .sm\:hover\:translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .sm\:hover\:translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .sm\:hover\:translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .sm\:hover\:translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .sm\:hover\:translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .sm\:hover\:translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .sm\:hover\:translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .sm\:hover\:translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .sm\:hover\:translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .sm\:hover\:translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .sm\:hover\:translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .sm\:hover\:translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .sm\:hover\:translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .sm\:hover\:translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .sm\:hover\:translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .sm\:hover\:translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .sm\:hover\:translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .sm\:hover\:translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .sm\:hover\:translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .sm\:hover\:translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .sm\:hover\:-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .sm\:hover\:-translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .sm\:hover\:-translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .sm\:hover\:-translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .sm\:hover\:-translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .sm\:hover\:-translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .sm\:hover\:-translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .sm\:hover\:-translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .sm\:hover\:-translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .sm\:hover\:-translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .sm\:hover\:-translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .sm\:hover\:-translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .sm\:hover\:-translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .sm\:hover\:-translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .sm\:hover\:-translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .sm\:hover\:-translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .sm\:hover\:-translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .sm\:hover\:-translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .sm\:hover\:-translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .sm\:hover\:-translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .sm\:hover\:-translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .sm\:hover\:-translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .sm\:hover\:-translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .sm\:hover\:-translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .sm\:hover\:-translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .sm\:hover\:-translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .sm\:hover\:-translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .sm\:hover\:-translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .sm\:hover\:-translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .sm\:hover\:-translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .sm\:hover\:-translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .sm\:hover\:-translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .sm\:hover\:-translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .sm\:hover\:-translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .sm\:hover\:-translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .sm\:hover\:translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .sm\:hover\:translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .sm\:hover\:translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .sm\:hover\:translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .sm\:hover\:translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .sm\:hover\:translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .sm\:hover\:translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .sm\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .sm\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .sm\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .sm\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .sm\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .sm\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .sm\:hover\:-translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .sm\:hover\:translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .sm\:hover\:translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .sm\:hover\:translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .sm\:hover\:translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .sm\:hover\:translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .sm\:hover\:translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .sm\:hover\:translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .sm\:hover\:translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .sm\:hover\:translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .sm\:hover\:translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .sm\:hover\:translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .sm\:hover\:translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .sm\:hover\:translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .sm\:hover\:translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .sm\:hover\:translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .sm\:hover\:translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .sm\:hover\:translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .sm\:hover\:translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .sm\:hover\:translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .sm\:hover\:translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .sm\:hover\:translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .sm\:hover\:translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .sm\:hover\:translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .sm\:hover\:translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .sm\:hover\:translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .sm\:hover\:translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .sm\:hover\:translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .sm\:hover\:translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .sm\:hover\:translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .sm\:hover\:translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .sm\:hover\:translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .sm\:hover\:translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .sm\:hover\:translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .sm\:hover\:translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .sm\:hover\:translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .sm\:hover\:-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .sm\:hover\:-translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .sm\:hover\:-translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .sm\:hover\:-translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .sm\:hover\:-translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .sm\:hover\:-translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .sm\:hover\:-translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .sm\:hover\:-translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .sm\:hover\:-translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .sm\:hover\:-translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .sm\:hover\:-translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .sm\:hover\:-translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .sm\:hover\:-translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .sm\:hover\:-translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .sm\:hover\:-translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .sm\:hover\:-translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .sm\:hover\:-translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .sm\:hover\:-translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .sm\:hover\:-translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .sm\:hover\:-translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .sm\:hover\:-translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .sm\:hover\:-translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .sm\:hover\:-translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .sm\:hover\:-translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .sm\:hover\:-translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .sm\:hover\:-translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .sm\:hover\:-translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .sm\:hover\:-translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .sm\:hover\:-translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .sm\:hover\:-translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .sm\:hover\:-translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .sm\:hover\:-translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .sm\:hover\:-translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .sm\:hover\:-translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .sm\:hover\:-translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .sm\:hover\:translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .sm\:hover\:translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .sm\:hover\:translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .sm\:hover\:translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .sm\:hover\:translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .sm\:hover\:translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .sm\:hover\:translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .sm\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .sm\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .sm\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .sm\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .sm\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .sm\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .sm\:hover\:-translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .sm\:focus\:translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .sm\:focus\:translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .sm\:focus\:translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .sm\:focus\:translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .sm\:focus\:translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .sm\:focus\:translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .sm\:focus\:translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .sm\:focus\:translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .sm\:focus\:translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .sm\:focus\:translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .sm\:focus\:translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .sm\:focus\:translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .sm\:focus\:translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .sm\:focus\:translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .sm\:focus\:translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .sm\:focus\:translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .sm\:focus\:translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .sm\:focus\:translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .sm\:focus\:translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .sm\:focus\:translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .sm\:focus\:translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .sm\:focus\:translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .sm\:focus\:translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .sm\:focus\:translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .sm\:focus\:translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .sm\:focus\:translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .sm\:focus\:translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .sm\:focus\:translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .sm\:focus\:translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .sm\:focus\:translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .sm\:focus\:translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .sm\:focus\:translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .sm\:focus\:translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .sm\:focus\:translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .sm\:focus\:translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .sm\:focus\:-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .sm\:focus\:-translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .sm\:focus\:-translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .sm\:focus\:-translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .sm\:focus\:-translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .sm\:focus\:-translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .sm\:focus\:-translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .sm\:focus\:-translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .sm\:focus\:-translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .sm\:focus\:-translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .sm\:focus\:-translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .sm\:focus\:-translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .sm\:focus\:-translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .sm\:focus\:-translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .sm\:focus\:-translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .sm\:focus\:-translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .sm\:focus\:-translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .sm\:focus\:-translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .sm\:focus\:-translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .sm\:focus\:-translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .sm\:focus\:-translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .sm\:focus\:-translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .sm\:focus\:-translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .sm\:focus\:-translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .sm\:focus\:-translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .sm\:focus\:-translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .sm\:focus\:-translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .sm\:focus\:-translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .sm\:focus\:-translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .sm\:focus\:-translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .sm\:focus\:-translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .sm\:focus\:-translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .sm\:focus\:-translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .sm\:focus\:-translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .sm\:focus\:-translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .sm\:focus\:translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .sm\:focus\:translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .sm\:focus\:translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .sm\:focus\:translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .sm\:focus\:translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .sm\:focus\:translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .sm\:focus\:translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .sm\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .sm\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .sm\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .sm\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .sm\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .sm\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .sm\:focus\:-translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .sm\:focus\:translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .sm\:focus\:translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .sm\:focus\:translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .sm\:focus\:translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .sm\:focus\:translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .sm\:focus\:translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .sm\:focus\:translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .sm\:focus\:translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .sm\:focus\:translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .sm\:focus\:translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .sm\:focus\:translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .sm\:focus\:translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .sm\:focus\:translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .sm\:focus\:translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .sm\:focus\:translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .sm\:focus\:translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .sm\:focus\:translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .sm\:focus\:translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .sm\:focus\:translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .sm\:focus\:translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .sm\:focus\:translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .sm\:focus\:translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .sm\:focus\:translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .sm\:focus\:translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .sm\:focus\:translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .sm\:focus\:translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .sm\:focus\:translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .sm\:focus\:translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .sm\:focus\:translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .sm\:focus\:translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .sm\:focus\:translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .sm\:focus\:translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .sm\:focus\:translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .sm\:focus\:translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .sm\:focus\:translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .sm\:focus\:-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .sm\:focus\:-translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .sm\:focus\:-translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .sm\:focus\:-translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .sm\:focus\:-translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .sm\:focus\:-translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .sm\:focus\:-translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .sm\:focus\:-translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .sm\:focus\:-translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .sm\:focus\:-translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .sm\:focus\:-translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .sm\:focus\:-translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .sm\:focus\:-translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .sm\:focus\:-translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .sm\:focus\:-translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .sm\:focus\:-translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .sm\:focus\:-translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .sm\:focus\:-translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .sm\:focus\:-translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .sm\:focus\:-translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .sm\:focus\:-translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .sm\:focus\:-translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .sm\:focus\:-translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .sm\:focus\:-translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .sm\:focus\:-translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .sm\:focus\:-translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .sm\:focus\:-translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .sm\:focus\:-translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .sm\:focus\:-translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .sm\:focus\:-translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .sm\:focus\:-translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .sm\:focus\:-translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .sm\:focus\:-translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .sm\:focus\:-translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .sm\:focus\:-translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .sm\:focus\:translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .sm\:focus\:translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .sm\:focus\:translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .sm\:focus\:translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .sm\:focus\:translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .sm\:focus\:translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .sm\:focus\:translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .sm\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .sm\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .sm\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .sm\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .sm\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .sm\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .sm\:focus\:-translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .sm\:rotate-0{
    --tw-rotate: 0deg !important;
  }

  .sm\:rotate-1{
    --tw-rotate: 1deg !important;
  }

  .sm\:rotate-2{
    --tw-rotate: 2deg !important;
  }

  .sm\:rotate-3{
    --tw-rotate: 3deg !important;
  }

  .sm\:rotate-6{
    --tw-rotate: 6deg !important;
  }

  .sm\:rotate-12{
    --tw-rotate: 12deg !important;
  }

  .sm\:rotate-45{
    --tw-rotate: 45deg !important;
  }

  .sm\:rotate-90{
    --tw-rotate: 90deg !important;
  }

  .sm\:rotate-180{
    --tw-rotate: 180deg !important;
  }

  .sm\:-rotate-180{
    --tw-rotate: -180deg !important;
  }

  .sm\:-rotate-90{
    --tw-rotate: -90deg !important;
  }

  .sm\:-rotate-45{
    --tw-rotate: -45deg !important;
  }

  .sm\:-rotate-12{
    --tw-rotate: -12deg !important;
  }

  .sm\:-rotate-6{
    --tw-rotate: -6deg !important;
  }

  .sm\:-rotate-3{
    --tw-rotate: -3deg !important;
  }

  .sm\:-rotate-2{
    --tw-rotate: -2deg !important;
  }

  .sm\:-rotate-1{
    --tw-rotate: -1deg !important;
  }

  .sm\:hover\:rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .sm\:hover\:rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .sm\:hover\:rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .sm\:hover\:rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .sm\:hover\:rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .sm\:hover\:rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .sm\:hover\:rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .sm\:hover\:rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .sm\:hover\:rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .sm\:hover\:-rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .sm\:hover\:-rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .sm\:hover\:-rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .sm\:hover\:-rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .sm\:hover\:-rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .sm\:hover\:-rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .sm\:hover\:-rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .sm\:hover\:-rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .sm\:focus\:rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .sm\:focus\:rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .sm\:focus\:rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .sm\:focus\:rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .sm\:focus\:rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .sm\:focus\:rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .sm\:focus\:rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .sm\:focus\:rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .sm\:focus\:rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .sm\:focus\:-rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .sm\:focus\:-rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .sm\:focus\:-rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .sm\:focus\:-rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .sm\:focus\:-rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .sm\:focus\:-rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .sm\:focus\:-rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .sm\:focus\:-rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .sm\:skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .sm\:skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .sm\:skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .sm\:skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .sm\:skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .sm\:skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .sm\:-skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .sm\:-skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .sm\:-skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .sm\:-skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .sm\:-skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .sm\:skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .sm\:skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .sm\:skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .sm\:skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .sm\:skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .sm\:skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .sm\:-skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .sm\:-skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .sm\:-skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .sm\:-skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .sm\:-skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .sm\:hover\:skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .sm\:hover\:skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .sm\:hover\:skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .sm\:hover\:skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .sm\:hover\:skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .sm\:hover\:skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .sm\:hover\:-skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .sm\:hover\:-skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .sm\:hover\:-skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .sm\:hover\:-skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .sm\:hover\:-skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .sm\:hover\:skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .sm\:hover\:skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .sm\:hover\:skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .sm\:hover\:skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .sm\:hover\:skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .sm\:hover\:skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .sm\:hover\:-skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .sm\:hover\:-skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .sm\:hover\:-skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .sm\:hover\:-skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .sm\:hover\:-skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .sm\:focus\:skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .sm\:focus\:skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .sm\:focus\:skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .sm\:focus\:skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .sm\:focus\:skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .sm\:focus\:skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .sm\:focus\:-skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .sm\:focus\:-skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .sm\:focus\:-skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .sm\:focus\:-skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .sm\:focus\:-skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .sm\:focus\:skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .sm\:focus\:skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .sm\:focus\:skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .sm\:focus\:skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .sm\:focus\:skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .sm\:focus\:skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .sm\:focus\:-skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .sm\:focus\:-skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .sm\:focus\:-skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .sm\:focus\:-skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .sm\:focus\:-skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .sm\:scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .sm\:scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .sm\:scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .sm\:scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .sm\:scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .sm\:scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .sm\:scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .sm\:scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .sm\:scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .sm\:scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .sm\:hover\:scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .sm\:hover\:scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .sm\:hover\:scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .sm\:hover\:scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .sm\:hover\:scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .sm\:hover\:scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .sm\:hover\:scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .sm\:hover\:scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .sm\:hover\:scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .sm\:hover\:scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .sm\:focus\:scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .sm\:focus\:scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .sm\:focus\:scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .sm\:focus\:scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .sm\:focus\:scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .sm\:focus\:scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .sm\:focus\:scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .sm\:focus\:scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .sm\:focus\:scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .sm\:focus\:scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .sm\:scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .sm\:scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .sm\:scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .sm\:scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .sm\:scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .sm\:scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .sm\:scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .sm\:scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .sm\:scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .sm\:scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .sm\:scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .sm\:scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .sm\:scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .sm\:scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .sm\:scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .sm\:scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .sm\:scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .sm\:scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .sm\:scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .sm\:scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .sm\:hover\:scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .sm\:hover\:scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .sm\:hover\:scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .sm\:hover\:scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .sm\:hover\:scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .sm\:hover\:scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .sm\:hover\:scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .sm\:hover\:scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .sm\:hover\:scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .sm\:hover\:scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .sm\:hover\:scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .sm\:hover\:scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .sm\:hover\:scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .sm\:hover\:scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .sm\:hover\:scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .sm\:hover\:scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .sm\:hover\:scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .sm\:hover\:scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .sm\:hover\:scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .sm\:hover\:scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .sm\:focus\:scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .sm\:focus\:scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .sm\:focus\:scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .sm\:focus\:scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .sm\:focus\:scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .sm\:focus\:scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .sm\:focus\:scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .sm\:focus\:scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .sm\:focus\:scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .sm\:focus\:scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .sm\:focus\:scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .sm\:focus\:scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .sm\:focus\:scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .sm\:focus\:scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .sm\:focus\:scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .sm\:focus\:scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .sm\:focus\:scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .sm\:focus\:scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .sm\:focus\:scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .sm\:focus\:scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .sm\:animate-none{
    animation: none !important;
  }

  .sm\:animate-spin{
    animation: spin 1s linear infinite !important;
  }

  .sm\:animate-ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .sm\:animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .sm\:animate-bounce{
    animation: bounce 1s infinite !important;
  }

  .sm\:cursor-auto{
    cursor: auto !important;
  }

  .sm\:cursor-default{
    cursor: default !important;
  }

  .sm\:cursor-pointer{
    cursor: pointer !important;
  }

  .sm\:cursor-wait{
    cursor: wait !important;
  }

  .sm\:cursor-text{
    cursor: text !important;
  }

  .sm\:cursor-move{
    cursor: move !important;
  }

  .sm\:cursor-help{
    cursor: help !important;
  }

  .sm\:cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .sm\:select-none{
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .sm\:select-text{
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .sm\:select-all{
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .sm\:select-auto{
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .sm\:resize-none{
    resize: none !important;
  }

  .sm\:resize-y{
    resize: vertical !important;
  }

  .sm\:resize-x{
    resize: horizontal !important;
  }

  .sm\:resize{
    resize: both !important;
  }

  .sm\:list-inside{
    list-style-position: inside !important;
  }

  .sm\:list-outside{
    list-style-position: outside !important;
  }

  .sm\:list-none{
    list-style-type: none !important;
  }

  .sm\:list-disc{
    list-style-type: disc !important;
  }

  .sm\:list-decimal{
    list-style-type: decimal !important;
  }

  .sm\:appearance-none{
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .sm\:auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .sm\:auto-cols-min{
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .sm\:auto-cols-max{
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .sm\:auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .sm\:grid-flow-row{
    grid-auto-flow: row !important;
  }

  .sm\:grid-flow-col{
    grid-auto-flow: column !important;
  }

  .sm\:grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .sm\:grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .sm\:auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .sm\:auto-rows-min{
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .sm\:auto-rows-max{
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .sm\:auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .sm\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-none{
    grid-template-columns: none !important;
  }

  .sm\:grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-none{
    grid-template-rows: none !important;
  }

  .sm\:flex-row{
    flex-direction: row !important;
  }

  .sm\:flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .sm\:flex-col{
    flex-direction: column !important;
  }

  .sm\:flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .sm\:flex-wrap{
    flex-wrap: wrap !important;
  }

  .sm\:flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .sm\:flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .sm\:place-content-center{
    place-content: center !important;
  }

  .sm\:place-content-start{
    place-content: start !important;
  }

  .sm\:place-content-end{
    place-content: end !important;
  }

  .sm\:place-content-between{
    place-content: space-between !important;
  }

  .sm\:place-content-around{
    place-content: space-around !important;
  }

  .sm\:place-content-evenly{
    place-content: space-evenly !important;
  }

  .sm\:place-content-stretch{
    place-content: stretch !important;
  }

  .sm\:place-items-start{
    place-items: start !important;
  }

  .sm\:place-items-end{
    place-items: end !important;
  }

  .sm\:place-items-center{
    place-items: center !important;
  }

  .sm\:place-items-stretch{
    place-items: stretch !important;
  }

  .sm\:content-center{
    align-content: center !important;
  }

  .sm\:content-start{
    align-content: flex-start !important;
  }

  .sm\:content-end{
    align-content: flex-end !important;
  }

  .sm\:content-between{
    align-content: space-between !important;
  }

  .sm\:content-around{
    align-content: space-around !important;
  }

  .sm\:content-evenly{
    align-content: space-evenly !important;
  }

  .sm\:items-start{
    align-items: flex-start !important;
  }

  .sm\:items-end{
    align-items: flex-end !important;
  }

  .sm\:items-center{
    align-items: center !important;
  }

  .sm\:items-baseline{
    align-items: baseline !important;
  }

  .sm\:items-stretch{
    align-items: stretch !important;
  }

  .sm\:justify-start{
    justify-content: flex-start !important;
  }

  .sm\:justify-end{
    justify-content: flex-end !important;
  }

  .sm\:justify-center{
    justify-content: center !important;
  }

  .sm\:justify-between{
    justify-content: space-between !important;
  }

  .sm\:justify-around{
    justify-content: space-around !important;
  }

  .sm\:justify-evenly{
    justify-content: space-evenly !important;
  }

  .sm\:justify-items-start{
    justify-items: start !important;
  }

  .sm\:justify-items-end{
    justify-items: end !important;
  }

  .sm\:justify-items-center{
    justify-items: center !important;
  }

  .sm\:justify-items-stretch{
    justify-items: stretch !important;
  }

  .sm\:gap-0{
    gap: 0px !important;
  }

  .sm\:gap-1{
    gap: 0.25rem !important;
  }

  .sm\:gap-2{
    gap: 0.5rem !important;
  }

  .sm\:gap-3{
    gap: 0.75rem !important;
  }

  .sm\:gap-4{
    gap: 1rem !important;
  }

  .sm\:gap-5{
    gap: 1.25rem !important;
  }

  .sm\:gap-6{
    gap: 1.5rem !important;
  }

  .sm\:gap-7{
    gap: 1.75rem !important;
  }

  .sm\:gap-8{
    gap: 2rem !important;
  }

  .sm\:gap-9{
    gap: 2.25rem !important;
  }

  .sm\:gap-10{
    gap: 2.5rem !important;
  }

  .sm\:gap-11{
    gap: 2.75rem !important;
  }

  .sm\:gap-12{
    gap: 3rem !important;
  }

  .sm\:gap-14{
    gap: 3.5rem !important;
  }

  .sm\:gap-16{
    gap: 4rem !important;
  }

  .sm\:gap-20{
    gap: 5rem !important;
  }

  .sm\:gap-24{
    gap: 6rem !important;
  }

  .sm\:gap-28{
    gap: 7rem !important;
  }

  .sm\:gap-32{
    gap: 8rem !important;
  }

  .sm\:gap-36{
    gap: 9rem !important;
  }

  .sm\:gap-40{
    gap: 10rem !important;
  }

  .sm\:gap-44{
    gap: 11rem !important;
  }

  .sm\:gap-48{
    gap: 12rem !important;
  }

  .sm\:gap-52{
    gap: 13rem !important;
  }

  .sm\:gap-56{
    gap: 14rem !important;
  }

  .sm\:gap-60{
    gap: 15rem !important;
  }

  .sm\:gap-64{
    gap: 16rem !important;
  }

  .sm\:gap-72{
    gap: 18rem !important;
  }

  .sm\:gap-80{
    gap: 20rem !important;
  }

  .sm\:gap-96{
    gap: 24rem !important;
  }

  .sm\:gap-px{
    gap: 1px !important;
  }

  .sm\:gap-0\.5{
    gap: 0.125rem !important;
  }

  .sm\:gap-1\.5{
    gap: 0.375rem !important;
  }

  .sm\:gap-2\.5{
    gap: 0.625rem !important;
  }

  .sm\:gap-3\.5{
    gap: 0.875rem !important;
  }

  .sm\:gap-x-0{
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .sm\:gap-x-1{
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .sm\:gap-x-2{
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .sm\:gap-x-3{
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .sm\:gap-x-4{
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .sm\:gap-x-5{
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .sm\:gap-x-6{
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .sm\:gap-x-7{
    -webkit-column-gap: 1.75rem !important;
            column-gap: 1.75rem !important;
  }

  .sm\:gap-x-8{
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .sm\:gap-x-9{
    -webkit-column-gap: 2.25rem !important;
            column-gap: 2.25rem !important;
  }

  .sm\:gap-x-10{
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .sm\:gap-x-11{
    -webkit-column-gap: 2.75rem !important;
            column-gap: 2.75rem !important;
  }

  .sm\:gap-x-12{
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .sm\:gap-x-14{
    -webkit-column-gap: 3.5rem !important;
            column-gap: 3.5rem !important;
  }

  .sm\:gap-x-16{
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .sm\:gap-x-20{
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .sm\:gap-x-24{
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .sm\:gap-x-28{
    -webkit-column-gap: 7rem !important;
            column-gap: 7rem !important;
  }

  .sm\:gap-x-32{
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .sm\:gap-x-36{
    -webkit-column-gap: 9rem !important;
            column-gap: 9rem !important;
  }

  .sm\:gap-x-40{
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .sm\:gap-x-44{
    -webkit-column-gap: 11rem !important;
            column-gap: 11rem !important;
  }

  .sm\:gap-x-48{
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .sm\:gap-x-52{
    -webkit-column-gap: 13rem !important;
            column-gap: 13rem !important;
  }

  .sm\:gap-x-56{
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .sm\:gap-x-60{
    -webkit-column-gap: 15rem !important;
            column-gap: 15rem !important;
  }

  .sm\:gap-x-64{
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .sm\:gap-x-72{
    -webkit-column-gap: 18rem !important;
            column-gap: 18rem !important;
  }

  .sm\:gap-x-80{
    -webkit-column-gap: 20rem !important;
            column-gap: 20rem !important;
  }

  .sm\:gap-x-96{
    -webkit-column-gap: 24rem !important;
            column-gap: 24rem !important;
  }

  .sm\:gap-x-px{
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .sm\:gap-x-0\.5{
    -webkit-column-gap: 0.125rem !important;
            column-gap: 0.125rem !important;
  }

  .sm\:gap-x-1\.5{
    -webkit-column-gap: 0.375rem !important;
            column-gap: 0.375rem !important;
  }

  .sm\:gap-x-2\.5{
    -webkit-column-gap: 0.625rem !important;
            column-gap: 0.625rem !important;
  }

  .sm\:gap-x-3\.5{
    -webkit-column-gap: 0.875rem !important;
            column-gap: 0.875rem !important;
  }

  .sm\:gap-y-0{
    row-gap: 0px !important;
  }

  .sm\:gap-y-1{
    row-gap: 0.25rem !important;
  }

  .sm\:gap-y-2{
    row-gap: 0.5rem !important;
  }

  .sm\:gap-y-3{
    row-gap: 0.75rem !important;
  }

  .sm\:gap-y-4{
    row-gap: 1rem !important;
  }

  .sm\:gap-y-5{
    row-gap: 1.25rem !important;
  }

  .sm\:gap-y-6{
    row-gap: 1.5rem !important;
  }

  .sm\:gap-y-7{
    row-gap: 1.75rem !important;
  }

  .sm\:gap-y-8{
    row-gap: 2rem !important;
  }

  .sm\:gap-y-9{
    row-gap: 2.25rem !important;
  }

  .sm\:gap-y-10{
    row-gap: 2.5rem !important;
  }

  .sm\:gap-y-11{
    row-gap: 2.75rem !important;
  }

  .sm\:gap-y-12{
    row-gap: 3rem !important;
  }

  .sm\:gap-y-14{
    row-gap: 3.5rem !important;
  }

  .sm\:gap-y-16{
    row-gap: 4rem !important;
  }

  .sm\:gap-y-20{
    row-gap: 5rem !important;
  }

  .sm\:gap-y-24{
    row-gap: 6rem !important;
  }

  .sm\:gap-y-28{
    row-gap: 7rem !important;
  }

  .sm\:gap-y-32{
    row-gap: 8rem !important;
  }

  .sm\:gap-y-36{
    row-gap: 9rem !important;
  }

  .sm\:gap-y-40{
    row-gap: 10rem !important;
  }

  .sm\:gap-y-44{
    row-gap: 11rem !important;
  }

  .sm\:gap-y-48{
    row-gap: 12rem !important;
  }

  .sm\:gap-y-52{
    row-gap: 13rem !important;
  }

  .sm\:gap-y-56{
    row-gap: 14rem !important;
  }

  .sm\:gap-y-60{
    row-gap: 15rem !important;
  }

  .sm\:gap-y-64{
    row-gap: 16rem !important;
  }

  .sm\:gap-y-72{
    row-gap: 18rem !important;
  }

  .sm\:gap-y-80{
    row-gap: 20rem !important;
  }

  .sm\:gap-y-96{
    row-gap: 24rem !important;
  }

  .sm\:gap-y-px{
    row-gap: 1px !important;
  }

  .sm\:gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .sm\:gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .sm\:gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .sm\:gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .sm\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .sm\:divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .sm\:divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-qipGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-portalGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-divide-opacity)) !important;
  }

  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .sm\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .sm\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .sm\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .sm\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .sm\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .sm\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .sm\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .sm\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .sm\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .sm\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .sm\:place-self-auto{
    place-self: auto !important;
  }

  .sm\:place-self-start{
    place-self: start !important;
  }

  .sm\:place-self-end{
    place-self: end !important;
  }

  .sm\:place-self-center{
    place-self: center !important;
  }

  .sm\:place-self-stretch{
    place-self: stretch !important;
  }

  .sm\:self-auto{
    align-self: auto !important;
  }

  .sm\:self-start{
    align-self: flex-start !important;
  }

  .sm\:self-end{
    align-self: flex-end !important;
  }

  .sm\:self-center{
    align-self: center !important;
  }

  .sm\:self-stretch{
    align-self: stretch !important;
  }

  .sm\:self-baseline{
    align-self: baseline !important;
  }

  .sm\:justify-self-auto{
    justify-self: auto !important;
  }

  .sm\:justify-self-start{
    justify-self: start !important;
  }

  .sm\:justify-self-end{
    justify-self: end !important;
  }

  .sm\:justify-self-center{
    justify-self: center !important;
  }

  .sm\:justify-self-stretch{
    justify-self: stretch !important;
  }

  .sm\:overflow-auto{
    overflow: auto !important;
  }

  .sm\:overflow-hidden{
    overflow: hidden !important;
  }

  .sm\:overflow-visible{
    overflow: visible !important;
  }

  .sm\:overflow-scroll{
    overflow: scroll !important;
  }

  .sm\:overflow-x-auto{
    overflow-x: auto !important;
  }

  .sm\:overflow-y-auto{
    overflow-y: auto !important;
  }

  .sm\:overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .sm\:overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .sm\:overflow-x-visible{
    overflow-x: visible !important;
  }

  .sm\:overflow-y-visible{
    overflow-y: visible !important;
  }

  .sm\:overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .sm\:overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .sm\:overscroll-auto{
    -ms-scroll-chaining: chained !important;
        overscroll-behavior: auto !important;
  }

  .sm\:overscroll-contain{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: contain !important;
  }

  .sm\:overscroll-none{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: none !important;
  }

  .sm\:overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .sm\:overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .sm\:overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .sm\:overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .sm\:overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .sm\:overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .sm\:truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .sm\:overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .sm\:overflow-clip{
    text-overflow: clip !important;
  }

  .sm\:whitespace-normal{
    white-space: normal !important;
  }

  .sm\:whitespace-nowrap{
    white-space: nowrap !important;
  }

  .sm\:whitespace-pre{
    white-space: pre !important;
  }

  .sm\:whitespace-pre-line{
    white-space: pre-line !important;
  }

  .sm\:whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .sm\:break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .sm\:break-words{
    overflow-wrap: break-word !important;
  }

  .sm\:break-all{
    word-break: break-all !important;
  }

  .sm\:rounded-none{
    border-radius: 0px !important;
  }

  .sm\:rounded-sm{
    border-radius: 0.125rem !important;
  }

  .sm\:rounded{
    border-radius: 0.25rem !important;
  }

  .sm\:rounded-md{
    border-radius: 0.375rem !important;
  }

  .sm\:rounded-lg{
    border-radius: 0.5rem !important;
  }

  .sm\:rounded-xl{
    border-radius: 0.75rem !important;
  }

  .sm\:rounded-2xl{
    border-radius: 1rem !important;
  }

  .sm\:rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .sm\:rounded-full{
    border-radius: 9999px !important;
  }

  .sm\:rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .sm\:rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .sm\:rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .sm\:rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .sm\:rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .sm\:rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .sm\:rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .sm\:rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .sm\:rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .sm\:rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .sm\:rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .sm\:rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .sm\:rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .sm\:rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .sm\:rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .sm\:rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .sm\:rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .sm\:rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .sm\:rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .sm\:rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .sm\:rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .sm\:rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .sm\:rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .sm\:rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .sm\:rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .sm\:rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:border-0{
    border-width: 0px !important;
  }

  .sm\:border-2{
    border-width: 2px !important;
  }

  .sm\:border-4{
    border-width: 4px !important;
  }

  .sm\:border-8{
    border-width: 8px !important;
  }

  .sm\:border{
    border-width: 1px !important;
  }

  .sm\:border-t-0{
    border-top-width: 0px !important;
  }

  .sm\:border-t-2{
    border-top-width: 2px !important;
  }

  .sm\:border-t-4{
    border-top-width: 4px !important;
  }

  .sm\:border-t-8{
    border-top-width: 8px !important;
  }

  .sm\:border-t{
    border-top-width: 1px !important;
  }

  .sm\:border-r-0{
    border-right-width: 0px !important;
  }

  .sm\:border-r-2{
    border-right-width: 2px !important;
  }

  .sm\:border-r-4{
    border-right-width: 4px !important;
  }

  .sm\:border-r-8{
    border-right-width: 8px !important;
  }

  .sm\:border-r{
    border-right-width: 1px !important;
  }

  .sm\:border-b-0{
    border-bottom-width: 0px !important;
  }

  .sm\:border-b-2{
    border-bottom-width: 2px !important;
  }

  .sm\:border-b-4{
    border-bottom-width: 4px !important;
  }

  .sm\:border-b-8{
    border-bottom-width: 8px !important;
  }

  .sm\:border-b{
    border-bottom-width: 1px !important;
  }

  .sm\:border-l-0{
    border-left-width: 0px !important;
  }

  .sm\:border-l-2{
    border-left-width: 2px !important;
  }

  .sm\:border-l-4{
    border-left-width: 4px !important;
  }

  .sm\:border-l-8{
    border-left-width: 8px !important;
  }

  .sm\:border-l{
    border-left-width: 1px !important;
  }

  .sm\:border-solid{
    border-style: solid !important;
  }

  .sm\:border-dashed{
    border-style: dashed !important;
  }

  .sm\:border-dotted{
    border-style: dotted !important;
  }

  .sm\:border-double{
    border-style: double !important;
  }

  .sm\:border-none{
    border-style: none !important;
  }

  .sm\:border-transparent{
    border-color: transparent !important;
  }

  .sm\:border-current{
    border-color: currentColor !important;
  }

  .sm\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-transparent{
    border-color: transparent !important;
  }

  .group:hover .sm\:group-hover\:border-current{
    border-color: currentColor !important;
  }

  .group:hover .sm\:group-hover\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-transparent:focus-within{
    border-color: transparent !important;
  }

  .sm\:focus-within\:border-current:focus-within{
    border-color: currentColor !important;
  }

  .sm\:focus-within\:border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-qipGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:focus-within\:border-portalGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-transparent:hover{
    border-color: transparent !important;
  }

  .sm\:hover\:border-current:hover{
    border-color: currentColor !important;
  }

  .sm\:hover\:border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-qipGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:hover\:border-portalGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-transparent:focus{
    border-color: transparent !important;
  }

  .sm\:focus\:border-current:focus{
    border-color: currentColor !important;
  }

  .sm\:focus\:border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-qipGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .sm\:focus\:border-portalGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .sm\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .sm\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .group:hover .sm\:group-hover\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .sm\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .sm\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .sm\:hover\:border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .sm\:hover\:border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:hover\:border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:hover\:border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:hover\:border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:hover\:border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:hover\:border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:hover\:border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:hover\:border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:hover\:border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:hover\:border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:hover\:border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:hover\:border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:hover\:border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:hover\:border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .sm\:focus\:border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .sm\:focus\:border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .sm\:focus\:border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .sm\:focus\:border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .sm\:focus\:border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .sm\:focus\:border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .sm\:focus\:border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .sm\:focus\:border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .sm\:focus\:border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .sm\:focus\:border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .sm\:focus\:border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .sm\:focus\:border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .sm\:focus\:border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .sm\:focus\:border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .sm\:focus\:border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .sm\:bg-transparent{
    background-color: transparent !important;
  }

  .sm\:bg-current{
    background-color: currentColor !important;
  }

  .sm\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-transparent{
    background-color: transparent !important;
  }

  .group:hover .sm\:group-hover\:bg-current{
    background-color: currentColor !important;
  }

  .group:hover .sm\:group-hover\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .sm\:focus-within\:bg-current:focus-within{
    background-color: currentColor !important;
  }

  .sm\:focus-within\:bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-qipGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus-within\:bg-portalGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-transparent:hover{
    background-color: transparent !important;
  }

  .sm\:hover\:bg-current:hover{
    background-color: currentColor !important;
  }

  .sm\:hover\:bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-qipGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:hover\:bg-portalGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-transparent:focus{
    background-color: transparent !important;
  }

  .sm\:focus\:bg-current:focus{
    background-color: currentColor !important;
  }

  .sm\:focus\:bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-qipGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .sm\:focus\:bg-portalGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .sm\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .group:hover .sm\:group-hover\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .sm\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .sm\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .sm\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .sm\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .sm\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .sm\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .sm\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .sm\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .sm\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .sm\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .sm\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .sm\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .sm\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .sm\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .sm\:bg-none{
    background-image: none !important;
  }

  .sm\:bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .sm\:bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .sm\:from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:from-qipGreen{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .sm\:from-portalGreen{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .sm\:hover\:from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:hover\:from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:hover\:from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:hover\:from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:hover\:from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:hover\:from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:hover\:from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:hover\:from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:hover\:from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:hover\:from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:hover\:from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:hover\:from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:hover\:from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:hover\:from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:hover\:from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:hover\:from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:hover\:from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:hover\:from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:hover\:from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:hover\:from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:hover\:from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:hover\:from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:hover\:from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:hover\:from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:hover\:from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:hover\:from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:hover\:from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:hover\:from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:hover\:from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:hover\:from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:hover\:from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:hover\:from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:hover\:from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:hover\:from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:hover\:from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:hover\:from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:hover\:from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:hover\:from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:hover\:from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:hover\:from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:hover\:from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:hover\:from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:hover\:from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:hover\:from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:hover\:from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:hover\:from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:hover\:from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:hover\:from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:hover\:from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:hover\:from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:hover\:from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:hover\:from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:hover\:from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:hover\:from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:hover\:from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:hover\:from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:hover\:from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:hover\:from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:hover\:from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:hover\:from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:hover\:from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:hover\:from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:hover\:from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:hover\:from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:hover\:from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:hover\:from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:hover\:from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:hover\:from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:hover\:from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:hover\:from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:hover\:from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:hover\:from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:hover\:from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:hover\:from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:hover\:from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:hover\:from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:hover\:from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:hover\:from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:hover\:from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:hover\:from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:hover\:from-qipGreen:hover{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .sm\:hover\:from-portalGreen:hover{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .sm\:focus\:from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:focus\:from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:focus\:from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:focus\:from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:focus\:from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:focus\:from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:focus\:from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:focus\:from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:focus\:from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:focus\:from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:focus\:from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:focus\:from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:focus\:from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:focus\:from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:focus\:from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:focus\:from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:focus\:from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:focus\:from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:focus\:from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:focus\:from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:focus\:from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:focus\:from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:focus\:from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:focus\:from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:focus\:from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:focus\:from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:focus\:from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:focus\:from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:focus\:from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:focus\:from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:focus\:from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:focus\:from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:focus\:from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:focus\:from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:focus\:from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:focus\:from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:focus\:from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:focus\:from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:focus\:from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:focus\:from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:focus\:from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:focus\:from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:focus\:from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:focus\:from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:focus\:from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:focus\:from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:focus\:from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:focus\:from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:focus\:from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:focus\:from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:focus\:from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:focus\:from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:focus\:from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:focus\:from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:focus\:from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:focus\:from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:focus\:from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:focus\:from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:focus\:from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:focus\:from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:focus\:from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:focus\:from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:focus\:from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:focus\:from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:focus\:from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:focus\:from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:focus\:from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:focus\:from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:focus\:from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:focus\:from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:focus\:from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:focus\:from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:focus\:from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:focus\:from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:focus\:from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:focus\:from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:focus\:from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:focus\:from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:focus\:from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:focus\:from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:focus\:from-qipGreen:focus{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .sm\:focus\:from-portalGreen:focus{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .sm\:via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:via-qipGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .sm\:via-portalGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .sm\:hover\:via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:hover\:via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:hover\:via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:hover\:via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:hover\:via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:hover\:via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:hover\:via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:hover\:via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:hover\:via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:hover\:via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:hover\:via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:hover\:via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:hover\:via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:hover\:via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:hover\:via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:hover\:via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:hover\:via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:hover\:via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:hover\:via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:hover\:via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:hover\:via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:hover\:via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:hover\:via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:hover\:via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:hover\:via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:hover\:via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:hover\:via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:hover\:via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:hover\:via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:hover\:via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:hover\:via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:hover\:via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:hover\:via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:hover\:via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:hover\:via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:hover\:via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:hover\:via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:hover\:via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:hover\:via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:hover\:via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:hover\:via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:hover\:via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:hover\:via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:hover\:via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:hover\:via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:hover\:via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:hover\:via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:hover\:via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:hover\:via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:hover\:via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:hover\:via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:hover\:via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:hover\:via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:hover\:via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:hover\:via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:hover\:via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:hover\:via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:hover\:via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:hover\:via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:hover\:via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:hover\:via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:hover\:via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:hover\:via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:hover\:via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:hover\:via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:hover\:via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:hover\:via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:hover\:via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:hover\:via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:hover\:via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:hover\:via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:hover\:via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:hover\:via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:hover\:via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:hover\:via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:hover\:via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:hover\:via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:hover\:via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:hover\:via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:hover\:via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:hover\:via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:hover\:via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:hover\:via-qipGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .sm\:hover\:via-portalGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .sm\:focus\:via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .sm\:focus\:via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .sm\:focus\:via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .sm\:focus\:via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .sm\:focus\:via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .sm\:focus\:via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .sm\:focus\:via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .sm\:focus\:via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .sm\:focus\:via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .sm\:focus\:via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .sm\:focus\:via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .sm\:focus\:via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .sm\:focus\:via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .sm\:focus\:via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .sm\:focus\:via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .sm\:focus\:via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .sm\:focus\:via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .sm\:focus\:via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .sm\:focus\:via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .sm\:focus\:via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .sm\:focus\:via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .sm\:focus\:via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .sm\:focus\:via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .sm\:focus\:via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .sm\:focus\:via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .sm\:focus\:via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .sm\:focus\:via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .sm\:focus\:via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .sm\:focus\:via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .sm\:focus\:via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .sm\:focus\:via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .sm\:focus\:via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .sm\:focus\:via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .sm\:focus\:via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .sm\:focus\:via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .sm\:focus\:via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .sm\:focus\:via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .sm\:focus\:via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .sm\:focus\:via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .sm\:focus\:via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .sm\:focus\:via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .sm\:focus\:via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .sm\:focus\:via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .sm\:focus\:via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .sm\:focus\:via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .sm\:focus\:via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .sm\:focus\:via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .sm\:focus\:via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .sm\:focus\:via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .sm\:focus\:via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .sm\:focus\:via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .sm\:focus\:via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .sm\:focus\:via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .sm\:focus\:via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .sm\:focus\:via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .sm\:focus\:via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .sm\:focus\:via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .sm\:focus\:via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .sm\:focus\:via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .sm\:focus\:via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .sm\:focus\:via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .sm\:focus\:via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .sm\:focus\:via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .sm\:focus\:via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .sm\:focus\:via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .sm\:focus\:via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .sm\:focus\:via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .sm\:focus\:via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .sm\:focus\:via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .sm\:focus\:via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .sm\:focus\:via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .sm\:focus\:via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .sm\:focus\:via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .sm\:focus\:via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .sm\:focus\:via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .sm\:focus\:via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .sm\:focus\:via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .sm\:focus\:via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .sm\:focus\:via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .sm\:focus\:via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .sm\:focus\:via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .sm\:focus\:via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .sm\:focus\:via-qipGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .sm\:focus\:via-portalGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .sm\:to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .sm\:to-current{
    --tw-gradient-to: currentColor !important;
  }

  .sm\:to-black{
    --tw-gradient-to: #000 !important;
  }

  .sm\:to-white{
    --tw-gradient-to: #fff !important;
  }

  .sm\:to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .sm\:to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .sm\:to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .sm\:to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .sm\:to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .sm\:to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .sm\:to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .sm\:to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .sm\:to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .sm\:to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .sm\:to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .sm\:to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .sm\:to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .sm\:to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .sm\:to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .sm\:to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .sm\:to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .sm\:to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .sm\:to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .sm\:to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .sm\:to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .sm\:to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .sm\:to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .sm\:to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .sm\:to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .sm\:to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .sm\:to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .sm\:to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .sm\:to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .sm\:to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .sm\:to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .sm\:to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .sm\:to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .sm\:to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .sm\:to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .sm\:to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .sm\:to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .sm\:to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .sm\:to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .sm\:to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .sm\:to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .sm\:to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .sm\:to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .sm\:to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .sm\:to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .sm\:to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .sm\:to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .sm\:to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .sm\:to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .sm\:to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .sm\:to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .sm\:to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .sm\:to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .sm\:to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .sm\:to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .sm\:to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .sm\:to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .sm\:to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .sm\:to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .sm\:to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .sm\:to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .sm\:to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .sm\:to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .sm\:to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .sm\:to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .sm\:to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .sm\:to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .sm\:to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .sm\:to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .sm\:to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .sm\:to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .sm\:to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .sm\:to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .sm\:to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .sm\:to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .sm\:to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .sm\:to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .sm\:to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .sm\:to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .sm\:to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .sm\:to-qipGreen{
    --tw-gradient-to: #8bc334 !important;
  }

  .sm\:to-portalGreen{
    --tw-gradient-to: #8ac04a !important;
  }

  .sm\:hover\:to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .sm\:hover\:to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .sm\:hover\:to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .sm\:hover\:to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .sm\:hover\:to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .sm\:hover\:to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .sm\:hover\:to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .sm\:hover\:to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .sm\:hover\:to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .sm\:hover\:to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .sm\:hover\:to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .sm\:hover\:to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .sm\:hover\:to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .sm\:hover\:to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .sm\:hover\:to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .sm\:hover\:to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .sm\:hover\:to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .sm\:hover\:to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .sm\:hover\:to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .sm\:hover\:to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .sm\:hover\:to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .sm\:hover\:to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .sm\:hover\:to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .sm\:hover\:to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .sm\:hover\:to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .sm\:hover\:to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .sm\:hover\:to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .sm\:hover\:to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .sm\:hover\:to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .sm\:hover\:to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .sm\:hover\:to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .sm\:hover\:to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .sm\:hover\:to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .sm\:hover\:to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .sm\:hover\:to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .sm\:hover\:to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .sm\:hover\:to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .sm\:hover\:to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .sm\:hover\:to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .sm\:hover\:to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .sm\:hover\:to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .sm\:hover\:to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .sm\:hover\:to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .sm\:hover\:to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .sm\:hover\:to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .sm\:hover\:to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .sm\:hover\:to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .sm\:hover\:to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .sm\:hover\:to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .sm\:hover\:to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .sm\:hover\:to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .sm\:hover\:to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .sm\:hover\:to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .sm\:hover\:to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .sm\:hover\:to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .sm\:hover\:to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .sm\:hover\:to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .sm\:hover\:to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .sm\:hover\:to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .sm\:hover\:to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .sm\:hover\:to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .sm\:hover\:to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .sm\:hover\:to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .sm\:hover\:to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .sm\:hover\:to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .sm\:hover\:to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .sm\:hover\:to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .sm\:hover\:to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .sm\:hover\:to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .sm\:hover\:to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .sm\:hover\:to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .sm\:hover\:to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .sm\:hover\:to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .sm\:hover\:to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .sm\:hover\:to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .sm\:hover\:to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .sm\:hover\:to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .sm\:hover\:to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .sm\:hover\:to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .sm\:hover\:to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .sm\:hover\:to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .sm\:hover\:to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .sm\:hover\:to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .sm\:hover\:to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .sm\:hover\:to-qipGreen:hover{
    --tw-gradient-to: #8bc334 !important;
  }

  .sm\:hover\:to-portalGreen:hover{
    --tw-gradient-to: #8ac04a !important;
  }

  .sm\:focus\:to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .sm\:focus\:to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .sm\:focus\:to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .sm\:focus\:to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .sm\:focus\:to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .sm\:focus\:to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .sm\:focus\:to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .sm\:focus\:to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .sm\:focus\:to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .sm\:focus\:to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .sm\:focus\:to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .sm\:focus\:to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .sm\:focus\:to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .sm\:focus\:to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .sm\:focus\:to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .sm\:focus\:to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .sm\:focus\:to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .sm\:focus\:to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .sm\:focus\:to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .sm\:focus\:to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .sm\:focus\:to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .sm\:focus\:to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .sm\:focus\:to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .sm\:focus\:to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .sm\:focus\:to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .sm\:focus\:to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .sm\:focus\:to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .sm\:focus\:to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .sm\:focus\:to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .sm\:focus\:to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .sm\:focus\:to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .sm\:focus\:to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .sm\:focus\:to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .sm\:focus\:to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .sm\:focus\:to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .sm\:focus\:to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .sm\:focus\:to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .sm\:focus\:to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .sm\:focus\:to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .sm\:focus\:to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .sm\:focus\:to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .sm\:focus\:to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .sm\:focus\:to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .sm\:focus\:to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .sm\:focus\:to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .sm\:focus\:to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .sm\:focus\:to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .sm\:focus\:to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .sm\:focus\:to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .sm\:focus\:to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .sm\:focus\:to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .sm\:focus\:to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .sm\:focus\:to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .sm\:focus\:to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .sm\:focus\:to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .sm\:focus\:to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .sm\:focus\:to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .sm\:focus\:to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .sm\:focus\:to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .sm\:focus\:to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .sm\:focus\:to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .sm\:focus\:to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .sm\:focus\:to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .sm\:focus\:to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .sm\:focus\:to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .sm\:focus\:to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .sm\:focus\:to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .sm\:focus\:to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .sm\:focus\:to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .sm\:focus\:to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .sm\:focus\:to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .sm\:focus\:to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .sm\:focus\:to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .sm\:focus\:to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .sm\:focus\:to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .sm\:focus\:to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .sm\:focus\:to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .sm\:focus\:to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .sm\:focus\:to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .sm\:focus\:to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .sm\:focus\:to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .sm\:focus\:to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .sm\:focus\:to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .sm\:focus\:to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .sm\:focus\:to-qipGreen:focus{
    --tw-gradient-to: #8bc334 !important;
  }

  .sm\:focus\:to-portalGreen:focus{
    --tw-gradient-to: #8ac04a !important;
  }

  .sm\:decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .sm\:decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .sm\:bg-auto{
    background-size: auto !important;
  }

  .sm\:bg-cover{
    background-size: cover !important;
  }

  .sm\:bg-contain{
    background-size: contain !important;
  }

  .sm\:bg-fixed{
    background-attachment: fixed !important;
  }

  .sm\:bg-local{
    background-attachment: local !important;
  }

  .sm\:bg-scroll{
    background-attachment: scroll !important;
  }

  .sm\:bg-clip-border{
    background-clip: border-box !important;
  }

  .sm\:bg-clip-padding{
    background-clip: padding-box !important;
  }

  .sm\:bg-clip-content{
    background-clip: content-box !important;
  }

  .sm\:bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .sm\:bg-bottom{
    background-position: bottom !important;
  }

  .sm\:bg-center{
    background-position: center !important;
  }

  .sm\:bg-left{
    background-position: left !important;
  }

  .sm\:bg-left-bottom{
    background-position: left bottom !important;
  }

  .sm\:bg-left-top{
    background-position: left top !important;
  }

  .sm\:bg-right{
    background-position: right !important;
  }

  .sm\:bg-right-bottom{
    background-position: right bottom !important;
  }

  .sm\:bg-right-top{
    background-position: right top !important;
  }

  .sm\:bg-top{
    background-position: top !important;
  }

  .sm\:bg-repeat{
    background-repeat: repeat !important;
  }

  .sm\:bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .sm\:bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .sm\:bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .sm\:bg-repeat-round{
    background-repeat: round !important;
  }

  .sm\:bg-repeat-space{
    background-repeat: space !important;
  }

  .sm\:bg-origin-border{
    background-origin: border-box !important;
  }

  .sm\:bg-origin-padding{
    background-origin: padding-box !important;
  }

  .sm\:bg-origin-content{
    background-origin: content-box !important;
  }

  .sm\:fill-current{
    fill: currentColor !important;
  }

  .sm\:stroke-current{
    stroke: currentColor !important;
  }

  .sm\:stroke-0{
    stroke-width: 0 !important;
  }

  .sm\:stroke-1{
    stroke-width: 1 !important;
  }

  .sm\:stroke-2{
    stroke-width: 2 !important;
  }

  .sm\:object-contain{
    object-fit: contain !important;
  }

  .sm\:object-cover{
    object-fit: cover !important;
  }

  .sm\:object-fill{
    object-fit: fill !important;
  }

  .sm\:object-none{
    object-fit: none !important;
  }

  .sm\:object-scale-down{
    object-fit: scale-down !important;
  }

  .sm\:object-bottom{
    object-position: bottom !important;
  }

  .sm\:object-center{
    object-position: center !important;
  }

  .sm\:object-left{
    object-position: left !important;
  }

  .sm\:object-left-bottom{
    object-position: left bottom !important;
  }

  .sm\:object-left-top{
    object-position: left top !important;
  }

  .sm\:object-right{
    object-position: right !important;
  }

  .sm\:object-right-bottom{
    object-position: right bottom !important;
  }

  .sm\:object-right-top{
    object-position: right top !important;
  }

  .sm\:object-top{
    object-position: top !important;
  }

  .sm\:p-0{
    padding: 0px !important;
  }

  .sm\:p-1{
    padding: 0.25rem !important;
  }

  .sm\:p-2{
    padding: 0.5rem !important;
  }

  .sm\:p-3{
    padding: 0.75rem !important;
  }

  .sm\:p-4{
    padding: 1rem !important;
  }

  .sm\:p-5{
    padding: 1.25rem !important;
  }

  .sm\:p-6{
    padding: 1.5rem !important;
  }

  .sm\:p-7{
    padding: 1.75rem !important;
  }

  .sm\:p-8{
    padding: 2rem !important;
  }

  .sm\:p-9{
    padding: 2.25rem !important;
  }

  .sm\:p-10{
    padding: 2.5rem !important;
  }

  .sm\:p-11{
    padding: 2.75rem !important;
  }

  .sm\:p-12{
    padding: 3rem !important;
  }

  .sm\:p-14{
    padding: 3.5rem !important;
  }

  .sm\:p-16{
    padding: 4rem !important;
  }

  .sm\:p-20{
    padding: 5rem !important;
  }

  .sm\:p-24{
    padding: 6rem !important;
  }

  .sm\:p-28{
    padding: 7rem !important;
  }

  .sm\:p-32{
    padding: 8rem !important;
  }

  .sm\:p-36{
    padding: 9rem !important;
  }

  .sm\:p-40{
    padding: 10rem !important;
  }

  .sm\:p-44{
    padding: 11rem !important;
  }

  .sm\:p-48{
    padding: 12rem !important;
  }

  .sm\:p-52{
    padding: 13rem !important;
  }

  .sm\:p-56{
    padding: 14rem !important;
  }

  .sm\:p-60{
    padding: 15rem !important;
  }

  .sm\:p-64{
    padding: 16rem !important;
  }

  .sm\:p-72{
    padding: 18rem !important;
  }

  .sm\:p-80{
    padding: 20rem !important;
  }

  .sm\:p-96{
    padding: 24rem !important;
  }

  .sm\:p-px{
    padding: 1px !important;
  }

  .sm\:p-0\.5{
    padding: 0.125rem !important;
  }

  .sm\:p-1\.5{
    padding: 0.375rem !important;
  }

  .sm\:p-2\.5{
    padding: 0.625rem !important;
  }

  .sm\:p-3\.5{
    padding: 0.875rem !important;
  }

  .sm\:px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .sm\:px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .sm\:px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .sm\:px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .sm\:px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .sm\:px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .sm\:px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .sm\:px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .sm\:px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .sm\:px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .sm\:px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .sm\:px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .sm\:px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .sm\:px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .sm\:px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .sm\:px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .sm\:px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .sm\:px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .sm\:px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .sm\:px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .sm\:px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .sm\:px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .sm\:px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .sm\:px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .sm\:px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .sm\:px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .sm\:px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .sm\:px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .sm\:py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .sm\:py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .sm\:py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .sm\:py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .sm\:py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .sm\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .sm\:py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .sm\:py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .sm\:py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .sm\:py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .sm\:py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .sm\:py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .sm\:py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .sm\:py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .sm\:py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .sm\:py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .sm\:py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .sm\:py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .sm\:py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .sm\:py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .sm\:py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .sm\:py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .sm\:py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .sm\:py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .sm\:py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .sm\:py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .sm\:py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .sm\:py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .sm\:py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .sm\:pt-0{
    padding-top: 0px !important;
  }

  .sm\:pt-1{
    padding-top: 0.25rem !important;
  }

  .sm\:pt-2{
    padding-top: 0.5rem !important;
  }

  .sm\:pt-3{
    padding-top: 0.75rem !important;
  }

  .sm\:pt-4{
    padding-top: 1rem !important;
  }

  .sm\:pt-5{
    padding-top: 1.25rem !important;
  }

  .sm\:pt-6{
    padding-top: 1.5rem !important;
  }

  .sm\:pt-7{
    padding-top: 1.75rem !important;
  }

  .sm\:pt-8{
    padding-top: 2rem !important;
  }

  .sm\:pt-9{
    padding-top: 2.25rem !important;
  }

  .sm\:pt-10{
    padding-top: 2.5rem !important;
  }

  .sm\:pt-11{
    padding-top: 2.75rem !important;
  }

  .sm\:pt-12{
    padding-top: 3rem !important;
  }

  .sm\:pt-14{
    padding-top: 3.5rem !important;
  }

  .sm\:pt-16{
    padding-top: 4rem !important;
  }

  .sm\:pt-20{
    padding-top: 5rem !important;
  }

  .sm\:pt-24{
    padding-top: 6rem !important;
  }

  .sm\:pt-28{
    padding-top: 7rem !important;
  }

  .sm\:pt-32{
    padding-top: 8rem !important;
  }

  .sm\:pt-36{
    padding-top: 9rem !important;
  }

  .sm\:pt-40{
    padding-top: 10rem !important;
  }

  .sm\:pt-44{
    padding-top: 11rem !important;
  }

  .sm\:pt-48{
    padding-top: 12rem !important;
  }

  .sm\:pt-52{
    padding-top: 13rem !important;
  }

  .sm\:pt-56{
    padding-top: 14rem !important;
  }

  .sm\:pt-60{
    padding-top: 15rem !important;
  }

  .sm\:pt-64{
    padding-top: 16rem !important;
  }

  .sm\:pt-72{
    padding-top: 18rem !important;
  }

  .sm\:pt-80{
    padding-top: 20rem !important;
  }

  .sm\:pt-96{
    padding-top: 24rem !important;
  }

  .sm\:pt-px{
    padding-top: 1px !important;
  }

  .sm\:pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .sm\:pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .sm\:pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .sm\:pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .sm\:pr-0{
    padding-right: 0px !important;
  }

  .sm\:pr-1{
    padding-right: 0.25rem !important;
  }

  .sm\:pr-2{
    padding-right: 0.5rem !important;
  }

  .sm\:pr-3{
    padding-right: 0.75rem !important;
  }

  .sm\:pr-4{
    padding-right: 1rem !important;
  }

  .sm\:pr-5{
    padding-right: 1.25rem !important;
  }

  .sm\:pr-6{
    padding-right: 1.5rem !important;
  }

  .sm\:pr-7{
    padding-right: 1.75rem !important;
  }

  .sm\:pr-8{
    padding-right: 2rem !important;
  }

  .sm\:pr-9{
    padding-right: 2.25rem !important;
  }

  .sm\:pr-10{
    padding-right: 2.5rem !important;
  }

  .sm\:pr-11{
    padding-right: 2.75rem !important;
  }

  .sm\:pr-12{
    padding-right: 3rem !important;
  }

  .sm\:pr-14{
    padding-right: 3.5rem !important;
  }

  .sm\:pr-16{
    padding-right: 4rem !important;
  }

  .sm\:pr-20{
    padding-right: 5rem !important;
  }

  .sm\:pr-24{
    padding-right: 6rem !important;
  }

  .sm\:pr-28{
    padding-right: 7rem !important;
  }

  .sm\:pr-32{
    padding-right: 8rem !important;
  }

  .sm\:pr-36{
    padding-right: 9rem !important;
  }

  .sm\:pr-40{
    padding-right: 10rem !important;
  }

  .sm\:pr-44{
    padding-right: 11rem !important;
  }

  .sm\:pr-48{
    padding-right: 12rem !important;
  }

  .sm\:pr-52{
    padding-right: 13rem !important;
  }

  .sm\:pr-56{
    padding-right: 14rem !important;
  }

  .sm\:pr-60{
    padding-right: 15rem !important;
  }

  .sm\:pr-64{
    padding-right: 16rem !important;
  }

  .sm\:pr-72{
    padding-right: 18rem !important;
  }

  .sm\:pr-80{
    padding-right: 20rem !important;
  }

  .sm\:pr-96{
    padding-right: 24rem !important;
  }

  .sm\:pr-px{
    padding-right: 1px !important;
  }

  .sm\:pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .sm\:pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .sm\:pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .sm\:pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .sm\:pb-0{
    padding-bottom: 0px !important;
  }

  .sm\:pb-1{
    padding-bottom: 0.25rem !important;
  }

  .sm\:pb-2{
    padding-bottom: 0.5rem !important;
  }

  .sm\:pb-3{
    padding-bottom: 0.75rem !important;
  }

  .sm\:pb-4{
    padding-bottom: 1rem !important;
  }

  .sm\:pb-5{
    padding-bottom: 1.25rem !important;
  }

  .sm\:pb-6{
    padding-bottom: 1.5rem !important;
  }

  .sm\:pb-7{
    padding-bottom: 1.75rem !important;
  }

  .sm\:pb-8{
    padding-bottom: 2rem !important;
  }

  .sm\:pb-9{
    padding-bottom: 2.25rem !important;
  }

  .sm\:pb-10{
    padding-bottom: 2.5rem !important;
  }

  .sm\:pb-11{
    padding-bottom: 2.75rem !important;
  }

  .sm\:pb-12{
    padding-bottom: 3rem !important;
  }

  .sm\:pb-14{
    padding-bottom: 3.5rem !important;
  }

  .sm\:pb-16{
    padding-bottom: 4rem !important;
  }

  .sm\:pb-20{
    padding-bottom: 5rem !important;
  }

  .sm\:pb-24{
    padding-bottom: 6rem !important;
  }

  .sm\:pb-28{
    padding-bottom: 7rem !important;
  }

  .sm\:pb-32{
    padding-bottom: 8rem !important;
  }

  .sm\:pb-36{
    padding-bottom: 9rem !important;
  }

  .sm\:pb-40{
    padding-bottom: 10rem !important;
  }

  .sm\:pb-44{
    padding-bottom: 11rem !important;
  }

  .sm\:pb-48{
    padding-bottom: 12rem !important;
  }

  .sm\:pb-52{
    padding-bottom: 13rem !important;
  }

  .sm\:pb-56{
    padding-bottom: 14rem !important;
  }

  .sm\:pb-60{
    padding-bottom: 15rem !important;
  }

  .sm\:pb-64{
    padding-bottom: 16rem !important;
  }

  .sm\:pb-72{
    padding-bottom: 18rem !important;
  }

  .sm\:pb-80{
    padding-bottom: 20rem !important;
  }

  .sm\:pb-96{
    padding-bottom: 24rem !important;
  }

  .sm\:pb-px{
    padding-bottom: 1px !important;
  }

  .sm\:pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .sm\:pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .sm\:pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .sm\:pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .sm\:pl-0{
    padding-left: 0px !important;
  }

  .sm\:pl-1{
    padding-left: 0.25rem !important;
  }

  .sm\:pl-2{
    padding-left: 0.5rem !important;
  }

  .sm\:pl-3{
    padding-left: 0.75rem !important;
  }

  .sm\:pl-4{
    padding-left: 1rem !important;
  }

  .sm\:pl-5{
    padding-left: 1.25rem !important;
  }

  .sm\:pl-6{
    padding-left: 1.5rem !important;
  }

  .sm\:pl-7{
    padding-left: 1.75rem !important;
  }

  .sm\:pl-8{
    padding-left: 2rem !important;
  }

  .sm\:pl-9{
    padding-left: 2.25rem !important;
  }

  .sm\:pl-10{
    padding-left: 2.5rem !important;
  }

  .sm\:pl-11{
    padding-left: 2.75rem !important;
  }

  .sm\:pl-12{
    padding-left: 3rem !important;
  }

  .sm\:pl-14{
    padding-left: 3.5rem !important;
  }

  .sm\:pl-16{
    padding-left: 4rem !important;
  }

  .sm\:pl-20{
    padding-left: 5rem !important;
  }

  .sm\:pl-24{
    padding-left: 6rem !important;
  }

  .sm\:pl-28{
    padding-left: 7rem !important;
  }

  .sm\:pl-32{
    padding-left: 8rem !important;
  }

  .sm\:pl-36{
    padding-left: 9rem !important;
  }

  .sm\:pl-40{
    padding-left: 10rem !important;
  }

  .sm\:pl-44{
    padding-left: 11rem !important;
  }

  .sm\:pl-48{
    padding-left: 12rem !important;
  }

  .sm\:pl-52{
    padding-left: 13rem !important;
  }

  .sm\:pl-56{
    padding-left: 14rem !important;
  }

  .sm\:pl-60{
    padding-left: 15rem !important;
  }

  .sm\:pl-64{
    padding-left: 16rem !important;
  }

  .sm\:pl-72{
    padding-left: 18rem !important;
  }

  .sm\:pl-80{
    padding-left: 20rem !important;
  }

  .sm\:pl-96{
    padding-left: 24rem !important;
  }

  .sm\:pl-px{
    padding-left: 1px !important;
  }

  .sm\:pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .sm\:pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .sm\:pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .sm\:pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .sm\:text-left{
    text-align: left !important;
  }

  .sm\:text-center{
    text-align: center !important;
  }

  .sm\:text-right{
    text-align: right !important;
  }

  .sm\:text-justify{
    text-align: justify !important;
  }

  .sm\:align-baseline{
    vertical-align: baseline !important;
  }

  .sm\:align-top{
    vertical-align: top !important;
  }

  .sm\:align-middle{
    vertical-align: middle !important;
  }

  .sm\:align-bottom{
    vertical-align: bottom !important;
  }

  .sm\:align-text-top{
    vertical-align: text-top !important;
  }

  .sm\:align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .sm\:font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .sm\:font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .sm\:font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .sm\:text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .sm\:text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .sm\:text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .sm\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .sm\:text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .sm\:text-2xl{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .sm\:text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .sm\:text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .sm\:text-5xl{
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .sm\:text-6xl{
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .sm\:text-7xl{
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .sm\:text-8xl{
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .sm\:text-9xl{
    font-size: 8rem !important;
    line-height: 1 !important;
  }

  .sm\:font-thin{
    font-weight: 100 !important;
  }

  .sm\:font-extralight{
    font-weight: 200 !important;
  }

  .sm\:font-light{
    font-weight: 300 !important;
  }

  .sm\:font-normal{
    font-weight: 400 !important;
  }

  .sm\:font-medium{
    font-weight: 500 !important;
  }

  .sm\:font-semibold{
    font-weight: 600 !important;
  }

  .sm\:font-bold{
    font-weight: 700 !important;
  }

  .sm\:font-extrabold{
    font-weight: 800 !important;
  }

  .sm\:font-black{
    font-weight: 900 !important;
  }

  .sm\:uppercase{
    text-transform: uppercase !important;
  }

  .sm\:lowercase{
    text-transform: lowercase !important;
  }

  .sm\:capitalize{
    text-transform: capitalize !important;
  }

  .sm\:normal-case{
    text-transform: none !important;
  }

  .sm\:italic{
    font-style: italic !important;
  }

  .sm\:not-italic{
    font-style: normal !important;
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .sm\:normal-nums{
    font-variant-numeric: normal !important;
  }

  .sm\:ordinal{
    --tw-ordinal: ordinal !important;
  }

  .sm\:slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .sm\:lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .sm\:oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .sm\:proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .sm\:tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .sm\:diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .sm\:stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .sm\:leading-3{
    line-height: .75rem !important;
  }

  .sm\:leading-4{
    line-height: 1rem !important;
  }

  .sm\:leading-5{
    line-height: 1.25rem !important;
  }

  .sm\:leading-6{
    line-height: 1.5rem !important;
  }

  .sm\:leading-7{
    line-height: 1.75rem !important;
  }

  .sm\:leading-8{
    line-height: 2rem !important;
  }

  .sm\:leading-9{
    line-height: 2.25rem !important;
  }

  .sm\:leading-10{
    line-height: 2.5rem !important;
  }

  .sm\:leading-none{
    line-height: 1 !important;
  }

  .sm\:leading-tight{
    line-height: 1.25 !important;
  }

  .sm\:leading-snug{
    line-height: 1.375 !important;
  }

  .sm\:leading-normal{
    line-height: 1.5 !important;
  }

  .sm\:leading-relaxed{
    line-height: 1.625 !important;
  }

  .sm\:leading-loose{
    line-height: 2 !important;
  }

  .sm\:tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .sm\:tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .sm\:tracking-normal{
    letter-spacing: 0em !important;
  }

  .sm\:tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .sm\:tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .sm\:tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .sm\:text-transparent{
    color: transparent !important;
  }

  .sm\:text-current{
    color: currentColor !important;
  }

  .sm\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-transparent{
    color: transparent !important;
  }

  .group:hover .sm\:group-hover\:text-current{
    color: currentColor !important;
  }

  .group:hover .sm\:group-hover\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .group:hover .sm\:group-hover\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-transparent:focus-within{
    color: transparent !important;
  }

  .sm\:focus-within\:text-current:focus-within{
    color: currentColor !important;
  }

  .sm\:focus-within\:text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-qipGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:focus-within\:text-portalGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-transparent:hover{
    color: transparent !important;
  }

  .sm\:hover\:text-current:hover{
    color: currentColor !important;
  }

  .sm\:hover\:text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-qipGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:hover\:text-portalGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-transparent:focus{
    color: transparent !important;
  }

  .sm\:focus\:text-current:focus{
    color: currentColor !important;
  }

  .sm\:focus\:text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-qipGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .sm\:focus\:text-portalGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .sm\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .sm\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .group:hover .sm\:group-hover\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .sm\:focus-within\:text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .sm\:focus-within\:text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:focus-within\:text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:focus-within\:text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:focus-within\:text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:focus-within\:text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:focus-within\:text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:focus-within\:text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:focus-within\:text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:focus-within\:text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:focus-within\:text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:focus-within\:text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:focus-within\:text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:focus-within\:text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:focus-within\:text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .sm\:hover\:text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .sm\:hover\:text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:hover\:text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:hover\:text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:hover\:text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:hover\:text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:hover\:text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:hover\:text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:hover\:text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:hover\:text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:hover\:text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:hover\:text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:hover\:text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:hover\:text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:hover\:text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .sm\:focus\:text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .sm\:focus\:text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .sm\:focus\:text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .sm\:focus\:text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .sm\:focus\:text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .sm\:focus\:text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .sm\:focus\:text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .sm\:focus\:text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .sm\:focus\:text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .sm\:focus\:text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .sm\:focus\:text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .sm\:focus\:text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .sm\:focus\:text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .sm\:focus\:text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .sm\:focus\:text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .sm\:underline{
    text-decoration: underline !important;
  }

  .sm\:line-through{
    text-decoration: line-through !important;
  }

  .sm\:no-underline{
    text-decoration: none !important;
  }

  .group:hover .sm\:group-hover\:underline{
    text-decoration: underline !important;
  }

  .group:hover .sm\:group-hover\:line-through{
    text-decoration: line-through !important;
  }

  .group:hover .sm\:group-hover\:no-underline{
    text-decoration: none !important;
  }

  .sm\:focus-within\:underline:focus-within{
    text-decoration: underline !important;
  }

  .sm\:focus-within\:line-through:focus-within{
    text-decoration: line-through !important;
  }

  .sm\:focus-within\:no-underline:focus-within{
    text-decoration: none !important;
  }

  .sm\:hover\:underline:hover{
    text-decoration: underline !important;
  }

  .sm\:hover\:line-through:hover{
    text-decoration: line-through !important;
  }

  .sm\:hover\:no-underline:hover{
    text-decoration: none !important;
  }

  .sm\:focus\:underline:focus{
    text-decoration: underline !important;
  }

  .sm\:focus\:line-through:focus{
    text-decoration: line-through !important;
  }

  .sm\:focus\:no-underline:focus{
    text-decoration: none !important;
  }

  .sm\:antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .sm\:subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .sm\:placeholder-transparent::-webkit-input-placeholder{
    color: transparent !important;
  }

  .sm\:placeholder-transparent:-ms-input-placeholder{
    color: transparent !important;
  }

  .sm\:placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .sm\:placeholder-current::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .sm\:placeholder-current:-ms-input-placeholder{
    color: currentColor !important;
  }

  .sm\:placeholder-current::placeholder{
    color: currentColor !important;
  }

  .sm\:placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-black:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-white:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-qipGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-qipGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-qipGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-portalGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-portalGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-portalGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent !important;
  }

  .sm\:focus\:placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent !important;
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .sm\:focus\:placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .sm\:focus\:placeholder-current:focus:-ms-input-placeholder{
    color: currentColor !important;
  }

  .sm\:focus\:placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .sm\:focus\:placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-black:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-white:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-qipGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-qipGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-qipGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-portalGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-portalGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:focus\:placeholder-portalGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .sm\:placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-5::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:placeholder-opacity-5:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:placeholder-opacity-10::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:placeholder-opacity-10:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:placeholder-opacity-20::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:placeholder-opacity-20:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-30::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:placeholder-opacity-30:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:placeholder-opacity-40::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:placeholder-opacity-40:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-60::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:placeholder-opacity-60:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:placeholder-opacity-70::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:placeholder-opacity-70:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-80::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:placeholder-opacity-80:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:placeholder-opacity-90:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:placeholder-opacity-95::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:placeholder-opacity-95:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:focus\:placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .sm\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:focus\:placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .sm\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:focus\:placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:focus\:placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .sm\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:focus\:placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:focus\:placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:focus\:placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .sm\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:focus\:placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .sm\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:focus\:placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .sm\:opacity-0{
    opacity: 0 !important;
  }

  .sm\:opacity-5{
    opacity: 0.05 !important;
  }

  .sm\:opacity-10{
    opacity: 0.1 !important;
  }

  .sm\:opacity-20{
    opacity: 0.2 !important;
  }

  .sm\:opacity-25{
    opacity: 0.25 !important;
  }

  .sm\:opacity-30{
    opacity: 0.3 !important;
  }

  .sm\:opacity-40{
    opacity: 0.4 !important;
  }

  .sm\:opacity-50{
    opacity: 0.5 !important;
  }

  .sm\:opacity-60{
    opacity: 0.6 !important;
  }

  .sm\:opacity-70{
    opacity: 0.7 !important;
  }

  .sm\:opacity-75{
    opacity: 0.75 !important;
  }

  .sm\:opacity-80{
    opacity: 0.8 !important;
  }

  .sm\:opacity-90{
    opacity: 0.9 !important;
  }

  .sm\:opacity-95{
    opacity: 0.95 !important;
  }

  .sm\:opacity-100{
    opacity: 1 !important;
  }

  .group:hover .sm\:group-hover\:opacity-0{
    opacity: 0 !important;
  }

  .group:hover .sm\:group-hover\:opacity-5{
    opacity: 0.05 !important;
  }

  .group:hover .sm\:group-hover\:opacity-10{
    opacity: 0.1 !important;
  }

  .group:hover .sm\:group-hover\:opacity-20{
    opacity: 0.2 !important;
  }

  .group:hover .sm\:group-hover\:opacity-25{
    opacity: 0.25 !important;
  }

  .group:hover .sm\:group-hover\:opacity-30{
    opacity: 0.3 !important;
  }

  .group:hover .sm\:group-hover\:opacity-40{
    opacity: 0.4 !important;
  }

  .group:hover .sm\:group-hover\:opacity-50{
    opacity: 0.5 !important;
  }

  .group:hover .sm\:group-hover\:opacity-60{
    opacity: 0.6 !important;
  }

  .group:hover .sm\:group-hover\:opacity-70{
    opacity: 0.7 !important;
  }

  .group:hover .sm\:group-hover\:opacity-75{
    opacity: 0.75 !important;
  }

  .group:hover .sm\:group-hover\:opacity-80{
    opacity: 0.8 !important;
  }

  .group:hover .sm\:group-hover\:opacity-90{
    opacity: 0.9 !important;
  }

  .group:hover .sm\:group-hover\:opacity-95{
    opacity: 0.95 !important;
  }

  .group:hover .sm\:group-hover\:opacity-100{
    opacity: 1 !important;
  }

  .sm\:focus-within\:opacity-0:focus-within{
    opacity: 0 !important;
  }

  .sm\:focus-within\:opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .sm\:focus-within\:opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .sm\:focus-within\:opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .sm\:focus-within\:opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .sm\:focus-within\:opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .sm\:focus-within\:opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .sm\:focus-within\:opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .sm\:focus-within\:opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .sm\:focus-within\:opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .sm\:focus-within\:opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .sm\:focus-within\:opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .sm\:focus-within\:opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .sm\:focus-within\:opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .sm\:focus-within\:opacity-100:focus-within{
    opacity: 1 !important;
  }

  .sm\:hover\:opacity-0:hover{
    opacity: 0 !important;
  }

  .sm\:hover\:opacity-5:hover{
    opacity: 0.05 !important;
  }

  .sm\:hover\:opacity-10:hover{
    opacity: 0.1 !important;
  }

  .sm\:hover\:opacity-20:hover{
    opacity: 0.2 !important;
  }

  .sm\:hover\:opacity-25:hover{
    opacity: 0.25 !important;
  }

  .sm\:hover\:opacity-30:hover{
    opacity: 0.3 !important;
  }

  .sm\:hover\:opacity-40:hover{
    opacity: 0.4 !important;
  }

  .sm\:hover\:opacity-50:hover{
    opacity: 0.5 !important;
  }

  .sm\:hover\:opacity-60:hover{
    opacity: 0.6 !important;
  }

  .sm\:hover\:opacity-70:hover{
    opacity: 0.7 !important;
  }

  .sm\:hover\:opacity-75:hover{
    opacity: 0.75 !important;
  }

  .sm\:hover\:opacity-80:hover{
    opacity: 0.8 !important;
  }

  .sm\:hover\:opacity-90:hover{
    opacity: 0.9 !important;
  }

  .sm\:hover\:opacity-95:hover{
    opacity: 0.95 !important;
  }

  .sm\:hover\:opacity-100:hover{
    opacity: 1 !important;
  }

  .sm\:focus\:opacity-0:focus{
    opacity: 0 !important;
  }

  .sm\:focus\:opacity-5:focus{
    opacity: 0.05 !important;
  }

  .sm\:focus\:opacity-10:focus{
    opacity: 0.1 !important;
  }

  .sm\:focus\:opacity-20:focus{
    opacity: 0.2 !important;
  }

  .sm\:focus\:opacity-25:focus{
    opacity: 0.25 !important;
  }

  .sm\:focus\:opacity-30:focus{
    opacity: 0.3 !important;
  }

  .sm\:focus\:opacity-40:focus{
    opacity: 0.4 !important;
  }

  .sm\:focus\:opacity-50:focus{
    opacity: 0.5 !important;
  }

  .sm\:focus\:opacity-60:focus{
    opacity: 0.6 !important;
  }

  .sm\:focus\:opacity-70:focus{
    opacity: 0.7 !important;
  }

  .sm\:focus\:opacity-75:focus{
    opacity: 0.75 !important;
  }

  .sm\:focus\:opacity-80:focus{
    opacity: 0.8 !important;
  }

  .sm\:focus\:opacity-90:focus{
    opacity: 0.9 !important;
  }

  .sm\:focus\:opacity-95:focus{
    opacity: 0.95 !important;
  }

  .sm\:focus\:opacity-100:focus{
    opacity: 1 !important;
  }

  .sm\:bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .sm\:bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .sm\:bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .sm\:bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .sm\:bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .sm\:bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .sm\:bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .sm\:bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .sm\:bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .sm\:bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .sm\:bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .sm\:bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .sm\:bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .sm\:bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .sm\:bg-blend-color{
    background-blend-mode: color !important;
  }

  .sm\:bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .sm\:mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .sm\:mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .sm\:mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .sm\:mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .sm\:mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .sm\:mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .sm\:mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .sm\:mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .sm\:mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .sm\:mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .sm\:mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .sm\:mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .sm\:mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .sm\:mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .sm\:mix-blend-color{
    mix-blend-mode: color !important;
  }

  .sm\:mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .sm\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .sm\:group-hover\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus-within\:shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:hover\:shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:focus\:shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .sm\:outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .sm\:outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .sm\:outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .sm\:focus-within\:outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .sm\:focus-within\:outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .sm\:focus-within\:outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .sm\:focus\:outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .sm\:focus\:outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .sm\:focus\:outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .sm\:ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:focus\:ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .sm\:ring-inset{
    --tw-ring-inset: inset !important;
  }

  .sm\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .sm\:focus\:ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .sm\:ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .sm\:ring-current{
    --tw-ring-color: currentColor !important;
  }

  .sm\:ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-qipGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-portalGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .sm\:focus-within\:ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .sm\:focus-within\:ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-qipGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus-within\:ring-portalGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .sm\:focus\:ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .sm\:focus\:ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-qipGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .sm\:focus\:ring-portalGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .sm\:ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .sm\:ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .sm\:ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .sm\:ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .sm\:ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .sm\:ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .sm\:ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .sm\:ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .sm\:ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .sm\:ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .sm\:ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .sm\:ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .sm\:ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .sm\:ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .sm\:ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .sm\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .sm\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .sm\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .sm\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .sm\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .sm\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .sm\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .sm\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .sm\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .sm\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .sm\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .sm\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .sm\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .sm\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .sm\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .sm\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .sm\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .sm\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .sm\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .sm\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .sm\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .sm\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .sm\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .sm\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .sm\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .sm\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .sm\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .sm\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .sm\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .sm\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .sm\:ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .sm\:ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .sm\:ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .sm\:ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .sm\:ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .sm\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .sm\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .sm\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .sm\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .sm\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .sm\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .sm\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .sm\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .sm\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .sm\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .sm\:ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .sm\:ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .sm\:ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .sm\:ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .sm\:ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .sm\:ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .sm\:ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .sm\:ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .sm\:ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .sm\:ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .sm\:ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .sm\:ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .sm\:ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .sm\:ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .sm\:ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .sm\:ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .sm\:ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .sm\:ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .sm\:ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .sm\:ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .sm\:ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .sm\:ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .sm\:ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .sm\:ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .sm\:ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .sm\:ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .sm\:ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .sm\:ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .sm\:ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .sm\:ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .sm\:ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .sm\:ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .sm\:ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .sm\:ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .sm\:ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .sm\:ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .sm\:ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .sm\:ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .sm\:ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .sm\:ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .sm\:ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .sm\:ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .sm\:ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .sm\:ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .sm\:ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .sm\:ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .sm\:ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .sm\:ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .sm\:ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .sm\:ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .sm\:ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .sm\:ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .sm\:ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .sm\:ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .sm\:ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .sm\:ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .sm\:ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .sm\:ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .sm\:ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .sm\:ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .sm\:ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .sm\:ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .sm\:ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .sm\:ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .sm\:ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .sm\:ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .sm\:ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .sm\:ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .sm\:ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .sm\:ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .sm\:ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .sm\:ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .sm\:ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .sm\:ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .sm\:ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .sm\:ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .sm\:ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .sm\:ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .sm\:ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .sm\:ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .sm\:ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .sm\:ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .sm\:ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .sm\:ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .sm\:ring-offset-qipGreen{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .sm\:ring-offset-portalGreen{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .sm\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .sm\:focus-within\:ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .sm\:focus-within\:ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .sm\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .sm\:focus-within\:ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .sm\:focus-within\:ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .sm\:focus-within\:ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .sm\:focus-within\:ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .sm\:focus-within\:ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .sm\:focus-within\:ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .sm\:focus-within\:ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .sm\:focus-within\:ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .sm\:focus-within\:ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .sm\:focus-within\:ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .sm\:focus-within\:ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .sm\:focus-within\:ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .sm\:focus-within\:ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .sm\:focus-within\:ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .sm\:focus-within\:ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .sm\:focus-within\:ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .sm\:focus-within\:ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .sm\:focus-within\:ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .sm\:focus-within\:ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .sm\:focus-within\:ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .sm\:focus-within\:ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .sm\:focus-within\:ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .sm\:focus-within\:ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .sm\:focus-within\:ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .sm\:focus-within\:ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .sm\:focus-within\:ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .sm\:focus-within\:ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .sm\:focus-within\:ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .sm\:focus-within\:ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .sm\:focus-within\:ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .sm\:focus-within\:ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .sm\:focus-within\:ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .sm\:focus-within\:ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .sm\:focus-within\:ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .sm\:focus-within\:ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .sm\:focus-within\:ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .sm\:focus-within\:ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .sm\:focus-within\:ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .sm\:focus-within\:ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .sm\:focus-within\:ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .sm\:focus-within\:ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .sm\:focus-within\:ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .sm\:focus-within\:ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .sm\:focus-within\:ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .sm\:focus-within\:ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .sm\:focus-within\:ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .sm\:focus-within\:ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .sm\:focus-within\:ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .sm\:focus-within\:ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .sm\:focus-within\:ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .sm\:focus-within\:ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .sm\:focus-within\:ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .sm\:focus-within\:ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .sm\:focus-within\:ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .sm\:focus-within\:ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .sm\:focus-within\:ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .sm\:focus-within\:ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .sm\:focus-within\:ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .sm\:focus-within\:ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .sm\:focus-within\:ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .sm\:focus-within\:ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .sm\:focus-within\:ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .sm\:focus-within\:ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .sm\:focus-within\:ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .sm\:focus-within\:ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .sm\:focus-within\:ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .sm\:focus-within\:ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .sm\:focus-within\:ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .sm\:focus-within\:ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .sm\:focus-within\:ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .sm\:focus-within\:ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .sm\:focus-within\:ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .sm\:focus-within\:ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .sm\:focus-within\:ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .sm\:focus-within\:ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .sm\:focus-within\:ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .sm\:focus-within\:ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .sm\:focus-within\:ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .sm\:focus-within\:ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .sm\:focus-within\:ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .sm\:focus-within\:ring-offset-qipGreen:focus-within{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .sm\:focus-within\:ring-offset-portalGreen:focus-within{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .sm\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .sm\:focus\:ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .sm\:focus\:ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .sm\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .sm\:focus\:ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .sm\:focus\:ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .sm\:focus\:ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .sm\:focus\:ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .sm\:focus\:ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .sm\:focus\:ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .sm\:focus\:ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .sm\:focus\:ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .sm\:focus\:ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .sm\:focus\:ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .sm\:focus\:ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .sm\:focus\:ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .sm\:focus\:ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .sm\:focus\:ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .sm\:focus\:ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .sm\:focus\:ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .sm\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .sm\:focus\:ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .sm\:focus\:ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .sm\:focus\:ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .sm\:focus\:ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .sm\:focus\:ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .sm\:focus\:ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .sm\:focus\:ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .sm\:focus\:ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .sm\:focus\:ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .sm\:focus\:ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .sm\:focus\:ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .sm\:focus\:ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .sm\:focus\:ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .sm\:focus\:ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .sm\:focus\:ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .sm\:focus\:ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .sm\:focus\:ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .sm\:focus\:ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .sm\:focus\:ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .sm\:focus\:ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .sm\:focus\:ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .sm\:focus\:ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .sm\:focus\:ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .sm\:focus\:ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .sm\:focus\:ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .sm\:focus\:ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .sm\:focus\:ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .sm\:focus\:ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .sm\:focus\:ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .sm\:focus\:ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .sm\:focus\:ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .sm\:focus\:ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .sm\:focus\:ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .sm\:focus\:ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .sm\:focus\:ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .sm\:focus\:ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .sm\:focus\:ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .sm\:focus\:ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .sm\:focus\:ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .sm\:focus\:ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .sm\:focus\:ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .sm\:focus\:ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .sm\:focus\:ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .sm\:focus\:ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .sm\:focus\:ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .sm\:focus\:ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .sm\:focus\:ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .sm\:focus\:ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .sm\:focus\:ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .sm\:focus\:ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .sm\:focus\:ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .sm\:focus\:ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .sm\:focus\:ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .sm\:focus\:ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .sm\:focus\:ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .sm\:focus\:ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .sm\:focus\:ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .sm\:focus\:ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .sm\:focus\:ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .sm\:focus\:ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .sm\:focus\:ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .sm\:focus\:ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .sm\:focus\:ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .sm\:focus\:ring-offset-qipGreen:focus{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .sm\:focus\:ring-offset-portalGreen:focus{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .sm\:filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .sm\:filter-none{
    -webkit-filter: none !important;
            filter: none !important;
  }

  .sm\:blur-0{
    --tw-blur: blur(0) !important;
  }

  .sm\:blur-none{
    --tw-blur: blur(0) !important;
  }

  .sm\:blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .sm\:blur{
    --tw-blur: blur(8px) !important;
  }

  .sm\:blur-md{
    --tw-blur: blur(12px) !important;
  }

  .sm\:blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .sm\:blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .sm\:blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .sm\:blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .sm\:brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .sm\:brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .sm\:brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .sm\:brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .sm\:brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .sm\:brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .sm\:brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .sm\:brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .sm\:brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .sm\:brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .sm\:brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .sm\:contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .sm\:contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .sm\:contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .sm\:contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .sm\:contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .sm\:contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .sm\:contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .sm\:drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .sm\:drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .sm\:drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .sm\:drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .sm\:drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .sm\:drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .sm\:drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .sm\:grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .sm\:grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .sm\:hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .sm\:hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .sm\:hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .sm\:hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .sm\:hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .sm\:hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .sm\:-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .sm\:-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .sm\:-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .sm\:-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .sm\:-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .sm\:invert-0{
    --tw-invert: invert(0) !important;
  }

  .sm\:invert{
    --tw-invert: invert(100%) !important;
  }

  .sm\:saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .sm\:saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .sm\:saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .sm\:saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .sm\:saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .sm\:sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .sm\:sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .sm\:backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .sm\:backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .sm\:backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .sm\:backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .sm\:backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .sm\:backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .sm\:backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .sm\:backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .sm\:backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .sm\:backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .sm\:backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .sm\:backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .sm\:backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .sm\:backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .sm\:backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .sm\:backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .sm\:backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .sm\:backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .sm\:backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .sm\:backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .sm\:backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .sm\:backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .sm\:backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .sm\:backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .sm\:backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .sm\:backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .sm\:backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .sm\:backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .sm\:backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .sm\:backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .sm\:backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .sm\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .sm\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .sm\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .sm\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .sm\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .sm\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .sm\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .sm\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .sm\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .sm\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .sm\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .sm\:backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .sm\:backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .sm\:backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .sm\:backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .sm\:backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .sm\:backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .sm\:backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .sm\:backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .sm\:backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .sm\:backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .sm\:backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .sm\:backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .sm\:backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .sm\:backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .sm\:backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .sm\:backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .sm\:backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .sm\:backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .sm\:backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .sm\:backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .sm\:backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .sm\:backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .sm\:backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .sm\:backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .sm\:transition-none{
    transition-property: none !important;
  }

  .sm\:transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .sm\:delay-75{
    transition-delay: 75ms !important;
  }

  .sm\:delay-100{
    transition-delay: 100ms !important;
  }

  .sm\:delay-150{
    transition-delay: 150ms !important;
  }

  .sm\:delay-200{
    transition-delay: 200ms !important;
  }

  .sm\:delay-300{
    transition-delay: 300ms !important;
  }

  .sm\:delay-500{
    transition-delay: 500ms !important;
  }

  .sm\:delay-700{
    transition-delay: 700ms !important;
  }

  .sm\:delay-1000{
    transition-delay: 1000ms !important;
  }

  .sm\:duration-75{
    transition-duration: 75ms !important;
  }

  .sm\:duration-100{
    transition-duration: 100ms !important;
  }

  .sm\:duration-150{
    transition-duration: 150ms !important;
  }

  .sm\:duration-200{
    transition-duration: 200ms !important;
  }

  .sm\:duration-300{
    transition-duration: 300ms !important;
  }

  .sm\:duration-500{
    transition-duration: 500ms !important;
  }

  .sm\:duration-700{
    transition-duration: 700ms !important;
  }

  .sm\:duration-1000{
    transition-duration: 1000ms !important;
  }

  .sm\:ease-linear{
    transition-timing-function: linear !important;
  }

  .sm\:ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .sm\:ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .sm\:ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 768px){
  .md\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .md\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .md\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .md\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .md\:container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .md\:container{
      max-width: 1536px;
    }
  }

  .md\:sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:focus-within\:sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:focus-within\:not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:focus\:sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:focus\:not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:pointer-events-none{
    pointer-events: none !important;
  }

  .md\:pointer-events-auto{
    pointer-events: auto !important;
  }

  .md\:visible{
    visibility: visible !important;
  }

  .md\:invisible{
    visibility: hidden !important;
  }

  .md\:static{
    position: static !important;
  }

  .md\:fixed{
    position: fixed !important;
  }

  .md\:absolute{
    position: absolute !important;
  }

  .md\:relative{
    position: relative !important;
  }

  .md\:sticky{
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .md\:inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .md\:inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .md\:inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .md\:inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .md\:inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .md\:inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .md\:inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .md\:inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .md\:inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .md\:inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .md\:inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .md\:inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .md\:inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .md\:inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .md\:inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .md\:inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .md\:inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .md\:inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .md\:inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .md\:inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .md\:inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .md\:inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .md\:inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .md\:inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .md\:inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .md\:inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .md\:inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .md\:inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .md\:inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .md\:inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .md\:inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .md\:inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .md\:inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .md\:inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .md\:inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .md\:inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .md\:-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .md\:-inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .md\:-inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .md\:-inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .md\:-inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .md\:-inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .md\:-inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .md\:-inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .md\:-inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .md\:-inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .md\:-inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .md\:-inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .md\:-inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .md\:-inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .md\:-inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .md\:-inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .md\:-inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .md\:-inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .md\:-inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .md\:-inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .md\:-inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .md\:-inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .md\:-inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .md\:-inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .md\:-inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .md\:-inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .md\:-inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .md\:-inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .md\:-inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .md\:-inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .md\:-inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .md\:-inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .md\:-inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .md\:-inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .md\:-inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .md\:inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .md\:inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .md\:inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .md\:inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .md\:inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .md\:inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .md\:inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .md\:-inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .md\:-inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .md\:-inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .md\:-inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .md\:-inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .md\:-inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .md\:-inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .md\:inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .md\:inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .md\:inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .md\:inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .md\:inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .md\:inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .md\:inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .md\:inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .md\:inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .md\:inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .md\:inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .md\:inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .md\:inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .md\:inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .md\:inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .md\:inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .md\:inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .md\:inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .md\:inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .md\:inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .md\:inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .md\:inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .md\:inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .md\:inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .md\:inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .md\:inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .md\:inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .md\:inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .md\:inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .md\:inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .md\:inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .md\:inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .md\:inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .md\:inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .md\:inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .md\:inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .md\:-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .md\:-inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .md\:-inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .md\:-inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .md\:-inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .md\:-inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .md\:-inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .md\:-inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .md\:-inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .md\:-inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .md\:-inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .md\:-inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .md\:-inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .md\:-inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .md\:-inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .md\:-inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .md\:-inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .md\:-inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .md\:-inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .md\:-inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .md\:-inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .md\:-inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .md\:-inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .md\:-inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .md\:-inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .md\:-inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .md\:-inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .md\:-inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .md\:-inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .md\:-inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .md\:-inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .md\:-inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .md\:-inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .md\:-inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .md\:-inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .md\:inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .md\:inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .md\:inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .md\:inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .md\:inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .md\:inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .md\:inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .md\:-inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .md\:-inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .md\:-inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .md\:-inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .md\:-inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .md\:-inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .md\:-inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .md\:inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .md\:inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .md\:inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .md\:inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .md\:inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .md\:inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .md\:inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .md\:inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .md\:inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .md\:inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .md\:inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .md\:inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .md\:inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .md\:inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .md\:inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .md\:inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .md\:inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .md\:inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .md\:inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .md\:inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .md\:inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .md\:inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .md\:inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .md\:inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .md\:inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .md\:inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .md\:inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .md\:inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .md\:inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .md\:inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .md\:inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .md\:inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .md\:inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .md\:inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .md\:inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .md\:inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .md\:-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .md\:-inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .md\:-inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .md\:-inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .md\:-inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .md\:-inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .md\:-inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .md\:-inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .md\:-inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .md\:-inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .md\:-inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .md\:-inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .md\:-inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .md\:-inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .md\:-inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .md\:-inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .md\:-inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .md\:-inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .md\:-inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .md\:-inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .md\:-inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .md\:-inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .md\:-inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .md\:-inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .md\:-inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .md\:-inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .md\:-inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .md\:-inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .md\:-inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .md\:-inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .md\:-inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .md\:-inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .md\:-inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .md\:-inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .md\:-inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .md\:inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .md\:inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .md\:inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .md\:inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .md\:inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .md\:inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .md\:inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .md\:-inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .md\:-inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .md\:-inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .md\:-inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .md\:-inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .md\:-inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .md\:-inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .md\:top-0{
    top: 0px !important;
  }

  .md\:top-1{
    top: 0.25rem !important;
  }

  .md\:top-2{
    top: 0.5rem !important;
  }

  .md\:top-3{
    top: 0.75rem !important;
  }

  .md\:top-4{
    top: 1rem !important;
  }

  .md\:top-5{
    top: 1.25rem !important;
  }

  .md\:top-6{
    top: 1.5rem !important;
  }

  .md\:top-7{
    top: 1.75rem !important;
  }

  .md\:top-8{
    top: 2rem !important;
  }

  .md\:top-9{
    top: 2.25rem !important;
  }

  .md\:top-10{
    top: 2.5rem !important;
  }

  .md\:top-11{
    top: 2.75rem !important;
  }

  .md\:top-12{
    top: 3rem !important;
  }

  .md\:top-14{
    top: 3.5rem !important;
  }

  .md\:top-16{
    top: 4rem !important;
  }

  .md\:top-20{
    top: 5rem !important;
  }

  .md\:top-24{
    top: 6rem !important;
  }

  .md\:top-28{
    top: 7rem !important;
  }

  .md\:top-32{
    top: 8rem !important;
  }

  .md\:top-36{
    top: 9rem !important;
  }

  .md\:top-40{
    top: 10rem !important;
  }

  .md\:top-44{
    top: 11rem !important;
  }

  .md\:top-48{
    top: 12rem !important;
  }

  .md\:top-52{
    top: 13rem !important;
  }

  .md\:top-56{
    top: 14rem !important;
  }

  .md\:top-60{
    top: 15rem !important;
  }

  .md\:top-64{
    top: 16rem !important;
  }

  .md\:top-72{
    top: 18rem !important;
  }

  .md\:top-80{
    top: 20rem !important;
  }

  .md\:top-96{
    top: 24rem !important;
  }

  .md\:top-auto{
    top: auto !important;
  }

  .md\:top-px{
    top: 1px !important;
  }

  .md\:top-0\.5{
    top: 0.125rem !important;
  }

  .md\:top-1\.5{
    top: 0.375rem !important;
  }

  .md\:top-2\.5{
    top: 0.625rem !important;
  }

  .md\:top-3\.5{
    top: 0.875rem !important;
  }

  .md\:-top-0{
    top: 0px !important;
  }

  .md\:-top-1{
    top: -0.25rem !important;
  }

  .md\:-top-2{
    top: -0.5rem !important;
  }

  .md\:-top-3{
    top: -0.75rem !important;
  }

  .md\:-top-4{
    top: -1rem !important;
  }

  .md\:-top-5{
    top: -1.25rem !important;
  }

  .md\:-top-6{
    top: -1.5rem !important;
  }

  .md\:-top-7{
    top: -1.75rem !important;
  }

  .md\:-top-8{
    top: -2rem !important;
  }

  .md\:-top-9{
    top: -2.25rem !important;
  }

  .md\:-top-10{
    top: -2.5rem !important;
  }

  .md\:-top-11{
    top: -2.75rem !important;
  }

  .md\:-top-12{
    top: -3rem !important;
  }

  .md\:-top-14{
    top: -3.5rem !important;
  }

  .md\:-top-16{
    top: -4rem !important;
  }

  .md\:-top-20{
    top: -5rem !important;
  }

  .md\:-top-24{
    top: -6rem !important;
  }

  .md\:-top-28{
    top: -7rem !important;
  }

  .md\:-top-32{
    top: -8rem !important;
  }

  .md\:-top-36{
    top: -9rem !important;
  }

  .md\:-top-40{
    top: -10rem !important;
  }

  .md\:-top-44{
    top: -11rem !important;
  }

  .md\:-top-48{
    top: -12rem !important;
  }

  .md\:-top-52{
    top: -13rem !important;
  }

  .md\:-top-56{
    top: -14rem !important;
  }

  .md\:-top-60{
    top: -15rem !important;
  }

  .md\:-top-64{
    top: -16rem !important;
  }

  .md\:-top-72{
    top: -18rem !important;
  }

  .md\:-top-80{
    top: -20rem !important;
  }

  .md\:-top-96{
    top: -24rem !important;
  }

  .md\:-top-px{
    top: -1px !important;
  }

  .md\:-top-0\.5{
    top: -0.125rem !important;
  }

  .md\:-top-1\.5{
    top: -0.375rem !important;
  }

  .md\:-top-2\.5{
    top: -0.625rem !important;
  }

  .md\:-top-3\.5{
    top: -0.875rem !important;
  }

  .md\:top-1\/2{
    top: 50% !important;
  }

  .md\:top-1\/3{
    top: 33.333333% !important;
  }

  .md\:top-2\/3{
    top: 66.666667% !important;
  }

  .md\:top-1\/4{
    top: 25% !important;
  }

  .md\:top-2\/4{
    top: 50% !important;
  }

  .md\:top-3\/4{
    top: 75% !important;
  }

  .md\:top-full{
    top: 100% !important;
  }

  .md\:-top-1\/2{
    top: -50% !important;
  }

  .md\:-top-1\/3{
    top: -33.333333% !important;
  }

  .md\:-top-2\/3{
    top: -66.666667% !important;
  }

  .md\:-top-1\/4{
    top: -25% !important;
  }

  .md\:-top-2\/4{
    top: -50% !important;
  }

  .md\:-top-3\/4{
    top: -75% !important;
  }

  .md\:-top-full{
    top: -100% !important;
  }

  .md\:right-0{
    right: 0px !important;
  }

  .md\:right-1{
    right: 0.25rem !important;
  }

  .md\:right-2{
    right: 0.5rem !important;
  }

  .md\:right-3{
    right: 0.75rem !important;
  }

  .md\:right-4{
    right: 1rem !important;
  }

  .md\:right-5{
    right: 1.25rem !important;
  }

  .md\:right-6{
    right: 1.5rem !important;
  }

  .md\:right-7{
    right: 1.75rem !important;
  }

  .md\:right-8{
    right: 2rem !important;
  }

  .md\:right-9{
    right: 2.25rem !important;
  }

  .md\:right-10{
    right: 2.5rem !important;
  }

  .md\:right-11{
    right: 2.75rem !important;
  }

  .md\:right-12{
    right: 3rem !important;
  }

  .md\:right-14{
    right: 3.5rem !important;
  }

  .md\:right-16{
    right: 4rem !important;
  }

  .md\:right-20{
    right: 5rem !important;
  }

  .md\:right-24{
    right: 6rem !important;
  }

  .md\:right-28{
    right: 7rem !important;
  }

  .md\:right-32{
    right: 8rem !important;
  }

  .md\:right-36{
    right: 9rem !important;
  }

  .md\:right-40{
    right: 10rem !important;
  }

  .md\:right-44{
    right: 11rem !important;
  }

  .md\:right-48{
    right: 12rem !important;
  }

  .md\:right-52{
    right: 13rem !important;
  }

  .md\:right-56{
    right: 14rem !important;
  }

  .md\:right-60{
    right: 15rem !important;
  }

  .md\:right-64{
    right: 16rem !important;
  }

  .md\:right-72{
    right: 18rem !important;
  }

  .md\:right-80{
    right: 20rem !important;
  }

  .md\:right-96{
    right: 24rem !important;
  }

  .md\:right-auto{
    right: auto !important;
  }

  .md\:right-px{
    right: 1px !important;
  }

  .md\:right-0\.5{
    right: 0.125rem !important;
  }

  .md\:right-1\.5{
    right: 0.375rem !important;
  }

  .md\:right-2\.5{
    right: 0.625rem !important;
  }

  .md\:right-3\.5{
    right: 0.875rem !important;
  }

  .md\:-right-0{
    right: 0px !important;
  }

  .md\:-right-1{
    right: -0.25rem !important;
  }

  .md\:-right-2{
    right: -0.5rem !important;
  }

  .md\:-right-3{
    right: -0.75rem !important;
  }

  .md\:-right-4{
    right: -1rem !important;
  }

  .md\:-right-5{
    right: -1.25rem !important;
  }

  .md\:-right-6{
    right: -1.5rem !important;
  }

  .md\:-right-7{
    right: -1.75rem !important;
  }

  .md\:-right-8{
    right: -2rem !important;
  }

  .md\:-right-9{
    right: -2.25rem !important;
  }

  .md\:-right-10{
    right: -2.5rem !important;
  }

  .md\:-right-11{
    right: -2.75rem !important;
  }

  .md\:-right-12{
    right: -3rem !important;
  }

  .md\:-right-14{
    right: -3.5rem !important;
  }

  .md\:-right-16{
    right: -4rem !important;
  }

  .md\:-right-20{
    right: -5rem !important;
  }

  .md\:-right-24{
    right: -6rem !important;
  }

  .md\:-right-28{
    right: -7rem !important;
  }

  .md\:-right-32{
    right: -8rem !important;
  }

  .md\:-right-36{
    right: -9rem !important;
  }

  .md\:-right-40{
    right: -10rem !important;
  }

  .md\:-right-44{
    right: -11rem !important;
  }

  .md\:-right-48{
    right: -12rem !important;
  }

  .md\:-right-52{
    right: -13rem !important;
  }

  .md\:-right-56{
    right: -14rem !important;
  }

  .md\:-right-60{
    right: -15rem !important;
  }

  .md\:-right-64{
    right: -16rem !important;
  }

  .md\:-right-72{
    right: -18rem !important;
  }

  .md\:-right-80{
    right: -20rem !important;
  }

  .md\:-right-96{
    right: -24rem !important;
  }

  .md\:-right-px{
    right: -1px !important;
  }

  .md\:-right-0\.5{
    right: -0.125rem !important;
  }

  .md\:-right-1\.5{
    right: -0.375rem !important;
  }

  .md\:-right-2\.5{
    right: -0.625rem !important;
  }

  .md\:-right-3\.5{
    right: -0.875rem !important;
  }

  .md\:right-1\/2{
    right: 50% !important;
  }

  .md\:right-1\/3{
    right: 33.333333% !important;
  }

  .md\:right-2\/3{
    right: 66.666667% !important;
  }

  .md\:right-1\/4{
    right: 25% !important;
  }

  .md\:right-2\/4{
    right: 50% !important;
  }

  .md\:right-3\/4{
    right: 75% !important;
  }

  .md\:right-full{
    right: 100% !important;
  }

  .md\:-right-1\/2{
    right: -50% !important;
  }

  .md\:-right-1\/3{
    right: -33.333333% !important;
  }

  .md\:-right-2\/3{
    right: -66.666667% !important;
  }

  .md\:-right-1\/4{
    right: -25% !important;
  }

  .md\:-right-2\/4{
    right: -50% !important;
  }

  .md\:-right-3\/4{
    right: -75% !important;
  }

  .md\:-right-full{
    right: -100% !important;
  }

  .md\:bottom-0{
    bottom: 0px !important;
  }

  .md\:bottom-1{
    bottom: 0.25rem !important;
  }

  .md\:bottom-2{
    bottom: 0.5rem !important;
  }

  .md\:bottom-3{
    bottom: 0.75rem !important;
  }

  .md\:bottom-4{
    bottom: 1rem !important;
  }

  .md\:bottom-5{
    bottom: 1.25rem !important;
  }

  .md\:bottom-6{
    bottom: 1.5rem !important;
  }

  .md\:bottom-7{
    bottom: 1.75rem !important;
  }

  .md\:bottom-8{
    bottom: 2rem !important;
  }

  .md\:bottom-9{
    bottom: 2.25rem !important;
  }

  .md\:bottom-10{
    bottom: 2.5rem !important;
  }

  .md\:bottom-11{
    bottom: 2.75rem !important;
  }

  .md\:bottom-12{
    bottom: 3rem !important;
  }

  .md\:bottom-14{
    bottom: 3.5rem !important;
  }

  .md\:bottom-16{
    bottom: 4rem !important;
  }

  .md\:bottom-20{
    bottom: 5rem !important;
  }

  .md\:bottom-24{
    bottom: 6rem !important;
  }

  .md\:bottom-28{
    bottom: 7rem !important;
  }

  .md\:bottom-32{
    bottom: 8rem !important;
  }

  .md\:bottom-36{
    bottom: 9rem !important;
  }

  .md\:bottom-40{
    bottom: 10rem !important;
  }

  .md\:bottom-44{
    bottom: 11rem !important;
  }

  .md\:bottom-48{
    bottom: 12rem !important;
  }

  .md\:bottom-52{
    bottom: 13rem !important;
  }

  .md\:bottom-56{
    bottom: 14rem !important;
  }

  .md\:bottom-60{
    bottom: 15rem !important;
  }

  .md\:bottom-64{
    bottom: 16rem !important;
  }

  .md\:bottom-72{
    bottom: 18rem !important;
  }

  .md\:bottom-80{
    bottom: 20rem !important;
  }

  .md\:bottom-96{
    bottom: 24rem !important;
  }

  .md\:bottom-auto{
    bottom: auto !important;
  }

  .md\:bottom-px{
    bottom: 1px !important;
  }

  .md\:bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .md\:bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .md\:bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .md\:bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .md\:-bottom-0{
    bottom: 0px !important;
  }

  .md\:-bottom-1{
    bottom: -0.25rem !important;
  }

  .md\:-bottom-2{
    bottom: -0.5rem !important;
  }

  .md\:-bottom-3{
    bottom: -0.75rem !important;
  }

  .md\:-bottom-4{
    bottom: -1rem !important;
  }

  .md\:-bottom-5{
    bottom: -1.25rem !important;
  }

  .md\:-bottom-6{
    bottom: -1.5rem !important;
  }

  .md\:-bottom-7{
    bottom: -1.75rem !important;
  }

  .md\:-bottom-8{
    bottom: -2rem !important;
  }

  .md\:-bottom-9{
    bottom: -2.25rem !important;
  }

  .md\:-bottom-10{
    bottom: -2.5rem !important;
  }

  .md\:-bottom-11{
    bottom: -2.75rem !important;
  }

  .md\:-bottom-12{
    bottom: -3rem !important;
  }

  .md\:-bottom-14{
    bottom: -3.5rem !important;
  }

  .md\:-bottom-16{
    bottom: -4rem !important;
  }

  .md\:-bottom-20{
    bottom: -5rem !important;
  }

  .md\:-bottom-24{
    bottom: -6rem !important;
  }

  .md\:-bottom-28{
    bottom: -7rem !important;
  }

  .md\:-bottom-32{
    bottom: -8rem !important;
  }

  .md\:-bottom-36{
    bottom: -9rem !important;
  }

  .md\:-bottom-40{
    bottom: -10rem !important;
  }

  .md\:-bottom-44{
    bottom: -11rem !important;
  }

  .md\:-bottom-48{
    bottom: -12rem !important;
  }

  .md\:-bottom-52{
    bottom: -13rem !important;
  }

  .md\:-bottom-56{
    bottom: -14rem !important;
  }

  .md\:-bottom-60{
    bottom: -15rem !important;
  }

  .md\:-bottom-64{
    bottom: -16rem !important;
  }

  .md\:-bottom-72{
    bottom: -18rem !important;
  }

  .md\:-bottom-80{
    bottom: -20rem !important;
  }

  .md\:-bottom-96{
    bottom: -24rem !important;
  }

  .md\:-bottom-px{
    bottom: -1px !important;
  }

  .md\:-bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .md\:-bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .md\:-bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .md\:-bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .md\:bottom-1\/2{
    bottom: 50% !important;
  }

  .md\:bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .md\:bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .md\:bottom-1\/4{
    bottom: 25% !important;
  }

  .md\:bottom-2\/4{
    bottom: 50% !important;
  }

  .md\:bottom-3\/4{
    bottom: 75% !important;
  }

  .md\:bottom-full{
    bottom: 100% !important;
  }

  .md\:-bottom-1\/2{
    bottom: -50% !important;
  }

  .md\:-bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .md\:-bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .md\:-bottom-1\/4{
    bottom: -25% !important;
  }

  .md\:-bottom-2\/4{
    bottom: -50% !important;
  }

  .md\:-bottom-3\/4{
    bottom: -75% !important;
  }

  .md\:-bottom-full{
    bottom: -100% !important;
  }

  .md\:left-0{
    left: 0px !important;
  }

  .md\:left-1{
    left: 0.25rem !important;
  }

  .md\:left-2{
    left: 0.5rem !important;
  }

  .md\:left-3{
    left: 0.75rem !important;
  }

  .md\:left-4{
    left: 1rem !important;
  }

  .md\:left-5{
    left: 1.25rem !important;
  }

  .md\:left-6{
    left: 1.5rem !important;
  }

  .md\:left-7{
    left: 1.75rem !important;
  }

  .md\:left-8{
    left: 2rem !important;
  }

  .md\:left-9{
    left: 2.25rem !important;
  }

  .md\:left-10{
    left: 2.5rem !important;
  }

  .md\:left-11{
    left: 2.75rem !important;
  }

  .md\:left-12{
    left: 3rem !important;
  }

  .md\:left-14{
    left: 3.5rem !important;
  }

  .md\:left-16{
    left: 4rem !important;
  }

  .md\:left-20{
    left: 5rem !important;
  }

  .md\:left-24{
    left: 6rem !important;
  }

  .md\:left-28{
    left: 7rem !important;
  }

  .md\:left-32{
    left: 8rem !important;
  }

  .md\:left-36{
    left: 9rem !important;
  }

  .md\:left-40{
    left: 10rem !important;
  }

  .md\:left-44{
    left: 11rem !important;
  }

  .md\:left-48{
    left: 12rem !important;
  }

  .md\:left-52{
    left: 13rem !important;
  }

  .md\:left-56{
    left: 14rem !important;
  }

  .md\:left-60{
    left: 15rem !important;
  }

  .md\:left-64{
    left: 16rem !important;
  }

  .md\:left-72{
    left: 18rem !important;
  }

  .md\:left-80{
    left: 20rem !important;
  }

  .md\:left-96{
    left: 24rem !important;
  }

  .md\:left-auto{
    left: auto !important;
  }

  .md\:left-px{
    left: 1px !important;
  }

  .md\:left-0\.5{
    left: 0.125rem !important;
  }

  .md\:left-1\.5{
    left: 0.375rem !important;
  }

  .md\:left-2\.5{
    left: 0.625rem !important;
  }

  .md\:left-3\.5{
    left: 0.875rem !important;
  }

  .md\:-left-0{
    left: 0px !important;
  }

  .md\:-left-1{
    left: -0.25rem !important;
  }

  .md\:-left-2{
    left: -0.5rem !important;
  }

  .md\:-left-3{
    left: -0.75rem !important;
  }

  .md\:-left-4{
    left: -1rem !important;
  }

  .md\:-left-5{
    left: -1.25rem !important;
  }

  .md\:-left-6{
    left: -1.5rem !important;
  }

  .md\:-left-7{
    left: -1.75rem !important;
  }

  .md\:-left-8{
    left: -2rem !important;
  }

  .md\:-left-9{
    left: -2.25rem !important;
  }

  .md\:-left-10{
    left: -2.5rem !important;
  }

  .md\:-left-11{
    left: -2.75rem !important;
  }

  .md\:-left-12{
    left: -3rem !important;
  }

  .md\:-left-14{
    left: -3.5rem !important;
  }

  .md\:-left-16{
    left: -4rem !important;
  }

  .md\:-left-20{
    left: -5rem !important;
  }

  .md\:-left-24{
    left: -6rem !important;
  }

  .md\:-left-28{
    left: -7rem !important;
  }

  .md\:-left-32{
    left: -8rem !important;
  }

  .md\:-left-36{
    left: -9rem !important;
  }

  .md\:-left-40{
    left: -10rem !important;
  }

  .md\:-left-44{
    left: -11rem !important;
  }

  .md\:-left-48{
    left: -12rem !important;
  }

  .md\:-left-52{
    left: -13rem !important;
  }

  .md\:-left-56{
    left: -14rem !important;
  }

  .md\:-left-60{
    left: -15rem !important;
  }

  .md\:-left-64{
    left: -16rem !important;
  }

  .md\:-left-72{
    left: -18rem !important;
  }

  .md\:-left-80{
    left: -20rem !important;
  }

  .md\:-left-96{
    left: -24rem !important;
  }

  .md\:-left-px{
    left: -1px !important;
  }

  .md\:-left-0\.5{
    left: -0.125rem !important;
  }

  .md\:-left-1\.5{
    left: -0.375rem !important;
  }

  .md\:-left-2\.5{
    left: -0.625rem !important;
  }

  .md\:-left-3\.5{
    left: -0.875rem !important;
  }

  .md\:left-1\/2{
    left: 50% !important;
  }

  .md\:left-1\/3{
    left: 33.333333% !important;
  }

  .md\:left-2\/3{
    left: 66.666667% !important;
  }

  .md\:left-1\/4{
    left: 25% !important;
  }

  .md\:left-2\/4{
    left: 50% !important;
  }

  .md\:left-3\/4{
    left: 75% !important;
  }

  .md\:left-full{
    left: 100% !important;
  }

  .md\:-left-1\/2{
    left: -50% !important;
  }

  .md\:-left-1\/3{
    left: -33.333333% !important;
  }

  .md\:-left-2\/3{
    left: -66.666667% !important;
  }

  .md\:-left-1\/4{
    left: -25% !important;
  }

  .md\:-left-2\/4{
    left: -50% !important;
  }

  .md\:-left-3\/4{
    left: -75% !important;
  }

  .md\:-left-full{
    left: -100% !important;
  }

  .md\:isolate{
    isolation: isolate !important;
  }

  .md\:isolation-auto{
    isolation: auto !important;
  }

  .md\:z-0{
    z-index: 0 !important;
  }

  .md\:z-10{
    z-index: 10 !important;
  }

  .md\:z-20{
    z-index: 20 !important;
  }

  .md\:z-30{
    z-index: 30 !important;
  }

  .md\:z-40{
    z-index: 40 !important;
  }

  .md\:z-50{
    z-index: 50 !important;
  }

  .md\:z-auto{
    z-index: auto !important;
  }

  .md\:focus-within\:z-0:focus-within{
    z-index: 0 !important;
  }

  .md\:focus-within\:z-10:focus-within{
    z-index: 10 !important;
  }

  .md\:focus-within\:z-20:focus-within{
    z-index: 20 !important;
  }

  .md\:focus-within\:z-30:focus-within{
    z-index: 30 !important;
  }

  .md\:focus-within\:z-40:focus-within{
    z-index: 40 !important;
  }

  .md\:focus-within\:z-50:focus-within{
    z-index: 50 !important;
  }

  .md\:focus-within\:z-auto:focus-within{
    z-index: auto !important;
  }

  .md\:focus\:z-0:focus{
    z-index: 0 !important;
  }

  .md\:focus\:z-10:focus{
    z-index: 10 !important;
  }

  .md\:focus\:z-20:focus{
    z-index: 20 !important;
  }

  .md\:focus\:z-30:focus{
    z-index: 30 !important;
  }

  .md\:focus\:z-40:focus{
    z-index: 40 !important;
  }

  .md\:focus\:z-50:focus{
    z-index: 50 !important;
  }

  .md\:focus\:z-auto:focus{
    z-index: auto !important;
  }

  .md\:order-1{
    order: 1 !important;
  }

  .md\:order-2{
    order: 2 !important;
  }

  .md\:order-3{
    order: 3 !important;
  }

  .md\:order-4{
    order: 4 !important;
  }

  .md\:order-5{
    order: 5 !important;
  }

  .md\:order-6{
    order: 6 !important;
  }

  .md\:order-7{
    order: 7 !important;
  }

  .md\:order-8{
    order: 8 !important;
  }

  .md\:order-9{
    order: 9 !important;
  }

  .md\:order-10{
    order: 10 !important;
  }

  .md\:order-11{
    order: 11 !important;
  }

  .md\:order-12{
    order: 12 !important;
  }

  .md\:order-first{
    order: -9999 !important;
  }

  .md\:order-last{
    order: 9999 !important;
  }

  .md\:order-none{
    order: 0 !important;
  }

  .md\:col-auto{
    grid-column: auto !important;
  }

  .md\:col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .md\:col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .md\:col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .md\:col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .md\:col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .md\:col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .md\:col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .md\:col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .md\:col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .md\:col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .md\:col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .md\:col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .md\:col-span-full{
    grid-column: 1 / -1 !important;
  }

  .md\:col-start-1{
    grid-column-start: 1 !important;
  }

  .md\:col-start-2{
    grid-column-start: 2 !important;
  }

  .md\:col-start-3{
    grid-column-start: 3 !important;
  }

  .md\:col-start-4{
    grid-column-start: 4 !important;
  }

  .md\:col-start-5{
    grid-column-start: 5 !important;
  }

  .md\:col-start-6{
    grid-column-start: 6 !important;
  }

  .md\:col-start-7{
    grid-column-start: 7 !important;
  }

  .md\:col-start-8{
    grid-column-start: 8 !important;
  }

  .md\:col-start-9{
    grid-column-start: 9 !important;
  }

  .md\:col-start-10{
    grid-column-start: 10 !important;
  }

  .md\:col-start-11{
    grid-column-start: 11 !important;
  }

  .md\:col-start-12{
    grid-column-start: 12 !important;
  }

  .md\:col-start-13{
    grid-column-start: 13 !important;
  }

  .md\:col-start-auto{
    grid-column-start: auto !important;
  }

  .md\:col-end-1{
    grid-column-end: 1 !important;
  }

  .md\:col-end-2{
    grid-column-end: 2 !important;
  }

  .md\:col-end-3{
    grid-column-end: 3 !important;
  }

  .md\:col-end-4{
    grid-column-end: 4 !important;
  }

  .md\:col-end-5{
    grid-column-end: 5 !important;
  }

  .md\:col-end-6{
    grid-column-end: 6 !important;
  }

  .md\:col-end-7{
    grid-column-end: 7 !important;
  }

  .md\:col-end-8{
    grid-column-end: 8 !important;
  }

  .md\:col-end-9{
    grid-column-end: 9 !important;
  }

  .md\:col-end-10{
    grid-column-end: 10 !important;
  }

  .md\:col-end-11{
    grid-column-end: 11 !important;
  }

  .md\:col-end-12{
    grid-column-end: 12 !important;
  }

  .md\:col-end-13{
    grid-column-end: 13 !important;
  }

  .md\:col-end-auto{
    grid-column-end: auto !important;
  }

  .md\:row-auto{
    grid-row: auto !important;
  }

  .md\:row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .md\:row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .md\:row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .md\:row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .md\:row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .md\:row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .md\:row-span-full{
    grid-row: 1 / -1 !important;
  }

  .md\:row-start-1{
    grid-row-start: 1 !important;
  }

  .md\:row-start-2{
    grid-row-start: 2 !important;
  }

  .md\:row-start-3{
    grid-row-start: 3 !important;
  }

  .md\:row-start-4{
    grid-row-start: 4 !important;
  }

  .md\:row-start-5{
    grid-row-start: 5 !important;
  }

  .md\:row-start-6{
    grid-row-start: 6 !important;
  }

  .md\:row-start-7{
    grid-row-start: 7 !important;
  }

  .md\:row-start-auto{
    grid-row-start: auto !important;
  }

  .md\:row-end-1{
    grid-row-end: 1 !important;
  }

  .md\:row-end-2{
    grid-row-end: 2 !important;
  }

  .md\:row-end-3{
    grid-row-end: 3 !important;
  }

  .md\:row-end-4{
    grid-row-end: 4 !important;
  }

  .md\:row-end-5{
    grid-row-end: 5 !important;
  }

  .md\:row-end-6{
    grid-row-end: 6 !important;
  }

  .md\:row-end-7{
    grid-row-end: 7 !important;
  }

  .md\:row-end-auto{
    grid-row-end: auto !important;
  }

  .md\:float-right{
    float: right !important;
  }

  .md\:float-left{
    float: left !important;
  }

  .md\:float-none{
    float: none !important;
  }

  .md\:clear-left{
    clear: left !important;
  }

  .md\:clear-right{
    clear: right !important;
  }

  .md\:clear-both{
    clear: both !important;
  }

  .md\:clear-none{
    clear: none !important;
  }

  .md\:m-0{
    margin: 0px !important;
  }

  .md\:m-1{
    margin: 0.25rem !important;
  }

  .md\:m-2{
    margin: 0.5rem !important;
  }

  .md\:m-3{
    margin: 0.75rem !important;
  }

  .md\:m-4{
    margin: 1rem !important;
  }

  .md\:m-5{
    margin: 1.25rem !important;
  }

  .md\:m-6{
    margin: 1.5rem !important;
  }

  .md\:m-7{
    margin: 1.75rem !important;
  }

  .md\:m-8{
    margin: 2rem !important;
  }

  .md\:m-9{
    margin: 2.25rem !important;
  }

  .md\:m-10{
    margin: 2.5rem !important;
  }

  .md\:m-11{
    margin: 2.75rem !important;
  }

  .md\:m-12{
    margin: 3rem !important;
  }

  .md\:m-14{
    margin: 3.5rem !important;
  }

  .md\:m-16{
    margin: 4rem !important;
  }

  .md\:m-20{
    margin: 5rem !important;
  }

  .md\:m-24{
    margin: 6rem !important;
  }

  .md\:m-28{
    margin: 7rem !important;
  }

  .md\:m-32{
    margin: 8rem !important;
  }

  .md\:m-36{
    margin: 9rem !important;
  }

  .md\:m-40{
    margin: 10rem !important;
  }

  .md\:m-44{
    margin: 11rem !important;
  }

  .md\:m-48{
    margin: 12rem !important;
  }

  .md\:m-52{
    margin: 13rem !important;
  }

  .md\:m-56{
    margin: 14rem !important;
  }

  .md\:m-60{
    margin: 15rem !important;
  }

  .md\:m-64{
    margin: 16rem !important;
  }

  .md\:m-72{
    margin: 18rem !important;
  }

  .md\:m-80{
    margin: 20rem !important;
  }

  .md\:m-96{
    margin: 24rem !important;
  }

  .md\:m-auto{
    margin: auto !important;
  }

  .md\:m-px{
    margin: 1px !important;
  }

  .md\:m-0\.5{
    margin: 0.125rem !important;
  }

  .md\:m-1\.5{
    margin: 0.375rem !important;
  }

  .md\:m-2\.5{
    margin: 0.625rem !important;
  }

  .md\:m-3\.5{
    margin: 0.875rem !important;
  }

  .md\:-m-0{
    margin: 0px !important;
  }

  .md\:-m-1{
    margin: -0.25rem !important;
  }

  .md\:-m-2{
    margin: -0.5rem !important;
  }

  .md\:-m-3{
    margin: -0.75rem !important;
  }

  .md\:-m-4{
    margin: -1rem !important;
  }

  .md\:-m-5{
    margin: -1.25rem !important;
  }

  .md\:-m-6{
    margin: -1.5rem !important;
  }

  .md\:-m-7{
    margin: -1.75rem !important;
  }

  .md\:-m-8{
    margin: -2rem !important;
  }

  .md\:-m-9{
    margin: -2.25rem !important;
  }

  .md\:-m-10{
    margin: -2.5rem !important;
  }

  .md\:-m-11{
    margin: -2.75rem !important;
  }

  .md\:-m-12{
    margin: -3rem !important;
  }

  .md\:-m-14{
    margin: -3.5rem !important;
  }

  .md\:-m-16{
    margin: -4rem !important;
  }

  .md\:-m-20{
    margin: -5rem !important;
  }

  .md\:-m-24{
    margin: -6rem !important;
  }

  .md\:-m-28{
    margin: -7rem !important;
  }

  .md\:-m-32{
    margin: -8rem !important;
  }

  .md\:-m-36{
    margin: -9rem !important;
  }

  .md\:-m-40{
    margin: -10rem !important;
  }

  .md\:-m-44{
    margin: -11rem !important;
  }

  .md\:-m-48{
    margin: -12rem !important;
  }

  .md\:-m-52{
    margin: -13rem !important;
  }

  .md\:-m-56{
    margin: -14rem !important;
  }

  .md\:-m-60{
    margin: -15rem !important;
  }

  .md\:-m-64{
    margin: -16rem !important;
  }

  .md\:-m-72{
    margin: -18rem !important;
  }

  .md\:-m-80{
    margin: -20rem !important;
  }

  .md\:-m-96{
    margin: -24rem !important;
  }

  .md\:-m-px{
    margin: -1px !important;
  }

  .md\:-m-0\.5{
    margin: -0.125rem !important;
  }

  .md\:-m-1\.5{
    margin: -0.375rem !important;
  }

  .md\:-m-2\.5{
    margin: -0.625rem !important;
  }

  .md\:-m-3\.5{
    margin: -0.875rem !important;
  }

  .md\:mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .md\:mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .md\:mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .md\:mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .md\:mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .md\:mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .md\:mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .md\:mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .md\:mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .md\:mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .md\:mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .md\:mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .md\:mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .md\:mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .md\:mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .md\:mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .md\:mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .md\:mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .md\:mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .md\:mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .md\:mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .md\:mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .md\:mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .md\:mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .md\:mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .md\:mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .md\:mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .md\:-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .md\:-mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .md\:-mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:-mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .md\:-mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .md\:-mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:-mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .md\:-mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .md\:-mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .md\:-mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .md\:-mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .md\:-mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:-mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .md\:-mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .md\:-mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .md\:-mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .md\:-mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .md\:-mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .md\:-mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .md\:-mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .md\:-mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .md\:-mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .md\:-mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .md\:-mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .md\:-mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .md\:-mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .md\:-mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .md\:-mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .md\:-mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .md\:-mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .md\:-mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .md\:my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md\:my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .md\:my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .md\:my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .md\:my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .md\:my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .md\:my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .md\:my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .md\:my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .md\:my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .md\:my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .md\:my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .md\:my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .md\:my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .md\:my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .md\:my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .md\:my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .md\:my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .md\:my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .md\:my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .md\:my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .md\:my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .md\:my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .md\:my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .md\:my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .md\:my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .md\:my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .md\:my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .md\:my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .md\:my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .md\:-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .md\:-my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .md\:-my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .md\:-my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .md\:-my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:-my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .md\:-my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:-my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .md\:-my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:-my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .md\:-my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .md\:-my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .md\:-my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:-my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .md\:-my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:-my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:-my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .md\:-my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .md\:-my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .md\:-my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .md\:-my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .md\:-my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .md\:-my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .md\:-my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .md\:-my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .md\:-my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .md\:-my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .md\:-my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .md\:-my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .md\:-my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .md\:-my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .md\:-my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .md\:-my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .md\:-my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .md\:-my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .md\:mt-0{
    margin-top: 0px !important;
  }

  .md\:mt-1{
    margin-top: 0.25rem !important;
  }

  .md\:mt-2{
    margin-top: 0.5rem !important;
  }

  .md\:mt-3{
    margin-top: 0.75rem !important;
  }

  .md\:mt-4{
    margin-top: 1rem !important;
  }

  .md\:mt-5{
    margin-top: 1.25rem !important;
  }

  .md\:mt-6{
    margin-top: 1.5rem !important;
  }

  .md\:mt-7{
    margin-top: 1.75rem !important;
  }

  .md\:mt-8{
    margin-top: 2rem !important;
  }

  .md\:mt-9{
    margin-top: 2.25rem !important;
  }

  .md\:mt-10{
    margin-top: 2.5rem !important;
  }

  .md\:mt-11{
    margin-top: 2.75rem !important;
  }

  .md\:mt-12{
    margin-top: 3rem !important;
  }

  .md\:mt-14{
    margin-top: 3.5rem !important;
  }

  .md\:mt-16{
    margin-top: 4rem !important;
  }

  .md\:mt-20{
    margin-top: 5rem !important;
  }

  .md\:mt-24{
    margin-top: 6rem !important;
  }

  .md\:mt-28{
    margin-top: 7rem !important;
  }

  .md\:mt-32{
    margin-top: 8rem !important;
  }

  .md\:mt-36{
    margin-top: 9rem !important;
  }

  .md\:mt-40{
    margin-top: 10rem !important;
  }

  .md\:mt-44{
    margin-top: 11rem !important;
  }

  .md\:mt-48{
    margin-top: 12rem !important;
  }

  .md\:mt-52{
    margin-top: 13rem !important;
  }

  .md\:mt-56{
    margin-top: 14rem !important;
  }

  .md\:mt-60{
    margin-top: 15rem !important;
  }

  .md\:mt-64{
    margin-top: 16rem !important;
  }

  .md\:mt-72{
    margin-top: 18rem !important;
  }

  .md\:mt-80{
    margin-top: 20rem !important;
  }

  .md\:mt-96{
    margin-top: 24rem !important;
  }

  .md\:mt-auto{
    margin-top: auto !important;
  }

  .md\:mt-px{
    margin-top: 1px !important;
  }

  .md\:mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .md\:mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .md\:mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .md\:mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .md\:-mt-0{
    margin-top: 0px !important;
  }

  .md\:-mt-1{
    margin-top: -0.25rem !important;
  }

  .md\:-mt-2{
    margin-top: -0.5rem !important;
  }

  .md\:-mt-3{
    margin-top: -0.75rem !important;
  }

  .md\:-mt-4{
    margin-top: -1rem !important;
  }

  .md\:-mt-5{
    margin-top: -1.25rem !important;
  }

  .md\:-mt-6{
    margin-top: -1.5rem !important;
  }

  .md\:-mt-7{
    margin-top: -1.75rem !important;
  }

  .md\:-mt-8{
    margin-top: -2rem !important;
  }

  .md\:-mt-9{
    margin-top: -2.25rem !important;
  }

  .md\:-mt-10{
    margin-top: -2.5rem !important;
  }

  .md\:-mt-11{
    margin-top: -2.75rem !important;
  }

  .md\:-mt-12{
    margin-top: -3rem !important;
  }

  .md\:-mt-14{
    margin-top: -3.5rem !important;
  }

  .md\:-mt-16{
    margin-top: -4rem !important;
  }

  .md\:-mt-20{
    margin-top: -5rem !important;
  }

  .md\:-mt-24{
    margin-top: -6rem !important;
  }

  .md\:-mt-28{
    margin-top: -7rem !important;
  }

  .md\:-mt-32{
    margin-top: -8rem !important;
  }

  .md\:-mt-36{
    margin-top: -9rem !important;
  }

  .md\:-mt-40{
    margin-top: -10rem !important;
  }

  .md\:-mt-44{
    margin-top: -11rem !important;
  }

  .md\:-mt-48{
    margin-top: -12rem !important;
  }

  .md\:-mt-52{
    margin-top: -13rem !important;
  }

  .md\:-mt-56{
    margin-top: -14rem !important;
  }

  .md\:-mt-60{
    margin-top: -15rem !important;
  }

  .md\:-mt-64{
    margin-top: -16rem !important;
  }

  .md\:-mt-72{
    margin-top: -18rem !important;
  }

  .md\:-mt-80{
    margin-top: -20rem !important;
  }

  .md\:-mt-96{
    margin-top: -24rem !important;
  }

  .md\:-mt-px{
    margin-top: -1px !important;
  }

  .md\:-mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .md\:-mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .md\:-mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .md\:-mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .md\:mr-0{
    margin-right: 0px !important;
  }

  .md\:mr-1{
    margin-right: 0.25rem !important;
  }

  .md\:mr-2{
    margin-right: 0.5rem !important;
  }

  .md\:mr-3{
    margin-right: 0.75rem !important;
  }

  .md\:mr-4{
    margin-right: 1rem !important;
  }

  .md\:mr-5{
    margin-right: 1.25rem !important;
  }

  .md\:mr-6{
    margin-right: 1.5rem !important;
  }

  .md\:mr-7{
    margin-right: 1.75rem !important;
  }

  .md\:mr-8{
    margin-right: 2rem !important;
  }

  .md\:mr-9{
    margin-right: 2.25rem !important;
  }

  .md\:mr-10{
    margin-right: 2.5rem !important;
  }

  .md\:mr-11{
    margin-right: 2.75rem !important;
  }

  .md\:mr-12{
    margin-right: 3rem !important;
  }

  .md\:mr-14{
    margin-right: 3.5rem !important;
  }

  .md\:mr-16{
    margin-right: 4rem !important;
  }

  .md\:mr-20{
    margin-right: 5rem !important;
  }

  .md\:mr-24{
    margin-right: 6rem !important;
  }

  .md\:mr-28{
    margin-right: 7rem !important;
  }

  .md\:mr-32{
    margin-right: 8rem !important;
  }

  .md\:mr-36{
    margin-right: 9rem !important;
  }

  .md\:mr-40{
    margin-right: 10rem !important;
  }

  .md\:mr-44{
    margin-right: 11rem !important;
  }

  .md\:mr-48{
    margin-right: 12rem !important;
  }

  .md\:mr-52{
    margin-right: 13rem !important;
  }

  .md\:mr-56{
    margin-right: 14rem !important;
  }

  .md\:mr-60{
    margin-right: 15rem !important;
  }

  .md\:mr-64{
    margin-right: 16rem !important;
  }

  .md\:mr-72{
    margin-right: 18rem !important;
  }

  .md\:mr-80{
    margin-right: 20rem !important;
  }

  .md\:mr-96{
    margin-right: 24rem !important;
  }

  .md\:mr-auto{
    margin-right: auto !important;
  }

  .md\:mr-px{
    margin-right: 1px !important;
  }

  .md\:mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .md\:mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .md\:mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .md\:mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .md\:-mr-0{
    margin-right: 0px !important;
  }

  .md\:-mr-1{
    margin-right: -0.25rem !important;
  }

  .md\:-mr-2{
    margin-right: -0.5rem !important;
  }

  .md\:-mr-3{
    margin-right: -0.75rem !important;
  }

  .md\:-mr-4{
    margin-right: -1rem !important;
  }

  .md\:-mr-5{
    margin-right: -1.25rem !important;
  }

  .md\:-mr-6{
    margin-right: -1.5rem !important;
  }

  .md\:-mr-7{
    margin-right: -1.75rem !important;
  }

  .md\:-mr-8{
    margin-right: -2rem !important;
  }

  .md\:-mr-9{
    margin-right: -2.25rem !important;
  }

  .md\:-mr-10{
    margin-right: -2.5rem !important;
  }

  .md\:-mr-11{
    margin-right: -2.75rem !important;
  }

  .md\:-mr-12{
    margin-right: -3rem !important;
  }

  .md\:-mr-14{
    margin-right: -3.5rem !important;
  }

  .md\:-mr-16{
    margin-right: -4rem !important;
  }

  .md\:-mr-20{
    margin-right: -5rem !important;
  }

  .md\:-mr-24{
    margin-right: -6rem !important;
  }

  .md\:-mr-28{
    margin-right: -7rem !important;
  }

  .md\:-mr-32{
    margin-right: -8rem !important;
  }

  .md\:-mr-36{
    margin-right: -9rem !important;
  }

  .md\:-mr-40{
    margin-right: -10rem !important;
  }

  .md\:-mr-44{
    margin-right: -11rem !important;
  }

  .md\:-mr-48{
    margin-right: -12rem !important;
  }

  .md\:-mr-52{
    margin-right: -13rem !important;
  }

  .md\:-mr-56{
    margin-right: -14rem !important;
  }

  .md\:-mr-60{
    margin-right: -15rem !important;
  }

  .md\:-mr-64{
    margin-right: -16rem !important;
  }

  .md\:-mr-72{
    margin-right: -18rem !important;
  }

  .md\:-mr-80{
    margin-right: -20rem !important;
  }

  .md\:-mr-96{
    margin-right: -24rem !important;
  }

  .md\:-mr-px{
    margin-right: -1px !important;
  }

  .md\:-mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .md\:-mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .md\:-mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .md\:-mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .md\:mb-0{
    margin-bottom: 0px !important;
  }

  .md\:mb-1{
    margin-bottom: 0.25rem !important;
  }

  .md\:mb-2{
    margin-bottom: 0.5rem !important;
  }

  .md\:mb-3{
    margin-bottom: 0.75rem !important;
  }

  .md\:mb-4{
    margin-bottom: 1rem !important;
  }

  .md\:mb-5{
    margin-bottom: 1.25rem !important;
  }

  .md\:mb-6{
    margin-bottom: 1.5rem !important;
  }

  .md\:mb-7{
    margin-bottom: 1.75rem !important;
  }

  .md\:mb-8{
    margin-bottom: 2rem !important;
  }

  .md\:mb-9{
    margin-bottom: 2.25rem !important;
  }

  .md\:mb-10{
    margin-bottom: 2.5rem !important;
  }

  .md\:mb-11{
    margin-bottom: 2.75rem !important;
  }

  .md\:mb-12{
    margin-bottom: 3rem !important;
  }

  .md\:mb-14{
    margin-bottom: 3.5rem !important;
  }

  .md\:mb-16{
    margin-bottom: 4rem !important;
  }

  .md\:mb-20{
    margin-bottom: 5rem !important;
  }

  .md\:mb-24{
    margin-bottom: 6rem !important;
  }

  .md\:mb-28{
    margin-bottom: 7rem !important;
  }

  .md\:mb-32{
    margin-bottom: 8rem !important;
  }

  .md\:mb-36{
    margin-bottom: 9rem !important;
  }

  .md\:mb-40{
    margin-bottom: 10rem !important;
  }

  .md\:mb-44{
    margin-bottom: 11rem !important;
  }

  .md\:mb-48{
    margin-bottom: 12rem !important;
  }

  .md\:mb-52{
    margin-bottom: 13rem !important;
  }

  .md\:mb-56{
    margin-bottom: 14rem !important;
  }

  .md\:mb-60{
    margin-bottom: 15rem !important;
  }

  .md\:mb-64{
    margin-bottom: 16rem !important;
  }

  .md\:mb-72{
    margin-bottom: 18rem !important;
  }

  .md\:mb-80{
    margin-bottom: 20rem !important;
  }

  .md\:mb-96{
    margin-bottom: 24rem !important;
  }

  .md\:mb-auto{
    margin-bottom: auto !important;
  }

  .md\:mb-px{
    margin-bottom: 1px !important;
  }

  .md\:mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .md\:mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .md\:mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .md\:mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .md\:-mb-0{
    margin-bottom: 0px !important;
  }

  .md\:-mb-1{
    margin-bottom: -0.25rem !important;
  }

  .md\:-mb-2{
    margin-bottom: -0.5rem !important;
  }

  .md\:-mb-3{
    margin-bottom: -0.75rem !important;
  }

  .md\:-mb-4{
    margin-bottom: -1rem !important;
  }

  .md\:-mb-5{
    margin-bottom: -1.25rem !important;
  }

  .md\:-mb-6{
    margin-bottom: -1.5rem !important;
  }

  .md\:-mb-7{
    margin-bottom: -1.75rem !important;
  }

  .md\:-mb-8{
    margin-bottom: -2rem !important;
  }

  .md\:-mb-9{
    margin-bottom: -2.25rem !important;
  }

  .md\:-mb-10{
    margin-bottom: -2.5rem !important;
  }

  .md\:-mb-11{
    margin-bottom: -2.75rem !important;
  }

  .md\:-mb-12{
    margin-bottom: -3rem !important;
  }

  .md\:-mb-14{
    margin-bottom: -3.5rem !important;
  }

  .md\:-mb-16{
    margin-bottom: -4rem !important;
  }

  .md\:-mb-20{
    margin-bottom: -5rem !important;
  }

  .md\:-mb-24{
    margin-bottom: -6rem !important;
  }

  .md\:-mb-28{
    margin-bottom: -7rem !important;
  }

  .md\:-mb-32{
    margin-bottom: -8rem !important;
  }

  .md\:-mb-36{
    margin-bottom: -9rem !important;
  }

  .md\:-mb-40{
    margin-bottom: -10rem !important;
  }

  .md\:-mb-44{
    margin-bottom: -11rem !important;
  }

  .md\:-mb-48{
    margin-bottom: -12rem !important;
  }

  .md\:-mb-52{
    margin-bottom: -13rem !important;
  }

  .md\:-mb-56{
    margin-bottom: -14rem !important;
  }

  .md\:-mb-60{
    margin-bottom: -15rem !important;
  }

  .md\:-mb-64{
    margin-bottom: -16rem !important;
  }

  .md\:-mb-72{
    margin-bottom: -18rem !important;
  }

  .md\:-mb-80{
    margin-bottom: -20rem !important;
  }

  .md\:-mb-96{
    margin-bottom: -24rem !important;
  }

  .md\:-mb-px{
    margin-bottom: -1px !important;
  }

  .md\:-mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .md\:-mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .md\:-mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .md\:-mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .md\:ml-0{
    margin-left: 0px !important;
  }

  .md\:ml-1{
    margin-left: 0.25rem !important;
  }

  .md\:ml-2{
    margin-left: 0.5rem !important;
  }

  .md\:ml-3{
    margin-left: 0.75rem !important;
  }

  .md\:ml-4{
    margin-left: 1rem !important;
  }

  .md\:ml-5{
    margin-left: 1.25rem !important;
  }

  .md\:ml-6{
    margin-left: 1.5rem !important;
  }

  .md\:ml-7{
    margin-left: 1.75rem !important;
  }

  .md\:ml-8{
    margin-left: 2rem !important;
  }

  .md\:ml-9{
    margin-left: 2.25rem !important;
  }

  .md\:ml-10{
    margin-left: 2.5rem !important;
  }

  .md\:ml-11{
    margin-left: 2.75rem !important;
  }

  .md\:ml-12{
    margin-left: 3rem !important;
  }

  .md\:ml-14{
    margin-left: 3.5rem !important;
  }

  .md\:ml-16{
    margin-left: 4rem !important;
  }

  .md\:ml-20{
    margin-left: 5rem !important;
  }

  .md\:ml-24{
    margin-left: 6rem !important;
  }

  .md\:ml-28{
    margin-left: 7rem !important;
  }

  .md\:ml-32{
    margin-left: 8rem !important;
  }

  .md\:ml-36{
    margin-left: 9rem !important;
  }

  .md\:ml-40{
    margin-left: 10rem !important;
  }

  .md\:ml-44{
    margin-left: 11rem !important;
  }

  .md\:ml-48{
    margin-left: 12rem !important;
  }

  .md\:ml-52{
    margin-left: 13rem !important;
  }

  .md\:ml-56{
    margin-left: 14rem !important;
  }

  .md\:ml-60{
    margin-left: 15rem !important;
  }

  .md\:ml-64{
    margin-left: 16rem !important;
  }

  .md\:ml-72{
    margin-left: 18rem !important;
  }

  .md\:ml-80{
    margin-left: 20rem !important;
  }

  .md\:ml-96{
    margin-left: 24rem !important;
  }

  .md\:ml-auto{
    margin-left: auto !important;
  }

  .md\:ml-px{
    margin-left: 1px !important;
  }

  .md\:ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .md\:ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .md\:ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .md\:ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .md\:-ml-0{
    margin-left: 0px !important;
  }

  .md\:-ml-1{
    margin-left: -0.25rem !important;
  }

  .md\:-ml-2{
    margin-left: -0.5rem !important;
  }

  .md\:-ml-3{
    margin-left: -0.75rem !important;
  }

  .md\:-ml-4{
    margin-left: -1rem !important;
  }

  .md\:-ml-5{
    margin-left: -1.25rem !important;
  }

  .md\:-ml-6{
    margin-left: -1.5rem !important;
  }

  .md\:-ml-7{
    margin-left: -1.75rem !important;
  }

  .md\:-ml-8{
    margin-left: -2rem !important;
  }

  .md\:-ml-9{
    margin-left: -2.25rem !important;
  }

  .md\:-ml-10{
    margin-left: -2.5rem !important;
  }

  .md\:-ml-11{
    margin-left: -2.75rem !important;
  }

  .md\:-ml-12{
    margin-left: -3rem !important;
  }

  .md\:-ml-14{
    margin-left: -3.5rem !important;
  }

  .md\:-ml-16{
    margin-left: -4rem !important;
  }

  .md\:-ml-20{
    margin-left: -5rem !important;
  }

  .md\:-ml-24{
    margin-left: -6rem !important;
  }

  .md\:-ml-28{
    margin-left: -7rem !important;
  }

  .md\:-ml-32{
    margin-left: -8rem !important;
  }

  .md\:-ml-36{
    margin-left: -9rem !important;
  }

  .md\:-ml-40{
    margin-left: -10rem !important;
  }

  .md\:-ml-44{
    margin-left: -11rem !important;
  }

  .md\:-ml-48{
    margin-left: -12rem !important;
  }

  .md\:-ml-52{
    margin-left: -13rem !important;
  }

  .md\:-ml-56{
    margin-left: -14rem !important;
  }

  .md\:-ml-60{
    margin-left: -15rem !important;
  }

  .md\:-ml-64{
    margin-left: -16rem !important;
  }

  .md\:-ml-72{
    margin-left: -18rem !important;
  }

  .md\:-ml-80{
    margin-left: -20rem !important;
  }

  .md\:-ml-96{
    margin-left: -24rem !important;
  }

  .md\:-ml-px{
    margin-left: -1px !important;
  }

  .md\:-ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .md\:-ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .md\:-ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .md\:-ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .md\:box-border{
    box-sizing: border-box !important;
  }

  .md\:box-content{
    box-sizing: content-box !important;
  }

  .md\:block{
    display: block !important;
  }

  .md\:inline-block{
    display: inline-block !important;
  }

  .md\:inline{
    display: inline !important;
  }

  .md\:flex{
    display: flex !important;
  }

  .md\:inline-flex{
    display: inline-flex !important;
  }

  .md\:table{
    display: table !important;
  }

  .md\:inline-table{
    display: inline-table !important;
  }

  .md\:table-caption{
    display: table-caption !important;
  }

  .md\:table-cell{
    display: table-cell !important;
  }

  .md\:table-column{
    display: table-column !important;
  }

  .md\:table-column-group{
    display: table-column-group !important;
  }

  .md\:table-footer-group{
    display: table-footer-group !important;
  }

  .md\:table-header-group{
    display: table-header-group !important;
  }

  .md\:table-row-group{
    display: table-row-group !important;
  }

  .md\:table-row{
    display: table-row !important;
  }

  .md\:flow-root{
    display: flow-root !important;
  }

  .md\:grid{
    display: grid !important;
  }

  .md\:inline-grid{
    display: inline-grid !important;
  }

  .md\:contents{
    display: contents !important;
  }

  .md\:list-item{
    display: list-item !important;
  }

  .md\:hidden{
    display: none !important;
  }

  .md\:h-0{
    height: 0px !important;
  }

  .md\:h-1{
    height: 0.25rem !important;
  }

  .md\:h-2{
    height: 0.5rem !important;
  }

  .md\:h-3{
    height: 0.75rem !important;
  }

  .md\:h-4{
    height: 1rem !important;
  }

  .md\:h-5{
    height: 1.25rem !important;
  }

  .md\:h-6{
    height: 1.5rem !important;
  }

  .md\:h-7{
    height: 1.75rem !important;
  }

  .md\:h-8{
    height: 2rem !important;
  }

  .md\:h-9{
    height: 2.25rem !important;
  }

  .md\:h-10{
    height: 2.5rem !important;
  }

  .md\:h-11{
    height: 2.75rem !important;
  }

  .md\:h-12{
    height: 3rem !important;
  }

  .md\:h-14{
    height: 3.5rem !important;
  }

  .md\:h-16{
    height: 4rem !important;
  }

  .md\:h-20{
    height: 5rem !important;
  }

  .md\:h-24{
    height: 6rem !important;
  }

  .md\:h-28{
    height: 7rem !important;
  }

  .md\:h-32{
    height: 8rem !important;
  }

  .md\:h-36{
    height: 9rem !important;
  }

  .md\:h-40{
    height: 10rem !important;
  }

  .md\:h-44{
    height: 11rem !important;
  }

  .md\:h-48{
    height: 12rem !important;
  }

  .md\:h-52{
    height: 13rem !important;
  }

  .md\:h-56{
    height: 14rem !important;
  }

  .md\:h-60{
    height: 15rem !important;
  }

  .md\:h-64{
    height: 16rem !important;
  }

  .md\:h-72{
    height: 18rem !important;
  }

  .md\:h-80{
    height: 20rem !important;
  }

  .md\:h-96{
    height: 24rem !important;
  }

  .md\:h-auto{
    height: auto !important;
  }

  .md\:h-px{
    height: 1px !important;
  }

  .md\:h-0\.5{
    height: 0.125rem !important;
  }

  .md\:h-1\.5{
    height: 0.375rem !important;
  }

  .md\:h-2\.5{
    height: 0.625rem !important;
  }

  .md\:h-3\.5{
    height: 0.875rem !important;
  }

  .md\:h-1\/2{
    height: 50% !important;
  }

  .md\:h-1\/3{
    height: 33.333333% !important;
  }

  .md\:h-2\/3{
    height: 66.666667% !important;
  }

  .md\:h-1\/4{
    height: 25% !important;
  }

  .md\:h-2\/4{
    height: 50% !important;
  }

  .md\:h-3\/4{
    height: 75% !important;
  }

  .md\:h-1\/5{
    height: 20% !important;
  }

  .md\:h-2\/5{
    height: 40% !important;
  }

  .md\:h-3\/5{
    height: 60% !important;
  }

  .md\:h-4\/5{
    height: 80% !important;
  }

  .md\:h-1\/6{
    height: 16.666667% !important;
  }

  .md\:h-2\/6{
    height: 33.333333% !important;
  }

  .md\:h-3\/6{
    height: 50% !important;
  }

  .md\:h-4\/6{
    height: 66.666667% !important;
  }

  .md\:h-5\/6{
    height: 83.333333% !important;
  }

  .md\:h-full{
    height: 100% !important;
  }

  .md\:h-screen{
    height: 100vh !important;
  }

  .md\:max-h-0{
    max-height: 0px !important;
  }

  .md\:max-h-1{
    max-height: 0.25rem !important;
  }

  .md\:max-h-2{
    max-height: 0.5rem !important;
  }

  .md\:max-h-3{
    max-height: 0.75rem !important;
  }

  .md\:max-h-4{
    max-height: 1rem !important;
  }

  .md\:max-h-5{
    max-height: 1.25rem !important;
  }

  .md\:max-h-6{
    max-height: 1.5rem !important;
  }

  .md\:max-h-7{
    max-height: 1.75rem !important;
  }

  .md\:max-h-8{
    max-height: 2rem !important;
  }

  .md\:max-h-9{
    max-height: 2.25rem !important;
  }

  .md\:max-h-10{
    max-height: 2.5rem !important;
  }

  .md\:max-h-11{
    max-height: 2.75rem !important;
  }

  .md\:max-h-12{
    max-height: 3rem !important;
  }

  .md\:max-h-14{
    max-height: 3.5rem !important;
  }

  .md\:max-h-16{
    max-height: 4rem !important;
  }

  .md\:max-h-20{
    max-height: 5rem !important;
  }

  .md\:max-h-24{
    max-height: 6rem !important;
  }

  .md\:max-h-28{
    max-height: 7rem !important;
  }

  .md\:max-h-32{
    max-height: 8rem !important;
  }

  .md\:max-h-36{
    max-height: 9rem !important;
  }

  .md\:max-h-40{
    max-height: 10rem !important;
  }

  .md\:max-h-44{
    max-height: 11rem !important;
  }

  .md\:max-h-48{
    max-height: 12rem !important;
  }

  .md\:max-h-52{
    max-height: 13rem !important;
  }

  .md\:max-h-56{
    max-height: 14rem !important;
  }

  .md\:max-h-60{
    max-height: 15rem !important;
  }

  .md\:max-h-64{
    max-height: 16rem !important;
  }

  .md\:max-h-72{
    max-height: 18rem !important;
  }

  .md\:max-h-80{
    max-height: 20rem !important;
  }

  .md\:max-h-96{
    max-height: 24rem !important;
  }

  .md\:max-h-px{
    max-height: 1px !important;
  }

  .md\:max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .md\:max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .md\:max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .md\:max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .md\:max-h-full{
    max-height: 100% !important;
  }

  .md\:max-h-screen{
    max-height: 100vh !important;
  }

  .md\:min-h-0{
    min-height: 0px !important;
  }

  .md\:min-h-full{
    min-height: 100% !important;
  }

  .md\:min-h-screen{
    min-height: 100vh !important;
  }

  .md\:w-0{
    width: 0px !important;
  }

  .md\:w-1{
    width: 0.25rem !important;
  }

  .md\:w-2{
    width: 0.5rem !important;
  }

  .md\:w-3{
    width: 0.75rem !important;
  }

  .md\:w-4{
    width: 1rem !important;
  }

  .md\:w-5{
    width: 1.25rem !important;
  }

  .md\:w-6{
    width: 1.5rem !important;
  }

  .md\:w-7{
    width: 1.75rem !important;
  }

  .md\:w-8{
    width: 2rem !important;
  }

  .md\:w-9{
    width: 2.25rem !important;
  }

  .md\:w-10{
    width: 2.5rem !important;
  }

  .md\:w-11{
    width: 2.75rem !important;
  }

  .md\:w-12{
    width: 3rem !important;
  }

  .md\:w-14{
    width: 3.5rem !important;
  }

  .md\:w-16{
    width: 4rem !important;
  }

  .md\:w-20{
    width: 5rem !important;
  }

  .md\:w-24{
    width: 6rem !important;
  }

  .md\:w-28{
    width: 7rem !important;
  }

  .md\:w-32{
    width: 8rem !important;
  }

  .md\:w-36{
    width: 9rem !important;
  }

  .md\:w-40{
    width: 10rem !important;
  }

  .md\:w-44{
    width: 11rem !important;
  }

  .md\:w-48{
    width: 12rem !important;
  }

  .md\:w-52{
    width: 13rem !important;
  }

  .md\:w-56{
    width: 14rem !important;
  }

  .md\:w-60{
    width: 15rem !important;
  }

  .md\:w-64{
    width: 16rem !important;
  }

  .md\:w-72{
    width: 18rem !important;
  }

  .md\:w-80{
    width: 20rem !important;
  }

  .md\:w-96{
    width: 24rem !important;
  }

  .md\:w-auto{
    width: auto !important;
  }

  .md\:w-px{
    width: 1px !important;
  }

  .md\:w-0\.5{
    width: 0.125rem !important;
  }

  .md\:w-1\.5{
    width: 0.375rem !important;
  }

  .md\:w-2\.5{
    width: 0.625rem !important;
  }

  .md\:w-3\.5{
    width: 0.875rem !important;
  }

  .md\:w-1\/2{
    width: 50% !important;
  }

  .md\:w-1\/3{
    width: 33.333333% !important;
  }

  .md\:w-2\/3{
    width: 66.666667% !important;
  }

  .md\:w-1\/4{
    width: 25% !important;
  }

  .md\:w-2\/4{
    width: 50% !important;
  }

  .md\:w-3\/4{
    width: 75% !important;
  }

  .md\:w-1\/5{
    width: 20% !important;
  }

  .md\:w-2\/5{
    width: 40% !important;
  }

  .md\:w-3\/5{
    width: 60% !important;
  }

  .md\:w-4\/5{
    width: 80% !important;
  }

  .md\:w-1\/6{
    width: 16.666667% !important;
  }

  .md\:w-2\/6{
    width: 33.333333% !important;
  }

  .md\:w-3\/6{
    width: 50% !important;
  }

  .md\:w-4\/6{
    width: 66.666667% !important;
  }

  .md\:w-5\/6{
    width: 83.333333% !important;
  }

  .md\:w-1\/12{
    width: 8.333333% !important;
  }

  .md\:w-2\/12{
    width: 16.666667% !important;
  }

  .md\:w-3\/12{
    width: 25% !important;
  }

  .md\:w-4\/12{
    width: 33.333333% !important;
  }

  .md\:w-5\/12{
    width: 41.666667% !important;
  }

  .md\:w-6\/12{
    width: 50% !important;
  }

  .md\:w-7\/12{
    width: 58.333333% !important;
  }

  .md\:w-8\/12{
    width: 66.666667% !important;
  }

  .md\:w-9\/12{
    width: 75% !important;
  }

  .md\:w-10\/12{
    width: 83.333333% !important;
  }

  .md\:w-11\/12{
    width: 91.666667% !important;
  }

  .md\:w-full{
    width: 100% !important;
  }

  .md\:w-screen{
    width: 100vw !important;
  }

  .md\:w-min{
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .md\:w-max{
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .md\:min-w-0{
    min-width: 0px !important;
  }

  .md\:min-w-full{
    min-width: 100% !important;
  }

  .md\:min-w-min{
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important;
  }

  .md\:min-w-max{
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
  }

  .md\:max-w-0{
    max-width: 0rem !important;
  }

  .md\:max-w-none{
    max-width: none !important;
  }

  .md\:max-w-xs{
    max-width: 20rem !important;
  }

  .md\:max-w-sm{
    max-width: 24rem !important;
  }

  .md\:max-w-md{
    max-width: 28rem !important;
  }

  .md\:max-w-lg{
    max-width: 32rem !important;
  }

  .md\:max-w-xl{
    max-width: 36rem !important;
  }

  .md\:max-w-2xl{
    max-width: 42rem !important;
  }

  .md\:max-w-3xl{
    max-width: 48rem !important;
  }

  .md\:max-w-4xl{
    max-width: 56rem !important;
  }

  .md\:max-w-5xl{
    max-width: 64rem !important;
  }

  .md\:max-w-6xl{
    max-width: 72rem !important;
  }

  .md\:max-w-7xl{
    max-width: 80rem !important;
  }

  .md\:max-w-full{
    max-width: 100% !important;
  }

  .md\:max-w-min{
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .md\:max-w-max{
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .md\:max-w-prose{
    max-width: 65ch !important;
  }

  .md\:max-w-screen-sm{
    max-width: 640px !important;
  }

  .md\:max-w-screen-md{
    max-width: 768px !important;
  }

  .md\:max-w-screen-lg{
    max-width: 1024px !important;
  }

  .md\:max-w-screen-xl{
    max-width: 1280px !important;
  }

  .md\:max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .md\:flex-1{
    flex: 1 1 0% !important;
  }

  .md\:flex-auto{
    flex: 1 1 auto !important;
  }

  .md\:flex-initial{
    flex: 0 1 auto !important;
  }

  .md\:flex-none{
    flex: none !important;
  }

  .md\:flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .md\:flex-shrink{
    flex-shrink: 1 !important;
  }

  .md\:flex-grow-0{
    flex-grow: 0 !important;
  }

  .md\:flex-grow{
    flex-grow: 1 !important;
  }

  .md\:table-auto{
    table-layout: auto !important;
  }

  .md\:table-fixed{
    table-layout: fixed !important;
  }

  .md\:border-collapse{
    border-collapse: collapse !important;
  }

  .md\:border-separate{
    border-collapse: separate !important;
  }

  .md\:origin-center{
    transform-origin: center !important;
  }

  .md\:origin-top{
    transform-origin: top !important;
  }

  .md\:origin-top-right{
    transform-origin: top right !important;
  }

  .md\:origin-right{
    transform-origin: right !important;
  }

  .md\:origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .md\:origin-bottom{
    transform-origin: bottom !important;
  }

  .md\:origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .md\:origin-left{
    transform-origin: left !important;
  }

  .md\:origin-top-left{
    transform-origin: top left !important;
  }

  .md\:transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .md\:transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .md\:transform-none{
    transform: none !important;
  }

  .md\:translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .md\:translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .md\:translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .md\:translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .md\:translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .md\:translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .md\:translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .md\:translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .md\:translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .md\:translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .md\:translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .md\:translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .md\:translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .md\:translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .md\:translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .md\:translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .md\:translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .md\:translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .md\:translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .md\:translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .md\:translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .md\:translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .md\:translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .md\:translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .md\:translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .md\:translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .md\:translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .md\:translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .md\:translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .md\:translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .md\:translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .md\:translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .md\:translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .md\:translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .md\:translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .md\:-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .md\:-translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .md\:-translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .md\:-translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .md\:-translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .md\:-translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .md\:-translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .md\:-translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .md\:-translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .md\:-translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .md\:-translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .md\:-translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .md\:-translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .md\:-translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .md\:-translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .md\:-translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .md\:-translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .md\:-translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .md\:-translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .md\:-translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .md\:-translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .md\:-translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .md\:-translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .md\:-translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .md\:-translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .md\:-translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .md\:-translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .md\:-translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .md\:-translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .md\:-translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .md\:-translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .md\:-translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .md\:-translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .md\:-translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .md\:-translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .md\:translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .md\:translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .md\:translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .md\:translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .md\:translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .md\:translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .md\:translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .md\:-translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .md\:-translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .md\:-translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .md\:-translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .md\:-translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .md\:-translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .md\:-translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .md\:translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .md\:translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .md\:translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .md\:translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .md\:translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .md\:translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .md\:translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .md\:translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .md\:translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .md\:translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .md\:translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .md\:translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .md\:translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .md\:translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .md\:translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .md\:translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .md\:translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .md\:translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .md\:translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .md\:translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .md\:translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .md\:translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .md\:translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .md\:translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .md\:translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .md\:translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .md\:translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .md\:translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .md\:translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .md\:translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .md\:translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .md\:translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .md\:translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .md\:translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .md\:translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .md\:-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .md\:-translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .md\:-translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .md\:-translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .md\:-translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .md\:-translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .md\:-translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .md\:-translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .md\:-translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .md\:-translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .md\:-translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .md\:-translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .md\:-translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .md\:-translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .md\:-translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .md\:-translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .md\:-translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .md\:-translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .md\:-translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .md\:-translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .md\:-translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .md\:-translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .md\:-translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .md\:-translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .md\:-translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .md\:-translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .md\:-translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .md\:-translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .md\:-translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .md\:-translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .md\:-translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .md\:-translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .md\:-translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .md\:-translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .md\:-translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .md\:translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .md\:translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .md\:translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .md\:translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .md\:translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .md\:translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .md\:translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .md\:-translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .md\:-translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .md\:-translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .md\:-translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .md\:-translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .md\:-translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .md\:-translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .md\:hover\:translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .md\:hover\:translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .md\:hover\:translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .md\:hover\:translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .md\:hover\:translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .md\:hover\:translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .md\:hover\:translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .md\:hover\:translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .md\:hover\:translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .md\:hover\:translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .md\:hover\:translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .md\:hover\:translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .md\:hover\:translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .md\:hover\:translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .md\:hover\:translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .md\:hover\:translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .md\:hover\:translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .md\:hover\:translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .md\:hover\:translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .md\:hover\:translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .md\:hover\:translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .md\:hover\:translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .md\:hover\:translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .md\:hover\:translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .md\:hover\:translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .md\:hover\:translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .md\:hover\:translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .md\:hover\:translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .md\:hover\:translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .md\:hover\:translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .md\:hover\:translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .md\:hover\:translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .md\:hover\:translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .md\:hover\:translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .md\:hover\:translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .md\:hover\:-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .md\:hover\:-translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .md\:hover\:-translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .md\:hover\:-translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .md\:hover\:-translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .md\:hover\:-translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .md\:hover\:-translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .md\:hover\:-translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .md\:hover\:-translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .md\:hover\:-translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .md\:hover\:-translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .md\:hover\:-translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .md\:hover\:-translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .md\:hover\:-translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .md\:hover\:-translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .md\:hover\:-translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .md\:hover\:-translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .md\:hover\:-translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .md\:hover\:-translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .md\:hover\:-translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .md\:hover\:-translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .md\:hover\:-translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .md\:hover\:-translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .md\:hover\:-translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .md\:hover\:-translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .md\:hover\:-translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .md\:hover\:-translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .md\:hover\:-translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .md\:hover\:-translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .md\:hover\:-translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .md\:hover\:-translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .md\:hover\:-translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .md\:hover\:-translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .md\:hover\:-translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .md\:hover\:-translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .md\:hover\:translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .md\:hover\:translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .md\:hover\:translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .md\:hover\:translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .md\:hover\:translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .md\:hover\:translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .md\:hover\:translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .md\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .md\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .md\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .md\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .md\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .md\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .md\:hover\:-translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .md\:hover\:translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .md\:hover\:translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .md\:hover\:translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .md\:hover\:translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .md\:hover\:translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .md\:hover\:translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .md\:hover\:translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .md\:hover\:translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .md\:hover\:translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .md\:hover\:translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .md\:hover\:translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .md\:hover\:translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .md\:hover\:translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .md\:hover\:translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .md\:hover\:translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .md\:hover\:translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .md\:hover\:translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .md\:hover\:translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .md\:hover\:translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .md\:hover\:translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .md\:hover\:translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .md\:hover\:translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .md\:hover\:translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .md\:hover\:translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .md\:hover\:translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .md\:hover\:translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .md\:hover\:translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .md\:hover\:translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .md\:hover\:translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .md\:hover\:translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .md\:hover\:translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .md\:hover\:translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .md\:hover\:translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .md\:hover\:translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .md\:hover\:translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .md\:hover\:-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .md\:hover\:-translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .md\:hover\:-translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .md\:hover\:-translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .md\:hover\:-translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .md\:hover\:-translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .md\:hover\:-translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .md\:hover\:-translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .md\:hover\:-translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .md\:hover\:-translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .md\:hover\:-translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .md\:hover\:-translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .md\:hover\:-translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .md\:hover\:-translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .md\:hover\:-translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .md\:hover\:-translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .md\:hover\:-translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .md\:hover\:-translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .md\:hover\:-translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .md\:hover\:-translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .md\:hover\:-translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .md\:hover\:-translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .md\:hover\:-translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .md\:hover\:-translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .md\:hover\:-translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .md\:hover\:-translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .md\:hover\:-translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .md\:hover\:-translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .md\:hover\:-translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .md\:hover\:-translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .md\:hover\:-translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .md\:hover\:-translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .md\:hover\:-translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .md\:hover\:-translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .md\:hover\:-translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .md\:hover\:translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .md\:hover\:translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .md\:hover\:translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .md\:hover\:translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .md\:hover\:translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .md\:hover\:translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .md\:hover\:translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .md\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .md\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .md\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .md\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .md\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .md\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .md\:hover\:-translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .md\:focus\:translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .md\:focus\:translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .md\:focus\:translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .md\:focus\:translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .md\:focus\:translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .md\:focus\:translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .md\:focus\:translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .md\:focus\:translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .md\:focus\:translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .md\:focus\:translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .md\:focus\:translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .md\:focus\:translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .md\:focus\:translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .md\:focus\:translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .md\:focus\:translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .md\:focus\:translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .md\:focus\:translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .md\:focus\:translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .md\:focus\:translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .md\:focus\:translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .md\:focus\:translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .md\:focus\:translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .md\:focus\:translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .md\:focus\:translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .md\:focus\:translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .md\:focus\:translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .md\:focus\:translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .md\:focus\:translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .md\:focus\:translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .md\:focus\:translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .md\:focus\:translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .md\:focus\:translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .md\:focus\:translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .md\:focus\:translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .md\:focus\:translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .md\:focus\:-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .md\:focus\:-translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .md\:focus\:-translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .md\:focus\:-translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .md\:focus\:-translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .md\:focus\:-translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .md\:focus\:-translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .md\:focus\:-translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .md\:focus\:-translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .md\:focus\:-translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .md\:focus\:-translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .md\:focus\:-translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .md\:focus\:-translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .md\:focus\:-translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .md\:focus\:-translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .md\:focus\:-translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .md\:focus\:-translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .md\:focus\:-translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .md\:focus\:-translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .md\:focus\:-translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .md\:focus\:-translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .md\:focus\:-translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .md\:focus\:-translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .md\:focus\:-translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .md\:focus\:-translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .md\:focus\:-translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .md\:focus\:-translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .md\:focus\:-translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .md\:focus\:-translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .md\:focus\:-translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .md\:focus\:-translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .md\:focus\:-translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .md\:focus\:-translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .md\:focus\:-translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .md\:focus\:-translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .md\:focus\:translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .md\:focus\:translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .md\:focus\:translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .md\:focus\:translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .md\:focus\:translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .md\:focus\:translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .md\:focus\:translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .md\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .md\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .md\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .md\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .md\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .md\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .md\:focus\:-translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .md\:focus\:translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .md\:focus\:translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .md\:focus\:translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .md\:focus\:translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .md\:focus\:translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .md\:focus\:translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .md\:focus\:translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .md\:focus\:translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .md\:focus\:translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .md\:focus\:translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .md\:focus\:translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .md\:focus\:translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .md\:focus\:translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .md\:focus\:translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .md\:focus\:translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .md\:focus\:translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .md\:focus\:translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .md\:focus\:translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .md\:focus\:translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .md\:focus\:translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .md\:focus\:translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .md\:focus\:translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .md\:focus\:translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .md\:focus\:translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .md\:focus\:translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .md\:focus\:translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .md\:focus\:translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .md\:focus\:translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .md\:focus\:translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .md\:focus\:translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .md\:focus\:translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .md\:focus\:translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .md\:focus\:translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .md\:focus\:translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .md\:focus\:translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .md\:focus\:-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .md\:focus\:-translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .md\:focus\:-translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .md\:focus\:-translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .md\:focus\:-translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .md\:focus\:-translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .md\:focus\:-translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .md\:focus\:-translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .md\:focus\:-translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .md\:focus\:-translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .md\:focus\:-translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .md\:focus\:-translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .md\:focus\:-translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .md\:focus\:-translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .md\:focus\:-translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .md\:focus\:-translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .md\:focus\:-translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .md\:focus\:-translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .md\:focus\:-translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .md\:focus\:-translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .md\:focus\:-translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .md\:focus\:-translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .md\:focus\:-translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .md\:focus\:-translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .md\:focus\:-translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .md\:focus\:-translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .md\:focus\:-translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .md\:focus\:-translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .md\:focus\:-translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .md\:focus\:-translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .md\:focus\:-translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .md\:focus\:-translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .md\:focus\:-translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .md\:focus\:-translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .md\:focus\:-translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .md\:focus\:translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .md\:focus\:translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .md\:focus\:translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .md\:focus\:translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .md\:focus\:translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .md\:focus\:translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .md\:focus\:translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .md\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .md\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .md\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .md\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .md\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .md\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .md\:focus\:-translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .md\:rotate-0{
    --tw-rotate: 0deg !important;
  }

  .md\:rotate-1{
    --tw-rotate: 1deg !important;
  }

  .md\:rotate-2{
    --tw-rotate: 2deg !important;
  }

  .md\:rotate-3{
    --tw-rotate: 3deg !important;
  }

  .md\:rotate-6{
    --tw-rotate: 6deg !important;
  }

  .md\:rotate-12{
    --tw-rotate: 12deg !important;
  }

  .md\:rotate-45{
    --tw-rotate: 45deg !important;
  }

  .md\:rotate-90{
    --tw-rotate: 90deg !important;
  }

  .md\:rotate-180{
    --tw-rotate: 180deg !important;
  }

  .md\:-rotate-180{
    --tw-rotate: -180deg !important;
  }

  .md\:-rotate-90{
    --tw-rotate: -90deg !important;
  }

  .md\:-rotate-45{
    --tw-rotate: -45deg !important;
  }

  .md\:-rotate-12{
    --tw-rotate: -12deg !important;
  }

  .md\:-rotate-6{
    --tw-rotate: -6deg !important;
  }

  .md\:-rotate-3{
    --tw-rotate: -3deg !important;
  }

  .md\:-rotate-2{
    --tw-rotate: -2deg !important;
  }

  .md\:-rotate-1{
    --tw-rotate: -1deg !important;
  }

  .md\:hover\:rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .md\:hover\:rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .md\:hover\:rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .md\:hover\:rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .md\:hover\:rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .md\:hover\:rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .md\:hover\:rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .md\:hover\:rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .md\:hover\:rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .md\:hover\:-rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .md\:hover\:-rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .md\:hover\:-rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .md\:hover\:-rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .md\:hover\:-rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .md\:hover\:-rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .md\:hover\:-rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .md\:hover\:-rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .md\:focus\:rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .md\:focus\:rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .md\:focus\:rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .md\:focus\:rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .md\:focus\:rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .md\:focus\:rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .md\:focus\:rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .md\:focus\:rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .md\:focus\:rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .md\:focus\:-rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .md\:focus\:-rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .md\:focus\:-rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .md\:focus\:-rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .md\:focus\:-rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .md\:focus\:-rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .md\:focus\:-rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .md\:focus\:-rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .md\:skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .md\:skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .md\:skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .md\:skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .md\:skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .md\:skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .md\:-skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .md\:-skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .md\:-skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .md\:-skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .md\:-skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .md\:skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .md\:skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .md\:skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .md\:skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .md\:skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .md\:skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .md\:-skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .md\:-skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .md\:-skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .md\:-skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .md\:-skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .md\:hover\:skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .md\:hover\:skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .md\:hover\:skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .md\:hover\:skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .md\:hover\:skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .md\:hover\:skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .md\:hover\:-skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .md\:hover\:-skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .md\:hover\:-skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .md\:hover\:-skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .md\:hover\:-skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .md\:hover\:skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .md\:hover\:skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .md\:hover\:skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .md\:hover\:skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .md\:hover\:skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .md\:hover\:skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .md\:hover\:-skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .md\:hover\:-skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .md\:hover\:-skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .md\:hover\:-skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .md\:hover\:-skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .md\:focus\:skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .md\:focus\:skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .md\:focus\:skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .md\:focus\:skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .md\:focus\:skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .md\:focus\:skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .md\:focus\:-skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .md\:focus\:-skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .md\:focus\:-skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .md\:focus\:-skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .md\:focus\:-skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .md\:focus\:skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .md\:focus\:skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .md\:focus\:skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .md\:focus\:skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .md\:focus\:skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .md\:focus\:skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .md\:focus\:-skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .md\:focus\:-skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .md\:focus\:-skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .md\:focus\:-skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .md\:focus\:-skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .md\:scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .md\:scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .md\:scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .md\:scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .md\:scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .md\:scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .md\:scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .md\:scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .md\:scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .md\:scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .md\:hover\:scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .md\:hover\:scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .md\:hover\:scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .md\:hover\:scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .md\:hover\:scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .md\:hover\:scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .md\:hover\:scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .md\:hover\:scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .md\:hover\:scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .md\:hover\:scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .md\:focus\:scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .md\:focus\:scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .md\:focus\:scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .md\:focus\:scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .md\:focus\:scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .md\:focus\:scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .md\:focus\:scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .md\:focus\:scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .md\:focus\:scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .md\:focus\:scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .md\:scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .md\:scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .md\:scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .md\:scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .md\:scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .md\:scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .md\:scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .md\:scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .md\:scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .md\:scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .md\:scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .md\:scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .md\:scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .md\:scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .md\:scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .md\:scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .md\:scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .md\:scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .md\:scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .md\:scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .md\:hover\:scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .md\:hover\:scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .md\:hover\:scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .md\:hover\:scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .md\:hover\:scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .md\:hover\:scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .md\:hover\:scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .md\:hover\:scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .md\:hover\:scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .md\:hover\:scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .md\:hover\:scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .md\:hover\:scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .md\:hover\:scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .md\:hover\:scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .md\:hover\:scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .md\:hover\:scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .md\:hover\:scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .md\:hover\:scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .md\:hover\:scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .md\:hover\:scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .md\:focus\:scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .md\:focus\:scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .md\:focus\:scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .md\:focus\:scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .md\:focus\:scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .md\:focus\:scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .md\:focus\:scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .md\:focus\:scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .md\:focus\:scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .md\:focus\:scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .md\:focus\:scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .md\:focus\:scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .md\:focus\:scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .md\:focus\:scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .md\:focus\:scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .md\:focus\:scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .md\:focus\:scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .md\:focus\:scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .md\:focus\:scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .md\:focus\:scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .md\:animate-none{
    animation: none !important;
  }

  .md\:animate-spin{
    animation: spin 1s linear infinite !important;
  }

  .md\:animate-ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .md\:animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .md\:animate-bounce{
    animation: bounce 1s infinite !important;
  }

  .md\:cursor-auto{
    cursor: auto !important;
  }

  .md\:cursor-default{
    cursor: default !important;
  }

  .md\:cursor-pointer{
    cursor: pointer !important;
  }

  .md\:cursor-wait{
    cursor: wait !important;
  }

  .md\:cursor-text{
    cursor: text !important;
  }

  .md\:cursor-move{
    cursor: move !important;
  }

  .md\:cursor-help{
    cursor: help !important;
  }

  .md\:cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .md\:select-none{
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .md\:select-text{
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .md\:select-all{
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .md\:select-auto{
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .md\:resize-none{
    resize: none !important;
  }

  .md\:resize-y{
    resize: vertical !important;
  }

  .md\:resize-x{
    resize: horizontal !important;
  }

  .md\:resize{
    resize: both !important;
  }

  .md\:list-inside{
    list-style-position: inside !important;
  }

  .md\:list-outside{
    list-style-position: outside !important;
  }

  .md\:list-none{
    list-style-type: none !important;
  }

  .md\:list-disc{
    list-style-type: disc !important;
  }

  .md\:list-decimal{
    list-style-type: decimal !important;
  }

  .md\:appearance-none{
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .md\:auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .md\:auto-cols-min{
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .md\:auto-cols-max{
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .md\:auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .md\:grid-flow-row{
    grid-auto-flow: row !important;
  }

  .md\:grid-flow-col{
    grid-auto-flow: column !important;
  }

  .md\:grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .md\:grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .md\:auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .md\:auto-rows-min{
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .md\:auto-rows-max{
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .md\:auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .md\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-none{
    grid-template-columns: none !important;
  }

  .md\:grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-none{
    grid-template-rows: none !important;
  }

  .md\:flex-row{
    flex-direction: row !important;
  }

  .md\:flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .md\:flex-col{
    flex-direction: column !important;
  }

  .md\:flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .md\:flex-wrap{
    flex-wrap: wrap !important;
  }

  .md\:flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .md\:flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .md\:place-content-center{
    place-content: center !important;
  }

  .md\:place-content-start{
    place-content: start !important;
  }

  .md\:place-content-end{
    place-content: end !important;
  }

  .md\:place-content-between{
    place-content: space-between !important;
  }

  .md\:place-content-around{
    place-content: space-around !important;
  }

  .md\:place-content-evenly{
    place-content: space-evenly !important;
  }

  .md\:place-content-stretch{
    place-content: stretch !important;
  }

  .md\:place-items-start{
    place-items: start !important;
  }

  .md\:place-items-end{
    place-items: end !important;
  }

  .md\:place-items-center{
    place-items: center !important;
  }

  .md\:place-items-stretch{
    place-items: stretch !important;
  }

  .md\:content-center{
    align-content: center !important;
  }

  .md\:content-start{
    align-content: flex-start !important;
  }

  .md\:content-end{
    align-content: flex-end !important;
  }

  .md\:content-between{
    align-content: space-between !important;
  }

  .md\:content-around{
    align-content: space-around !important;
  }

  .md\:content-evenly{
    align-content: space-evenly !important;
  }

  .md\:items-start{
    align-items: flex-start !important;
  }

  .md\:items-end{
    align-items: flex-end !important;
  }

  .md\:items-center{
    align-items: center !important;
  }

  .md\:items-baseline{
    align-items: baseline !important;
  }

  .md\:items-stretch{
    align-items: stretch !important;
  }

  .md\:justify-start{
    justify-content: flex-start !important;
  }

  .md\:justify-end{
    justify-content: flex-end !important;
  }

  .md\:justify-center{
    justify-content: center !important;
  }

  .md\:justify-between{
    justify-content: space-between !important;
  }

  .md\:justify-around{
    justify-content: space-around !important;
  }

  .md\:justify-evenly{
    justify-content: space-evenly !important;
  }

  .md\:justify-items-start{
    justify-items: start !important;
  }

  .md\:justify-items-end{
    justify-items: end !important;
  }

  .md\:justify-items-center{
    justify-items: center !important;
  }

  .md\:justify-items-stretch{
    justify-items: stretch !important;
  }

  .md\:gap-0{
    gap: 0px !important;
  }

  .md\:gap-1{
    gap: 0.25rem !important;
  }

  .md\:gap-2{
    gap: 0.5rem !important;
  }

  .md\:gap-3{
    gap: 0.75rem !important;
  }

  .md\:gap-4{
    gap: 1rem !important;
  }

  .md\:gap-5{
    gap: 1.25rem !important;
  }

  .md\:gap-6{
    gap: 1.5rem !important;
  }

  .md\:gap-7{
    gap: 1.75rem !important;
  }

  .md\:gap-8{
    gap: 2rem !important;
  }

  .md\:gap-9{
    gap: 2.25rem !important;
  }

  .md\:gap-10{
    gap: 2.5rem !important;
  }

  .md\:gap-11{
    gap: 2.75rem !important;
  }

  .md\:gap-12{
    gap: 3rem !important;
  }

  .md\:gap-14{
    gap: 3.5rem !important;
  }

  .md\:gap-16{
    gap: 4rem !important;
  }

  .md\:gap-20{
    gap: 5rem !important;
  }

  .md\:gap-24{
    gap: 6rem !important;
  }

  .md\:gap-28{
    gap: 7rem !important;
  }

  .md\:gap-32{
    gap: 8rem !important;
  }

  .md\:gap-36{
    gap: 9rem !important;
  }

  .md\:gap-40{
    gap: 10rem !important;
  }

  .md\:gap-44{
    gap: 11rem !important;
  }

  .md\:gap-48{
    gap: 12rem !important;
  }

  .md\:gap-52{
    gap: 13rem !important;
  }

  .md\:gap-56{
    gap: 14rem !important;
  }

  .md\:gap-60{
    gap: 15rem !important;
  }

  .md\:gap-64{
    gap: 16rem !important;
  }

  .md\:gap-72{
    gap: 18rem !important;
  }

  .md\:gap-80{
    gap: 20rem !important;
  }

  .md\:gap-96{
    gap: 24rem !important;
  }

  .md\:gap-px{
    gap: 1px !important;
  }

  .md\:gap-0\.5{
    gap: 0.125rem !important;
  }

  .md\:gap-1\.5{
    gap: 0.375rem !important;
  }

  .md\:gap-2\.5{
    gap: 0.625rem !important;
  }

  .md\:gap-3\.5{
    gap: 0.875rem !important;
  }

  .md\:gap-x-0{
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .md\:gap-x-1{
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .md\:gap-x-2{
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .md\:gap-x-3{
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .md\:gap-x-4{
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .md\:gap-x-5{
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .md\:gap-x-6{
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .md\:gap-x-7{
    -webkit-column-gap: 1.75rem !important;
            column-gap: 1.75rem !important;
  }

  .md\:gap-x-8{
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .md\:gap-x-9{
    -webkit-column-gap: 2.25rem !important;
            column-gap: 2.25rem !important;
  }

  .md\:gap-x-10{
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .md\:gap-x-11{
    -webkit-column-gap: 2.75rem !important;
            column-gap: 2.75rem !important;
  }

  .md\:gap-x-12{
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .md\:gap-x-14{
    -webkit-column-gap: 3.5rem !important;
            column-gap: 3.5rem !important;
  }

  .md\:gap-x-16{
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .md\:gap-x-20{
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .md\:gap-x-24{
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .md\:gap-x-28{
    -webkit-column-gap: 7rem !important;
            column-gap: 7rem !important;
  }

  .md\:gap-x-32{
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .md\:gap-x-36{
    -webkit-column-gap: 9rem !important;
            column-gap: 9rem !important;
  }

  .md\:gap-x-40{
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .md\:gap-x-44{
    -webkit-column-gap: 11rem !important;
            column-gap: 11rem !important;
  }

  .md\:gap-x-48{
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .md\:gap-x-52{
    -webkit-column-gap: 13rem !important;
            column-gap: 13rem !important;
  }

  .md\:gap-x-56{
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .md\:gap-x-60{
    -webkit-column-gap: 15rem !important;
            column-gap: 15rem !important;
  }

  .md\:gap-x-64{
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .md\:gap-x-72{
    -webkit-column-gap: 18rem !important;
            column-gap: 18rem !important;
  }

  .md\:gap-x-80{
    -webkit-column-gap: 20rem !important;
            column-gap: 20rem !important;
  }

  .md\:gap-x-96{
    -webkit-column-gap: 24rem !important;
            column-gap: 24rem !important;
  }

  .md\:gap-x-px{
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .md\:gap-x-0\.5{
    -webkit-column-gap: 0.125rem !important;
            column-gap: 0.125rem !important;
  }

  .md\:gap-x-1\.5{
    -webkit-column-gap: 0.375rem !important;
            column-gap: 0.375rem !important;
  }

  .md\:gap-x-2\.5{
    -webkit-column-gap: 0.625rem !important;
            column-gap: 0.625rem !important;
  }

  .md\:gap-x-3\.5{
    -webkit-column-gap: 0.875rem !important;
            column-gap: 0.875rem !important;
  }

  .md\:gap-y-0{
    row-gap: 0px !important;
  }

  .md\:gap-y-1{
    row-gap: 0.25rem !important;
  }

  .md\:gap-y-2{
    row-gap: 0.5rem !important;
  }

  .md\:gap-y-3{
    row-gap: 0.75rem !important;
  }

  .md\:gap-y-4{
    row-gap: 1rem !important;
  }

  .md\:gap-y-5{
    row-gap: 1.25rem !important;
  }

  .md\:gap-y-6{
    row-gap: 1.5rem !important;
  }

  .md\:gap-y-7{
    row-gap: 1.75rem !important;
  }

  .md\:gap-y-8{
    row-gap: 2rem !important;
  }

  .md\:gap-y-9{
    row-gap: 2.25rem !important;
  }

  .md\:gap-y-10{
    row-gap: 2.5rem !important;
  }

  .md\:gap-y-11{
    row-gap: 2.75rem !important;
  }

  .md\:gap-y-12{
    row-gap: 3rem !important;
  }

  .md\:gap-y-14{
    row-gap: 3.5rem !important;
  }

  .md\:gap-y-16{
    row-gap: 4rem !important;
  }

  .md\:gap-y-20{
    row-gap: 5rem !important;
  }

  .md\:gap-y-24{
    row-gap: 6rem !important;
  }

  .md\:gap-y-28{
    row-gap: 7rem !important;
  }

  .md\:gap-y-32{
    row-gap: 8rem !important;
  }

  .md\:gap-y-36{
    row-gap: 9rem !important;
  }

  .md\:gap-y-40{
    row-gap: 10rem !important;
  }

  .md\:gap-y-44{
    row-gap: 11rem !important;
  }

  .md\:gap-y-48{
    row-gap: 12rem !important;
  }

  .md\:gap-y-52{
    row-gap: 13rem !important;
  }

  .md\:gap-y-56{
    row-gap: 14rem !important;
  }

  .md\:gap-y-60{
    row-gap: 15rem !important;
  }

  .md\:gap-y-64{
    row-gap: 16rem !important;
  }

  .md\:gap-y-72{
    row-gap: 18rem !important;
  }

  .md\:gap-y-80{
    row-gap: 20rem !important;
  }

  .md\:gap-y-96{
    row-gap: 24rem !important;
  }

  .md\:gap-y-px{
    row-gap: 1px !important;
  }

  .md\:gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .md\:gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .md\:gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .md\:gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .md\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .md\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .md\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .md\:divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .md\:divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-qipGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-portalGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-divide-opacity)) !important;
  }

  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .md\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .md\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .md\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .md\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .md\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .md\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .md\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .md\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .md\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .md\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .md\:place-self-auto{
    place-self: auto !important;
  }

  .md\:place-self-start{
    place-self: start !important;
  }

  .md\:place-self-end{
    place-self: end !important;
  }

  .md\:place-self-center{
    place-self: center !important;
  }

  .md\:place-self-stretch{
    place-self: stretch !important;
  }

  .md\:self-auto{
    align-self: auto !important;
  }

  .md\:self-start{
    align-self: flex-start !important;
  }

  .md\:self-end{
    align-self: flex-end !important;
  }

  .md\:self-center{
    align-self: center !important;
  }

  .md\:self-stretch{
    align-self: stretch !important;
  }

  .md\:self-baseline{
    align-self: baseline !important;
  }

  .md\:justify-self-auto{
    justify-self: auto !important;
  }

  .md\:justify-self-start{
    justify-self: start !important;
  }

  .md\:justify-self-end{
    justify-self: end !important;
  }

  .md\:justify-self-center{
    justify-self: center !important;
  }

  .md\:justify-self-stretch{
    justify-self: stretch !important;
  }

  .md\:overflow-auto{
    overflow: auto !important;
  }

  .md\:overflow-hidden{
    overflow: hidden !important;
  }

  .md\:overflow-visible{
    overflow: visible !important;
  }

  .md\:overflow-scroll{
    overflow: scroll !important;
  }

  .md\:overflow-x-auto{
    overflow-x: auto !important;
  }

  .md\:overflow-y-auto{
    overflow-y: auto !important;
  }

  .md\:overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .md\:overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .md\:overflow-x-visible{
    overflow-x: visible !important;
  }

  .md\:overflow-y-visible{
    overflow-y: visible !important;
  }

  .md\:overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .md\:overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .md\:overscroll-auto{
    -ms-scroll-chaining: chained !important;
        overscroll-behavior: auto !important;
  }

  .md\:overscroll-contain{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: contain !important;
  }

  .md\:overscroll-none{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: none !important;
  }

  .md\:overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .md\:overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .md\:overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .md\:overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .md\:overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .md\:overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .md\:truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .md\:overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .md\:overflow-clip{
    text-overflow: clip !important;
  }

  .md\:whitespace-normal{
    white-space: normal !important;
  }

  .md\:whitespace-nowrap{
    white-space: nowrap !important;
  }

  .md\:whitespace-pre{
    white-space: pre !important;
  }

  .md\:whitespace-pre-line{
    white-space: pre-line !important;
  }

  .md\:whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .md\:break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .md\:break-words{
    overflow-wrap: break-word !important;
  }

  .md\:break-all{
    word-break: break-all !important;
  }

  .md\:rounded-none{
    border-radius: 0px !important;
  }

  .md\:rounded-sm{
    border-radius: 0.125rem !important;
  }

  .md\:rounded{
    border-radius: 0.25rem !important;
  }

  .md\:rounded-md{
    border-radius: 0.375rem !important;
  }

  .md\:rounded-lg{
    border-radius: 0.5rem !important;
  }

  .md\:rounded-xl{
    border-radius: 0.75rem !important;
  }

  .md\:rounded-2xl{
    border-radius: 1rem !important;
  }

  .md\:rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .md\:rounded-full{
    border-radius: 9999px !important;
  }

  .md\:rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .md\:rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .md\:rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .md\:rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .md\:rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .md\:rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .md\:rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .md\:rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .md\:rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .md\:rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .md\:rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .md\:rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .md\:rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .md\:rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .md\:rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .md\:rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .md\:rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .md\:rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .md\:rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .md\:rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .md\:rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .md\:rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .md\:rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .md\:rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .md\:rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .md\:rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .md\:rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .md\:rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .md\:rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .md\:rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .md\:rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .md\:rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .md\:rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .md\:rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .md\:rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .md\:rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .md\:rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .md\:rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .md\:rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .md\:rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .md\:rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .md\:border-0{
    border-width: 0px !important;
  }

  .md\:border-2{
    border-width: 2px !important;
  }

  .md\:border-4{
    border-width: 4px !important;
  }

  .md\:border-8{
    border-width: 8px !important;
  }

  .md\:border{
    border-width: 1px !important;
  }

  .md\:border-t-0{
    border-top-width: 0px !important;
  }

  .md\:border-t-2{
    border-top-width: 2px !important;
  }

  .md\:border-t-4{
    border-top-width: 4px !important;
  }

  .md\:border-t-8{
    border-top-width: 8px !important;
  }

  .md\:border-t{
    border-top-width: 1px !important;
  }

  .md\:border-r-0{
    border-right-width: 0px !important;
  }

  .md\:border-r-2{
    border-right-width: 2px !important;
  }

  .md\:border-r-4{
    border-right-width: 4px !important;
  }

  .md\:border-r-8{
    border-right-width: 8px !important;
  }

  .md\:border-r{
    border-right-width: 1px !important;
  }

  .md\:border-b-0{
    border-bottom-width: 0px !important;
  }

  .md\:border-b-2{
    border-bottom-width: 2px !important;
  }

  .md\:border-b-4{
    border-bottom-width: 4px !important;
  }

  .md\:border-b-8{
    border-bottom-width: 8px !important;
  }

  .md\:border-b{
    border-bottom-width: 1px !important;
  }

  .md\:border-l-0{
    border-left-width: 0px !important;
  }

  .md\:border-l-2{
    border-left-width: 2px !important;
  }

  .md\:border-l-4{
    border-left-width: 4px !important;
  }

  .md\:border-l-8{
    border-left-width: 8px !important;
  }

  .md\:border-l{
    border-left-width: 1px !important;
  }

  .md\:border-solid{
    border-style: solid !important;
  }

  .md\:border-dashed{
    border-style: dashed !important;
  }

  .md\:border-dotted{
    border-style: dotted !important;
  }

  .md\:border-double{
    border-style: double !important;
  }

  .md\:border-none{
    border-style: none !important;
  }

  .md\:border-transparent{
    border-color: transparent !important;
  }

  .md\:border-current{
    border-color: currentColor !important;
  }

  .md\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .md\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-transparent{
    border-color: transparent !important;
  }

  .group:hover .md\:group-hover\:border-current{
    border-color: currentColor !important;
  }

  .group:hover .md\:group-hover\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .group:hover .md\:group-hover\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-transparent:focus-within{
    border-color: transparent !important;
  }

  .md\:focus-within\:border-current:focus-within{
    border-color: currentColor !important;
  }

  .md\:focus-within\:border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-qipGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .md\:focus-within\:border-portalGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-transparent:hover{
    border-color: transparent !important;
  }

  .md\:hover\:border-current:hover{
    border-color: currentColor !important;
  }

  .md\:hover\:border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-qipGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .md\:hover\:border-portalGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-transparent:focus{
    border-color: transparent !important;
  }

  .md\:focus\:border-current:focus{
    border-color: currentColor !important;
  }

  .md\:focus\:border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-qipGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .md\:focus\:border-portalGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .md\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .md\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .group:hover .md\:group-hover\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .md\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .md\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .md\:hover\:border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .md\:hover\:border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:hover\:border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:hover\:border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:hover\:border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:hover\:border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:hover\:border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:hover\:border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:hover\:border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:hover\:border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:hover\:border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:hover\:border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:hover\:border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:hover\:border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:hover\:border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .md\:focus\:border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .md\:focus\:border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .md\:focus\:border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .md\:focus\:border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .md\:focus\:border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .md\:focus\:border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .md\:focus\:border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .md\:focus\:border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .md\:focus\:border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .md\:focus\:border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .md\:focus\:border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .md\:focus\:border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .md\:focus\:border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .md\:focus\:border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .md\:focus\:border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .md\:bg-transparent{
    background-color: transparent !important;
  }

  .md\:bg-current{
    background-color: currentColor !important;
  }

  .md\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-transparent{
    background-color: transparent !important;
  }

  .group:hover .md\:group-hover\:bg-current{
    background-color: currentColor !important;
  }

  .group:hover .md\:group-hover\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .group:hover .md\:group-hover\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .md\:focus-within\:bg-current:focus-within{
    background-color: currentColor !important;
  }

  .md\:focus-within\:bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-qipGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:focus-within\:bg-portalGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-transparent:hover{
    background-color: transparent !important;
  }

  .md\:hover\:bg-current:hover{
    background-color: currentColor !important;
  }

  .md\:hover\:bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-qipGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:hover\:bg-portalGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-transparent:focus{
    background-color: transparent !important;
  }

  .md\:focus\:bg-current:focus{
    background-color: currentColor !important;
  }

  .md\:focus\:bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-qipGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .md\:focus\:bg-portalGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .md\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .md\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .group:hover .md\:group-hover\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .md\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .md\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .md\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .md\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .md\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .md\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .md\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .md\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .md\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .md\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .md\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .md\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .md\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .md\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .md\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .md\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .md\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .md\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .md\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .md\:bg-none{
    background-image: none !important;
  }

  .md\:bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .md\:bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .md\:from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:from-qipGreen{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .md\:from-portalGreen{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .md\:hover\:from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:hover\:from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:hover\:from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:hover\:from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:hover\:from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:hover\:from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:hover\:from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:hover\:from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:hover\:from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:hover\:from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:hover\:from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:hover\:from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:hover\:from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:hover\:from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:hover\:from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:hover\:from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:hover\:from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:hover\:from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:hover\:from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:hover\:from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:hover\:from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:hover\:from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:hover\:from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:hover\:from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:hover\:from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:hover\:from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:hover\:from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:hover\:from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:hover\:from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:hover\:from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:hover\:from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:hover\:from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:hover\:from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:hover\:from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:hover\:from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:hover\:from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:hover\:from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:hover\:from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:hover\:from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:hover\:from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:hover\:from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:hover\:from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:hover\:from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:hover\:from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:hover\:from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:hover\:from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:hover\:from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:hover\:from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:hover\:from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:hover\:from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:hover\:from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:hover\:from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:hover\:from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:hover\:from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:hover\:from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:hover\:from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:hover\:from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:hover\:from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:hover\:from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:hover\:from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:hover\:from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:hover\:from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:hover\:from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:hover\:from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:hover\:from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:hover\:from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:hover\:from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:hover\:from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:hover\:from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:hover\:from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:hover\:from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:hover\:from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:hover\:from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:hover\:from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:hover\:from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:hover\:from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:hover\:from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:hover\:from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:hover\:from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:hover\:from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:hover\:from-qipGreen:hover{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .md\:hover\:from-portalGreen:hover{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .md\:focus\:from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:focus\:from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:focus\:from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:focus\:from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:focus\:from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:focus\:from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:focus\:from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:focus\:from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:focus\:from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:focus\:from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:focus\:from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:focus\:from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:focus\:from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:focus\:from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:focus\:from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:focus\:from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:focus\:from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:focus\:from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:focus\:from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:focus\:from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:focus\:from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:focus\:from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:focus\:from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:focus\:from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:focus\:from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:focus\:from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:focus\:from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:focus\:from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:focus\:from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:focus\:from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:focus\:from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:focus\:from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:focus\:from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:focus\:from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:focus\:from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:focus\:from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:focus\:from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:focus\:from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:focus\:from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:focus\:from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:focus\:from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:focus\:from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:focus\:from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:focus\:from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:focus\:from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:focus\:from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:focus\:from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:focus\:from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:focus\:from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:focus\:from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:focus\:from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:focus\:from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:focus\:from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:focus\:from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:focus\:from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:focus\:from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:focus\:from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:focus\:from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:focus\:from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:focus\:from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:focus\:from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:focus\:from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:focus\:from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:focus\:from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:focus\:from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:focus\:from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:focus\:from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:focus\:from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:focus\:from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:focus\:from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:focus\:from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:focus\:from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:focus\:from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:focus\:from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:focus\:from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:focus\:from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:focus\:from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:focus\:from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:focus\:from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:focus\:from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:focus\:from-qipGreen:focus{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .md\:focus\:from-portalGreen:focus{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .md\:via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:via-qipGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .md\:via-portalGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .md\:hover\:via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:hover\:via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:hover\:via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:hover\:via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:hover\:via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:hover\:via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:hover\:via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:hover\:via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:hover\:via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:hover\:via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:hover\:via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:hover\:via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:hover\:via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:hover\:via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:hover\:via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:hover\:via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:hover\:via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:hover\:via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:hover\:via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:hover\:via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:hover\:via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:hover\:via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:hover\:via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:hover\:via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:hover\:via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:hover\:via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:hover\:via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:hover\:via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:hover\:via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:hover\:via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:hover\:via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:hover\:via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:hover\:via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:hover\:via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:hover\:via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:hover\:via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:hover\:via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:hover\:via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:hover\:via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:hover\:via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:hover\:via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:hover\:via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:hover\:via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:hover\:via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:hover\:via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:hover\:via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:hover\:via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:hover\:via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:hover\:via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:hover\:via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:hover\:via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:hover\:via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:hover\:via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:hover\:via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:hover\:via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:hover\:via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:hover\:via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:hover\:via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:hover\:via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:hover\:via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:hover\:via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:hover\:via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:hover\:via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:hover\:via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:hover\:via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:hover\:via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:hover\:via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:hover\:via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:hover\:via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:hover\:via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:hover\:via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:hover\:via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:hover\:via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:hover\:via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:hover\:via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:hover\:via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:hover\:via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:hover\:via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:hover\:via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:hover\:via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:hover\:via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:hover\:via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:hover\:via-qipGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .md\:hover\:via-portalGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .md\:focus\:via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .md\:focus\:via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .md\:focus\:via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .md\:focus\:via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .md\:focus\:via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .md\:focus\:via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .md\:focus\:via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .md\:focus\:via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .md\:focus\:via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .md\:focus\:via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .md\:focus\:via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .md\:focus\:via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .md\:focus\:via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .md\:focus\:via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .md\:focus\:via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .md\:focus\:via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .md\:focus\:via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .md\:focus\:via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .md\:focus\:via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .md\:focus\:via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .md\:focus\:via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .md\:focus\:via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .md\:focus\:via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .md\:focus\:via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .md\:focus\:via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .md\:focus\:via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .md\:focus\:via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .md\:focus\:via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .md\:focus\:via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .md\:focus\:via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .md\:focus\:via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .md\:focus\:via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .md\:focus\:via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .md\:focus\:via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .md\:focus\:via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .md\:focus\:via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .md\:focus\:via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .md\:focus\:via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .md\:focus\:via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .md\:focus\:via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .md\:focus\:via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .md\:focus\:via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .md\:focus\:via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .md\:focus\:via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .md\:focus\:via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .md\:focus\:via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .md\:focus\:via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .md\:focus\:via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .md\:focus\:via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .md\:focus\:via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .md\:focus\:via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .md\:focus\:via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .md\:focus\:via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .md\:focus\:via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .md\:focus\:via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .md\:focus\:via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .md\:focus\:via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .md\:focus\:via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .md\:focus\:via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .md\:focus\:via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .md\:focus\:via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .md\:focus\:via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .md\:focus\:via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .md\:focus\:via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .md\:focus\:via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .md\:focus\:via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .md\:focus\:via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .md\:focus\:via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .md\:focus\:via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .md\:focus\:via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .md\:focus\:via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .md\:focus\:via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .md\:focus\:via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .md\:focus\:via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .md\:focus\:via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .md\:focus\:via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .md\:focus\:via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .md\:focus\:via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .md\:focus\:via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .md\:focus\:via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .md\:focus\:via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .md\:focus\:via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .md\:focus\:via-qipGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .md\:focus\:via-portalGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .md\:to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .md\:to-current{
    --tw-gradient-to: currentColor !important;
  }

  .md\:to-black{
    --tw-gradient-to: #000 !important;
  }

  .md\:to-white{
    --tw-gradient-to: #fff !important;
  }

  .md\:to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .md\:to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .md\:to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .md\:to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .md\:to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .md\:to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .md\:to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .md\:to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .md\:to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .md\:to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .md\:to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .md\:to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .md\:to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .md\:to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .md\:to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .md\:to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .md\:to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .md\:to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .md\:to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .md\:to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .md\:to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .md\:to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .md\:to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .md\:to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .md\:to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .md\:to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .md\:to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .md\:to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .md\:to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .md\:to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .md\:to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .md\:to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .md\:to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .md\:to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .md\:to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .md\:to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .md\:to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .md\:to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .md\:to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .md\:to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .md\:to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .md\:to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .md\:to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .md\:to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .md\:to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .md\:to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .md\:to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .md\:to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .md\:to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .md\:to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .md\:to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .md\:to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .md\:to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .md\:to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .md\:to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .md\:to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .md\:to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .md\:to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .md\:to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .md\:to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .md\:to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .md\:to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .md\:to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .md\:to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .md\:to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .md\:to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .md\:to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .md\:to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .md\:to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .md\:to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .md\:to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .md\:to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .md\:to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .md\:to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .md\:to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .md\:to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .md\:to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .md\:to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .md\:to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .md\:to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .md\:to-qipGreen{
    --tw-gradient-to: #8bc334 !important;
  }

  .md\:to-portalGreen{
    --tw-gradient-to: #8ac04a !important;
  }

  .md\:hover\:to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .md\:hover\:to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .md\:hover\:to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .md\:hover\:to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .md\:hover\:to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .md\:hover\:to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .md\:hover\:to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .md\:hover\:to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .md\:hover\:to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .md\:hover\:to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .md\:hover\:to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .md\:hover\:to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .md\:hover\:to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .md\:hover\:to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .md\:hover\:to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .md\:hover\:to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .md\:hover\:to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .md\:hover\:to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .md\:hover\:to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .md\:hover\:to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .md\:hover\:to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .md\:hover\:to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .md\:hover\:to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .md\:hover\:to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .md\:hover\:to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .md\:hover\:to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .md\:hover\:to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .md\:hover\:to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .md\:hover\:to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .md\:hover\:to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .md\:hover\:to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .md\:hover\:to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .md\:hover\:to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .md\:hover\:to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .md\:hover\:to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .md\:hover\:to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .md\:hover\:to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .md\:hover\:to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .md\:hover\:to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .md\:hover\:to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .md\:hover\:to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .md\:hover\:to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .md\:hover\:to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .md\:hover\:to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .md\:hover\:to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .md\:hover\:to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .md\:hover\:to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .md\:hover\:to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .md\:hover\:to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .md\:hover\:to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .md\:hover\:to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .md\:hover\:to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .md\:hover\:to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .md\:hover\:to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .md\:hover\:to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .md\:hover\:to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .md\:hover\:to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .md\:hover\:to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .md\:hover\:to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .md\:hover\:to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .md\:hover\:to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .md\:hover\:to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .md\:hover\:to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .md\:hover\:to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .md\:hover\:to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .md\:hover\:to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .md\:hover\:to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .md\:hover\:to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .md\:hover\:to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .md\:hover\:to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .md\:hover\:to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .md\:hover\:to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .md\:hover\:to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .md\:hover\:to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .md\:hover\:to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .md\:hover\:to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .md\:hover\:to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .md\:hover\:to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .md\:hover\:to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .md\:hover\:to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .md\:hover\:to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .md\:hover\:to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .md\:hover\:to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .md\:hover\:to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .md\:hover\:to-qipGreen:hover{
    --tw-gradient-to: #8bc334 !important;
  }

  .md\:hover\:to-portalGreen:hover{
    --tw-gradient-to: #8ac04a !important;
  }

  .md\:focus\:to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .md\:focus\:to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .md\:focus\:to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .md\:focus\:to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .md\:focus\:to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .md\:focus\:to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .md\:focus\:to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .md\:focus\:to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .md\:focus\:to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .md\:focus\:to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .md\:focus\:to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .md\:focus\:to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .md\:focus\:to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .md\:focus\:to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .md\:focus\:to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .md\:focus\:to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .md\:focus\:to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .md\:focus\:to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .md\:focus\:to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .md\:focus\:to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .md\:focus\:to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .md\:focus\:to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .md\:focus\:to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .md\:focus\:to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .md\:focus\:to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .md\:focus\:to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .md\:focus\:to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .md\:focus\:to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .md\:focus\:to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .md\:focus\:to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .md\:focus\:to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .md\:focus\:to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .md\:focus\:to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .md\:focus\:to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .md\:focus\:to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .md\:focus\:to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .md\:focus\:to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .md\:focus\:to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .md\:focus\:to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .md\:focus\:to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .md\:focus\:to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .md\:focus\:to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .md\:focus\:to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .md\:focus\:to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .md\:focus\:to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .md\:focus\:to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .md\:focus\:to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .md\:focus\:to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .md\:focus\:to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .md\:focus\:to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .md\:focus\:to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .md\:focus\:to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .md\:focus\:to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .md\:focus\:to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .md\:focus\:to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .md\:focus\:to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .md\:focus\:to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .md\:focus\:to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .md\:focus\:to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .md\:focus\:to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .md\:focus\:to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .md\:focus\:to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .md\:focus\:to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .md\:focus\:to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .md\:focus\:to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .md\:focus\:to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .md\:focus\:to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .md\:focus\:to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .md\:focus\:to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .md\:focus\:to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .md\:focus\:to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .md\:focus\:to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .md\:focus\:to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .md\:focus\:to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .md\:focus\:to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .md\:focus\:to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .md\:focus\:to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .md\:focus\:to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .md\:focus\:to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .md\:focus\:to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .md\:focus\:to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .md\:focus\:to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .md\:focus\:to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .md\:focus\:to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .md\:focus\:to-qipGreen:focus{
    --tw-gradient-to: #8bc334 !important;
  }

  .md\:focus\:to-portalGreen:focus{
    --tw-gradient-to: #8ac04a !important;
  }

  .md\:decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .md\:decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .md\:bg-auto{
    background-size: auto !important;
  }

  .md\:bg-cover{
    background-size: cover !important;
  }

  .md\:bg-contain{
    background-size: contain !important;
  }

  .md\:bg-fixed{
    background-attachment: fixed !important;
  }

  .md\:bg-local{
    background-attachment: local !important;
  }

  .md\:bg-scroll{
    background-attachment: scroll !important;
  }

  .md\:bg-clip-border{
    background-clip: border-box !important;
  }

  .md\:bg-clip-padding{
    background-clip: padding-box !important;
  }

  .md\:bg-clip-content{
    background-clip: content-box !important;
  }

  .md\:bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .md\:bg-bottom{
    background-position: bottom !important;
  }

  .md\:bg-center{
    background-position: center !important;
  }

  .md\:bg-left{
    background-position: left !important;
  }

  .md\:bg-left-bottom{
    background-position: left bottom !important;
  }

  .md\:bg-left-top{
    background-position: left top !important;
  }

  .md\:bg-right{
    background-position: right !important;
  }

  .md\:bg-right-bottom{
    background-position: right bottom !important;
  }

  .md\:bg-right-top{
    background-position: right top !important;
  }

  .md\:bg-top{
    background-position: top !important;
  }

  .md\:bg-repeat{
    background-repeat: repeat !important;
  }

  .md\:bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .md\:bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .md\:bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .md\:bg-repeat-round{
    background-repeat: round !important;
  }

  .md\:bg-repeat-space{
    background-repeat: space !important;
  }

  .md\:bg-origin-border{
    background-origin: border-box !important;
  }

  .md\:bg-origin-padding{
    background-origin: padding-box !important;
  }

  .md\:bg-origin-content{
    background-origin: content-box !important;
  }

  .md\:fill-current{
    fill: currentColor !important;
  }

  .md\:stroke-current{
    stroke: currentColor !important;
  }

  .md\:stroke-0{
    stroke-width: 0 !important;
  }

  .md\:stroke-1{
    stroke-width: 1 !important;
  }

  .md\:stroke-2{
    stroke-width: 2 !important;
  }

  .md\:object-contain{
    object-fit: contain !important;
  }

  .md\:object-cover{
    object-fit: cover !important;
  }

  .md\:object-fill{
    object-fit: fill !important;
  }

  .md\:object-none{
    object-fit: none !important;
  }

  .md\:object-scale-down{
    object-fit: scale-down !important;
  }

  .md\:object-bottom{
    object-position: bottom !important;
  }

  .md\:object-center{
    object-position: center !important;
  }

  .md\:object-left{
    object-position: left !important;
  }

  .md\:object-left-bottom{
    object-position: left bottom !important;
  }

  .md\:object-left-top{
    object-position: left top !important;
  }

  .md\:object-right{
    object-position: right !important;
  }

  .md\:object-right-bottom{
    object-position: right bottom !important;
  }

  .md\:object-right-top{
    object-position: right top !important;
  }

  .md\:object-top{
    object-position: top !important;
  }

  .md\:p-0{
    padding: 0px !important;
  }

  .md\:p-1{
    padding: 0.25rem !important;
  }

  .md\:p-2{
    padding: 0.5rem !important;
  }

  .md\:p-3{
    padding: 0.75rem !important;
  }

  .md\:p-4{
    padding: 1rem !important;
  }

  .md\:p-5{
    padding: 1.25rem !important;
  }

  .md\:p-6{
    padding: 1.5rem !important;
  }

  .md\:p-7{
    padding: 1.75rem !important;
  }

  .md\:p-8{
    padding: 2rem !important;
  }

  .md\:p-9{
    padding: 2.25rem !important;
  }

  .md\:p-10{
    padding: 2.5rem !important;
  }

  .md\:p-11{
    padding: 2.75rem !important;
  }

  .md\:p-12{
    padding: 3rem !important;
  }

  .md\:p-14{
    padding: 3.5rem !important;
  }

  .md\:p-16{
    padding: 4rem !important;
  }

  .md\:p-20{
    padding: 5rem !important;
  }

  .md\:p-24{
    padding: 6rem !important;
  }

  .md\:p-28{
    padding: 7rem !important;
  }

  .md\:p-32{
    padding: 8rem !important;
  }

  .md\:p-36{
    padding: 9rem !important;
  }

  .md\:p-40{
    padding: 10rem !important;
  }

  .md\:p-44{
    padding: 11rem !important;
  }

  .md\:p-48{
    padding: 12rem !important;
  }

  .md\:p-52{
    padding: 13rem !important;
  }

  .md\:p-56{
    padding: 14rem !important;
  }

  .md\:p-60{
    padding: 15rem !important;
  }

  .md\:p-64{
    padding: 16rem !important;
  }

  .md\:p-72{
    padding: 18rem !important;
  }

  .md\:p-80{
    padding: 20rem !important;
  }

  .md\:p-96{
    padding: 24rem !important;
  }

  .md\:p-px{
    padding: 1px !important;
  }

  .md\:p-0\.5{
    padding: 0.125rem !important;
  }

  .md\:p-1\.5{
    padding: 0.375rem !important;
  }

  .md\:p-2\.5{
    padding: 0.625rem !important;
  }

  .md\:p-3\.5{
    padding: 0.875rem !important;
  }

  .md\:px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .md\:px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .md\:px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .md\:px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .md\:px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .md\:px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .md\:px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .md\:px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .md\:px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .md\:px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .md\:px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .md\:px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .md\:px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .md\:px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .md\:px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .md\:px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .md\:px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .md\:px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .md\:px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .md\:px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .md\:px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .md\:px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .md\:px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .md\:px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .md\:px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .md\:px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .md\:px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .md\:py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .md\:py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .md\:py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .md\:py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .md\:py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .md\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .md\:py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .md\:py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .md\:py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .md\:py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .md\:py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .md\:py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .md\:py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .md\:py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .md\:py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .md\:py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .md\:py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .md\:py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .md\:py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .md\:py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .md\:py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .md\:py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .md\:py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .md\:py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .md\:py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .md\:py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .md\:py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .md\:py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .md\:py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .md\:pt-0{
    padding-top: 0px !important;
  }

  .md\:pt-1{
    padding-top: 0.25rem !important;
  }

  .md\:pt-2{
    padding-top: 0.5rem !important;
  }

  .md\:pt-3{
    padding-top: 0.75rem !important;
  }

  .md\:pt-4{
    padding-top: 1rem !important;
  }

  .md\:pt-5{
    padding-top: 1.25rem !important;
  }

  .md\:pt-6{
    padding-top: 1.5rem !important;
  }

  .md\:pt-7{
    padding-top: 1.75rem !important;
  }

  .md\:pt-8{
    padding-top: 2rem !important;
  }

  .md\:pt-9{
    padding-top: 2.25rem !important;
  }

  .md\:pt-10{
    padding-top: 2.5rem !important;
  }

  .md\:pt-11{
    padding-top: 2.75rem !important;
  }

  .md\:pt-12{
    padding-top: 3rem !important;
  }

  .md\:pt-14{
    padding-top: 3.5rem !important;
  }

  .md\:pt-16{
    padding-top: 4rem !important;
  }

  .md\:pt-20{
    padding-top: 5rem !important;
  }

  .md\:pt-24{
    padding-top: 6rem !important;
  }

  .md\:pt-28{
    padding-top: 7rem !important;
  }

  .md\:pt-32{
    padding-top: 8rem !important;
  }

  .md\:pt-36{
    padding-top: 9rem !important;
  }

  .md\:pt-40{
    padding-top: 10rem !important;
  }

  .md\:pt-44{
    padding-top: 11rem !important;
  }

  .md\:pt-48{
    padding-top: 12rem !important;
  }

  .md\:pt-52{
    padding-top: 13rem !important;
  }

  .md\:pt-56{
    padding-top: 14rem !important;
  }

  .md\:pt-60{
    padding-top: 15rem !important;
  }

  .md\:pt-64{
    padding-top: 16rem !important;
  }

  .md\:pt-72{
    padding-top: 18rem !important;
  }

  .md\:pt-80{
    padding-top: 20rem !important;
  }

  .md\:pt-96{
    padding-top: 24rem !important;
  }

  .md\:pt-px{
    padding-top: 1px !important;
  }

  .md\:pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .md\:pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .md\:pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .md\:pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .md\:pr-0{
    padding-right: 0px !important;
  }

  .md\:pr-1{
    padding-right: 0.25rem !important;
  }

  .md\:pr-2{
    padding-right: 0.5rem !important;
  }

  .md\:pr-3{
    padding-right: 0.75rem !important;
  }

  .md\:pr-4{
    padding-right: 1rem !important;
  }

  .md\:pr-5{
    padding-right: 1.25rem !important;
  }

  .md\:pr-6{
    padding-right: 1.5rem !important;
  }

  .md\:pr-7{
    padding-right: 1.75rem !important;
  }

  .md\:pr-8{
    padding-right: 2rem !important;
  }

  .md\:pr-9{
    padding-right: 2.25rem !important;
  }

  .md\:pr-10{
    padding-right: 2.5rem !important;
  }

  .md\:pr-11{
    padding-right: 2.75rem !important;
  }

  .md\:pr-12{
    padding-right: 3rem !important;
  }

  .md\:pr-14{
    padding-right: 3.5rem !important;
  }

  .md\:pr-16{
    padding-right: 4rem !important;
  }

  .md\:pr-20{
    padding-right: 5rem !important;
  }

  .md\:pr-24{
    padding-right: 6rem !important;
  }

  .md\:pr-28{
    padding-right: 7rem !important;
  }

  .md\:pr-32{
    padding-right: 8rem !important;
  }

  .md\:pr-36{
    padding-right: 9rem !important;
  }

  .md\:pr-40{
    padding-right: 10rem !important;
  }

  .md\:pr-44{
    padding-right: 11rem !important;
  }

  .md\:pr-48{
    padding-right: 12rem !important;
  }

  .md\:pr-52{
    padding-right: 13rem !important;
  }

  .md\:pr-56{
    padding-right: 14rem !important;
  }

  .md\:pr-60{
    padding-right: 15rem !important;
  }

  .md\:pr-64{
    padding-right: 16rem !important;
  }

  .md\:pr-72{
    padding-right: 18rem !important;
  }

  .md\:pr-80{
    padding-right: 20rem !important;
  }

  .md\:pr-96{
    padding-right: 24rem !important;
  }

  .md\:pr-px{
    padding-right: 1px !important;
  }

  .md\:pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .md\:pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .md\:pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .md\:pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .md\:pb-0{
    padding-bottom: 0px !important;
  }

  .md\:pb-1{
    padding-bottom: 0.25rem !important;
  }

  .md\:pb-2{
    padding-bottom: 0.5rem !important;
  }

  .md\:pb-3{
    padding-bottom: 0.75rem !important;
  }

  .md\:pb-4{
    padding-bottom: 1rem !important;
  }

  .md\:pb-5{
    padding-bottom: 1.25rem !important;
  }

  .md\:pb-6{
    padding-bottom: 1.5rem !important;
  }

  .md\:pb-7{
    padding-bottom: 1.75rem !important;
  }

  .md\:pb-8{
    padding-bottom: 2rem !important;
  }

  .md\:pb-9{
    padding-bottom: 2.25rem !important;
  }

  .md\:pb-10{
    padding-bottom: 2.5rem !important;
  }

  .md\:pb-11{
    padding-bottom: 2.75rem !important;
  }

  .md\:pb-12{
    padding-bottom: 3rem !important;
  }

  .md\:pb-14{
    padding-bottom: 3.5rem !important;
  }

  .md\:pb-16{
    padding-bottom: 4rem !important;
  }

  .md\:pb-20{
    padding-bottom: 5rem !important;
  }

  .md\:pb-24{
    padding-bottom: 6rem !important;
  }

  .md\:pb-28{
    padding-bottom: 7rem !important;
  }

  .md\:pb-32{
    padding-bottom: 8rem !important;
  }

  .md\:pb-36{
    padding-bottom: 9rem !important;
  }

  .md\:pb-40{
    padding-bottom: 10rem !important;
  }

  .md\:pb-44{
    padding-bottom: 11rem !important;
  }

  .md\:pb-48{
    padding-bottom: 12rem !important;
  }

  .md\:pb-52{
    padding-bottom: 13rem !important;
  }

  .md\:pb-56{
    padding-bottom: 14rem !important;
  }

  .md\:pb-60{
    padding-bottom: 15rem !important;
  }

  .md\:pb-64{
    padding-bottom: 16rem !important;
  }

  .md\:pb-72{
    padding-bottom: 18rem !important;
  }

  .md\:pb-80{
    padding-bottom: 20rem !important;
  }

  .md\:pb-96{
    padding-bottom: 24rem !important;
  }

  .md\:pb-px{
    padding-bottom: 1px !important;
  }

  .md\:pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .md\:pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .md\:pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .md\:pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .md\:pl-0{
    padding-left: 0px !important;
  }

  .md\:pl-1{
    padding-left: 0.25rem !important;
  }

  .md\:pl-2{
    padding-left: 0.5rem !important;
  }

  .md\:pl-3{
    padding-left: 0.75rem !important;
  }

  .md\:pl-4{
    padding-left: 1rem !important;
  }

  .md\:pl-5{
    padding-left: 1.25rem !important;
  }

  .md\:pl-6{
    padding-left: 1.5rem !important;
  }

  .md\:pl-7{
    padding-left: 1.75rem !important;
  }

  .md\:pl-8{
    padding-left: 2rem !important;
  }

  .md\:pl-9{
    padding-left: 2.25rem !important;
  }

  .md\:pl-10{
    padding-left: 2.5rem !important;
  }

  .md\:pl-11{
    padding-left: 2.75rem !important;
  }

  .md\:pl-12{
    padding-left: 3rem !important;
  }

  .md\:pl-14{
    padding-left: 3.5rem !important;
  }

  .md\:pl-16{
    padding-left: 4rem !important;
  }

  .md\:pl-20{
    padding-left: 5rem !important;
  }

  .md\:pl-24{
    padding-left: 6rem !important;
  }

  .md\:pl-28{
    padding-left: 7rem !important;
  }

  .md\:pl-32{
    padding-left: 8rem !important;
  }

  .md\:pl-36{
    padding-left: 9rem !important;
  }

  .md\:pl-40{
    padding-left: 10rem !important;
  }

  .md\:pl-44{
    padding-left: 11rem !important;
  }

  .md\:pl-48{
    padding-left: 12rem !important;
  }

  .md\:pl-52{
    padding-left: 13rem !important;
  }

  .md\:pl-56{
    padding-left: 14rem !important;
  }

  .md\:pl-60{
    padding-left: 15rem !important;
  }

  .md\:pl-64{
    padding-left: 16rem !important;
  }

  .md\:pl-72{
    padding-left: 18rem !important;
  }

  .md\:pl-80{
    padding-left: 20rem !important;
  }

  .md\:pl-96{
    padding-left: 24rem !important;
  }

  .md\:pl-px{
    padding-left: 1px !important;
  }

  .md\:pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .md\:pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .md\:pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .md\:pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .md\:text-left{
    text-align: left !important;
  }

  .md\:text-center{
    text-align: center !important;
  }

  .md\:text-right{
    text-align: right !important;
  }

  .md\:text-justify{
    text-align: justify !important;
  }

  .md\:align-baseline{
    vertical-align: baseline !important;
  }

  .md\:align-top{
    vertical-align: top !important;
  }

  .md\:align-middle{
    vertical-align: middle !important;
  }

  .md\:align-bottom{
    vertical-align: bottom !important;
  }

  .md\:align-text-top{
    vertical-align: text-top !important;
  }

  .md\:align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .md\:font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .md\:font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .md\:font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .md\:text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .md\:text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .md\:text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .md\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .md\:text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .md\:text-2xl{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .md\:text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .md\:text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .md\:text-5xl{
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .md\:text-6xl{
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .md\:text-7xl{
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .md\:text-8xl{
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .md\:text-9xl{
    font-size: 8rem !important;
    line-height: 1 !important;
  }

  .md\:font-thin{
    font-weight: 100 !important;
  }

  .md\:font-extralight{
    font-weight: 200 !important;
  }

  .md\:font-light{
    font-weight: 300 !important;
  }

  .md\:font-normal{
    font-weight: 400 !important;
  }

  .md\:font-medium{
    font-weight: 500 !important;
  }

  .md\:font-semibold{
    font-weight: 600 !important;
  }

  .md\:font-bold{
    font-weight: 700 !important;
  }

  .md\:font-extrabold{
    font-weight: 800 !important;
  }

  .md\:font-black{
    font-weight: 900 !important;
  }

  .md\:uppercase{
    text-transform: uppercase !important;
  }

  .md\:lowercase{
    text-transform: lowercase !important;
  }

  .md\:capitalize{
    text-transform: capitalize !important;
  }

  .md\:normal-case{
    text-transform: none !important;
  }

  .md\:italic{
    font-style: italic !important;
  }

  .md\:not-italic{
    font-style: normal !important;
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .md\:normal-nums{
    font-variant-numeric: normal !important;
  }

  .md\:ordinal{
    --tw-ordinal: ordinal !important;
  }

  .md\:slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .md\:lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .md\:oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .md\:proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .md\:tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .md\:diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .md\:stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .md\:leading-3{
    line-height: .75rem !important;
  }

  .md\:leading-4{
    line-height: 1rem !important;
  }

  .md\:leading-5{
    line-height: 1.25rem !important;
  }

  .md\:leading-6{
    line-height: 1.5rem !important;
  }

  .md\:leading-7{
    line-height: 1.75rem !important;
  }

  .md\:leading-8{
    line-height: 2rem !important;
  }

  .md\:leading-9{
    line-height: 2.25rem !important;
  }

  .md\:leading-10{
    line-height: 2.5rem !important;
  }

  .md\:leading-none{
    line-height: 1 !important;
  }

  .md\:leading-tight{
    line-height: 1.25 !important;
  }

  .md\:leading-snug{
    line-height: 1.375 !important;
  }

  .md\:leading-normal{
    line-height: 1.5 !important;
  }

  .md\:leading-relaxed{
    line-height: 1.625 !important;
  }

  .md\:leading-loose{
    line-height: 2 !important;
  }

  .md\:tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .md\:tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .md\:tracking-normal{
    letter-spacing: 0em !important;
  }

  .md\:tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .md\:tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .md\:tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .md\:text-transparent{
    color: transparent !important;
  }

  .md\:text-current{
    color: currentColor !important;
  }

  .md\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .md\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-transparent{
    color: transparent !important;
  }

  .group:hover .md\:group-hover\:text-current{
    color: currentColor !important;
  }

  .group:hover .md\:group-hover\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .group:hover .md\:group-hover\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-transparent:focus-within{
    color: transparent !important;
  }

  .md\:focus-within\:text-current:focus-within{
    color: currentColor !important;
  }

  .md\:focus-within\:text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-qipGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .md\:focus-within\:text-portalGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-transparent:hover{
    color: transparent !important;
  }

  .md\:hover\:text-current:hover{
    color: currentColor !important;
  }

  .md\:hover\:text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-qipGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .md\:hover\:text-portalGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-transparent:focus{
    color: transparent !important;
  }

  .md\:focus\:text-current:focus{
    color: currentColor !important;
  }

  .md\:focus\:text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-qipGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .md\:focus\:text-portalGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .md\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .md\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .group:hover .md\:group-hover\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .md\:focus-within\:text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .md\:focus-within\:text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:focus-within\:text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:focus-within\:text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:focus-within\:text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:focus-within\:text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:focus-within\:text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:focus-within\:text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:focus-within\:text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:focus-within\:text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:focus-within\:text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:focus-within\:text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:focus-within\:text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:focus-within\:text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:focus-within\:text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .md\:hover\:text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .md\:hover\:text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:hover\:text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:hover\:text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:hover\:text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:hover\:text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:hover\:text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:hover\:text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:hover\:text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:hover\:text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:hover\:text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:hover\:text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:hover\:text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:hover\:text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:hover\:text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .md\:focus\:text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .md\:focus\:text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .md\:focus\:text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .md\:focus\:text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .md\:focus\:text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .md\:focus\:text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .md\:focus\:text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .md\:focus\:text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .md\:focus\:text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .md\:focus\:text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .md\:focus\:text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .md\:focus\:text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .md\:focus\:text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .md\:focus\:text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .md\:focus\:text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .md\:underline{
    text-decoration: underline !important;
  }

  .md\:line-through{
    text-decoration: line-through !important;
  }

  .md\:no-underline{
    text-decoration: none !important;
  }

  .group:hover .md\:group-hover\:underline{
    text-decoration: underline !important;
  }

  .group:hover .md\:group-hover\:line-through{
    text-decoration: line-through !important;
  }

  .group:hover .md\:group-hover\:no-underline{
    text-decoration: none !important;
  }

  .md\:focus-within\:underline:focus-within{
    text-decoration: underline !important;
  }

  .md\:focus-within\:line-through:focus-within{
    text-decoration: line-through !important;
  }

  .md\:focus-within\:no-underline:focus-within{
    text-decoration: none !important;
  }

  .md\:hover\:underline:hover{
    text-decoration: underline !important;
  }

  .md\:hover\:line-through:hover{
    text-decoration: line-through !important;
  }

  .md\:hover\:no-underline:hover{
    text-decoration: none !important;
  }

  .md\:focus\:underline:focus{
    text-decoration: underline !important;
  }

  .md\:focus\:line-through:focus{
    text-decoration: line-through !important;
  }

  .md\:focus\:no-underline:focus{
    text-decoration: none !important;
  }

  .md\:antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .md\:subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .md\:placeholder-transparent::-webkit-input-placeholder{
    color: transparent !important;
  }

  .md\:placeholder-transparent:-ms-input-placeholder{
    color: transparent !important;
  }

  .md\:placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .md\:placeholder-current::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .md\:placeholder-current:-ms-input-placeholder{
    color: currentColor !important;
  }

  .md\:placeholder-current::placeholder{
    color: currentColor !important;
  }

  .md\:placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-black:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-white:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-qipGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-qipGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-qipGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-portalGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-portalGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-portalGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent !important;
  }

  .md\:focus\:placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent !important;
  }

  .md\:focus\:placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .md\:focus\:placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .md\:focus\:placeholder-current:focus:-ms-input-placeholder{
    color: currentColor !important;
  }

  .md\:focus\:placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .md\:focus\:placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-black:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-white:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-qipGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-qipGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-qipGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-portalGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-portalGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .md\:focus\:placeholder-portalGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .md\:placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-5::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:placeholder-opacity-5:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:placeholder-opacity-10::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:placeholder-opacity-10:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:placeholder-opacity-20::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:placeholder-opacity-20:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-30::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:placeholder-opacity-30:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:placeholder-opacity-40::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:placeholder-opacity-40:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-60::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:placeholder-opacity-60:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:placeholder-opacity-70::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:placeholder-opacity-70:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-80::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:placeholder-opacity-80:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:placeholder-opacity-90:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:placeholder-opacity-95::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:placeholder-opacity-95:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:focus\:placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .md\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:focus\:placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .md\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:focus\:placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:focus\:placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .md\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:focus\:placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:focus\:placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:focus\:placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .md\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:focus\:placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .md\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:focus\:placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .md\:opacity-0{
    opacity: 0 !important;
  }

  .md\:opacity-5{
    opacity: 0.05 !important;
  }

  .md\:opacity-10{
    opacity: 0.1 !important;
  }

  .md\:opacity-20{
    opacity: 0.2 !important;
  }

  .md\:opacity-25{
    opacity: 0.25 !important;
  }

  .md\:opacity-30{
    opacity: 0.3 !important;
  }

  .md\:opacity-40{
    opacity: 0.4 !important;
  }

  .md\:opacity-50{
    opacity: 0.5 !important;
  }

  .md\:opacity-60{
    opacity: 0.6 !important;
  }

  .md\:opacity-70{
    opacity: 0.7 !important;
  }

  .md\:opacity-75{
    opacity: 0.75 !important;
  }

  .md\:opacity-80{
    opacity: 0.8 !important;
  }

  .md\:opacity-90{
    opacity: 0.9 !important;
  }

  .md\:opacity-95{
    opacity: 0.95 !important;
  }

  .md\:opacity-100{
    opacity: 1 !important;
  }

  .group:hover .md\:group-hover\:opacity-0{
    opacity: 0 !important;
  }

  .group:hover .md\:group-hover\:opacity-5{
    opacity: 0.05 !important;
  }

  .group:hover .md\:group-hover\:opacity-10{
    opacity: 0.1 !important;
  }

  .group:hover .md\:group-hover\:opacity-20{
    opacity: 0.2 !important;
  }

  .group:hover .md\:group-hover\:opacity-25{
    opacity: 0.25 !important;
  }

  .group:hover .md\:group-hover\:opacity-30{
    opacity: 0.3 !important;
  }

  .group:hover .md\:group-hover\:opacity-40{
    opacity: 0.4 !important;
  }

  .group:hover .md\:group-hover\:opacity-50{
    opacity: 0.5 !important;
  }

  .group:hover .md\:group-hover\:opacity-60{
    opacity: 0.6 !important;
  }

  .group:hover .md\:group-hover\:opacity-70{
    opacity: 0.7 !important;
  }

  .group:hover .md\:group-hover\:opacity-75{
    opacity: 0.75 !important;
  }

  .group:hover .md\:group-hover\:opacity-80{
    opacity: 0.8 !important;
  }

  .group:hover .md\:group-hover\:opacity-90{
    opacity: 0.9 !important;
  }

  .group:hover .md\:group-hover\:opacity-95{
    opacity: 0.95 !important;
  }

  .group:hover .md\:group-hover\:opacity-100{
    opacity: 1 !important;
  }

  .md\:focus-within\:opacity-0:focus-within{
    opacity: 0 !important;
  }

  .md\:focus-within\:opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .md\:focus-within\:opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .md\:focus-within\:opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .md\:focus-within\:opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .md\:focus-within\:opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .md\:focus-within\:opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .md\:focus-within\:opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .md\:focus-within\:opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .md\:focus-within\:opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .md\:focus-within\:opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .md\:focus-within\:opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .md\:focus-within\:opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .md\:focus-within\:opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .md\:focus-within\:opacity-100:focus-within{
    opacity: 1 !important;
  }

  .md\:hover\:opacity-0:hover{
    opacity: 0 !important;
  }

  .md\:hover\:opacity-5:hover{
    opacity: 0.05 !important;
  }

  .md\:hover\:opacity-10:hover{
    opacity: 0.1 !important;
  }

  .md\:hover\:opacity-20:hover{
    opacity: 0.2 !important;
  }

  .md\:hover\:opacity-25:hover{
    opacity: 0.25 !important;
  }

  .md\:hover\:opacity-30:hover{
    opacity: 0.3 !important;
  }

  .md\:hover\:opacity-40:hover{
    opacity: 0.4 !important;
  }

  .md\:hover\:opacity-50:hover{
    opacity: 0.5 !important;
  }

  .md\:hover\:opacity-60:hover{
    opacity: 0.6 !important;
  }

  .md\:hover\:opacity-70:hover{
    opacity: 0.7 !important;
  }

  .md\:hover\:opacity-75:hover{
    opacity: 0.75 !important;
  }

  .md\:hover\:opacity-80:hover{
    opacity: 0.8 !important;
  }

  .md\:hover\:opacity-90:hover{
    opacity: 0.9 !important;
  }

  .md\:hover\:opacity-95:hover{
    opacity: 0.95 !important;
  }

  .md\:hover\:opacity-100:hover{
    opacity: 1 !important;
  }

  .md\:focus\:opacity-0:focus{
    opacity: 0 !important;
  }

  .md\:focus\:opacity-5:focus{
    opacity: 0.05 !important;
  }

  .md\:focus\:opacity-10:focus{
    opacity: 0.1 !important;
  }

  .md\:focus\:opacity-20:focus{
    opacity: 0.2 !important;
  }

  .md\:focus\:opacity-25:focus{
    opacity: 0.25 !important;
  }

  .md\:focus\:opacity-30:focus{
    opacity: 0.3 !important;
  }

  .md\:focus\:opacity-40:focus{
    opacity: 0.4 !important;
  }

  .md\:focus\:opacity-50:focus{
    opacity: 0.5 !important;
  }

  .md\:focus\:opacity-60:focus{
    opacity: 0.6 !important;
  }

  .md\:focus\:opacity-70:focus{
    opacity: 0.7 !important;
  }

  .md\:focus\:opacity-75:focus{
    opacity: 0.75 !important;
  }

  .md\:focus\:opacity-80:focus{
    opacity: 0.8 !important;
  }

  .md\:focus\:opacity-90:focus{
    opacity: 0.9 !important;
  }

  .md\:focus\:opacity-95:focus{
    opacity: 0.95 !important;
  }

  .md\:focus\:opacity-100:focus{
    opacity: 1 !important;
  }

  .md\:bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .md\:bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .md\:bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .md\:bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .md\:bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .md\:bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .md\:bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .md\:bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .md\:bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .md\:bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .md\:bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .md\:bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .md\:bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .md\:bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .md\:bg-blend-color{
    background-blend-mode: color !important;
  }

  .md\:bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .md\:mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .md\:mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .md\:mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .md\:mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .md\:mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .md\:mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .md\:mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .md\:mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .md\:mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .md\:mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .md\:mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .md\:mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .md\:mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .md\:mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .md\:mix-blend-color{
    mix-blend-mode: color !important;
  }

  .md\:mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .md\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .md\:group-hover\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus-within\:shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:hover\:shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:focus\:shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .md\:outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .md\:outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .md\:outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .md\:focus-within\:outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .md\:focus-within\:outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .md\:focus-within\:outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .md\:focus\:outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .md\:focus\:outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .md\:focus\:outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .md\:ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:focus\:ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .md\:ring-inset{
    --tw-ring-inset: inset !important;
  }

  .md\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .md\:focus\:ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .md\:ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .md\:ring-current{
    --tw-ring-color: currentColor !important;
  }

  .md\:ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-qipGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-portalGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .md\:focus-within\:ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .md\:focus-within\:ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-qipGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .md\:focus-within\:ring-portalGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .md\:focus\:ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .md\:focus\:ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-qipGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .md\:focus\:ring-portalGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .md\:ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .md\:ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .md\:ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .md\:ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .md\:ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .md\:ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .md\:ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .md\:ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .md\:ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .md\:ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .md\:ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .md\:ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .md\:ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .md\:ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .md\:ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .md\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .md\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .md\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .md\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .md\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .md\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .md\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .md\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .md\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .md\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .md\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .md\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .md\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .md\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .md\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .md\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .md\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .md\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .md\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .md\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .md\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .md\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .md\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .md\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .md\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .md\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .md\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .md\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .md\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .md\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .md\:ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .md\:ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .md\:ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .md\:ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .md\:ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .md\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .md\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .md\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .md\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .md\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .md\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .md\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .md\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .md\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .md\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .md\:ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .md\:ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .md\:ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .md\:ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .md\:ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .md\:ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .md\:ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .md\:ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .md\:ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .md\:ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .md\:ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .md\:ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .md\:ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .md\:ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .md\:ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .md\:ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .md\:ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .md\:ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .md\:ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .md\:ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .md\:ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .md\:ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .md\:ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .md\:ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .md\:ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .md\:ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .md\:ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .md\:ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .md\:ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .md\:ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .md\:ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .md\:ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .md\:ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .md\:ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .md\:ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .md\:ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .md\:ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .md\:ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .md\:ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .md\:ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .md\:ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .md\:ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .md\:ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .md\:ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .md\:ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .md\:ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .md\:ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .md\:ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .md\:ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .md\:ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .md\:ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .md\:ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .md\:ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .md\:ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .md\:ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .md\:ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .md\:ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .md\:ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .md\:ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .md\:ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .md\:ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .md\:ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .md\:ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .md\:ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .md\:ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .md\:ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .md\:ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .md\:ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .md\:ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .md\:ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .md\:ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .md\:ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .md\:ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .md\:ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .md\:ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .md\:ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .md\:ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .md\:ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .md\:ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .md\:ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .md\:ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .md\:ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .md\:ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .md\:ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .md\:ring-offset-qipGreen{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .md\:ring-offset-portalGreen{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .md\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .md\:focus-within\:ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .md\:focus-within\:ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .md\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .md\:focus-within\:ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .md\:focus-within\:ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .md\:focus-within\:ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .md\:focus-within\:ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .md\:focus-within\:ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .md\:focus-within\:ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .md\:focus-within\:ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .md\:focus-within\:ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .md\:focus-within\:ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .md\:focus-within\:ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .md\:focus-within\:ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .md\:focus-within\:ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .md\:focus-within\:ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .md\:focus-within\:ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .md\:focus-within\:ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .md\:focus-within\:ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .md\:focus-within\:ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .md\:focus-within\:ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .md\:focus-within\:ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .md\:focus-within\:ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .md\:focus-within\:ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .md\:focus-within\:ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .md\:focus-within\:ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .md\:focus-within\:ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .md\:focus-within\:ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .md\:focus-within\:ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .md\:focus-within\:ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .md\:focus-within\:ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .md\:focus-within\:ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .md\:focus-within\:ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .md\:focus-within\:ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .md\:focus-within\:ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .md\:focus-within\:ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .md\:focus-within\:ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .md\:focus-within\:ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .md\:focus-within\:ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .md\:focus-within\:ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .md\:focus-within\:ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .md\:focus-within\:ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .md\:focus-within\:ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .md\:focus-within\:ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .md\:focus-within\:ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .md\:focus-within\:ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .md\:focus-within\:ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .md\:focus-within\:ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .md\:focus-within\:ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .md\:focus-within\:ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .md\:focus-within\:ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .md\:focus-within\:ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .md\:focus-within\:ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .md\:focus-within\:ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .md\:focus-within\:ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .md\:focus-within\:ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .md\:focus-within\:ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .md\:focus-within\:ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .md\:focus-within\:ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .md\:focus-within\:ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .md\:focus-within\:ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .md\:focus-within\:ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .md\:focus-within\:ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .md\:focus-within\:ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .md\:focus-within\:ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .md\:focus-within\:ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .md\:focus-within\:ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .md\:focus-within\:ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .md\:focus-within\:ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .md\:focus-within\:ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .md\:focus-within\:ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .md\:focus-within\:ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .md\:focus-within\:ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .md\:focus-within\:ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .md\:focus-within\:ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .md\:focus-within\:ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .md\:focus-within\:ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .md\:focus-within\:ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .md\:focus-within\:ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .md\:focus-within\:ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .md\:focus-within\:ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .md\:focus-within\:ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .md\:focus-within\:ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .md\:focus-within\:ring-offset-qipGreen:focus-within{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .md\:focus-within\:ring-offset-portalGreen:focus-within{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .md\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .md\:focus\:ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .md\:focus\:ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .md\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .md\:focus\:ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .md\:focus\:ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .md\:focus\:ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .md\:focus\:ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .md\:focus\:ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .md\:focus\:ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .md\:focus\:ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .md\:focus\:ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .md\:focus\:ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .md\:focus\:ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .md\:focus\:ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .md\:focus\:ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .md\:focus\:ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .md\:focus\:ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .md\:focus\:ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .md\:focus\:ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .md\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .md\:focus\:ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .md\:focus\:ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .md\:focus\:ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .md\:focus\:ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .md\:focus\:ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .md\:focus\:ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .md\:focus\:ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .md\:focus\:ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .md\:focus\:ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .md\:focus\:ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .md\:focus\:ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .md\:focus\:ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .md\:focus\:ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .md\:focus\:ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .md\:focus\:ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .md\:focus\:ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .md\:focus\:ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .md\:focus\:ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .md\:focus\:ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .md\:focus\:ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .md\:focus\:ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .md\:focus\:ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .md\:focus\:ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .md\:focus\:ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .md\:focus\:ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .md\:focus\:ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .md\:focus\:ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .md\:focus\:ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .md\:focus\:ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .md\:focus\:ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .md\:focus\:ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .md\:focus\:ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .md\:focus\:ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .md\:focus\:ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .md\:focus\:ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .md\:focus\:ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .md\:focus\:ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .md\:focus\:ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .md\:focus\:ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .md\:focus\:ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .md\:focus\:ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .md\:focus\:ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .md\:focus\:ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .md\:focus\:ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .md\:focus\:ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .md\:focus\:ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .md\:focus\:ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .md\:focus\:ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .md\:focus\:ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .md\:focus\:ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .md\:focus\:ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .md\:focus\:ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .md\:focus\:ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .md\:focus\:ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .md\:focus\:ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .md\:focus\:ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .md\:focus\:ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .md\:focus\:ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .md\:focus\:ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .md\:focus\:ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .md\:focus\:ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .md\:focus\:ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .md\:focus\:ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .md\:focus\:ring-offset-qipGreen:focus{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .md\:focus\:ring-offset-portalGreen:focus{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .md\:filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .md\:filter-none{
    -webkit-filter: none !important;
            filter: none !important;
  }

  .md\:blur-0{
    --tw-blur: blur(0) !important;
  }

  .md\:blur-none{
    --tw-blur: blur(0) !important;
  }

  .md\:blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .md\:blur{
    --tw-blur: blur(8px) !important;
  }

  .md\:blur-md{
    --tw-blur: blur(12px) !important;
  }

  .md\:blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .md\:blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .md\:blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .md\:blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .md\:brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .md\:brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .md\:brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .md\:brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .md\:brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .md\:brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .md\:brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .md\:brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .md\:brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .md\:brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .md\:brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .md\:contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .md\:contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .md\:contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .md\:contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .md\:contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .md\:contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .md\:contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .md\:drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .md\:drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .md\:drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .md\:drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .md\:drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .md\:drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .md\:drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .md\:grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .md\:grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .md\:hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .md\:hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .md\:hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .md\:hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .md\:hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .md\:hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .md\:-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .md\:-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .md\:-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .md\:-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .md\:-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .md\:invert-0{
    --tw-invert: invert(0) !important;
  }

  .md\:invert{
    --tw-invert: invert(100%) !important;
  }

  .md\:saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .md\:saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .md\:saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .md\:saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .md\:saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .md\:sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .md\:sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .md\:backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .md\:backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .md\:backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .md\:backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .md\:backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .md\:backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .md\:backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .md\:backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .md\:backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .md\:backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .md\:backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .md\:backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .md\:backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .md\:backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .md\:backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .md\:backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .md\:backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .md\:backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .md\:backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .md\:backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .md\:backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .md\:backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .md\:backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .md\:backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .md\:backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .md\:backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .md\:backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .md\:backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .md\:backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .md\:backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .md\:backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .md\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .md\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .md\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .md\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .md\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .md\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .md\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .md\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .md\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .md\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .md\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .md\:backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .md\:backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .md\:backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .md\:backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .md\:backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .md\:backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .md\:backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .md\:backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .md\:backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .md\:backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .md\:backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .md\:backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .md\:backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .md\:backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .md\:backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .md\:backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .md\:backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .md\:backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .md\:backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .md\:backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .md\:backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .md\:backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .md\:backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .md\:backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .md\:transition-none{
    transition-property: none !important;
  }

  .md\:transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .md\:delay-75{
    transition-delay: 75ms !important;
  }

  .md\:delay-100{
    transition-delay: 100ms !important;
  }

  .md\:delay-150{
    transition-delay: 150ms !important;
  }

  .md\:delay-200{
    transition-delay: 200ms !important;
  }

  .md\:delay-300{
    transition-delay: 300ms !important;
  }

  .md\:delay-500{
    transition-delay: 500ms !important;
  }

  .md\:delay-700{
    transition-delay: 700ms !important;
  }

  .md\:delay-1000{
    transition-delay: 1000ms !important;
  }

  .md\:duration-75{
    transition-duration: 75ms !important;
  }

  .md\:duration-100{
    transition-duration: 100ms !important;
  }

  .md\:duration-150{
    transition-duration: 150ms !important;
  }

  .md\:duration-200{
    transition-duration: 200ms !important;
  }

  .md\:duration-300{
    transition-duration: 300ms !important;
  }

  .md\:duration-500{
    transition-duration: 500ms !important;
  }

  .md\:duration-700{
    transition-duration: 700ms !important;
  }

  .md\:duration-1000{
    transition-duration: 1000ms !important;
  }

  .md\:ease-linear{
    transition-timing-function: linear !important;
  }

  .md\:ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .md\:ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .md\:ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 1024px){
  .lg\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .lg\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .lg\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .lg\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .lg\:container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .lg\:container{
      max-width: 1536px;
    }
  }

  .lg\:sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:focus-within\:sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:focus-within\:not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:focus\:sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:focus\:not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:pointer-events-none{
    pointer-events: none !important;
  }

  .lg\:pointer-events-auto{
    pointer-events: auto !important;
  }

  .lg\:visible{
    visibility: visible !important;
  }

  .lg\:invisible{
    visibility: hidden !important;
  }

  .lg\:static{
    position: static !important;
  }

  .lg\:fixed{
    position: fixed !important;
  }

  .lg\:absolute{
    position: absolute !important;
  }

  .lg\:relative{
    position: relative !important;
  }

  .lg\:sticky{
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .lg\:inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .lg\:inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .lg\:inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .lg\:inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .lg\:inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .lg\:inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .lg\:inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .lg\:inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .lg\:inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .lg\:inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .lg\:inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .lg\:inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .lg\:inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .lg\:inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .lg\:inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .lg\:inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .lg\:inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .lg\:inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .lg\:inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .lg\:inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .lg\:inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .lg\:inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .lg\:inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .lg\:inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .lg\:inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .lg\:inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .lg\:inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .lg\:inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .lg\:inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .lg\:inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .lg\:inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lg\:inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .lg\:inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .lg\:inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .lg\:inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .lg\:inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .lg\:-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .lg\:-inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .lg\:-inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .lg\:-inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .lg\:-inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .lg\:-inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .lg\:-inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .lg\:-inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .lg\:-inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .lg\:-inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .lg\:-inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .lg\:-inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .lg\:-inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .lg\:-inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .lg\:-inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .lg\:-inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .lg\:-inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .lg\:-inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .lg\:-inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .lg\:-inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .lg\:-inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .lg\:-inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .lg\:-inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .lg\:-inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .lg\:-inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .lg\:-inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .lg\:-inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .lg\:-inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .lg\:-inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .lg\:-inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .lg\:-inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .lg\:-inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .lg\:-inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .lg\:-inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .lg\:-inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .lg\:inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .lg\:inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .lg\:inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .lg\:inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .lg\:inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .lg\:inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .lg\:inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .lg\:-inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .lg\:-inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .lg\:-inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .lg\:-inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .lg\:-inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .lg\:-inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .lg\:-inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .lg\:inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .lg\:inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .lg\:inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .lg\:inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .lg\:inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .lg\:inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .lg\:inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .lg\:inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .lg\:inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .lg\:inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .lg\:inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .lg\:inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .lg\:inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .lg\:inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .lg\:inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .lg\:inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .lg\:inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .lg\:inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .lg\:inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .lg\:inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .lg\:inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .lg\:inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .lg\:inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .lg\:inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .lg\:inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .lg\:inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .lg\:inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .lg\:inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .lg\:inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .lg\:inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .lg\:inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .lg\:inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .lg\:inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .lg\:inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .lg\:inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .lg\:inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .lg\:-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .lg\:-inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .lg\:-inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .lg\:-inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .lg\:-inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .lg\:-inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .lg\:-inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .lg\:-inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .lg\:-inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .lg\:-inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .lg\:-inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .lg\:-inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .lg\:-inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .lg\:-inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .lg\:-inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .lg\:-inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .lg\:-inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .lg\:-inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .lg\:-inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .lg\:-inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .lg\:-inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .lg\:-inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .lg\:-inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .lg\:-inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .lg\:-inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .lg\:-inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .lg\:-inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .lg\:-inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .lg\:-inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .lg\:-inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .lg\:-inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .lg\:-inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .lg\:-inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .lg\:-inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .lg\:-inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .lg\:inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .lg\:inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .lg\:inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .lg\:inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .lg\:inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .lg\:inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .lg\:inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .lg\:-inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .lg\:-inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .lg\:-inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .lg\:-inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .lg\:-inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .lg\:-inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .lg\:-inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .lg\:inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .lg\:inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .lg\:inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .lg\:inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .lg\:inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .lg\:inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .lg\:inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .lg\:inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .lg\:inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .lg\:inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .lg\:inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .lg\:inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .lg\:inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .lg\:inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .lg\:inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .lg\:inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .lg\:inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .lg\:inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .lg\:inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .lg\:inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .lg\:inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .lg\:inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .lg\:inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .lg\:inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .lg\:inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .lg\:inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .lg\:inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .lg\:inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .lg\:inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .lg\:inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .lg\:inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .lg\:inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .lg\:inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .lg\:inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .lg\:inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .lg\:inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .lg\:-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .lg\:-inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .lg\:-inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .lg\:-inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .lg\:-inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .lg\:-inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .lg\:-inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .lg\:-inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .lg\:-inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .lg\:-inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .lg\:-inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .lg\:-inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .lg\:-inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .lg\:-inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .lg\:-inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .lg\:-inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .lg\:-inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .lg\:-inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .lg\:-inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .lg\:-inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .lg\:-inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .lg\:-inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .lg\:-inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .lg\:-inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .lg\:-inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .lg\:-inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .lg\:-inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .lg\:-inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .lg\:-inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .lg\:-inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .lg\:-inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .lg\:-inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .lg\:-inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .lg\:-inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .lg\:-inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .lg\:inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .lg\:inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .lg\:inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .lg\:inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .lg\:inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .lg\:inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .lg\:inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .lg\:-inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .lg\:-inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .lg\:-inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .lg\:-inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .lg\:-inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .lg\:-inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .lg\:-inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .lg\:top-0{
    top: 0px !important;
  }

  .lg\:top-1{
    top: 0.25rem !important;
  }

  .lg\:top-2{
    top: 0.5rem !important;
  }

  .lg\:top-3{
    top: 0.75rem !important;
  }

  .lg\:top-4{
    top: 1rem !important;
  }

  .lg\:top-5{
    top: 1.25rem !important;
  }

  .lg\:top-6{
    top: 1.5rem !important;
  }

  .lg\:top-7{
    top: 1.75rem !important;
  }

  .lg\:top-8{
    top: 2rem !important;
  }

  .lg\:top-9{
    top: 2.25rem !important;
  }

  .lg\:top-10{
    top: 2.5rem !important;
  }

  .lg\:top-11{
    top: 2.75rem !important;
  }

  .lg\:top-12{
    top: 3rem !important;
  }

  .lg\:top-14{
    top: 3.5rem !important;
  }

  .lg\:top-16{
    top: 4rem !important;
  }

  .lg\:top-20{
    top: 5rem !important;
  }

  .lg\:top-24{
    top: 6rem !important;
  }

  .lg\:top-28{
    top: 7rem !important;
  }

  .lg\:top-32{
    top: 8rem !important;
  }

  .lg\:top-36{
    top: 9rem !important;
  }

  .lg\:top-40{
    top: 10rem !important;
  }

  .lg\:top-44{
    top: 11rem !important;
  }

  .lg\:top-48{
    top: 12rem !important;
  }

  .lg\:top-52{
    top: 13rem !important;
  }

  .lg\:top-56{
    top: 14rem !important;
  }

  .lg\:top-60{
    top: 15rem !important;
  }

  .lg\:top-64{
    top: 16rem !important;
  }

  .lg\:top-72{
    top: 18rem !important;
  }

  .lg\:top-80{
    top: 20rem !important;
  }

  .lg\:top-96{
    top: 24rem !important;
  }

  .lg\:top-auto{
    top: auto !important;
  }

  .lg\:top-px{
    top: 1px !important;
  }

  .lg\:top-0\.5{
    top: 0.125rem !important;
  }

  .lg\:top-1\.5{
    top: 0.375rem !important;
  }

  .lg\:top-2\.5{
    top: 0.625rem !important;
  }

  .lg\:top-3\.5{
    top: 0.875rem !important;
  }

  .lg\:-top-0{
    top: 0px !important;
  }

  .lg\:-top-1{
    top: -0.25rem !important;
  }

  .lg\:-top-2{
    top: -0.5rem !important;
  }

  .lg\:-top-3{
    top: -0.75rem !important;
  }

  .lg\:-top-4{
    top: -1rem !important;
  }

  .lg\:-top-5{
    top: -1.25rem !important;
  }

  .lg\:-top-6{
    top: -1.5rem !important;
  }

  .lg\:-top-7{
    top: -1.75rem !important;
  }

  .lg\:-top-8{
    top: -2rem !important;
  }

  .lg\:-top-9{
    top: -2.25rem !important;
  }

  .lg\:-top-10{
    top: -2.5rem !important;
  }

  .lg\:-top-11{
    top: -2.75rem !important;
  }

  .lg\:-top-12{
    top: -3rem !important;
  }

  .lg\:-top-14{
    top: -3.5rem !important;
  }

  .lg\:-top-16{
    top: -4rem !important;
  }

  .lg\:-top-20{
    top: -5rem !important;
  }

  .lg\:-top-24{
    top: -6rem !important;
  }

  .lg\:-top-28{
    top: -7rem !important;
  }

  .lg\:-top-32{
    top: -8rem !important;
  }

  .lg\:-top-36{
    top: -9rem !important;
  }

  .lg\:-top-40{
    top: -10rem !important;
  }

  .lg\:-top-44{
    top: -11rem !important;
  }

  .lg\:-top-48{
    top: -12rem !important;
  }

  .lg\:-top-52{
    top: -13rem !important;
  }

  .lg\:-top-56{
    top: -14rem !important;
  }

  .lg\:-top-60{
    top: -15rem !important;
  }

  .lg\:-top-64{
    top: -16rem !important;
  }

  .lg\:-top-72{
    top: -18rem !important;
  }

  .lg\:-top-80{
    top: -20rem !important;
  }

  .lg\:-top-96{
    top: -24rem !important;
  }

  .lg\:-top-px{
    top: -1px !important;
  }

  .lg\:-top-0\.5{
    top: -0.125rem !important;
  }

  .lg\:-top-1\.5{
    top: -0.375rem !important;
  }

  .lg\:-top-2\.5{
    top: -0.625rem !important;
  }

  .lg\:-top-3\.5{
    top: -0.875rem !important;
  }

  .lg\:top-1\/2{
    top: 50% !important;
  }

  .lg\:top-1\/3{
    top: 33.333333% !important;
  }

  .lg\:top-2\/3{
    top: 66.666667% !important;
  }

  .lg\:top-1\/4{
    top: 25% !important;
  }

  .lg\:top-2\/4{
    top: 50% !important;
  }

  .lg\:top-3\/4{
    top: 75% !important;
  }

  .lg\:top-full{
    top: 100% !important;
  }

  .lg\:-top-1\/2{
    top: -50% !important;
  }

  .lg\:-top-1\/3{
    top: -33.333333% !important;
  }

  .lg\:-top-2\/3{
    top: -66.666667% !important;
  }

  .lg\:-top-1\/4{
    top: -25% !important;
  }

  .lg\:-top-2\/4{
    top: -50% !important;
  }

  .lg\:-top-3\/4{
    top: -75% !important;
  }

  .lg\:-top-full{
    top: -100% !important;
  }

  .lg\:right-0{
    right: 0px !important;
  }

  .lg\:right-1{
    right: 0.25rem !important;
  }

  .lg\:right-2{
    right: 0.5rem !important;
  }

  .lg\:right-3{
    right: 0.75rem !important;
  }

  .lg\:right-4{
    right: 1rem !important;
  }

  .lg\:right-5{
    right: 1.25rem !important;
  }

  .lg\:right-6{
    right: 1.5rem !important;
  }

  .lg\:right-7{
    right: 1.75rem !important;
  }

  .lg\:right-8{
    right: 2rem !important;
  }

  .lg\:right-9{
    right: 2.25rem !important;
  }

  .lg\:right-10{
    right: 2.5rem !important;
  }

  .lg\:right-11{
    right: 2.75rem !important;
  }

  .lg\:right-12{
    right: 3rem !important;
  }

  .lg\:right-14{
    right: 3.5rem !important;
  }

  .lg\:right-16{
    right: 4rem !important;
  }

  .lg\:right-20{
    right: 5rem !important;
  }

  .lg\:right-24{
    right: 6rem !important;
  }

  .lg\:right-28{
    right: 7rem !important;
  }

  .lg\:right-32{
    right: 8rem !important;
  }

  .lg\:right-36{
    right: 9rem !important;
  }

  .lg\:right-40{
    right: 10rem !important;
  }

  .lg\:right-44{
    right: 11rem !important;
  }

  .lg\:right-48{
    right: 12rem !important;
  }

  .lg\:right-52{
    right: 13rem !important;
  }

  .lg\:right-56{
    right: 14rem !important;
  }

  .lg\:right-60{
    right: 15rem !important;
  }

  .lg\:right-64{
    right: 16rem !important;
  }

  .lg\:right-72{
    right: 18rem !important;
  }

  .lg\:right-80{
    right: 20rem !important;
  }

  .lg\:right-96{
    right: 24rem !important;
  }

  .lg\:right-auto{
    right: auto !important;
  }

  .lg\:right-px{
    right: 1px !important;
  }

  .lg\:right-0\.5{
    right: 0.125rem !important;
  }

  .lg\:right-1\.5{
    right: 0.375rem !important;
  }

  .lg\:right-2\.5{
    right: 0.625rem !important;
  }

  .lg\:right-3\.5{
    right: 0.875rem !important;
  }

  .lg\:-right-0{
    right: 0px !important;
  }

  .lg\:-right-1{
    right: -0.25rem !important;
  }

  .lg\:-right-2{
    right: -0.5rem !important;
  }

  .lg\:-right-3{
    right: -0.75rem !important;
  }

  .lg\:-right-4{
    right: -1rem !important;
  }

  .lg\:-right-5{
    right: -1.25rem !important;
  }

  .lg\:-right-6{
    right: -1.5rem !important;
  }

  .lg\:-right-7{
    right: -1.75rem !important;
  }

  .lg\:-right-8{
    right: -2rem !important;
  }

  .lg\:-right-9{
    right: -2.25rem !important;
  }

  .lg\:-right-10{
    right: -2.5rem !important;
  }

  .lg\:-right-11{
    right: -2.75rem !important;
  }

  .lg\:-right-12{
    right: -3rem !important;
  }

  .lg\:-right-14{
    right: -3.5rem !important;
  }

  .lg\:-right-16{
    right: -4rem !important;
  }

  .lg\:-right-20{
    right: -5rem !important;
  }

  .lg\:-right-24{
    right: -6rem !important;
  }

  .lg\:-right-28{
    right: -7rem !important;
  }

  .lg\:-right-32{
    right: -8rem !important;
  }

  .lg\:-right-36{
    right: -9rem !important;
  }

  .lg\:-right-40{
    right: -10rem !important;
  }

  .lg\:-right-44{
    right: -11rem !important;
  }

  .lg\:-right-48{
    right: -12rem !important;
  }

  .lg\:-right-52{
    right: -13rem !important;
  }

  .lg\:-right-56{
    right: -14rem !important;
  }

  .lg\:-right-60{
    right: -15rem !important;
  }

  .lg\:-right-64{
    right: -16rem !important;
  }

  .lg\:-right-72{
    right: -18rem !important;
  }

  .lg\:-right-80{
    right: -20rem !important;
  }

  .lg\:-right-96{
    right: -24rem !important;
  }

  .lg\:-right-px{
    right: -1px !important;
  }

  .lg\:-right-0\.5{
    right: -0.125rem !important;
  }

  .lg\:-right-1\.5{
    right: -0.375rem !important;
  }

  .lg\:-right-2\.5{
    right: -0.625rem !important;
  }

  .lg\:-right-3\.5{
    right: -0.875rem !important;
  }

  .lg\:right-1\/2{
    right: 50% !important;
  }

  .lg\:right-1\/3{
    right: 33.333333% !important;
  }

  .lg\:right-2\/3{
    right: 66.666667% !important;
  }

  .lg\:right-1\/4{
    right: 25% !important;
  }

  .lg\:right-2\/4{
    right: 50% !important;
  }

  .lg\:right-3\/4{
    right: 75% !important;
  }

  .lg\:right-full{
    right: 100% !important;
  }

  .lg\:-right-1\/2{
    right: -50% !important;
  }

  .lg\:-right-1\/3{
    right: -33.333333% !important;
  }

  .lg\:-right-2\/3{
    right: -66.666667% !important;
  }

  .lg\:-right-1\/4{
    right: -25% !important;
  }

  .lg\:-right-2\/4{
    right: -50% !important;
  }

  .lg\:-right-3\/4{
    right: -75% !important;
  }

  .lg\:-right-full{
    right: -100% !important;
  }

  .lg\:bottom-0{
    bottom: 0px !important;
  }

  .lg\:bottom-1{
    bottom: 0.25rem !important;
  }

  .lg\:bottom-2{
    bottom: 0.5rem !important;
  }

  .lg\:bottom-3{
    bottom: 0.75rem !important;
  }

  .lg\:bottom-4{
    bottom: 1rem !important;
  }

  .lg\:bottom-5{
    bottom: 1.25rem !important;
  }

  .lg\:bottom-6{
    bottom: 1.5rem !important;
  }

  .lg\:bottom-7{
    bottom: 1.75rem !important;
  }

  .lg\:bottom-8{
    bottom: 2rem !important;
  }

  .lg\:bottom-9{
    bottom: 2.25rem !important;
  }

  .lg\:bottom-10{
    bottom: 2.5rem !important;
  }

  .lg\:bottom-11{
    bottom: 2.75rem !important;
  }

  .lg\:bottom-12{
    bottom: 3rem !important;
  }

  .lg\:bottom-14{
    bottom: 3.5rem !important;
  }

  .lg\:bottom-16{
    bottom: 4rem !important;
  }

  .lg\:bottom-20{
    bottom: 5rem !important;
  }

  .lg\:bottom-24{
    bottom: 6rem !important;
  }

  .lg\:bottom-28{
    bottom: 7rem !important;
  }

  .lg\:bottom-32{
    bottom: 8rem !important;
  }

  .lg\:bottom-36{
    bottom: 9rem !important;
  }

  .lg\:bottom-40{
    bottom: 10rem !important;
  }

  .lg\:bottom-44{
    bottom: 11rem !important;
  }

  .lg\:bottom-48{
    bottom: 12rem !important;
  }

  .lg\:bottom-52{
    bottom: 13rem !important;
  }

  .lg\:bottom-56{
    bottom: 14rem !important;
  }

  .lg\:bottom-60{
    bottom: 15rem !important;
  }

  .lg\:bottom-64{
    bottom: 16rem !important;
  }

  .lg\:bottom-72{
    bottom: 18rem !important;
  }

  .lg\:bottom-80{
    bottom: 20rem !important;
  }

  .lg\:bottom-96{
    bottom: 24rem !important;
  }

  .lg\:bottom-auto{
    bottom: auto !important;
  }

  .lg\:bottom-px{
    bottom: 1px !important;
  }

  .lg\:bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .lg\:bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .lg\:bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .lg\:bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .lg\:-bottom-0{
    bottom: 0px !important;
  }

  .lg\:-bottom-1{
    bottom: -0.25rem !important;
  }

  .lg\:-bottom-2{
    bottom: -0.5rem !important;
  }

  .lg\:-bottom-3{
    bottom: -0.75rem !important;
  }

  .lg\:-bottom-4{
    bottom: -1rem !important;
  }

  .lg\:-bottom-5{
    bottom: -1.25rem !important;
  }

  .lg\:-bottom-6{
    bottom: -1.5rem !important;
  }

  .lg\:-bottom-7{
    bottom: -1.75rem !important;
  }

  .lg\:-bottom-8{
    bottom: -2rem !important;
  }

  .lg\:-bottom-9{
    bottom: -2.25rem !important;
  }

  .lg\:-bottom-10{
    bottom: -2.5rem !important;
  }

  .lg\:-bottom-11{
    bottom: -2.75rem !important;
  }

  .lg\:-bottom-12{
    bottom: -3rem !important;
  }

  .lg\:-bottom-14{
    bottom: -3.5rem !important;
  }

  .lg\:-bottom-16{
    bottom: -4rem !important;
  }

  .lg\:-bottom-20{
    bottom: -5rem !important;
  }

  .lg\:-bottom-24{
    bottom: -6rem !important;
  }

  .lg\:-bottom-28{
    bottom: -7rem !important;
  }

  .lg\:-bottom-32{
    bottom: -8rem !important;
  }

  .lg\:-bottom-36{
    bottom: -9rem !important;
  }

  .lg\:-bottom-40{
    bottom: -10rem !important;
  }

  .lg\:-bottom-44{
    bottom: -11rem !important;
  }

  .lg\:-bottom-48{
    bottom: -12rem !important;
  }

  .lg\:-bottom-52{
    bottom: -13rem !important;
  }

  .lg\:-bottom-56{
    bottom: -14rem !important;
  }

  .lg\:-bottom-60{
    bottom: -15rem !important;
  }

  .lg\:-bottom-64{
    bottom: -16rem !important;
  }

  .lg\:-bottom-72{
    bottom: -18rem !important;
  }

  .lg\:-bottom-80{
    bottom: -20rem !important;
  }

  .lg\:-bottom-96{
    bottom: -24rem !important;
  }

  .lg\:-bottom-px{
    bottom: -1px !important;
  }

  .lg\:-bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .lg\:-bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .lg\:-bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .lg\:-bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .lg\:bottom-1\/2{
    bottom: 50% !important;
  }

  .lg\:bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .lg\:bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .lg\:bottom-1\/4{
    bottom: 25% !important;
  }

  .lg\:bottom-2\/4{
    bottom: 50% !important;
  }

  .lg\:bottom-3\/4{
    bottom: 75% !important;
  }

  .lg\:bottom-full{
    bottom: 100% !important;
  }

  .lg\:-bottom-1\/2{
    bottom: -50% !important;
  }

  .lg\:-bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .lg\:-bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .lg\:-bottom-1\/4{
    bottom: -25% !important;
  }

  .lg\:-bottom-2\/4{
    bottom: -50% !important;
  }

  .lg\:-bottom-3\/4{
    bottom: -75% !important;
  }

  .lg\:-bottom-full{
    bottom: -100% !important;
  }

  .lg\:left-0{
    left: 0px !important;
  }

  .lg\:left-1{
    left: 0.25rem !important;
  }

  .lg\:left-2{
    left: 0.5rem !important;
  }

  .lg\:left-3{
    left: 0.75rem !important;
  }

  .lg\:left-4{
    left: 1rem !important;
  }

  .lg\:left-5{
    left: 1.25rem !important;
  }

  .lg\:left-6{
    left: 1.5rem !important;
  }

  .lg\:left-7{
    left: 1.75rem !important;
  }

  .lg\:left-8{
    left: 2rem !important;
  }

  .lg\:left-9{
    left: 2.25rem !important;
  }

  .lg\:left-10{
    left: 2.5rem !important;
  }

  .lg\:left-11{
    left: 2.75rem !important;
  }

  .lg\:left-12{
    left: 3rem !important;
  }

  .lg\:left-14{
    left: 3.5rem !important;
  }

  .lg\:left-16{
    left: 4rem !important;
  }

  .lg\:left-20{
    left: 5rem !important;
  }

  .lg\:left-24{
    left: 6rem !important;
  }

  .lg\:left-28{
    left: 7rem !important;
  }

  .lg\:left-32{
    left: 8rem !important;
  }

  .lg\:left-36{
    left: 9rem !important;
  }

  .lg\:left-40{
    left: 10rem !important;
  }

  .lg\:left-44{
    left: 11rem !important;
  }

  .lg\:left-48{
    left: 12rem !important;
  }

  .lg\:left-52{
    left: 13rem !important;
  }

  .lg\:left-56{
    left: 14rem !important;
  }

  .lg\:left-60{
    left: 15rem !important;
  }

  .lg\:left-64{
    left: 16rem !important;
  }

  .lg\:left-72{
    left: 18rem !important;
  }

  .lg\:left-80{
    left: 20rem !important;
  }

  .lg\:left-96{
    left: 24rem !important;
  }

  .lg\:left-auto{
    left: auto !important;
  }

  .lg\:left-px{
    left: 1px !important;
  }

  .lg\:left-0\.5{
    left: 0.125rem !important;
  }

  .lg\:left-1\.5{
    left: 0.375rem !important;
  }

  .lg\:left-2\.5{
    left: 0.625rem !important;
  }

  .lg\:left-3\.5{
    left: 0.875rem !important;
  }

  .lg\:-left-0{
    left: 0px !important;
  }

  .lg\:-left-1{
    left: -0.25rem !important;
  }

  .lg\:-left-2{
    left: -0.5rem !important;
  }

  .lg\:-left-3{
    left: -0.75rem !important;
  }

  .lg\:-left-4{
    left: -1rem !important;
  }

  .lg\:-left-5{
    left: -1.25rem !important;
  }

  .lg\:-left-6{
    left: -1.5rem !important;
  }

  .lg\:-left-7{
    left: -1.75rem !important;
  }

  .lg\:-left-8{
    left: -2rem !important;
  }

  .lg\:-left-9{
    left: -2.25rem !important;
  }

  .lg\:-left-10{
    left: -2.5rem !important;
  }

  .lg\:-left-11{
    left: -2.75rem !important;
  }

  .lg\:-left-12{
    left: -3rem !important;
  }

  .lg\:-left-14{
    left: -3.5rem !important;
  }

  .lg\:-left-16{
    left: -4rem !important;
  }

  .lg\:-left-20{
    left: -5rem !important;
  }

  .lg\:-left-24{
    left: -6rem !important;
  }

  .lg\:-left-28{
    left: -7rem !important;
  }

  .lg\:-left-32{
    left: -8rem !important;
  }

  .lg\:-left-36{
    left: -9rem !important;
  }

  .lg\:-left-40{
    left: -10rem !important;
  }

  .lg\:-left-44{
    left: -11rem !important;
  }

  .lg\:-left-48{
    left: -12rem !important;
  }

  .lg\:-left-52{
    left: -13rem !important;
  }

  .lg\:-left-56{
    left: -14rem !important;
  }

  .lg\:-left-60{
    left: -15rem !important;
  }

  .lg\:-left-64{
    left: -16rem !important;
  }

  .lg\:-left-72{
    left: -18rem !important;
  }

  .lg\:-left-80{
    left: -20rem !important;
  }

  .lg\:-left-96{
    left: -24rem !important;
  }

  .lg\:-left-px{
    left: -1px !important;
  }

  .lg\:-left-0\.5{
    left: -0.125rem !important;
  }

  .lg\:-left-1\.5{
    left: -0.375rem !important;
  }

  .lg\:-left-2\.5{
    left: -0.625rem !important;
  }

  .lg\:-left-3\.5{
    left: -0.875rem !important;
  }

  .lg\:left-1\/2{
    left: 50% !important;
  }

  .lg\:left-1\/3{
    left: 33.333333% !important;
  }

  .lg\:left-2\/3{
    left: 66.666667% !important;
  }

  .lg\:left-1\/4{
    left: 25% !important;
  }

  .lg\:left-2\/4{
    left: 50% !important;
  }

  .lg\:left-3\/4{
    left: 75% !important;
  }

  .lg\:left-full{
    left: 100% !important;
  }

  .lg\:-left-1\/2{
    left: -50% !important;
  }

  .lg\:-left-1\/3{
    left: -33.333333% !important;
  }

  .lg\:-left-2\/3{
    left: -66.666667% !important;
  }

  .lg\:-left-1\/4{
    left: -25% !important;
  }

  .lg\:-left-2\/4{
    left: -50% !important;
  }

  .lg\:-left-3\/4{
    left: -75% !important;
  }

  .lg\:-left-full{
    left: -100% !important;
  }

  .lg\:isolate{
    isolation: isolate !important;
  }

  .lg\:isolation-auto{
    isolation: auto !important;
  }

  .lg\:z-0{
    z-index: 0 !important;
  }

  .lg\:z-10{
    z-index: 10 !important;
  }

  .lg\:z-20{
    z-index: 20 !important;
  }

  .lg\:z-30{
    z-index: 30 !important;
  }

  .lg\:z-40{
    z-index: 40 !important;
  }

  .lg\:z-50{
    z-index: 50 !important;
  }

  .lg\:z-auto{
    z-index: auto !important;
  }

  .lg\:focus-within\:z-0:focus-within{
    z-index: 0 !important;
  }

  .lg\:focus-within\:z-10:focus-within{
    z-index: 10 !important;
  }

  .lg\:focus-within\:z-20:focus-within{
    z-index: 20 !important;
  }

  .lg\:focus-within\:z-30:focus-within{
    z-index: 30 !important;
  }

  .lg\:focus-within\:z-40:focus-within{
    z-index: 40 !important;
  }

  .lg\:focus-within\:z-50:focus-within{
    z-index: 50 !important;
  }

  .lg\:focus-within\:z-auto:focus-within{
    z-index: auto !important;
  }

  .lg\:focus\:z-0:focus{
    z-index: 0 !important;
  }

  .lg\:focus\:z-10:focus{
    z-index: 10 !important;
  }

  .lg\:focus\:z-20:focus{
    z-index: 20 !important;
  }

  .lg\:focus\:z-30:focus{
    z-index: 30 !important;
  }

  .lg\:focus\:z-40:focus{
    z-index: 40 !important;
  }

  .lg\:focus\:z-50:focus{
    z-index: 50 !important;
  }

  .lg\:focus\:z-auto:focus{
    z-index: auto !important;
  }

  .lg\:order-1{
    order: 1 !important;
  }

  .lg\:order-2{
    order: 2 !important;
  }

  .lg\:order-3{
    order: 3 !important;
  }

  .lg\:order-4{
    order: 4 !important;
  }

  .lg\:order-5{
    order: 5 !important;
  }

  .lg\:order-6{
    order: 6 !important;
  }

  .lg\:order-7{
    order: 7 !important;
  }

  .lg\:order-8{
    order: 8 !important;
  }

  .lg\:order-9{
    order: 9 !important;
  }

  .lg\:order-10{
    order: 10 !important;
  }

  .lg\:order-11{
    order: 11 !important;
  }

  .lg\:order-12{
    order: 12 !important;
  }

  .lg\:order-first{
    order: -9999 !important;
  }

  .lg\:order-last{
    order: 9999 !important;
  }

  .lg\:order-none{
    order: 0 !important;
  }

  .lg\:col-auto{
    grid-column: auto !important;
  }

  .lg\:col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .lg\:col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .lg\:col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .lg\:col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .lg\:col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .lg\:col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .lg\:col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .lg\:col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .lg\:col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .lg\:col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .lg\:col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .lg\:col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .lg\:col-span-full{
    grid-column: 1 / -1 !important;
  }

  .lg\:col-start-1{
    grid-column-start: 1 !important;
  }

  .lg\:col-start-2{
    grid-column-start: 2 !important;
  }

  .lg\:col-start-3{
    grid-column-start: 3 !important;
  }

  .lg\:col-start-4{
    grid-column-start: 4 !important;
  }

  .lg\:col-start-5{
    grid-column-start: 5 !important;
  }

  .lg\:col-start-6{
    grid-column-start: 6 !important;
  }

  .lg\:col-start-7{
    grid-column-start: 7 !important;
  }

  .lg\:col-start-8{
    grid-column-start: 8 !important;
  }

  .lg\:col-start-9{
    grid-column-start: 9 !important;
  }

  .lg\:col-start-10{
    grid-column-start: 10 !important;
  }

  .lg\:col-start-11{
    grid-column-start: 11 !important;
  }

  .lg\:col-start-12{
    grid-column-start: 12 !important;
  }

  .lg\:col-start-13{
    grid-column-start: 13 !important;
  }

  .lg\:col-start-auto{
    grid-column-start: auto !important;
  }

  .lg\:col-end-1{
    grid-column-end: 1 !important;
  }

  .lg\:col-end-2{
    grid-column-end: 2 !important;
  }

  .lg\:col-end-3{
    grid-column-end: 3 !important;
  }

  .lg\:col-end-4{
    grid-column-end: 4 !important;
  }

  .lg\:col-end-5{
    grid-column-end: 5 !important;
  }

  .lg\:col-end-6{
    grid-column-end: 6 !important;
  }

  .lg\:col-end-7{
    grid-column-end: 7 !important;
  }

  .lg\:col-end-8{
    grid-column-end: 8 !important;
  }

  .lg\:col-end-9{
    grid-column-end: 9 !important;
  }

  .lg\:col-end-10{
    grid-column-end: 10 !important;
  }

  .lg\:col-end-11{
    grid-column-end: 11 !important;
  }

  .lg\:col-end-12{
    grid-column-end: 12 !important;
  }

  .lg\:col-end-13{
    grid-column-end: 13 !important;
  }

  .lg\:col-end-auto{
    grid-column-end: auto !important;
  }

  .lg\:row-auto{
    grid-row: auto !important;
  }

  .lg\:row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .lg\:row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .lg\:row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .lg\:row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .lg\:row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .lg\:row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .lg\:row-span-full{
    grid-row: 1 / -1 !important;
  }

  .lg\:row-start-1{
    grid-row-start: 1 !important;
  }

  .lg\:row-start-2{
    grid-row-start: 2 !important;
  }

  .lg\:row-start-3{
    grid-row-start: 3 !important;
  }

  .lg\:row-start-4{
    grid-row-start: 4 !important;
  }

  .lg\:row-start-5{
    grid-row-start: 5 !important;
  }

  .lg\:row-start-6{
    grid-row-start: 6 !important;
  }

  .lg\:row-start-7{
    grid-row-start: 7 !important;
  }

  .lg\:row-start-auto{
    grid-row-start: auto !important;
  }

  .lg\:row-end-1{
    grid-row-end: 1 !important;
  }

  .lg\:row-end-2{
    grid-row-end: 2 !important;
  }

  .lg\:row-end-3{
    grid-row-end: 3 !important;
  }

  .lg\:row-end-4{
    grid-row-end: 4 !important;
  }

  .lg\:row-end-5{
    grid-row-end: 5 !important;
  }

  .lg\:row-end-6{
    grid-row-end: 6 !important;
  }

  .lg\:row-end-7{
    grid-row-end: 7 !important;
  }

  .lg\:row-end-auto{
    grid-row-end: auto !important;
  }

  .lg\:float-right{
    float: right !important;
  }

  .lg\:float-left{
    float: left !important;
  }

  .lg\:float-none{
    float: none !important;
  }

  .lg\:clear-left{
    clear: left !important;
  }

  .lg\:clear-right{
    clear: right !important;
  }

  .lg\:clear-both{
    clear: both !important;
  }

  .lg\:clear-none{
    clear: none !important;
  }

  .lg\:m-0{
    margin: 0px !important;
  }

  .lg\:m-1{
    margin: 0.25rem !important;
  }

  .lg\:m-2{
    margin: 0.5rem !important;
  }

  .lg\:m-3{
    margin: 0.75rem !important;
  }

  .lg\:m-4{
    margin: 1rem !important;
  }

  .lg\:m-5{
    margin: 1.25rem !important;
  }

  .lg\:m-6{
    margin: 1.5rem !important;
  }

  .lg\:m-7{
    margin: 1.75rem !important;
  }

  .lg\:m-8{
    margin: 2rem !important;
  }

  .lg\:m-9{
    margin: 2.25rem !important;
  }

  .lg\:m-10{
    margin: 2.5rem !important;
  }

  .lg\:m-11{
    margin: 2.75rem !important;
  }

  .lg\:m-12{
    margin: 3rem !important;
  }

  .lg\:m-14{
    margin: 3.5rem !important;
  }

  .lg\:m-16{
    margin: 4rem !important;
  }

  .lg\:m-20{
    margin: 5rem !important;
  }

  .lg\:m-24{
    margin: 6rem !important;
  }

  .lg\:m-28{
    margin: 7rem !important;
  }

  .lg\:m-32{
    margin: 8rem !important;
  }

  .lg\:m-36{
    margin: 9rem !important;
  }

  .lg\:m-40{
    margin: 10rem !important;
  }

  .lg\:m-44{
    margin: 11rem !important;
  }

  .lg\:m-48{
    margin: 12rem !important;
  }

  .lg\:m-52{
    margin: 13rem !important;
  }

  .lg\:m-56{
    margin: 14rem !important;
  }

  .lg\:m-60{
    margin: 15rem !important;
  }

  .lg\:m-64{
    margin: 16rem !important;
  }

  .lg\:m-72{
    margin: 18rem !important;
  }

  .lg\:m-80{
    margin: 20rem !important;
  }

  .lg\:m-96{
    margin: 24rem !important;
  }

  .lg\:m-auto{
    margin: auto !important;
  }

  .lg\:m-px{
    margin: 1px !important;
  }

  .lg\:m-0\.5{
    margin: 0.125rem !important;
  }

  .lg\:m-1\.5{
    margin: 0.375rem !important;
  }

  .lg\:m-2\.5{
    margin: 0.625rem !important;
  }

  .lg\:m-3\.5{
    margin: 0.875rem !important;
  }

  .lg\:-m-0{
    margin: 0px !important;
  }

  .lg\:-m-1{
    margin: -0.25rem !important;
  }

  .lg\:-m-2{
    margin: -0.5rem !important;
  }

  .lg\:-m-3{
    margin: -0.75rem !important;
  }

  .lg\:-m-4{
    margin: -1rem !important;
  }

  .lg\:-m-5{
    margin: -1.25rem !important;
  }

  .lg\:-m-6{
    margin: -1.5rem !important;
  }

  .lg\:-m-7{
    margin: -1.75rem !important;
  }

  .lg\:-m-8{
    margin: -2rem !important;
  }

  .lg\:-m-9{
    margin: -2.25rem !important;
  }

  .lg\:-m-10{
    margin: -2.5rem !important;
  }

  .lg\:-m-11{
    margin: -2.75rem !important;
  }

  .lg\:-m-12{
    margin: -3rem !important;
  }

  .lg\:-m-14{
    margin: -3.5rem !important;
  }

  .lg\:-m-16{
    margin: -4rem !important;
  }

  .lg\:-m-20{
    margin: -5rem !important;
  }

  .lg\:-m-24{
    margin: -6rem !important;
  }

  .lg\:-m-28{
    margin: -7rem !important;
  }

  .lg\:-m-32{
    margin: -8rem !important;
  }

  .lg\:-m-36{
    margin: -9rem !important;
  }

  .lg\:-m-40{
    margin: -10rem !important;
  }

  .lg\:-m-44{
    margin: -11rem !important;
  }

  .lg\:-m-48{
    margin: -12rem !important;
  }

  .lg\:-m-52{
    margin: -13rem !important;
  }

  .lg\:-m-56{
    margin: -14rem !important;
  }

  .lg\:-m-60{
    margin: -15rem !important;
  }

  .lg\:-m-64{
    margin: -16rem !important;
  }

  .lg\:-m-72{
    margin: -18rem !important;
  }

  .lg\:-m-80{
    margin: -20rem !important;
  }

  .lg\:-m-96{
    margin: -24rem !important;
  }

  .lg\:-m-px{
    margin: -1px !important;
  }

  .lg\:-m-0\.5{
    margin: -0.125rem !important;
  }

  .lg\:-m-1\.5{
    margin: -0.375rem !important;
  }

  .lg\:-m-2\.5{
    margin: -0.625rem !important;
  }

  .lg\:-m-3\.5{
    margin: -0.875rem !important;
  }

  .lg\:mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lg\:mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lg\:mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lg\:mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lg\:mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .lg\:mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .lg\:mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lg\:mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .lg\:mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .lg\:mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lg\:mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lg\:mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lg\:mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lg\:mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lg\:mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .lg\:mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lg\:mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .lg\:mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lg\:mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .lg\:mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lg\:mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .lg\:mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .lg\:mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .lg\:mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lg\:mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .lg\:mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .lg\:mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .lg\:mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .lg\:-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:-mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lg\:-mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lg\:-mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lg\:-mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lg\:-mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:-mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .lg\:-mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .lg\:-mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lg\:-mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .lg\:-mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .lg\:-mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:-mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lg\:-mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lg\:-mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lg\:-mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lg\:-mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lg\:-mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .lg\:-mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lg\:-mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .lg\:-mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lg\:-mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .lg\:-mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lg\:-mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .lg\:-mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .lg\:-mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .lg\:-mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lg\:-mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .lg\:-mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .lg\:-mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .lg\:-mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .lg\:my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg\:my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lg\:my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lg\:my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lg\:my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lg\:my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .lg\:my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .lg\:my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lg\:my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .lg\:my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .lg\:my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lg\:my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lg\:my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lg\:my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lg\:my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lg\:my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .lg\:my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lg\:my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .lg\:my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lg\:my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .lg\:my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lg\:my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .lg\:my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .lg\:my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .lg\:my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lg\:my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lg\:my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .lg\:my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .lg\:my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .lg\:my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .lg\:-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .lg\:-my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lg\:-my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lg\:-my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lg\:-my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:-my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lg\:-my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:-my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .lg\:-my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:-my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .lg\:-my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lg\:-my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .lg\:-my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:-my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .lg\:-my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:-my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:-my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lg\:-my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .lg\:-my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lg\:-my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .lg\:-my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lg\:-my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .lg\:-my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lg\:-my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .lg\:-my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lg\:-my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .lg\:-my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lg\:-my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .lg\:-my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .lg\:-my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .lg\:-my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lg\:-my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .lg\:-my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .lg\:-my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .lg\:-my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .lg\:mt-0{
    margin-top: 0px !important;
  }

  .lg\:mt-1{
    margin-top: 0.25rem !important;
  }

  .lg\:mt-2{
    margin-top: 0.5rem !important;
  }

  .lg\:mt-3{
    margin-top: 0.75rem !important;
  }

  .lg\:mt-4{
    margin-top: 1rem !important;
  }

  .lg\:mt-5{
    margin-top: 1.25rem !important;
  }

  .lg\:mt-6{
    margin-top: 1.5rem !important;
  }

  .lg\:mt-7{
    margin-top: 1.75rem !important;
  }

  .lg\:mt-8{
    margin-top: 2rem !important;
  }

  .lg\:mt-9{
    margin-top: 2.25rem !important;
  }

  .lg\:mt-10{
    margin-top: 2.5rem !important;
  }

  .lg\:mt-11{
    margin-top: 2.75rem !important;
  }

  .lg\:mt-12{
    margin-top: 3rem !important;
  }

  .lg\:mt-14{
    margin-top: 3.5rem !important;
  }

  .lg\:mt-16{
    margin-top: 4rem !important;
  }

  .lg\:mt-20{
    margin-top: 5rem !important;
  }

  .lg\:mt-24{
    margin-top: 6rem !important;
  }

  .lg\:mt-28{
    margin-top: 7rem !important;
  }

  .lg\:mt-32{
    margin-top: 8rem !important;
  }

  .lg\:mt-36{
    margin-top: 9rem !important;
  }

  .lg\:mt-40{
    margin-top: 10rem !important;
  }

  .lg\:mt-44{
    margin-top: 11rem !important;
  }

  .lg\:mt-48{
    margin-top: 12rem !important;
  }

  .lg\:mt-52{
    margin-top: 13rem !important;
  }

  .lg\:mt-56{
    margin-top: 14rem !important;
  }

  .lg\:mt-60{
    margin-top: 15rem !important;
  }

  .lg\:mt-64{
    margin-top: 16rem !important;
  }

  .lg\:mt-72{
    margin-top: 18rem !important;
  }

  .lg\:mt-80{
    margin-top: 20rem !important;
  }

  .lg\:mt-96{
    margin-top: 24rem !important;
  }

  .lg\:mt-auto{
    margin-top: auto !important;
  }

  .lg\:mt-px{
    margin-top: 1px !important;
  }

  .lg\:mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .lg\:mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .lg\:mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .lg\:mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .lg\:-mt-0{
    margin-top: 0px !important;
  }

  .lg\:-mt-1{
    margin-top: -0.25rem !important;
  }

  .lg\:-mt-2{
    margin-top: -0.5rem !important;
  }

  .lg\:-mt-3{
    margin-top: -0.75rem !important;
  }

  .lg\:-mt-4{
    margin-top: -1rem !important;
  }

  .lg\:-mt-5{
    margin-top: -1.25rem !important;
  }

  .lg\:-mt-6{
    margin-top: -1.5rem !important;
  }

  .lg\:-mt-7{
    margin-top: -1.75rem !important;
  }

  .lg\:-mt-8{
    margin-top: -2rem !important;
  }

  .lg\:-mt-9{
    margin-top: -2.25rem !important;
  }

  .lg\:-mt-10{
    margin-top: -2.5rem !important;
  }

  .lg\:-mt-11{
    margin-top: -2.75rem !important;
  }

  .lg\:-mt-12{
    margin-top: -3rem !important;
  }

  .lg\:-mt-14{
    margin-top: -3.5rem !important;
  }

  .lg\:-mt-16{
    margin-top: -4rem !important;
  }

  .lg\:-mt-20{
    margin-top: -5rem !important;
  }

  .lg\:-mt-24{
    margin-top: -6rem !important;
  }

  .lg\:-mt-28{
    margin-top: -7rem !important;
  }

  .lg\:-mt-32{
    margin-top: -8rem !important;
  }

  .lg\:-mt-36{
    margin-top: -9rem !important;
  }

  .lg\:-mt-40{
    margin-top: -10rem !important;
  }

  .lg\:-mt-44{
    margin-top: -11rem !important;
  }

  .lg\:-mt-48{
    margin-top: -12rem !important;
  }

  .lg\:-mt-52{
    margin-top: -13rem !important;
  }

  .lg\:-mt-56{
    margin-top: -14rem !important;
  }

  .lg\:-mt-60{
    margin-top: -15rem !important;
  }

  .lg\:-mt-64{
    margin-top: -16rem !important;
  }

  .lg\:-mt-72{
    margin-top: -18rem !important;
  }

  .lg\:-mt-80{
    margin-top: -20rem !important;
  }

  .lg\:-mt-96{
    margin-top: -24rem !important;
  }

  .lg\:-mt-px{
    margin-top: -1px !important;
  }

  .lg\:-mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .lg\:-mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .lg\:-mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .lg\:-mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .lg\:mr-0{
    margin-right: 0px !important;
  }

  .lg\:mr-1{
    margin-right: 0.25rem !important;
  }

  .lg\:mr-2{
    margin-right: 0.5rem !important;
  }

  .lg\:mr-3{
    margin-right: 0.75rem !important;
  }

  .lg\:mr-4{
    margin-right: 1rem !important;
  }

  .lg\:mr-5{
    margin-right: 1.25rem !important;
  }

  .lg\:mr-6{
    margin-right: 1.5rem !important;
  }

  .lg\:mr-7{
    margin-right: 1.75rem !important;
  }

  .lg\:mr-8{
    margin-right: 2rem !important;
  }

  .lg\:mr-9{
    margin-right: 2.25rem !important;
  }

  .lg\:mr-10{
    margin-right: 2.5rem !important;
  }

  .lg\:mr-11{
    margin-right: 2.75rem !important;
  }

  .lg\:mr-12{
    margin-right: 3rem !important;
  }

  .lg\:mr-14{
    margin-right: 3.5rem !important;
  }

  .lg\:mr-16{
    margin-right: 4rem !important;
  }

  .lg\:mr-20{
    margin-right: 5rem !important;
  }

  .lg\:mr-24{
    margin-right: 6rem !important;
  }

  .lg\:mr-28{
    margin-right: 7rem !important;
  }

  .lg\:mr-32{
    margin-right: 8rem !important;
  }

  .lg\:mr-36{
    margin-right: 9rem !important;
  }

  .lg\:mr-40{
    margin-right: 10rem !important;
  }

  .lg\:mr-44{
    margin-right: 11rem !important;
  }

  .lg\:mr-48{
    margin-right: 12rem !important;
  }

  .lg\:mr-52{
    margin-right: 13rem !important;
  }

  .lg\:mr-56{
    margin-right: 14rem !important;
  }

  .lg\:mr-60{
    margin-right: 15rem !important;
  }

  .lg\:mr-64{
    margin-right: 16rem !important;
  }

  .lg\:mr-72{
    margin-right: 18rem !important;
  }

  .lg\:mr-80{
    margin-right: 20rem !important;
  }

  .lg\:mr-96{
    margin-right: 24rem !important;
  }

  .lg\:mr-auto{
    margin-right: auto !important;
  }

  .lg\:mr-px{
    margin-right: 1px !important;
  }

  .lg\:mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .lg\:mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .lg\:mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .lg\:mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .lg\:-mr-0{
    margin-right: 0px !important;
  }

  .lg\:-mr-1{
    margin-right: -0.25rem !important;
  }

  .lg\:-mr-2{
    margin-right: -0.5rem !important;
  }

  .lg\:-mr-3{
    margin-right: -0.75rem !important;
  }

  .lg\:-mr-4{
    margin-right: -1rem !important;
  }

  .lg\:-mr-5{
    margin-right: -1.25rem !important;
  }

  .lg\:-mr-6{
    margin-right: -1.5rem !important;
  }

  .lg\:-mr-7{
    margin-right: -1.75rem !important;
  }

  .lg\:-mr-8{
    margin-right: -2rem !important;
  }

  .lg\:-mr-9{
    margin-right: -2.25rem !important;
  }

  .lg\:-mr-10{
    margin-right: -2.5rem !important;
  }

  .lg\:-mr-11{
    margin-right: -2.75rem !important;
  }

  .lg\:-mr-12{
    margin-right: -3rem !important;
  }

  .lg\:-mr-14{
    margin-right: -3.5rem !important;
  }

  .lg\:-mr-16{
    margin-right: -4rem !important;
  }

  .lg\:-mr-20{
    margin-right: -5rem !important;
  }

  .lg\:-mr-24{
    margin-right: -6rem !important;
  }

  .lg\:-mr-28{
    margin-right: -7rem !important;
  }

  .lg\:-mr-32{
    margin-right: -8rem !important;
  }

  .lg\:-mr-36{
    margin-right: -9rem !important;
  }

  .lg\:-mr-40{
    margin-right: -10rem !important;
  }

  .lg\:-mr-44{
    margin-right: -11rem !important;
  }

  .lg\:-mr-48{
    margin-right: -12rem !important;
  }

  .lg\:-mr-52{
    margin-right: -13rem !important;
  }

  .lg\:-mr-56{
    margin-right: -14rem !important;
  }

  .lg\:-mr-60{
    margin-right: -15rem !important;
  }

  .lg\:-mr-64{
    margin-right: -16rem !important;
  }

  .lg\:-mr-72{
    margin-right: -18rem !important;
  }

  .lg\:-mr-80{
    margin-right: -20rem !important;
  }

  .lg\:-mr-96{
    margin-right: -24rem !important;
  }

  .lg\:-mr-px{
    margin-right: -1px !important;
  }

  .lg\:-mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .lg\:-mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .lg\:-mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .lg\:-mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .lg\:mb-0{
    margin-bottom: 0px !important;
  }

  .lg\:mb-1{
    margin-bottom: 0.25rem !important;
  }

  .lg\:mb-2{
    margin-bottom: 0.5rem !important;
  }

  .lg\:mb-3{
    margin-bottom: 0.75rem !important;
  }

  .lg\:mb-4{
    margin-bottom: 1rem !important;
  }

  .lg\:mb-5{
    margin-bottom: 1.25rem !important;
  }

  .lg\:mb-6{
    margin-bottom: 1.5rem !important;
  }

  .lg\:mb-7{
    margin-bottom: 1.75rem !important;
  }

  .lg\:mb-8{
    margin-bottom: 2rem !important;
  }

  .lg\:mb-9{
    margin-bottom: 2.25rem !important;
  }

  .lg\:mb-10{
    margin-bottom: 2.5rem !important;
  }

  .lg\:mb-11{
    margin-bottom: 2.75rem !important;
  }

  .lg\:mb-12{
    margin-bottom: 3rem !important;
  }

  .lg\:mb-14{
    margin-bottom: 3.5rem !important;
  }

  .lg\:mb-16{
    margin-bottom: 4rem !important;
  }

  .lg\:mb-20{
    margin-bottom: 5rem !important;
  }

  .lg\:mb-24{
    margin-bottom: 6rem !important;
  }

  .lg\:mb-28{
    margin-bottom: 7rem !important;
  }

  .lg\:mb-32{
    margin-bottom: 8rem !important;
  }

  .lg\:mb-36{
    margin-bottom: 9rem !important;
  }

  .lg\:mb-40{
    margin-bottom: 10rem !important;
  }

  .lg\:mb-44{
    margin-bottom: 11rem !important;
  }

  .lg\:mb-48{
    margin-bottom: 12rem !important;
  }

  .lg\:mb-52{
    margin-bottom: 13rem !important;
  }

  .lg\:mb-56{
    margin-bottom: 14rem !important;
  }

  .lg\:mb-60{
    margin-bottom: 15rem !important;
  }

  .lg\:mb-64{
    margin-bottom: 16rem !important;
  }

  .lg\:mb-72{
    margin-bottom: 18rem !important;
  }

  .lg\:mb-80{
    margin-bottom: 20rem !important;
  }

  .lg\:mb-96{
    margin-bottom: 24rem !important;
  }

  .lg\:mb-auto{
    margin-bottom: auto !important;
  }

  .lg\:mb-px{
    margin-bottom: 1px !important;
  }

  .lg\:mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .lg\:mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .lg\:mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .lg\:mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .lg\:-mb-0{
    margin-bottom: 0px !important;
  }

  .lg\:-mb-1{
    margin-bottom: -0.25rem !important;
  }

  .lg\:-mb-2{
    margin-bottom: -0.5rem !important;
  }

  .lg\:-mb-3{
    margin-bottom: -0.75rem !important;
  }

  .lg\:-mb-4{
    margin-bottom: -1rem !important;
  }

  .lg\:-mb-5{
    margin-bottom: -1.25rem !important;
  }

  .lg\:-mb-6{
    margin-bottom: -1.5rem !important;
  }

  .lg\:-mb-7{
    margin-bottom: -1.75rem !important;
  }

  .lg\:-mb-8{
    margin-bottom: -2rem !important;
  }

  .lg\:-mb-9{
    margin-bottom: -2.25rem !important;
  }

  .lg\:-mb-10{
    margin-bottom: -2.5rem !important;
  }

  .lg\:-mb-11{
    margin-bottom: -2.75rem !important;
  }

  .lg\:-mb-12{
    margin-bottom: -3rem !important;
  }

  .lg\:-mb-14{
    margin-bottom: -3.5rem !important;
  }

  .lg\:-mb-16{
    margin-bottom: -4rem !important;
  }

  .lg\:-mb-20{
    margin-bottom: -5rem !important;
  }

  .lg\:-mb-24{
    margin-bottom: -6rem !important;
  }

  .lg\:-mb-28{
    margin-bottom: -7rem !important;
  }

  .lg\:-mb-32{
    margin-bottom: -8rem !important;
  }

  .lg\:-mb-36{
    margin-bottom: -9rem !important;
  }

  .lg\:-mb-40{
    margin-bottom: -10rem !important;
  }

  .lg\:-mb-44{
    margin-bottom: -11rem !important;
  }

  .lg\:-mb-48{
    margin-bottom: -12rem !important;
  }

  .lg\:-mb-52{
    margin-bottom: -13rem !important;
  }

  .lg\:-mb-56{
    margin-bottom: -14rem !important;
  }

  .lg\:-mb-60{
    margin-bottom: -15rem !important;
  }

  .lg\:-mb-64{
    margin-bottom: -16rem !important;
  }

  .lg\:-mb-72{
    margin-bottom: -18rem !important;
  }

  .lg\:-mb-80{
    margin-bottom: -20rem !important;
  }

  .lg\:-mb-96{
    margin-bottom: -24rem !important;
  }

  .lg\:-mb-px{
    margin-bottom: -1px !important;
  }

  .lg\:-mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .lg\:-mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .lg\:-mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .lg\:-mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .lg\:ml-0{
    margin-left: 0px !important;
  }

  .lg\:ml-1{
    margin-left: 0.25rem !important;
  }

  .lg\:ml-2{
    margin-left: 0.5rem !important;
  }

  .lg\:ml-3{
    margin-left: 0.75rem !important;
  }

  .lg\:ml-4{
    margin-left: 1rem !important;
  }

  .lg\:ml-5{
    margin-left: 1.25rem !important;
  }

  .lg\:ml-6{
    margin-left: 1.5rem !important;
  }

  .lg\:ml-7{
    margin-left: 1.75rem !important;
  }

  .lg\:ml-8{
    margin-left: 2rem !important;
  }

  .lg\:ml-9{
    margin-left: 2.25rem !important;
  }

  .lg\:ml-10{
    margin-left: 2.5rem !important;
  }

  .lg\:ml-11{
    margin-left: 2.75rem !important;
  }

  .lg\:ml-12{
    margin-left: 3rem !important;
  }

  .lg\:ml-14{
    margin-left: 3.5rem !important;
  }

  .lg\:ml-16{
    margin-left: 4rem !important;
  }

  .lg\:ml-20{
    margin-left: 5rem !important;
  }

  .lg\:ml-24{
    margin-left: 6rem !important;
  }

  .lg\:ml-28{
    margin-left: 7rem !important;
  }

  .lg\:ml-32{
    margin-left: 8rem !important;
  }

  .lg\:ml-36{
    margin-left: 9rem !important;
  }

  .lg\:ml-40{
    margin-left: 10rem !important;
  }

  .lg\:ml-44{
    margin-left: 11rem !important;
  }

  .lg\:ml-48{
    margin-left: 12rem !important;
  }

  .lg\:ml-52{
    margin-left: 13rem !important;
  }

  .lg\:ml-56{
    margin-left: 14rem !important;
  }

  .lg\:ml-60{
    margin-left: 15rem !important;
  }

  .lg\:ml-64{
    margin-left: 16rem !important;
  }

  .lg\:ml-72{
    margin-left: 18rem !important;
  }

  .lg\:ml-80{
    margin-left: 20rem !important;
  }

  .lg\:ml-96{
    margin-left: 24rem !important;
  }

  .lg\:ml-auto{
    margin-left: auto !important;
  }

  .lg\:ml-px{
    margin-left: 1px !important;
  }

  .lg\:ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .lg\:ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .lg\:ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .lg\:ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .lg\:-ml-0{
    margin-left: 0px !important;
  }

  .lg\:-ml-1{
    margin-left: -0.25rem !important;
  }

  .lg\:-ml-2{
    margin-left: -0.5rem !important;
  }

  .lg\:-ml-3{
    margin-left: -0.75rem !important;
  }

  .lg\:-ml-4{
    margin-left: -1rem !important;
  }

  .lg\:-ml-5{
    margin-left: -1.25rem !important;
  }

  .lg\:-ml-6{
    margin-left: -1.5rem !important;
  }

  .lg\:-ml-7{
    margin-left: -1.75rem !important;
  }

  .lg\:-ml-8{
    margin-left: -2rem !important;
  }

  .lg\:-ml-9{
    margin-left: -2.25rem !important;
  }

  .lg\:-ml-10{
    margin-left: -2.5rem !important;
  }

  .lg\:-ml-11{
    margin-left: -2.75rem !important;
  }

  .lg\:-ml-12{
    margin-left: -3rem !important;
  }

  .lg\:-ml-14{
    margin-left: -3.5rem !important;
  }

  .lg\:-ml-16{
    margin-left: -4rem !important;
  }

  .lg\:-ml-20{
    margin-left: -5rem !important;
  }

  .lg\:-ml-24{
    margin-left: -6rem !important;
  }

  .lg\:-ml-28{
    margin-left: -7rem !important;
  }

  .lg\:-ml-32{
    margin-left: -8rem !important;
  }

  .lg\:-ml-36{
    margin-left: -9rem !important;
  }

  .lg\:-ml-40{
    margin-left: -10rem !important;
  }

  .lg\:-ml-44{
    margin-left: -11rem !important;
  }

  .lg\:-ml-48{
    margin-left: -12rem !important;
  }

  .lg\:-ml-52{
    margin-left: -13rem !important;
  }

  .lg\:-ml-56{
    margin-left: -14rem !important;
  }

  .lg\:-ml-60{
    margin-left: -15rem !important;
  }

  .lg\:-ml-64{
    margin-left: -16rem !important;
  }

  .lg\:-ml-72{
    margin-left: -18rem !important;
  }

  .lg\:-ml-80{
    margin-left: -20rem !important;
  }

  .lg\:-ml-96{
    margin-left: -24rem !important;
  }

  .lg\:-ml-px{
    margin-left: -1px !important;
  }

  .lg\:-ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .lg\:-ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .lg\:-ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .lg\:-ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .lg\:box-border{
    box-sizing: border-box !important;
  }

  .lg\:box-content{
    box-sizing: content-box !important;
  }

  .lg\:block{
    display: block !important;
  }

  .lg\:inline-block{
    display: inline-block !important;
  }

  .lg\:inline{
    display: inline !important;
  }

  .lg\:flex{
    display: flex !important;
  }

  .lg\:inline-flex{
    display: inline-flex !important;
  }

  .lg\:table{
    display: table !important;
  }

  .lg\:inline-table{
    display: inline-table !important;
  }

  .lg\:table-caption{
    display: table-caption !important;
  }

  .lg\:table-cell{
    display: table-cell !important;
  }

  .lg\:table-column{
    display: table-column !important;
  }

  .lg\:table-column-group{
    display: table-column-group !important;
  }

  .lg\:table-footer-group{
    display: table-footer-group !important;
  }

  .lg\:table-header-group{
    display: table-header-group !important;
  }

  .lg\:table-row-group{
    display: table-row-group !important;
  }

  .lg\:table-row{
    display: table-row !important;
  }

  .lg\:flow-root{
    display: flow-root !important;
  }

  .lg\:grid{
    display: grid !important;
  }

  .lg\:inline-grid{
    display: inline-grid !important;
  }

  .lg\:contents{
    display: contents !important;
  }

  .lg\:list-item{
    display: list-item !important;
  }

  .lg\:hidden{
    display: none !important;
  }

  .lg\:h-0{
    height: 0px !important;
  }

  .lg\:h-1{
    height: 0.25rem !important;
  }

  .lg\:h-2{
    height: 0.5rem !important;
  }

  .lg\:h-3{
    height: 0.75rem !important;
  }

  .lg\:h-4{
    height: 1rem !important;
  }

  .lg\:h-5{
    height: 1.25rem !important;
  }

  .lg\:h-6{
    height: 1.5rem !important;
  }

  .lg\:h-7{
    height: 1.75rem !important;
  }

  .lg\:h-8{
    height: 2rem !important;
  }

  .lg\:h-9{
    height: 2.25rem !important;
  }

  .lg\:h-10{
    height: 2.5rem !important;
  }

  .lg\:h-11{
    height: 2.75rem !important;
  }

  .lg\:h-12{
    height: 3rem !important;
  }

  .lg\:h-14{
    height: 3.5rem !important;
  }

  .lg\:h-16{
    height: 4rem !important;
  }

  .lg\:h-20{
    height: 5rem !important;
  }

  .lg\:h-24{
    height: 6rem !important;
  }

  .lg\:h-28{
    height: 7rem !important;
  }

  .lg\:h-32{
    height: 8rem !important;
  }

  .lg\:h-36{
    height: 9rem !important;
  }

  .lg\:h-40{
    height: 10rem !important;
  }

  .lg\:h-44{
    height: 11rem !important;
  }

  .lg\:h-48{
    height: 12rem !important;
  }

  .lg\:h-52{
    height: 13rem !important;
  }

  .lg\:h-56{
    height: 14rem !important;
  }

  .lg\:h-60{
    height: 15rem !important;
  }

  .lg\:h-64{
    height: 16rem !important;
  }

  .lg\:h-72{
    height: 18rem !important;
  }

  .lg\:h-80{
    height: 20rem !important;
  }

  .lg\:h-96{
    height: 24rem !important;
  }

  .lg\:h-auto{
    height: auto !important;
  }

  .lg\:h-px{
    height: 1px !important;
  }

  .lg\:h-0\.5{
    height: 0.125rem !important;
  }

  .lg\:h-1\.5{
    height: 0.375rem !important;
  }

  .lg\:h-2\.5{
    height: 0.625rem !important;
  }

  .lg\:h-3\.5{
    height: 0.875rem !important;
  }

  .lg\:h-1\/2{
    height: 50% !important;
  }

  .lg\:h-1\/3{
    height: 33.333333% !important;
  }

  .lg\:h-2\/3{
    height: 66.666667% !important;
  }

  .lg\:h-1\/4{
    height: 25% !important;
  }

  .lg\:h-2\/4{
    height: 50% !important;
  }

  .lg\:h-3\/4{
    height: 75% !important;
  }

  .lg\:h-1\/5{
    height: 20% !important;
  }

  .lg\:h-2\/5{
    height: 40% !important;
  }

  .lg\:h-3\/5{
    height: 60% !important;
  }

  .lg\:h-4\/5{
    height: 80% !important;
  }

  .lg\:h-1\/6{
    height: 16.666667% !important;
  }

  .lg\:h-2\/6{
    height: 33.333333% !important;
  }

  .lg\:h-3\/6{
    height: 50% !important;
  }

  .lg\:h-4\/6{
    height: 66.666667% !important;
  }

  .lg\:h-5\/6{
    height: 83.333333% !important;
  }

  .lg\:h-full{
    height: 100% !important;
  }

  .lg\:h-screen{
    height: 100vh !important;
  }

  .lg\:max-h-0{
    max-height: 0px !important;
  }

  .lg\:max-h-1{
    max-height: 0.25rem !important;
  }

  .lg\:max-h-2{
    max-height: 0.5rem !important;
  }

  .lg\:max-h-3{
    max-height: 0.75rem !important;
  }

  .lg\:max-h-4{
    max-height: 1rem !important;
  }

  .lg\:max-h-5{
    max-height: 1.25rem !important;
  }

  .lg\:max-h-6{
    max-height: 1.5rem !important;
  }

  .lg\:max-h-7{
    max-height: 1.75rem !important;
  }

  .lg\:max-h-8{
    max-height: 2rem !important;
  }

  .lg\:max-h-9{
    max-height: 2.25rem !important;
  }

  .lg\:max-h-10{
    max-height: 2.5rem !important;
  }

  .lg\:max-h-11{
    max-height: 2.75rem !important;
  }

  .lg\:max-h-12{
    max-height: 3rem !important;
  }

  .lg\:max-h-14{
    max-height: 3.5rem !important;
  }

  .lg\:max-h-16{
    max-height: 4rem !important;
  }

  .lg\:max-h-20{
    max-height: 5rem !important;
  }

  .lg\:max-h-24{
    max-height: 6rem !important;
  }

  .lg\:max-h-28{
    max-height: 7rem !important;
  }

  .lg\:max-h-32{
    max-height: 8rem !important;
  }

  .lg\:max-h-36{
    max-height: 9rem !important;
  }

  .lg\:max-h-40{
    max-height: 10rem !important;
  }

  .lg\:max-h-44{
    max-height: 11rem !important;
  }

  .lg\:max-h-48{
    max-height: 12rem !important;
  }

  .lg\:max-h-52{
    max-height: 13rem !important;
  }

  .lg\:max-h-56{
    max-height: 14rem !important;
  }

  .lg\:max-h-60{
    max-height: 15rem !important;
  }

  .lg\:max-h-64{
    max-height: 16rem !important;
  }

  .lg\:max-h-72{
    max-height: 18rem !important;
  }

  .lg\:max-h-80{
    max-height: 20rem !important;
  }

  .lg\:max-h-96{
    max-height: 24rem !important;
  }

  .lg\:max-h-px{
    max-height: 1px !important;
  }

  .lg\:max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .lg\:max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .lg\:max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .lg\:max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .lg\:max-h-full{
    max-height: 100% !important;
  }

  .lg\:max-h-screen{
    max-height: 100vh !important;
  }

  .lg\:min-h-0{
    min-height: 0px !important;
  }

  .lg\:min-h-full{
    min-height: 100% !important;
  }

  .lg\:min-h-screen{
    min-height: 100vh !important;
  }

  .lg\:w-0{
    width: 0px !important;
  }

  .lg\:w-1{
    width: 0.25rem !important;
  }

  .lg\:w-2{
    width: 0.5rem !important;
  }

  .lg\:w-3{
    width: 0.75rem !important;
  }

  .lg\:w-4{
    width: 1rem !important;
  }

  .lg\:w-5{
    width: 1.25rem !important;
  }

  .lg\:w-6{
    width: 1.5rem !important;
  }

  .lg\:w-7{
    width: 1.75rem !important;
  }

  .lg\:w-8{
    width: 2rem !important;
  }

  .lg\:w-9{
    width: 2.25rem !important;
  }

  .lg\:w-10{
    width: 2.5rem !important;
  }

  .lg\:w-11{
    width: 2.75rem !important;
  }

  .lg\:w-12{
    width: 3rem !important;
  }

  .lg\:w-14{
    width: 3.5rem !important;
  }

  .lg\:w-16{
    width: 4rem !important;
  }

  .lg\:w-20{
    width: 5rem !important;
  }

  .lg\:w-24{
    width: 6rem !important;
  }

  .lg\:w-28{
    width: 7rem !important;
  }

  .lg\:w-32{
    width: 8rem !important;
  }

  .lg\:w-36{
    width: 9rem !important;
  }

  .lg\:w-40{
    width: 10rem !important;
  }

  .lg\:w-44{
    width: 11rem !important;
  }

  .lg\:w-48{
    width: 12rem !important;
  }

  .lg\:w-52{
    width: 13rem !important;
  }

  .lg\:w-56{
    width: 14rem !important;
  }

  .lg\:w-60{
    width: 15rem !important;
  }

  .lg\:w-64{
    width: 16rem !important;
  }

  .lg\:w-72{
    width: 18rem !important;
  }

  .lg\:w-80{
    width: 20rem !important;
  }

  .lg\:w-96{
    width: 24rem !important;
  }

  .lg\:w-auto{
    width: auto !important;
  }

  .lg\:w-px{
    width: 1px !important;
  }

  .lg\:w-0\.5{
    width: 0.125rem !important;
  }

  .lg\:w-1\.5{
    width: 0.375rem !important;
  }

  .lg\:w-2\.5{
    width: 0.625rem !important;
  }

  .lg\:w-3\.5{
    width: 0.875rem !important;
  }

  .lg\:w-1\/2{
    width: 50% !important;
  }

  .lg\:w-1\/3{
    width: 33.333333% !important;
  }

  .lg\:w-2\/3{
    width: 66.666667% !important;
  }

  .lg\:w-1\/4{
    width: 25% !important;
  }

  .lg\:w-2\/4{
    width: 50% !important;
  }

  .lg\:w-3\/4{
    width: 75% !important;
  }

  .lg\:w-1\/5{
    width: 20% !important;
  }

  .lg\:w-2\/5{
    width: 40% !important;
  }

  .lg\:w-3\/5{
    width: 60% !important;
  }

  .lg\:w-4\/5{
    width: 80% !important;
  }

  .lg\:w-1\/6{
    width: 16.666667% !important;
  }

  .lg\:w-2\/6{
    width: 33.333333% !important;
  }

  .lg\:w-3\/6{
    width: 50% !important;
  }

  .lg\:w-4\/6{
    width: 66.666667% !important;
  }

  .lg\:w-5\/6{
    width: 83.333333% !important;
  }

  .lg\:w-1\/12{
    width: 8.333333% !important;
  }

  .lg\:w-2\/12{
    width: 16.666667% !important;
  }

  .lg\:w-3\/12{
    width: 25% !important;
  }

  .lg\:w-4\/12{
    width: 33.333333% !important;
  }

  .lg\:w-5\/12{
    width: 41.666667% !important;
  }

  .lg\:w-6\/12{
    width: 50% !important;
  }

  .lg\:w-7\/12{
    width: 58.333333% !important;
  }

  .lg\:w-8\/12{
    width: 66.666667% !important;
  }

  .lg\:w-9\/12{
    width: 75% !important;
  }

  .lg\:w-10\/12{
    width: 83.333333% !important;
  }

  .lg\:w-11\/12{
    width: 91.666667% !important;
  }

  .lg\:w-full{
    width: 100% !important;
  }

  .lg\:w-screen{
    width: 100vw !important;
  }

  .lg\:w-min{
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .lg\:w-max{
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .lg\:min-w-0{
    min-width: 0px !important;
  }

  .lg\:min-w-full{
    min-width: 100% !important;
  }

  .lg\:min-w-min{
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important;
  }

  .lg\:min-w-max{
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
  }

  .lg\:max-w-0{
    max-width: 0rem !important;
  }

  .lg\:max-w-none{
    max-width: none !important;
  }

  .lg\:max-w-xs{
    max-width: 20rem !important;
  }

  .lg\:max-w-sm{
    max-width: 24rem !important;
  }

  .lg\:max-w-md{
    max-width: 28rem !important;
  }

  .lg\:max-w-lg{
    max-width: 32rem !important;
  }

  .lg\:max-w-xl{
    max-width: 36rem !important;
  }

  .lg\:max-w-2xl{
    max-width: 42rem !important;
  }

  .lg\:max-w-3xl{
    max-width: 48rem !important;
  }

  .lg\:max-w-4xl{
    max-width: 56rem !important;
  }

  .lg\:max-w-5xl{
    max-width: 64rem !important;
  }

  .lg\:max-w-6xl{
    max-width: 72rem !important;
  }

  .lg\:max-w-7xl{
    max-width: 80rem !important;
  }

  .lg\:max-w-full{
    max-width: 100% !important;
  }

  .lg\:max-w-min{
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .lg\:max-w-max{
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .lg\:max-w-prose{
    max-width: 65ch !important;
  }

  .lg\:max-w-screen-sm{
    max-width: 640px !important;
  }

  .lg\:max-w-screen-md{
    max-width: 768px !important;
  }

  .lg\:max-w-screen-lg{
    max-width: 1024px !important;
  }

  .lg\:max-w-screen-xl{
    max-width: 1280px !important;
  }

  .lg\:max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .lg\:flex-1{
    flex: 1 1 0% !important;
  }

  .lg\:flex-auto{
    flex: 1 1 auto !important;
  }

  .lg\:flex-initial{
    flex: 0 1 auto !important;
  }

  .lg\:flex-none{
    flex: none !important;
  }

  .lg\:flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .lg\:flex-shrink{
    flex-shrink: 1 !important;
  }

  .lg\:flex-grow-0{
    flex-grow: 0 !important;
  }

  .lg\:flex-grow{
    flex-grow: 1 !important;
  }

  .lg\:table-auto{
    table-layout: auto !important;
  }

  .lg\:table-fixed{
    table-layout: fixed !important;
  }

  .lg\:border-collapse{
    border-collapse: collapse !important;
  }

  .lg\:border-separate{
    border-collapse: separate !important;
  }

  .lg\:origin-center{
    transform-origin: center !important;
  }

  .lg\:origin-top{
    transform-origin: top !important;
  }

  .lg\:origin-top-right{
    transform-origin: top right !important;
  }

  .lg\:origin-right{
    transform-origin: right !important;
  }

  .lg\:origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .lg\:origin-bottom{
    transform-origin: bottom !important;
  }

  .lg\:origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .lg\:origin-left{
    transform-origin: left !important;
  }

  .lg\:origin-top-left{
    transform-origin: top left !important;
  }

  .lg\:transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .lg\:transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .lg\:transform-none{
    transform: none !important;
  }

  .lg\:translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .lg\:translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .lg\:translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .lg\:translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .lg\:translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .lg\:translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .lg\:translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .lg\:translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .lg\:translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .lg\:translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .lg\:translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .lg\:translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .lg\:translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .lg\:translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .lg\:translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .lg\:translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .lg\:translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .lg\:translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .lg\:translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .lg\:translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .lg\:translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .lg\:translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .lg\:translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .lg\:translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .lg\:translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .lg\:translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .lg\:translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .lg\:translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .lg\:translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .lg\:translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .lg\:translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .lg\:translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .lg\:translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .lg\:translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .lg\:translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .lg\:-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .lg\:-translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .lg\:-translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .lg\:-translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .lg\:-translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .lg\:-translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .lg\:-translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .lg\:-translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .lg\:-translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .lg\:-translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .lg\:-translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .lg\:-translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .lg\:-translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .lg\:-translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .lg\:-translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .lg\:-translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .lg\:-translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .lg\:-translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .lg\:-translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .lg\:-translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .lg\:-translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .lg\:-translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .lg\:-translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .lg\:-translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .lg\:-translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .lg\:-translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .lg\:-translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .lg\:-translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .lg\:-translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .lg\:-translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .lg\:-translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .lg\:-translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .lg\:-translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .lg\:-translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .lg\:-translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .lg\:translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .lg\:translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .lg\:translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .lg\:translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .lg\:translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .lg\:translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .lg\:translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .lg\:-translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .lg\:-translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .lg\:-translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .lg\:-translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .lg\:-translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .lg\:-translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .lg\:-translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .lg\:translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .lg\:translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .lg\:translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .lg\:translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .lg\:translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .lg\:translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .lg\:translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .lg\:translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .lg\:translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .lg\:translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .lg\:translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .lg\:translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .lg\:translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .lg\:translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .lg\:translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .lg\:translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .lg\:translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .lg\:translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .lg\:translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .lg\:translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .lg\:translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .lg\:translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .lg\:translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .lg\:translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .lg\:translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .lg\:translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .lg\:translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .lg\:translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .lg\:translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .lg\:translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .lg\:translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .lg\:translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .lg\:translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .lg\:translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .lg\:translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .lg\:-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .lg\:-translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .lg\:-translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .lg\:-translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .lg\:-translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .lg\:-translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .lg\:-translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .lg\:-translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .lg\:-translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .lg\:-translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .lg\:-translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .lg\:-translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .lg\:-translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .lg\:-translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .lg\:-translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .lg\:-translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .lg\:-translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .lg\:-translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .lg\:-translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .lg\:-translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .lg\:-translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .lg\:-translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .lg\:-translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .lg\:-translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .lg\:-translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .lg\:-translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .lg\:-translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .lg\:-translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .lg\:-translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .lg\:-translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .lg\:-translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .lg\:-translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .lg\:-translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .lg\:-translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .lg\:-translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .lg\:translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .lg\:translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .lg\:translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .lg\:translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .lg\:translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .lg\:translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .lg\:translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .lg\:-translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .lg\:-translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .lg\:-translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .lg\:-translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .lg\:-translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .lg\:-translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .lg\:-translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .lg\:hover\:translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .lg\:hover\:translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .lg\:hover\:translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .lg\:hover\:translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .lg\:hover\:translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .lg\:hover\:translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .lg\:hover\:translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .lg\:hover\:translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .lg\:hover\:translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .lg\:hover\:translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .lg\:hover\:translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .lg\:hover\:translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .lg\:hover\:translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .lg\:hover\:translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .lg\:hover\:translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .lg\:hover\:translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .lg\:hover\:translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .lg\:hover\:translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .lg\:hover\:translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .lg\:hover\:translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .lg\:hover\:translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .lg\:hover\:translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .lg\:hover\:translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .lg\:hover\:translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .lg\:hover\:translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .lg\:hover\:translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .lg\:hover\:translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .lg\:hover\:translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .lg\:hover\:translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .lg\:hover\:translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .lg\:hover\:translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .lg\:hover\:translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .lg\:hover\:translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .lg\:hover\:translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .lg\:hover\:translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .lg\:hover\:-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .lg\:hover\:-translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .lg\:hover\:-translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .lg\:hover\:-translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .lg\:hover\:-translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .lg\:hover\:-translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .lg\:hover\:-translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .lg\:hover\:-translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .lg\:hover\:-translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .lg\:hover\:-translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .lg\:hover\:-translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .lg\:hover\:-translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .lg\:hover\:-translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .lg\:hover\:-translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .lg\:hover\:-translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .lg\:hover\:-translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .lg\:hover\:-translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .lg\:hover\:-translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .lg\:hover\:-translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .lg\:hover\:-translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .lg\:hover\:-translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .lg\:hover\:-translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .lg\:hover\:-translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .lg\:hover\:-translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .lg\:hover\:-translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .lg\:hover\:-translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .lg\:hover\:-translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .lg\:hover\:-translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .lg\:hover\:-translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .lg\:hover\:-translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .lg\:hover\:-translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .lg\:hover\:-translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .lg\:hover\:-translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .lg\:hover\:-translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .lg\:hover\:-translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .lg\:hover\:translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .lg\:hover\:translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .lg\:hover\:translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .lg\:hover\:translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .lg\:hover\:translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .lg\:hover\:translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .lg\:hover\:translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .lg\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .lg\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .lg\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .lg\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .lg\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .lg\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .lg\:hover\:-translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .lg\:hover\:translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .lg\:hover\:translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .lg\:hover\:translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .lg\:hover\:translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .lg\:hover\:translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .lg\:hover\:translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .lg\:hover\:translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .lg\:hover\:translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .lg\:hover\:translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .lg\:hover\:translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .lg\:hover\:translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .lg\:hover\:translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .lg\:hover\:translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .lg\:hover\:translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .lg\:hover\:translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .lg\:hover\:translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .lg\:hover\:translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .lg\:hover\:translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .lg\:hover\:translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .lg\:hover\:translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .lg\:hover\:translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .lg\:hover\:translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .lg\:hover\:translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .lg\:hover\:translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .lg\:hover\:translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .lg\:hover\:translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .lg\:hover\:translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .lg\:hover\:translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .lg\:hover\:translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .lg\:hover\:translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .lg\:hover\:translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .lg\:hover\:translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .lg\:hover\:translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .lg\:hover\:translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .lg\:hover\:translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .lg\:hover\:-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .lg\:hover\:-translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .lg\:hover\:-translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .lg\:hover\:-translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .lg\:hover\:-translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .lg\:hover\:-translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .lg\:hover\:-translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .lg\:hover\:-translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .lg\:hover\:-translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .lg\:hover\:-translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .lg\:hover\:-translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .lg\:hover\:-translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .lg\:hover\:-translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .lg\:hover\:-translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .lg\:hover\:-translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .lg\:hover\:-translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .lg\:hover\:-translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .lg\:hover\:-translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .lg\:hover\:-translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .lg\:hover\:-translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .lg\:hover\:-translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .lg\:hover\:-translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .lg\:hover\:-translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .lg\:hover\:-translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .lg\:hover\:-translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .lg\:hover\:-translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .lg\:hover\:-translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .lg\:hover\:-translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .lg\:hover\:-translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .lg\:hover\:-translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .lg\:hover\:-translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .lg\:hover\:-translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .lg\:hover\:-translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .lg\:hover\:-translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .lg\:hover\:-translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .lg\:hover\:translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .lg\:hover\:translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .lg\:hover\:translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .lg\:hover\:translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .lg\:hover\:translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .lg\:hover\:translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .lg\:hover\:translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .lg\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .lg\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .lg\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .lg\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .lg\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .lg\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .lg\:hover\:-translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .lg\:focus\:translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .lg\:focus\:translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .lg\:focus\:translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .lg\:focus\:translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .lg\:focus\:translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .lg\:focus\:translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .lg\:focus\:translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .lg\:focus\:translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .lg\:focus\:translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .lg\:focus\:translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .lg\:focus\:translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .lg\:focus\:translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .lg\:focus\:translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .lg\:focus\:translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .lg\:focus\:translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .lg\:focus\:translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .lg\:focus\:translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .lg\:focus\:translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .lg\:focus\:translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .lg\:focus\:translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .lg\:focus\:translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .lg\:focus\:translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .lg\:focus\:translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .lg\:focus\:translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .lg\:focus\:translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .lg\:focus\:translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .lg\:focus\:translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .lg\:focus\:translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .lg\:focus\:translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .lg\:focus\:translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .lg\:focus\:translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .lg\:focus\:translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .lg\:focus\:translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .lg\:focus\:translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .lg\:focus\:translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .lg\:focus\:-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .lg\:focus\:-translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .lg\:focus\:-translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .lg\:focus\:-translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .lg\:focus\:-translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .lg\:focus\:-translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .lg\:focus\:-translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .lg\:focus\:-translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .lg\:focus\:-translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .lg\:focus\:-translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .lg\:focus\:-translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .lg\:focus\:-translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .lg\:focus\:-translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .lg\:focus\:-translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .lg\:focus\:-translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .lg\:focus\:-translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .lg\:focus\:-translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .lg\:focus\:-translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .lg\:focus\:-translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .lg\:focus\:-translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .lg\:focus\:-translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .lg\:focus\:-translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .lg\:focus\:-translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .lg\:focus\:-translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .lg\:focus\:-translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .lg\:focus\:-translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .lg\:focus\:-translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .lg\:focus\:-translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .lg\:focus\:-translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .lg\:focus\:-translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .lg\:focus\:-translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .lg\:focus\:-translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .lg\:focus\:-translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .lg\:focus\:-translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .lg\:focus\:-translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .lg\:focus\:translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .lg\:focus\:translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .lg\:focus\:translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .lg\:focus\:translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .lg\:focus\:translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .lg\:focus\:translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .lg\:focus\:translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .lg\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .lg\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .lg\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .lg\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .lg\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .lg\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .lg\:focus\:-translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .lg\:focus\:translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .lg\:focus\:translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .lg\:focus\:translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .lg\:focus\:translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .lg\:focus\:translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .lg\:focus\:translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .lg\:focus\:translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .lg\:focus\:translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .lg\:focus\:translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .lg\:focus\:translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .lg\:focus\:translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .lg\:focus\:translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .lg\:focus\:translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .lg\:focus\:translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .lg\:focus\:translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .lg\:focus\:translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .lg\:focus\:translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .lg\:focus\:translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .lg\:focus\:translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .lg\:focus\:translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .lg\:focus\:translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .lg\:focus\:translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .lg\:focus\:translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .lg\:focus\:translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .lg\:focus\:translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .lg\:focus\:translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .lg\:focus\:translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .lg\:focus\:translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .lg\:focus\:translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .lg\:focus\:translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .lg\:focus\:translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .lg\:focus\:translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .lg\:focus\:translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .lg\:focus\:translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .lg\:focus\:translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .lg\:focus\:-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .lg\:focus\:-translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .lg\:focus\:-translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .lg\:focus\:-translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .lg\:focus\:-translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .lg\:focus\:-translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .lg\:focus\:-translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .lg\:focus\:-translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .lg\:focus\:-translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .lg\:focus\:-translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .lg\:focus\:-translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .lg\:focus\:-translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .lg\:focus\:-translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .lg\:focus\:-translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .lg\:focus\:-translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .lg\:focus\:-translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .lg\:focus\:-translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .lg\:focus\:-translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .lg\:focus\:-translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .lg\:focus\:-translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .lg\:focus\:-translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .lg\:focus\:-translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .lg\:focus\:-translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .lg\:focus\:-translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .lg\:focus\:-translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .lg\:focus\:-translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .lg\:focus\:-translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .lg\:focus\:-translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .lg\:focus\:-translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .lg\:focus\:-translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .lg\:focus\:-translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .lg\:focus\:-translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .lg\:focus\:-translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .lg\:focus\:-translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .lg\:focus\:-translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .lg\:focus\:translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .lg\:focus\:translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .lg\:focus\:translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .lg\:focus\:translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .lg\:focus\:translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .lg\:focus\:translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .lg\:focus\:translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .lg\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .lg\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .lg\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .lg\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .lg\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .lg\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .lg\:focus\:-translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .lg\:rotate-0{
    --tw-rotate: 0deg !important;
  }

  .lg\:rotate-1{
    --tw-rotate: 1deg !important;
  }

  .lg\:rotate-2{
    --tw-rotate: 2deg !important;
  }

  .lg\:rotate-3{
    --tw-rotate: 3deg !important;
  }

  .lg\:rotate-6{
    --tw-rotate: 6deg !important;
  }

  .lg\:rotate-12{
    --tw-rotate: 12deg !important;
  }

  .lg\:rotate-45{
    --tw-rotate: 45deg !important;
  }

  .lg\:rotate-90{
    --tw-rotate: 90deg !important;
  }

  .lg\:rotate-180{
    --tw-rotate: 180deg !important;
  }

  .lg\:-rotate-180{
    --tw-rotate: -180deg !important;
  }

  .lg\:-rotate-90{
    --tw-rotate: -90deg !important;
  }

  .lg\:-rotate-45{
    --tw-rotate: -45deg !important;
  }

  .lg\:-rotate-12{
    --tw-rotate: -12deg !important;
  }

  .lg\:-rotate-6{
    --tw-rotate: -6deg !important;
  }

  .lg\:-rotate-3{
    --tw-rotate: -3deg !important;
  }

  .lg\:-rotate-2{
    --tw-rotate: -2deg !important;
  }

  .lg\:-rotate-1{
    --tw-rotate: -1deg !important;
  }

  .lg\:hover\:rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .lg\:hover\:rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .lg\:hover\:rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .lg\:hover\:rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .lg\:hover\:rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .lg\:hover\:rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .lg\:hover\:rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .lg\:hover\:rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .lg\:hover\:rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .lg\:hover\:-rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .lg\:hover\:-rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .lg\:hover\:-rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .lg\:hover\:-rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .lg\:hover\:-rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .lg\:hover\:-rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .lg\:hover\:-rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .lg\:hover\:-rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .lg\:focus\:rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .lg\:focus\:rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .lg\:focus\:rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .lg\:focus\:rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .lg\:focus\:rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .lg\:focus\:rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .lg\:focus\:rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .lg\:focus\:rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .lg\:focus\:rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .lg\:focus\:-rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .lg\:focus\:-rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .lg\:focus\:-rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .lg\:focus\:-rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .lg\:focus\:-rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .lg\:focus\:-rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .lg\:focus\:-rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .lg\:focus\:-rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .lg\:skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .lg\:skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .lg\:skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .lg\:skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .lg\:skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .lg\:skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .lg\:-skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .lg\:-skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .lg\:-skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .lg\:-skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .lg\:-skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .lg\:skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .lg\:skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .lg\:skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .lg\:skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .lg\:skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .lg\:skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .lg\:-skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .lg\:-skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .lg\:-skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .lg\:-skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .lg\:-skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .lg\:hover\:skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .lg\:hover\:skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .lg\:hover\:skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .lg\:hover\:skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .lg\:hover\:skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .lg\:hover\:skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .lg\:hover\:-skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .lg\:hover\:-skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .lg\:hover\:-skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .lg\:hover\:-skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .lg\:hover\:-skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .lg\:hover\:skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .lg\:hover\:skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .lg\:hover\:skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .lg\:hover\:skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .lg\:hover\:skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .lg\:hover\:skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .lg\:hover\:-skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .lg\:hover\:-skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .lg\:hover\:-skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .lg\:hover\:-skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .lg\:hover\:-skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .lg\:focus\:skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .lg\:focus\:skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .lg\:focus\:skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .lg\:focus\:skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .lg\:focus\:skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .lg\:focus\:skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .lg\:focus\:-skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .lg\:focus\:-skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .lg\:focus\:-skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .lg\:focus\:-skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .lg\:focus\:-skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .lg\:focus\:skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .lg\:focus\:skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .lg\:focus\:skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .lg\:focus\:skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .lg\:focus\:skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .lg\:focus\:skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .lg\:focus\:-skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .lg\:focus\:-skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .lg\:focus\:-skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .lg\:focus\:-skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .lg\:focus\:-skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .lg\:scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .lg\:scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .lg\:scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .lg\:scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .lg\:scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .lg\:scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .lg\:scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .lg\:scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .lg\:scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .lg\:scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .lg\:hover\:scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .lg\:hover\:scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .lg\:hover\:scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .lg\:hover\:scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .lg\:hover\:scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .lg\:hover\:scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .lg\:hover\:scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .lg\:hover\:scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .lg\:hover\:scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .lg\:hover\:scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .lg\:focus\:scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .lg\:focus\:scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .lg\:focus\:scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .lg\:focus\:scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .lg\:focus\:scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .lg\:focus\:scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .lg\:focus\:scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .lg\:focus\:scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .lg\:focus\:scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .lg\:focus\:scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .lg\:scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .lg\:scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .lg\:scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .lg\:scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .lg\:scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .lg\:scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .lg\:scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .lg\:scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .lg\:scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .lg\:scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .lg\:scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .lg\:scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .lg\:scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .lg\:scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .lg\:scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .lg\:scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .lg\:scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .lg\:scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .lg\:scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .lg\:scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .lg\:hover\:scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .lg\:hover\:scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .lg\:hover\:scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .lg\:hover\:scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .lg\:hover\:scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .lg\:hover\:scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .lg\:hover\:scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .lg\:hover\:scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .lg\:hover\:scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .lg\:hover\:scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .lg\:hover\:scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .lg\:hover\:scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .lg\:hover\:scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .lg\:hover\:scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .lg\:hover\:scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .lg\:hover\:scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .lg\:hover\:scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .lg\:hover\:scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .lg\:hover\:scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .lg\:hover\:scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .lg\:focus\:scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .lg\:focus\:scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .lg\:focus\:scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .lg\:focus\:scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .lg\:focus\:scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .lg\:focus\:scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .lg\:focus\:scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .lg\:focus\:scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .lg\:focus\:scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .lg\:focus\:scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .lg\:focus\:scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .lg\:focus\:scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .lg\:focus\:scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .lg\:focus\:scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .lg\:focus\:scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .lg\:focus\:scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .lg\:focus\:scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .lg\:focus\:scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .lg\:focus\:scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .lg\:focus\:scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .lg\:animate-none{
    animation: none !important;
  }

  .lg\:animate-spin{
    animation: spin 1s linear infinite !important;
  }

  .lg\:animate-ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .lg\:animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .lg\:animate-bounce{
    animation: bounce 1s infinite !important;
  }

  .lg\:cursor-auto{
    cursor: auto !important;
  }

  .lg\:cursor-default{
    cursor: default !important;
  }

  .lg\:cursor-pointer{
    cursor: pointer !important;
  }

  .lg\:cursor-wait{
    cursor: wait !important;
  }

  .lg\:cursor-text{
    cursor: text !important;
  }

  .lg\:cursor-move{
    cursor: move !important;
  }

  .lg\:cursor-help{
    cursor: help !important;
  }

  .lg\:cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .lg\:select-none{
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .lg\:select-text{
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .lg\:select-all{
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .lg\:select-auto{
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .lg\:resize-none{
    resize: none !important;
  }

  .lg\:resize-y{
    resize: vertical !important;
  }

  .lg\:resize-x{
    resize: horizontal !important;
  }

  .lg\:resize{
    resize: both !important;
  }

  .lg\:list-inside{
    list-style-position: inside !important;
  }

  .lg\:list-outside{
    list-style-position: outside !important;
  }

  .lg\:list-none{
    list-style-type: none !important;
  }

  .lg\:list-disc{
    list-style-type: disc !important;
  }

  .lg\:list-decimal{
    list-style-type: decimal !important;
  }

  .lg\:appearance-none{
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .lg\:auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .lg\:auto-cols-min{
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .lg\:auto-cols-max{
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .lg\:auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .lg\:grid-flow-row{
    grid-auto-flow: row !important;
  }

  .lg\:grid-flow-col{
    grid-auto-flow: column !important;
  }

  .lg\:grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .lg\:grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .lg\:auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .lg\:auto-rows-min{
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .lg\:auto-rows-max{
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .lg\:auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .lg\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-none{
    grid-template-columns: none !important;
  }

  .lg\:grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-none{
    grid-template-rows: none !important;
  }

  .lg\:flex-row{
    flex-direction: row !important;
  }

  .lg\:flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .lg\:flex-col{
    flex-direction: column !important;
  }

  .lg\:flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .lg\:flex-wrap{
    flex-wrap: wrap !important;
  }

  .lg\:flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .lg\:flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .lg\:place-content-center{
    place-content: center !important;
  }

  .lg\:place-content-start{
    place-content: start !important;
  }

  .lg\:place-content-end{
    place-content: end !important;
  }

  .lg\:place-content-between{
    place-content: space-between !important;
  }

  .lg\:place-content-around{
    place-content: space-around !important;
  }

  .lg\:place-content-evenly{
    place-content: space-evenly !important;
  }

  .lg\:place-content-stretch{
    place-content: stretch !important;
  }

  .lg\:place-items-start{
    place-items: start !important;
  }

  .lg\:place-items-end{
    place-items: end !important;
  }

  .lg\:place-items-center{
    place-items: center !important;
  }

  .lg\:place-items-stretch{
    place-items: stretch !important;
  }

  .lg\:content-center{
    align-content: center !important;
  }

  .lg\:content-start{
    align-content: flex-start !important;
  }

  .lg\:content-end{
    align-content: flex-end !important;
  }

  .lg\:content-between{
    align-content: space-between !important;
  }

  .lg\:content-around{
    align-content: space-around !important;
  }

  .lg\:content-evenly{
    align-content: space-evenly !important;
  }

  .lg\:items-start{
    align-items: flex-start !important;
  }

  .lg\:items-end{
    align-items: flex-end !important;
  }

  .lg\:items-center{
    align-items: center !important;
  }

  .lg\:items-baseline{
    align-items: baseline !important;
  }

  .lg\:items-stretch{
    align-items: stretch !important;
  }

  .lg\:justify-start{
    justify-content: flex-start !important;
  }

  .lg\:justify-end{
    justify-content: flex-end !important;
  }

  .lg\:justify-center{
    justify-content: center !important;
  }

  .lg\:justify-between{
    justify-content: space-between !important;
  }

  .lg\:justify-around{
    justify-content: space-around !important;
  }

  .lg\:justify-evenly{
    justify-content: space-evenly !important;
  }

  .lg\:justify-items-start{
    justify-items: start !important;
  }

  .lg\:justify-items-end{
    justify-items: end !important;
  }

  .lg\:justify-items-center{
    justify-items: center !important;
  }

  .lg\:justify-items-stretch{
    justify-items: stretch !important;
  }

  .lg\:gap-0{
    gap: 0px !important;
  }

  .lg\:gap-1{
    gap: 0.25rem !important;
  }

  .lg\:gap-2{
    gap: 0.5rem !important;
  }

  .lg\:gap-3{
    gap: 0.75rem !important;
  }

  .lg\:gap-4{
    gap: 1rem !important;
  }

  .lg\:gap-5{
    gap: 1.25rem !important;
  }

  .lg\:gap-6{
    gap: 1.5rem !important;
  }

  .lg\:gap-7{
    gap: 1.75rem !important;
  }

  .lg\:gap-8{
    gap: 2rem !important;
  }

  .lg\:gap-9{
    gap: 2.25rem !important;
  }

  .lg\:gap-10{
    gap: 2.5rem !important;
  }

  .lg\:gap-11{
    gap: 2.75rem !important;
  }

  .lg\:gap-12{
    gap: 3rem !important;
  }

  .lg\:gap-14{
    gap: 3.5rem !important;
  }

  .lg\:gap-16{
    gap: 4rem !important;
  }

  .lg\:gap-20{
    gap: 5rem !important;
  }

  .lg\:gap-24{
    gap: 6rem !important;
  }

  .lg\:gap-28{
    gap: 7rem !important;
  }

  .lg\:gap-32{
    gap: 8rem !important;
  }

  .lg\:gap-36{
    gap: 9rem !important;
  }

  .lg\:gap-40{
    gap: 10rem !important;
  }

  .lg\:gap-44{
    gap: 11rem !important;
  }

  .lg\:gap-48{
    gap: 12rem !important;
  }

  .lg\:gap-52{
    gap: 13rem !important;
  }

  .lg\:gap-56{
    gap: 14rem !important;
  }

  .lg\:gap-60{
    gap: 15rem !important;
  }

  .lg\:gap-64{
    gap: 16rem !important;
  }

  .lg\:gap-72{
    gap: 18rem !important;
  }

  .lg\:gap-80{
    gap: 20rem !important;
  }

  .lg\:gap-96{
    gap: 24rem !important;
  }

  .lg\:gap-px{
    gap: 1px !important;
  }

  .lg\:gap-0\.5{
    gap: 0.125rem !important;
  }

  .lg\:gap-1\.5{
    gap: 0.375rem !important;
  }

  .lg\:gap-2\.5{
    gap: 0.625rem !important;
  }

  .lg\:gap-3\.5{
    gap: 0.875rem !important;
  }

  .lg\:gap-x-0{
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .lg\:gap-x-1{
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .lg\:gap-x-2{
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .lg\:gap-x-3{
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .lg\:gap-x-4{
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .lg\:gap-x-5{
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .lg\:gap-x-6{
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .lg\:gap-x-7{
    -webkit-column-gap: 1.75rem !important;
            column-gap: 1.75rem !important;
  }

  .lg\:gap-x-8{
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .lg\:gap-x-9{
    -webkit-column-gap: 2.25rem !important;
            column-gap: 2.25rem !important;
  }

  .lg\:gap-x-10{
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .lg\:gap-x-11{
    -webkit-column-gap: 2.75rem !important;
            column-gap: 2.75rem !important;
  }

  .lg\:gap-x-12{
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .lg\:gap-x-14{
    -webkit-column-gap: 3.5rem !important;
            column-gap: 3.5rem !important;
  }

  .lg\:gap-x-16{
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .lg\:gap-x-20{
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .lg\:gap-x-24{
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .lg\:gap-x-28{
    -webkit-column-gap: 7rem !important;
            column-gap: 7rem !important;
  }

  .lg\:gap-x-32{
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .lg\:gap-x-36{
    -webkit-column-gap: 9rem !important;
            column-gap: 9rem !important;
  }

  .lg\:gap-x-40{
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .lg\:gap-x-44{
    -webkit-column-gap: 11rem !important;
            column-gap: 11rem !important;
  }

  .lg\:gap-x-48{
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .lg\:gap-x-52{
    -webkit-column-gap: 13rem !important;
            column-gap: 13rem !important;
  }

  .lg\:gap-x-56{
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .lg\:gap-x-60{
    -webkit-column-gap: 15rem !important;
            column-gap: 15rem !important;
  }

  .lg\:gap-x-64{
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .lg\:gap-x-72{
    -webkit-column-gap: 18rem !important;
            column-gap: 18rem !important;
  }

  .lg\:gap-x-80{
    -webkit-column-gap: 20rem !important;
            column-gap: 20rem !important;
  }

  .lg\:gap-x-96{
    -webkit-column-gap: 24rem !important;
            column-gap: 24rem !important;
  }

  .lg\:gap-x-px{
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .lg\:gap-x-0\.5{
    -webkit-column-gap: 0.125rem !important;
            column-gap: 0.125rem !important;
  }

  .lg\:gap-x-1\.5{
    -webkit-column-gap: 0.375rem !important;
            column-gap: 0.375rem !important;
  }

  .lg\:gap-x-2\.5{
    -webkit-column-gap: 0.625rem !important;
            column-gap: 0.625rem !important;
  }

  .lg\:gap-x-3\.5{
    -webkit-column-gap: 0.875rem !important;
            column-gap: 0.875rem !important;
  }

  .lg\:gap-y-0{
    row-gap: 0px !important;
  }

  .lg\:gap-y-1{
    row-gap: 0.25rem !important;
  }

  .lg\:gap-y-2{
    row-gap: 0.5rem !important;
  }

  .lg\:gap-y-3{
    row-gap: 0.75rem !important;
  }

  .lg\:gap-y-4{
    row-gap: 1rem !important;
  }

  .lg\:gap-y-5{
    row-gap: 1.25rem !important;
  }

  .lg\:gap-y-6{
    row-gap: 1.5rem !important;
  }

  .lg\:gap-y-7{
    row-gap: 1.75rem !important;
  }

  .lg\:gap-y-8{
    row-gap: 2rem !important;
  }

  .lg\:gap-y-9{
    row-gap: 2.25rem !important;
  }

  .lg\:gap-y-10{
    row-gap: 2.5rem !important;
  }

  .lg\:gap-y-11{
    row-gap: 2.75rem !important;
  }

  .lg\:gap-y-12{
    row-gap: 3rem !important;
  }

  .lg\:gap-y-14{
    row-gap: 3.5rem !important;
  }

  .lg\:gap-y-16{
    row-gap: 4rem !important;
  }

  .lg\:gap-y-20{
    row-gap: 5rem !important;
  }

  .lg\:gap-y-24{
    row-gap: 6rem !important;
  }

  .lg\:gap-y-28{
    row-gap: 7rem !important;
  }

  .lg\:gap-y-32{
    row-gap: 8rem !important;
  }

  .lg\:gap-y-36{
    row-gap: 9rem !important;
  }

  .lg\:gap-y-40{
    row-gap: 10rem !important;
  }

  .lg\:gap-y-44{
    row-gap: 11rem !important;
  }

  .lg\:gap-y-48{
    row-gap: 12rem !important;
  }

  .lg\:gap-y-52{
    row-gap: 13rem !important;
  }

  .lg\:gap-y-56{
    row-gap: 14rem !important;
  }

  .lg\:gap-y-60{
    row-gap: 15rem !important;
  }

  .lg\:gap-y-64{
    row-gap: 16rem !important;
  }

  .lg\:gap-y-72{
    row-gap: 18rem !important;
  }

  .lg\:gap-y-80{
    row-gap: 20rem !important;
  }

  .lg\:gap-y-96{
    row-gap: 24rem !important;
  }

  .lg\:gap-y-px{
    row-gap: 1px !important;
  }

  .lg\:gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .lg\:gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .lg\:gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .lg\:gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .lg\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .lg\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .lg\:divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .lg\:divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-qipGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-portalGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-divide-opacity)) !important;
  }

  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .lg\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .lg\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .lg\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .lg\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .lg\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .lg\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .lg\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .lg\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .lg\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .lg\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .lg\:place-self-auto{
    place-self: auto !important;
  }

  .lg\:place-self-start{
    place-self: start !important;
  }

  .lg\:place-self-end{
    place-self: end !important;
  }

  .lg\:place-self-center{
    place-self: center !important;
  }

  .lg\:place-self-stretch{
    place-self: stretch !important;
  }

  .lg\:self-auto{
    align-self: auto !important;
  }

  .lg\:self-start{
    align-self: flex-start !important;
  }

  .lg\:self-end{
    align-self: flex-end !important;
  }

  .lg\:self-center{
    align-self: center !important;
  }

  .lg\:self-stretch{
    align-self: stretch !important;
  }

  .lg\:self-baseline{
    align-self: baseline !important;
  }

  .lg\:justify-self-auto{
    justify-self: auto !important;
  }

  .lg\:justify-self-start{
    justify-self: start !important;
  }

  .lg\:justify-self-end{
    justify-self: end !important;
  }

  .lg\:justify-self-center{
    justify-self: center !important;
  }

  .lg\:justify-self-stretch{
    justify-self: stretch !important;
  }

  .lg\:overflow-auto{
    overflow: auto !important;
  }

  .lg\:overflow-hidden{
    overflow: hidden !important;
  }

  .lg\:overflow-visible{
    overflow: visible !important;
  }

  .lg\:overflow-scroll{
    overflow: scroll !important;
  }

  .lg\:overflow-x-auto{
    overflow-x: auto !important;
  }

  .lg\:overflow-y-auto{
    overflow-y: auto !important;
  }

  .lg\:overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .lg\:overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .lg\:overflow-x-visible{
    overflow-x: visible !important;
  }

  .lg\:overflow-y-visible{
    overflow-y: visible !important;
  }

  .lg\:overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .lg\:overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .lg\:overscroll-auto{
    -ms-scroll-chaining: chained !important;
        overscroll-behavior: auto !important;
  }

  .lg\:overscroll-contain{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: contain !important;
  }

  .lg\:overscroll-none{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: none !important;
  }

  .lg\:overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .lg\:overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .lg\:overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .lg\:overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .lg\:overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .lg\:overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .lg\:truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lg\:overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .lg\:overflow-clip{
    text-overflow: clip !important;
  }

  .lg\:whitespace-normal{
    white-space: normal !important;
  }

  .lg\:whitespace-nowrap{
    white-space: nowrap !important;
  }

  .lg\:whitespace-pre{
    white-space: pre !important;
  }

  .lg\:whitespace-pre-line{
    white-space: pre-line !important;
  }

  .lg\:whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .lg\:break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lg\:break-words{
    overflow-wrap: break-word !important;
  }

  .lg\:break-all{
    word-break: break-all !important;
  }

  .lg\:rounded-none{
    border-radius: 0px !important;
  }

  .lg\:rounded-sm{
    border-radius: 0.125rem !important;
  }

  .lg\:rounded{
    border-radius: 0.25rem !important;
  }

  .lg\:rounded-md{
    border-radius: 0.375rem !important;
  }

  .lg\:rounded-lg{
    border-radius: 0.5rem !important;
  }

  .lg\:rounded-xl{
    border-radius: 0.75rem !important;
  }

  .lg\:rounded-2xl{
    border-radius: 1rem !important;
  }

  .lg\:rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .lg\:rounded-full{
    border-radius: 9999px !important;
  }

  .lg\:rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .lg\:rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .lg\:rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .lg\:rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lg\:rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .lg\:rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .lg\:rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .lg\:rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .lg\:rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .lg\:rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .lg\:rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .lg\:rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .lg\:rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .lg\:rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .lg\:rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .lg\:rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .lg\:rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .lg\:rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .lg\:rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .lg\:rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .lg\:rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .lg\:rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .lg\:rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .lg\:rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .lg\:rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .lg\:rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:border-0{
    border-width: 0px !important;
  }

  .lg\:border-2{
    border-width: 2px !important;
  }

  .lg\:border-4{
    border-width: 4px !important;
  }

  .lg\:border-8{
    border-width: 8px !important;
  }

  .lg\:border{
    border-width: 1px !important;
  }

  .lg\:border-t-0{
    border-top-width: 0px !important;
  }

  .lg\:border-t-2{
    border-top-width: 2px !important;
  }

  .lg\:border-t-4{
    border-top-width: 4px !important;
  }

  .lg\:border-t-8{
    border-top-width: 8px !important;
  }

  .lg\:border-t{
    border-top-width: 1px !important;
  }

  .lg\:border-r-0{
    border-right-width: 0px !important;
  }

  .lg\:border-r-2{
    border-right-width: 2px !important;
  }

  .lg\:border-r-4{
    border-right-width: 4px !important;
  }

  .lg\:border-r-8{
    border-right-width: 8px !important;
  }

  .lg\:border-r{
    border-right-width: 1px !important;
  }

  .lg\:border-b-0{
    border-bottom-width: 0px !important;
  }

  .lg\:border-b-2{
    border-bottom-width: 2px !important;
  }

  .lg\:border-b-4{
    border-bottom-width: 4px !important;
  }

  .lg\:border-b-8{
    border-bottom-width: 8px !important;
  }

  .lg\:border-b{
    border-bottom-width: 1px !important;
  }

  .lg\:border-l-0{
    border-left-width: 0px !important;
  }

  .lg\:border-l-2{
    border-left-width: 2px !important;
  }

  .lg\:border-l-4{
    border-left-width: 4px !important;
  }

  .lg\:border-l-8{
    border-left-width: 8px !important;
  }

  .lg\:border-l{
    border-left-width: 1px !important;
  }

  .lg\:border-solid{
    border-style: solid !important;
  }

  .lg\:border-dashed{
    border-style: dashed !important;
  }

  .lg\:border-dotted{
    border-style: dotted !important;
  }

  .lg\:border-double{
    border-style: double !important;
  }

  .lg\:border-none{
    border-style: none !important;
  }

  .lg\:border-transparent{
    border-color: transparent !important;
  }

  .lg\:border-current{
    border-color: currentColor !important;
  }

  .lg\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-transparent{
    border-color: transparent !important;
  }

  .group:hover .lg\:group-hover\:border-current{
    border-color: currentColor !important;
  }

  .group:hover .lg\:group-hover\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-transparent:focus-within{
    border-color: transparent !important;
  }

  .lg\:focus-within\:border-current:focus-within{
    border-color: currentColor !important;
  }

  .lg\:focus-within\:border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-qipGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:focus-within\:border-portalGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-transparent:hover{
    border-color: transparent !important;
  }

  .lg\:hover\:border-current:hover{
    border-color: currentColor !important;
  }

  .lg\:hover\:border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-qipGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:hover\:border-portalGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-transparent:focus{
    border-color: transparent !important;
  }

  .lg\:focus\:border-current:focus{
    border-color: currentColor !important;
  }

  .lg\:focus\:border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-qipGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .lg\:focus\:border-portalGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .lg\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .lg\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .group:hover .lg\:group-hover\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .lg\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .lg\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .lg\:hover\:border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .lg\:hover\:border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:hover\:border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:hover\:border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:hover\:border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:hover\:border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:hover\:border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:hover\:border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:hover\:border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:hover\:border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:hover\:border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:hover\:border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:hover\:border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:hover\:border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:hover\:border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .lg\:focus\:border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .lg\:focus\:border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .lg\:focus\:border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .lg\:focus\:border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .lg\:focus\:border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .lg\:focus\:border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .lg\:focus\:border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .lg\:focus\:border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .lg\:focus\:border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .lg\:focus\:border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .lg\:focus\:border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .lg\:focus\:border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .lg\:focus\:border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .lg\:focus\:border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .lg\:focus\:border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .lg\:bg-transparent{
    background-color: transparent !important;
  }

  .lg\:bg-current{
    background-color: currentColor !important;
  }

  .lg\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-transparent{
    background-color: transparent !important;
  }

  .group:hover .lg\:group-hover\:bg-current{
    background-color: currentColor !important;
  }

  .group:hover .lg\:group-hover\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .lg\:focus-within\:bg-current:focus-within{
    background-color: currentColor !important;
  }

  .lg\:focus-within\:bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-qipGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus-within\:bg-portalGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-transparent:hover{
    background-color: transparent !important;
  }

  .lg\:hover\:bg-current:hover{
    background-color: currentColor !important;
  }

  .lg\:hover\:bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-qipGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:hover\:bg-portalGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-transparent:focus{
    background-color: transparent !important;
  }

  .lg\:focus\:bg-current:focus{
    background-color: currentColor !important;
  }

  .lg\:focus\:bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-qipGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .lg\:focus\:bg-portalGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .lg\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .group:hover .lg\:group-hover\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .lg\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .lg\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .lg\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .lg\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .lg\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .lg\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .lg\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .lg\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .lg\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .lg\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .lg\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .lg\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .lg\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .lg\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .lg\:bg-none{
    background-image: none !important;
  }

  .lg\:bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .lg\:bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .lg\:from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:from-qipGreen{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .lg\:from-portalGreen{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .lg\:hover\:from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:hover\:from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:hover\:from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:hover\:from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:hover\:from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:hover\:from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:hover\:from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:hover\:from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:hover\:from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:hover\:from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:hover\:from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:hover\:from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:hover\:from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:hover\:from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:hover\:from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:hover\:from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:hover\:from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:hover\:from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:hover\:from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:hover\:from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:hover\:from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:hover\:from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:hover\:from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:hover\:from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:hover\:from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:hover\:from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:hover\:from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:hover\:from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:hover\:from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:hover\:from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:hover\:from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:hover\:from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:hover\:from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:hover\:from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:hover\:from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:hover\:from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:hover\:from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:hover\:from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:hover\:from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:hover\:from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:hover\:from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:hover\:from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:hover\:from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:hover\:from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:hover\:from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:hover\:from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:hover\:from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:hover\:from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:hover\:from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:hover\:from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:hover\:from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:hover\:from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:hover\:from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:hover\:from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:hover\:from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:hover\:from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:hover\:from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:hover\:from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:hover\:from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:hover\:from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:hover\:from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:hover\:from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:hover\:from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:hover\:from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:hover\:from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:hover\:from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:hover\:from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:hover\:from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:hover\:from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:hover\:from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:hover\:from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:hover\:from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:hover\:from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:hover\:from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:hover\:from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:hover\:from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:hover\:from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:hover\:from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:hover\:from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:hover\:from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:hover\:from-qipGreen:hover{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .lg\:hover\:from-portalGreen:hover{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .lg\:focus\:from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:focus\:from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:focus\:from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:focus\:from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:focus\:from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:focus\:from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:focus\:from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:focus\:from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:focus\:from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:focus\:from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:focus\:from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:focus\:from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:focus\:from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:focus\:from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:focus\:from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:focus\:from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:focus\:from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:focus\:from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:focus\:from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:focus\:from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:focus\:from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:focus\:from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:focus\:from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:focus\:from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:focus\:from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:focus\:from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:focus\:from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:focus\:from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:focus\:from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:focus\:from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:focus\:from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:focus\:from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:focus\:from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:focus\:from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:focus\:from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:focus\:from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:focus\:from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:focus\:from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:focus\:from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:focus\:from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:focus\:from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:focus\:from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:focus\:from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:focus\:from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:focus\:from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:focus\:from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:focus\:from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:focus\:from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:focus\:from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:focus\:from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:focus\:from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:focus\:from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:focus\:from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:focus\:from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:focus\:from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:focus\:from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:focus\:from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:focus\:from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:focus\:from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:focus\:from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:focus\:from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:focus\:from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:focus\:from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:focus\:from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:focus\:from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:focus\:from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:focus\:from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:focus\:from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:focus\:from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:focus\:from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:focus\:from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:focus\:from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:focus\:from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:focus\:from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:focus\:from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:focus\:from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:focus\:from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:focus\:from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:focus\:from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:focus\:from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:focus\:from-qipGreen:focus{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .lg\:focus\:from-portalGreen:focus{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .lg\:via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:via-qipGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .lg\:via-portalGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .lg\:hover\:via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:hover\:via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:hover\:via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:hover\:via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:hover\:via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:hover\:via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:hover\:via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:hover\:via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:hover\:via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:hover\:via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:hover\:via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:hover\:via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:hover\:via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:hover\:via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:hover\:via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:hover\:via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:hover\:via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:hover\:via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:hover\:via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:hover\:via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:hover\:via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:hover\:via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:hover\:via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:hover\:via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:hover\:via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:hover\:via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:hover\:via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:hover\:via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:hover\:via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:hover\:via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:hover\:via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:hover\:via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:hover\:via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:hover\:via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:hover\:via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:hover\:via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:hover\:via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:hover\:via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:hover\:via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:hover\:via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:hover\:via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:hover\:via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:hover\:via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:hover\:via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:hover\:via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:hover\:via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:hover\:via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:hover\:via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:hover\:via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:hover\:via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:hover\:via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:hover\:via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:hover\:via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:hover\:via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:hover\:via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:hover\:via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:hover\:via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:hover\:via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:hover\:via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:hover\:via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:hover\:via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:hover\:via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:hover\:via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:hover\:via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:hover\:via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:hover\:via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:hover\:via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:hover\:via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:hover\:via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:hover\:via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:hover\:via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:hover\:via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:hover\:via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:hover\:via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:hover\:via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:hover\:via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:hover\:via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:hover\:via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:hover\:via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:hover\:via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:hover\:via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:hover\:via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:hover\:via-qipGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .lg\:hover\:via-portalGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .lg\:focus\:via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .lg\:focus\:via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .lg\:focus\:via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .lg\:focus\:via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .lg\:focus\:via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .lg\:focus\:via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .lg\:focus\:via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .lg\:focus\:via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .lg\:focus\:via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .lg\:focus\:via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .lg\:focus\:via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .lg\:focus\:via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .lg\:focus\:via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .lg\:focus\:via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .lg\:focus\:via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .lg\:focus\:via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .lg\:focus\:via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .lg\:focus\:via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .lg\:focus\:via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .lg\:focus\:via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .lg\:focus\:via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .lg\:focus\:via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .lg\:focus\:via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .lg\:focus\:via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .lg\:focus\:via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .lg\:focus\:via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .lg\:focus\:via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .lg\:focus\:via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .lg\:focus\:via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .lg\:focus\:via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .lg\:focus\:via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .lg\:focus\:via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .lg\:focus\:via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .lg\:focus\:via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .lg\:focus\:via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .lg\:focus\:via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .lg\:focus\:via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .lg\:focus\:via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .lg\:focus\:via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .lg\:focus\:via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .lg\:focus\:via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .lg\:focus\:via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .lg\:focus\:via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .lg\:focus\:via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .lg\:focus\:via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .lg\:focus\:via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .lg\:focus\:via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .lg\:focus\:via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .lg\:focus\:via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .lg\:focus\:via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .lg\:focus\:via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .lg\:focus\:via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .lg\:focus\:via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .lg\:focus\:via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .lg\:focus\:via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .lg\:focus\:via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .lg\:focus\:via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .lg\:focus\:via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .lg\:focus\:via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .lg\:focus\:via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .lg\:focus\:via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .lg\:focus\:via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .lg\:focus\:via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .lg\:focus\:via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .lg\:focus\:via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .lg\:focus\:via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .lg\:focus\:via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .lg\:focus\:via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .lg\:focus\:via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .lg\:focus\:via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .lg\:focus\:via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .lg\:focus\:via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .lg\:focus\:via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .lg\:focus\:via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .lg\:focus\:via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .lg\:focus\:via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .lg\:focus\:via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .lg\:focus\:via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .lg\:focus\:via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .lg\:focus\:via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .lg\:focus\:via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .lg\:focus\:via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .lg\:focus\:via-qipGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .lg\:focus\:via-portalGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .lg\:to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .lg\:to-current{
    --tw-gradient-to: currentColor !important;
  }

  .lg\:to-black{
    --tw-gradient-to: #000 !important;
  }

  .lg\:to-white{
    --tw-gradient-to: #fff !important;
  }

  .lg\:to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .lg\:to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .lg\:to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .lg\:to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .lg\:to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .lg\:to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .lg\:to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .lg\:to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .lg\:to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .lg\:to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .lg\:to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .lg\:to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .lg\:to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .lg\:to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .lg\:to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .lg\:to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .lg\:to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .lg\:to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .lg\:to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .lg\:to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .lg\:to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .lg\:to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .lg\:to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .lg\:to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .lg\:to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .lg\:to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .lg\:to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .lg\:to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .lg\:to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .lg\:to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .lg\:to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .lg\:to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .lg\:to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .lg\:to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .lg\:to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .lg\:to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .lg\:to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .lg\:to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .lg\:to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .lg\:to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .lg\:to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .lg\:to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .lg\:to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .lg\:to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .lg\:to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .lg\:to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .lg\:to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .lg\:to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .lg\:to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .lg\:to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .lg\:to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .lg\:to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .lg\:to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .lg\:to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .lg\:to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .lg\:to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .lg\:to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .lg\:to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .lg\:to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .lg\:to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .lg\:to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .lg\:to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .lg\:to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .lg\:to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .lg\:to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .lg\:to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .lg\:to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .lg\:to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .lg\:to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .lg\:to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .lg\:to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .lg\:to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .lg\:to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .lg\:to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .lg\:to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .lg\:to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .lg\:to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .lg\:to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .lg\:to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .lg\:to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .lg\:to-qipGreen{
    --tw-gradient-to: #8bc334 !important;
  }

  .lg\:to-portalGreen{
    --tw-gradient-to: #8ac04a !important;
  }

  .lg\:hover\:to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .lg\:hover\:to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .lg\:hover\:to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .lg\:hover\:to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .lg\:hover\:to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .lg\:hover\:to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .lg\:hover\:to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .lg\:hover\:to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .lg\:hover\:to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .lg\:hover\:to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .lg\:hover\:to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .lg\:hover\:to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .lg\:hover\:to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .lg\:hover\:to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .lg\:hover\:to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .lg\:hover\:to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .lg\:hover\:to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .lg\:hover\:to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .lg\:hover\:to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .lg\:hover\:to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .lg\:hover\:to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .lg\:hover\:to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .lg\:hover\:to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .lg\:hover\:to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .lg\:hover\:to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .lg\:hover\:to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .lg\:hover\:to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .lg\:hover\:to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .lg\:hover\:to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .lg\:hover\:to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .lg\:hover\:to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .lg\:hover\:to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .lg\:hover\:to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .lg\:hover\:to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .lg\:hover\:to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .lg\:hover\:to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .lg\:hover\:to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .lg\:hover\:to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .lg\:hover\:to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .lg\:hover\:to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .lg\:hover\:to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .lg\:hover\:to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .lg\:hover\:to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .lg\:hover\:to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .lg\:hover\:to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .lg\:hover\:to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .lg\:hover\:to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .lg\:hover\:to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .lg\:hover\:to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .lg\:hover\:to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .lg\:hover\:to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .lg\:hover\:to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .lg\:hover\:to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .lg\:hover\:to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .lg\:hover\:to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .lg\:hover\:to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .lg\:hover\:to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .lg\:hover\:to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .lg\:hover\:to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .lg\:hover\:to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .lg\:hover\:to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .lg\:hover\:to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .lg\:hover\:to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .lg\:hover\:to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .lg\:hover\:to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .lg\:hover\:to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .lg\:hover\:to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .lg\:hover\:to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .lg\:hover\:to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .lg\:hover\:to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .lg\:hover\:to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .lg\:hover\:to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .lg\:hover\:to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .lg\:hover\:to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .lg\:hover\:to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .lg\:hover\:to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .lg\:hover\:to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .lg\:hover\:to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .lg\:hover\:to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .lg\:hover\:to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .lg\:hover\:to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .lg\:hover\:to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .lg\:hover\:to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .lg\:hover\:to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .lg\:hover\:to-qipGreen:hover{
    --tw-gradient-to: #8bc334 !important;
  }

  .lg\:hover\:to-portalGreen:hover{
    --tw-gradient-to: #8ac04a !important;
  }

  .lg\:focus\:to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .lg\:focus\:to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .lg\:focus\:to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .lg\:focus\:to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .lg\:focus\:to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .lg\:focus\:to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .lg\:focus\:to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .lg\:focus\:to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .lg\:focus\:to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .lg\:focus\:to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .lg\:focus\:to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .lg\:focus\:to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .lg\:focus\:to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .lg\:focus\:to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .lg\:focus\:to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .lg\:focus\:to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .lg\:focus\:to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .lg\:focus\:to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .lg\:focus\:to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .lg\:focus\:to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .lg\:focus\:to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .lg\:focus\:to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .lg\:focus\:to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .lg\:focus\:to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .lg\:focus\:to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .lg\:focus\:to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .lg\:focus\:to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .lg\:focus\:to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .lg\:focus\:to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .lg\:focus\:to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .lg\:focus\:to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .lg\:focus\:to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .lg\:focus\:to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .lg\:focus\:to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .lg\:focus\:to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .lg\:focus\:to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .lg\:focus\:to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .lg\:focus\:to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .lg\:focus\:to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .lg\:focus\:to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .lg\:focus\:to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .lg\:focus\:to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .lg\:focus\:to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .lg\:focus\:to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .lg\:focus\:to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .lg\:focus\:to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .lg\:focus\:to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .lg\:focus\:to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .lg\:focus\:to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .lg\:focus\:to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .lg\:focus\:to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .lg\:focus\:to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .lg\:focus\:to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .lg\:focus\:to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .lg\:focus\:to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .lg\:focus\:to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .lg\:focus\:to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .lg\:focus\:to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .lg\:focus\:to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .lg\:focus\:to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .lg\:focus\:to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .lg\:focus\:to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .lg\:focus\:to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .lg\:focus\:to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .lg\:focus\:to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .lg\:focus\:to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .lg\:focus\:to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .lg\:focus\:to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .lg\:focus\:to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .lg\:focus\:to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .lg\:focus\:to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .lg\:focus\:to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .lg\:focus\:to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .lg\:focus\:to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .lg\:focus\:to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .lg\:focus\:to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .lg\:focus\:to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .lg\:focus\:to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .lg\:focus\:to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .lg\:focus\:to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .lg\:focus\:to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .lg\:focus\:to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .lg\:focus\:to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .lg\:focus\:to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .lg\:focus\:to-qipGreen:focus{
    --tw-gradient-to: #8bc334 !important;
  }

  .lg\:focus\:to-portalGreen:focus{
    --tw-gradient-to: #8ac04a !important;
  }

  .lg\:decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .lg\:decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .lg\:bg-auto{
    background-size: auto !important;
  }

  .lg\:bg-cover{
    background-size: cover !important;
  }

  .lg\:bg-contain{
    background-size: contain !important;
  }

  .lg\:bg-fixed{
    background-attachment: fixed !important;
  }

  .lg\:bg-local{
    background-attachment: local !important;
  }

  .lg\:bg-scroll{
    background-attachment: scroll !important;
  }

  .lg\:bg-clip-border{
    background-clip: border-box !important;
  }

  .lg\:bg-clip-padding{
    background-clip: padding-box !important;
  }

  .lg\:bg-clip-content{
    background-clip: content-box !important;
  }

  .lg\:bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .lg\:bg-bottom{
    background-position: bottom !important;
  }

  .lg\:bg-center{
    background-position: center !important;
  }

  .lg\:bg-left{
    background-position: left !important;
  }

  .lg\:bg-left-bottom{
    background-position: left bottom !important;
  }

  .lg\:bg-left-top{
    background-position: left top !important;
  }

  .lg\:bg-right{
    background-position: right !important;
  }

  .lg\:bg-right-bottom{
    background-position: right bottom !important;
  }

  .lg\:bg-right-top{
    background-position: right top !important;
  }

  .lg\:bg-top{
    background-position: top !important;
  }

  .lg\:bg-repeat{
    background-repeat: repeat !important;
  }

  .lg\:bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .lg\:bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .lg\:bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .lg\:bg-repeat-round{
    background-repeat: round !important;
  }

  .lg\:bg-repeat-space{
    background-repeat: space !important;
  }

  .lg\:bg-origin-border{
    background-origin: border-box !important;
  }

  .lg\:bg-origin-padding{
    background-origin: padding-box !important;
  }

  .lg\:bg-origin-content{
    background-origin: content-box !important;
  }

  .lg\:fill-current{
    fill: currentColor !important;
  }

  .lg\:stroke-current{
    stroke: currentColor !important;
  }

  .lg\:stroke-0{
    stroke-width: 0 !important;
  }

  .lg\:stroke-1{
    stroke-width: 1 !important;
  }

  .lg\:stroke-2{
    stroke-width: 2 !important;
  }

  .lg\:object-contain{
    object-fit: contain !important;
  }

  .lg\:object-cover{
    object-fit: cover !important;
  }

  .lg\:object-fill{
    object-fit: fill !important;
  }

  .lg\:object-none{
    object-fit: none !important;
  }

  .lg\:object-scale-down{
    object-fit: scale-down !important;
  }

  .lg\:object-bottom{
    object-position: bottom !important;
  }

  .lg\:object-center{
    object-position: center !important;
  }

  .lg\:object-left{
    object-position: left !important;
  }

  .lg\:object-left-bottom{
    object-position: left bottom !important;
  }

  .lg\:object-left-top{
    object-position: left top !important;
  }

  .lg\:object-right{
    object-position: right !important;
  }

  .lg\:object-right-bottom{
    object-position: right bottom !important;
  }

  .lg\:object-right-top{
    object-position: right top !important;
  }

  .lg\:object-top{
    object-position: top !important;
  }

  .lg\:p-0{
    padding: 0px !important;
  }

  .lg\:p-1{
    padding: 0.25rem !important;
  }

  .lg\:p-2{
    padding: 0.5rem !important;
  }

  .lg\:p-3{
    padding: 0.75rem !important;
  }

  .lg\:p-4{
    padding: 1rem !important;
  }

  .lg\:p-5{
    padding: 1.25rem !important;
  }

  .lg\:p-6{
    padding: 1.5rem !important;
  }

  .lg\:p-7{
    padding: 1.75rem !important;
  }

  .lg\:p-8{
    padding: 2rem !important;
  }

  .lg\:p-9{
    padding: 2.25rem !important;
  }

  .lg\:p-10{
    padding: 2.5rem !important;
  }

  .lg\:p-11{
    padding: 2.75rem !important;
  }

  .lg\:p-12{
    padding: 3rem !important;
  }

  .lg\:p-14{
    padding: 3.5rem !important;
  }

  .lg\:p-16{
    padding: 4rem !important;
  }

  .lg\:p-20{
    padding: 5rem !important;
  }

  .lg\:p-24{
    padding: 6rem !important;
  }

  .lg\:p-28{
    padding: 7rem !important;
  }

  .lg\:p-32{
    padding: 8rem !important;
  }

  .lg\:p-36{
    padding: 9rem !important;
  }

  .lg\:p-40{
    padding: 10rem !important;
  }

  .lg\:p-44{
    padding: 11rem !important;
  }

  .lg\:p-48{
    padding: 12rem !important;
  }

  .lg\:p-52{
    padding: 13rem !important;
  }

  .lg\:p-56{
    padding: 14rem !important;
  }

  .lg\:p-60{
    padding: 15rem !important;
  }

  .lg\:p-64{
    padding: 16rem !important;
  }

  .lg\:p-72{
    padding: 18rem !important;
  }

  .lg\:p-80{
    padding: 20rem !important;
  }

  .lg\:p-96{
    padding: 24rem !important;
  }

  .lg\:p-px{
    padding: 1px !important;
  }

  .lg\:p-0\.5{
    padding: 0.125rem !important;
  }

  .lg\:p-1\.5{
    padding: 0.375rem !important;
  }

  .lg\:p-2\.5{
    padding: 0.625rem !important;
  }

  .lg\:p-3\.5{
    padding: 0.875rem !important;
  }

  .lg\:px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .lg\:px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lg\:px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lg\:px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lg\:px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lg\:px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .lg\:px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .lg\:px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lg\:px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .lg\:px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .lg\:px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lg\:px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lg\:px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lg\:px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lg\:px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lg\:px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .lg\:px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lg\:px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .lg\:px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lg\:px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .lg\:px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lg\:px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .lg\:px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .lg\:px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .lg\:px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lg\:px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .lg\:px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .lg\:px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .lg\:px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .lg\:py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .lg\:py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lg\:py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lg\:py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lg\:py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lg\:py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .lg\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .lg\:py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lg\:py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .lg\:py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .lg\:py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lg\:py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lg\:py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lg\:py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lg\:py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lg\:py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .lg\:py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lg\:py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .lg\:py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lg\:py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .lg\:py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lg\:py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .lg\:py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .lg\:py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .lg\:py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lg\:py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .lg\:py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .lg\:py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .lg\:py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .lg\:pt-0{
    padding-top: 0px !important;
  }

  .lg\:pt-1{
    padding-top: 0.25rem !important;
  }

  .lg\:pt-2{
    padding-top: 0.5rem !important;
  }

  .lg\:pt-3{
    padding-top: 0.75rem !important;
  }

  .lg\:pt-4{
    padding-top: 1rem !important;
  }

  .lg\:pt-5{
    padding-top: 1.25rem !important;
  }

  .lg\:pt-6{
    padding-top: 1.5rem !important;
  }

  .lg\:pt-7{
    padding-top: 1.75rem !important;
  }

  .lg\:pt-8{
    padding-top: 2rem !important;
  }

  .lg\:pt-9{
    padding-top: 2.25rem !important;
  }

  .lg\:pt-10{
    padding-top: 2.5rem !important;
  }

  .lg\:pt-11{
    padding-top: 2.75rem !important;
  }

  .lg\:pt-12{
    padding-top: 3rem !important;
  }

  .lg\:pt-14{
    padding-top: 3.5rem !important;
  }

  .lg\:pt-16{
    padding-top: 4rem !important;
  }

  .lg\:pt-20{
    padding-top: 5rem !important;
  }

  .lg\:pt-24{
    padding-top: 6rem !important;
  }

  .lg\:pt-28{
    padding-top: 7rem !important;
  }

  .lg\:pt-32{
    padding-top: 8rem !important;
  }

  .lg\:pt-36{
    padding-top: 9rem !important;
  }

  .lg\:pt-40{
    padding-top: 10rem !important;
  }

  .lg\:pt-44{
    padding-top: 11rem !important;
  }

  .lg\:pt-48{
    padding-top: 12rem !important;
  }

  .lg\:pt-52{
    padding-top: 13rem !important;
  }

  .lg\:pt-56{
    padding-top: 14rem !important;
  }

  .lg\:pt-60{
    padding-top: 15rem !important;
  }

  .lg\:pt-64{
    padding-top: 16rem !important;
  }

  .lg\:pt-72{
    padding-top: 18rem !important;
  }

  .lg\:pt-80{
    padding-top: 20rem !important;
  }

  .lg\:pt-96{
    padding-top: 24rem !important;
  }

  .lg\:pt-px{
    padding-top: 1px !important;
  }

  .lg\:pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .lg\:pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .lg\:pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .lg\:pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .lg\:pr-0{
    padding-right: 0px !important;
  }

  .lg\:pr-1{
    padding-right: 0.25rem !important;
  }

  .lg\:pr-2{
    padding-right: 0.5rem !important;
  }

  .lg\:pr-3{
    padding-right: 0.75rem !important;
  }

  .lg\:pr-4{
    padding-right: 1rem !important;
  }

  .lg\:pr-5{
    padding-right: 1.25rem !important;
  }

  .lg\:pr-6{
    padding-right: 1.5rem !important;
  }

  .lg\:pr-7{
    padding-right: 1.75rem !important;
  }

  .lg\:pr-8{
    padding-right: 2rem !important;
  }

  .lg\:pr-9{
    padding-right: 2.25rem !important;
  }

  .lg\:pr-10{
    padding-right: 2.5rem !important;
  }

  .lg\:pr-11{
    padding-right: 2.75rem !important;
  }

  .lg\:pr-12{
    padding-right: 3rem !important;
  }

  .lg\:pr-14{
    padding-right: 3.5rem !important;
  }

  .lg\:pr-16{
    padding-right: 4rem !important;
  }

  .lg\:pr-20{
    padding-right: 5rem !important;
  }

  .lg\:pr-24{
    padding-right: 6rem !important;
  }

  .lg\:pr-28{
    padding-right: 7rem !important;
  }

  .lg\:pr-32{
    padding-right: 8rem !important;
  }

  .lg\:pr-36{
    padding-right: 9rem !important;
  }

  .lg\:pr-40{
    padding-right: 10rem !important;
  }

  .lg\:pr-44{
    padding-right: 11rem !important;
  }

  .lg\:pr-48{
    padding-right: 12rem !important;
  }

  .lg\:pr-52{
    padding-right: 13rem !important;
  }

  .lg\:pr-56{
    padding-right: 14rem !important;
  }

  .lg\:pr-60{
    padding-right: 15rem !important;
  }

  .lg\:pr-64{
    padding-right: 16rem !important;
  }

  .lg\:pr-72{
    padding-right: 18rem !important;
  }

  .lg\:pr-80{
    padding-right: 20rem !important;
  }

  .lg\:pr-96{
    padding-right: 24rem !important;
  }

  .lg\:pr-px{
    padding-right: 1px !important;
  }

  .lg\:pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .lg\:pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .lg\:pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .lg\:pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .lg\:pb-0{
    padding-bottom: 0px !important;
  }

  .lg\:pb-1{
    padding-bottom: 0.25rem !important;
  }

  .lg\:pb-2{
    padding-bottom: 0.5rem !important;
  }

  .lg\:pb-3{
    padding-bottom: 0.75rem !important;
  }

  .lg\:pb-4{
    padding-bottom: 1rem !important;
  }

  .lg\:pb-5{
    padding-bottom: 1.25rem !important;
  }

  .lg\:pb-6{
    padding-bottom: 1.5rem !important;
  }

  .lg\:pb-7{
    padding-bottom: 1.75rem !important;
  }

  .lg\:pb-8{
    padding-bottom: 2rem !important;
  }

  .lg\:pb-9{
    padding-bottom: 2.25rem !important;
  }

  .lg\:pb-10{
    padding-bottom: 2.5rem !important;
  }

  .lg\:pb-11{
    padding-bottom: 2.75rem !important;
  }

  .lg\:pb-12{
    padding-bottom: 3rem !important;
  }

  .lg\:pb-14{
    padding-bottom: 3.5rem !important;
  }

  .lg\:pb-16{
    padding-bottom: 4rem !important;
  }

  .lg\:pb-20{
    padding-bottom: 5rem !important;
  }

  .lg\:pb-24{
    padding-bottom: 6rem !important;
  }

  .lg\:pb-28{
    padding-bottom: 7rem !important;
  }

  .lg\:pb-32{
    padding-bottom: 8rem !important;
  }

  .lg\:pb-36{
    padding-bottom: 9rem !important;
  }

  .lg\:pb-40{
    padding-bottom: 10rem !important;
  }

  .lg\:pb-44{
    padding-bottom: 11rem !important;
  }

  .lg\:pb-48{
    padding-bottom: 12rem !important;
  }

  .lg\:pb-52{
    padding-bottom: 13rem !important;
  }

  .lg\:pb-56{
    padding-bottom: 14rem !important;
  }

  .lg\:pb-60{
    padding-bottom: 15rem !important;
  }

  .lg\:pb-64{
    padding-bottom: 16rem !important;
  }

  .lg\:pb-72{
    padding-bottom: 18rem !important;
  }

  .lg\:pb-80{
    padding-bottom: 20rem !important;
  }

  .lg\:pb-96{
    padding-bottom: 24rem !important;
  }

  .lg\:pb-px{
    padding-bottom: 1px !important;
  }

  .lg\:pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .lg\:pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .lg\:pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .lg\:pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .lg\:pl-0{
    padding-left: 0px !important;
  }

  .lg\:pl-1{
    padding-left: 0.25rem !important;
  }

  .lg\:pl-2{
    padding-left: 0.5rem !important;
  }

  .lg\:pl-3{
    padding-left: 0.75rem !important;
  }

  .lg\:pl-4{
    padding-left: 1rem !important;
  }

  .lg\:pl-5{
    padding-left: 1.25rem !important;
  }

  .lg\:pl-6{
    padding-left: 1.5rem !important;
  }

  .lg\:pl-7{
    padding-left: 1.75rem !important;
  }

  .lg\:pl-8{
    padding-left: 2rem !important;
  }

  .lg\:pl-9{
    padding-left: 2.25rem !important;
  }

  .lg\:pl-10{
    padding-left: 2.5rem !important;
  }

  .lg\:pl-11{
    padding-left: 2.75rem !important;
  }

  .lg\:pl-12{
    padding-left: 3rem !important;
  }

  .lg\:pl-14{
    padding-left: 3.5rem !important;
  }

  .lg\:pl-16{
    padding-left: 4rem !important;
  }

  .lg\:pl-20{
    padding-left: 5rem !important;
  }

  .lg\:pl-24{
    padding-left: 6rem !important;
  }

  .lg\:pl-28{
    padding-left: 7rem !important;
  }

  .lg\:pl-32{
    padding-left: 8rem !important;
  }

  .lg\:pl-36{
    padding-left: 9rem !important;
  }

  .lg\:pl-40{
    padding-left: 10rem !important;
  }

  .lg\:pl-44{
    padding-left: 11rem !important;
  }

  .lg\:pl-48{
    padding-left: 12rem !important;
  }

  .lg\:pl-52{
    padding-left: 13rem !important;
  }

  .lg\:pl-56{
    padding-left: 14rem !important;
  }

  .lg\:pl-60{
    padding-left: 15rem !important;
  }

  .lg\:pl-64{
    padding-left: 16rem !important;
  }

  .lg\:pl-72{
    padding-left: 18rem !important;
  }

  .lg\:pl-80{
    padding-left: 20rem !important;
  }

  .lg\:pl-96{
    padding-left: 24rem !important;
  }

  .lg\:pl-px{
    padding-left: 1px !important;
  }

  .lg\:pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .lg\:pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .lg\:pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .lg\:pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .lg\:text-left{
    text-align: left !important;
  }

  .lg\:text-center{
    text-align: center !important;
  }

  .lg\:text-right{
    text-align: right !important;
  }

  .lg\:text-justify{
    text-align: justify !important;
  }

  .lg\:align-baseline{
    vertical-align: baseline !important;
  }

  .lg\:align-top{
    vertical-align: top !important;
  }

  .lg\:align-middle{
    vertical-align: middle !important;
  }

  .lg\:align-bottom{
    vertical-align: bottom !important;
  }

  .lg\:align-text-top{
    vertical-align: text-top !important;
  }

  .lg\:align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .lg\:font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .lg\:font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .lg\:font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .lg\:text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .lg\:text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .lg\:text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .lg\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .lg\:text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .lg\:text-2xl{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .lg\:text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .lg\:text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .lg\:text-5xl{
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .lg\:text-6xl{
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .lg\:text-7xl{
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .lg\:text-8xl{
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .lg\:text-9xl{
    font-size: 8rem !important;
    line-height: 1 !important;
  }

  .lg\:font-thin{
    font-weight: 100 !important;
  }

  .lg\:font-extralight{
    font-weight: 200 !important;
  }

  .lg\:font-light{
    font-weight: 300 !important;
  }

  .lg\:font-normal{
    font-weight: 400 !important;
  }

  .lg\:font-medium{
    font-weight: 500 !important;
  }

  .lg\:font-semibold{
    font-weight: 600 !important;
  }

  .lg\:font-bold{
    font-weight: 700 !important;
  }

  .lg\:font-extrabold{
    font-weight: 800 !important;
  }

  .lg\:font-black{
    font-weight: 900 !important;
  }

  .lg\:uppercase{
    text-transform: uppercase !important;
  }

  .lg\:lowercase{
    text-transform: lowercase !important;
  }

  .lg\:capitalize{
    text-transform: capitalize !important;
  }

  .lg\:normal-case{
    text-transform: none !important;
  }

  .lg\:italic{
    font-style: italic !important;
  }

  .lg\:not-italic{
    font-style: normal !important;
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .lg\:normal-nums{
    font-variant-numeric: normal !important;
  }

  .lg\:ordinal{
    --tw-ordinal: ordinal !important;
  }

  .lg\:slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .lg\:lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .lg\:oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .lg\:proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .lg\:tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .lg\:diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .lg\:stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .lg\:leading-3{
    line-height: .75rem !important;
  }

  .lg\:leading-4{
    line-height: 1rem !important;
  }

  .lg\:leading-5{
    line-height: 1.25rem !important;
  }

  .lg\:leading-6{
    line-height: 1.5rem !important;
  }

  .lg\:leading-7{
    line-height: 1.75rem !important;
  }

  .lg\:leading-8{
    line-height: 2rem !important;
  }

  .lg\:leading-9{
    line-height: 2.25rem !important;
  }

  .lg\:leading-10{
    line-height: 2.5rem !important;
  }

  .lg\:leading-none{
    line-height: 1 !important;
  }

  .lg\:leading-tight{
    line-height: 1.25 !important;
  }

  .lg\:leading-snug{
    line-height: 1.375 !important;
  }

  .lg\:leading-normal{
    line-height: 1.5 !important;
  }

  .lg\:leading-relaxed{
    line-height: 1.625 !important;
  }

  .lg\:leading-loose{
    line-height: 2 !important;
  }

  .lg\:tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .lg\:tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .lg\:tracking-normal{
    letter-spacing: 0em !important;
  }

  .lg\:tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .lg\:tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .lg\:tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .lg\:text-transparent{
    color: transparent !important;
  }

  .lg\:text-current{
    color: currentColor !important;
  }

  .lg\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-transparent{
    color: transparent !important;
  }

  .group:hover .lg\:group-hover\:text-current{
    color: currentColor !important;
  }

  .group:hover .lg\:group-hover\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .group:hover .lg\:group-hover\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-transparent:focus-within{
    color: transparent !important;
  }

  .lg\:focus-within\:text-current:focus-within{
    color: currentColor !important;
  }

  .lg\:focus-within\:text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-qipGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:focus-within\:text-portalGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-transparent:hover{
    color: transparent !important;
  }

  .lg\:hover\:text-current:hover{
    color: currentColor !important;
  }

  .lg\:hover\:text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-qipGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:hover\:text-portalGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-transparent:focus{
    color: transparent !important;
  }

  .lg\:focus\:text-current:focus{
    color: currentColor !important;
  }

  .lg\:focus\:text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-qipGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .lg\:focus\:text-portalGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .lg\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .lg\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .group:hover .lg\:group-hover\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .lg\:focus-within\:text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .lg\:focus-within\:text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:focus-within\:text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:focus-within\:text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:focus-within\:text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:focus-within\:text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:focus-within\:text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:focus-within\:text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:focus-within\:text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:focus-within\:text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:focus-within\:text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:focus-within\:text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:focus-within\:text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:focus-within\:text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:focus-within\:text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .lg\:hover\:text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .lg\:hover\:text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:hover\:text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:hover\:text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:hover\:text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:hover\:text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:hover\:text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:hover\:text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:hover\:text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:hover\:text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:hover\:text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:hover\:text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:hover\:text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:hover\:text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:hover\:text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .lg\:focus\:text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .lg\:focus\:text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .lg\:focus\:text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .lg\:focus\:text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .lg\:focus\:text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .lg\:focus\:text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .lg\:focus\:text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .lg\:focus\:text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .lg\:focus\:text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .lg\:focus\:text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .lg\:focus\:text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .lg\:focus\:text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .lg\:focus\:text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .lg\:focus\:text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .lg\:focus\:text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .lg\:underline{
    text-decoration: underline !important;
  }

  .lg\:line-through{
    text-decoration: line-through !important;
  }

  .lg\:no-underline{
    text-decoration: none !important;
  }

  .group:hover .lg\:group-hover\:underline{
    text-decoration: underline !important;
  }

  .group:hover .lg\:group-hover\:line-through{
    text-decoration: line-through !important;
  }

  .group:hover .lg\:group-hover\:no-underline{
    text-decoration: none !important;
  }

  .lg\:focus-within\:underline:focus-within{
    text-decoration: underline !important;
  }

  .lg\:focus-within\:line-through:focus-within{
    text-decoration: line-through !important;
  }

  .lg\:focus-within\:no-underline:focus-within{
    text-decoration: none !important;
  }

  .lg\:hover\:underline:hover{
    text-decoration: underline !important;
  }

  .lg\:hover\:line-through:hover{
    text-decoration: line-through !important;
  }

  .lg\:hover\:no-underline:hover{
    text-decoration: none !important;
  }

  .lg\:focus\:underline:focus{
    text-decoration: underline !important;
  }

  .lg\:focus\:line-through:focus{
    text-decoration: line-through !important;
  }

  .lg\:focus\:no-underline:focus{
    text-decoration: none !important;
  }

  .lg\:antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .lg\:subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .lg\:placeholder-transparent::-webkit-input-placeholder{
    color: transparent !important;
  }

  .lg\:placeholder-transparent:-ms-input-placeholder{
    color: transparent !important;
  }

  .lg\:placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .lg\:placeholder-current::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .lg\:placeholder-current:-ms-input-placeholder{
    color: currentColor !important;
  }

  .lg\:placeholder-current::placeholder{
    color: currentColor !important;
  }

  .lg\:placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-black:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-white:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-qipGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-qipGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-qipGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-portalGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-portalGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-portalGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent !important;
  }

  .lg\:focus\:placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent !important;
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .lg\:focus\:placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .lg\:focus\:placeholder-current:focus:-ms-input-placeholder{
    color: currentColor !important;
  }

  .lg\:focus\:placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .lg\:focus\:placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-black:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-white:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-qipGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-qipGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-qipGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-portalGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-portalGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:focus\:placeholder-portalGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .lg\:placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-5::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:placeholder-opacity-5:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:placeholder-opacity-10::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:placeholder-opacity-10:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:placeholder-opacity-20::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:placeholder-opacity-20:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-30::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:placeholder-opacity-30:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:placeholder-opacity-40::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:placeholder-opacity-40:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-60::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:placeholder-opacity-60:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:placeholder-opacity-70::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:placeholder-opacity-70:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-80::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:placeholder-opacity-80:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:placeholder-opacity-90:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:placeholder-opacity-95::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:placeholder-opacity-95:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:focus\:placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .lg\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:focus\:placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .lg\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:focus\:placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:focus\:placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .lg\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:focus\:placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:focus\:placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:focus\:placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .lg\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:focus\:placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .lg\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:focus\:placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .lg\:opacity-0{
    opacity: 0 !important;
  }

  .lg\:opacity-5{
    opacity: 0.05 !important;
  }

  .lg\:opacity-10{
    opacity: 0.1 !important;
  }

  .lg\:opacity-20{
    opacity: 0.2 !important;
  }

  .lg\:opacity-25{
    opacity: 0.25 !important;
  }

  .lg\:opacity-30{
    opacity: 0.3 !important;
  }

  .lg\:opacity-40{
    opacity: 0.4 !important;
  }

  .lg\:opacity-50{
    opacity: 0.5 !important;
  }

  .lg\:opacity-60{
    opacity: 0.6 !important;
  }

  .lg\:opacity-70{
    opacity: 0.7 !important;
  }

  .lg\:opacity-75{
    opacity: 0.75 !important;
  }

  .lg\:opacity-80{
    opacity: 0.8 !important;
  }

  .lg\:opacity-90{
    opacity: 0.9 !important;
  }

  .lg\:opacity-95{
    opacity: 0.95 !important;
  }

  .lg\:opacity-100{
    opacity: 1 !important;
  }

  .group:hover .lg\:group-hover\:opacity-0{
    opacity: 0 !important;
  }

  .group:hover .lg\:group-hover\:opacity-5{
    opacity: 0.05 !important;
  }

  .group:hover .lg\:group-hover\:opacity-10{
    opacity: 0.1 !important;
  }

  .group:hover .lg\:group-hover\:opacity-20{
    opacity: 0.2 !important;
  }

  .group:hover .lg\:group-hover\:opacity-25{
    opacity: 0.25 !important;
  }

  .group:hover .lg\:group-hover\:opacity-30{
    opacity: 0.3 !important;
  }

  .group:hover .lg\:group-hover\:opacity-40{
    opacity: 0.4 !important;
  }

  .group:hover .lg\:group-hover\:opacity-50{
    opacity: 0.5 !important;
  }

  .group:hover .lg\:group-hover\:opacity-60{
    opacity: 0.6 !important;
  }

  .group:hover .lg\:group-hover\:opacity-70{
    opacity: 0.7 !important;
  }

  .group:hover .lg\:group-hover\:opacity-75{
    opacity: 0.75 !important;
  }

  .group:hover .lg\:group-hover\:opacity-80{
    opacity: 0.8 !important;
  }

  .group:hover .lg\:group-hover\:opacity-90{
    opacity: 0.9 !important;
  }

  .group:hover .lg\:group-hover\:opacity-95{
    opacity: 0.95 !important;
  }

  .group:hover .lg\:group-hover\:opacity-100{
    opacity: 1 !important;
  }

  .lg\:focus-within\:opacity-0:focus-within{
    opacity: 0 !important;
  }

  .lg\:focus-within\:opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .lg\:focus-within\:opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .lg\:focus-within\:opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .lg\:focus-within\:opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .lg\:focus-within\:opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .lg\:focus-within\:opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .lg\:focus-within\:opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .lg\:focus-within\:opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .lg\:focus-within\:opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .lg\:focus-within\:opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .lg\:focus-within\:opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .lg\:focus-within\:opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .lg\:focus-within\:opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .lg\:focus-within\:opacity-100:focus-within{
    opacity: 1 !important;
  }

  .lg\:hover\:opacity-0:hover{
    opacity: 0 !important;
  }

  .lg\:hover\:opacity-5:hover{
    opacity: 0.05 !important;
  }

  .lg\:hover\:opacity-10:hover{
    opacity: 0.1 !important;
  }

  .lg\:hover\:opacity-20:hover{
    opacity: 0.2 !important;
  }

  .lg\:hover\:opacity-25:hover{
    opacity: 0.25 !important;
  }

  .lg\:hover\:opacity-30:hover{
    opacity: 0.3 !important;
  }

  .lg\:hover\:opacity-40:hover{
    opacity: 0.4 !important;
  }

  .lg\:hover\:opacity-50:hover{
    opacity: 0.5 !important;
  }

  .lg\:hover\:opacity-60:hover{
    opacity: 0.6 !important;
  }

  .lg\:hover\:opacity-70:hover{
    opacity: 0.7 !important;
  }

  .lg\:hover\:opacity-75:hover{
    opacity: 0.75 !important;
  }

  .lg\:hover\:opacity-80:hover{
    opacity: 0.8 !important;
  }

  .lg\:hover\:opacity-90:hover{
    opacity: 0.9 !important;
  }

  .lg\:hover\:opacity-95:hover{
    opacity: 0.95 !important;
  }

  .lg\:hover\:opacity-100:hover{
    opacity: 1 !important;
  }

  .lg\:focus\:opacity-0:focus{
    opacity: 0 !important;
  }

  .lg\:focus\:opacity-5:focus{
    opacity: 0.05 !important;
  }

  .lg\:focus\:opacity-10:focus{
    opacity: 0.1 !important;
  }

  .lg\:focus\:opacity-20:focus{
    opacity: 0.2 !important;
  }

  .lg\:focus\:opacity-25:focus{
    opacity: 0.25 !important;
  }

  .lg\:focus\:opacity-30:focus{
    opacity: 0.3 !important;
  }

  .lg\:focus\:opacity-40:focus{
    opacity: 0.4 !important;
  }

  .lg\:focus\:opacity-50:focus{
    opacity: 0.5 !important;
  }

  .lg\:focus\:opacity-60:focus{
    opacity: 0.6 !important;
  }

  .lg\:focus\:opacity-70:focus{
    opacity: 0.7 !important;
  }

  .lg\:focus\:opacity-75:focus{
    opacity: 0.75 !important;
  }

  .lg\:focus\:opacity-80:focus{
    opacity: 0.8 !important;
  }

  .lg\:focus\:opacity-90:focus{
    opacity: 0.9 !important;
  }

  .lg\:focus\:opacity-95:focus{
    opacity: 0.95 !important;
  }

  .lg\:focus\:opacity-100:focus{
    opacity: 1 !important;
  }

  .lg\:bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .lg\:bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .lg\:bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .lg\:bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .lg\:bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .lg\:bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .lg\:bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .lg\:bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .lg\:bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .lg\:bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .lg\:bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .lg\:bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .lg\:bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .lg\:bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .lg\:bg-blend-color{
    background-blend-mode: color !important;
  }

  .lg\:bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .lg\:mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .lg\:mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .lg\:mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .lg\:mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .lg\:mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .lg\:mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .lg\:mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .lg\:mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .lg\:mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .lg\:mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .lg\:mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .lg\:mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .lg\:mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .lg\:mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .lg\:mix-blend-color{
    mix-blend-mode: color !important;
  }

  .lg\:mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .lg\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .lg\:group-hover\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus-within\:shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:hover\:shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:focus\:shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .lg\:outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .lg\:outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .lg\:outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .lg\:focus-within\:outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .lg\:focus-within\:outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .lg\:focus-within\:outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .lg\:focus\:outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .lg\:focus\:outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .lg\:focus\:outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .lg\:ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:focus\:ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .lg\:ring-inset{
    --tw-ring-inset: inset !important;
  }

  .lg\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .lg\:focus\:ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .lg\:ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .lg\:ring-current{
    --tw-ring-color: currentColor !important;
  }

  .lg\:ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-qipGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-portalGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .lg\:focus-within\:ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .lg\:focus-within\:ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-qipGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus-within\:ring-portalGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .lg\:focus\:ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .lg\:focus\:ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-qipGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .lg\:focus\:ring-portalGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .lg\:ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .lg\:ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .lg\:ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .lg\:ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .lg\:ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .lg\:ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .lg\:ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .lg\:ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .lg\:ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .lg\:ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .lg\:ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .lg\:ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .lg\:ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .lg\:ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .lg\:ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .lg\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .lg\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .lg\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .lg\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .lg\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .lg\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .lg\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .lg\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .lg\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .lg\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .lg\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .lg\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .lg\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .lg\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .lg\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .lg\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .lg\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .lg\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .lg\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .lg\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .lg\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .lg\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .lg\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .lg\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .lg\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .lg\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .lg\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .lg\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .lg\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .lg\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .lg\:ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .lg\:ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .lg\:ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .lg\:ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .lg\:ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .lg\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .lg\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .lg\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .lg\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .lg\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .lg\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .lg\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .lg\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .lg\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .lg\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .lg\:ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .lg\:ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .lg\:ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .lg\:ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .lg\:ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .lg\:ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .lg\:ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .lg\:ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .lg\:ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .lg\:ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .lg\:ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .lg\:ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .lg\:ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .lg\:ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .lg\:ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .lg\:ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .lg\:ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .lg\:ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .lg\:ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .lg\:ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .lg\:ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .lg\:ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .lg\:ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .lg\:ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .lg\:ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .lg\:ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .lg\:ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .lg\:ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .lg\:ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .lg\:ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .lg\:ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .lg\:ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .lg\:ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .lg\:ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .lg\:ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .lg\:ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .lg\:ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .lg\:ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .lg\:ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .lg\:ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .lg\:ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .lg\:ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .lg\:ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .lg\:ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .lg\:ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .lg\:ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .lg\:ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .lg\:ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .lg\:ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .lg\:ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .lg\:ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .lg\:ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .lg\:ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .lg\:ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .lg\:ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .lg\:ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .lg\:ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .lg\:ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .lg\:ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .lg\:ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .lg\:ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .lg\:ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .lg\:ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .lg\:ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .lg\:ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .lg\:ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .lg\:ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .lg\:ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .lg\:ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .lg\:ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .lg\:ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .lg\:ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .lg\:ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .lg\:ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .lg\:ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .lg\:ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .lg\:ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .lg\:ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .lg\:ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .lg\:ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .lg\:ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .lg\:ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .lg\:ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .lg\:ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .lg\:ring-offset-qipGreen{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .lg\:ring-offset-portalGreen{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .lg\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .lg\:focus-within\:ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .lg\:focus-within\:ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .lg\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .lg\:focus-within\:ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .lg\:focus-within\:ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .lg\:focus-within\:ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .lg\:focus-within\:ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .lg\:focus-within\:ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .lg\:focus-within\:ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .lg\:focus-within\:ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .lg\:focus-within\:ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .lg\:focus-within\:ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .lg\:focus-within\:ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .lg\:focus-within\:ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .lg\:focus-within\:ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .lg\:focus-within\:ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .lg\:focus-within\:ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .lg\:focus-within\:ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .lg\:focus-within\:ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .lg\:focus-within\:ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .lg\:focus-within\:ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .lg\:focus-within\:ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .lg\:focus-within\:ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .lg\:focus-within\:ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .lg\:focus-within\:ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .lg\:focus-within\:ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .lg\:focus-within\:ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .lg\:focus-within\:ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .lg\:focus-within\:ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .lg\:focus-within\:ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .lg\:focus-within\:ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .lg\:focus-within\:ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .lg\:focus-within\:ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .lg\:focus-within\:ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .lg\:focus-within\:ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .lg\:focus-within\:ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .lg\:focus-within\:ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .lg\:focus-within\:ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .lg\:focus-within\:ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .lg\:focus-within\:ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .lg\:focus-within\:ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .lg\:focus-within\:ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .lg\:focus-within\:ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .lg\:focus-within\:ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .lg\:focus-within\:ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .lg\:focus-within\:ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .lg\:focus-within\:ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .lg\:focus-within\:ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .lg\:focus-within\:ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .lg\:focus-within\:ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .lg\:focus-within\:ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .lg\:focus-within\:ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .lg\:focus-within\:ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .lg\:focus-within\:ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .lg\:focus-within\:ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .lg\:focus-within\:ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .lg\:focus-within\:ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .lg\:focus-within\:ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .lg\:focus-within\:ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .lg\:focus-within\:ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .lg\:focus-within\:ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .lg\:focus-within\:ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .lg\:focus-within\:ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .lg\:focus-within\:ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .lg\:focus-within\:ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .lg\:focus-within\:ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .lg\:focus-within\:ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .lg\:focus-within\:ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .lg\:focus-within\:ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .lg\:focus-within\:ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .lg\:focus-within\:ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .lg\:focus-within\:ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .lg\:focus-within\:ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .lg\:focus-within\:ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .lg\:focus-within\:ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .lg\:focus-within\:ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .lg\:focus-within\:ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .lg\:focus-within\:ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .lg\:focus-within\:ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .lg\:focus-within\:ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .lg\:focus-within\:ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .lg\:focus-within\:ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .lg\:focus-within\:ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .lg\:focus-within\:ring-offset-qipGreen:focus-within{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .lg\:focus-within\:ring-offset-portalGreen:focus-within{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .lg\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .lg\:focus\:ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .lg\:focus\:ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .lg\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .lg\:focus\:ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .lg\:focus\:ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .lg\:focus\:ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .lg\:focus\:ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .lg\:focus\:ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .lg\:focus\:ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .lg\:focus\:ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .lg\:focus\:ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .lg\:focus\:ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .lg\:focus\:ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .lg\:focus\:ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .lg\:focus\:ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .lg\:focus\:ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .lg\:focus\:ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .lg\:focus\:ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .lg\:focus\:ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .lg\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .lg\:focus\:ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .lg\:focus\:ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .lg\:focus\:ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .lg\:focus\:ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .lg\:focus\:ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .lg\:focus\:ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .lg\:focus\:ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .lg\:focus\:ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .lg\:focus\:ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .lg\:focus\:ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .lg\:focus\:ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .lg\:focus\:ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .lg\:focus\:ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .lg\:focus\:ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .lg\:focus\:ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .lg\:focus\:ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .lg\:focus\:ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .lg\:focus\:ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .lg\:focus\:ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .lg\:focus\:ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .lg\:focus\:ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .lg\:focus\:ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .lg\:focus\:ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .lg\:focus\:ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .lg\:focus\:ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .lg\:focus\:ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .lg\:focus\:ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .lg\:focus\:ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .lg\:focus\:ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .lg\:focus\:ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .lg\:focus\:ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .lg\:focus\:ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .lg\:focus\:ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .lg\:focus\:ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .lg\:focus\:ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .lg\:focus\:ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .lg\:focus\:ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .lg\:focus\:ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .lg\:focus\:ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .lg\:focus\:ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .lg\:focus\:ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .lg\:focus\:ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .lg\:focus\:ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .lg\:focus\:ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .lg\:focus\:ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .lg\:focus\:ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .lg\:focus\:ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .lg\:focus\:ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .lg\:focus\:ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .lg\:focus\:ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .lg\:focus\:ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .lg\:focus\:ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .lg\:focus\:ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .lg\:focus\:ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .lg\:focus\:ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .lg\:focus\:ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .lg\:focus\:ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .lg\:focus\:ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .lg\:focus\:ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .lg\:focus\:ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .lg\:focus\:ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .lg\:focus\:ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .lg\:focus\:ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .lg\:focus\:ring-offset-qipGreen:focus{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .lg\:focus\:ring-offset-portalGreen:focus{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .lg\:filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .lg\:filter-none{
    -webkit-filter: none !important;
            filter: none !important;
  }

  .lg\:blur-0{
    --tw-blur: blur(0) !important;
  }

  .lg\:blur-none{
    --tw-blur: blur(0) !important;
  }

  .lg\:blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .lg\:blur{
    --tw-blur: blur(8px) !important;
  }

  .lg\:blur-md{
    --tw-blur: blur(12px) !important;
  }

  .lg\:blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .lg\:blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .lg\:blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .lg\:blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .lg\:brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .lg\:brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .lg\:brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .lg\:brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .lg\:brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .lg\:brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .lg\:brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .lg\:brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .lg\:brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .lg\:brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .lg\:brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .lg\:contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .lg\:contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .lg\:contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .lg\:contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .lg\:contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .lg\:contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .lg\:contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .lg\:drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .lg\:drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .lg\:drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .lg\:drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .lg\:drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .lg\:drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .lg\:drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .lg\:grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .lg\:grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .lg\:hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .lg\:hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .lg\:hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .lg\:hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .lg\:hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .lg\:hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .lg\:-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .lg\:-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .lg\:-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .lg\:-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .lg\:-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .lg\:invert-0{
    --tw-invert: invert(0) !important;
  }

  .lg\:invert{
    --tw-invert: invert(100%) !important;
  }

  .lg\:saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .lg\:saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .lg\:saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .lg\:saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .lg\:saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .lg\:sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .lg\:sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .lg\:backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .lg\:backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .lg\:backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .lg\:backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .lg\:backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .lg\:backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .lg\:backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .lg\:backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .lg\:backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .lg\:backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .lg\:backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .lg\:backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .lg\:backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .lg\:backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .lg\:backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .lg\:backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .lg\:backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .lg\:backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .lg\:backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .lg\:backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .lg\:backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .lg\:backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .lg\:backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .lg\:backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .lg\:backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .lg\:backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .lg\:backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .lg\:backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .lg\:backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .lg\:backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .lg\:backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .lg\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .lg\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .lg\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .lg\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .lg\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .lg\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .lg\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .lg\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .lg\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .lg\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .lg\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .lg\:backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .lg\:backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .lg\:backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .lg\:backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .lg\:backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .lg\:backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .lg\:backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .lg\:backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .lg\:backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .lg\:backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .lg\:backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .lg\:backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .lg\:backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .lg\:backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .lg\:backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .lg\:backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .lg\:backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .lg\:backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .lg\:backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .lg\:backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .lg\:backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .lg\:backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .lg\:backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .lg\:backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .lg\:transition-none{
    transition-property: none !important;
  }

  .lg\:transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .lg\:delay-75{
    transition-delay: 75ms !important;
  }

  .lg\:delay-100{
    transition-delay: 100ms !important;
  }

  .lg\:delay-150{
    transition-delay: 150ms !important;
  }

  .lg\:delay-200{
    transition-delay: 200ms !important;
  }

  .lg\:delay-300{
    transition-delay: 300ms !important;
  }

  .lg\:delay-500{
    transition-delay: 500ms !important;
  }

  .lg\:delay-700{
    transition-delay: 700ms !important;
  }

  .lg\:delay-1000{
    transition-delay: 1000ms !important;
  }

  .lg\:duration-75{
    transition-duration: 75ms !important;
  }

  .lg\:duration-100{
    transition-duration: 100ms !important;
  }

  .lg\:duration-150{
    transition-duration: 150ms !important;
  }

  .lg\:duration-200{
    transition-duration: 200ms !important;
  }

  .lg\:duration-300{
    transition-duration: 300ms !important;
  }

  .lg\:duration-500{
    transition-duration: 500ms !important;
  }

  .lg\:duration-700{
    transition-duration: 700ms !important;
  }

  .lg\:duration-1000{
    transition-duration: 1000ms !important;
  }

  .lg\:ease-linear{
    transition-timing-function: linear !important;
  }

  .lg\:ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .lg\:ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .lg\:ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 1280px){
  .xl\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .xl\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .xl\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .xl\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .xl\:container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .xl\:container{
      max-width: 1536px;
    }
  }

  .xl\:sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:focus-within\:sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:focus-within\:not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:focus\:sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:focus\:not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:pointer-events-none{
    pointer-events: none !important;
  }

  .xl\:pointer-events-auto{
    pointer-events: auto !important;
  }

  .xl\:visible{
    visibility: visible !important;
  }

  .xl\:invisible{
    visibility: hidden !important;
  }

  .xl\:static{
    position: static !important;
  }

  .xl\:fixed{
    position: fixed !important;
  }

  .xl\:absolute{
    position: absolute !important;
  }

  .xl\:relative{
    position: relative !important;
  }

  .xl\:sticky{
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .xl\:inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .xl\:inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .xl\:inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .xl\:inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .xl\:inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .xl\:inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .xl\:inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .xl\:inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .xl\:inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .xl\:inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .xl\:inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .xl\:inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .xl\:inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .xl\:inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .xl\:inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .xl\:inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .xl\:inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .xl\:inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .xl\:inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .xl\:inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .xl\:inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .xl\:inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .xl\:inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .xl\:inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .xl\:inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .xl\:inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .xl\:inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .xl\:inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .xl\:inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .xl\:inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .xl\:inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xl\:inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .xl\:inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .xl\:inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .xl\:inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .xl\:inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .xl\:-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .xl\:-inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .xl\:-inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .xl\:-inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .xl\:-inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .xl\:-inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .xl\:-inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .xl\:-inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .xl\:-inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .xl\:-inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .xl\:-inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .xl\:-inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .xl\:-inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .xl\:-inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .xl\:-inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .xl\:-inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .xl\:-inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .xl\:-inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .xl\:-inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .xl\:-inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .xl\:-inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .xl\:-inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .xl\:-inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .xl\:-inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .xl\:-inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .xl\:-inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .xl\:-inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .xl\:-inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .xl\:-inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .xl\:-inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .xl\:-inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .xl\:-inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .xl\:-inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .xl\:-inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .xl\:-inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .xl\:inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .xl\:inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .xl\:inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .xl\:inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .xl\:inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .xl\:inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .xl\:inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .xl\:-inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .xl\:-inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .xl\:-inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .xl\:-inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .xl\:-inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .xl\:-inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .xl\:-inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .xl\:inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .xl\:inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .xl\:inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .xl\:inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .xl\:inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .xl\:inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .xl\:inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .xl\:inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .xl\:inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .xl\:inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .xl\:inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .xl\:inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .xl\:inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .xl\:inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .xl\:inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .xl\:inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .xl\:inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .xl\:inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .xl\:inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .xl\:inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .xl\:inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .xl\:inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .xl\:inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .xl\:inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .xl\:inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .xl\:inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .xl\:inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .xl\:inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .xl\:inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .xl\:inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .xl\:inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .xl\:inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .xl\:inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .xl\:inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .xl\:inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .xl\:inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .xl\:-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .xl\:-inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .xl\:-inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .xl\:-inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .xl\:-inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .xl\:-inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .xl\:-inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .xl\:-inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .xl\:-inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .xl\:-inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .xl\:-inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .xl\:-inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .xl\:-inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .xl\:-inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .xl\:-inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .xl\:-inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .xl\:-inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .xl\:-inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .xl\:-inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .xl\:-inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .xl\:-inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .xl\:-inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .xl\:-inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .xl\:-inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .xl\:-inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .xl\:-inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .xl\:-inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .xl\:-inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .xl\:-inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .xl\:-inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .xl\:-inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .xl\:-inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .xl\:-inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .xl\:-inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .xl\:-inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .xl\:inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .xl\:inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .xl\:inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .xl\:inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .xl\:inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .xl\:inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .xl\:inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .xl\:-inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .xl\:-inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .xl\:-inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .xl\:-inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .xl\:-inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .xl\:-inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .xl\:-inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .xl\:inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .xl\:inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .xl\:inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .xl\:inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .xl\:inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .xl\:inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .xl\:inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .xl\:inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .xl\:inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .xl\:inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .xl\:inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .xl\:inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .xl\:inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .xl\:inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .xl\:inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .xl\:inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .xl\:inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .xl\:inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .xl\:inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .xl\:inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .xl\:inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .xl\:inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .xl\:inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .xl\:inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .xl\:inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .xl\:inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .xl\:inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .xl\:inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .xl\:inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .xl\:inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .xl\:inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .xl\:inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .xl\:inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .xl\:inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .xl\:inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .xl\:inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .xl\:-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .xl\:-inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .xl\:-inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .xl\:-inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .xl\:-inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .xl\:-inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .xl\:-inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .xl\:-inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .xl\:-inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .xl\:-inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .xl\:-inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .xl\:-inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .xl\:-inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .xl\:-inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .xl\:-inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .xl\:-inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .xl\:-inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .xl\:-inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .xl\:-inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .xl\:-inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .xl\:-inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .xl\:-inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .xl\:-inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .xl\:-inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .xl\:-inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .xl\:-inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .xl\:-inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .xl\:-inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .xl\:-inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .xl\:-inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .xl\:-inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .xl\:-inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .xl\:-inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .xl\:-inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .xl\:-inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .xl\:inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .xl\:inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .xl\:inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .xl\:inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .xl\:inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .xl\:inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .xl\:inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .xl\:-inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .xl\:-inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .xl\:-inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .xl\:-inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .xl\:-inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .xl\:-inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .xl\:-inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .xl\:top-0{
    top: 0px !important;
  }

  .xl\:top-1{
    top: 0.25rem !important;
  }

  .xl\:top-2{
    top: 0.5rem !important;
  }

  .xl\:top-3{
    top: 0.75rem !important;
  }

  .xl\:top-4{
    top: 1rem !important;
  }

  .xl\:top-5{
    top: 1.25rem !important;
  }

  .xl\:top-6{
    top: 1.5rem !important;
  }

  .xl\:top-7{
    top: 1.75rem !important;
  }

  .xl\:top-8{
    top: 2rem !important;
  }

  .xl\:top-9{
    top: 2.25rem !important;
  }

  .xl\:top-10{
    top: 2.5rem !important;
  }

  .xl\:top-11{
    top: 2.75rem !important;
  }

  .xl\:top-12{
    top: 3rem !important;
  }

  .xl\:top-14{
    top: 3.5rem !important;
  }

  .xl\:top-16{
    top: 4rem !important;
  }

  .xl\:top-20{
    top: 5rem !important;
  }

  .xl\:top-24{
    top: 6rem !important;
  }

  .xl\:top-28{
    top: 7rem !important;
  }

  .xl\:top-32{
    top: 8rem !important;
  }

  .xl\:top-36{
    top: 9rem !important;
  }

  .xl\:top-40{
    top: 10rem !important;
  }

  .xl\:top-44{
    top: 11rem !important;
  }

  .xl\:top-48{
    top: 12rem !important;
  }

  .xl\:top-52{
    top: 13rem !important;
  }

  .xl\:top-56{
    top: 14rem !important;
  }

  .xl\:top-60{
    top: 15rem !important;
  }

  .xl\:top-64{
    top: 16rem !important;
  }

  .xl\:top-72{
    top: 18rem !important;
  }

  .xl\:top-80{
    top: 20rem !important;
  }

  .xl\:top-96{
    top: 24rem !important;
  }

  .xl\:top-auto{
    top: auto !important;
  }

  .xl\:top-px{
    top: 1px !important;
  }

  .xl\:top-0\.5{
    top: 0.125rem !important;
  }

  .xl\:top-1\.5{
    top: 0.375rem !important;
  }

  .xl\:top-2\.5{
    top: 0.625rem !important;
  }

  .xl\:top-3\.5{
    top: 0.875rem !important;
  }

  .xl\:-top-0{
    top: 0px !important;
  }

  .xl\:-top-1{
    top: -0.25rem !important;
  }

  .xl\:-top-2{
    top: -0.5rem !important;
  }

  .xl\:-top-3{
    top: -0.75rem !important;
  }

  .xl\:-top-4{
    top: -1rem !important;
  }

  .xl\:-top-5{
    top: -1.25rem !important;
  }

  .xl\:-top-6{
    top: -1.5rem !important;
  }

  .xl\:-top-7{
    top: -1.75rem !important;
  }

  .xl\:-top-8{
    top: -2rem !important;
  }

  .xl\:-top-9{
    top: -2.25rem !important;
  }

  .xl\:-top-10{
    top: -2.5rem !important;
  }

  .xl\:-top-11{
    top: -2.75rem !important;
  }

  .xl\:-top-12{
    top: -3rem !important;
  }

  .xl\:-top-14{
    top: -3.5rem !important;
  }

  .xl\:-top-16{
    top: -4rem !important;
  }

  .xl\:-top-20{
    top: -5rem !important;
  }

  .xl\:-top-24{
    top: -6rem !important;
  }

  .xl\:-top-28{
    top: -7rem !important;
  }

  .xl\:-top-32{
    top: -8rem !important;
  }

  .xl\:-top-36{
    top: -9rem !important;
  }

  .xl\:-top-40{
    top: -10rem !important;
  }

  .xl\:-top-44{
    top: -11rem !important;
  }

  .xl\:-top-48{
    top: -12rem !important;
  }

  .xl\:-top-52{
    top: -13rem !important;
  }

  .xl\:-top-56{
    top: -14rem !important;
  }

  .xl\:-top-60{
    top: -15rem !important;
  }

  .xl\:-top-64{
    top: -16rem !important;
  }

  .xl\:-top-72{
    top: -18rem !important;
  }

  .xl\:-top-80{
    top: -20rem !important;
  }

  .xl\:-top-96{
    top: -24rem !important;
  }

  .xl\:-top-px{
    top: -1px !important;
  }

  .xl\:-top-0\.5{
    top: -0.125rem !important;
  }

  .xl\:-top-1\.5{
    top: -0.375rem !important;
  }

  .xl\:-top-2\.5{
    top: -0.625rem !important;
  }

  .xl\:-top-3\.5{
    top: -0.875rem !important;
  }

  .xl\:top-1\/2{
    top: 50% !important;
  }

  .xl\:top-1\/3{
    top: 33.333333% !important;
  }

  .xl\:top-2\/3{
    top: 66.666667% !important;
  }

  .xl\:top-1\/4{
    top: 25% !important;
  }

  .xl\:top-2\/4{
    top: 50% !important;
  }

  .xl\:top-3\/4{
    top: 75% !important;
  }

  .xl\:top-full{
    top: 100% !important;
  }

  .xl\:-top-1\/2{
    top: -50% !important;
  }

  .xl\:-top-1\/3{
    top: -33.333333% !important;
  }

  .xl\:-top-2\/3{
    top: -66.666667% !important;
  }

  .xl\:-top-1\/4{
    top: -25% !important;
  }

  .xl\:-top-2\/4{
    top: -50% !important;
  }

  .xl\:-top-3\/4{
    top: -75% !important;
  }

  .xl\:-top-full{
    top: -100% !important;
  }

  .xl\:right-0{
    right: 0px !important;
  }

  .xl\:right-1{
    right: 0.25rem !important;
  }

  .xl\:right-2{
    right: 0.5rem !important;
  }

  .xl\:right-3{
    right: 0.75rem !important;
  }

  .xl\:right-4{
    right: 1rem !important;
  }

  .xl\:right-5{
    right: 1.25rem !important;
  }

  .xl\:right-6{
    right: 1.5rem !important;
  }

  .xl\:right-7{
    right: 1.75rem !important;
  }

  .xl\:right-8{
    right: 2rem !important;
  }

  .xl\:right-9{
    right: 2.25rem !important;
  }

  .xl\:right-10{
    right: 2.5rem !important;
  }

  .xl\:right-11{
    right: 2.75rem !important;
  }

  .xl\:right-12{
    right: 3rem !important;
  }

  .xl\:right-14{
    right: 3.5rem !important;
  }

  .xl\:right-16{
    right: 4rem !important;
  }

  .xl\:right-20{
    right: 5rem !important;
  }

  .xl\:right-24{
    right: 6rem !important;
  }

  .xl\:right-28{
    right: 7rem !important;
  }

  .xl\:right-32{
    right: 8rem !important;
  }

  .xl\:right-36{
    right: 9rem !important;
  }

  .xl\:right-40{
    right: 10rem !important;
  }

  .xl\:right-44{
    right: 11rem !important;
  }

  .xl\:right-48{
    right: 12rem !important;
  }

  .xl\:right-52{
    right: 13rem !important;
  }

  .xl\:right-56{
    right: 14rem !important;
  }

  .xl\:right-60{
    right: 15rem !important;
  }

  .xl\:right-64{
    right: 16rem !important;
  }

  .xl\:right-72{
    right: 18rem !important;
  }

  .xl\:right-80{
    right: 20rem !important;
  }

  .xl\:right-96{
    right: 24rem !important;
  }

  .xl\:right-auto{
    right: auto !important;
  }

  .xl\:right-px{
    right: 1px !important;
  }

  .xl\:right-0\.5{
    right: 0.125rem !important;
  }

  .xl\:right-1\.5{
    right: 0.375rem !important;
  }

  .xl\:right-2\.5{
    right: 0.625rem !important;
  }

  .xl\:right-3\.5{
    right: 0.875rem !important;
  }

  .xl\:-right-0{
    right: 0px !important;
  }

  .xl\:-right-1{
    right: -0.25rem !important;
  }

  .xl\:-right-2{
    right: -0.5rem !important;
  }

  .xl\:-right-3{
    right: -0.75rem !important;
  }

  .xl\:-right-4{
    right: -1rem !important;
  }

  .xl\:-right-5{
    right: -1.25rem !important;
  }

  .xl\:-right-6{
    right: -1.5rem !important;
  }

  .xl\:-right-7{
    right: -1.75rem !important;
  }

  .xl\:-right-8{
    right: -2rem !important;
  }

  .xl\:-right-9{
    right: -2.25rem !important;
  }

  .xl\:-right-10{
    right: -2.5rem !important;
  }

  .xl\:-right-11{
    right: -2.75rem !important;
  }

  .xl\:-right-12{
    right: -3rem !important;
  }

  .xl\:-right-14{
    right: -3.5rem !important;
  }

  .xl\:-right-16{
    right: -4rem !important;
  }

  .xl\:-right-20{
    right: -5rem !important;
  }

  .xl\:-right-24{
    right: -6rem !important;
  }

  .xl\:-right-28{
    right: -7rem !important;
  }

  .xl\:-right-32{
    right: -8rem !important;
  }

  .xl\:-right-36{
    right: -9rem !important;
  }

  .xl\:-right-40{
    right: -10rem !important;
  }

  .xl\:-right-44{
    right: -11rem !important;
  }

  .xl\:-right-48{
    right: -12rem !important;
  }

  .xl\:-right-52{
    right: -13rem !important;
  }

  .xl\:-right-56{
    right: -14rem !important;
  }

  .xl\:-right-60{
    right: -15rem !important;
  }

  .xl\:-right-64{
    right: -16rem !important;
  }

  .xl\:-right-72{
    right: -18rem !important;
  }

  .xl\:-right-80{
    right: -20rem !important;
  }

  .xl\:-right-96{
    right: -24rem !important;
  }

  .xl\:-right-px{
    right: -1px !important;
  }

  .xl\:-right-0\.5{
    right: -0.125rem !important;
  }

  .xl\:-right-1\.5{
    right: -0.375rem !important;
  }

  .xl\:-right-2\.5{
    right: -0.625rem !important;
  }

  .xl\:-right-3\.5{
    right: -0.875rem !important;
  }

  .xl\:right-1\/2{
    right: 50% !important;
  }

  .xl\:right-1\/3{
    right: 33.333333% !important;
  }

  .xl\:right-2\/3{
    right: 66.666667% !important;
  }

  .xl\:right-1\/4{
    right: 25% !important;
  }

  .xl\:right-2\/4{
    right: 50% !important;
  }

  .xl\:right-3\/4{
    right: 75% !important;
  }

  .xl\:right-full{
    right: 100% !important;
  }

  .xl\:-right-1\/2{
    right: -50% !important;
  }

  .xl\:-right-1\/3{
    right: -33.333333% !important;
  }

  .xl\:-right-2\/3{
    right: -66.666667% !important;
  }

  .xl\:-right-1\/4{
    right: -25% !important;
  }

  .xl\:-right-2\/4{
    right: -50% !important;
  }

  .xl\:-right-3\/4{
    right: -75% !important;
  }

  .xl\:-right-full{
    right: -100% !important;
  }

  .xl\:bottom-0{
    bottom: 0px !important;
  }

  .xl\:bottom-1{
    bottom: 0.25rem !important;
  }

  .xl\:bottom-2{
    bottom: 0.5rem !important;
  }

  .xl\:bottom-3{
    bottom: 0.75rem !important;
  }

  .xl\:bottom-4{
    bottom: 1rem !important;
  }

  .xl\:bottom-5{
    bottom: 1.25rem !important;
  }

  .xl\:bottom-6{
    bottom: 1.5rem !important;
  }

  .xl\:bottom-7{
    bottom: 1.75rem !important;
  }

  .xl\:bottom-8{
    bottom: 2rem !important;
  }

  .xl\:bottom-9{
    bottom: 2.25rem !important;
  }

  .xl\:bottom-10{
    bottom: 2.5rem !important;
  }

  .xl\:bottom-11{
    bottom: 2.75rem !important;
  }

  .xl\:bottom-12{
    bottom: 3rem !important;
  }

  .xl\:bottom-14{
    bottom: 3.5rem !important;
  }

  .xl\:bottom-16{
    bottom: 4rem !important;
  }

  .xl\:bottom-20{
    bottom: 5rem !important;
  }

  .xl\:bottom-24{
    bottom: 6rem !important;
  }

  .xl\:bottom-28{
    bottom: 7rem !important;
  }

  .xl\:bottom-32{
    bottom: 8rem !important;
  }

  .xl\:bottom-36{
    bottom: 9rem !important;
  }

  .xl\:bottom-40{
    bottom: 10rem !important;
  }

  .xl\:bottom-44{
    bottom: 11rem !important;
  }

  .xl\:bottom-48{
    bottom: 12rem !important;
  }

  .xl\:bottom-52{
    bottom: 13rem !important;
  }

  .xl\:bottom-56{
    bottom: 14rem !important;
  }

  .xl\:bottom-60{
    bottom: 15rem !important;
  }

  .xl\:bottom-64{
    bottom: 16rem !important;
  }

  .xl\:bottom-72{
    bottom: 18rem !important;
  }

  .xl\:bottom-80{
    bottom: 20rem !important;
  }

  .xl\:bottom-96{
    bottom: 24rem !important;
  }

  .xl\:bottom-auto{
    bottom: auto !important;
  }

  .xl\:bottom-px{
    bottom: 1px !important;
  }

  .xl\:bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .xl\:bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .xl\:bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .xl\:bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .xl\:-bottom-0{
    bottom: 0px !important;
  }

  .xl\:-bottom-1{
    bottom: -0.25rem !important;
  }

  .xl\:-bottom-2{
    bottom: -0.5rem !important;
  }

  .xl\:-bottom-3{
    bottom: -0.75rem !important;
  }

  .xl\:-bottom-4{
    bottom: -1rem !important;
  }

  .xl\:-bottom-5{
    bottom: -1.25rem !important;
  }

  .xl\:-bottom-6{
    bottom: -1.5rem !important;
  }

  .xl\:-bottom-7{
    bottom: -1.75rem !important;
  }

  .xl\:-bottom-8{
    bottom: -2rem !important;
  }

  .xl\:-bottom-9{
    bottom: -2.25rem !important;
  }

  .xl\:-bottom-10{
    bottom: -2.5rem !important;
  }

  .xl\:-bottom-11{
    bottom: -2.75rem !important;
  }

  .xl\:-bottom-12{
    bottom: -3rem !important;
  }

  .xl\:-bottom-14{
    bottom: -3.5rem !important;
  }

  .xl\:-bottom-16{
    bottom: -4rem !important;
  }

  .xl\:-bottom-20{
    bottom: -5rem !important;
  }

  .xl\:-bottom-24{
    bottom: -6rem !important;
  }

  .xl\:-bottom-28{
    bottom: -7rem !important;
  }

  .xl\:-bottom-32{
    bottom: -8rem !important;
  }

  .xl\:-bottom-36{
    bottom: -9rem !important;
  }

  .xl\:-bottom-40{
    bottom: -10rem !important;
  }

  .xl\:-bottom-44{
    bottom: -11rem !important;
  }

  .xl\:-bottom-48{
    bottom: -12rem !important;
  }

  .xl\:-bottom-52{
    bottom: -13rem !important;
  }

  .xl\:-bottom-56{
    bottom: -14rem !important;
  }

  .xl\:-bottom-60{
    bottom: -15rem !important;
  }

  .xl\:-bottom-64{
    bottom: -16rem !important;
  }

  .xl\:-bottom-72{
    bottom: -18rem !important;
  }

  .xl\:-bottom-80{
    bottom: -20rem !important;
  }

  .xl\:-bottom-96{
    bottom: -24rem !important;
  }

  .xl\:-bottom-px{
    bottom: -1px !important;
  }

  .xl\:-bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .xl\:-bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .xl\:-bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .xl\:-bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .xl\:bottom-1\/2{
    bottom: 50% !important;
  }

  .xl\:bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .xl\:bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .xl\:bottom-1\/4{
    bottom: 25% !important;
  }

  .xl\:bottom-2\/4{
    bottom: 50% !important;
  }

  .xl\:bottom-3\/4{
    bottom: 75% !important;
  }

  .xl\:bottom-full{
    bottom: 100% !important;
  }

  .xl\:-bottom-1\/2{
    bottom: -50% !important;
  }

  .xl\:-bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .xl\:-bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .xl\:-bottom-1\/4{
    bottom: -25% !important;
  }

  .xl\:-bottom-2\/4{
    bottom: -50% !important;
  }

  .xl\:-bottom-3\/4{
    bottom: -75% !important;
  }

  .xl\:-bottom-full{
    bottom: -100% !important;
  }

  .xl\:left-0{
    left: 0px !important;
  }

  .xl\:left-1{
    left: 0.25rem !important;
  }

  .xl\:left-2{
    left: 0.5rem !important;
  }

  .xl\:left-3{
    left: 0.75rem !important;
  }

  .xl\:left-4{
    left: 1rem !important;
  }

  .xl\:left-5{
    left: 1.25rem !important;
  }

  .xl\:left-6{
    left: 1.5rem !important;
  }

  .xl\:left-7{
    left: 1.75rem !important;
  }

  .xl\:left-8{
    left: 2rem !important;
  }

  .xl\:left-9{
    left: 2.25rem !important;
  }

  .xl\:left-10{
    left: 2.5rem !important;
  }

  .xl\:left-11{
    left: 2.75rem !important;
  }

  .xl\:left-12{
    left: 3rem !important;
  }

  .xl\:left-14{
    left: 3.5rem !important;
  }

  .xl\:left-16{
    left: 4rem !important;
  }

  .xl\:left-20{
    left: 5rem !important;
  }

  .xl\:left-24{
    left: 6rem !important;
  }

  .xl\:left-28{
    left: 7rem !important;
  }

  .xl\:left-32{
    left: 8rem !important;
  }

  .xl\:left-36{
    left: 9rem !important;
  }

  .xl\:left-40{
    left: 10rem !important;
  }

  .xl\:left-44{
    left: 11rem !important;
  }

  .xl\:left-48{
    left: 12rem !important;
  }

  .xl\:left-52{
    left: 13rem !important;
  }

  .xl\:left-56{
    left: 14rem !important;
  }

  .xl\:left-60{
    left: 15rem !important;
  }

  .xl\:left-64{
    left: 16rem !important;
  }

  .xl\:left-72{
    left: 18rem !important;
  }

  .xl\:left-80{
    left: 20rem !important;
  }

  .xl\:left-96{
    left: 24rem !important;
  }

  .xl\:left-auto{
    left: auto !important;
  }

  .xl\:left-px{
    left: 1px !important;
  }

  .xl\:left-0\.5{
    left: 0.125rem !important;
  }

  .xl\:left-1\.5{
    left: 0.375rem !important;
  }

  .xl\:left-2\.5{
    left: 0.625rem !important;
  }

  .xl\:left-3\.5{
    left: 0.875rem !important;
  }

  .xl\:-left-0{
    left: 0px !important;
  }

  .xl\:-left-1{
    left: -0.25rem !important;
  }

  .xl\:-left-2{
    left: -0.5rem !important;
  }

  .xl\:-left-3{
    left: -0.75rem !important;
  }

  .xl\:-left-4{
    left: -1rem !important;
  }

  .xl\:-left-5{
    left: -1.25rem !important;
  }

  .xl\:-left-6{
    left: -1.5rem !important;
  }

  .xl\:-left-7{
    left: -1.75rem !important;
  }

  .xl\:-left-8{
    left: -2rem !important;
  }

  .xl\:-left-9{
    left: -2.25rem !important;
  }

  .xl\:-left-10{
    left: -2.5rem !important;
  }

  .xl\:-left-11{
    left: -2.75rem !important;
  }

  .xl\:-left-12{
    left: -3rem !important;
  }

  .xl\:-left-14{
    left: -3.5rem !important;
  }

  .xl\:-left-16{
    left: -4rem !important;
  }

  .xl\:-left-20{
    left: -5rem !important;
  }

  .xl\:-left-24{
    left: -6rem !important;
  }

  .xl\:-left-28{
    left: -7rem !important;
  }

  .xl\:-left-32{
    left: -8rem !important;
  }

  .xl\:-left-36{
    left: -9rem !important;
  }

  .xl\:-left-40{
    left: -10rem !important;
  }

  .xl\:-left-44{
    left: -11rem !important;
  }

  .xl\:-left-48{
    left: -12rem !important;
  }

  .xl\:-left-52{
    left: -13rem !important;
  }

  .xl\:-left-56{
    left: -14rem !important;
  }

  .xl\:-left-60{
    left: -15rem !important;
  }

  .xl\:-left-64{
    left: -16rem !important;
  }

  .xl\:-left-72{
    left: -18rem !important;
  }

  .xl\:-left-80{
    left: -20rem !important;
  }

  .xl\:-left-96{
    left: -24rem !important;
  }

  .xl\:-left-px{
    left: -1px !important;
  }

  .xl\:-left-0\.5{
    left: -0.125rem !important;
  }

  .xl\:-left-1\.5{
    left: -0.375rem !important;
  }

  .xl\:-left-2\.5{
    left: -0.625rem !important;
  }

  .xl\:-left-3\.5{
    left: -0.875rem !important;
  }

  .xl\:left-1\/2{
    left: 50% !important;
  }

  .xl\:left-1\/3{
    left: 33.333333% !important;
  }

  .xl\:left-2\/3{
    left: 66.666667% !important;
  }

  .xl\:left-1\/4{
    left: 25% !important;
  }

  .xl\:left-2\/4{
    left: 50% !important;
  }

  .xl\:left-3\/4{
    left: 75% !important;
  }

  .xl\:left-full{
    left: 100% !important;
  }

  .xl\:-left-1\/2{
    left: -50% !important;
  }

  .xl\:-left-1\/3{
    left: -33.333333% !important;
  }

  .xl\:-left-2\/3{
    left: -66.666667% !important;
  }

  .xl\:-left-1\/4{
    left: -25% !important;
  }

  .xl\:-left-2\/4{
    left: -50% !important;
  }

  .xl\:-left-3\/4{
    left: -75% !important;
  }

  .xl\:-left-full{
    left: -100% !important;
  }

  .xl\:isolate{
    isolation: isolate !important;
  }

  .xl\:isolation-auto{
    isolation: auto !important;
  }

  .xl\:z-0{
    z-index: 0 !important;
  }

  .xl\:z-10{
    z-index: 10 !important;
  }

  .xl\:z-20{
    z-index: 20 !important;
  }

  .xl\:z-30{
    z-index: 30 !important;
  }

  .xl\:z-40{
    z-index: 40 !important;
  }

  .xl\:z-50{
    z-index: 50 !important;
  }

  .xl\:z-auto{
    z-index: auto !important;
  }

  .xl\:focus-within\:z-0:focus-within{
    z-index: 0 !important;
  }

  .xl\:focus-within\:z-10:focus-within{
    z-index: 10 !important;
  }

  .xl\:focus-within\:z-20:focus-within{
    z-index: 20 !important;
  }

  .xl\:focus-within\:z-30:focus-within{
    z-index: 30 !important;
  }

  .xl\:focus-within\:z-40:focus-within{
    z-index: 40 !important;
  }

  .xl\:focus-within\:z-50:focus-within{
    z-index: 50 !important;
  }

  .xl\:focus-within\:z-auto:focus-within{
    z-index: auto !important;
  }

  .xl\:focus\:z-0:focus{
    z-index: 0 !important;
  }

  .xl\:focus\:z-10:focus{
    z-index: 10 !important;
  }

  .xl\:focus\:z-20:focus{
    z-index: 20 !important;
  }

  .xl\:focus\:z-30:focus{
    z-index: 30 !important;
  }

  .xl\:focus\:z-40:focus{
    z-index: 40 !important;
  }

  .xl\:focus\:z-50:focus{
    z-index: 50 !important;
  }

  .xl\:focus\:z-auto:focus{
    z-index: auto !important;
  }

  .xl\:order-1{
    order: 1 !important;
  }

  .xl\:order-2{
    order: 2 !important;
  }

  .xl\:order-3{
    order: 3 !important;
  }

  .xl\:order-4{
    order: 4 !important;
  }

  .xl\:order-5{
    order: 5 !important;
  }

  .xl\:order-6{
    order: 6 !important;
  }

  .xl\:order-7{
    order: 7 !important;
  }

  .xl\:order-8{
    order: 8 !important;
  }

  .xl\:order-9{
    order: 9 !important;
  }

  .xl\:order-10{
    order: 10 !important;
  }

  .xl\:order-11{
    order: 11 !important;
  }

  .xl\:order-12{
    order: 12 !important;
  }

  .xl\:order-first{
    order: -9999 !important;
  }

  .xl\:order-last{
    order: 9999 !important;
  }

  .xl\:order-none{
    order: 0 !important;
  }

  .xl\:col-auto{
    grid-column: auto !important;
  }

  .xl\:col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .xl\:col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .xl\:col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .xl\:col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .xl\:col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .xl\:col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .xl\:col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .xl\:col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .xl\:col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .xl\:col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .xl\:col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .xl\:col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .xl\:col-span-full{
    grid-column: 1 / -1 !important;
  }

  .xl\:col-start-1{
    grid-column-start: 1 !important;
  }

  .xl\:col-start-2{
    grid-column-start: 2 !important;
  }

  .xl\:col-start-3{
    grid-column-start: 3 !important;
  }

  .xl\:col-start-4{
    grid-column-start: 4 !important;
  }

  .xl\:col-start-5{
    grid-column-start: 5 !important;
  }

  .xl\:col-start-6{
    grid-column-start: 6 !important;
  }

  .xl\:col-start-7{
    grid-column-start: 7 !important;
  }

  .xl\:col-start-8{
    grid-column-start: 8 !important;
  }

  .xl\:col-start-9{
    grid-column-start: 9 !important;
  }

  .xl\:col-start-10{
    grid-column-start: 10 !important;
  }

  .xl\:col-start-11{
    grid-column-start: 11 !important;
  }

  .xl\:col-start-12{
    grid-column-start: 12 !important;
  }

  .xl\:col-start-13{
    grid-column-start: 13 !important;
  }

  .xl\:col-start-auto{
    grid-column-start: auto !important;
  }

  .xl\:col-end-1{
    grid-column-end: 1 !important;
  }

  .xl\:col-end-2{
    grid-column-end: 2 !important;
  }

  .xl\:col-end-3{
    grid-column-end: 3 !important;
  }

  .xl\:col-end-4{
    grid-column-end: 4 !important;
  }

  .xl\:col-end-5{
    grid-column-end: 5 !important;
  }

  .xl\:col-end-6{
    grid-column-end: 6 !important;
  }

  .xl\:col-end-7{
    grid-column-end: 7 !important;
  }

  .xl\:col-end-8{
    grid-column-end: 8 !important;
  }

  .xl\:col-end-9{
    grid-column-end: 9 !important;
  }

  .xl\:col-end-10{
    grid-column-end: 10 !important;
  }

  .xl\:col-end-11{
    grid-column-end: 11 !important;
  }

  .xl\:col-end-12{
    grid-column-end: 12 !important;
  }

  .xl\:col-end-13{
    grid-column-end: 13 !important;
  }

  .xl\:col-end-auto{
    grid-column-end: auto !important;
  }

  .xl\:row-auto{
    grid-row: auto !important;
  }

  .xl\:row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .xl\:row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .xl\:row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .xl\:row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .xl\:row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .xl\:row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .xl\:row-span-full{
    grid-row: 1 / -1 !important;
  }

  .xl\:row-start-1{
    grid-row-start: 1 !important;
  }

  .xl\:row-start-2{
    grid-row-start: 2 !important;
  }

  .xl\:row-start-3{
    grid-row-start: 3 !important;
  }

  .xl\:row-start-4{
    grid-row-start: 4 !important;
  }

  .xl\:row-start-5{
    grid-row-start: 5 !important;
  }

  .xl\:row-start-6{
    grid-row-start: 6 !important;
  }

  .xl\:row-start-7{
    grid-row-start: 7 !important;
  }

  .xl\:row-start-auto{
    grid-row-start: auto !important;
  }

  .xl\:row-end-1{
    grid-row-end: 1 !important;
  }

  .xl\:row-end-2{
    grid-row-end: 2 !important;
  }

  .xl\:row-end-3{
    grid-row-end: 3 !important;
  }

  .xl\:row-end-4{
    grid-row-end: 4 !important;
  }

  .xl\:row-end-5{
    grid-row-end: 5 !important;
  }

  .xl\:row-end-6{
    grid-row-end: 6 !important;
  }

  .xl\:row-end-7{
    grid-row-end: 7 !important;
  }

  .xl\:row-end-auto{
    grid-row-end: auto !important;
  }

  .xl\:float-right{
    float: right !important;
  }

  .xl\:float-left{
    float: left !important;
  }

  .xl\:float-none{
    float: none !important;
  }

  .xl\:clear-left{
    clear: left !important;
  }

  .xl\:clear-right{
    clear: right !important;
  }

  .xl\:clear-both{
    clear: both !important;
  }

  .xl\:clear-none{
    clear: none !important;
  }

  .xl\:m-0{
    margin: 0px !important;
  }

  .xl\:m-1{
    margin: 0.25rem !important;
  }

  .xl\:m-2{
    margin: 0.5rem !important;
  }

  .xl\:m-3{
    margin: 0.75rem !important;
  }

  .xl\:m-4{
    margin: 1rem !important;
  }

  .xl\:m-5{
    margin: 1.25rem !important;
  }

  .xl\:m-6{
    margin: 1.5rem !important;
  }

  .xl\:m-7{
    margin: 1.75rem !important;
  }

  .xl\:m-8{
    margin: 2rem !important;
  }

  .xl\:m-9{
    margin: 2.25rem !important;
  }

  .xl\:m-10{
    margin: 2.5rem !important;
  }

  .xl\:m-11{
    margin: 2.75rem !important;
  }

  .xl\:m-12{
    margin: 3rem !important;
  }

  .xl\:m-14{
    margin: 3.5rem !important;
  }

  .xl\:m-16{
    margin: 4rem !important;
  }

  .xl\:m-20{
    margin: 5rem !important;
  }

  .xl\:m-24{
    margin: 6rem !important;
  }

  .xl\:m-28{
    margin: 7rem !important;
  }

  .xl\:m-32{
    margin: 8rem !important;
  }

  .xl\:m-36{
    margin: 9rem !important;
  }

  .xl\:m-40{
    margin: 10rem !important;
  }

  .xl\:m-44{
    margin: 11rem !important;
  }

  .xl\:m-48{
    margin: 12rem !important;
  }

  .xl\:m-52{
    margin: 13rem !important;
  }

  .xl\:m-56{
    margin: 14rem !important;
  }

  .xl\:m-60{
    margin: 15rem !important;
  }

  .xl\:m-64{
    margin: 16rem !important;
  }

  .xl\:m-72{
    margin: 18rem !important;
  }

  .xl\:m-80{
    margin: 20rem !important;
  }

  .xl\:m-96{
    margin: 24rem !important;
  }

  .xl\:m-auto{
    margin: auto !important;
  }

  .xl\:m-px{
    margin: 1px !important;
  }

  .xl\:m-0\.5{
    margin: 0.125rem !important;
  }

  .xl\:m-1\.5{
    margin: 0.375rem !important;
  }

  .xl\:m-2\.5{
    margin: 0.625rem !important;
  }

  .xl\:m-3\.5{
    margin: 0.875rem !important;
  }

  .xl\:-m-0{
    margin: 0px !important;
  }

  .xl\:-m-1{
    margin: -0.25rem !important;
  }

  .xl\:-m-2{
    margin: -0.5rem !important;
  }

  .xl\:-m-3{
    margin: -0.75rem !important;
  }

  .xl\:-m-4{
    margin: -1rem !important;
  }

  .xl\:-m-5{
    margin: -1.25rem !important;
  }

  .xl\:-m-6{
    margin: -1.5rem !important;
  }

  .xl\:-m-7{
    margin: -1.75rem !important;
  }

  .xl\:-m-8{
    margin: -2rem !important;
  }

  .xl\:-m-9{
    margin: -2.25rem !important;
  }

  .xl\:-m-10{
    margin: -2.5rem !important;
  }

  .xl\:-m-11{
    margin: -2.75rem !important;
  }

  .xl\:-m-12{
    margin: -3rem !important;
  }

  .xl\:-m-14{
    margin: -3.5rem !important;
  }

  .xl\:-m-16{
    margin: -4rem !important;
  }

  .xl\:-m-20{
    margin: -5rem !important;
  }

  .xl\:-m-24{
    margin: -6rem !important;
  }

  .xl\:-m-28{
    margin: -7rem !important;
  }

  .xl\:-m-32{
    margin: -8rem !important;
  }

  .xl\:-m-36{
    margin: -9rem !important;
  }

  .xl\:-m-40{
    margin: -10rem !important;
  }

  .xl\:-m-44{
    margin: -11rem !important;
  }

  .xl\:-m-48{
    margin: -12rem !important;
  }

  .xl\:-m-52{
    margin: -13rem !important;
  }

  .xl\:-m-56{
    margin: -14rem !important;
  }

  .xl\:-m-60{
    margin: -15rem !important;
  }

  .xl\:-m-64{
    margin: -16rem !important;
  }

  .xl\:-m-72{
    margin: -18rem !important;
  }

  .xl\:-m-80{
    margin: -20rem !important;
  }

  .xl\:-m-96{
    margin: -24rem !important;
  }

  .xl\:-m-px{
    margin: -1px !important;
  }

  .xl\:-m-0\.5{
    margin: -0.125rem !important;
  }

  .xl\:-m-1\.5{
    margin: -0.375rem !important;
  }

  .xl\:-m-2\.5{
    margin: -0.625rem !important;
  }

  .xl\:-m-3\.5{
    margin: -0.875rem !important;
  }

  .xl\:mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xl\:mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xl\:mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xl\:mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xl\:mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xl\:mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .xl\:mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .xl\:mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xl\:mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .xl\:mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .xl\:mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xl\:mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .xl\:mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xl\:mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .xl\:mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xl\:mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .xl\:mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xl\:mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .xl\:mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xl\:mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .xl\:mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xl\:mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .xl\:mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .xl\:mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .xl\:mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xl\:mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .xl\:mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .xl\:mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .xl\:mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .xl\:-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .xl\:-mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xl\:-mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xl\:-mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xl\:-mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xl\:-mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:-mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .xl\:-mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .xl\:-mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xl\:-mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .xl\:-mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .xl\:-mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:-mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xl\:-mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .xl\:-mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xl\:-mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .xl\:-mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xl\:-mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .xl\:-mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xl\:-mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .xl\:-mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xl\:-mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .xl\:-mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xl\:-mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .xl\:-mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .xl\:-mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .xl\:-mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xl\:-mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .xl\:-mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .xl\:-mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .xl\:-mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .xl\:my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl\:my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xl\:my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xl\:my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xl\:my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xl\:my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .xl\:my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .xl\:my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xl\:my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .xl\:my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .xl\:my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xl\:my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .xl\:my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xl\:my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .xl\:my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xl\:my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .xl\:my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xl\:my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .xl\:my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xl\:my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .xl\:my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xl\:my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .xl\:my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .xl\:my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .xl\:my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xl\:my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xl\:my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .xl\:my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .xl\:my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .xl\:my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .xl\:-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .xl\:-my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xl\:-my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xl\:-my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xl\:-my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:-my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xl\:-my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:-my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .xl\:-my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:-my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .xl\:-my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xl\:-my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .xl\:-my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:-my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .xl\:-my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:-my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:-my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xl\:-my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .xl\:-my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xl\:-my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .xl\:-my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xl\:-my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .xl\:-my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xl\:-my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .xl\:-my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xl\:-my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .xl\:-my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xl\:-my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .xl\:-my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .xl\:-my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .xl\:-my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xl\:-my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .xl\:-my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .xl\:-my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .xl\:-my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .xl\:mt-0{
    margin-top: 0px !important;
  }

  .xl\:mt-1{
    margin-top: 0.25rem !important;
  }

  .xl\:mt-2{
    margin-top: 0.5rem !important;
  }

  .xl\:mt-3{
    margin-top: 0.75rem !important;
  }

  .xl\:mt-4{
    margin-top: 1rem !important;
  }

  .xl\:mt-5{
    margin-top: 1.25rem !important;
  }

  .xl\:mt-6{
    margin-top: 1.5rem !important;
  }

  .xl\:mt-7{
    margin-top: 1.75rem !important;
  }

  .xl\:mt-8{
    margin-top: 2rem !important;
  }

  .xl\:mt-9{
    margin-top: 2.25rem !important;
  }

  .xl\:mt-10{
    margin-top: 2.5rem !important;
  }

  .xl\:mt-11{
    margin-top: 2.75rem !important;
  }

  .xl\:mt-12{
    margin-top: 3rem !important;
  }

  .xl\:mt-14{
    margin-top: 3.5rem !important;
  }

  .xl\:mt-16{
    margin-top: 4rem !important;
  }

  .xl\:mt-20{
    margin-top: 5rem !important;
  }

  .xl\:mt-24{
    margin-top: 6rem !important;
  }

  .xl\:mt-28{
    margin-top: 7rem !important;
  }

  .xl\:mt-32{
    margin-top: 8rem !important;
  }

  .xl\:mt-36{
    margin-top: 9rem !important;
  }

  .xl\:mt-40{
    margin-top: 10rem !important;
  }

  .xl\:mt-44{
    margin-top: 11rem !important;
  }

  .xl\:mt-48{
    margin-top: 12rem !important;
  }

  .xl\:mt-52{
    margin-top: 13rem !important;
  }

  .xl\:mt-56{
    margin-top: 14rem !important;
  }

  .xl\:mt-60{
    margin-top: 15rem !important;
  }

  .xl\:mt-64{
    margin-top: 16rem !important;
  }

  .xl\:mt-72{
    margin-top: 18rem !important;
  }

  .xl\:mt-80{
    margin-top: 20rem !important;
  }

  .xl\:mt-96{
    margin-top: 24rem !important;
  }

  .xl\:mt-auto{
    margin-top: auto !important;
  }

  .xl\:mt-px{
    margin-top: 1px !important;
  }

  .xl\:mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .xl\:mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .xl\:mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .xl\:mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .xl\:-mt-0{
    margin-top: 0px !important;
  }

  .xl\:-mt-1{
    margin-top: -0.25rem !important;
  }

  .xl\:-mt-2{
    margin-top: -0.5rem !important;
  }

  .xl\:-mt-3{
    margin-top: -0.75rem !important;
  }

  .xl\:-mt-4{
    margin-top: -1rem !important;
  }

  .xl\:-mt-5{
    margin-top: -1.25rem !important;
  }

  .xl\:-mt-6{
    margin-top: -1.5rem !important;
  }

  .xl\:-mt-7{
    margin-top: -1.75rem !important;
  }

  .xl\:-mt-8{
    margin-top: -2rem !important;
  }

  .xl\:-mt-9{
    margin-top: -2.25rem !important;
  }

  .xl\:-mt-10{
    margin-top: -2.5rem !important;
  }

  .xl\:-mt-11{
    margin-top: -2.75rem !important;
  }

  .xl\:-mt-12{
    margin-top: -3rem !important;
  }

  .xl\:-mt-14{
    margin-top: -3.5rem !important;
  }

  .xl\:-mt-16{
    margin-top: -4rem !important;
  }

  .xl\:-mt-20{
    margin-top: -5rem !important;
  }

  .xl\:-mt-24{
    margin-top: -6rem !important;
  }

  .xl\:-mt-28{
    margin-top: -7rem !important;
  }

  .xl\:-mt-32{
    margin-top: -8rem !important;
  }

  .xl\:-mt-36{
    margin-top: -9rem !important;
  }

  .xl\:-mt-40{
    margin-top: -10rem !important;
  }

  .xl\:-mt-44{
    margin-top: -11rem !important;
  }

  .xl\:-mt-48{
    margin-top: -12rem !important;
  }

  .xl\:-mt-52{
    margin-top: -13rem !important;
  }

  .xl\:-mt-56{
    margin-top: -14rem !important;
  }

  .xl\:-mt-60{
    margin-top: -15rem !important;
  }

  .xl\:-mt-64{
    margin-top: -16rem !important;
  }

  .xl\:-mt-72{
    margin-top: -18rem !important;
  }

  .xl\:-mt-80{
    margin-top: -20rem !important;
  }

  .xl\:-mt-96{
    margin-top: -24rem !important;
  }

  .xl\:-mt-px{
    margin-top: -1px !important;
  }

  .xl\:-mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .xl\:-mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .xl\:-mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .xl\:-mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .xl\:mr-0{
    margin-right: 0px !important;
  }

  .xl\:mr-1{
    margin-right: 0.25rem !important;
  }

  .xl\:mr-2{
    margin-right: 0.5rem !important;
  }

  .xl\:mr-3{
    margin-right: 0.75rem !important;
  }

  .xl\:mr-4{
    margin-right: 1rem !important;
  }

  .xl\:mr-5{
    margin-right: 1.25rem !important;
  }

  .xl\:mr-6{
    margin-right: 1.5rem !important;
  }

  .xl\:mr-7{
    margin-right: 1.75rem !important;
  }

  .xl\:mr-8{
    margin-right: 2rem !important;
  }

  .xl\:mr-9{
    margin-right: 2.25rem !important;
  }

  .xl\:mr-10{
    margin-right: 2.5rem !important;
  }

  .xl\:mr-11{
    margin-right: 2.75rem !important;
  }

  .xl\:mr-12{
    margin-right: 3rem !important;
  }

  .xl\:mr-14{
    margin-right: 3.5rem !important;
  }

  .xl\:mr-16{
    margin-right: 4rem !important;
  }

  .xl\:mr-20{
    margin-right: 5rem !important;
  }

  .xl\:mr-24{
    margin-right: 6rem !important;
  }

  .xl\:mr-28{
    margin-right: 7rem !important;
  }

  .xl\:mr-32{
    margin-right: 8rem !important;
  }

  .xl\:mr-36{
    margin-right: 9rem !important;
  }

  .xl\:mr-40{
    margin-right: 10rem !important;
  }

  .xl\:mr-44{
    margin-right: 11rem !important;
  }

  .xl\:mr-48{
    margin-right: 12rem !important;
  }

  .xl\:mr-52{
    margin-right: 13rem !important;
  }

  .xl\:mr-56{
    margin-right: 14rem !important;
  }

  .xl\:mr-60{
    margin-right: 15rem !important;
  }

  .xl\:mr-64{
    margin-right: 16rem !important;
  }

  .xl\:mr-72{
    margin-right: 18rem !important;
  }

  .xl\:mr-80{
    margin-right: 20rem !important;
  }

  .xl\:mr-96{
    margin-right: 24rem !important;
  }

  .xl\:mr-auto{
    margin-right: auto !important;
  }

  .xl\:mr-px{
    margin-right: 1px !important;
  }

  .xl\:mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .xl\:mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .xl\:mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .xl\:mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .xl\:-mr-0{
    margin-right: 0px !important;
  }

  .xl\:-mr-1{
    margin-right: -0.25rem !important;
  }

  .xl\:-mr-2{
    margin-right: -0.5rem !important;
  }

  .xl\:-mr-3{
    margin-right: -0.75rem !important;
  }

  .xl\:-mr-4{
    margin-right: -1rem !important;
  }

  .xl\:-mr-5{
    margin-right: -1.25rem !important;
  }

  .xl\:-mr-6{
    margin-right: -1.5rem !important;
  }

  .xl\:-mr-7{
    margin-right: -1.75rem !important;
  }

  .xl\:-mr-8{
    margin-right: -2rem !important;
  }

  .xl\:-mr-9{
    margin-right: -2.25rem !important;
  }

  .xl\:-mr-10{
    margin-right: -2.5rem !important;
  }

  .xl\:-mr-11{
    margin-right: -2.75rem !important;
  }

  .xl\:-mr-12{
    margin-right: -3rem !important;
  }

  .xl\:-mr-14{
    margin-right: -3.5rem !important;
  }

  .xl\:-mr-16{
    margin-right: -4rem !important;
  }

  .xl\:-mr-20{
    margin-right: -5rem !important;
  }

  .xl\:-mr-24{
    margin-right: -6rem !important;
  }

  .xl\:-mr-28{
    margin-right: -7rem !important;
  }

  .xl\:-mr-32{
    margin-right: -8rem !important;
  }

  .xl\:-mr-36{
    margin-right: -9rem !important;
  }

  .xl\:-mr-40{
    margin-right: -10rem !important;
  }

  .xl\:-mr-44{
    margin-right: -11rem !important;
  }

  .xl\:-mr-48{
    margin-right: -12rem !important;
  }

  .xl\:-mr-52{
    margin-right: -13rem !important;
  }

  .xl\:-mr-56{
    margin-right: -14rem !important;
  }

  .xl\:-mr-60{
    margin-right: -15rem !important;
  }

  .xl\:-mr-64{
    margin-right: -16rem !important;
  }

  .xl\:-mr-72{
    margin-right: -18rem !important;
  }

  .xl\:-mr-80{
    margin-right: -20rem !important;
  }

  .xl\:-mr-96{
    margin-right: -24rem !important;
  }

  .xl\:-mr-px{
    margin-right: -1px !important;
  }

  .xl\:-mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .xl\:-mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .xl\:-mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .xl\:-mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .xl\:mb-0{
    margin-bottom: 0px !important;
  }

  .xl\:mb-1{
    margin-bottom: 0.25rem !important;
  }

  .xl\:mb-2{
    margin-bottom: 0.5rem !important;
  }

  .xl\:mb-3{
    margin-bottom: 0.75rem !important;
  }

  .xl\:mb-4{
    margin-bottom: 1rem !important;
  }

  .xl\:mb-5{
    margin-bottom: 1.25rem !important;
  }

  .xl\:mb-6{
    margin-bottom: 1.5rem !important;
  }

  .xl\:mb-7{
    margin-bottom: 1.75rem !important;
  }

  .xl\:mb-8{
    margin-bottom: 2rem !important;
  }

  .xl\:mb-9{
    margin-bottom: 2.25rem !important;
  }

  .xl\:mb-10{
    margin-bottom: 2.5rem !important;
  }

  .xl\:mb-11{
    margin-bottom: 2.75rem !important;
  }

  .xl\:mb-12{
    margin-bottom: 3rem !important;
  }

  .xl\:mb-14{
    margin-bottom: 3.5rem !important;
  }

  .xl\:mb-16{
    margin-bottom: 4rem !important;
  }

  .xl\:mb-20{
    margin-bottom: 5rem !important;
  }

  .xl\:mb-24{
    margin-bottom: 6rem !important;
  }

  .xl\:mb-28{
    margin-bottom: 7rem !important;
  }

  .xl\:mb-32{
    margin-bottom: 8rem !important;
  }

  .xl\:mb-36{
    margin-bottom: 9rem !important;
  }

  .xl\:mb-40{
    margin-bottom: 10rem !important;
  }

  .xl\:mb-44{
    margin-bottom: 11rem !important;
  }

  .xl\:mb-48{
    margin-bottom: 12rem !important;
  }

  .xl\:mb-52{
    margin-bottom: 13rem !important;
  }

  .xl\:mb-56{
    margin-bottom: 14rem !important;
  }

  .xl\:mb-60{
    margin-bottom: 15rem !important;
  }

  .xl\:mb-64{
    margin-bottom: 16rem !important;
  }

  .xl\:mb-72{
    margin-bottom: 18rem !important;
  }

  .xl\:mb-80{
    margin-bottom: 20rem !important;
  }

  .xl\:mb-96{
    margin-bottom: 24rem !important;
  }

  .xl\:mb-auto{
    margin-bottom: auto !important;
  }

  .xl\:mb-px{
    margin-bottom: 1px !important;
  }

  .xl\:mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .xl\:mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .xl\:mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .xl\:mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .xl\:-mb-0{
    margin-bottom: 0px !important;
  }

  .xl\:-mb-1{
    margin-bottom: -0.25rem !important;
  }

  .xl\:-mb-2{
    margin-bottom: -0.5rem !important;
  }

  .xl\:-mb-3{
    margin-bottom: -0.75rem !important;
  }

  .xl\:-mb-4{
    margin-bottom: -1rem !important;
  }

  .xl\:-mb-5{
    margin-bottom: -1.25rem !important;
  }

  .xl\:-mb-6{
    margin-bottom: -1.5rem !important;
  }

  .xl\:-mb-7{
    margin-bottom: -1.75rem !important;
  }

  .xl\:-mb-8{
    margin-bottom: -2rem !important;
  }

  .xl\:-mb-9{
    margin-bottom: -2.25rem !important;
  }

  .xl\:-mb-10{
    margin-bottom: -2.5rem !important;
  }

  .xl\:-mb-11{
    margin-bottom: -2.75rem !important;
  }

  .xl\:-mb-12{
    margin-bottom: -3rem !important;
  }

  .xl\:-mb-14{
    margin-bottom: -3.5rem !important;
  }

  .xl\:-mb-16{
    margin-bottom: -4rem !important;
  }

  .xl\:-mb-20{
    margin-bottom: -5rem !important;
  }

  .xl\:-mb-24{
    margin-bottom: -6rem !important;
  }

  .xl\:-mb-28{
    margin-bottom: -7rem !important;
  }

  .xl\:-mb-32{
    margin-bottom: -8rem !important;
  }

  .xl\:-mb-36{
    margin-bottom: -9rem !important;
  }

  .xl\:-mb-40{
    margin-bottom: -10rem !important;
  }

  .xl\:-mb-44{
    margin-bottom: -11rem !important;
  }

  .xl\:-mb-48{
    margin-bottom: -12rem !important;
  }

  .xl\:-mb-52{
    margin-bottom: -13rem !important;
  }

  .xl\:-mb-56{
    margin-bottom: -14rem !important;
  }

  .xl\:-mb-60{
    margin-bottom: -15rem !important;
  }

  .xl\:-mb-64{
    margin-bottom: -16rem !important;
  }

  .xl\:-mb-72{
    margin-bottom: -18rem !important;
  }

  .xl\:-mb-80{
    margin-bottom: -20rem !important;
  }

  .xl\:-mb-96{
    margin-bottom: -24rem !important;
  }

  .xl\:-mb-px{
    margin-bottom: -1px !important;
  }

  .xl\:-mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .xl\:-mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .xl\:-mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .xl\:-mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .xl\:ml-0{
    margin-left: 0px !important;
  }

  .xl\:ml-1{
    margin-left: 0.25rem !important;
  }

  .xl\:ml-2{
    margin-left: 0.5rem !important;
  }

  .xl\:ml-3{
    margin-left: 0.75rem !important;
  }

  .xl\:ml-4{
    margin-left: 1rem !important;
  }

  .xl\:ml-5{
    margin-left: 1.25rem !important;
  }

  .xl\:ml-6{
    margin-left: 1.5rem !important;
  }

  .xl\:ml-7{
    margin-left: 1.75rem !important;
  }

  .xl\:ml-8{
    margin-left: 2rem !important;
  }

  .xl\:ml-9{
    margin-left: 2.25rem !important;
  }

  .xl\:ml-10{
    margin-left: 2.5rem !important;
  }

  .xl\:ml-11{
    margin-left: 2.75rem !important;
  }

  .xl\:ml-12{
    margin-left: 3rem !important;
  }

  .xl\:ml-14{
    margin-left: 3.5rem !important;
  }

  .xl\:ml-16{
    margin-left: 4rem !important;
  }

  .xl\:ml-20{
    margin-left: 5rem !important;
  }

  .xl\:ml-24{
    margin-left: 6rem !important;
  }

  .xl\:ml-28{
    margin-left: 7rem !important;
  }

  .xl\:ml-32{
    margin-left: 8rem !important;
  }

  .xl\:ml-36{
    margin-left: 9rem !important;
  }

  .xl\:ml-40{
    margin-left: 10rem !important;
  }

  .xl\:ml-44{
    margin-left: 11rem !important;
  }

  .xl\:ml-48{
    margin-left: 12rem !important;
  }

  .xl\:ml-52{
    margin-left: 13rem !important;
  }

  .xl\:ml-56{
    margin-left: 14rem !important;
  }

  .xl\:ml-60{
    margin-left: 15rem !important;
  }

  .xl\:ml-64{
    margin-left: 16rem !important;
  }

  .xl\:ml-72{
    margin-left: 18rem !important;
  }

  .xl\:ml-80{
    margin-left: 20rem !important;
  }

  .xl\:ml-96{
    margin-left: 24rem !important;
  }

  .xl\:ml-auto{
    margin-left: auto !important;
  }

  .xl\:ml-px{
    margin-left: 1px !important;
  }

  .xl\:ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .xl\:ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .xl\:ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .xl\:ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .xl\:-ml-0{
    margin-left: 0px !important;
  }

  .xl\:-ml-1{
    margin-left: -0.25rem !important;
  }

  .xl\:-ml-2{
    margin-left: -0.5rem !important;
  }

  .xl\:-ml-3{
    margin-left: -0.75rem !important;
  }

  .xl\:-ml-4{
    margin-left: -1rem !important;
  }

  .xl\:-ml-5{
    margin-left: -1.25rem !important;
  }

  .xl\:-ml-6{
    margin-left: -1.5rem !important;
  }

  .xl\:-ml-7{
    margin-left: -1.75rem !important;
  }

  .xl\:-ml-8{
    margin-left: -2rem !important;
  }

  .xl\:-ml-9{
    margin-left: -2.25rem !important;
  }

  .xl\:-ml-10{
    margin-left: -2.5rem !important;
  }

  .xl\:-ml-11{
    margin-left: -2.75rem !important;
  }

  .xl\:-ml-12{
    margin-left: -3rem !important;
  }

  .xl\:-ml-14{
    margin-left: -3.5rem !important;
  }

  .xl\:-ml-16{
    margin-left: -4rem !important;
  }

  .xl\:-ml-20{
    margin-left: -5rem !important;
  }

  .xl\:-ml-24{
    margin-left: -6rem !important;
  }

  .xl\:-ml-28{
    margin-left: -7rem !important;
  }

  .xl\:-ml-32{
    margin-left: -8rem !important;
  }

  .xl\:-ml-36{
    margin-left: -9rem !important;
  }

  .xl\:-ml-40{
    margin-left: -10rem !important;
  }

  .xl\:-ml-44{
    margin-left: -11rem !important;
  }

  .xl\:-ml-48{
    margin-left: -12rem !important;
  }

  .xl\:-ml-52{
    margin-left: -13rem !important;
  }

  .xl\:-ml-56{
    margin-left: -14rem !important;
  }

  .xl\:-ml-60{
    margin-left: -15rem !important;
  }

  .xl\:-ml-64{
    margin-left: -16rem !important;
  }

  .xl\:-ml-72{
    margin-left: -18rem !important;
  }

  .xl\:-ml-80{
    margin-left: -20rem !important;
  }

  .xl\:-ml-96{
    margin-left: -24rem !important;
  }

  .xl\:-ml-px{
    margin-left: -1px !important;
  }

  .xl\:-ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .xl\:-ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .xl\:-ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .xl\:-ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .xl\:box-border{
    box-sizing: border-box !important;
  }

  .xl\:box-content{
    box-sizing: content-box !important;
  }

  .xl\:block{
    display: block !important;
  }

  .xl\:inline-block{
    display: inline-block !important;
  }

  .xl\:inline{
    display: inline !important;
  }

  .xl\:flex{
    display: flex !important;
  }

  .xl\:inline-flex{
    display: inline-flex !important;
  }

  .xl\:table{
    display: table !important;
  }

  .xl\:inline-table{
    display: inline-table !important;
  }

  .xl\:table-caption{
    display: table-caption !important;
  }

  .xl\:table-cell{
    display: table-cell !important;
  }

  .xl\:table-column{
    display: table-column !important;
  }

  .xl\:table-column-group{
    display: table-column-group !important;
  }

  .xl\:table-footer-group{
    display: table-footer-group !important;
  }

  .xl\:table-header-group{
    display: table-header-group !important;
  }

  .xl\:table-row-group{
    display: table-row-group !important;
  }

  .xl\:table-row{
    display: table-row !important;
  }

  .xl\:flow-root{
    display: flow-root !important;
  }

  .xl\:grid{
    display: grid !important;
  }

  .xl\:inline-grid{
    display: inline-grid !important;
  }

  .xl\:contents{
    display: contents !important;
  }

  .xl\:list-item{
    display: list-item !important;
  }

  .xl\:hidden{
    display: none !important;
  }

  .xl\:h-0{
    height: 0px !important;
  }

  .xl\:h-1{
    height: 0.25rem !important;
  }

  .xl\:h-2{
    height: 0.5rem !important;
  }

  .xl\:h-3{
    height: 0.75rem !important;
  }

  .xl\:h-4{
    height: 1rem !important;
  }

  .xl\:h-5{
    height: 1.25rem !important;
  }

  .xl\:h-6{
    height: 1.5rem !important;
  }

  .xl\:h-7{
    height: 1.75rem !important;
  }

  .xl\:h-8{
    height: 2rem !important;
  }

  .xl\:h-9{
    height: 2.25rem !important;
  }

  .xl\:h-10{
    height: 2.5rem !important;
  }

  .xl\:h-11{
    height: 2.75rem !important;
  }

  .xl\:h-12{
    height: 3rem !important;
  }

  .xl\:h-14{
    height: 3.5rem !important;
  }

  .xl\:h-16{
    height: 4rem !important;
  }

  .xl\:h-20{
    height: 5rem !important;
  }

  .xl\:h-24{
    height: 6rem !important;
  }

  .xl\:h-28{
    height: 7rem !important;
  }

  .xl\:h-32{
    height: 8rem !important;
  }

  .xl\:h-36{
    height: 9rem !important;
  }

  .xl\:h-40{
    height: 10rem !important;
  }

  .xl\:h-44{
    height: 11rem !important;
  }

  .xl\:h-48{
    height: 12rem !important;
  }

  .xl\:h-52{
    height: 13rem !important;
  }

  .xl\:h-56{
    height: 14rem !important;
  }

  .xl\:h-60{
    height: 15rem !important;
  }

  .xl\:h-64{
    height: 16rem !important;
  }

  .xl\:h-72{
    height: 18rem !important;
  }

  .xl\:h-80{
    height: 20rem !important;
  }

  .xl\:h-96{
    height: 24rem !important;
  }

  .xl\:h-auto{
    height: auto !important;
  }

  .xl\:h-px{
    height: 1px !important;
  }

  .xl\:h-0\.5{
    height: 0.125rem !important;
  }

  .xl\:h-1\.5{
    height: 0.375rem !important;
  }

  .xl\:h-2\.5{
    height: 0.625rem !important;
  }

  .xl\:h-3\.5{
    height: 0.875rem !important;
  }

  .xl\:h-1\/2{
    height: 50% !important;
  }

  .xl\:h-1\/3{
    height: 33.333333% !important;
  }

  .xl\:h-2\/3{
    height: 66.666667% !important;
  }

  .xl\:h-1\/4{
    height: 25% !important;
  }

  .xl\:h-2\/4{
    height: 50% !important;
  }

  .xl\:h-3\/4{
    height: 75% !important;
  }

  .xl\:h-1\/5{
    height: 20% !important;
  }

  .xl\:h-2\/5{
    height: 40% !important;
  }

  .xl\:h-3\/5{
    height: 60% !important;
  }

  .xl\:h-4\/5{
    height: 80% !important;
  }

  .xl\:h-1\/6{
    height: 16.666667% !important;
  }

  .xl\:h-2\/6{
    height: 33.333333% !important;
  }

  .xl\:h-3\/6{
    height: 50% !important;
  }

  .xl\:h-4\/6{
    height: 66.666667% !important;
  }

  .xl\:h-5\/6{
    height: 83.333333% !important;
  }

  .xl\:h-full{
    height: 100% !important;
  }

  .xl\:h-screen{
    height: 100vh !important;
  }

  .xl\:max-h-0{
    max-height: 0px !important;
  }

  .xl\:max-h-1{
    max-height: 0.25rem !important;
  }

  .xl\:max-h-2{
    max-height: 0.5rem !important;
  }

  .xl\:max-h-3{
    max-height: 0.75rem !important;
  }

  .xl\:max-h-4{
    max-height: 1rem !important;
  }

  .xl\:max-h-5{
    max-height: 1.25rem !important;
  }

  .xl\:max-h-6{
    max-height: 1.5rem !important;
  }

  .xl\:max-h-7{
    max-height: 1.75rem !important;
  }

  .xl\:max-h-8{
    max-height: 2rem !important;
  }

  .xl\:max-h-9{
    max-height: 2.25rem !important;
  }

  .xl\:max-h-10{
    max-height: 2.5rem !important;
  }

  .xl\:max-h-11{
    max-height: 2.75rem !important;
  }

  .xl\:max-h-12{
    max-height: 3rem !important;
  }

  .xl\:max-h-14{
    max-height: 3.5rem !important;
  }

  .xl\:max-h-16{
    max-height: 4rem !important;
  }

  .xl\:max-h-20{
    max-height: 5rem !important;
  }

  .xl\:max-h-24{
    max-height: 6rem !important;
  }

  .xl\:max-h-28{
    max-height: 7rem !important;
  }

  .xl\:max-h-32{
    max-height: 8rem !important;
  }

  .xl\:max-h-36{
    max-height: 9rem !important;
  }

  .xl\:max-h-40{
    max-height: 10rem !important;
  }

  .xl\:max-h-44{
    max-height: 11rem !important;
  }

  .xl\:max-h-48{
    max-height: 12rem !important;
  }

  .xl\:max-h-52{
    max-height: 13rem !important;
  }

  .xl\:max-h-56{
    max-height: 14rem !important;
  }

  .xl\:max-h-60{
    max-height: 15rem !important;
  }

  .xl\:max-h-64{
    max-height: 16rem !important;
  }

  .xl\:max-h-72{
    max-height: 18rem !important;
  }

  .xl\:max-h-80{
    max-height: 20rem !important;
  }

  .xl\:max-h-96{
    max-height: 24rem !important;
  }

  .xl\:max-h-px{
    max-height: 1px !important;
  }

  .xl\:max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .xl\:max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .xl\:max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .xl\:max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .xl\:max-h-full{
    max-height: 100% !important;
  }

  .xl\:max-h-screen{
    max-height: 100vh !important;
  }

  .xl\:min-h-0{
    min-height: 0px !important;
  }

  .xl\:min-h-full{
    min-height: 100% !important;
  }

  .xl\:min-h-screen{
    min-height: 100vh !important;
  }

  .xl\:w-0{
    width: 0px !important;
  }

  .xl\:w-1{
    width: 0.25rem !important;
  }

  .xl\:w-2{
    width: 0.5rem !important;
  }

  .xl\:w-3{
    width: 0.75rem !important;
  }

  .xl\:w-4{
    width: 1rem !important;
  }

  .xl\:w-5{
    width: 1.25rem !important;
  }

  .xl\:w-6{
    width: 1.5rem !important;
  }

  .xl\:w-7{
    width: 1.75rem !important;
  }

  .xl\:w-8{
    width: 2rem !important;
  }

  .xl\:w-9{
    width: 2.25rem !important;
  }

  .xl\:w-10{
    width: 2.5rem !important;
  }

  .xl\:w-11{
    width: 2.75rem !important;
  }

  .xl\:w-12{
    width: 3rem !important;
  }

  .xl\:w-14{
    width: 3.5rem !important;
  }

  .xl\:w-16{
    width: 4rem !important;
  }

  .xl\:w-20{
    width: 5rem !important;
  }

  .xl\:w-24{
    width: 6rem !important;
  }

  .xl\:w-28{
    width: 7rem !important;
  }

  .xl\:w-32{
    width: 8rem !important;
  }

  .xl\:w-36{
    width: 9rem !important;
  }

  .xl\:w-40{
    width: 10rem !important;
  }

  .xl\:w-44{
    width: 11rem !important;
  }

  .xl\:w-48{
    width: 12rem !important;
  }

  .xl\:w-52{
    width: 13rem !important;
  }

  .xl\:w-56{
    width: 14rem !important;
  }

  .xl\:w-60{
    width: 15rem !important;
  }

  .xl\:w-64{
    width: 16rem !important;
  }

  .xl\:w-72{
    width: 18rem !important;
  }

  .xl\:w-80{
    width: 20rem !important;
  }

  .xl\:w-96{
    width: 24rem !important;
  }

  .xl\:w-auto{
    width: auto !important;
  }

  .xl\:w-px{
    width: 1px !important;
  }

  .xl\:w-0\.5{
    width: 0.125rem !important;
  }

  .xl\:w-1\.5{
    width: 0.375rem !important;
  }

  .xl\:w-2\.5{
    width: 0.625rem !important;
  }

  .xl\:w-3\.5{
    width: 0.875rem !important;
  }

  .xl\:w-1\/2{
    width: 50% !important;
  }

  .xl\:w-1\/3{
    width: 33.333333% !important;
  }

  .xl\:w-2\/3{
    width: 66.666667% !important;
  }

  .xl\:w-1\/4{
    width: 25% !important;
  }

  .xl\:w-2\/4{
    width: 50% !important;
  }

  .xl\:w-3\/4{
    width: 75% !important;
  }

  .xl\:w-1\/5{
    width: 20% !important;
  }

  .xl\:w-2\/5{
    width: 40% !important;
  }

  .xl\:w-3\/5{
    width: 60% !important;
  }

  .xl\:w-4\/5{
    width: 80% !important;
  }

  .xl\:w-1\/6{
    width: 16.666667% !important;
  }

  .xl\:w-2\/6{
    width: 33.333333% !important;
  }

  .xl\:w-3\/6{
    width: 50% !important;
  }

  .xl\:w-4\/6{
    width: 66.666667% !important;
  }

  .xl\:w-5\/6{
    width: 83.333333% !important;
  }

  .xl\:w-1\/12{
    width: 8.333333% !important;
  }

  .xl\:w-2\/12{
    width: 16.666667% !important;
  }

  .xl\:w-3\/12{
    width: 25% !important;
  }

  .xl\:w-4\/12{
    width: 33.333333% !important;
  }

  .xl\:w-5\/12{
    width: 41.666667% !important;
  }

  .xl\:w-6\/12{
    width: 50% !important;
  }

  .xl\:w-7\/12{
    width: 58.333333% !important;
  }

  .xl\:w-8\/12{
    width: 66.666667% !important;
  }

  .xl\:w-9\/12{
    width: 75% !important;
  }

  .xl\:w-10\/12{
    width: 83.333333% !important;
  }

  .xl\:w-11\/12{
    width: 91.666667% !important;
  }

  .xl\:w-full{
    width: 100% !important;
  }

  .xl\:w-screen{
    width: 100vw !important;
  }

  .xl\:w-min{
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .xl\:w-max{
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .xl\:min-w-0{
    min-width: 0px !important;
  }

  .xl\:min-w-full{
    min-width: 100% !important;
  }

  .xl\:min-w-min{
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important;
  }

  .xl\:min-w-max{
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
  }

  .xl\:max-w-0{
    max-width: 0rem !important;
  }

  .xl\:max-w-none{
    max-width: none !important;
  }

  .xl\:max-w-xs{
    max-width: 20rem !important;
  }

  .xl\:max-w-sm{
    max-width: 24rem !important;
  }

  .xl\:max-w-md{
    max-width: 28rem !important;
  }

  .xl\:max-w-lg{
    max-width: 32rem !important;
  }

  .xl\:max-w-xl{
    max-width: 36rem !important;
  }

  .xl\:max-w-2xl{
    max-width: 42rem !important;
  }

  .xl\:max-w-3xl{
    max-width: 48rem !important;
  }

  .xl\:max-w-4xl{
    max-width: 56rem !important;
  }

  .xl\:max-w-5xl{
    max-width: 64rem !important;
  }

  .xl\:max-w-6xl{
    max-width: 72rem !important;
  }

  .xl\:max-w-7xl{
    max-width: 80rem !important;
  }

  .xl\:max-w-full{
    max-width: 100% !important;
  }

  .xl\:max-w-min{
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .xl\:max-w-max{
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .xl\:max-w-prose{
    max-width: 65ch !important;
  }

  .xl\:max-w-screen-sm{
    max-width: 640px !important;
  }

  .xl\:max-w-screen-md{
    max-width: 768px !important;
  }

  .xl\:max-w-screen-lg{
    max-width: 1024px !important;
  }

  .xl\:max-w-screen-xl{
    max-width: 1280px !important;
  }

  .xl\:max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .xl\:flex-1{
    flex: 1 1 0% !important;
  }

  .xl\:flex-auto{
    flex: 1 1 auto !important;
  }

  .xl\:flex-initial{
    flex: 0 1 auto !important;
  }

  .xl\:flex-none{
    flex: none !important;
  }

  .xl\:flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .xl\:flex-shrink{
    flex-shrink: 1 !important;
  }

  .xl\:flex-grow-0{
    flex-grow: 0 !important;
  }

  .xl\:flex-grow{
    flex-grow: 1 !important;
  }

  .xl\:table-auto{
    table-layout: auto !important;
  }

  .xl\:table-fixed{
    table-layout: fixed !important;
  }

  .xl\:border-collapse{
    border-collapse: collapse !important;
  }

  .xl\:border-separate{
    border-collapse: separate !important;
  }

  .xl\:origin-center{
    transform-origin: center !important;
  }

  .xl\:origin-top{
    transform-origin: top !important;
  }

  .xl\:origin-top-right{
    transform-origin: top right !important;
  }

  .xl\:origin-right{
    transform-origin: right !important;
  }

  .xl\:origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .xl\:origin-bottom{
    transform-origin: bottom !important;
  }

  .xl\:origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .xl\:origin-left{
    transform-origin: left !important;
  }

  .xl\:origin-top-left{
    transform-origin: top left !important;
  }

  .xl\:transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .xl\:transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .xl\:transform-none{
    transform: none !important;
  }

  .xl\:translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .xl\:translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .xl\:translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .xl\:translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .xl\:translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .xl\:translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .xl\:translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .xl\:translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .xl\:translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .xl\:translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .xl\:translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .xl\:translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .xl\:translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .xl\:translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .xl\:translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .xl\:translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .xl\:translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .xl\:translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .xl\:translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .xl\:translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .xl\:translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .xl\:translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .xl\:translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .xl\:translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .xl\:translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .xl\:translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .xl\:translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .xl\:translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .xl\:translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .xl\:translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .xl\:translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .xl\:translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .xl\:translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .xl\:translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .xl\:translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .xl\:-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .xl\:-translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .xl\:-translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .xl\:-translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .xl\:-translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .xl\:-translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .xl\:-translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .xl\:-translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .xl\:-translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .xl\:-translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .xl\:-translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .xl\:-translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .xl\:-translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .xl\:-translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .xl\:-translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .xl\:-translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .xl\:-translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .xl\:-translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .xl\:-translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .xl\:-translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .xl\:-translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .xl\:-translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .xl\:-translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .xl\:-translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .xl\:-translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .xl\:-translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .xl\:-translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .xl\:-translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .xl\:-translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .xl\:-translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .xl\:-translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .xl\:-translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .xl\:-translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .xl\:-translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .xl\:-translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .xl\:translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .xl\:translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .xl\:translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .xl\:translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .xl\:translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .xl\:translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .xl\:translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .xl\:-translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .xl\:-translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .xl\:-translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .xl\:-translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .xl\:-translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .xl\:-translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .xl\:-translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .xl\:translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .xl\:translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .xl\:translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .xl\:translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .xl\:translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .xl\:translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .xl\:translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .xl\:translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .xl\:translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .xl\:translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .xl\:translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .xl\:translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .xl\:translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .xl\:translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .xl\:translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .xl\:translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .xl\:translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .xl\:translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .xl\:translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .xl\:translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .xl\:translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .xl\:translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .xl\:translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .xl\:translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .xl\:translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .xl\:translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .xl\:translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .xl\:translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .xl\:translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .xl\:translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .xl\:translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .xl\:translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .xl\:translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .xl\:translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .xl\:translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .xl\:-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .xl\:-translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .xl\:-translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .xl\:-translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .xl\:-translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .xl\:-translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .xl\:-translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .xl\:-translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .xl\:-translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .xl\:-translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .xl\:-translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .xl\:-translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .xl\:-translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .xl\:-translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .xl\:-translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .xl\:-translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .xl\:-translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .xl\:-translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .xl\:-translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .xl\:-translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .xl\:-translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .xl\:-translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .xl\:-translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .xl\:-translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .xl\:-translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .xl\:-translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .xl\:-translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .xl\:-translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .xl\:-translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .xl\:-translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .xl\:-translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .xl\:-translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .xl\:-translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .xl\:-translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .xl\:-translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .xl\:translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .xl\:translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .xl\:translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .xl\:translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .xl\:translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .xl\:translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .xl\:translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .xl\:-translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .xl\:-translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .xl\:-translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .xl\:-translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .xl\:-translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .xl\:-translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .xl\:-translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .xl\:hover\:translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .xl\:hover\:translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .xl\:hover\:translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .xl\:hover\:translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .xl\:hover\:translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .xl\:hover\:translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .xl\:hover\:translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .xl\:hover\:translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .xl\:hover\:translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .xl\:hover\:translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .xl\:hover\:translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .xl\:hover\:translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .xl\:hover\:translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .xl\:hover\:translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .xl\:hover\:translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .xl\:hover\:translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .xl\:hover\:translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .xl\:hover\:translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .xl\:hover\:translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .xl\:hover\:translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .xl\:hover\:translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .xl\:hover\:translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .xl\:hover\:translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .xl\:hover\:translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .xl\:hover\:translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .xl\:hover\:translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .xl\:hover\:translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .xl\:hover\:translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .xl\:hover\:translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .xl\:hover\:translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .xl\:hover\:translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .xl\:hover\:translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .xl\:hover\:translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .xl\:hover\:translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .xl\:hover\:translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .xl\:hover\:-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .xl\:hover\:-translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .xl\:hover\:-translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .xl\:hover\:-translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .xl\:hover\:-translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .xl\:hover\:-translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .xl\:hover\:-translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .xl\:hover\:-translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .xl\:hover\:-translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .xl\:hover\:-translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .xl\:hover\:-translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .xl\:hover\:-translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .xl\:hover\:-translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .xl\:hover\:-translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .xl\:hover\:-translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .xl\:hover\:-translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .xl\:hover\:-translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .xl\:hover\:-translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .xl\:hover\:-translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .xl\:hover\:-translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .xl\:hover\:-translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .xl\:hover\:-translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .xl\:hover\:-translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .xl\:hover\:-translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .xl\:hover\:-translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .xl\:hover\:-translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .xl\:hover\:-translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .xl\:hover\:-translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .xl\:hover\:-translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .xl\:hover\:-translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .xl\:hover\:-translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .xl\:hover\:-translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .xl\:hover\:-translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .xl\:hover\:-translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .xl\:hover\:-translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .xl\:hover\:translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .xl\:hover\:translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .xl\:hover\:translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .xl\:hover\:translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .xl\:hover\:translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .xl\:hover\:translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .xl\:hover\:translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .xl\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .xl\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .xl\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .xl\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .xl\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .xl\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .xl\:hover\:-translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .xl\:hover\:translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .xl\:hover\:translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .xl\:hover\:translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .xl\:hover\:translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .xl\:hover\:translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .xl\:hover\:translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .xl\:hover\:translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .xl\:hover\:translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .xl\:hover\:translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .xl\:hover\:translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .xl\:hover\:translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .xl\:hover\:translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .xl\:hover\:translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .xl\:hover\:translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .xl\:hover\:translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .xl\:hover\:translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .xl\:hover\:translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .xl\:hover\:translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .xl\:hover\:translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .xl\:hover\:translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .xl\:hover\:translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .xl\:hover\:translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .xl\:hover\:translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .xl\:hover\:translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .xl\:hover\:translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .xl\:hover\:translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .xl\:hover\:translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .xl\:hover\:translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .xl\:hover\:translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .xl\:hover\:translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .xl\:hover\:translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .xl\:hover\:translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .xl\:hover\:translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .xl\:hover\:translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .xl\:hover\:translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .xl\:hover\:-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .xl\:hover\:-translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .xl\:hover\:-translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .xl\:hover\:-translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .xl\:hover\:-translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .xl\:hover\:-translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .xl\:hover\:-translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .xl\:hover\:-translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .xl\:hover\:-translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .xl\:hover\:-translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .xl\:hover\:-translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .xl\:hover\:-translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .xl\:hover\:-translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .xl\:hover\:-translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .xl\:hover\:-translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .xl\:hover\:-translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .xl\:hover\:-translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .xl\:hover\:-translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .xl\:hover\:-translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .xl\:hover\:-translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .xl\:hover\:-translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .xl\:hover\:-translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .xl\:hover\:-translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .xl\:hover\:-translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .xl\:hover\:-translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .xl\:hover\:-translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .xl\:hover\:-translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .xl\:hover\:-translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .xl\:hover\:-translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .xl\:hover\:-translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .xl\:hover\:-translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .xl\:hover\:-translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .xl\:hover\:-translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .xl\:hover\:-translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .xl\:hover\:-translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .xl\:hover\:translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .xl\:hover\:translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .xl\:hover\:translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .xl\:hover\:translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .xl\:hover\:translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .xl\:hover\:translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .xl\:hover\:translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .xl\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .xl\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .xl\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .xl\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .xl\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .xl\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .xl\:hover\:-translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .xl\:focus\:translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .xl\:focus\:translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .xl\:focus\:translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .xl\:focus\:translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .xl\:focus\:translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .xl\:focus\:translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .xl\:focus\:translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .xl\:focus\:translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .xl\:focus\:translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .xl\:focus\:translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .xl\:focus\:translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .xl\:focus\:translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .xl\:focus\:translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .xl\:focus\:translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .xl\:focus\:translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .xl\:focus\:translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .xl\:focus\:translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .xl\:focus\:translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .xl\:focus\:translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .xl\:focus\:translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .xl\:focus\:translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .xl\:focus\:translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .xl\:focus\:translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .xl\:focus\:translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .xl\:focus\:translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .xl\:focus\:translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .xl\:focus\:translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .xl\:focus\:translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .xl\:focus\:translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .xl\:focus\:translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .xl\:focus\:translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .xl\:focus\:translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .xl\:focus\:translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .xl\:focus\:translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .xl\:focus\:translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .xl\:focus\:-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .xl\:focus\:-translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .xl\:focus\:-translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .xl\:focus\:-translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .xl\:focus\:-translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .xl\:focus\:-translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .xl\:focus\:-translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .xl\:focus\:-translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .xl\:focus\:-translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .xl\:focus\:-translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .xl\:focus\:-translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .xl\:focus\:-translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .xl\:focus\:-translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .xl\:focus\:-translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .xl\:focus\:-translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .xl\:focus\:-translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .xl\:focus\:-translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .xl\:focus\:-translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .xl\:focus\:-translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .xl\:focus\:-translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .xl\:focus\:-translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .xl\:focus\:-translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .xl\:focus\:-translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .xl\:focus\:-translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .xl\:focus\:-translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .xl\:focus\:-translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .xl\:focus\:-translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .xl\:focus\:-translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .xl\:focus\:-translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .xl\:focus\:-translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .xl\:focus\:-translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .xl\:focus\:-translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .xl\:focus\:-translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .xl\:focus\:-translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .xl\:focus\:-translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .xl\:focus\:translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .xl\:focus\:translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .xl\:focus\:translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .xl\:focus\:translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .xl\:focus\:translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .xl\:focus\:translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .xl\:focus\:translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .xl\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .xl\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .xl\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .xl\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .xl\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .xl\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .xl\:focus\:-translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .xl\:focus\:translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .xl\:focus\:translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .xl\:focus\:translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .xl\:focus\:translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .xl\:focus\:translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .xl\:focus\:translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .xl\:focus\:translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .xl\:focus\:translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .xl\:focus\:translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .xl\:focus\:translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .xl\:focus\:translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .xl\:focus\:translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .xl\:focus\:translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .xl\:focus\:translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .xl\:focus\:translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .xl\:focus\:translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .xl\:focus\:translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .xl\:focus\:translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .xl\:focus\:translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .xl\:focus\:translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .xl\:focus\:translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .xl\:focus\:translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .xl\:focus\:translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .xl\:focus\:translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .xl\:focus\:translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .xl\:focus\:translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .xl\:focus\:translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .xl\:focus\:translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .xl\:focus\:translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .xl\:focus\:translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .xl\:focus\:translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .xl\:focus\:translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .xl\:focus\:translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .xl\:focus\:translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .xl\:focus\:translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .xl\:focus\:-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .xl\:focus\:-translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .xl\:focus\:-translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .xl\:focus\:-translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .xl\:focus\:-translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .xl\:focus\:-translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .xl\:focus\:-translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .xl\:focus\:-translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .xl\:focus\:-translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .xl\:focus\:-translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .xl\:focus\:-translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .xl\:focus\:-translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .xl\:focus\:-translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .xl\:focus\:-translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .xl\:focus\:-translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .xl\:focus\:-translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .xl\:focus\:-translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .xl\:focus\:-translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .xl\:focus\:-translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .xl\:focus\:-translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .xl\:focus\:-translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .xl\:focus\:-translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .xl\:focus\:-translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .xl\:focus\:-translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .xl\:focus\:-translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .xl\:focus\:-translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .xl\:focus\:-translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .xl\:focus\:-translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .xl\:focus\:-translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .xl\:focus\:-translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .xl\:focus\:-translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .xl\:focus\:-translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .xl\:focus\:-translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .xl\:focus\:-translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .xl\:focus\:-translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .xl\:focus\:translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .xl\:focus\:translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .xl\:focus\:translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .xl\:focus\:translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .xl\:focus\:translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .xl\:focus\:translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .xl\:focus\:translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .xl\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .xl\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .xl\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .xl\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .xl\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .xl\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .xl\:focus\:-translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .xl\:rotate-0{
    --tw-rotate: 0deg !important;
  }

  .xl\:rotate-1{
    --tw-rotate: 1deg !important;
  }

  .xl\:rotate-2{
    --tw-rotate: 2deg !important;
  }

  .xl\:rotate-3{
    --tw-rotate: 3deg !important;
  }

  .xl\:rotate-6{
    --tw-rotate: 6deg !important;
  }

  .xl\:rotate-12{
    --tw-rotate: 12deg !important;
  }

  .xl\:rotate-45{
    --tw-rotate: 45deg !important;
  }

  .xl\:rotate-90{
    --tw-rotate: 90deg !important;
  }

  .xl\:rotate-180{
    --tw-rotate: 180deg !important;
  }

  .xl\:-rotate-180{
    --tw-rotate: -180deg !important;
  }

  .xl\:-rotate-90{
    --tw-rotate: -90deg !important;
  }

  .xl\:-rotate-45{
    --tw-rotate: -45deg !important;
  }

  .xl\:-rotate-12{
    --tw-rotate: -12deg !important;
  }

  .xl\:-rotate-6{
    --tw-rotate: -6deg !important;
  }

  .xl\:-rotate-3{
    --tw-rotate: -3deg !important;
  }

  .xl\:-rotate-2{
    --tw-rotate: -2deg !important;
  }

  .xl\:-rotate-1{
    --tw-rotate: -1deg !important;
  }

  .xl\:hover\:rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .xl\:hover\:rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .xl\:hover\:rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .xl\:hover\:rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .xl\:hover\:rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .xl\:hover\:rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .xl\:hover\:rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .xl\:hover\:rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .xl\:hover\:rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .xl\:hover\:-rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .xl\:hover\:-rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .xl\:hover\:-rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .xl\:hover\:-rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .xl\:hover\:-rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .xl\:hover\:-rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .xl\:hover\:-rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .xl\:hover\:-rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .xl\:focus\:rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .xl\:focus\:rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .xl\:focus\:rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .xl\:focus\:rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .xl\:focus\:rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .xl\:focus\:rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .xl\:focus\:rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .xl\:focus\:rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .xl\:focus\:rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .xl\:focus\:-rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .xl\:focus\:-rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .xl\:focus\:-rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .xl\:focus\:-rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .xl\:focus\:-rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .xl\:focus\:-rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .xl\:focus\:-rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .xl\:focus\:-rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .xl\:skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .xl\:skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .xl\:skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .xl\:skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .xl\:skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .xl\:skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .xl\:-skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .xl\:-skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .xl\:-skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .xl\:-skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .xl\:-skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .xl\:skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .xl\:skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .xl\:skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .xl\:skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .xl\:skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .xl\:skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .xl\:-skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .xl\:-skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .xl\:-skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .xl\:-skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .xl\:-skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .xl\:hover\:skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .xl\:hover\:skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .xl\:hover\:skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .xl\:hover\:skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .xl\:hover\:skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .xl\:hover\:skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .xl\:hover\:-skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .xl\:hover\:-skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .xl\:hover\:-skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .xl\:hover\:-skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .xl\:hover\:-skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .xl\:hover\:skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .xl\:hover\:skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .xl\:hover\:skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .xl\:hover\:skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .xl\:hover\:skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .xl\:hover\:skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .xl\:hover\:-skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .xl\:hover\:-skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .xl\:hover\:-skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .xl\:hover\:-skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .xl\:hover\:-skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .xl\:focus\:skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .xl\:focus\:skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .xl\:focus\:skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .xl\:focus\:skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .xl\:focus\:skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .xl\:focus\:skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .xl\:focus\:-skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .xl\:focus\:-skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .xl\:focus\:-skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .xl\:focus\:-skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .xl\:focus\:-skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .xl\:focus\:skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .xl\:focus\:skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .xl\:focus\:skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .xl\:focus\:skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .xl\:focus\:skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .xl\:focus\:skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .xl\:focus\:-skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .xl\:focus\:-skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .xl\:focus\:-skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .xl\:focus\:-skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .xl\:focus\:-skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .xl\:scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .xl\:scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .xl\:scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .xl\:scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .xl\:scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .xl\:scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .xl\:scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .xl\:scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .xl\:scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .xl\:scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .xl\:hover\:scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .xl\:hover\:scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .xl\:hover\:scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .xl\:hover\:scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .xl\:hover\:scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .xl\:hover\:scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .xl\:hover\:scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .xl\:hover\:scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .xl\:hover\:scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .xl\:hover\:scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .xl\:focus\:scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .xl\:focus\:scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .xl\:focus\:scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .xl\:focus\:scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .xl\:focus\:scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .xl\:focus\:scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .xl\:focus\:scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .xl\:focus\:scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .xl\:focus\:scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .xl\:focus\:scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .xl\:scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .xl\:scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .xl\:scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .xl\:scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .xl\:scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .xl\:scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .xl\:scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .xl\:scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .xl\:scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .xl\:scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .xl\:scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .xl\:scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .xl\:scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .xl\:scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .xl\:scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .xl\:scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .xl\:scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .xl\:scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .xl\:scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .xl\:scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .xl\:hover\:scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .xl\:hover\:scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .xl\:hover\:scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .xl\:hover\:scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .xl\:hover\:scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .xl\:hover\:scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .xl\:hover\:scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .xl\:hover\:scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .xl\:hover\:scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .xl\:hover\:scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .xl\:hover\:scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .xl\:hover\:scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .xl\:hover\:scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .xl\:hover\:scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .xl\:hover\:scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .xl\:hover\:scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .xl\:hover\:scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .xl\:hover\:scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .xl\:hover\:scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .xl\:hover\:scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .xl\:focus\:scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .xl\:focus\:scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .xl\:focus\:scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .xl\:focus\:scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .xl\:focus\:scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .xl\:focus\:scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .xl\:focus\:scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .xl\:focus\:scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .xl\:focus\:scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .xl\:focus\:scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .xl\:focus\:scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .xl\:focus\:scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .xl\:focus\:scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .xl\:focus\:scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .xl\:focus\:scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .xl\:focus\:scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .xl\:focus\:scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .xl\:focus\:scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .xl\:focus\:scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .xl\:focus\:scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .xl\:animate-none{
    animation: none !important;
  }

  .xl\:animate-spin{
    animation: spin 1s linear infinite !important;
  }

  .xl\:animate-ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .xl\:animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .xl\:animate-bounce{
    animation: bounce 1s infinite !important;
  }

  .xl\:cursor-auto{
    cursor: auto !important;
  }

  .xl\:cursor-default{
    cursor: default !important;
  }

  .xl\:cursor-pointer{
    cursor: pointer !important;
  }

  .xl\:cursor-wait{
    cursor: wait !important;
  }

  .xl\:cursor-text{
    cursor: text !important;
  }

  .xl\:cursor-move{
    cursor: move !important;
  }

  .xl\:cursor-help{
    cursor: help !important;
  }

  .xl\:cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .xl\:select-none{
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .xl\:select-text{
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .xl\:select-all{
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .xl\:select-auto{
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .xl\:resize-none{
    resize: none !important;
  }

  .xl\:resize-y{
    resize: vertical !important;
  }

  .xl\:resize-x{
    resize: horizontal !important;
  }

  .xl\:resize{
    resize: both !important;
  }

  .xl\:list-inside{
    list-style-position: inside !important;
  }

  .xl\:list-outside{
    list-style-position: outside !important;
  }

  .xl\:list-none{
    list-style-type: none !important;
  }

  .xl\:list-disc{
    list-style-type: disc !important;
  }

  .xl\:list-decimal{
    list-style-type: decimal !important;
  }

  .xl\:appearance-none{
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .xl\:auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .xl\:auto-cols-min{
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .xl\:auto-cols-max{
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .xl\:auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .xl\:grid-flow-row{
    grid-auto-flow: row !important;
  }

  .xl\:grid-flow-col{
    grid-auto-flow: column !important;
  }

  .xl\:grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .xl\:grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .xl\:auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .xl\:auto-rows-min{
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .xl\:auto-rows-max{
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .xl\:auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .xl\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-none{
    grid-template-columns: none !important;
  }

  .xl\:grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-none{
    grid-template-rows: none !important;
  }

  .xl\:flex-row{
    flex-direction: row !important;
  }

  .xl\:flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .xl\:flex-col{
    flex-direction: column !important;
  }

  .xl\:flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .xl\:flex-wrap{
    flex-wrap: wrap !important;
  }

  .xl\:flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .xl\:flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .xl\:place-content-center{
    place-content: center !important;
  }

  .xl\:place-content-start{
    place-content: start !important;
  }

  .xl\:place-content-end{
    place-content: end !important;
  }

  .xl\:place-content-between{
    place-content: space-between !important;
  }

  .xl\:place-content-around{
    place-content: space-around !important;
  }

  .xl\:place-content-evenly{
    place-content: space-evenly !important;
  }

  .xl\:place-content-stretch{
    place-content: stretch !important;
  }

  .xl\:place-items-start{
    place-items: start !important;
  }

  .xl\:place-items-end{
    place-items: end !important;
  }

  .xl\:place-items-center{
    place-items: center !important;
  }

  .xl\:place-items-stretch{
    place-items: stretch !important;
  }

  .xl\:content-center{
    align-content: center !important;
  }

  .xl\:content-start{
    align-content: flex-start !important;
  }

  .xl\:content-end{
    align-content: flex-end !important;
  }

  .xl\:content-between{
    align-content: space-between !important;
  }

  .xl\:content-around{
    align-content: space-around !important;
  }

  .xl\:content-evenly{
    align-content: space-evenly !important;
  }

  .xl\:items-start{
    align-items: flex-start !important;
  }

  .xl\:items-end{
    align-items: flex-end !important;
  }

  .xl\:items-center{
    align-items: center !important;
  }

  .xl\:items-baseline{
    align-items: baseline !important;
  }

  .xl\:items-stretch{
    align-items: stretch !important;
  }

  .xl\:justify-start{
    justify-content: flex-start !important;
  }

  .xl\:justify-end{
    justify-content: flex-end !important;
  }

  .xl\:justify-center{
    justify-content: center !important;
  }

  .xl\:justify-between{
    justify-content: space-between !important;
  }

  .xl\:justify-around{
    justify-content: space-around !important;
  }

  .xl\:justify-evenly{
    justify-content: space-evenly !important;
  }

  .xl\:justify-items-start{
    justify-items: start !important;
  }

  .xl\:justify-items-end{
    justify-items: end !important;
  }

  .xl\:justify-items-center{
    justify-items: center !important;
  }

  .xl\:justify-items-stretch{
    justify-items: stretch !important;
  }

  .xl\:gap-0{
    gap: 0px !important;
  }

  .xl\:gap-1{
    gap: 0.25rem !important;
  }

  .xl\:gap-2{
    gap: 0.5rem !important;
  }

  .xl\:gap-3{
    gap: 0.75rem !important;
  }

  .xl\:gap-4{
    gap: 1rem !important;
  }

  .xl\:gap-5{
    gap: 1.25rem !important;
  }

  .xl\:gap-6{
    gap: 1.5rem !important;
  }

  .xl\:gap-7{
    gap: 1.75rem !important;
  }

  .xl\:gap-8{
    gap: 2rem !important;
  }

  .xl\:gap-9{
    gap: 2.25rem !important;
  }

  .xl\:gap-10{
    gap: 2.5rem !important;
  }

  .xl\:gap-11{
    gap: 2.75rem !important;
  }

  .xl\:gap-12{
    gap: 3rem !important;
  }

  .xl\:gap-14{
    gap: 3.5rem !important;
  }

  .xl\:gap-16{
    gap: 4rem !important;
  }

  .xl\:gap-20{
    gap: 5rem !important;
  }

  .xl\:gap-24{
    gap: 6rem !important;
  }

  .xl\:gap-28{
    gap: 7rem !important;
  }

  .xl\:gap-32{
    gap: 8rem !important;
  }

  .xl\:gap-36{
    gap: 9rem !important;
  }

  .xl\:gap-40{
    gap: 10rem !important;
  }

  .xl\:gap-44{
    gap: 11rem !important;
  }

  .xl\:gap-48{
    gap: 12rem !important;
  }

  .xl\:gap-52{
    gap: 13rem !important;
  }

  .xl\:gap-56{
    gap: 14rem !important;
  }

  .xl\:gap-60{
    gap: 15rem !important;
  }

  .xl\:gap-64{
    gap: 16rem !important;
  }

  .xl\:gap-72{
    gap: 18rem !important;
  }

  .xl\:gap-80{
    gap: 20rem !important;
  }

  .xl\:gap-96{
    gap: 24rem !important;
  }

  .xl\:gap-px{
    gap: 1px !important;
  }

  .xl\:gap-0\.5{
    gap: 0.125rem !important;
  }

  .xl\:gap-1\.5{
    gap: 0.375rem !important;
  }

  .xl\:gap-2\.5{
    gap: 0.625rem !important;
  }

  .xl\:gap-3\.5{
    gap: 0.875rem !important;
  }

  .xl\:gap-x-0{
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .xl\:gap-x-1{
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .xl\:gap-x-2{
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .xl\:gap-x-3{
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .xl\:gap-x-4{
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .xl\:gap-x-5{
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .xl\:gap-x-6{
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .xl\:gap-x-7{
    -webkit-column-gap: 1.75rem !important;
            column-gap: 1.75rem !important;
  }

  .xl\:gap-x-8{
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .xl\:gap-x-9{
    -webkit-column-gap: 2.25rem !important;
            column-gap: 2.25rem !important;
  }

  .xl\:gap-x-10{
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .xl\:gap-x-11{
    -webkit-column-gap: 2.75rem !important;
            column-gap: 2.75rem !important;
  }

  .xl\:gap-x-12{
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .xl\:gap-x-14{
    -webkit-column-gap: 3.5rem !important;
            column-gap: 3.5rem !important;
  }

  .xl\:gap-x-16{
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .xl\:gap-x-20{
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .xl\:gap-x-24{
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .xl\:gap-x-28{
    -webkit-column-gap: 7rem !important;
            column-gap: 7rem !important;
  }

  .xl\:gap-x-32{
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .xl\:gap-x-36{
    -webkit-column-gap: 9rem !important;
            column-gap: 9rem !important;
  }

  .xl\:gap-x-40{
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .xl\:gap-x-44{
    -webkit-column-gap: 11rem !important;
            column-gap: 11rem !important;
  }

  .xl\:gap-x-48{
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .xl\:gap-x-52{
    -webkit-column-gap: 13rem !important;
            column-gap: 13rem !important;
  }

  .xl\:gap-x-56{
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .xl\:gap-x-60{
    -webkit-column-gap: 15rem !important;
            column-gap: 15rem !important;
  }

  .xl\:gap-x-64{
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .xl\:gap-x-72{
    -webkit-column-gap: 18rem !important;
            column-gap: 18rem !important;
  }

  .xl\:gap-x-80{
    -webkit-column-gap: 20rem !important;
            column-gap: 20rem !important;
  }

  .xl\:gap-x-96{
    -webkit-column-gap: 24rem !important;
            column-gap: 24rem !important;
  }

  .xl\:gap-x-px{
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .xl\:gap-x-0\.5{
    -webkit-column-gap: 0.125rem !important;
            column-gap: 0.125rem !important;
  }

  .xl\:gap-x-1\.5{
    -webkit-column-gap: 0.375rem !important;
            column-gap: 0.375rem !important;
  }

  .xl\:gap-x-2\.5{
    -webkit-column-gap: 0.625rem !important;
            column-gap: 0.625rem !important;
  }

  .xl\:gap-x-3\.5{
    -webkit-column-gap: 0.875rem !important;
            column-gap: 0.875rem !important;
  }

  .xl\:gap-y-0{
    row-gap: 0px !important;
  }

  .xl\:gap-y-1{
    row-gap: 0.25rem !important;
  }

  .xl\:gap-y-2{
    row-gap: 0.5rem !important;
  }

  .xl\:gap-y-3{
    row-gap: 0.75rem !important;
  }

  .xl\:gap-y-4{
    row-gap: 1rem !important;
  }

  .xl\:gap-y-5{
    row-gap: 1.25rem !important;
  }

  .xl\:gap-y-6{
    row-gap: 1.5rem !important;
  }

  .xl\:gap-y-7{
    row-gap: 1.75rem !important;
  }

  .xl\:gap-y-8{
    row-gap: 2rem !important;
  }

  .xl\:gap-y-9{
    row-gap: 2.25rem !important;
  }

  .xl\:gap-y-10{
    row-gap: 2.5rem !important;
  }

  .xl\:gap-y-11{
    row-gap: 2.75rem !important;
  }

  .xl\:gap-y-12{
    row-gap: 3rem !important;
  }

  .xl\:gap-y-14{
    row-gap: 3.5rem !important;
  }

  .xl\:gap-y-16{
    row-gap: 4rem !important;
  }

  .xl\:gap-y-20{
    row-gap: 5rem !important;
  }

  .xl\:gap-y-24{
    row-gap: 6rem !important;
  }

  .xl\:gap-y-28{
    row-gap: 7rem !important;
  }

  .xl\:gap-y-32{
    row-gap: 8rem !important;
  }

  .xl\:gap-y-36{
    row-gap: 9rem !important;
  }

  .xl\:gap-y-40{
    row-gap: 10rem !important;
  }

  .xl\:gap-y-44{
    row-gap: 11rem !important;
  }

  .xl\:gap-y-48{
    row-gap: 12rem !important;
  }

  .xl\:gap-y-52{
    row-gap: 13rem !important;
  }

  .xl\:gap-y-56{
    row-gap: 14rem !important;
  }

  .xl\:gap-y-60{
    row-gap: 15rem !important;
  }

  .xl\:gap-y-64{
    row-gap: 16rem !important;
  }

  .xl\:gap-y-72{
    row-gap: 18rem !important;
  }

  .xl\:gap-y-80{
    row-gap: 20rem !important;
  }

  .xl\:gap-y-96{
    row-gap: 24rem !important;
  }

  .xl\:gap-y-px{
    row-gap: 1px !important;
  }

  .xl\:gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .xl\:gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .xl\:gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .xl\:gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .xl\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .xl\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .xl\:divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .xl\:divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-qipGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-portalGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-divide-opacity)) !important;
  }

  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .xl\:place-self-auto{
    place-self: auto !important;
  }

  .xl\:place-self-start{
    place-self: start !important;
  }

  .xl\:place-self-end{
    place-self: end !important;
  }

  .xl\:place-self-center{
    place-self: center !important;
  }

  .xl\:place-self-stretch{
    place-self: stretch !important;
  }

  .xl\:self-auto{
    align-self: auto !important;
  }

  .xl\:self-start{
    align-self: flex-start !important;
  }

  .xl\:self-end{
    align-self: flex-end !important;
  }

  .xl\:self-center{
    align-self: center !important;
  }

  .xl\:self-stretch{
    align-self: stretch !important;
  }

  .xl\:self-baseline{
    align-self: baseline !important;
  }

  .xl\:justify-self-auto{
    justify-self: auto !important;
  }

  .xl\:justify-self-start{
    justify-self: start !important;
  }

  .xl\:justify-self-end{
    justify-self: end !important;
  }

  .xl\:justify-self-center{
    justify-self: center !important;
  }

  .xl\:justify-self-stretch{
    justify-self: stretch !important;
  }

  .xl\:overflow-auto{
    overflow: auto !important;
  }

  .xl\:overflow-hidden{
    overflow: hidden !important;
  }

  .xl\:overflow-visible{
    overflow: visible !important;
  }

  .xl\:overflow-scroll{
    overflow: scroll !important;
  }

  .xl\:overflow-x-auto{
    overflow-x: auto !important;
  }

  .xl\:overflow-y-auto{
    overflow-y: auto !important;
  }

  .xl\:overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .xl\:overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .xl\:overflow-x-visible{
    overflow-x: visible !important;
  }

  .xl\:overflow-y-visible{
    overflow-y: visible !important;
  }

  .xl\:overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .xl\:overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .xl\:overscroll-auto{
    -ms-scroll-chaining: chained !important;
        overscroll-behavior: auto !important;
  }

  .xl\:overscroll-contain{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: contain !important;
  }

  .xl\:overscroll-none{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: none !important;
  }

  .xl\:overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .xl\:overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .xl\:overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .xl\:overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .xl\:overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .xl\:overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .xl\:truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xl\:overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .xl\:overflow-clip{
    text-overflow: clip !important;
  }

  .xl\:whitespace-normal{
    white-space: normal !important;
  }

  .xl\:whitespace-nowrap{
    white-space: nowrap !important;
  }

  .xl\:whitespace-pre{
    white-space: pre !important;
  }

  .xl\:whitespace-pre-line{
    white-space: pre-line !important;
  }

  .xl\:whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .xl\:break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xl\:break-words{
    overflow-wrap: break-word !important;
  }

  .xl\:break-all{
    word-break: break-all !important;
  }

  .xl\:rounded-none{
    border-radius: 0px !important;
  }

  .xl\:rounded-sm{
    border-radius: 0.125rem !important;
  }

  .xl\:rounded{
    border-radius: 0.25rem !important;
  }

  .xl\:rounded-md{
    border-radius: 0.375rem !important;
  }

  .xl\:rounded-lg{
    border-radius: 0.5rem !important;
  }

  .xl\:rounded-xl{
    border-radius: 0.75rem !important;
  }

  .xl\:rounded-2xl{
    border-radius: 1rem !important;
  }

  .xl\:rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .xl\:rounded-full{
    border-radius: 9999px !important;
  }

  .xl\:rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .xl\:rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .xl\:rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xl\:rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xl\:rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .xl\:rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .xl\:rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .xl\:rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .xl\:rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .xl\:rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .xl\:rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .xl\:rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .xl\:rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .xl\:rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .xl\:rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .xl\:rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .xl\:rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .xl\:rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .xl\:rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .xl\:rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .xl\:rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .xl\:rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .xl\:rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .xl\:rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .xl\:rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .xl\:rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:border-0{
    border-width: 0px !important;
  }

  .xl\:border-2{
    border-width: 2px !important;
  }

  .xl\:border-4{
    border-width: 4px !important;
  }

  .xl\:border-8{
    border-width: 8px !important;
  }

  .xl\:border{
    border-width: 1px !important;
  }

  .xl\:border-t-0{
    border-top-width: 0px !important;
  }

  .xl\:border-t-2{
    border-top-width: 2px !important;
  }

  .xl\:border-t-4{
    border-top-width: 4px !important;
  }

  .xl\:border-t-8{
    border-top-width: 8px !important;
  }

  .xl\:border-t{
    border-top-width: 1px !important;
  }

  .xl\:border-r-0{
    border-right-width: 0px !important;
  }

  .xl\:border-r-2{
    border-right-width: 2px !important;
  }

  .xl\:border-r-4{
    border-right-width: 4px !important;
  }

  .xl\:border-r-8{
    border-right-width: 8px !important;
  }

  .xl\:border-r{
    border-right-width: 1px !important;
  }

  .xl\:border-b-0{
    border-bottom-width: 0px !important;
  }

  .xl\:border-b-2{
    border-bottom-width: 2px !important;
  }

  .xl\:border-b-4{
    border-bottom-width: 4px !important;
  }

  .xl\:border-b-8{
    border-bottom-width: 8px !important;
  }

  .xl\:border-b{
    border-bottom-width: 1px !important;
  }

  .xl\:border-l-0{
    border-left-width: 0px !important;
  }

  .xl\:border-l-2{
    border-left-width: 2px !important;
  }

  .xl\:border-l-4{
    border-left-width: 4px !important;
  }

  .xl\:border-l-8{
    border-left-width: 8px !important;
  }

  .xl\:border-l{
    border-left-width: 1px !important;
  }

  .xl\:border-solid{
    border-style: solid !important;
  }

  .xl\:border-dashed{
    border-style: dashed !important;
  }

  .xl\:border-dotted{
    border-style: dotted !important;
  }

  .xl\:border-double{
    border-style: double !important;
  }

  .xl\:border-none{
    border-style: none !important;
  }

  .xl\:border-transparent{
    border-color: transparent !important;
  }

  .xl\:border-current{
    border-color: currentColor !important;
  }

  .xl\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-transparent{
    border-color: transparent !important;
  }

  .group:hover .xl\:group-hover\:border-current{
    border-color: currentColor !important;
  }

  .group:hover .xl\:group-hover\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-transparent:focus-within{
    border-color: transparent !important;
  }

  .xl\:focus-within\:border-current:focus-within{
    border-color: currentColor !important;
  }

  .xl\:focus-within\:border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-qipGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:focus-within\:border-portalGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-transparent:hover{
    border-color: transparent !important;
  }

  .xl\:hover\:border-current:hover{
    border-color: currentColor !important;
  }

  .xl\:hover\:border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-qipGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:hover\:border-portalGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-transparent:focus{
    border-color: transparent !important;
  }

  .xl\:focus\:border-current:focus{
    border-color: currentColor !important;
  }

  .xl\:focus\:border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-qipGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .xl\:focus\:border-portalGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .xl\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .xl\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .group:hover .xl\:group-hover\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .xl\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .xl\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .xl\:hover\:border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .xl\:hover\:border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:hover\:border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:hover\:border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:hover\:border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:hover\:border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:hover\:border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:hover\:border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:hover\:border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:hover\:border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:hover\:border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:hover\:border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:hover\:border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:hover\:border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:hover\:border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .xl\:focus\:border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .xl\:focus\:border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .xl\:focus\:border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .xl\:focus\:border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .xl\:focus\:border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .xl\:focus\:border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .xl\:focus\:border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .xl\:focus\:border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .xl\:focus\:border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .xl\:focus\:border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .xl\:focus\:border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .xl\:focus\:border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .xl\:focus\:border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .xl\:focus\:border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .xl\:focus\:border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .xl\:bg-transparent{
    background-color: transparent !important;
  }

  .xl\:bg-current{
    background-color: currentColor !important;
  }

  .xl\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-transparent{
    background-color: transparent !important;
  }

  .group:hover .xl\:group-hover\:bg-current{
    background-color: currentColor !important;
  }

  .group:hover .xl\:group-hover\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .xl\:focus-within\:bg-current:focus-within{
    background-color: currentColor !important;
  }

  .xl\:focus-within\:bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-qipGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus-within\:bg-portalGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-transparent:hover{
    background-color: transparent !important;
  }

  .xl\:hover\:bg-current:hover{
    background-color: currentColor !important;
  }

  .xl\:hover\:bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-qipGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:hover\:bg-portalGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-transparent:focus{
    background-color: transparent !important;
  }

  .xl\:focus\:bg-current:focus{
    background-color: currentColor !important;
  }

  .xl\:focus\:bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-qipGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .xl\:focus\:bg-portalGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .xl\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .group:hover .xl\:group-hover\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .xl\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .xl\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .xl\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .xl\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .xl\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .xl\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .xl\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .xl\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .xl\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .xl\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .xl\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .xl\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .xl\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .xl\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .xl\:bg-none{
    background-image: none !important;
  }

  .xl\:bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .xl\:bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .xl\:from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:from-qipGreen{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .xl\:from-portalGreen{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .xl\:hover\:from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:hover\:from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:hover\:from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:hover\:from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:hover\:from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:hover\:from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:hover\:from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:hover\:from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:hover\:from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:hover\:from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:hover\:from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:hover\:from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:hover\:from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:hover\:from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:hover\:from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:hover\:from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:hover\:from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:hover\:from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:hover\:from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:hover\:from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:hover\:from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:hover\:from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:hover\:from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:hover\:from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:hover\:from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:hover\:from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:hover\:from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:hover\:from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:hover\:from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:hover\:from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:hover\:from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:hover\:from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:hover\:from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:hover\:from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:hover\:from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:hover\:from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:hover\:from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:hover\:from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:hover\:from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:hover\:from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:hover\:from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:hover\:from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:hover\:from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:hover\:from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:hover\:from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:hover\:from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:hover\:from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:hover\:from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:hover\:from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:hover\:from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:hover\:from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:hover\:from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:hover\:from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:hover\:from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:hover\:from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:hover\:from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:hover\:from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:hover\:from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:hover\:from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:hover\:from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:hover\:from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:hover\:from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:hover\:from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:hover\:from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:hover\:from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:hover\:from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:hover\:from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:hover\:from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:hover\:from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:hover\:from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:hover\:from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:hover\:from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:hover\:from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:hover\:from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:hover\:from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:hover\:from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:hover\:from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:hover\:from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:hover\:from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:hover\:from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:hover\:from-qipGreen:hover{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .xl\:hover\:from-portalGreen:hover{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .xl\:focus\:from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:focus\:from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:focus\:from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:focus\:from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:focus\:from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:focus\:from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:focus\:from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:focus\:from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:focus\:from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:focus\:from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:focus\:from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:focus\:from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:focus\:from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:focus\:from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:focus\:from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:focus\:from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:focus\:from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:focus\:from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:focus\:from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:focus\:from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:focus\:from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:focus\:from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:focus\:from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:focus\:from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:focus\:from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:focus\:from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:focus\:from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:focus\:from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:focus\:from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:focus\:from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:focus\:from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:focus\:from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:focus\:from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:focus\:from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:focus\:from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:focus\:from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:focus\:from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:focus\:from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:focus\:from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:focus\:from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:focus\:from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:focus\:from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:focus\:from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:focus\:from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:focus\:from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:focus\:from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:focus\:from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:focus\:from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:focus\:from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:focus\:from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:focus\:from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:focus\:from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:focus\:from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:focus\:from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:focus\:from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:focus\:from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:focus\:from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:focus\:from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:focus\:from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:focus\:from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:focus\:from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:focus\:from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:focus\:from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:focus\:from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:focus\:from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:focus\:from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:focus\:from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:focus\:from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:focus\:from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:focus\:from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:focus\:from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:focus\:from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:focus\:from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:focus\:from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:focus\:from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:focus\:from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:focus\:from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:focus\:from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:focus\:from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:focus\:from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:focus\:from-qipGreen:focus{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .xl\:focus\:from-portalGreen:focus{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .xl\:via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:via-qipGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .xl\:via-portalGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .xl\:hover\:via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:hover\:via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:hover\:via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:hover\:via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:hover\:via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:hover\:via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:hover\:via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:hover\:via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:hover\:via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:hover\:via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:hover\:via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:hover\:via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:hover\:via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:hover\:via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:hover\:via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:hover\:via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:hover\:via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:hover\:via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:hover\:via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:hover\:via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:hover\:via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:hover\:via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:hover\:via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:hover\:via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:hover\:via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:hover\:via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:hover\:via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:hover\:via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:hover\:via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:hover\:via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:hover\:via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:hover\:via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:hover\:via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:hover\:via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:hover\:via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:hover\:via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:hover\:via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:hover\:via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:hover\:via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:hover\:via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:hover\:via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:hover\:via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:hover\:via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:hover\:via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:hover\:via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:hover\:via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:hover\:via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:hover\:via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:hover\:via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:hover\:via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:hover\:via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:hover\:via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:hover\:via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:hover\:via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:hover\:via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:hover\:via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:hover\:via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:hover\:via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:hover\:via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:hover\:via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:hover\:via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:hover\:via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:hover\:via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:hover\:via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:hover\:via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:hover\:via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:hover\:via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:hover\:via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:hover\:via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:hover\:via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:hover\:via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:hover\:via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:hover\:via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:hover\:via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:hover\:via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:hover\:via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:hover\:via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:hover\:via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:hover\:via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:hover\:via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:hover\:via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:hover\:via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:hover\:via-qipGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .xl\:hover\:via-portalGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .xl\:focus\:via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .xl\:focus\:via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .xl\:focus\:via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .xl\:focus\:via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .xl\:focus\:via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .xl\:focus\:via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .xl\:focus\:via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .xl\:focus\:via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .xl\:focus\:via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .xl\:focus\:via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .xl\:focus\:via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .xl\:focus\:via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .xl\:focus\:via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .xl\:focus\:via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .xl\:focus\:via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .xl\:focus\:via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .xl\:focus\:via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .xl\:focus\:via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .xl\:focus\:via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .xl\:focus\:via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .xl\:focus\:via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .xl\:focus\:via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .xl\:focus\:via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .xl\:focus\:via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .xl\:focus\:via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .xl\:focus\:via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .xl\:focus\:via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .xl\:focus\:via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .xl\:focus\:via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .xl\:focus\:via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .xl\:focus\:via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .xl\:focus\:via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .xl\:focus\:via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .xl\:focus\:via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .xl\:focus\:via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .xl\:focus\:via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .xl\:focus\:via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .xl\:focus\:via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .xl\:focus\:via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .xl\:focus\:via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .xl\:focus\:via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .xl\:focus\:via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .xl\:focus\:via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .xl\:focus\:via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .xl\:focus\:via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .xl\:focus\:via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .xl\:focus\:via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .xl\:focus\:via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .xl\:focus\:via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .xl\:focus\:via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .xl\:focus\:via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .xl\:focus\:via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .xl\:focus\:via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .xl\:focus\:via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .xl\:focus\:via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .xl\:focus\:via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .xl\:focus\:via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .xl\:focus\:via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .xl\:focus\:via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .xl\:focus\:via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .xl\:focus\:via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .xl\:focus\:via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .xl\:focus\:via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .xl\:focus\:via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .xl\:focus\:via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .xl\:focus\:via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .xl\:focus\:via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .xl\:focus\:via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .xl\:focus\:via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .xl\:focus\:via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .xl\:focus\:via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .xl\:focus\:via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .xl\:focus\:via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .xl\:focus\:via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .xl\:focus\:via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .xl\:focus\:via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .xl\:focus\:via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .xl\:focus\:via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .xl\:focus\:via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .xl\:focus\:via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .xl\:focus\:via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .xl\:focus\:via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .xl\:focus\:via-qipGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .xl\:focus\:via-portalGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .xl\:to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .xl\:to-current{
    --tw-gradient-to: currentColor !important;
  }

  .xl\:to-black{
    --tw-gradient-to: #000 !important;
  }

  .xl\:to-white{
    --tw-gradient-to: #fff !important;
  }

  .xl\:to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .xl\:to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .xl\:to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .xl\:to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .xl\:to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .xl\:to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .xl\:to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .xl\:to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .xl\:to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .xl\:to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .xl\:to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .xl\:to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .xl\:to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .xl\:to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .xl\:to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .xl\:to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .xl\:to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .xl\:to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .xl\:to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .xl\:to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .xl\:to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .xl\:to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .xl\:to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .xl\:to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .xl\:to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .xl\:to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .xl\:to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .xl\:to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .xl\:to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .xl\:to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .xl\:to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .xl\:to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .xl\:to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .xl\:to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .xl\:to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .xl\:to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .xl\:to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .xl\:to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .xl\:to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .xl\:to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .xl\:to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .xl\:to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .xl\:to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .xl\:to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .xl\:to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .xl\:to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .xl\:to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .xl\:to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .xl\:to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .xl\:to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .xl\:to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .xl\:to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .xl\:to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .xl\:to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .xl\:to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .xl\:to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .xl\:to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .xl\:to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .xl\:to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .xl\:to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .xl\:to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .xl\:to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .xl\:to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .xl\:to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .xl\:to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .xl\:to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .xl\:to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .xl\:to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .xl\:to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .xl\:to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .xl\:to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .xl\:to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .xl\:to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .xl\:to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .xl\:to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .xl\:to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .xl\:to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .xl\:to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .xl\:to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .xl\:to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .xl\:to-qipGreen{
    --tw-gradient-to: #8bc334 !important;
  }

  .xl\:to-portalGreen{
    --tw-gradient-to: #8ac04a !important;
  }

  .xl\:hover\:to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .xl\:hover\:to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .xl\:hover\:to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .xl\:hover\:to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .xl\:hover\:to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .xl\:hover\:to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .xl\:hover\:to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .xl\:hover\:to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .xl\:hover\:to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .xl\:hover\:to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .xl\:hover\:to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .xl\:hover\:to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .xl\:hover\:to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .xl\:hover\:to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .xl\:hover\:to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .xl\:hover\:to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .xl\:hover\:to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .xl\:hover\:to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .xl\:hover\:to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .xl\:hover\:to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .xl\:hover\:to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .xl\:hover\:to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .xl\:hover\:to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .xl\:hover\:to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .xl\:hover\:to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .xl\:hover\:to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .xl\:hover\:to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .xl\:hover\:to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .xl\:hover\:to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .xl\:hover\:to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .xl\:hover\:to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .xl\:hover\:to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .xl\:hover\:to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .xl\:hover\:to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .xl\:hover\:to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .xl\:hover\:to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .xl\:hover\:to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .xl\:hover\:to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .xl\:hover\:to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .xl\:hover\:to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .xl\:hover\:to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .xl\:hover\:to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .xl\:hover\:to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .xl\:hover\:to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .xl\:hover\:to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .xl\:hover\:to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .xl\:hover\:to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .xl\:hover\:to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .xl\:hover\:to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .xl\:hover\:to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .xl\:hover\:to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .xl\:hover\:to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .xl\:hover\:to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .xl\:hover\:to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .xl\:hover\:to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .xl\:hover\:to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .xl\:hover\:to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .xl\:hover\:to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .xl\:hover\:to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .xl\:hover\:to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .xl\:hover\:to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .xl\:hover\:to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .xl\:hover\:to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .xl\:hover\:to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .xl\:hover\:to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .xl\:hover\:to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .xl\:hover\:to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .xl\:hover\:to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .xl\:hover\:to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .xl\:hover\:to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .xl\:hover\:to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .xl\:hover\:to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .xl\:hover\:to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .xl\:hover\:to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .xl\:hover\:to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .xl\:hover\:to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .xl\:hover\:to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .xl\:hover\:to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .xl\:hover\:to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .xl\:hover\:to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .xl\:hover\:to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .xl\:hover\:to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .xl\:hover\:to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .xl\:hover\:to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .xl\:hover\:to-qipGreen:hover{
    --tw-gradient-to: #8bc334 !important;
  }

  .xl\:hover\:to-portalGreen:hover{
    --tw-gradient-to: #8ac04a !important;
  }

  .xl\:focus\:to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .xl\:focus\:to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .xl\:focus\:to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .xl\:focus\:to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .xl\:focus\:to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .xl\:focus\:to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .xl\:focus\:to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .xl\:focus\:to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .xl\:focus\:to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .xl\:focus\:to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .xl\:focus\:to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .xl\:focus\:to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .xl\:focus\:to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .xl\:focus\:to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .xl\:focus\:to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .xl\:focus\:to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .xl\:focus\:to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .xl\:focus\:to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .xl\:focus\:to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .xl\:focus\:to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .xl\:focus\:to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .xl\:focus\:to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .xl\:focus\:to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .xl\:focus\:to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .xl\:focus\:to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .xl\:focus\:to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .xl\:focus\:to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .xl\:focus\:to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .xl\:focus\:to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .xl\:focus\:to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .xl\:focus\:to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .xl\:focus\:to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .xl\:focus\:to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .xl\:focus\:to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .xl\:focus\:to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .xl\:focus\:to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .xl\:focus\:to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .xl\:focus\:to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .xl\:focus\:to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .xl\:focus\:to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .xl\:focus\:to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .xl\:focus\:to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .xl\:focus\:to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .xl\:focus\:to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .xl\:focus\:to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .xl\:focus\:to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .xl\:focus\:to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .xl\:focus\:to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .xl\:focus\:to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .xl\:focus\:to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .xl\:focus\:to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .xl\:focus\:to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .xl\:focus\:to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .xl\:focus\:to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .xl\:focus\:to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .xl\:focus\:to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .xl\:focus\:to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .xl\:focus\:to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .xl\:focus\:to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .xl\:focus\:to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .xl\:focus\:to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .xl\:focus\:to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .xl\:focus\:to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .xl\:focus\:to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .xl\:focus\:to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .xl\:focus\:to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .xl\:focus\:to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .xl\:focus\:to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .xl\:focus\:to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .xl\:focus\:to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .xl\:focus\:to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .xl\:focus\:to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .xl\:focus\:to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .xl\:focus\:to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .xl\:focus\:to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .xl\:focus\:to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .xl\:focus\:to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .xl\:focus\:to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .xl\:focus\:to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .xl\:focus\:to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .xl\:focus\:to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .xl\:focus\:to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .xl\:focus\:to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .xl\:focus\:to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .xl\:focus\:to-qipGreen:focus{
    --tw-gradient-to: #8bc334 !important;
  }

  .xl\:focus\:to-portalGreen:focus{
    --tw-gradient-to: #8ac04a !important;
  }

  .xl\:decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .xl\:decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .xl\:bg-auto{
    background-size: auto !important;
  }

  .xl\:bg-cover{
    background-size: cover !important;
  }

  .xl\:bg-contain{
    background-size: contain !important;
  }

  .xl\:bg-fixed{
    background-attachment: fixed !important;
  }

  .xl\:bg-local{
    background-attachment: local !important;
  }

  .xl\:bg-scroll{
    background-attachment: scroll !important;
  }

  .xl\:bg-clip-border{
    background-clip: border-box !important;
  }

  .xl\:bg-clip-padding{
    background-clip: padding-box !important;
  }

  .xl\:bg-clip-content{
    background-clip: content-box !important;
  }

  .xl\:bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .xl\:bg-bottom{
    background-position: bottom !important;
  }

  .xl\:bg-center{
    background-position: center !important;
  }

  .xl\:bg-left{
    background-position: left !important;
  }

  .xl\:bg-left-bottom{
    background-position: left bottom !important;
  }

  .xl\:bg-left-top{
    background-position: left top !important;
  }

  .xl\:bg-right{
    background-position: right !important;
  }

  .xl\:bg-right-bottom{
    background-position: right bottom !important;
  }

  .xl\:bg-right-top{
    background-position: right top !important;
  }

  .xl\:bg-top{
    background-position: top !important;
  }

  .xl\:bg-repeat{
    background-repeat: repeat !important;
  }

  .xl\:bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .xl\:bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .xl\:bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .xl\:bg-repeat-round{
    background-repeat: round !important;
  }

  .xl\:bg-repeat-space{
    background-repeat: space !important;
  }

  .xl\:bg-origin-border{
    background-origin: border-box !important;
  }

  .xl\:bg-origin-padding{
    background-origin: padding-box !important;
  }

  .xl\:bg-origin-content{
    background-origin: content-box !important;
  }

  .xl\:fill-current{
    fill: currentColor !important;
  }

  .xl\:stroke-current{
    stroke: currentColor !important;
  }

  .xl\:stroke-0{
    stroke-width: 0 !important;
  }

  .xl\:stroke-1{
    stroke-width: 1 !important;
  }

  .xl\:stroke-2{
    stroke-width: 2 !important;
  }

  .xl\:object-contain{
    object-fit: contain !important;
  }

  .xl\:object-cover{
    object-fit: cover !important;
  }

  .xl\:object-fill{
    object-fit: fill !important;
  }

  .xl\:object-none{
    object-fit: none !important;
  }

  .xl\:object-scale-down{
    object-fit: scale-down !important;
  }

  .xl\:object-bottom{
    object-position: bottom !important;
  }

  .xl\:object-center{
    object-position: center !important;
  }

  .xl\:object-left{
    object-position: left !important;
  }

  .xl\:object-left-bottom{
    object-position: left bottom !important;
  }

  .xl\:object-left-top{
    object-position: left top !important;
  }

  .xl\:object-right{
    object-position: right !important;
  }

  .xl\:object-right-bottom{
    object-position: right bottom !important;
  }

  .xl\:object-right-top{
    object-position: right top !important;
  }

  .xl\:object-top{
    object-position: top !important;
  }

  .xl\:p-0{
    padding: 0px !important;
  }

  .xl\:p-1{
    padding: 0.25rem !important;
  }

  .xl\:p-2{
    padding: 0.5rem !important;
  }

  .xl\:p-3{
    padding: 0.75rem !important;
  }

  .xl\:p-4{
    padding: 1rem !important;
  }

  .xl\:p-5{
    padding: 1.25rem !important;
  }

  .xl\:p-6{
    padding: 1.5rem !important;
  }

  .xl\:p-7{
    padding: 1.75rem !important;
  }

  .xl\:p-8{
    padding: 2rem !important;
  }

  .xl\:p-9{
    padding: 2.25rem !important;
  }

  .xl\:p-10{
    padding: 2.5rem !important;
  }

  .xl\:p-11{
    padding: 2.75rem !important;
  }

  .xl\:p-12{
    padding: 3rem !important;
  }

  .xl\:p-14{
    padding: 3.5rem !important;
  }

  .xl\:p-16{
    padding: 4rem !important;
  }

  .xl\:p-20{
    padding: 5rem !important;
  }

  .xl\:p-24{
    padding: 6rem !important;
  }

  .xl\:p-28{
    padding: 7rem !important;
  }

  .xl\:p-32{
    padding: 8rem !important;
  }

  .xl\:p-36{
    padding: 9rem !important;
  }

  .xl\:p-40{
    padding: 10rem !important;
  }

  .xl\:p-44{
    padding: 11rem !important;
  }

  .xl\:p-48{
    padding: 12rem !important;
  }

  .xl\:p-52{
    padding: 13rem !important;
  }

  .xl\:p-56{
    padding: 14rem !important;
  }

  .xl\:p-60{
    padding: 15rem !important;
  }

  .xl\:p-64{
    padding: 16rem !important;
  }

  .xl\:p-72{
    padding: 18rem !important;
  }

  .xl\:p-80{
    padding: 20rem !important;
  }

  .xl\:p-96{
    padding: 24rem !important;
  }

  .xl\:p-px{
    padding: 1px !important;
  }

  .xl\:p-0\.5{
    padding: 0.125rem !important;
  }

  .xl\:p-1\.5{
    padding: 0.375rem !important;
  }

  .xl\:p-2\.5{
    padding: 0.625rem !important;
  }

  .xl\:p-3\.5{
    padding: 0.875rem !important;
  }

  .xl\:px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .xl\:px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xl\:px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xl\:px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xl\:px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xl\:px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .xl\:px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .xl\:px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xl\:px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .xl\:px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .xl\:px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xl\:px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .xl\:px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xl\:px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .xl\:px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xl\:px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .xl\:px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xl\:px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .xl\:px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xl\:px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .xl\:px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xl\:px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .xl\:px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .xl\:px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .xl\:px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xl\:px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .xl\:px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .xl\:px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .xl\:px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .xl\:py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .xl\:py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xl\:py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xl\:py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xl\:py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xl\:py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .xl\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .xl\:py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xl\:py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .xl\:py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .xl\:py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xl\:py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xl\:py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .xl\:py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xl\:py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .xl\:py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xl\:py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .xl\:py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xl\:py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .xl\:py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xl\:py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .xl\:py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xl\:py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .xl\:py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .xl\:py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .xl\:py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xl\:py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .xl\:py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .xl\:py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .xl\:py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .xl\:pt-0{
    padding-top: 0px !important;
  }

  .xl\:pt-1{
    padding-top: 0.25rem !important;
  }

  .xl\:pt-2{
    padding-top: 0.5rem !important;
  }

  .xl\:pt-3{
    padding-top: 0.75rem !important;
  }

  .xl\:pt-4{
    padding-top: 1rem !important;
  }

  .xl\:pt-5{
    padding-top: 1.25rem !important;
  }

  .xl\:pt-6{
    padding-top: 1.5rem !important;
  }

  .xl\:pt-7{
    padding-top: 1.75rem !important;
  }

  .xl\:pt-8{
    padding-top: 2rem !important;
  }

  .xl\:pt-9{
    padding-top: 2.25rem !important;
  }

  .xl\:pt-10{
    padding-top: 2.5rem !important;
  }

  .xl\:pt-11{
    padding-top: 2.75rem !important;
  }

  .xl\:pt-12{
    padding-top: 3rem !important;
  }

  .xl\:pt-14{
    padding-top: 3.5rem !important;
  }

  .xl\:pt-16{
    padding-top: 4rem !important;
  }

  .xl\:pt-20{
    padding-top: 5rem !important;
  }

  .xl\:pt-24{
    padding-top: 6rem !important;
  }

  .xl\:pt-28{
    padding-top: 7rem !important;
  }

  .xl\:pt-32{
    padding-top: 8rem !important;
  }

  .xl\:pt-36{
    padding-top: 9rem !important;
  }

  .xl\:pt-40{
    padding-top: 10rem !important;
  }

  .xl\:pt-44{
    padding-top: 11rem !important;
  }

  .xl\:pt-48{
    padding-top: 12rem !important;
  }

  .xl\:pt-52{
    padding-top: 13rem !important;
  }

  .xl\:pt-56{
    padding-top: 14rem !important;
  }

  .xl\:pt-60{
    padding-top: 15rem !important;
  }

  .xl\:pt-64{
    padding-top: 16rem !important;
  }

  .xl\:pt-72{
    padding-top: 18rem !important;
  }

  .xl\:pt-80{
    padding-top: 20rem !important;
  }

  .xl\:pt-96{
    padding-top: 24rem !important;
  }

  .xl\:pt-px{
    padding-top: 1px !important;
  }

  .xl\:pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .xl\:pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .xl\:pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .xl\:pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .xl\:pr-0{
    padding-right: 0px !important;
  }

  .xl\:pr-1{
    padding-right: 0.25rem !important;
  }

  .xl\:pr-2{
    padding-right: 0.5rem !important;
  }

  .xl\:pr-3{
    padding-right: 0.75rem !important;
  }

  .xl\:pr-4{
    padding-right: 1rem !important;
  }

  .xl\:pr-5{
    padding-right: 1.25rem !important;
  }

  .xl\:pr-6{
    padding-right: 1.5rem !important;
  }

  .xl\:pr-7{
    padding-right: 1.75rem !important;
  }

  .xl\:pr-8{
    padding-right: 2rem !important;
  }

  .xl\:pr-9{
    padding-right: 2.25rem !important;
  }

  .xl\:pr-10{
    padding-right: 2.5rem !important;
  }

  .xl\:pr-11{
    padding-right: 2.75rem !important;
  }

  .xl\:pr-12{
    padding-right: 3rem !important;
  }

  .xl\:pr-14{
    padding-right: 3.5rem !important;
  }

  .xl\:pr-16{
    padding-right: 4rem !important;
  }

  .xl\:pr-20{
    padding-right: 5rem !important;
  }

  .xl\:pr-24{
    padding-right: 6rem !important;
  }

  .xl\:pr-28{
    padding-right: 7rem !important;
  }

  .xl\:pr-32{
    padding-right: 8rem !important;
  }

  .xl\:pr-36{
    padding-right: 9rem !important;
  }

  .xl\:pr-40{
    padding-right: 10rem !important;
  }

  .xl\:pr-44{
    padding-right: 11rem !important;
  }

  .xl\:pr-48{
    padding-right: 12rem !important;
  }

  .xl\:pr-52{
    padding-right: 13rem !important;
  }

  .xl\:pr-56{
    padding-right: 14rem !important;
  }

  .xl\:pr-60{
    padding-right: 15rem !important;
  }

  .xl\:pr-64{
    padding-right: 16rem !important;
  }

  .xl\:pr-72{
    padding-right: 18rem !important;
  }

  .xl\:pr-80{
    padding-right: 20rem !important;
  }

  .xl\:pr-96{
    padding-right: 24rem !important;
  }

  .xl\:pr-px{
    padding-right: 1px !important;
  }

  .xl\:pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .xl\:pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .xl\:pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .xl\:pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .xl\:pb-0{
    padding-bottom: 0px !important;
  }

  .xl\:pb-1{
    padding-bottom: 0.25rem !important;
  }

  .xl\:pb-2{
    padding-bottom: 0.5rem !important;
  }

  .xl\:pb-3{
    padding-bottom: 0.75rem !important;
  }

  .xl\:pb-4{
    padding-bottom: 1rem !important;
  }

  .xl\:pb-5{
    padding-bottom: 1.25rem !important;
  }

  .xl\:pb-6{
    padding-bottom: 1.5rem !important;
  }

  .xl\:pb-7{
    padding-bottom: 1.75rem !important;
  }

  .xl\:pb-8{
    padding-bottom: 2rem !important;
  }

  .xl\:pb-9{
    padding-bottom: 2.25rem !important;
  }

  .xl\:pb-10{
    padding-bottom: 2.5rem !important;
  }

  .xl\:pb-11{
    padding-bottom: 2.75rem !important;
  }

  .xl\:pb-12{
    padding-bottom: 3rem !important;
  }

  .xl\:pb-14{
    padding-bottom: 3.5rem !important;
  }

  .xl\:pb-16{
    padding-bottom: 4rem !important;
  }

  .xl\:pb-20{
    padding-bottom: 5rem !important;
  }

  .xl\:pb-24{
    padding-bottom: 6rem !important;
  }

  .xl\:pb-28{
    padding-bottom: 7rem !important;
  }

  .xl\:pb-32{
    padding-bottom: 8rem !important;
  }

  .xl\:pb-36{
    padding-bottom: 9rem !important;
  }

  .xl\:pb-40{
    padding-bottom: 10rem !important;
  }

  .xl\:pb-44{
    padding-bottom: 11rem !important;
  }

  .xl\:pb-48{
    padding-bottom: 12rem !important;
  }

  .xl\:pb-52{
    padding-bottom: 13rem !important;
  }

  .xl\:pb-56{
    padding-bottom: 14rem !important;
  }

  .xl\:pb-60{
    padding-bottom: 15rem !important;
  }

  .xl\:pb-64{
    padding-bottom: 16rem !important;
  }

  .xl\:pb-72{
    padding-bottom: 18rem !important;
  }

  .xl\:pb-80{
    padding-bottom: 20rem !important;
  }

  .xl\:pb-96{
    padding-bottom: 24rem !important;
  }

  .xl\:pb-px{
    padding-bottom: 1px !important;
  }

  .xl\:pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .xl\:pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .xl\:pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .xl\:pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .xl\:pl-0{
    padding-left: 0px !important;
  }

  .xl\:pl-1{
    padding-left: 0.25rem !important;
  }

  .xl\:pl-2{
    padding-left: 0.5rem !important;
  }

  .xl\:pl-3{
    padding-left: 0.75rem !important;
  }

  .xl\:pl-4{
    padding-left: 1rem !important;
  }

  .xl\:pl-5{
    padding-left: 1.25rem !important;
  }

  .xl\:pl-6{
    padding-left: 1.5rem !important;
  }

  .xl\:pl-7{
    padding-left: 1.75rem !important;
  }

  .xl\:pl-8{
    padding-left: 2rem !important;
  }

  .xl\:pl-9{
    padding-left: 2.25rem !important;
  }

  .xl\:pl-10{
    padding-left: 2.5rem !important;
  }

  .xl\:pl-11{
    padding-left: 2.75rem !important;
  }

  .xl\:pl-12{
    padding-left: 3rem !important;
  }

  .xl\:pl-14{
    padding-left: 3.5rem !important;
  }

  .xl\:pl-16{
    padding-left: 4rem !important;
  }

  .xl\:pl-20{
    padding-left: 5rem !important;
  }

  .xl\:pl-24{
    padding-left: 6rem !important;
  }

  .xl\:pl-28{
    padding-left: 7rem !important;
  }

  .xl\:pl-32{
    padding-left: 8rem !important;
  }

  .xl\:pl-36{
    padding-left: 9rem !important;
  }

  .xl\:pl-40{
    padding-left: 10rem !important;
  }

  .xl\:pl-44{
    padding-left: 11rem !important;
  }

  .xl\:pl-48{
    padding-left: 12rem !important;
  }

  .xl\:pl-52{
    padding-left: 13rem !important;
  }

  .xl\:pl-56{
    padding-left: 14rem !important;
  }

  .xl\:pl-60{
    padding-left: 15rem !important;
  }

  .xl\:pl-64{
    padding-left: 16rem !important;
  }

  .xl\:pl-72{
    padding-left: 18rem !important;
  }

  .xl\:pl-80{
    padding-left: 20rem !important;
  }

  .xl\:pl-96{
    padding-left: 24rem !important;
  }

  .xl\:pl-px{
    padding-left: 1px !important;
  }

  .xl\:pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .xl\:pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .xl\:pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .xl\:pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .xl\:text-left{
    text-align: left !important;
  }

  .xl\:text-center{
    text-align: center !important;
  }

  .xl\:text-right{
    text-align: right !important;
  }

  .xl\:text-justify{
    text-align: justify !important;
  }

  .xl\:align-baseline{
    vertical-align: baseline !important;
  }

  .xl\:align-top{
    vertical-align: top !important;
  }

  .xl\:align-middle{
    vertical-align: middle !important;
  }

  .xl\:align-bottom{
    vertical-align: bottom !important;
  }

  .xl\:align-text-top{
    vertical-align: text-top !important;
  }

  .xl\:align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .xl\:font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .xl\:font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .xl\:font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .xl\:text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .xl\:text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .xl\:text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .xl\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .xl\:text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .xl\:text-2xl{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .xl\:text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .xl\:text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .xl\:text-5xl{
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .xl\:text-6xl{
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .xl\:text-7xl{
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .xl\:text-8xl{
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .xl\:text-9xl{
    font-size: 8rem !important;
    line-height: 1 !important;
  }

  .xl\:font-thin{
    font-weight: 100 !important;
  }

  .xl\:font-extralight{
    font-weight: 200 !important;
  }

  .xl\:font-light{
    font-weight: 300 !important;
  }

  .xl\:font-normal{
    font-weight: 400 !important;
  }

  .xl\:font-medium{
    font-weight: 500 !important;
  }

  .xl\:font-semibold{
    font-weight: 600 !important;
  }

  .xl\:font-bold{
    font-weight: 700 !important;
  }

  .xl\:font-extrabold{
    font-weight: 800 !important;
  }

  .xl\:font-black{
    font-weight: 900 !important;
  }

  .xl\:uppercase{
    text-transform: uppercase !important;
  }

  .xl\:lowercase{
    text-transform: lowercase !important;
  }

  .xl\:capitalize{
    text-transform: capitalize !important;
  }

  .xl\:normal-case{
    text-transform: none !important;
  }

  .xl\:italic{
    font-style: italic !important;
  }

  .xl\:not-italic{
    font-style: normal !important;
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .xl\:normal-nums{
    font-variant-numeric: normal !important;
  }

  .xl\:ordinal{
    --tw-ordinal: ordinal !important;
  }

  .xl\:slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .xl\:lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .xl\:oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .xl\:proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .xl\:tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .xl\:diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .xl\:stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .xl\:leading-3{
    line-height: .75rem !important;
  }

  .xl\:leading-4{
    line-height: 1rem !important;
  }

  .xl\:leading-5{
    line-height: 1.25rem !important;
  }

  .xl\:leading-6{
    line-height: 1.5rem !important;
  }

  .xl\:leading-7{
    line-height: 1.75rem !important;
  }

  .xl\:leading-8{
    line-height: 2rem !important;
  }

  .xl\:leading-9{
    line-height: 2.25rem !important;
  }

  .xl\:leading-10{
    line-height: 2.5rem !important;
  }

  .xl\:leading-none{
    line-height: 1 !important;
  }

  .xl\:leading-tight{
    line-height: 1.25 !important;
  }

  .xl\:leading-snug{
    line-height: 1.375 !important;
  }

  .xl\:leading-normal{
    line-height: 1.5 !important;
  }

  .xl\:leading-relaxed{
    line-height: 1.625 !important;
  }

  .xl\:leading-loose{
    line-height: 2 !important;
  }

  .xl\:tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .xl\:tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .xl\:tracking-normal{
    letter-spacing: 0em !important;
  }

  .xl\:tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .xl\:tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .xl\:tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .xl\:text-transparent{
    color: transparent !important;
  }

  .xl\:text-current{
    color: currentColor !important;
  }

  .xl\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-transparent{
    color: transparent !important;
  }

  .group:hover .xl\:group-hover\:text-current{
    color: currentColor !important;
  }

  .group:hover .xl\:group-hover\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .group:hover .xl\:group-hover\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-transparent:focus-within{
    color: transparent !important;
  }

  .xl\:focus-within\:text-current:focus-within{
    color: currentColor !important;
  }

  .xl\:focus-within\:text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-qipGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:focus-within\:text-portalGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-transparent:hover{
    color: transparent !important;
  }

  .xl\:hover\:text-current:hover{
    color: currentColor !important;
  }

  .xl\:hover\:text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-qipGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:hover\:text-portalGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-transparent:focus{
    color: transparent !important;
  }

  .xl\:focus\:text-current:focus{
    color: currentColor !important;
  }

  .xl\:focus\:text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-qipGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .xl\:focus\:text-portalGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .xl\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .xl\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .group:hover .xl\:group-hover\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .xl\:focus-within\:text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .xl\:focus-within\:text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:focus-within\:text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:focus-within\:text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:focus-within\:text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:focus-within\:text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:focus-within\:text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:focus-within\:text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:focus-within\:text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:focus-within\:text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:focus-within\:text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:focus-within\:text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:focus-within\:text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:focus-within\:text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:focus-within\:text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .xl\:hover\:text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .xl\:hover\:text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:hover\:text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:hover\:text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:hover\:text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:hover\:text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:hover\:text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:hover\:text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:hover\:text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:hover\:text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:hover\:text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:hover\:text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:hover\:text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:hover\:text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:hover\:text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .xl\:focus\:text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .xl\:focus\:text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .xl\:focus\:text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .xl\:focus\:text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .xl\:focus\:text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .xl\:focus\:text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .xl\:focus\:text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .xl\:focus\:text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .xl\:focus\:text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .xl\:focus\:text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .xl\:focus\:text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .xl\:focus\:text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .xl\:focus\:text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .xl\:focus\:text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .xl\:focus\:text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .xl\:underline{
    text-decoration: underline !important;
  }

  .xl\:line-through{
    text-decoration: line-through !important;
  }

  .xl\:no-underline{
    text-decoration: none !important;
  }

  .group:hover .xl\:group-hover\:underline{
    text-decoration: underline !important;
  }

  .group:hover .xl\:group-hover\:line-through{
    text-decoration: line-through !important;
  }

  .group:hover .xl\:group-hover\:no-underline{
    text-decoration: none !important;
  }

  .xl\:focus-within\:underline:focus-within{
    text-decoration: underline !important;
  }

  .xl\:focus-within\:line-through:focus-within{
    text-decoration: line-through !important;
  }

  .xl\:focus-within\:no-underline:focus-within{
    text-decoration: none !important;
  }

  .xl\:hover\:underline:hover{
    text-decoration: underline !important;
  }

  .xl\:hover\:line-through:hover{
    text-decoration: line-through !important;
  }

  .xl\:hover\:no-underline:hover{
    text-decoration: none !important;
  }

  .xl\:focus\:underline:focus{
    text-decoration: underline !important;
  }

  .xl\:focus\:line-through:focus{
    text-decoration: line-through !important;
  }

  .xl\:focus\:no-underline:focus{
    text-decoration: none !important;
  }

  .xl\:antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .xl\:subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .xl\:placeholder-transparent::-webkit-input-placeholder{
    color: transparent !important;
  }

  .xl\:placeholder-transparent:-ms-input-placeholder{
    color: transparent !important;
  }

  .xl\:placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .xl\:placeholder-current::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .xl\:placeholder-current:-ms-input-placeholder{
    color: currentColor !important;
  }

  .xl\:placeholder-current::placeholder{
    color: currentColor !important;
  }

  .xl\:placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-black:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-white:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-qipGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-qipGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-qipGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-portalGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-portalGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-portalGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent !important;
  }

  .xl\:focus\:placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent !important;
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .xl\:focus\:placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .xl\:focus\:placeholder-current:focus:-ms-input-placeholder{
    color: currentColor !important;
  }

  .xl\:focus\:placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .xl\:focus\:placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-black:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-white:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-qipGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-qipGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-qipGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-portalGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-portalGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:focus\:placeholder-portalGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .xl\:placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-5::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:placeholder-opacity-5:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:placeholder-opacity-10::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:placeholder-opacity-10:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:placeholder-opacity-20::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:placeholder-opacity-20:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-30::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:placeholder-opacity-30:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:placeholder-opacity-40::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:placeholder-opacity-40:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-60::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:placeholder-opacity-60:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:placeholder-opacity-70::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:placeholder-opacity-70:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-80::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:placeholder-opacity-80:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:placeholder-opacity-90:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:placeholder-opacity-95::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:placeholder-opacity-95:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:focus\:placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .xl\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:focus\:placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .xl\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:focus\:placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:focus\:placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .xl\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:focus\:placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:focus\:placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:focus\:placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .xl\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:focus\:placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .xl\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:focus\:placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .xl\:opacity-0{
    opacity: 0 !important;
  }

  .xl\:opacity-5{
    opacity: 0.05 !important;
  }

  .xl\:opacity-10{
    opacity: 0.1 !important;
  }

  .xl\:opacity-20{
    opacity: 0.2 !important;
  }

  .xl\:opacity-25{
    opacity: 0.25 !important;
  }

  .xl\:opacity-30{
    opacity: 0.3 !important;
  }

  .xl\:opacity-40{
    opacity: 0.4 !important;
  }

  .xl\:opacity-50{
    opacity: 0.5 !important;
  }

  .xl\:opacity-60{
    opacity: 0.6 !important;
  }

  .xl\:opacity-70{
    opacity: 0.7 !important;
  }

  .xl\:opacity-75{
    opacity: 0.75 !important;
  }

  .xl\:opacity-80{
    opacity: 0.8 !important;
  }

  .xl\:opacity-90{
    opacity: 0.9 !important;
  }

  .xl\:opacity-95{
    opacity: 0.95 !important;
  }

  .xl\:opacity-100{
    opacity: 1 !important;
  }

  .group:hover .xl\:group-hover\:opacity-0{
    opacity: 0 !important;
  }

  .group:hover .xl\:group-hover\:opacity-5{
    opacity: 0.05 !important;
  }

  .group:hover .xl\:group-hover\:opacity-10{
    opacity: 0.1 !important;
  }

  .group:hover .xl\:group-hover\:opacity-20{
    opacity: 0.2 !important;
  }

  .group:hover .xl\:group-hover\:opacity-25{
    opacity: 0.25 !important;
  }

  .group:hover .xl\:group-hover\:opacity-30{
    opacity: 0.3 !important;
  }

  .group:hover .xl\:group-hover\:opacity-40{
    opacity: 0.4 !important;
  }

  .group:hover .xl\:group-hover\:opacity-50{
    opacity: 0.5 !important;
  }

  .group:hover .xl\:group-hover\:opacity-60{
    opacity: 0.6 !important;
  }

  .group:hover .xl\:group-hover\:opacity-70{
    opacity: 0.7 !important;
  }

  .group:hover .xl\:group-hover\:opacity-75{
    opacity: 0.75 !important;
  }

  .group:hover .xl\:group-hover\:opacity-80{
    opacity: 0.8 !important;
  }

  .group:hover .xl\:group-hover\:opacity-90{
    opacity: 0.9 !important;
  }

  .group:hover .xl\:group-hover\:opacity-95{
    opacity: 0.95 !important;
  }

  .group:hover .xl\:group-hover\:opacity-100{
    opacity: 1 !important;
  }

  .xl\:focus-within\:opacity-0:focus-within{
    opacity: 0 !important;
  }

  .xl\:focus-within\:opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .xl\:focus-within\:opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .xl\:focus-within\:opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .xl\:focus-within\:opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .xl\:focus-within\:opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .xl\:focus-within\:opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .xl\:focus-within\:opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .xl\:focus-within\:opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .xl\:focus-within\:opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .xl\:focus-within\:opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .xl\:focus-within\:opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .xl\:focus-within\:opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .xl\:focus-within\:opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .xl\:focus-within\:opacity-100:focus-within{
    opacity: 1 !important;
  }

  .xl\:hover\:opacity-0:hover{
    opacity: 0 !important;
  }

  .xl\:hover\:opacity-5:hover{
    opacity: 0.05 !important;
  }

  .xl\:hover\:opacity-10:hover{
    opacity: 0.1 !important;
  }

  .xl\:hover\:opacity-20:hover{
    opacity: 0.2 !important;
  }

  .xl\:hover\:opacity-25:hover{
    opacity: 0.25 !important;
  }

  .xl\:hover\:opacity-30:hover{
    opacity: 0.3 !important;
  }

  .xl\:hover\:opacity-40:hover{
    opacity: 0.4 !important;
  }

  .xl\:hover\:opacity-50:hover{
    opacity: 0.5 !important;
  }

  .xl\:hover\:opacity-60:hover{
    opacity: 0.6 !important;
  }

  .xl\:hover\:opacity-70:hover{
    opacity: 0.7 !important;
  }

  .xl\:hover\:opacity-75:hover{
    opacity: 0.75 !important;
  }

  .xl\:hover\:opacity-80:hover{
    opacity: 0.8 !important;
  }

  .xl\:hover\:opacity-90:hover{
    opacity: 0.9 !important;
  }

  .xl\:hover\:opacity-95:hover{
    opacity: 0.95 !important;
  }

  .xl\:hover\:opacity-100:hover{
    opacity: 1 !important;
  }

  .xl\:focus\:opacity-0:focus{
    opacity: 0 !important;
  }

  .xl\:focus\:opacity-5:focus{
    opacity: 0.05 !important;
  }

  .xl\:focus\:opacity-10:focus{
    opacity: 0.1 !important;
  }

  .xl\:focus\:opacity-20:focus{
    opacity: 0.2 !important;
  }

  .xl\:focus\:opacity-25:focus{
    opacity: 0.25 !important;
  }

  .xl\:focus\:opacity-30:focus{
    opacity: 0.3 !important;
  }

  .xl\:focus\:opacity-40:focus{
    opacity: 0.4 !important;
  }

  .xl\:focus\:opacity-50:focus{
    opacity: 0.5 !important;
  }

  .xl\:focus\:opacity-60:focus{
    opacity: 0.6 !important;
  }

  .xl\:focus\:opacity-70:focus{
    opacity: 0.7 !important;
  }

  .xl\:focus\:opacity-75:focus{
    opacity: 0.75 !important;
  }

  .xl\:focus\:opacity-80:focus{
    opacity: 0.8 !important;
  }

  .xl\:focus\:opacity-90:focus{
    opacity: 0.9 !important;
  }

  .xl\:focus\:opacity-95:focus{
    opacity: 0.95 !important;
  }

  .xl\:focus\:opacity-100:focus{
    opacity: 1 !important;
  }

  .xl\:bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .xl\:bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .xl\:bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .xl\:bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .xl\:bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .xl\:bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .xl\:bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .xl\:bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .xl\:bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .xl\:bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .xl\:bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .xl\:bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .xl\:bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .xl\:bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .xl\:bg-blend-color{
    background-blend-mode: color !important;
  }

  .xl\:bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .xl\:mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .xl\:mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .xl\:mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .xl\:mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .xl\:mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .xl\:mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .xl\:mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .xl\:mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .xl\:mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .xl\:mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .xl\:mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .xl\:mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .xl\:mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .xl\:mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .xl\:mix-blend-color{
    mix-blend-mode: color !important;
  }

  .xl\:mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .xl\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .xl\:group-hover\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus-within\:shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:hover\:shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:focus\:shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .xl\:outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .xl\:outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .xl\:outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .xl\:focus-within\:outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .xl\:focus-within\:outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .xl\:focus-within\:outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .xl\:focus\:outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .xl\:focus\:outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .xl\:focus\:outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .xl\:ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:focus\:ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .xl\:ring-inset{
    --tw-ring-inset: inset !important;
  }

  .xl\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .xl\:focus\:ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .xl\:ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .xl\:ring-current{
    --tw-ring-color: currentColor !important;
  }

  .xl\:ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-qipGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-portalGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .xl\:focus-within\:ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .xl\:focus-within\:ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-qipGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus-within\:ring-portalGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .xl\:focus\:ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .xl\:focus\:ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-qipGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .xl\:focus\:ring-portalGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .xl\:ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .xl\:ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .xl\:ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .xl\:ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .xl\:ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .xl\:ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .xl\:ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .xl\:ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .xl\:ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .xl\:ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .xl\:ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .xl\:ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .xl\:ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .xl\:ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .xl\:ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .xl\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .xl\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .xl\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .xl\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .xl\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .xl\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .xl\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .xl\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .xl\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .xl\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .xl\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .xl\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .xl\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .xl\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .xl\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .xl\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .xl\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .xl\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .xl\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .xl\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .xl\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .xl\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .xl\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .xl\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .xl\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .xl\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .xl\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .xl\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .xl\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .xl\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .xl\:ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .xl\:ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .xl\:ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .xl\:ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .xl\:ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .xl\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .xl\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .xl\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .xl\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .xl\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .xl\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .xl\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .xl\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .xl\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .xl\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .xl\:ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .xl\:ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .xl\:ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .xl\:ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .xl\:ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .xl\:ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .xl\:ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .xl\:ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .xl\:ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .xl\:ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .xl\:ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .xl\:ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .xl\:ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .xl\:ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .xl\:ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .xl\:ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .xl\:ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .xl\:ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .xl\:ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .xl\:ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .xl\:ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .xl\:ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .xl\:ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .xl\:ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .xl\:ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .xl\:ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .xl\:ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .xl\:ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .xl\:ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .xl\:ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .xl\:ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .xl\:ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .xl\:ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .xl\:ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .xl\:ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .xl\:ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .xl\:ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .xl\:ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .xl\:ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .xl\:ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .xl\:ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .xl\:ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .xl\:ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .xl\:ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .xl\:ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .xl\:ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .xl\:ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .xl\:ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .xl\:ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .xl\:ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .xl\:ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .xl\:ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .xl\:ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .xl\:ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .xl\:ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .xl\:ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .xl\:ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .xl\:ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .xl\:ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .xl\:ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .xl\:ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .xl\:ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .xl\:ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .xl\:ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .xl\:ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .xl\:ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .xl\:ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .xl\:ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .xl\:ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .xl\:ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .xl\:ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .xl\:ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .xl\:ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .xl\:ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .xl\:ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .xl\:ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .xl\:ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .xl\:ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .xl\:ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .xl\:ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .xl\:ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .xl\:ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .xl\:ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .xl\:ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .xl\:ring-offset-qipGreen{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .xl\:ring-offset-portalGreen{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .xl\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .xl\:focus-within\:ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .xl\:focus-within\:ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .xl\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .xl\:focus-within\:ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .xl\:focus-within\:ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .xl\:focus-within\:ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .xl\:focus-within\:ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .xl\:focus-within\:ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .xl\:focus-within\:ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .xl\:focus-within\:ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .xl\:focus-within\:ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .xl\:focus-within\:ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .xl\:focus-within\:ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .xl\:focus-within\:ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .xl\:focus-within\:ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .xl\:focus-within\:ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .xl\:focus-within\:ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .xl\:focus-within\:ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .xl\:focus-within\:ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .xl\:focus-within\:ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .xl\:focus-within\:ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .xl\:focus-within\:ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .xl\:focus-within\:ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .xl\:focus-within\:ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .xl\:focus-within\:ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .xl\:focus-within\:ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .xl\:focus-within\:ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .xl\:focus-within\:ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .xl\:focus-within\:ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .xl\:focus-within\:ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .xl\:focus-within\:ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .xl\:focus-within\:ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .xl\:focus-within\:ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .xl\:focus-within\:ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .xl\:focus-within\:ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .xl\:focus-within\:ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .xl\:focus-within\:ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .xl\:focus-within\:ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .xl\:focus-within\:ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .xl\:focus-within\:ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .xl\:focus-within\:ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .xl\:focus-within\:ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .xl\:focus-within\:ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .xl\:focus-within\:ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .xl\:focus-within\:ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .xl\:focus-within\:ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .xl\:focus-within\:ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .xl\:focus-within\:ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .xl\:focus-within\:ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .xl\:focus-within\:ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .xl\:focus-within\:ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .xl\:focus-within\:ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .xl\:focus-within\:ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .xl\:focus-within\:ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .xl\:focus-within\:ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .xl\:focus-within\:ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .xl\:focus-within\:ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .xl\:focus-within\:ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .xl\:focus-within\:ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .xl\:focus-within\:ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .xl\:focus-within\:ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .xl\:focus-within\:ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .xl\:focus-within\:ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .xl\:focus-within\:ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .xl\:focus-within\:ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .xl\:focus-within\:ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .xl\:focus-within\:ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .xl\:focus-within\:ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .xl\:focus-within\:ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .xl\:focus-within\:ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .xl\:focus-within\:ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .xl\:focus-within\:ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .xl\:focus-within\:ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .xl\:focus-within\:ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .xl\:focus-within\:ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .xl\:focus-within\:ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .xl\:focus-within\:ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .xl\:focus-within\:ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .xl\:focus-within\:ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .xl\:focus-within\:ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .xl\:focus-within\:ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .xl\:focus-within\:ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .xl\:focus-within\:ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .xl\:focus-within\:ring-offset-qipGreen:focus-within{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .xl\:focus-within\:ring-offset-portalGreen:focus-within{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .xl\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .xl\:focus\:ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .xl\:focus\:ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .xl\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .xl\:focus\:ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .xl\:focus\:ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .xl\:focus\:ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .xl\:focus\:ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .xl\:focus\:ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .xl\:focus\:ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .xl\:focus\:ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .xl\:focus\:ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .xl\:focus\:ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .xl\:focus\:ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .xl\:focus\:ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .xl\:focus\:ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .xl\:focus\:ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .xl\:focus\:ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .xl\:focus\:ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .xl\:focus\:ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .xl\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .xl\:focus\:ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .xl\:focus\:ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .xl\:focus\:ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .xl\:focus\:ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .xl\:focus\:ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .xl\:focus\:ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .xl\:focus\:ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .xl\:focus\:ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .xl\:focus\:ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .xl\:focus\:ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .xl\:focus\:ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .xl\:focus\:ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .xl\:focus\:ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .xl\:focus\:ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .xl\:focus\:ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .xl\:focus\:ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .xl\:focus\:ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .xl\:focus\:ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .xl\:focus\:ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .xl\:focus\:ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .xl\:focus\:ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .xl\:focus\:ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .xl\:focus\:ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .xl\:focus\:ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .xl\:focus\:ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .xl\:focus\:ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .xl\:focus\:ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .xl\:focus\:ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .xl\:focus\:ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .xl\:focus\:ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .xl\:focus\:ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .xl\:focus\:ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .xl\:focus\:ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .xl\:focus\:ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .xl\:focus\:ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .xl\:focus\:ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .xl\:focus\:ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .xl\:focus\:ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .xl\:focus\:ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .xl\:focus\:ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .xl\:focus\:ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .xl\:focus\:ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .xl\:focus\:ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .xl\:focus\:ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .xl\:focus\:ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .xl\:focus\:ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .xl\:focus\:ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .xl\:focus\:ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .xl\:focus\:ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .xl\:focus\:ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .xl\:focus\:ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .xl\:focus\:ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .xl\:focus\:ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .xl\:focus\:ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .xl\:focus\:ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .xl\:focus\:ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .xl\:focus\:ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .xl\:focus\:ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .xl\:focus\:ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .xl\:focus\:ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .xl\:focus\:ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .xl\:focus\:ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .xl\:focus\:ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .xl\:focus\:ring-offset-qipGreen:focus{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .xl\:focus\:ring-offset-portalGreen:focus{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .xl\:filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .xl\:filter-none{
    -webkit-filter: none !important;
            filter: none !important;
  }

  .xl\:blur-0{
    --tw-blur: blur(0) !important;
  }

  .xl\:blur-none{
    --tw-blur: blur(0) !important;
  }

  .xl\:blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .xl\:blur{
    --tw-blur: blur(8px) !important;
  }

  .xl\:blur-md{
    --tw-blur: blur(12px) !important;
  }

  .xl\:blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .xl\:blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .xl\:blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .xl\:blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .xl\:brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .xl\:brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .xl\:brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .xl\:brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .xl\:brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .xl\:brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .xl\:brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .xl\:brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .xl\:brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .xl\:brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .xl\:brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .xl\:contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .xl\:contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .xl\:contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .xl\:contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .xl\:contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .xl\:contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .xl\:contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .xl\:drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .xl\:drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .xl\:drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .xl\:drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .xl\:drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .xl\:drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .xl\:drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .xl\:grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .xl\:grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .xl\:hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .xl\:hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .xl\:hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .xl\:hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .xl\:hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .xl\:hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .xl\:-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .xl\:-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .xl\:-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .xl\:-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .xl\:-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .xl\:invert-0{
    --tw-invert: invert(0) !important;
  }

  .xl\:invert{
    --tw-invert: invert(100%) !important;
  }

  .xl\:saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .xl\:saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .xl\:saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .xl\:saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .xl\:saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .xl\:sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .xl\:sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .xl\:backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .xl\:backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .xl\:backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .xl\:backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .xl\:backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .xl\:backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .xl\:backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .xl\:backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .xl\:backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .xl\:backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .xl\:backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .xl\:backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .xl\:backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .xl\:backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .xl\:backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .xl\:backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .xl\:backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .xl\:backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .xl\:backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .xl\:backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .xl\:backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .xl\:backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .xl\:backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .xl\:backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .xl\:backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .xl\:backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .xl\:backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .xl\:backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .xl\:backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .xl\:backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .xl\:backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .xl\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .xl\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .xl\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .xl\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .xl\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .xl\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .xl\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .xl\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .xl\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .xl\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .xl\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .xl\:backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .xl\:backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .xl\:backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .xl\:backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .xl\:backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .xl\:backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .xl\:backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .xl\:backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .xl\:backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .xl\:backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .xl\:backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .xl\:backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .xl\:backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .xl\:backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .xl\:backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .xl\:backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .xl\:backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .xl\:backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .xl\:backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .xl\:backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .xl\:backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .xl\:backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .xl\:backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .xl\:backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .xl\:transition-none{
    transition-property: none !important;
  }

  .xl\:transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .xl\:delay-75{
    transition-delay: 75ms !important;
  }

  .xl\:delay-100{
    transition-delay: 100ms !important;
  }

  .xl\:delay-150{
    transition-delay: 150ms !important;
  }

  .xl\:delay-200{
    transition-delay: 200ms !important;
  }

  .xl\:delay-300{
    transition-delay: 300ms !important;
  }

  .xl\:delay-500{
    transition-delay: 500ms !important;
  }

  .xl\:delay-700{
    transition-delay: 700ms !important;
  }

  .xl\:delay-1000{
    transition-delay: 1000ms !important;
  }

  .xl\:duration-75{
    transition-duration: 75ms !important;
  }

  .xl\:duration-100{
    transition-duration: 100ms !important;
  }

  .xl\:duration-150{
    transition-duration: 150ms !important;
  }

  .xl\:duration-200{
    transition-duration: 200ms !important;
  }

  .xl\:duration-300{
    transition-duration: 300ms !important;
  }

  .xl\:duration-500{
    transition-duration: 500ms !important;
  }

  .xl\:duration-700{
    transition-duration: 700ms !important;
  }

  .xl\:duration-1000{
    transition-duration: 1000ms !important;
  }

  .xl\:ease-linear{
    transition-timing-function: linear !important;
  }

  .xl\:ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .xl\:ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .xl\:ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

@media (min-width: 1536px){
  .\32xl\:container{
    width: 100%;
  }

  @media (min-width: 640px){
    .\32xl\:container{
      max-width: 640px;
    }
  }

  @media (min-width: 768px){
    .\32xl\:container{
      max-width: 768px;
    }
  }

  @media (min-width: 1024px){
    .\32xl\:container{
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px){
    .\32xl\:container{
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px){
    .\32xl\:container{
      max-width: 1536px;
    }
  }

  .\32xl\:sr-only{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .\32xl\:not-sr-only{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .\32xl\:focus-within\:sr-only:focus-within{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .\32xl\:focus-within\:not-sr-only:focus-within{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .\32xl\:focus\:sr-only:focus{
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .\32xl\:focus\:not-sr-only:focus{
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .\32xl\:pointer-events-none{
    pointer-events: none !important;
  }

  .\32xl\:pointer-events-auto{
    pointer-events: auto !important;
  }

  .\32xl\:visible{
    visibility: visible !important;
  }

  .\32xl\:invisible{
    visibility: hidden !important;
  }

  .\32xl\:static{
    position: static !important;
  }

  .\32xl\:fixed{
    position: fixed !important;
  }

  .\32xl\:absolute{
    position: absolute !important;
  }

  .\32xl\:relative{
    position: relative !important;
  }

  .\32xl\:sticky{
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .\32xl\:inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .\32xl\:inset-1{
    top: 0.25rem !important;
    right: 0.25rem !important;
    bottom: 0.25rem !important;
    left: 0.25rem !important;
  }

  .\32xl\:inset-2{
    top: 0.5rem !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    left: 0.5rem !important;
  }

  .\32xl\:inset-3{
    top: 0.75rem !important;
    right: 0.75rem !important;
    bottom: 0.75rem !important;
    left: 0.75rem !important;
  }

  .\32xl\:inset-4{
    top: 1rem !important;
    right: 1rem !important;
    bottom: 1rem !important;
    left: 1rem !important;
  }

  .\32xl\:inset-5{
    top: 1.25rem !important;
    right: 1.25rem !important;
    bottom: 1.25rem !important;
    left: 1.25rem !important;
  }

  .\32xl\:inset-6{
    top: 1.5rem !important;
    right: 1.5rem !important;
    bottom: 1.5rem !important;
    left: 1.5rem !important;
  }

  .\32xl\:inset-7{
    top: 1.75rem !important;
    right: 1.75rem !important;
    bottom: 1.75rem !important;
    left: 1.75rem !important;
  }

  .\32xl\:inset-8{
    top: 2rem !important;
    right: 2rem !important;
    bottom: 2rem !important;
    left: 2rem !important;
  }

  .\32xl\:inset-9{
    top: 2.25rem !important;
    right: 2.25rem !important;
    bottom: 2.25rem !important;
    left: 2.25rem !important;
  }

  .\32xl\:inset-10{
    top: 2.5rem !important;
    right: 2.5rem !important;
    bottom: 2.5rem !important;
    left: 2.5rem !important;
  }

  .\32xl\:inset-11{
    top: 2.75rem !important;
    right: 2.75rem !important;
    bottom: 2.75rem !important;
    left: 2.75rem !important;
  }

  .\32xl\:inset-12{
    top: 3rem !important;
    right: 3rem !important;
    bottom: 3rem !important;
    left: 3rem !important;
  }

  .\32xl\:inset-14{
    top: 3.5rem !important;
    right: 3.5rem !important;
    bottom: 3.5rem !important;
    left: 3.5rem !important;
  }

  .\32xl\:inset-16{
    top: 4rem !important;
    right: 4rem !important;
    bottom: 4rem !important;
    left: 4rem !important;
  }

  .\32xl\:inset-20{
    top: 5rem !important;
    right: 5rem !important;
    bottom: 5rem !important;
    left: 5rem !important;
  }

  .\32xl\:inset-24{
    top: 6rem !important;
    right: 6rem !important;
    bottom: 6rem !important;
    left: 6rem !important;
  }

  .\32xl\:inset-28{
    top: 7rem !important;
    right: 7rem !important;
    bottom: 7rem !important;
    left: 7rem !important;
  }

  .\32xl\:inset-32{
    top: 8rem !important;
    right: 8rem !important;
    bottom: 8rem !important;
    left: 8rem !important;
  }

  .\32xl\:inset-36{
    top: 9rem !important;
    right: 9rem !important;
    bottom: 9rem !important;
    left: 9rem !important;
  }

  .\32xl\:inset-40{
    top: 10rem !important;
    right: 10rem !important;
    bottom: 10rem !important;
    left: 10rem !important;
  }

  .\32xl\:inset-44{
    top: 11rem !important;
    right: 11rem !important;
    bottom: 11rem !important;
    left: 11rem !important;
  }

  .\32xl\:inset-48{
    top: 12rem !important;
    right: 12rem !important;
    bottom: 12rem !important;
    left: 12rem !important;
  }

  .\32xl\:inset-52{
    top: 13rem !important;
    right: 13rem !important;
    bottom: 13rem !important;
    left: 13rem !important;
  }

  .\32xl\:inset-56{
    top: 14rem !important;
    right: 14rem !important;
    bottom: 14rem !important;
    left: 14rem !important;
  }

  .\32xl\:inset-60{
    top: 15rem !important;
    right: 15rem !important;
    bottom: 15rem !important;
    left: 15rem !important;
  }

  .\32xl\:inset-64{
    top: 16rem !important;
    right: 16rem !important;
    bottom: 16rem !important;
    left: 16rem !important;
  }

  .\32xl\:inset-72{
    top: 18rem !important;
    right: 18rem !important;
    bottom: 18rem !important;
    left: 18rem !important;
  }

  .\32xl\:inset-80{
    top: 20rem !important;
    right: 20rem !important;
    bottom: 20rem !important;
    left: 20rem !important;
  }

  .\32xl\:inset-96{
    top: 24rem !important;
    right: 24rem !important;
    bottom: 24rem !important;
    left: 24rem !important;
  }

  .\32xl\:inset-auto{
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .\32xl\:inset-px{
    top: 1px !important;
    right: 1px !important;
    bottom: 1px !important;
    left: 1px !important;
  }

  .\32xl\:inset-0\.5{
    top: 0.125rem !important;
    right: 0.125rem !important;
    bottom: 0.125rem !important;
    left: 0.125rem !important;
  }

  .\32xl\:inset-1\.5{
    top: 0.375rem !important;
    right: 0.375rem !important;
    bottom: 0.375rem !important;
    left: 0.375rem !important;
  }

  .\32xl\:inset-2\.5{
    top: 0.625rem !important;
    right: 0.625rem !important;
    bottom: 0.625rem !important;
    left: 0.625rem !important;
  }

  .\32xl\:inset-3\.5{
    top: 0.875rem !important;
    right: 0.875rem !important;
    bottom: 0.875rem !important;
    left: 0.875rem !important;
  }

  .\32xl\:-inset-0{
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
  }

  .\32xl\:-inset-1{
    top: -0.25rem !important;
    right: -0.25rem !important;
    bottom: -0.25rem !important;
    left: -0.25rem !important;
  }

  .\32xl\:-inset-2{
    top: -0.5rem !important;
    right: -0.5rem !important;
    bottom: -0.5rem !important;
    left: -0.5rem !important;
  }

  .\32xl\:-inset-3{
    top: -0.75rem !important;
    right: -0.75rem !important;
    bottom: -0.75rem !important;
    left: -0.75rem !important;
  }

  .\32xl\:-inset-4{
    top: -1rem !important;
    right: -1rem !important;
    bottom: -1rem !important;
    left: -1rem !important;
  }

  .\32xl\:-inset-5{
    top: -1.25rem !important;
    right: -1.25rem !important;
    bottom: -1.25rem !important;
    left: -1.25rem !important;
  }

  .\32xl\:-inset-6{
    top: -1.5rem !important;
    right: -1.5rem !important;
    bottom: -1.5rem !important;
    left: -1.5rem !important;
  }

  .\32xl\:-inset-7{
    top: -1.75rem !important;
    right: -1.75rem !important;
    bottom: -1.75rem !important;
    left: -1.75rem !important;
  }

  .\32xl\:-inset-8{
    top: -2rem !important;
    right: -2rem !important;
    bottom: -2rem !important;
    left: -2rem !important;
  }

  .\32xl\:-inset-9{
    top: -2.25rem !important;
    right: -2.25rem !important;
    bottom: -2.25rem !important;
    left: -2.25rem !important;
  }

  .\32xl\:-inset-10{
    top: -2.5rem !important;
    right: -2.5rem !important;
    bottom: -2.5rem !important;
    left: -2.5rem !important;
  }

  .\32xl\:-inset-11{
    top: -2.75rem !important;
    right: -2.75rem !important;
    bottom: -2.75rem !important;
    left: -2.75rem !important;
  }

  .\32xl\:-inset-12{
    top: -3rem !important;
    right: -3rem !important;
    bottom: -3rem !important;
    left: -3rem !important;
  }

  .\32xl\:-inset-14{
    top: -3.5rem !important;
    right: -3.5rem !important;
    bottom: -3.5rem !important;
    left: -3.5rem !important;
  }

  .\32xl\:-inset-16{
    top: -4rem !important;
    right: -4rem !important;
    bottom: -4rem !important;
    left: -4rem !important;
  }

  .\32xl\:-inset-20{
    top: -5rem !important;
    right: -5rem !important;
    bottom: -5rem !important;
    left: -5rem !important;
  }

  .\32xl\:-inset-24{
    top: -6rem !important;
    right: -6rem !important;
    bottom: -6rem !important;
    left: -6rem !important;
  }

  .\32xl\:-inset-28{
    top: -7rem !important;
    right: -7rem !important;
    bottom: -7rem !important;
    left: -7rem !important;
  }

  .\32xl\:-inset-32{
    top: -8rem !important;
    right: -8rem !important;
    bottom: -8rem !important;
    left: -8rem !important;
  }

  .\32xl\:-inset-36{
    top: -9rem !important;
    right: -9rem !important;
    bottom: -9rem !important;
    left: -9rem !important;
  }

  .\32xl\:-inset-40{
    top: -10rem !important;
    right: -10rem !important;
    bottom: -10rem !important;
    left: -10rem !important;
  }

  .\32xl\:-inset-44{
    top: -11rem !important;
    right: -11rem !important;
    bottom: -11rem !important;
    left: -11rem !important;
  }

  .\32xl\:-inset-48{
    top: -12rem !important;
    right: -12rem !important;
    bottom: -12rem !important;
    left: -12rem !important;
  }

  .\32xl\:-inset-52{
    top: -13rem !important;
    right: -13rem !important;
    bottom: -13rem !important;
    left: -13rem !important;
  }

  .\32xl\:-inset-56{
    top: -14rem !important;
    right: -14rem !important;
    bottom: -14rem !important;
    left: -14rem !important;
  }

  .\32xl\:-inset-60{
    top: -15rem !important;
    right: -15rem !important;
    bottom: -15rem !important;
    left: -15rem !important;
  }

  .\32xl\:-inset-64{
    top: -16rem !important;
    right: -16rem !important;
    bottom: -16rem !important;
    left: -16rem !important;
  }

  .\32xl\:-inset-72{
    top: -18rem !important;
    right: -18rem !important;
    bottom: -18rem !important;
    left: -18rem !important;
  }

  .\32xl\:-inset-80{
    top: -20rem !important;
    right: -20rem !important;
    bottom: -20rem !important;
    left: -20rem !important;
  }

  .\32xl\:-inset-96{
    top: -24rem !important;
    right: -24rem !important;
    bottom: -24rem !important;
    left: -24rem !important;
  }

  .\32xl\:-inset-px{
    top: -1px !important;
    right: -1px !important;
    bottom: -1px !important;
    left: -1px !important;
  }

  .\32xl\:-inset-0\.5{
    top: -0.125rem !important;
    right: -0.125rem !important;
    bottom: -0.125rem !important;
    left: -0.125rem !important;
  }

  .\32xl\:-inset-1\.5{
    top: -0.375rem !important;
    right: -0.375rem !important;
    bottom: -0.375rem !important;
    left: -0.375rem !important;
  }

  .\32xl\:-inset-2\.5{
    top: -0.625rem !important;
    right: -0.625rem !important;
    bottom: -0.625rem !important;
    left: -0.625rem !important;
  }

  .\32xl\:-inset-3\.5{
    top: -0.875rem !important;
    right: -0.875rem !important;
    bottom: -0.875rem !important;
    left: -0.875rem !important;
  }

  .\32xl\:inset-1\/2{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .\32xl\:inset-1\/3{
    top: 33.333333% !important;
    right: 33.333333% !important;
    bottom: 33.333333% !important;
    left: 33.333333% !important;
  }

  .\32xl\:inset-2\/3{
    top: 66.666667% !important;
    right: 66.666667% !important;
    bottom: 66.666667% !important;
    left: 66.666667% !important;
  }

  .\32xl\:inset-1\/4{
    top: 25% !important;
    right: 25% !important;
    bottom: 25% !important;
    left: 25% !important;
  }

  .\32xl\:inset-2\/4{
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
  }

  .\32xl\:inset-3\/4{
    top: 75% !important;
    right: 75% !important;
    bottom: 75% !important;
    left: 75% !important;
  }

  .\32xl\:inset-full{
    top: 100% !important;
    right: 100% !important;
    bottom: 100% !important;
    left: 100% !important;
  }

  .\32xl\:-inset-1\/2{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .\32xl\:-inset-1\/3{
    top: -33.333333% !important;
    right: -33.333333% !important;
    bottom: -33.333333% !important;
    left: -33.333333% !important;
  }

  .\32xl\:-inset-2\/3{
    top: -66.666667% !important;
    right: -66.666667% !important;
    bottom: -66.666667% !important;
    left: -66.666667% !important;
  }

  .\32xl\:-inset-1\/4{
    top: -25% !important;
    right: -25% !important;
    bottom: -25% !important;
    left: -25% !important;
  }

  .\32xl\:-inset-2\/4{
    top: -50% !important;
    right: -50% !important;
    bottom: -50% !important;
    left: -50% !important;
  }

  .\32xl\:-inset-3\/4{
    top: -75% !important;
    right: -75% !important;
    bottom: -75% !important;
    left: -75% !important;
  }

  .\32xl\:-inset-full{
    top: -100% !important;
    right: -100% !important;
    bottom: -100% !important;
    left: -100% !important;
  }

  .\32xl\:inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .\32xl\:inset-x-1{
    left: 0.25rem !important;
    right: 0.25rem !important;
  }

  .\32xl\:inset-x-2{
    left: 0.5rem !important;
    right: 0.5rem !important;
  }

  .\32xl\:inset-x-3{
    left: 0.75rem !important;
    right: 0.75rem !important;
  }

  .\32xl\:inset-x-4{
    left: 1rem !important;
    right: 1rem !important;
  }

  .\32xl\:inset-x-5{
    left: 1.25rem !important;
    right: 1.25rem !important;
  }

  .\32xl\:inset-x-6{
    left: 1.5rem !important;
    right: 1.5rem !important;
  }

  .\32xl\:inset-x-7{
    left: 1.75rem !important;
    right: 1.75rem !important;
  }

  .\32xl\:inset-x-8{
    left: 2rem !important;
    right: 2rem !important;
  }

  .\32xl\:inset-x-9{
    left: 2.25rem !important;
    right: 2.25rem !important;
  }

  .\32xl\:inset-x-10{
    left: 2.5rem !important;
    right: 2.5rem !important;
  }

  .\32xl\:inset-x-11{
    left: 2.75rem !important;
    right: 2.75rem !important;
  }

  .\32xl\:inset-x-12{
    left: 3rem !important;
    right: 3rem !important;
  }

  .\32xl\:inset-x-14{
    left: 3.5rem !important;
    right: 3.5rem !important;
  }

  .\32xl\:inset-x-16{
    left: 4rem !important;
    right: 4rem !important;
  }

  .\32xl\:inset-x-20{
    left: 5rem !important;
    right: 5rem !important;
  }

  .\32xl\:inset-x-24{
    left: 6rem !important;
    right: 6rem !important;
  }

  .\32xl\:inset-x-28{
    left: 7rem !important;
    right: 7rem !important;
  }

  .\32xl\:inset-x-32{
    left: 8rem !important;
    right: 8rem !important;
  }

  .\32xl\:inset-x-36{
    left: 9rem !important;
    right: 9rem !important;
  }

  .\32xl\:inset-x-40{
    left: 10rem !important;
    right: 10rem !important;
  }

  .\32xl\:inset-x-44{
    left: 11rem !important;
    right: 11rem !important;
  }

  .\32xl\:inset-x-48{
    left: 12rem !important;
    right: 12rem !important;
  }

  .\32xl\:inset-x-52{
    left: 13rem !important;
    right: 13rem !important;
  }

  .\32xl\:inset-x-56{
    left: 14rem !important;
    right: 14rem !important;
  }

  .\32xl\:inset-x-60{
    left: 15rem !important;
    right: 15rem !important;
  }

  .\32xl\:inset-x-64{
    left: 16rem !important;
    right: 16rem !important;
  }

  .\32xl\:inset-x-72{
    left: 18rem !important;
    right: 18rem !important;
  }

  .\32xl\:inset-x-80{
    left: 20rem !important;
    right: 20rem !important;
  }

  .\32xl\:inset-x-96{
    left: 24rem !important;
    right: 24rem !important;
  }

  .\32xl\:inset-x-auto{
    left: auto !important;
    right: auto !important;
  }

  .\32xl\:inset-x-px{
    left: 1px !important;
    right: 1px !important;
  }

  .\32xl\:inset-x-0\.5{
    left: 0.125rem !important;
    right: 0.125rem !important;
  }

  .\32xl\:inset-x-1\.5{
    left: 0.375rem !important;
    right: 0.375rem !important;
  }

  .\32xl\:inset-x-2\.5{
    left: 0.625rem !important;
    right: 0.625rem !important;
  }

  .\32xl\:inset-x-3\.5{
    left: 0.875rem !important;
    right: 0.875rem !important;
  }

  .\32xl\:-inset-x-0{
    left: 0px !important;
    right: 0px !important;
  }

  .\32xl\:-inset-x-1{
    left: -0.25rem !important;
    right: -0.25rem !important;
  }

  .\32xl\:-inset-x-2{
    left: -0.5rem !important;
    right: -0.5rem !important;
  }

  .\32xl\:-inset-x-3{
    left: -0.75rem !important;
    right: -0.75rem !important;
  }

  .\32xl\:-inset-x-4{
    left: -1rem !important;
    right: -1rem !important;
  }

  .\32xl\:-inset-x-5{
    left: -1.25rem !important;
    right: -1.25rem !important;
  }

  .\32xl\:-inset-x-6{
    left: -1.5rem !important;
    right: -1.5rem !important;
  }

  .\32xl\:-inset-x-7{
    left: -1.75rem !important;
    right: -1.75rem !important;
  }

  .\32xl\:-inset-x-8{
    left: -2rem !important;
    right: -2rem !important;
  }

  .\32xl\:-inset-x-9{
    left: -2.25rem !important;
    right: -2.25rem !important;
  }

  .\32xl\:-inset-x-10{
    left: -2.5rem !important;
    right: -2.5rem !important;
  }

  .\32xl\:-inset-x-11{
    left: -2.75rem !important;
    right: -2.75rem !important;
  }

  .\32xl\:-inset-x-12{
    left: -3rem !important;
    right: -3rem !important;
  }

  .\32xl\:-inset-x-14{
    left: -3.5rem !important;
    right: -3.5rem !important;
  }

  .\32xl\:-inset-x-16{
    left: -4rem !important;
    right: -4rem !important;
  }

  .\32xl\:-inset-x-20{
    left: -5rem !important;
    right: -5rem !important;
  }

  .\32xl\:-inset-x-24{
    left: -6rem !important;
    right: -6rem !important;
  }

  .\32xl\:-inset-x-28{
    left: -7rem !important;
    right: -7rem !important;
  }

  .\32xl\:-inset-x-32{
    left: -8rem !important;
    right: -8rem !important;
  }

  .\32xl\:-inset-x-36{
    left: -9rem !important;
    right: -9rem !important;
  }

  .\32xl\:-inset-x-40{
    left: -10rem !important;
    right: -10rem !important;
  }

  .\32xl\:-inset-x-44{
    left: -11rem !important;
    right: -11rem !important;
  }

  .\32xl\:-inset-x-48{
    left: -12rem !important;
    right: -12rem !important;
  }

  .\32xl\:-inset-x-52{
    left: -13rem !important;
    right: -13rem !important;
  }

  .\32xl\:-inset-x-56{
    left: -14rem !important;
    right: -14rem !important;
  }

  .\32xl\:-inset-x-60{
    left: -15rem !important;
    right: -15rem !important;
  }

  .\32xl\:-inset-x-64{
    left: -16rem !important;
    right: -16rem !important;
  }

  .\32xl\:-inset-x-72{
    left: -18rem !important;
    right: -18rem !important;
  }

  .\32xl\:-inset-x-80{
    left: -20rem !important;
    right: -20rem !important;
  }

  .\32xl\:-inset-x-96{
    left: -24rem !important;
    right: -24rem !important;
  }

  .\32xl\:-inset-x-px{
    left: -1px !important;
    right: -1px !important;
  }

  .\32xl\:-inset-x-0\.5{
    left: -0.125rem !important;
    right: -0.125rem !important;
  }

  .\32xl\:-inset-x-1\.5{
    left: -0.375rem !important;
    right: -0.375rem !important;
  }

  .\32xl\:-inset-x-2\.5{
    left: -0.625rem !important;
    right: -0.625rem !important;
  }

  .\32xl\:-inset-x-3\.5{
    left: -0.875rem !important;
    right: -0.875rem !important;
  }

  .\32xl\:inset-x-1\/2{
    left: 50% !important;
    right: 50% !important;
  }

  .\32xl\:inset-x-1\/3{
    left: 33.333333% !important;
    right: 33.333333% !important;
  }

  .\32xl\:inset-x-2\/3{
    left: 66.666667% !important;
    right: 66.666667% !important;
  }

  .\32xl\:inset-x-1\/4{
    left: 25% !important;
    right: 25% !important;
  }

  .\32xl\:inset-x-2\/4{
    left: 50% !important;
    right: 50% !important;
  }

  .\32xl\:inset-x-3\/4{
    left: 75% !important;
    right: 75% !important;
  }

  .\32xl\:inset-x-full{
    left: 100% !important;
    right: 100% !important;
  }

  .\32xl\:-inset-x-1\/2{
    left: -50% !important;
    right: -50% !important;
  }

  .\32xl\:-inset-x-1\/3{
    left: -33.333333% !important;
    right: -33.333333% !important;
  }

  .\32xl\:-inset-x-2\/3{
    left: -66.666667% !important;
    right: -66.666667% !important;
  }

  .\32xl\:-inset-x-1\/4{
    left: -25% !important;
    right: -25% !important;
  }

  .\32xl\:-inset-x-2\/4{
    left: -50% !important;
    right: -50% !important;
  }

  .\32xl\:-inset-x-3\/4{
    left: -75% !important;
    right: -75% !important;
  }

  .\32xl\:-inset-x-full{
    left: -100% !important;
    right: -100% !important;
  }

  .\32xl\:inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .\32xl\:inset-y-1{
    top: 0.25rem !important;
    bottom: 0.25rem !important;
  }

  .\32xl\:inset-y-2{
    top: 0.5rem !important;
    bottom: 0.5rem !important;
  }

  .\32xl\:inset-y-3{
    top: 0.75rem !important;
    bottom: 0.75rem !important;
  }

  .\32xl\:inset-y-4{
    top: 1rem !important;
    bottom: 1rem !important;
  }

  .\32xl\:inset-y-5{
    top: 1.25rem !important;
    bottom: 1.25rem !important;
  }

  .\32xl\:inset-y-6{
    top: 1.5rem !important;
    bottom: 1.5rem !important;
  }

  .\32xl\:inset-y-7{
    top: 1.75rem !important;
    bottom: 1.75rem !important;
  }

  .\32xl\:inset-y-8{
    top: 2rem !important;
    bottom: 2rem !important;
  }

  .\32xl\:inset-y-9{
    top: 2.25rem !important;
    bottom: 2.25rem !important;
  }

  .\32xl\:inset-y-10{
    top: 2.5rem !important;
    bottom: 2.5rem !important;
  }

  .\32xl\:inset-y-11{
    top: 2.75rem !important;
    bottom: 2.75rem !important;
  }

  .\32xl\:inset-y-12{
    top: 3rem !important;
    bottom: 3rem !important;
  }

  .\32xl\:inset-y-14{
    top: 3.5rem !important;
    bottom: 3.5rem !important;
  }

  .\32xl\:inset-y-16{
    top: 4rem !important;
    bottom: 4rem !important;
  }

  .\32xl\:inset-y-20{
    top: 5rem !important;
    bottom: 5rem !important;
  }

  .\32xl\:inset-y-24{
    top: 6rem !important;
    bottom: 6rem !important;
  }

  .\32xl\:inset-y-28{
    top: 7rem !important;
    bottom: 7rem !important;
  }

  .\32xl\:inset-y-32{
    top: 8rem !important;
    bottom: 8rem !important;
  }

  .\32xl\:inset-y-36{
    top: 9rem !important;
    bottom: 9rem !important;
  }

  .\32xl\:inset-y-40{
    top: 10rem !important;
    bottom: 10rem !important;
  }

  .\32xl\:inset-y-44{
    top: 11rem !important;
    bottom: 11rem !important;
  }

  .\32xl\:inset-y-48{
    top: 12rem !important;
    bottom: 12rem !important;
  }

  .\32xl\:inset-y-52{
    top: 13rem !important;
    bottom: 13rem !important;
  }

  .\32xl\:inset-y-56{
    top: 14rem !important;
    bottom: 14rem !important;
  }

  .\32xl\:inset-y-60{
    top: 15rem !important;
    bottom: 15rem !important;
  }

  .\32xl\:inset-y-64{
    top: 16rem !important;
    bottom: 16rem !important;
  }

  .\32xl\:inset-y-72{
    top: 18rem !important;
    bottom: 18rem !important;
  }

  .\32xl\:inset-y-80{
    top: 20rem !important;
    bottom: 20rem !important;
  }

  .\32xl\:inset-y-96{
    top: 24rem !important;
    bottom: 24rem !important;
  }

  .\32xl\:inset-y-auto{
    top: auto !important;
    bottom: auto !important;
  }

  .\32xl\:inset-y-px{
    top: 1px !important;
    bottom: 1px !important;
  }

  .\32xl\:inset-y-0\.5{
    top: 0.125rem !important;
    bottom: 0.125rem !important;
  }

  .\32xl\:inset-y-1\.5{
    top: 0.375rem !important;
    bottom: 0.375rem !important;
  }

  .\32xl\:inset-y-2\.5{
    top: 0.625rem !important;
    bottom: 0.625rem !important;
  }

  .\32xl\:inset-y-3\.5{
    top: 0.875rem !important;
    bottom: 0.875rem !important;
  }

  .\32xl\:-inset-y-0{
    top: 0px !important;
    bottom: 0px !important;
  }

  .\32xl\:-inset-y-1{
    top: -0.25rem !important;
    bottom: -0.25rem !important;
  }

  .\32xl\:-inset-y-2{
    top: -0.5rem !important;
    bottom: -0.5rem !important;
  }

  .\32xl\:-inset-y-3{
    top: -0.75rem !important;
    bottom: -0.75rem !important;
  }

  .\32xl\:-inset-y-4{
    top: -1rem !important;
    bottom: -1rem !important;
  }

  .\32xl\:-inset-y-5{
    top: -1.25rem !important;
    bottom: -1.25rem !important;
  }

  .\32xl\:-inset-y-6{
    top: -1.5rem !important;
    bottom: -1.5rem !important;
  }

  .\32xl\:-inset-y-7{
    top: -1.75rem !important;
    bottom: -1.75rem !important;
  }

  .\32xl\:-inset-y-8{
    top: -2rem !important;
    bottom: -2rem !important;
  }

  .\32xl\:-inset-y-9{
    top: -2.25rem !important;
    bottom: -2.25rem !important;
  }

  .\32xl\:-inset-y-10{
    top: -2.5rem !important;
    bottom: -2.5rem !important;
  }

  .\32xl\:-inset-y-11{
    top: -2.75rem !important;
    bottom: -2.75rem !important;
  }

  .\32xl\:-inset-y-12{
    top: -3rem !important;
    bottom: -3rem !important;
  }

  .\32xl\:-inset-y-14{
    top: -3.5rem !important;
    bottom: -3.5rem !important;
  }

  .\32xl\:-inset-y-16{
    top: -4rem !important;
    bottom: -4rem !important;
  }

  .\32xl\:-inset-y-20{
    top: -5rem !important;
    bottom: -5rem !important;
  }

  .\32xl\:-inset-y-24{
    top: -6rem !important;
    bottom: -6rem !important;
  }

  .\32xl\:-inset-y-28{
    top: -7rem !important;
    bottom: -7rem !important;
  }

  .\32xl\:-inset-y-32{
    top: -8rem !important;
    bottom: -8rem !important;
  }

  .\32xl\:-inset-y-36{
    top: -9rem !important;
    bottom: -9rem !important;
  }

  .\32xl\:-inset-y-40{
    top: -10rem !important;
    bottom: -10rem !important;
  }

  .\32xl\:-inset-y-44{
    top: -11rem !important;
    bottom: -11rem !important;
  }

  .\32xl\:-inset-y-48{
    top: -12rem !important;
    bottom: -12rem !important;
  }

  .\32xl\:-inset-y-52{
    top: -13rem !important;
    bottom: -13rem !important;
  }

  .\32xl\:-inset-y-56{
    top: -14rem !important;
    bottom: -14rem !important;
  }

  .\32xl\:-inset-y-60{
    top: -15rem !important;
    bottom: -15rem !important;
  }

  .\32xl\:-inset-y-64{
    top: -16rem !important;
    bottom: -16rem !important;
  }

  .\32xl\:-inset-y-72{
    top: -18rem !important;
    bottom: -18rem !important;
  }

  .\32xl\:-inset-y-80{
    top: -20rem !important;
    bottom: -20rem !important;
  }

  .\32xl\:-inset-y-96{
    top: -24rem !important;
    bottom: -24rem !important;
  }

  .\32xl\:-inset-y-px{
    top: -1px !important;
    bottom: -1px !important;
  }

  .\32xl\:-inset-y-0\.5{
    top: -0.125rem !important;
    bottom: -0.125rem !important;
  }

  .\32xl\:-inset-y-1\.5{
    top: -0.375rem !important;
    bottom: -0.375rem !important;
  }

  .\32xl\:-inset-y-2\.5{
    top: -0.625rem !important;
    bottom: -0.625rem !important;
  }

  .\32xl\:-inset-y-3\.5{
    top: -0.875rem !important;
    bottom: -0.875rem !important;
  }

  .\32xl\:inset-y-1\/2{
    top: 50% !important;
    bottom: 50% !important;
  }

  .\32xl\:inset-y-1\/3{
    top: 33.333333% !important;
    bottom: 33.333333% !important;
  }

  .\32xl\:inset-y-2\/3{
    top: 66.666667% !important;
    bottom: 66.666667% !important;
  }

  .\32xl\:inset-y-1\/4{
    top: 25% !important;
    bottom: 25% !important;
  }

  .\32xl\:inset-y-2\/4{
    top: 50% !important;
    bottom: 50% !important;
  }

  .\32xl\:inset-y-3\/4{
    top: 75% !important;
    bottom: 75% !important;
  }

  .\32xl\:inset-y-full{
    top: 100% !important;
    bottom: 100% !important;
  }

  .\32xl\:-inset-y-1\/2{
    top: -50% !important;
    bottom: -50% !important;
  }

  .\32xl\:-inset-y-1\/3{
    top: -33.333333% !important;
    bottom: -33.333333% !important;
  }

  .\32xl\:-inset-y-2\/3{
    top: -66.666667% !important;
    bottom: -66.666667% !important;
  }

  .\32xl\:-inset-y-1\/4{
    top: -25% !important;
    bottom: -25% !important;
  }

  .\32xl\:-inset-y-2\/4{
    top: -50% !important;
    bottom: -50% !important;
  }

  .\32xl\:-inset-y-3\/4{
    top: -75% !important;
    bottom: -75% !important;
  }

  .\32xl\:-inset-y-full{
    top: -100% !important;
    bottom: -100% !important;
  }

  .\32xl\:top-0{
    top: 0px !important;
  }

  .\32xl\:top-1{
    top: 0.25rem !important;
  }

  .\32xl\:top-2{
    top: 0.5rem !important;
  }

  .\32xl\:top-3{
    top: 0.75rem !important;
  }

  .\32xl\:top-4{
    top: 1rem !important;
  }

  .\32xl\:top-5{
    top: 1.25rem !important;
  }

  .\32xl\:top-6{
    top: 1.5rem !important;
  }

  .\32xl\:top-7{
    top: 1.75rem !important;
  }

  .\32xl\:top-8{
    top: 2rem !important;
  }

  .\32xl\:top-9{
    top: 2.25rem !important;
  }

  .\32xl\:top-10{
    top: 2.5rem !important;
  }

  .\32xl\:top-11{
    top: 2.75rem !important;
  }

  .\32xl\:top-12{
    top: 3rem !important;
  }

  .\32xl\:top-14{
    top: 3.5rem !important;
  }

  .\32xl\:top-16{
    top: 4rem !important;
  }

  .\32xl\:top-20{
    top: 5rem !important;
  }

  .\32xl\:top-24{
    top: 6rem !important;
  }

  .\32xl\:top-28{
    top: 7rem !important;
  }

  .\32xl\:top-32{
    top: 8rem !important;
  }

  .\32xl\:top-36{
    top: 9rem !important;
  }

  .\32xl\:top-40{
    top: 10rem !important;
  }

  .\32xl\:top-44{
    top: 11rem !important;
  }

  .\32xl\:top-48{
    top: 12rem !important;
  }

  .\32xl\:top-52{
    top: 13rem !important;
  }

  .\32xl\:top-56{
    top: 14rem !important;
  }

  .\32xl\:top-60{
    top: 15rem !important;
  }

  .\32xl\:top-64{
    top: 16rem !important;
  }

  .\32xl\:top-72{
    top: 18rem !important;
  }

  .\32xl\:top-80{
    top: 20rem !important;
  }

  .\32xl\:top-96{
    top: 24rem !important;
  }

  .\32xl\:top-auto{
    top: auto !important;
  }

  .\32xl\:top-px{
    top: 1px !important;
  }

  .\32xl\:top-0\.5{
    top: 0.125rem !important;
  }

  .\32xl\:top-1\.5{
    top: 0.375rem !important;
  }

  .\32xl\:top-2\.5{
    top: 0.625rem !important;
  }

  .\32xl\:top-3\.5{
    top: 0.875rem !important;
  }

  .\32xl\:-top-0{
    top: 0px !important;
  }

  .\32xl\:-top-1{
    top: -0.25rem !important;
  }

  .\32xl\:-top-2{
    top: -0.5rem !important;
  }

  .\32xl\:-top-3{
    top: -0.75rem !important;
  }

  .\32xl\:-top-4{
    top: -1rem !important;
  }

  .\32xl\:-top-5{
    top: -1.25rem !important;
  }

  .\32xl\:-top-6{
    top: -1.5rem !important;
  }

  .\32xl\:-top-7{
    top: -1.75rem !important;
  }

  .\32xl\:-top-8{
    top: -2rem !important;
  }

  .\32xl\:-top-9{
    top: -2.25rem !important;
  }

  .\32xl\:-top-10{
    top: -2.5rem !important;
  }

  .\32xl\:-top-11{
    top: -2.75rem !important;
  }

  .\32xl\:-top-12{
    top: -3rem !important;
  }

  .\32xl\:-top-14{
    top: -3.5rem !important;
  }

  .\32xl\:-top-16{
    top: -4rem !important;
  }

  .\32xl\:-top-20{
    top: -5rem !important;
  }

  .\32xl\:-top-24{
    top: -6rem !important;
  }

  .\32xl\:-top-28{
    top: -7rem !important;
  }

  .\32xl\:-top-32{
    top: -8rem !important;
  }

  .\32xl\:-top-36{
    top: -9rem !important;
  }

  .\32xl\:-top-40{
    top: -10rem !important;
  }

  .\32xl\:-top-44{
    top: -11rem !important;
  }

  .\32xl\:-top-48{
    top: -12rem !important;
  }

  .\32xl\:-top-52{
    top: -13rem !important;
  }

  .\32xl\:-top-56{
    top: -14rem !important;
  }

  .\32xl\:-top-60{
    top: -15rem !important;
  }

  .\32xl\:-top-64{
    top: -16rem !important;
  }

  .\32xl\:-top-72{
    top: -18rem !important;
  }

  .\32xl\:-top-80{
    top: -20rem !important;
  }

  .\32xl\:-top-96{
    top: -24rem !important;
  }

  .\32xl\:-top-px{
    top: -1px !important;
  }

  .\32xl\:-top-0\.5{
    top: -0.125rem !important;
  }

  .\32xl\:-top-1\.5{
    top: -0.375rem !important;
  }

  .\32xl\:-top-2\.5{
    top: -0.625rem !important;
  }

  .\32xl\:-top-3\.5{
    top: -0.875rem !important;
  }

  .\32xl\:top-1\/2{
    top: 50% !important;
  }

  .\32xl\:top-1\/3{
    top: 33.333333% !important;
  }

  .\32xl\:top-2\/3{
    top: 66.666667% !important;
  }

  .\32xl\:top-1\/4{
    top: 25% !important;
  }

  .\32xl\:top-2\/4{
    top: 50% !important;
  }

  .\32xl\:top-3\/4{
    top: 75% !important;
  }

  .\32xl\:top-full{
    top: 100% !important;
  }

  .\32xl\:-top-1\/2{
    top: -50% !important;
  }

  .\32xl\:-top-1\/3{
    top: -33.333333% !important;
  }

  .\32xl\:-top-2\/3{
    top: -66.666667% !important;
  }

  .\32xl\:-top-1\/4{
    top: -25% !important;
  }

  .\32xl\:-top-2\/4{
    top: -50% !important;
  }

  .\32xl\:-top-3\/4{
    top: -75% !important;
  }

  .\32xl\:-top-full{
    top: -100% !important;
  }

  .\32xl\:right-0{
    right: 0px !important;
  }

  .\32xl\:right-1{
    right: 0.25rem !important;
  }

  .\32xl\:right-2{
    right: 0.5rem !important;
  }

  .\32xl\:right-3{
    right: 0.75rem !important;
  }

  .\32xl\:right-4{
    right: 1rem !important;
  }

  .\32xl\:right-5{
    right: 1.25rem !important;
  }

  .\32xl\:right-6{
    right: 1.5rem !important;
  }

  .\32xl\:right-7{
    right: 1.75rem !important;
  }

  .\32xl\:right-8{
    right: 2rem !important;
  }

  .\32xl\:right-9{
    right: 2.25rem !important;
  }

  .\32xl\:right-10{
    right: 2.5rem !important;
  }

  .\32xl\:right-11{
    right: 2.75rem !important;
  }

  .\32xl\:right-12{
    right: 3rem !important;
  }

  .\32xl\:right-14{
    right: 3.5rem !important;
  }

  .\32xl\:right-16{
    right: 4rem !important;
  }

  .\32xl\:right-20{
    right: 5rem !important;
  }

  .\32xl\:right-24{
    right: 6rem !important;
  }

  .\32xl\:right-28{
    right: 7rem !important;
  }

  .\32xl\:right-32{
    right: 8rem !important;
  }

  .\32xl\:right-36{
    right: 9rem !important;
  }

  .\32xl\:right-40{
    right: 10rem !important;
  }

  .\32xl\:right-44{
    right: 11rem !important;
  }

  .\32xl\:right-48{
    right: 12rem !important;
  }

  .\32xl\:right-52{
    right: 13rem !important;
  }

  .\32xl\:right-56{
    right: 14rem !important;
  }

  .\32xl\:right-60{
    right: 15rem !important;
  }

  .\32xl\:right-64{
    right: 16rem !important;
  }

  .\32xl\:right-72{
    right: 18rem !important;
  }

  .\32xl\:right-80{
    right: 20rem !important;
  }

  .\32xl\:right-96{
    right: 24rem !important;
  }

  .\32xl\:right-auto{
    right: auto !important;
  }

  .\32xl\:right-px{
    right: 1px !important;
  }

  .\32xl\:right-0\.5{
    right: 0.125rem !important;
  }

  .\32xl\:right-1\.5{
    right: 0.375rem !important;
  }

  .\32xl\:right-2\.5{
    right: 0.625rem !important;
  }

  .\32xl\:right-3\.5{
    right: 0.875rem !important;
  }

  .\32xl\:-right-0{
    right: 0px !important;
  }

  .\32xl\:-right-1{
    right: -0.25rem !important;
  }

  .\32xl\:-right-2{
    right: -0.5rem !important;
  }

  .\32xl\:-right-3{
    right: -0.75rem !important;
  }

  .\32xl\:-right-4{
    right: -1rem !important;
  }

  .\32xl\:-right-5{
    right: -1.25rem !important;
  }

  .\32xl\:-right-6{
    right: -1.5rem !important;
  }

  .\32xl\:-right-7{
    right: -1.75rem !important;
  }

  .\32xl\:-right-8{
    right: -2rem !important;
  }

  .\32xl\:-right-9{
    right: -2.25rem !important;
  }

  .\32xl\:-right-10{
    right: -2.5rem !important;
  }

  .\32xl\:-right-11{
    right: -2.75rem !important;
  }

  .\32xl\:-right-12{
    right: -3rem !important;
  }

  .\32xl\:-right-14{
    right: -3.5rem !important;
  }

  .\32xl\:-right-16{
    right: -4rem !important;
  }

  .\32xl\:-right-20{
    right: -5rem !important;
  }

  .\32xl\:-right-24{
    right: -6rem !important;
  }

  .\32xl\:-right-28{
    right: -7rem !important;
  }

  .\32xl\:-right-32{
    right: -8rem !important;
  }

  .\32xl\:-right-36{
    right: -9rem !important;
  }

  .\32xl\:-right-40{
    right: -10rem !important;
  }

  .\32xl\:-right-44{
    right: -11rem !important;
  }

  .\32xl\:-right-48{
    right: -12rem !important;
  }

  .\32xl\:-right-52{
    right: -13rem !important;
  }

  .\32xl\:-right-56{
    right: -14rem !important;
  }

  .\32xl\:-right-60{
    right: -15rem !important;
  }

  .\32xl\:-right-64{
    right: -16rem !important;
  }

  .\32xl\:-right-72{
    right: -18rem !important;
  }

  .\32xl\:-right-80{
    right: -20rem !important;
  }

  .\32xl\:-right-96{
    right: -24rem !important;
  }

  .\32xl\:-right-px{
    right: -1px !important;
  }

  .\32xl\:-right-0\.5{
    right: -0.125rem !important;
  }

  .\32xl\:-right-1\.5{
    right: -0.375rem !important;
  }

  .\32xl\:-right-2\.5{
    right: -0.625rem !important;
  }

  .\32xl\:-right-3\.5{
    right: -0.875rem !important;
  }

  .\32xl\:right-1\/2{
    right: 50% !important;
  }

  .\32xl\:right-1\/3{
    right: 33.333333% !important;
  }

  .\32xl\:right-2\/3{
    right: 66.666667% !important;
  }

  .\32xl\:right-1\/4{
    right: 25% !important;
  }

  .\32xl\:right-2\/4{
    right: 50% !important;
  }

  .\32xl\:right-3\/4{
    right: 75% !important;
  }

  .\32xl\:right-full{
    right: 100% !important;
  }

  .\32xl\:-right-1\/2{
    right: -50% !important;
  }

  .\32xl\:-right-1\/3{
    right: -33.333333% !important;
  }

  .\32xl\:-right-2\/3{
    right: -66.666667% !important;
  }

  .\32xl\:-right-1\/4{
    right: -25% !important;
  }

  .\32xl\:-right-2\/4{
    right: -50% !important;
  }

  .\32xl\:-right-3\/4{
    right: -75% !important;
  }

  .\32xl\:-right-full{
    right: -100% !important;
  }

  .\32xl\:bottom-0{
    bottom: 0px !important;
  }

  .\32xl\:bottom-1{
    bottom: 0.25rem !important;
  }

  .\32xl\:bottom-2{
    bottom: 0.5rem !important;
  }

  .\32xl\:bottom-3{
    bottom: 0.75rem !important;
  }

  .\32xl\:bottom-4{
    bottom: 1rem !important;
  }

  .\32xl\:bottom-5{
    bottom: 1.25rem !important;
  }

  .\32xl\:bottom-6{
    bottom: 1.5rem !important;
  }

  .\32xl\:bottom-7{
    bottom: 1.75rem !important;
  }

  .\32xl\:bottom-8{
    bottom: 2rem !important;
  }

  .\32xl\:bottom-9{
    bottom: 2.25rem !important;
  }

  .\32xl\:bottom-10{
    bottom: 2.5rem !important;
  }

  .\32xl\:bottom-11{
    bottom: 2.75rem !important;
  }

  .\32xl\:bottom-12{
    bottom: 3rem !important;
  }

  .\32xl\:bottom-14{
    bottom: 3.5rem !important;
  }

  .\32xl\:bottom-16{
    bottom: 4rem !important;
  }

  .\32xl\:bottom-20{
    bottom: 5rem !important;
  }

  .\32xl\:bottom-24{
    bottom: 6rem !important;
  }

  .\32xl\:bottom-28{
    bottom: 7rem !important;
  }

  .\32xl\:bottom-32{
    bottom: 8rem !important;
  }

  .\32xl\:bottom-36{
    bottom: 9rem !important;
  }

  .\32xl\:bottom-40{
    bottom: 10rem !important;
  }

  .\32xl\:bottom-44{
    bottom: 11rem !important;
  }

  .\32xl\:bottom-48{
    bottom: 12rem !important;
  }

  .\32xl\:bottom-52{
    bottom: 13rem !important;
  }

  .\32xl\:bottom-56{
    bottom: 14rem !important;
  }

  .\32xl\:bottom-60{
    bottom: 15rem !important;
  }

  .\32xl\:bottom-64{
    bottom: 16rem !important;
  }

  .\32xl\:bottom-72{
    bottom: 18rem !important;
  }

  .\32xl\:bottom-80{
    bottom: 20rem !important;
  }

  .\32xl\:bottom-96{
    bottom: 24rem !important;
  }

  .\32xl\:bottom-auto{
    bottom: auto !important;
  }

  .\32xl\:bottom-px{
    bottom: 1px !important;
  }

  .\32xl\:bottom-0\.5{
    bottom: 0.125rem !important;
  }

  .\32xl\:bottom-1\.5{
    bottom: 0.375rem !important;
  }

  .\32xl\:bottom-2\.5{
    bottom: 0.625rem !important;
  }

  .\32xl\:bottom-3\.5{
    bottom: 0.875rem !important;
  }

  .\32xl\:-bottom-0{
    bottom: 0px !important;
  }

  .\32xl\:-bottom-1{
    bottom: -0.25rem !important;
  }

  .\32xl\:-bottom-2{
    bottom: -0.5rem !important;
  }

  .\32xl\:-bottom-3{
    bottom: -0.75rem !important;
  }

  .\32xl\:-bottom-4{
    bottom: -1rem !important;
  }

  .\32xl\:-bottom-5{
    bottom: -1.25rem !important;
  }

  .\32xl\:-bottom-6{
    bottom: -1.5rem !important;
  }

  .\32xl\:-bottom-7{
    bottom: -1.75rem !important;
  }

  .\32xl\:-bottom-8{
    bottom: -2rem !important;
  }

  .\32xl\:-bottom-9{
    bottom: -2.25rem !important;
  }

  .\32xl\:-bottom-10{
    bottom: -2.5rem !important;
  }

  .\32xl\:-bottom-11{
    bottom: -2.75rem !important;
  }

  .\32xl\:-bottom-12{
    bottom: -3rem !important;
  }

  .\32xl\:-bottom-14{
    bottom: -3.5rem !important;
  }

  .\32xl\:-bottom-16{
    bottom: -4rem !important;
  }

  .\32xl\:-bottom-20{
    bottom: -5rem !important;
  }

  .\32xl\:-bottom-24{
    bottom: -6rem !important;
  }

  .\32xl\:-bottom-28{
    bottom: -7rem !important;
  }

  .\32xl\:-bottom-32{
    bottom: -8rem !important;
  }

  .\32xl\:-bottom-36{
    bottom: -9rem !important;
  }

  .\32xl\:-bottom-40{
    bottom: -10rem !important;
  }

  .\32xl\:-bottom-44{
    bottom: -11rem !important;
  }

  .\32xl\:-bottom-48{
    bottom: -12rem !important;
  }

  .\32xl\:-bottom-52{
    bottom: -13rem !important;
  }

  .\32xl\:-bottom-56{
    bottom: -14rem !important;
  }

  .\32xl\:-bottom-60{
    bottom: -15rem !important;
  }

  .\32xl\:-bottom-64{
    bottom: -16rem !important;
  }

  .\32xl\:-bottom-72{
    bottom: -18rem !important;
  }

  .\32xl\:-bottom-80{
    bottom: -20rem !important;
  }

  .\32xl\:-bottom-96{
    bottom: -24rem !important;
  }

  .\32xl\:-bottom-px{
    bottom: -1px !important;
  }

  .\32xl\:-bottom-0\.5{
    bottom: -0.125rem !important;
  }

  .\32xl\:-bottom-1\.5{
    bottom: -0.375rem !important;
  }

  .\32xl\:-bottom-2\.5{
    bottom: -0.625rem !important;
  }

  .\32xl\:-bottom-3\.5{
    bottom: -0.875rem !important;
  }

  .\32xl\:bottom-1\/2{
    bottom: 50% !important;
  }

  .\32xl\:bottom-1\/3{
    bottom: 33.333333% !important;
  }

  .\32xl\:bottom-2\/3{
    bottom: 66.666667% !important;
  }

  .\32xl\:bottom-1\/4{
    bottom: 25% !important;
  }

  .\32xl\:bottom-2\/4{
    bottom: 50% !important;
  }

  .\32xl\:bottom-3\/4{
    bottom: 75% !important;
  }

  .\32xl\:bottom-full{
    bottom: 100% !important;
  }

  .\32xl\:-bottom-1\/2{
    bottom: -50% !important;
  }

  .\32xl\:-bottom-1\/3{
    bottom: -33.333333% !important;
  }

  .\32xl\:-bottom-2\/3{
    bottom: -66.666667% !important;
  }

  .\32xl\:-bottom-1\/4{
    bottom: -25% !important;
  }

  .\32xl\:-bottom-2\/4{
    bottom: -50% !important;
  }

  .\32xl\:-bottom-3\/4{
    bottom: -75% !important;
  }

  .\32xl\:-bottom-full{
    bottom: -100% !important;
  }

  .\32xl\:left-0{
    left: 0px !important;
  }

  .\32xl\:left-1{
    left: 0.25rem !important;
  }

  .\32xl\:left-2{
    left: 0.5rem !important;
  }

  .\32xl\:left-3{
    left: 0.75rem !important;
  }

  .\32xl\:left-4{
    left: 1rem !important;
  }

  .\32xl\:left-5{
    left: 1.25rem !important;
  }

  .\32xl\:left-6{
    left: 1.5rem !important;
  }

  .\32xl\:left-7{
    left: 1.75rem !important;
  }

  .\32xl\:left-8{
    left: 2rem !important;
  }

  .\32xl\:left-9{
    left: 2.25rem !important;
  }

  .\32xl\:left-10{
    left: 2.5rem !important;
  }

  .\32xl\:left-11{
    left: 2.75rem !important;
  }

  .\32xl\:left-12{
    left: 3rem !important;
  }

  .\32xl\:left-14{
    left: 3.5rem !important;
  }

  .\32xl\:left-16{
    left: 4rem !important;
  }

  .\32xl\:left-20{
    left: 5rem !important;
  }

  .\32xl\:left-24{
    left: 6rem !important;
  }

  .\32xl\:left-28{
    left: 7rem !important;
  }

  .\32xl\:left-32{
    left: 8rem !important;
  }

  .\32xl\:left-36{
    left: 9rem !important;
  }

  .\32xl\:left-40{
    left: 10rem !important;
  }

  .\32xl\:left-44{
    left: 11rem !important;
  }

  .\32xl\:left-48{
    left: 12rem !important;
  }

  .\32xl\:left-52{
    left: 13rem !important;
  }

  .\32xl\:left-56{
    left: 14rem !important;
  }

  .\32xl\:left-60{
    left: 15rem !important;
  }

  .\32xl\:left-64{
    left: 16rem !important;
  }

  .\32xl\:left-72{
    left: 18rem !important;
  }

  .\32xl\:left-80{
    left: 20rem !important;
  }

  .\32xl\:left-96{
    left: 24rem !important;
  }

  .\32xl\:left-auto{
    left: auto !important;
  }

  .\32xl\:left-px{
    left: 1px !important;
  }

  .\32xl\:left-0\.5{
    left: 0.125rem !important;
  }

  .\32xl\:left-1\.5{
    left: 0.375rem !important;
  }

  .\32xl\:left-2\.5{
    left: 0.625rem !important;
  }

  .\32xl\:left-3\.5{
    left: 0.875rem !important;
  }

  .\32xl\:-left-0{
    left: 0px !important;
  }

  .\32xl\:-left-1{
    left: -0.25rem !important;
  }

  .\32xl\:-left-2{
    left: -0.5rem !important;
  }

  .\32xl\:-left-3{
    left: -0.75rem !important;
  }

  .\32xl\:-left-4{
    left: -1rem !important;
  }

  .\32xl\:-left-5{
    left: -1.25rem !important;
  }

  .\32xl\:-left-6{
    left: -1.5rem !important;
  }

  .\32xl\:-left-7{
    left: -1.75rem !important;
  }

  .\32xl\:-left-8{
    left: -2rem !important;
  }

  .\32xl\:-left-9{
    left: -2.25rem !important;
  }

  .\32xl\:-left-10{
    left: -2.5rem !important;
  }

  .\32xl\:-left-11{
    left: -2.75rem !important;
  }

  .\32xl\:-left-12{
    left: -3rem !important;
  }

  .\32xl\:-left-14{
    left: -3.5rem !important;
  }

  .\32xl\:-left-16{
    left: -4rem !important;
  }

  .\32xl\:-left-20{
    left: -5rem !important;
  }

  .\32xl\:-left-24{
    left: -6rem !important;
  }

  .\32xl\:-left-28{
    left: -7rem !important;
  }

  .\32xl\:-left-32{
    left: -8rem !important;
  }

  .\32xl\:-left-36{
    left: -9rem !important;
  }

  .\32xl\:-left-40{
    left: -10rem !important;
  }

  .\32xl\:-left-44{
    left: -11rem !important;
  }

  .\32xl\:-left-48{
    left: -12rem !important;
  }

  .\32xl\:-left-52{
    left: -13rem !important;
  }

  .\32xl\:-left-56{
    left: -14rem !important;
  }

  .\32xl\:-left-60{
    left: -15rem !important;
  }

  .\32xl\:-left-64{
    left: -16rem !important;
  }

  .\32xl\:-left-72{
    left: -18rem !important;
  }

  .\32xl\:-left-80{
    left: -20rem !important;
  }

  .\32xl\:-left-96{
    left: -24rem !important;
  }

  .\32xl\:-left-px{
    left: -1px !important;
  }

  .\32xl\:-left-0\.5{
    left: -0.125rem !important;
  }

  .\32xl\:-left-1\.5{
    left: -0.375rem !important;
  }

  .\32xl\:-left-2\.5{
    left: -0.625rem !important;
  }

  .\32xl\:-left-3\.5{
    left: -0.875rem !important;
  }

  .\32xl\:left-1\/2{
    left: 50% !important;
  }

  .\32xl\:left-1\/3{
    left: 33.333333% !important;
  }

  .\32xl\:left-2\/3{
    left: 66.666667% !important;
  }

  .\32xl\:left-1\/4{
    left: 25% !important;
  }

  .\32xl\:left-2\/4{
    left: 50% !important;
  }

  .\32xl\:left-3\/4{
    left: 75% !important;
  }

  .\32xl\:left-full{
    left: 100% !important;
  }

  .\32xl\:-left-1\/2{
    left: -50% !important;
  }

  .\32xl\:-left-1\/3{
    left: -33.333333% !important;
  }

  .\32xl\:-left-2\/3{
    left: -66.666667% !important;
  }

  .\32xl\:-left-1\/4{
    left: -25% !important;
  }

  .\32xl\:-left-2\/4{
    left: -50% !important;
  }

  .\32xl\:-left-3\/4{
    left: -75% !important;
  }

  .\32xl\:-left-full{
    left: -100% !important;
  }

  .\32xl\:isolate{
    isolation: isolate !important;
  }

  .\32xl\:isolation-auto{
    isolation: auto !important;
  }

  .\32xl\:z-0{
    z-index: 0 !important;
  }

  .\32xl\:z-10{
    z-index: 10 !important;
  }

  .\32xl\:z-20{
    z-index: 20 !important;
  }

  .\32xl\:z-30{
    z-index: 30 !important;
  }

  .\32xl\:z-40{
    z-index: 40 !important;
  }

  .\32xl\:z-50{
    z-index: 50 !important;
  }

  .\32xl\:z-auto{
    z-index: auto !important;
  }

  .\32xl\:focus-within\:z-0:focus-within{
    z-index: 0 !important;
  }

  .\32xl\:focus-within\:z-10:focus-within{
    z-index: 10 !important;
  }

  .\32xl\:focus-within\:z-20:focus-within{
    z-index: 20 !important;
  }

  .\32xl\:focus-within\:z-30:focus-within{
    z-index: 30 !important;
  }

  .\32xl\:focus-within\:z-40:focus-within{
    z-index: 40 !important;
  }

  .\32xl\:focus-within\:z-50:focus-within{
    z-index: 50 !important;
  }

  .\32xl\:focus-within\:z-auto:focus-within{
    z-index: auto !important;
  }

  .\32xl\:focus\:z-0:focus{
    z-index: 0 !important;
  }

  .\32xl\:focus\:z-10:focus{
    z-index: 10 !important;
  }

  .\32xl\:focus\:z-20:focus{
    z-index: 20 !important;
  }

  .\32xl\:focus\:z-30:focus{
    z-index: 30 !important;
  }

  .\32xl\:focus\:z-40:focus{
    z-index: 40 !important;
  }

  .\32xl\:focus\:z-50:focus{
    z-index: 50 !important;
  }

  .\32xl\:focus\:z-auto:focus{
    z-index: auto !important;
  }

  .\32xl\:order-1{
    order: 1 !important;
  }

  .\32xl\:order-2{
    order: 2 !important;
  }

  .\32xl\:order-3{
    order: 3 !important;
  }

  .\32xl\:order-4{
    order: 4 !important;
  }

  .\32xl\:order-5{
    order: 5 !important;
  }

  .\32xl\:order-6{
    order: 6 !important;
  }

  .\32xl\:order-7{
    order: 7 !important;
  }

  .\32xl\:order-8{
    order: 8 !important;
  }

  .\32xl\:order-9{
    order: 9 !important;
  }

  .\32xl\:order-10{
    order: 10 !important;
  }

  .\32xl\:order-11{
    order: 11 !important;
  }

  .\32xl\:order-12{
    order: 12 !important;
  }

  .\32xl\:order-first{
    order: -9999 !important;
  }

  .\32xl\:order-last{
    order: 9999 !important;
  }

  .\32xl\:order-none{
    order: 0 !important;
  }

  .\32xl\:col-auto{
    grid-column: auto !important;
  }

  .\32xl\:col-span-1{
    grid-column: span 1 / span 1 !important;
  }

  .\32xl\:col-span-2{
    grid-column: span 2 / span 2 !important;
  }

  .\32xl\:col-span-3{
    grid-column: span 3 / span 3 !important;
  }

  .\32xl\:col-span-4{
    grid-column: span 4 / span 4 !important;
  }

  .\32xl\:col-span-5{
    grid-column: span 5 / span 5 !important;
  }

  .\32xl\:col-span-6{
    grid-column: span 6 / span 6 !important;
  }

  .\32xl\:col-span-7{
    grid-column: span 7 / span 7 !important;
  }

  .\32xl\:col-span-8{
    grid-column: span 8 / span 8 !important;
  }

  .\32xl\:col-span-9{
    grid-column: span 9 / span 9 !important;
  }

  .\32xl\:col-span-10{
    grid-column: span 10 / span 10 !important;
  }

  .\32xl\:col-span-11{
    grid-column: span 11 / span 11 !important;
  }

  .\32xl\:col-span-12{
    grid-column: span 12 / span 12 !important;
  }

  .\32xl\:col-span-full{
    grid-column: 1 / -1 !important;
  }

  .\32xl\:col-start-1{
    grid-column-start: 1 !important;
  }

  .\32xl\:col-start-2{
    grid-column-start: 2 !important;
  }

  .\32xl\:col-start-3{
    grid-column-start: 3 !important;
  }

  .\32xl\:col-start-4{
    grid-column-start: 4 !important;
  }

  .\32xl\:col-start-5{
    grid-column-start: 5 !important;
  }

  .\32xl\:col-start-6{
    grid-column-start: 6 !important;
  }

  .\32xl\:col-start-7{
    grid-column-start: 7 !important;
  }

  .\32xl\:col-start-8{
    grid-column-start: 8 !important;
  }

  .\32xl\:col-start-9{
    grid-column-start: 9 !important;
  }

  .\32xl\:col-start-10{
    grid-column-start: 10 !important;
  }

  .\32xl\:col-start-11{
    grid-column-start: 11 !important;
  }

  .\32xl\:col-start-12{
    grid-column-start: 12 !important;
  }

  .\32xl\:col-start-13{
    grid-column-start: 13 !important;
  }

  .\32xl\:col-start-auto{
    grid-column-start: auto !important;
  }

  .\32xl\:col-end-1{
    grid-column-end: 1 !important;
  }

  .\32xl\:col-end-2{
    grid-column-end: 2 !important;
  }

  .\32xl\:col-end-3{
    grid-column-end: 3 !important;
  }

  .\32xl\:col-end-4{
    grid-column-end: 4 !important;
  }

  .\32xl\:col-end-5{
    grid-column-end: 5 !important;
  }

  .\32xl\:col-end-6{
    grid-column-end: 6 !important;
  }

  .\32xl\:col-end-7{
    grid-column-end: 7 !important;
  }

  .\32xl\:col-end-8{
    grid-column-end: 8 !important;
  }

  .\32xl\:col-end-9{
    grid-column-end: 9 !important;
  }

  .\32xl\:col-end-10{
    grid-column-end: 10 !important;
  }

  .\32xl\:col-end-11{
    grid-column-end: 11 !important;
  }

  .\32xl\:col-end-12{
    grid-column-end: 12 !important;
  }

  .\32xl\:col-end-13{
    grid-column-end: 13 !important;
  }

  .\32xl\:col-end-auto{
    grid-column-end: auto !important;
  }

  .\32xl\:row-auto{
    grid-row: auto !important;
  }

  .\32xl\:row-span-1{
    grid-row: span 1 / span 1 !important;
  }

  .\32xl\:row-span-2{
    grid-row: span 2 / span 2 !important;
  }

  .\32xl\:row-span-3{
    grid-row: span 3 / span 3 !important;
  }

  .\32xl\:row-span-4{
    grid-row: span 4 / span 4 !important;
  }

  .\32xl\:row-span-5{
    grid-row: span 5 / span 5 !important;
  }

  .\32xl\:row-span-6{
    grid-row: span 6 / span 6 !important;
  }

  .\32xl\:row-span-full{
    grid-row: 1 / -1 !important;
  }

  .\32xl\:row-start-1{
    grid-row-start: 1 !important;
  }

  .\32xl\:row-start-2{
    grid-row-start: 2 !important;
  }

  .\32xl\:row-start-3{
    grid-row-start: 3 !important;
  }

  .\32xl\:row-start-4{
    grid-row-start: 4 !important;
  }

  .\32xl\:row-start-5{
    grid-row-start: 5 !important;
  }

  .\32xl\:row-start-6{
    grid-row-start: 6 !important;
  }

  .\32xl\:row-start-7{
    grid-row-start: 7 !important;
  }

  .\32xl\:row-start-auto{
    grid-row-start: auto !important;
  }

  .\32xl\:row-end-1{
    grid-row-end: 1 !important;
  }

  .\32xl\:row-end-2{
    grid-row-end: 2 !important;
  }

  .\32xl\:row-end-3{
    grid-row-end: 3 !important;
  }

  .\32xl\:row-end-4{
    grid-row-end: 4 !important;
  }

  .\32xl\:row-end-5{
    grid-row-end: 5 !important;
  }

  .\32xl\:row-end-6{
    grid-row-end: 6 !important;
  }

  .\32xl\:row-end-7{
    grid-row-end: 7 !important;
  }

  .\32xl\:row-end-auto{
    grid-row-end: auto !important;
  }

  .\32xl\:float-right{
    float: right !important;
  }

  .\32xl\:float-left{
    float: left !important;
  }

  .\32xl\:float-none{
    float: none !important;
  }

  .\32xl\:clear-left{
    clear: left !important;
  }

  .\32xl\:clear-right{
    clear: right !important;
  }

  .\32xl\:clear-both{
    clear: both !important;
  }

  .\32xl\:clear-none{
    clear: none !important;
  }

  .\32xl\:m-0{
    margin: 0px !important;
  }

  .\32xl\:m-1{
    margin: 0.25rem !important;
  }

  .\32xl\:m-2{
    margin: 0.5rem !important;
  }

  .\32xl\:m-3{
    margin: 0.75rem !important;
  }

  .\32xl\:m-4{
    margin: 1rem !important;
  }

  .\32xl\:m-5{
    margin: 1.25rem !important;
  }

  .\32xl\:m-6{
    margin: 1.5rem !important;
  }

  .\32xl\:m-7{
    margin: 1.75rem !important;
  }

  .\32xl\:m-8{
    margin: 2rem !important;
  }

  .\32xl\:m-9{
    margin: 2.25rem !important;
  }

  .\32xl\:m-10{
    margin: 2.5rem !important;
  }

  .\32xl\:m-11{
    margin: 2.75rem !important;
  }

  .\32xl\:m-12{
    margin: 3rem !important;
  }

  .\32xl\:m-14{
    margin: 3.5rem !important;
  }

  .\32xl\:m-16{
    margin: 4rem !important;
  }

  .\32xl\:m-20{
    margin: 5rem !important;
  }

  .\32xl\:m-24{
    margin: 6rem !important;
  }

  .\32xl\:m-28{
    margin: 7rem !important;
  }

  .\32xl\:m-32{
    margin: 8rem !important;
  }

  .\32xl\:m-36{
    margin: 9rem !important;
  }

  .\32xl\:m-40{
    margin: 10rem !important;
  }

  .\32xl\:m-44{
    margin: 11rem !important;
  }

  .\32xl\:m-48{
    margin: 12rem !important;
  }

  .\32xl\:m-52{
    margin: 13rem !important;
  }

  .\32xl\:m-56{
    margin: 14rem !important;
  }

  .\32xl\:m-60{
    margin: 15rem !important;
  }

  .\32xl\:m-64{
    margin: 16rem !important;
  }

  .\32xl\:m-72{
    margin: 18rem !important;
  }

  .\32xl\:m-80{
    margin: 20rem !important;
  }

  .\32xl\:m-96{
    margin: 24rem !important;
  }

  .\32xl\:m-auto{
    margin: auto !important;
  }

  .\32xl\:m-px{
    margin: 1px !important;
  }

  .\32xl\:m-0\.5{
    margin: 0.125rem !important;
  }

  .\32xl\:m-1\.5{
    margin: 0.375rem !important;
  }

  .\32xl\:m-2\.5{
    margin: 0.625rem !important;
  }

  .\32xl\:m-3\.5{
    margin: 0.875rem !important;
  }

  .\32xl\:-m-0{
    margin: 0px !important;
  }

  .\32xl\:-m-1{
    margin: -0.25rem !important;
  }

  .\32xl\:-m-2{
    margin: -0.5rem !important;
  }

  .\32xl\:-m-3{
    margin: -0.75rem !important;
  }

  .\32xl\:-m-4{
    margin: -1rem !important;
  }

  .\32xl\:-m-5{
    margin: -1.25rem !important;
  }

  .\32xl\:-m-6{
    margin: -1.5rem !important;
  }

  .\32xl\:-m-7{
    margin: -1.75rem !important;
  }

  .\32xl\:-m-8{
    margin: -2rem !important;
  }

  .\32xl\:-m-9{
    margin: -2.25rem !important;
  }

  .\32xl\:-m-10{
    margin: -2.5rem !important;
  }

  .\32xl\:-m-11{
    margin: -2.75rem !important;
  }

  .\32xl\:-m-12{
    margin: -3rem !important;
  }

  .\32xl\:-m-14{
    margin: -3.5rem !important;
  }

  .\32xl\:-m-16{
    margin: -4rem !important;
  }

  .\32xl\:-m-20{
    margin: -5rem !important;
  }

  .\32xl\:-m-24{
    margin: -6rem !important;
  }

  .\32xl\:-m-28{
    margin: -7rem !important;
  }

  .\32xl\:-m-32{
    margin: -8rem !important;
  }

  .\32xl\:-m-36{
    margin: -9rem !important;
  }

  .\32xl\:-m-40{
    margin: -10rem !important;
  }

  .\32xl\:-m-44{
    margin: -11rem !important;
  }

  .\32xl\:-m-48{
    margin: -12rem !important;
  }

  .\32xl\:-m-52{
    margin: -13rem !important;
  }

  .\32xl\:-m-56{
    margin: -14rem !important;
  }

  .\32xl\:-m-60{
    margin: -15rem !important;
  }

  .\32xl\:-m-64{
    margin: -16rem !important;
  }

  .\32xl\:-m-72{
    margin: -18rem !important;
  }

  .\32xl\:-m-80{
    margin: -20rem !important;
  }

  .\32xl\:-m-96{
    margin: -24rem !important;
  }

  .\32xl\:-m-px{
    margin: -1px !important;
  }

  .\32xl\:-m-0\.5{
    margin: -0.125rem !important;
  }

  .\32xl\:-m-1\.5{
    margin: -0.375rem !important;
  }

  .\32xl\:-m-2\.5{
    margin: -0.625rem !important;
  }

  .\32xl\:-m-3\.5{
    margin: -0.875rem !important;
  }

  .\32xl\:mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .\32xl\:mx-1{
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .\32xl\:mx-2{
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .\32xl\:mx-3{
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .\32xl\:mx-4{
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .\32xl\:mx-5{
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .\32xl\:mx-6{
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .\32xl\:mx-7{
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }

  .\32xl\:mx-8{
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .\32xl\:mx-9{
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .\32xl\:mx-10{
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .\32xl\:mx-11{
    margin-left: 2.75rem !important;
    margin-right: 2.75rem !important;
  }

  .\32xl\:mx-12{
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .\32xl\:mx-14{
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .\32xl\:mx-16{
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .\32xl\:mx-20{
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .\32xl\:mx-24{
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .\32xl\:mx-28{
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .\32xl\:mx-32{
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .\32xl\:mx-36{
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .\32xl\:mx-40{
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .\32xl\:mx-44{
    margin-left: 11rem !important;
    margin-right: 11rem !important;
  }

  .\32xl\:mx-48{
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .\32xl\:mx-52{
    margin-left: 13rem !important;
    margin-right: 13rem !important;
  }

  .\32xl\:mx-56{
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .\32xl\:mx-60{
    margin-left: 15rem !important;
    margin-right: 15rem !important;
  }

  .\32xl\:mx-64{
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .\32xl\:mx-72{
    margin-left: 18rem !important;
    margin-right: 18rem !important;
  }

  .\32xl\:mx-80{
    margin-left: 20rem !important;
    margin-right: 20rem !important;
  }

  .\32xl\:mx-96{
    margin-left: 24rem !important;
    margin-right: 24rem !important;
  }

  .\32xl\:mx-auto{
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .\32xl\:mx-px{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .\32xl\:mx-0\.5{
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }

  .\32xl\:mx-1\.5{
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
  }

  .\32xl\:mx-2\.5{
    margin-left: 0.625rem !important;
    margin-right: 0.625rem !important;
  }

  .\32xl\:mx-3\.5{
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
  }

  .\32xl\:-mx-0{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .\32xl\:-mx-1{
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .\32xl\:-mx-2{
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .\32xl\:-mx-3{
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .\32xl\:-mx-4{
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .\32xl\:-mx-5{
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .\32xl\:-mx-6{
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .\32xl\:-mx-7{
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }

  .\32xl\:-mx-8{
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .\32xl\:-mx-9{
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .\32xl\:-mx-10{
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .\32xl\:-mx-11{
    margin-left: -2.75rem !important;
    margin-right: -2.75rem !important;
  }

  .\32xl\:-mx-12{
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .\32xl\:-mx-14{
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .\32xl\:-mx-16{
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .\32xl\:-mx-20{
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .\32xl\:-mx-24{
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .\32xl\:-mx-28{
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .\32xl\:-mx-32{
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .\32xl\:-mx-36{
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .\32xl\:-mx-40{
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .\32xl\:-mx-44{
    margin-left: -11rem !important;
    margin-right: -11rem !important;
  }

  .\32xl\:-mx-48{
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .\32xl\:-mx-52{
    margin-left: -13rem !important;
    margin-right: -13rem !important;
  }

  .\32xl\:-mx-56{
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .\32xl\:-mx-60{
    margin-left: -15rem !important;
    margin-right: -15rem !important;
  }

  .\32xl\:-mx-64{
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .\32xl\:-mx-72{
    margin-left: -18rem !important;
    margin-right: -18rem !important;
  }

  .\32xl\:-mx-80{
    margin-left: -20rem !important;
    margin-right: -20rem !important;
  }

  .\32xl\:-mx-96{
    margin-left: -24rem !important;
    margin-right: -24rem !important;
  }

  .\32xl\:-mx-px{
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .\32xl\:-mx-0\.5{
    margin-left: -0.125rem !important;
    margin-right: -0.125rem !important;
  }

  .\32xl\:-mx-1\.5{
    margin-left: -0.375rem !important;
    margin-right: -0.375rem !important;
  }

  .\32xl\:-mx-2\.5{
    margin-left: -0.625rem !important;
    margin-right: -0.625rem !important;
  }

  .\32xl\:-mx-3\.5{
    margin-left: -0.875rem !important;
    margin-right: -0.875rem !important;
  }

  .\32xl\:my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .\32xl\:my-1{
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .\32xl\:my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .\32xl\:my-3{
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .\32xl\:my-4{
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .\32xl\:my-5{
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .\32xl\:my-6{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .\32xl\:my-7{
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .\32xl\:my-8{
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .\32xl\:my-9{
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .\32xl\:my-10{
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .\32xl\:my-11{
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .\32xl\:my-12{
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .\32xl\:my-14{
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .\32xl\:my-16{
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .\32xl\:my-20{
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .\32xl\:my-24{
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .\32xl\:my-28{
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .\32xl\:my-32{
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .\32xl\:my-36{
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .\32xl\:my-40{
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .\32xl\:my-44{
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }

  .\32xl\:my-48{
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .\32xl\:my-52{
    margin-top: 13rem !important;
    margin-bottom: 13rem !important;
  }

  .\32xl\:my-56{
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .\32xl\:my-60{
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }

  .\32xl\:my-64{
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .\32xl\:my-72{
    margin-top: 18rem !important;
    margin-bottom: 18rem !important;
  }

  .\32xl\:my-80{
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }

  .\32xl\:my-96{
    margin-top: 24rem !important;
    margin-bottom: 24rem !important;
  }

  .\32xl\:my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .\32xl\:my-px{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .\32xl\:my-0\.5{
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }

  .\32xl\:my-1\.5{
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }

  .\32xl\:my-2\.5{
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }

  .\32xl\:my-3\.5{
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }

  .\32xl\:-my-0{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .\32xl\:-my-1{
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .\32xl\:-my-2{
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .\32xl\:-my-3{
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .\32xl\:-my-4{
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .\32xl\:-my-5{
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .\32xl\:-my-6{
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .\32xl\:-my-7{
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .\32xl\:-my-8{
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .\32xl\:-my-9{
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .\32xl\:-my-10{
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .\32xl\:-my-11{
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .\32xl\:-my-12{
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .\32xl\:-my-14{
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .\32xl\:-my-16{
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .\32xl\:-my-20{
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .\32xl\:-my-24{
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .\32xl\:-my-28{
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .\32xl\:-my-32{
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .\32xl\:-my-36{
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .\32xl\:-my-40{
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .\32xl\:-my-44{
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }

  .\32xl\:-my-48{
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .\32xl\:-my-52{
    margin-top: -13rem !important;
    margin-bottom: -13rem !important;
  }

  .\32xl\:-my-56{
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .\32xl\:-my-60{
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }

  .\32xl\:-my-64{
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .\32xl\:-my-72{
    margin-top: -18rem !important;
    margin-bottom: -18rem !important;
  }

  .\32xl\:-my-80{
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }

  .\32xl\:-my-96{
    margin-top: -24rem !important;
    margin-bottom: -24rem !important;
  }

  .\32xl\:-my-px{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .\32xl\:-my-0\.5{
    margin-top: -0.125rem !important;
    margin-bottom: -0.125rem !important;
  }

  .\32xl\:-my-1\.5{
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }

  .\32xl\:-my-2\.5{
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }

  .\32xl\:-my-3\.5{
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }

  .\32xl\:mt-0{
    margin-top: 0px !important;
  }

  .\32xl\:mt-1{
    margin-top: 0.25rem !important;
  }

  .\32xl\:mt-2{
    margin-top: 0.5rem !important;
  }

  .\32xl\:mt-3{
    margin-top: 0.75rem !important;
  }

  .\32xl\:mt-4{
    margin-top: 1rem !important;
  }

  .\32xl\:mt-5{
    margin-top: 1.25rem !important;
  }

  .\32xl\:mt-6{
    margin-top: 1.5rem !important;
  }

  .\32xl\:mt-7{
    margin-top: 1.75rem !important;
  }

  .\32xl\:mt-8{
    margin-top: 2rem !important;
  }

  .\32xl\:mt-9{
    margin-top: 2.25rem !important;
  }

  .\32xl\:mt-10{
    margin-top: 2.5rem !important;
  }

  .\32xl\:mt-11{
    margin-top: 2.75rem !important;
  }

  .\32xl\:mt-12{
    margin-top: 3rem !important;
  }

  .\32xl\:mt-14{
    margin-top: 3.5rem !important;
  }

  .\32xl\:mt-16{
    margin-top: 4rem !important;
  }

  .\32xl\:mt-20{
    margin-top: 5rem !important;
  }

  .\32xl\:mt-24{
    margin-top: 6rem !important;
  }

  .\32xl\:mt-28{
    margin-top: 7rem !important;
  }

  .\32xl\:mt-32{
    margin-top: 8rem !important;
  }

  .\32xl\:mt-36{
    margin-top: 9rem !important;
  }

  .\32xl\:mt-40{
    margin-top: 10rem !important;
  }

  .\32xl\:mt-44{
    margin-top: 11rem !important;
  }

  .\32xl\:mt-48{
    margin-top: 12rem !important;
  }

  .\32xl\:mt-52{
    margin-top: 13rem !important;
  }

  .\32xl\:mt-56{
    margin-top: 14rem !important;
  }

  .\32xl\:mt-60{
    margin-top: 15rem !important;
  }

  .\32xl\:mt-64{
    margin-top: 16rem !important;
  }

  .\32xl\:mt-72{
    margin-top: 18rem !important;
  }

  .\32xl\:mt-80{
    margin-top: 20rem !important;
  }

  .\32xl\:mt-96{
    margin-top: 24rem !important;
  }

  .\32xl\:mt-auto{
    margin-top: auto !important;
  }

  .\32xl\:mt-px{
    margin-top: 1px !important;
  }

  .\32xl\:mt-0\.5{
    margin-top: 0.125rem !important;
  }

  .\32xl\:mt-1\.5{
    margin-top: 0.375rem !important;
  }

  .\32xl\:mt-2\.5{
    margin-top: 0.625rem !important;
  }

  .\32xl\:mt-3\.5{
    margin-top: 0.875rem !important;
  }

  .\32xl\:-mt-0{
    margin-top: 0px !important;
  }

  .\32xl\:-mt-1{
    margin-top: -0.25rem !important;
  }

  .\32xl\:-mt-2{
    margin-top: -0.5rem !important;
  }

  .\32xl\:-mt-3{
    margin-top: -0.75rem !important;
  }

  .\32xl\:-mt-4{
    margin-top: -1rem !important;
  }

  .\32xl\:-mt-5{
    margin-top: -1.25rem !important;
  }

  .\32xl\:-mt-6{
    margin-top: -1.5rem !important;
  }

  .\32xl\:-mt-7{
    margin-top: -1.75rem !important;
  }

  .\32xl\:-mt-8{
    margin-top: -2rem !important;
  }

  .\32xl\:-mt-9{
    margin-top: -2.25rem !important;
  }

  .\32xl\:-mt-10{
    margin-top: -2.5rem !important;
  }

  .\32xl\:-mt-11{
    margin-top: -2.75rem !important;
  }

  .\32xl\:-mt-12{
    margin-top: -3rem !important;
  }

  .\32xl\:-mt-14{
    margin-top: -3.5rem !important;
  }

  .\32xl\:-mt-16{
    margin-top: -4rem !important;
  }

  .\32xl\:-mt-20{
    margin-top: -5rem !important;
  }

  .\32xl\:-mt-24{
    margin-top: -6rem !important;
  }

  .\32xl\:-mt-28{
    margin-top: -7rem !important;
  }

  .\32xl\:-mt-32{
    margin-top: -8rem !important;
  }

  .\32xl\:-mt-36{
    margin-top: -9rem !important;
  }

  .\32xl\:-mt-40{
    margin-top: -10rem !important;
  }

  .\32xl\:-mt-44{
    margin-top: -11rem !important;
  }

  .\32xl\:-mt-48{
    margin-top: -12rem !important;
  }

  .\32xl\:-mt-52{
    margin-top: -13rem !important;
  }

  .\32xl\:-mt-56{
    margin-top: -14rem !important;
  }

  .\32xl\:-mt-60{
    margin-top: -15rem !important;
  }

  .\32xl\:-mt-64{
    margin-top: -16rem !important;
  }

  .\32xl\:-mt-72{
    margin-top: -18rem !important;
  }

  .\32xl\:-mt-80{
    margin-top: -20rem !important;
  }

  .\32xl\:-mt-96{
    margin-top: -24rem !important;
  }

  .\32xl\:-mt-px{
    margin-top: -1px !important;
  }

  .\32xl\:-mt-0\.5{
    margin-top: -0.125rem !important;
  }

  .\32xl\:-mt-1\.5{
    margin-top: -0.375rem !important;
  }

  .\32xl\:-mt-2\.5{
    margin-top: -0.625rem !important;
  }

  .\32xl\:-mt-3\.5{
    margin-top: -0.875rem !important;
  }

  .\32xl\:mr-0{
    margin-right: 0px !important;
  }

  .\32xl\:mr-1{
    margin-right: 0.25rem !important;
  }

  .\32xl\:mr-2{
    margin-right: 0.5rem !important;
  }

  .\32xl\:mr-3{
    margin-right: 0.75rem !important;
  }

  .\32xl\:mr-4{
    margin-right: 1rem !important;
  }

  .\32xl\:mr-5{
    margin-right: 1.25rem !important;
  }

  .\32xl\:mr-6{
    margin-right: 1.5rem !important;
  }

  .\32xl\:mr-7{
    margin-right: 1.75rem !important;
  }

  .\32xl\:mr-8{
    margin-right: 2rem !important;
  }

  .\32xl\:mr-9{
    margin-right: 2.25rem !important;
  }

  .\32xl\:mr-10{
    margin-right: 2.5rem !important;
  }

  .\32xl\:mr-11{
    margin-right: 2.75rem !important;
  }

  .\32xl\:mr-12{
    margin-right: 3rem !important;
  }

  .\32xl\:mr-14{
    margin-right: 3.5rem !important;
  }

  .\32xl\:mr-16{
    margin-right: 4rem !important;
  }

  .\32xl\:mr-20{
    margin-right: 5rem !important;
  }

  .\32xl\:mr-24{
    margin-right: 6rem !important;
  }

  .\32xl\:mr-28{
    margin-right: 7rem !important;
  }

  .\32xl\:mr-32{
    margin-right: 8rem !important;
  }

  .\32xl\:mr-36{
    margin-right: 9rem !important;
  }

  .\32xl\:mr-40{
    margin-right: 10rem !important;
  }

  .\32xl\:mr-44{
    margin-right: 11rem !important;
  }

  .\32xl\:mr-48{
    margin-right: 12rem !important;
  }

  .\32xl\:mr-52{
    margin-right: 13rem !important;
  }

  .\32xl\:mr-56{
    margin-right: 14rem !important;
  }

  .\32xl\:mr-60{
    margin-right: 15rem !important;
  }

  .\32xl\:mr-64{
    margin-right: 16rem !important;
  }

  .\32xl\:mr-72{
    margin-right: 18rem !important;
  }

  .\32xl\:mr-80{
    margin-right: 20rem !important;
  }

  .\32xl\:mr-96{
    margin-right: 24rem !important;
  }

  .\32xl\:mr-auto{
    margin-right: auto !important;
  }

  .\32xl\:mr-px{
    margin-right: 1px !important;
  }

  .\32xl\:mr-0\.5{
    margin-right: 0.125rem !important;
  }

  .\32xl\:mr-1\.5{
    margin-right: 0.375rem !important;
  }

  .\32xl\:mr-2\.5{
    margin-right: 0.625rem !important;
  }

  .\32xl\:mr-3\.5{
    margin-right: 0.875rem !important;
  }

  .\32xl\:-mr-0{
    margin-right: 0px !important;
  }

  .\32xl\:-mr-1{
    margin-right: -0.25rem !important;
  }

  .\32xl\:-mr-2{
    margin-right: -0.5rem !important;
  }

  .\32xl\:-mr-3{
    margin-right: -0.75rem !important;
  }

  .\32xl\:-mr-4{
    margin-right: -1rem !important;
  }

  .\32xl\:-mr-5{
    margin-right: -1.25rem !important;
  }

  .\32xl\:-mr-6{
    margin-right: -1.5rem !important;
  }

  .\32xl\:-mr-7{
    margin-right: -1.75rem !important;
  }

  .\32xl\:-mr-8{
    margin-right: -2rem !important;
  }

  .\32xl\:-mr-9{
    margin-right: -2.25rem !important;
  }

  .\32xl\:-mr-10{
    margin-right: -2.5rem !important;
  }

  .\32xl\:-mr-11{
    margin-right: -2.75rem !important;
  }

  .\32xl\:-mr-12{
    margin-right: -3rem !important;
  }

  .\32xl\:-mr-14{
    margin-right: -3.5rem !important;
  }

  .\32xl\:-mr-16{
    margin-right: -4rem !important;
  }

  .\32xl\:-mr-20{
    margin-right: -5rem !important;
  }

  .\32xl\:-mr-24{
    margin-right: -6rem !important;
  }

  .\32xl\:-mr-28{
    margin-right: -7rem !important;
  }

  .\32xl\:-mr-32{
    margin-right: -8rem !important;
  }

  .\32xl\:-mr-36{
    margin-right: -9rem !important;
  }

  .\32xl\:-mr-40{
    margin-right: -10rem !important;
  }

  .\32xl\:-mr-44{
    margin-right: -11rem !important;
  }

  .\32xl\:-mr-48{
    margin-right: -12rem !important;
  }

  .\32xl\:-mr-52{
    margin-right: -13rem !important;
  }

  .\32xl\:-mr-56{
    margin-right: -14rem !important;
  }

  .\32xl\:-mr-60{
    margin-right: -15rem !important;
  }

  .\32xl\:-mr-64{
    margin-right: -16rem !important;
  }

  .\32xl\:-mr-72{
    margin-right: -18rem !important;
  }

  .\32xl\:-mr-80{
    margin-right: -20rem !important;
  }

  .\32xl\:-mr-96{
    margin-right: -24rem !important;
  }

  .\32xl\:-mr-px{
    margin-right: -1px !important;
  }

  .\32xl\:-mr-0\.5{
    margin-right: -0.125rem !important;
  }

  .\32xl\:-mr-1\.5{
    margin-right: -0.375rem !important;
  }

  .\32xl\:-mr-2\.5{
    margin-right: -0.625rem !important;
  }

  .\32xl\:-mr-3\.5{
    margin-right: -0.875rem !important;
  }

  .\32xl\:mb-0{
    margin-bottom: 0px !important;
  }

  .\32xl\:mb-1{
    margin-bottom: 0.25rem !important;
  }

  .\32xl\:mb-2{
    margin-bottom: 0.5rem !important;
  }

  .\32xl\:mb-3{
    margin-bottom: 0.75rem !important;
  }

  .\32xl\:mb-4{
    margin-bottom: 1rem !important;
  }

  .\32xl\:mb-5{
    margin-bottom: 1.25rem !important;
  }

  .\32xl\:mb-6{
    margin-bottom: 1.5rem !important;
  }

  .\32xl\:mb-7{
    margin-bottom: 1.75rem !important;
  }

  .\32xl\:mb-8{
    margin-bottom: 2rem !important;
  }

  .\32xl\:mb-9{
    margin-bottom: 2.25rem !important;
  }

  .\32xl\:mb-10{
    margin-bottom: 2.5rem !important;
  }

  .\32xl\:mb-11{
    margin-bottom: 2.75rem !important;
  }

  .\32xl\:mb-12{
    margin-bottom: 3rem !important;
  }

  .\32xl\:mb-14{
    margin-bottom: 3.5rem !important;
  }

  .\32xl\:mb-16{
    margin-bottom: 4rem !important;
  }

  .\32xl\:mb-20{
    margin-bottom: 5rem !important;
  }

  .\32xl\:mb-24{
    margin-bottom: 6rem !important;
  }

  .\32xl\:mb-28{
    margin-bottom: 7rem !important;
  }

  .\32xl\:mb-32{
    margin-bottom: 8rem !important;
  }

  .\32xl\:mb-36{
    margin-bottom: 9rem !important;
  }

  .\32xl\:mb-40{
    margin-bottom: 10rem !important;
  }

  .\32xl\:mb-44{
    margin-bottom: 11rem !important;
  }

  .\32xl\:mb-48{
    margin-bottom: 12rem !important;
  }

  .\32xl\:mb-52{
    margin-bottom: 13rem !important;
  }

  .\32xl\:mb-56{
    margin-bottom: 14rem !important;
  }

  .\32xl\:mb-60{
    margin-bottom: 15rem !important;
  }

  .\32xl\:mb-64{
    margin-bottom: 16rem !important;
  }

  .\32xl\:mb-72{
    margin-bottom: 18rem !important;
  }

  .\32xl\:mb-80{
    margin-bottom: 20rem !important;
  }

  .\32xl\:mb-96{
    margin-bottom: 24rem !important;
  }

  .\32xl\:mb-auto{
    margin-bottom: auto !important;
  }

  .\32xl\:mb-px{
    margin-bottom: 1px !important;
  }

  .\32xl\:mb-0\.5{
    margin-bottom: 0.125rem !important;
  }

  .\32xl\:mb-1\.5{
    margin-bottom: 0.375rem !important;
  }

  .\32xl\:mb-2\.5{
    margin-bottom: 0.625rem !important;
  }

  .\32xl\:mb-3\.5{
    margin-bottom: 0.875rem !important;
  }

  .\32xl\:-mb-0{
    margin-bottom: 0px !important;
  }

  .\32xl\:-mb-1{
    margin-bottom: -0.25rem !important;
  }

  .\32xl\:-mb-2{
    margin-bottom: -0.5rem !important;
  }

  .\32xl\:-mb-3{
    margin-bottom: -0.75rem !important;
  }

  .\32xl\:-mb-4{
    margin-bottom: -1rem !important;
  }

  .\32xl\:-mb-5{
    margin-bottom: -1.25rem !important;
  }

  .\32xl\:-mb-6{
    margin-bottom: -1.5rem !important;
  }

  .\32xl\:-mb-7{
    margin-bottom: -1.75rem !important;
  }

  .\32xl\:-mb-8{
    margin-bottom: -2rem !important;
  }

  .\32xl\:-mb-9{
    margin-bottom: -2.25rem !important;
  }

  .\32xl\:-mb-10{
    margin-bottom: -2.5rem !important;
  }

  .\32xl\:-mb-11{
    margin-bottom: -2.75rem !important;
  }

  .\32xl\:-mb-12{
    margin-bottom: -3rem !important;
  }

  .\32xl\:-mb-14{
    margin-bottom: -3.5rem !important;
  }

  .\32xl\:-mb-16{
    margin-bottom: -4rem !important;
  }

  .\32xl\:-mb-20{
    margin-bottom: -5rem !important;
  }

  .\32xl\:-mb-24{
    margin-bottom: -6rem !important;
  }

  .\32xl\:-mb-28{
    margin-bottom: -7rem !important;
  }

  .\32xl\:-mb-32{
    margin-bottom: -8rem !important;
  }

  .\32xl\:-mb-36{
    margin-bottom: -9rem !important;
  }

  .\32xl\:-mb-40{
    margin-bottom: -10rem !important;
  }

  .\32xl\:-mb-44{
    margin-bottom: -11rem !important;
  }

  .\32xl\:-mb-48{
    margin-bottom: -12rem !important;
  }

  .\32xl\:-mb-52{
    margin-bottom: -13rem !important;
  }

  .\32xl\:-mb-56{
    margin-bottom: -14rem !important;
  }

  .\32xl\:-mb-60{
    margin-bottom: -15rem !important;
  }

  .\32xl\:-mb-64{
    margin-bottom: -16rem !important;
  }

  .\32xl\:-mb-72{
    margin-bottom: -18rem !important;
  }

  .\32xl\:-mb-80{
    margin-bottom: -20rem !important;
  }

  .\32xl\:-mb-96{
    margin-bottom: -24rem !important;
  }

  .\32xl\:-mb-px{
    margin-bottom: -1px !important;
  }

  .\32xl\:-mb-0\.5{
    margin-bottom: -0.125rem !important;
  }

  .\32xl\:-mb-1\.5{
    margin-bottom: -0.375rem !important;
  }

  .\32xl\:-mb-2\.5{
    margin-bottom: -0.625rem !important;
  }

  .\32xl\:-mb-3\.5{
    margin-bottom: -0.875rem !important;
  }

  .\32xl\:ml-0{
    margin-left: 0px !important;
  }

  .\32xl\:ml-1{
    margin-left: 0.25rem !important;
  }

  .\32xl\:ml-2{
    margin-left: 0.5rem !important;
  }

  .\32xl\:ml-3{
    margin-left: 0.75rem !important;
  }

  .\32xl\:ml-4{
    margin-left: 1rem !important;
  }

  .\32xl\:ml-5{
    margin-left: 1.25rem !important;
  }

  .\32xl\:ml-6{
    margin-left: 1.5rem !important;
  }

  .\32xl\:ml-7{
    margin-left: 1.75rem !important;
  }

  .\32xl\:ml-8{
    margin-left: 2rem !important;
  }

  .\32xl\:ml-9{
    margin-left: 2.25rem !important;
  }

  .\32xl\:ml-10{
    margin-left: 2.5rem !important;
  }

  .\32xl\:ml-11{
    margin-left: 2.75rem !important;
  }

  .\32xl\:ml-12{
    margin-left: 3rem !important;
  }

  .\32xl\:ml-14{
    margin-left: 3.5rem !important;
  }

  .\32xl\:ml-16{
    margin-left: 4rem !important;
  }

  .\32xl\:ml-20{
    margin-left: 5rem !important;
  }

  .\32xl\:ml-24{
    margin-left: 6rem !important;
  }

  .\32xl\:ml-28{
    margin-left: 7rem !important;
  }

  .\32xl\:ml-32{
    margin-left: 8rem !important;
  }

  .\32xl\:ml-36{
    margin-left: 9rem !important;
  }

  .\32xl\:ml-40{
    margin-left: 10rem !important;
  }

  .\32xl\:ml-44{
    margin-left: 11rem !important;
  }

  .\32xl\:ml-48{
    margin-left: 12rem !important;
  }

  .\32xl\:ml-52{
    margin-left: 13rem !important;
  }

  .\32xl\:ml-56{
    margin-left: 14rem !important;
  }

  .\32xl\:ml-60{
    margin-left: 15rem !important;
  }

  .\32xl\:ml-64{
    margin-left: 16rem !important;
  }

  .\32xl\:ml-72{
    margin-left: 18rem !important;
  }

  .\32xl\:ml-80{
    margin-left: 20rem !important;
  }

  .\32xl\:ml-96{
    margin-left: 24rem !important;
  }

  .\32xl\:ml-auto{
    margin-left: auto !important;
  }

  .\32xl\:ml-px{
    margin-left: 1px !important;
  }

  .\32xl\:ml-0\.5{
    margin-left: 0.125rem !important;
  }

  .\32xl\:ml-1\.5{
    margin-left: 0.375rem !important;
  }

  .\32xl\:ml-2\.5{
    margin-left: 0.625rem !important;
  }

  .\32xl\:ml-3\.5{
    margin-left: 0.875rem !important;
  }

  .\32xl\:-ml-0{
    margin-left: 0px !important;
  }

  .\32xl\:-ml-1{
    margin-left: -0.25rem !important;
  }

  .\32xl\:-ml-2{
    margin-left: -0.5rem !important;
  }

  .\32xl\:-ml-3{
    margin-left: -0.75rem !important;
  }

  .\32xl\:-ml-4{
    margin-left: -1rem !important;
  }

  .\32xl\:-ml-5{
    margin-left: -1.25rem !important;
  }

  .\32xl\:-ml-6{
    margin-left: -1.5rem !important;
  }

  .\32xl\:-ml-7{
    margin-left: -1.75rem !important;
  }

  .\32xl\:-ml-8{
    margin-left: -2rem !important;
  }

  .\32xl\:-ml-9{
    margin-left: -2.25rem !important;
  }

  .\32xl\:-ml-10{
    margin-left: -2.5rem !important;
  }

  .\32xl\:-ml-11{
    margin-left: -2.75rem !important;
  }

  .\32xl\:-ml-12{
    margin-left: -3rem !important;
  }

  .\32xl\:-ml-14{
    margin-left: -3.5rem !important;
  }

  .\32xl\:-ml-16{
    margin-left: -4rem !important;
  }

  .\32xl\:-ml-20{
    margin-left: -5rem !important;
  }

  .\32xl\:-ml-24{
    margin-left: -6rem !important;
  }

  .\32xl\:-ml-28{
    margin-left: -7rem !important;
  }

  .\32xl\:-ml-32{
    margin-left: -8rem !important;
  }

  .\32xl\:-ml-36{
    margin-left: -9rem !important;
  }

  .\32xl\:-ml-40{
    margin-left: -10rem !important;
  }

  .\32xl\:-ml-44{
    margin-left: -11rem !important;
  }

  .\32xl\:-ml-48{
    margin-left: -12rem !important;
  }

  .\32xl\:-ml-52{
    margin-left: -13rem !important;
  }

  .\32xl\:-ml-56{
    margin-left: -14rem !important;
  }

  .\32xl\:-ml-60{
    margin-left: -15rem !important;
  }

  .\32xl\:-ml-64{
    margin-left: -16rem !important;
  }

  .\32xl\:-ml-72{
    margin-left: -18rem !important;
  }

  .\32xl\:-ml-80{
    margin-left: -20rem !important;
  }

  .\32xl\:-ml-96{
    margin-left: -24rem !important;
  }

  .\32xl\:-ml-px{
    margin-left: -1px !important;
  }

  .\32xl\:-ml-0\.5{
    margin-left: -0.125rem !important;
  }

  .\32xl\:-ml-1\.5{
    margin-left: -0.375rem !important;
  }

  .\32xl\:-ml-2\.5{
    margin-left: -0.625rem !important;
  }

  .\32xl\:-ml-3\.5{
    margin-left: -0.875rem !important;
  }

  .\32xl\:box-border{
    box-sizing: border-box !important;
  }

  .\32xl\:box-content{
    box-sizing: content-box !important;
  }

  .\32xl\:block{
    display: block !important;
  }

  .\32xl\:inline-block{
    display: inline-block !important;
  }

  .\32xl\:inline{
    display: inline !important;
  }

  .\32xl\:flex{
    display: flex !important;
  }

  .\32xl\:inline-flex{
    display: inline-flex !important;
  }

  .\32xl\:table{
    display: table !important;
  }

  .\32xl\:inline-table{
    display: inline-table !important;
  }

  .\32xl\:table-caption{
    display: table-caption !important;
  }

  .\32xl\:table-cell{
    display: table-cell !important;
  }

  .\32xl\:table-column{
    display: table-column !important;
  }

  .\32xl\:table-column-group{
    display: table-column-group !important;
  }

  .\32xl\:table-footer-group{
    display: table-footer-group !important;
  }

  .\32xl\:table-header-group{
    display: table-header-group !important;
  }

  .\32xl\:table-row-group{
    display: table-row-group !important;
  }

  .\32xl\:table-row{
    display: table-row !important;
  }

  .\32xl\:flow-root{
    display: flow-root !important;
  }

  .\32xl\:grid{
    display: grid !important;
  }

  .\32xl\:inline-grid{
    display: inline-grid !important;
  }

  .\32xl\:contents{
    display: contents !important;
  }

  .\32xl\:list-item{
    display: list-item !important;
  }

  .\32xl\:hidden{
    display: none !important;
  }

  .\32xl\:h-0{
    height: 0px !important;
  }

  .\32xl\:h-1{
    height: 0.25rem !important;
  }

  .\32xl\:h-2{
    height: 0.5rem !important;
  }

  .\32xl\:h-3{
    height: 0.75rem !important;
  }

  .\32xl\:h-4{
    height: 1rem !important;
  }

  .\32xl\:h-5{
    height: 1.25rem !important;
  }

  .\32xl\:h-6{
    height: 1.5rem !important;
  }

  .\32xl\:h-7{
    height: 1.75rem !important;
  }

  .\32xl\:h-8{
    height: 2rem !important;
  }

  .\32xl\:h-9{
    height: 2.25rem !important;
  }

  .\32xl\:h-10{
    height: 2.5rem !important;
  }

  .\32xl\:h-11{
    height: 2.75rem !important;
  }

  .\32xl\:h-12{
    height: 3rem !important;
  }

  .\32xl\:h-14{
    height: 3.5rem !important;
  }

  .\32xl\:h-16{
    height: 4rem !important;
  }

  .\32xl\:h-20{
    height: 5rem !important;
  }

  .\32xl\:h-24{
    height: 6rem !important;
  }

  .\32xl\:h-28{
    height: 7rem !important;
  }

  .\32xl\:h-32{
    height: 8rem !important;
  }

  .\32xl\:h-36{
    height: 9rem !important;
  }

  .\32xl\:h-40{
    height: 10rem !important;
  }

  .\32xl\:h-44{
    height: 11rem !important;
  }

  .\32xl\:h-48{
    height: 12rem !important;
  }

  .\32xl\:h-52{
    height: 13rem !important;
  }

  .\32xl\:h-56{
    height: 14rem !important;
  }

  .\32xl\:h-60{
    height: 15rem !important;
  }

  .\32xl\:h-64{
    height: 16rem !important;
  }

  .\32xl\:h-72{
    height: 18rem !important;
  }

  .\32xl\:h-80{
    height: 20rem !important;
  }

  .\32xl\:h-96{
    height: 24rem !important;
  }

  .\32xl\:h-auto{
    height: auto !important;
  }

  .\32xl\:h-px{
    height: 1px !important;
  }

  .\32xl\:h-0\.5{
    height: 0.125rem !important;
  }

  .\32xl\:h-1\.5{
    height: 0.375rem !important;
  }

  .\32xl\:h-2\.5{
    height: 0.625rem !important;
  }

  .\32xl\:h-3\.5{
    height: 0.875rem !important;
  }

  .\32xl\:h-1\/2{
    height: 50% !important;
  }

  .\32xl\:h-1\/3{
    height: 33.333333% !important;
  }

  .\32xl\:h-2\/3{
    height: 66.666667% !important;
  }

  .\32xl\:h-1\/4{
    height: 25% !important;
  }

  .\32xl\:h-2\/4{
    height: 50% !important;
  }

  .\32xl\:h-3\/4{
    height: 75% !important;
  }

  .\32xl\:h-1\/5{
    height: 20% !important;
  }

  .\32xl\:h-2\/5{
    height: 40% !important;
  }

  .\32xl\:h-3\/5{
    height: 60% !important;
  }

  .\32xl\:h-4\/5{
    height: 80% !important;
  }

  .\32xl\:h-1\/6{
    height: 16.666667% !important;
  }

  .\32xl\:h-2\/6{
    height: 33.333333% !important;
  }

  .\32xl\:h-3\/6{
    height: 50% !important;
  }

  .\32xl\:h-4\/6{
    height: 66.666667% !important;
  }

  .\32xl\:h-5\/6{
    height: 83.333333% !important;
  }

  .\32xl\:h-full{
    height: 100% !important;
  }

  .\32xl\:h-screen{
    height: 100vh !important;
  }

  .\32xl\:max-h-0{
    max-height: 0px !important;
  }

  .\32xl\:max-h-1{
    max-height: 0.25rem !important;
  }

  .\32xl\:max-h-2{
    max-height: 0.5rem !important;
  }

  .\32xl\:max-h-3{
    max-height: 0.75rem !important;
  }

  .\32xl\:max-h-4{
    max-height: 1rem !important;
  }

  .\32xl\:max-h-5{
    max-height: 1.25rem !important;
  }

  .\32xl\:max-h-6{
    max-height: 1.5rem !important;
  }

  .\32xl\:max-h-7{
    max-height: 1.75rem !important;
  }

  .\32xl\:max-h-8{
    max-height: 2rem !important;
  }

  .\32xl\:max-h-9{
    max-height: 2.25rem !important;
  }

  .\32xl\:max-h-10{
    max-height: 2.5rem !important;
  }

  .\32xl\:max-h-11{
    max-height: 2.75rem !important;
  }

  .\32xl\:max-h-12{
    max-height: 3rem !important;
  }

  .\32xl\:max-h-14{
    max-height: 3.5rem !important;
  }

  .\32xl\:max-h-16{
    max-height: 4rem !important;
  }

  .\32xl\:max-h-20{
    max-height: 5rem !important;
  }

  .\32xl\:max-h-24{
    max-height: 6rem !important;
  }

  .\32xl\:max-h-28{
    max-height: 7rem !important;
  }

  .\32xl\:max-h-32{
    max-height: 8rem !important;
  }

  .\32xl\:max-h-36{
    max-height: 9rem !important;
  }

  .\32xl\:max-h-40{
    max-height: 10rem !important;
  }

  .\32xl\:max-h-44{
    max-height: 11rem !important;
  }

  .\32xl\:max-h-48{
    max-height: 12rem !important;
  }

  .\32xl\:max-h-52{
    max-height: 13rem !important;
  }

  .\32xl\:max-h-56{
    max-height: 14rem !important;
  }

  .\32xl\:max-h-60{
    max-height: 15rem !important;
  }

  .\32xl\:max-h-64{
    max-height: 16rem !important;
  }

  .\32xl\:max-h-72{
    max-height: 18rem !important;
  }

  .\32xl\:max-h-80{
    max-height: 20rem !important;
  }

  .\32xl\:max-h-96{
    max-height: 24rem !important;
  }

  .\32xl\:max-h-px{
    max-height: 1px !important;
  }

  .\32xl\:max-h-0\.5{
    max-height: 0.125rem !important;
  }

  .\32xl\:max-h-1\.5{
    max-height: 0.375rem !important;
  }

  .\32xl\:max-h-2\.5{
    max-height: 0.625rem !important;
  }

  .\32xl\:max-h-3\.5{
    max-height: 0.875rem !important;
  }

  .\32xl\:max-h-full{
    max-height: 100% !important;
  }

  .\32xl\:max-h-screen{
    max-height: 100vh !important;
  }

  .\32xl\:min-h-0{
    min-height: 0px !important;
  }

  .\32xl\:min-h-full{
    min-height: 100% !important;
  }

  .\32xl\:min-h-screen{
    min-height: 100vh !important;
  }

  .\32xl\:w-0{
    width: 0px !important;
  }

  .\32xl\:w-1{
    width: 0.25rem !important;
  }

  .\32xl\:w-2{
    width: 0.5rem !important;
  }

  .\32xl\:w-3{
    width: 0.75rem !important;
  }

  .\32xl\:w-4{
    width: 1rem !important;
  }

  .\32xl\:w-5{
    width: 1.25rem !important;
  }

  .\32xl\:w-6{
    width: 1.5rem !important;
  }

  .\32xl\:w-7{
    width: 1.75rem !important;
  }

  .\32xl\:w-8{
    width: 2rem !important;
  }

  .\32xl\:w-9{
    width: 2.25rem !important;
  }

  .\32xl\:w-10{
    width: 2.5rem !important;
  }

  .\32xl\:w-11{
    width: 2.75rem !important;
  }

  .\32xl\:w-12{
    width: 3rem !important;
  }

  .\32xl\:w-14{
    width: 3.5rem !important;
  }

  .\32xl\:w-16{
    width: 4rem !important;
  }

  .\32xl\:w-20{
    width: 5rem !important;
  }

  .\32xl\:w-24{
    width: 6rem !important;
  }

  .\32xl\:w-28{
    width: 7rem !important;
  }

  .\32xl\:w-32{
    width: 8rem !important;
  }

  .\32xl\:w-36{
    width: 9rem !important;
  }

  .\32xl\:w-40{
    width: 10rem !important;
  }

  .\32xl\:w-44{
    width: 11rem !important;
  }

  .\32xl\:w-48{
    width: 12rem !important;
  }

  .\32xl\:w-52{
    width: 13rem !important;
  }

  .\32xl\:w-56{
    width: 14rem !important;
  }

  .\32xl\:w-60{
    width: 15rem !important;
  }

  .\32xl\:w-64{
    width: 16rem !important;
  }

  .\32xl\:w-72{
    width: 18rem !important;
  }

  .\32xl\:w-80{
    width: 20rem !important;
  }

  .\32xl\:w-96{
    width: 24rem !important;
  }

  .\32xl\:w-auto{
    width: auto !important;
  }

  .\32xl\:w-px{
    width: 1px !important;
  }

  .\32xl\:w-0\.5{
    width: 0.125rem !important;
  }

  .\32xl\:w-1\.5{
    width: 0.375rem !important;
  }

  .\32xl\:w-2\.5{
    width: 0.625rem !important;
  }

  .\32xl\:w-3\.5{
    width: 0.875rem !important;
  }

  .\32xl\:w-1\/2{
    width: 50% !important;
  }

  .\32xl\:w-1\/3{
    width: 33.333333% !important;
  }

  .\32xl\:w-2\/3{
    width: 66.666667% !important;
  }

  .\32xl\:w-1\/4{
    width: 25% !important;
  }

  .\32xl\:w-2\/4{
    width: 50% !important;
  }

  .\32xl\:w-3\/4{
    width: 75% !important;
  }

  .\32xl\:w-1\/5{
    width: 20% !important;
  }

  .\32xl\:w-2\/5{
    width: 40% !important;
  }

  .\32xl\:w-3\/5{
    width: 60% !important;
  }

  .\32xl\:w-4\/5{
    width: 80% !important;
  }

  .\32xl\:w-1\/6{
    width: 16.666667% !important;
  }

  .\32xl\:w-2\/6{
    width: 33.333333% !important;
  }

  .\32xl\:w-3\/6{
    width: 50% !important;
  }

  .\32xl\:w-4\/6{
    width: 66.666667% !important;
  }

  .\32xl\:w-5\/6{
    width: 83.333333% !important;
  }

  .\32xl\:w-1\/12{
    width: 8.333333% !important;
  }

  .\32xl\:w-2\/12{
    width: 16.666667% !important;
  }

  .\32xl\:w-3\/12{
    width: 25% !important;
  }

  .\32xl\:w-4\/12{
    width: 33.333333% !important;
  }

  .\32xl\:w-5\/12{
    width: 41.666667% !important;
  }

  .\32xl\:w-6\/12{
    width: 50% !important;
  }

  .\32xl\:w-7\/12{
    width: 58.333333% !important;
  }

  .\32xl\:w-8\/12{
    width: 66.666667% !important;
  }

  .\32xl\:w-9\/12{
    width: 75% !important;
  }

  .\32xl\:w-10\/12{
    width: 83.333333% !important;
  }

  .\32xl\:w-11\/12{
    width: 91.666667% !important;
  }

  .\32xl\:w-full{
    width: 100% !important;
  }

  .\32xl\:w-screen{
    width: 100vw !important;
  }

  .\32xl\:w-min{
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
  }

  .\32xl\:w-max{
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
  }

  .\32xl\:min-w-0{
    min-width: 0px !important;
  }

  .\32xl\:min-w-full{
    min-width: 100% !important;
  }

  .\32xl\:min-w-min{
    min-width: -webkit-min-content !important;
    min-width: -moz-min-content !important;
    min-width: min-content !important;
  }

  .\32xl\:min-w-max{
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
  }

  .\32xl\:max-w-0{
    max-width: 0rem !important;
  }

  .\32xl\:max-w-none{
    max-width: none !important;
  }

  .\32xl\:max-w-xs{
    max-width: 20rem !important;
  }

  .\32xl\:max-w-sm{
    max-width: 24rem !important;
  }

  .\32xl\:max-w-md{
    max-width: 28rem !important;
  }

  .\32xl\:max-w-lg{
    max-width: 32rem !important;
  }

  .\32xl\:max-w-xl{
    max-width: 36rem !important;
  }

  .\32xl\:max-w-2xl{
    max-width: 42rem !important;
  }

  .\32xl\:max-w-3xl{
    max-width: 48rem !important;
  }

  .\32xl\:max-w-4xl{
    max-width: 56rem !important;
  }

  .\32xl\:max-w-5xl{
    max-width: 64rem !important;
  }

  .\32xl\:max-w-6xl{
    max-width: 72rem !important;
  }

  .\32xl\:max-w-7xl{
    max-width: 80rem !important;
  }

  .\32xl\:max-w-full{
    max-width: 100% !important;
  }

  .\32xl\:max-w-min{
    max-width: -webkit-min-content !important;
    max-width: -moz-min-content !important;
    max-width: min-content !important;
  }

  .\32xl\:max-w-max{
    max-width: -webkit-max-content !important;
    max-width: -moz-max-content !important;
    max-width: max-content !important;
  }

  .\32xl\:max-w-prose{
    max-width: 65ch !important;
  }

  .\32xl\:max-w-screen-sm{
    max-width: 640px !important;
  }

  .\32xl\:max-w-screen-md{
    max-width: 768px !important;
  }

  .\32xl\:max-w-screen-lg{
    max-width: 1024px !important;
  }

  .\32xl\:max-w-screen-xl{
    max-width: 1280px !important;
  }

  .\32xl\:max-w-screen-2xl{
    max-width: 1536px !important;
  }

  .\32xl\:flex-1{
    flex: 1 1 0% !important;
  }

  .\32xl\:flex-auto{
    flex: 1 1 auto !important;
  }

  .\32xl\:flex-initial{
    flex: 0 1 auto !important;
  }

  .\32xl\:flex-none{
    flex: none !important;
  }

  .\32xl\:flex-shrink-0{
    flex-shrink: 0 !important;
  }

  .\32xl\:flex-shrink{
    flex-shrink: 1 !important;
  }

  .\32xl\:flex-grow-0{
    flex-grow: 0 !important;
  }

  .\32xl\:flex-grow{
    flex-grow: 1 !important;
  }

  .\32xl\:table-auto{
    table-layout: auto !important;
  }

  .\32xl\:table-fixed{
    table-layout: fixed !important;
  }

  .\32xl\:border-collapse{
    border-collapse: collapse !important;
  }

  .\32xl\:border-separate{
    border-collapse: separate !important;
  }

  .\32xl\:origin-center{
    transform-origin: center !important;
  }

  .\32xl\:origin-top{
    transform-origin: top !important;
  }

  .\32xl\:origin-top-right{
    transform-origin: top right !important;
  }

  .\32xl\:origin-right{
    transform-origin: right !important;
  }

  .\32xl\:origin-bottom-right{
    transform-origin: bottom right !important;
  }

  .\32xl\:origin-bottom{
    transform-origin: bottom !important;
  }

  .\32xl\:origin-bottom-left{
    transform-origin: bottom left !important;
  }

  .\32xl\:origin-left{
    transform-origin: left !important;
  }

  .\32xl\:origin-top-left{
    transform-origin: top left !important;
  }

  .\32xl\:transform{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\32xl\:transform-gpu{
    --tw-translate-x: 0 !important;
    --tw-translate-y: 0 !important;
    --tw-rotate: 0 !important;
    --tw-skew-x: 0 !important;
    --tw-skew-y: 0 !important;
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  }

  .\32xl\:transform-none{
    transform: none !important;
  }

  .\32xl\:translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:translate-x-1{
    --tw-translate-x: 0.25rem !important;
  }

  .\32xl\:translate-x-2{
    --tw-translate-x: 0.5rem !important;
  }

  .\32xl\:translate-x-3{
    --tw-translate-x: 0.75rem !important;
  }

  .\32xl\:translate-x-4{
    --tw-translate-x: 1rem !important;
  }

  .\32xl\:translate-x-5{
    --tw-translate-x: 1.25rem !important;
  }

  .\32xl\:translate-x-6{
    --tw-translate-x: 1.5rem !important;
  }

  .\32xl\:translate-x-7{
    --tw-translate-x: 1.75rem !important;
  }

  .\32xl\:translate-x-8{
    --tw-translate-x: 2rem !important;
  }

  .\32xl\:translate-x-9{
    --tw-translate-x: 2.25rem !important;
  }

  .\32xl\:translate-x-10{
    --tw-translate-x: 2.5rem !important;
  }

  .\32xl\:translate-x-11{
    --tw-translate-x: 2.75rem !important;
  }

  .\32xl\:translate-x-12{
    --tw-translate-x: 3rem !important;
  }

  .\32xl\:translate-x-14{
    --tw-translate-x: 3.5rem !important;
  }

  .\32xl\:translate-x-16{
    --tw-translate-x: 4rem !important;
  }

  .\32xl\:translate-x-20{
    --tw-translate-x: 5rem !important;
  }

  .\32xl\:translate-x-24{
    --tw-translate-x: 6rem !important;
  }

  .\32xl\:translate-x-28{
    --tw-translate-x: 7rem !important;
  }

  .\32xl\:translate-x-32{
    --tw-translate-x: 8rem !important;
  }

  .\32xl\:translate-x-36{
    --tw-translate-x: 9rem !important;
  }

  .\32xl\:translate-x-40{
    --tw-translate-x: 10rem !important;
  }

  .\32xl\:translate-x-44{
    --tw-translate-x: 11rem !important;
  }

  .\32xl\:translate-x-48{
    --tw-translate-x: 12rem !important;
  }

  .\32xl\:translate-x-52{
    --tw-translate-x: 13rem !important;
  }

  .\32xl\:translate-x-56{
    --tw-translate-x: 14rem !important;
  }

  .\32xl\:translate-x-60{
    --tw-translate-x: 15rem !important;
  }

  .\32xl\:translate-x-64{
    --tw-translate-x: 16rem !important;
  }

  .\32xl\:translate-x-72{
    --tw-translate-x: 18rem !important;
  }

  .\32xl\:translate-x-80{
    --tw-translate-x: 20rem !important;
  }

  .\32xl\:translate-x-96{
    --tw-translate-x: 24rem !important;
  }

  .\32xl\:translate-x-px{
    --tw-translate-x: 1px !important;
  }

  .\32xl\:translate-x-0\.5{
    --tw-translate-x: 0.125rem !important;
  }

  .\32xl\:translate-x-1\.5{
    --tw-translate-x: 0.375rem !important;
  }

  .\32xl\:translate-x-2\.5{
    --tw-translate-x: 0.625rem !important;
  }

  .\32xl\:translate-x-3\.5{
    --tw-translate-x: 0.875rem !important;
  }

  .\32xl\:-translate-x-0{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:-translate-x-1{
    --tw-translate-x: -0.25rem !important;
  }

  .\32xl\:-translate-x-2{
    --tw-translate-x: -0.5rem !important;
  }

  .\32xl\:-translate-x-3{
    --tw-translate-x: -0.75rem !important;
  }

  .\32xl\:-translate-x-4{
    --tw-translate-x: -1rem !important;
  }

  .\32xl\:-translate-x-5{
    --tw-translate-x: -1.25rem !important;
  }

  .\32xl\:-translate-x-6{
    --tw-translate-x: -1.5rem !important;
  }

  .\32xl\:-translate-x-7{
    --tw-translate-x: -1.75rem !important;
  }

  .\32xl\:-translate-x-8{
    --tw-translate-x: -2rem !important;
  }

  .\32xl\:-translate-x-9{
    --tw-translate-x: -2.25rem !important;
  }

  .\32xl\:-translate-x-10{
    --tw-translate-x: -2.5rem !important;
  }

  .\32xl\:-translate-x-11{
    --tw-translate-x: -2.75rem !important;
  }

  .\32xl\:-translate-x-12{
    --tw-translate-x: -3rem !important;
  }

  .\32xl\:-translate-x-14{
    --tw-translate-x: -3.5rem !important;
  }

  .\32xl\:-translate-x-16{
    --tw-translate-x: -4rem !important;
  }

  .\32xl\:-translate-x-20{
    --tw-translate-x: -5rem !important;
  }

  .\32xl\:-translate-x-24{
    --tw-translate-x: -6rem !important;
  }

  .\32xl\:-translate-x-28{
    --tw-translate-x: -7rem !important;
  }

  .\32xl\:-translate-x-32{
    --tw-translate-x: -8rem !important;
  }

  .\32xl\:-translate-x-36{
    --tw-translate-x: -9rem !important;
  }

  .\32xl\:-translate-x-40{
    --tw-translate-x: -10rem !important;
  }

  .\32xl\:-translate-x-44{
    --tw-translate-x: -11rem !important;
  }

  .\32xl\:-translate-x-48{
    --tw-translate-x: -12rem !important;
  }

  .\32xl\:-translate-x-52{
    --tw-translate-x: -13rem !important;
  }

  .\32xl\:-translate-x-56{
    --tw-translate-x: -14rem !important;
  }

  .\32xl\:-translate-x-60{
    --tw-translate-x: -15rem !important;
  }

  .\32xl\:-translate-x-64{
    --tw-translate-x: -16rem !important;
  }

  .\32xl\:-translate-x-72{
    --tw-translate-x: -18rem !important;
  }

  .\32xl\:-translate-x-80{
    --tw-translate-x: -20rem !important;
  }

  .\32xl\:-translate-x-96{
    --tw-translate-x: -24rem !important;
  }

  .\32xl\:-translate-x-px{
    --tw-translate-x: -1px !important;
  }

  .\32xl\:-translate-x-0\.5{
    --tw-translate-x: -0.125rem !important;
  }

  .\32xl\:-translate-x-1\.5{
    --tw-translate-x: -0.375rem !important;
  }

  .\32xl\:-translate-x-2\.5{
    --tw-translate-x: -0.625rem !important;
  }

  .\32xl\:-translate-x-3\.5{
    --tw-translate-x: -0.875rem !important;
  }

  .\32xl\:translate-x-1\/2{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:translate-x-1\/3{
    --tw-translate-x: 33.333333% !important;
  }

  .\32xl\:translate-x-2\/3{
    --tw-translate-x: 66.666667% !important;
  }

  .\32xl\:translate-x-1\/4{
    --tw-translate-x: 25% !important;
  }

  .\32xl\:translate-x-2\/4{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:translate-x-3\/4{
    --tw-translate-x: 75% !important;
  }

  .\32xl\:translate-x-full{
    --tw-translate-x: 100% !important;
  }

  .\32xl\:-translate-x-1\/2{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:-translate-x-1\/3{
    --tw-translate-x: -33.333333% !important;
  }

  .\32xl\:-translate-x-2\/3{
    --tw-translate-x: -66.666667% !important;
  }

  .\32xl\:-translate-x-1\/4{
    --tw-translate-x: -25% !important;
  }

  .\32xl\:-translate-x-2\/4{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:-translate-x-3\/4{
    --tw-translate-x: -75% !important;
  }

  .\32xl\:-translate-x-full{
    --tw-translate-x: -100% !important;
  }

  .\32xl\:translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:translate-y-1{
    --tw-translate-y: 0.25rem !important;
  }

  .\32xl\:translate-y-2{
    --tw-translate-y: 0.5rem !important;
  }

  .\32xl\:translate-y-3{
    --tw-translate-y: 0.75rem !important;
  }

  .\32xl\:translate-y-4{
    --tw-translate-y: 1rem !important;
  }

  .\32xl\:translate-y-5{
    --tw-translate-y: 1.25rem !important;
  }

  .\32xl\:translate-y-6{
    --tw-translate-y: 1.5rem !important;
  }

  .\32xl\:translate-y-7{
    --tw-translate-y: 1.75rem !important;
  }

  .\32xl\:translate-y-8{
    --tw-translate-y: 2rem !important;
  }

  .\32xl\:translate-y-9{
    --tw-translate-y: 2.25rem !important;
  }

  .\32xl\:translate-y-10{
    --tw-translate-y: 2.5rem !important;
  }

  .\32xl\:translate-y-11{
    --tw-translate-y: 2.75rem !important;
  }

  .\32xl\:translate-y-12{
    --tw-translate-y: 3rem !important;
  }

  .\32xl\:translate-y-14{
    --tw-translate-y: 3.5rem !important;
  }

  .\32xl\:translate-y-16{
    --tw-translate-y: 4rem !important;
  }

  .\32xl\:translate-y-20{
    --tw-translate-y: 5rem !important;
  }

  .\32xl\:translate-y-24{
    --tw-translate-y: 6rem !important;
  }

  .\32xl\:translate-y-28{
    --tw-translate-y: 7rem !important;
  }

  .\32xl\:translate-y-32{
    --tw-translate-y: 8rem !important;
  }

  .\32xl\:translate-y-36{
    --tw-translate-y: 9rem !important;
  }

  .\32xl\:translate-y-40{
    --tw-translate-y: 10rem !important;
  }

  .\32xl\:translate-y-44{
    --tw-translate-y: 11rem !important;
  }

  .\32xl\:translate-y-48{
    --tw-translate-y: 12rem !important;
  }

  .\32xl\:translate-y-52{
    --tw-translate-y: 13rem !important;
  }

  .\32xl\:translate-y-56{
    --tw-translate-y: 14rem !important;
  }

  .\32xl\:translate-y-60{
    --tw-translate-y: 15rem !important;
  }

  .\32xl\:translate-y-64{
    --tw-translate-y: 16rem !important;
  }

  .\32xl\:translate-y-72{
    --tw-translate-y: 18rem !important;
  }

  .\32xl\:translate-y-80{
    --tw-translate-y: 20rem !important;
  }

  .\32xl\:translate-y-96{
    --tw-translate-y: 24rem !important;
  }

  .\32xl\:translate-y-px{
    --tw-translate-y: 1px !important;
  }

  .\32xl\:translate-y-0\.5{
    --tw-translate-y: 0.125rem !important;
  }

  .\32xl\:translate-y-1\.5{
    --tw-translate-y: 0.375rem !important;
  }

  .\32xl\:translate-y-2\.5{
    --tw-translate-y: 0.625rem !important;
  }

  .\32xl\:translate-y-3\.5{
    --tw-translate-y: 0.875rem !important;
  }

  .\32xl\:-translate-y-0{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:-translate-y-1{
    --tw-translate-y: -0.25rem !important;
  }

  .\32xl\:-translate-y-2{
    --tw-translate-y: -0.5rem !important;
  }

  .\32xl\:-translate-y-3{
    --tw-translate-y: -0.75rem !important;
  }

  .\32xl\:-translate-y-4{
    --tw-translate-y: -1rem !important;
  }

  .\32xl\:-translate-y-5{
    --tw-translate-y: -1.25rem !important;
  }

  .\32xl\:-translate-y-6{
    --tw-translate-y: -1.5rem !important;
  }

  .\32xl\:-translate-y-7{
    --tw-translate-y: -1.75rem !important;
  }

  .\32xl\:-translate-y-8{
    --tw-translate-y: -2rem !important;
  }

  .\32xl\:-translate-y-9{
    --tw-translate-y: -2.25rem !important;
  }

  .\32xl\:-translate-y-10{
    --tw-translate-y: -2.5rem !important;
  }

  .\32xl\:-translate-y-11{
    --tw-translate-y: -2.75rem !important;
  }

  .\32xl\:-translate-y-12{
    --tw-translate-y: -3rem !important;
  }

  .\32xl\:-translate-y-14{
    --tw-translate-y: -3.5rem !important;
  }

  .\32xl\:-translate-y-16{
    --tw-translate-y: -4rem !important;
  }

  .\32xl\:-translate-y-20{
    --tw-translate-y: -5rem !important;
  }

  .\32xl\:-translate-y-24{
    --tw-translate-y: -6rem !important;
  }

  .\32xl\:-translate-y-28{
    --tw-translate-y: -7rem !important;
  }

  .\32xl\:-translate-y-32{
    --tw-translate-y: -8rem !important;
  }

  .\32xl\:-translate-y-36{
    --tw-translate-y: -9rem !important;
  }

  .\32xl\:-translate-y-40{
    --tw-translate-y: -10rem !important;
  }

  .\32xl\:-translate-y-44{
    --tw-translate-y: -11rem !important;
  }

  .\32xl\:-translate-y-48{
    --tw-translate-y: -12rem !important;
  }

  .\32xl\:-translate-y-52{
    --tw-translate-y: -13rem !important;
  }

  .\32xl\:-translate-y-56{
    --tw-translate-y: -14rem !important;
  }

  .\32xl\:-translate-y-60{
    --tw-translate-y: -15rem !important;
  }

  .\32xl\:-translate-y-64{
    --tw-translate-y: -16rem !important;
  }

  .\32xl\:-translate-y-72{
    --tw-translate-y: -18rem !important;
  }

  .\32xl\:-translate-y-80{
    --tw-translate-y: -20rem !important;
  }

  .\32xl\:-translate-y-96{
    --tw-translate-y: -24rem !important;
  }

  .\32xl\:-translate-y-px{
    --tw-translate-y: -1px !important;
  }

  .\32xl\:-translate-y-0\.5{
    --tw-translate-y: -0.125rem !important;
  }

  .\32xl\:-translate-y-1\.5{
    --tw-translate-y: -0.375rem !important;
  }

  .\32xl\:-translate-y-2\.5{
    --tw-translate-y: -0.625rem !important;
  }

  .\32xl\:-translate-y-3\.5{
    --tw-translate-y: -0.875rem !important;
  }

  .\32xl\:translate-y-1\/2{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:translate-y-1\/3{
    --tw-translate-y: 33.333333% !important;
  }

  .\32xl\:translate-y-2\/3{
    --tw-translate-y: 66.666667% !important;
  }

  .\32xl\:translate-y-1\/4{
    --tw-translate-y: 25% !important;
  }

  .\32xl\:translate-y-2\/4{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:translate-y-3\/4{
    --tw-translate-y: 75% !important;
  }

  .\32xl\:translate-y-full{
    --tw-translate-y: 100% !important;
  }

  .\32xl\:-translate-y-1\/2{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:-translate-y-1\/3{
    --tw-translate-y: -33.333333% !important;
  }

  .\32xl\:-translate-y-2\/3{
    --tw-translate-y: -66.666667% !important;
  }

  .\32xl\:-translate-y-1\/4{
    --tw-translate-y: -25% !important;
  }

  .\32xl\:-translate-y-2\/4{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:-translate-y-3\/4{
    --tw-translate-y: -75% !important;
  }

  .\32xl\:-translate-y-full{
    --tw-translate-y: -100% !important;
  }

  .\32xl\:hover\:translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:hover\:translate-x-1:hover{
    --tw-translate-x: 0.25rem !important;
  }

  .\32xl\:hover\:translate-x-2:hover{
    --tw-translate-x: 0.5rem !important;
  }

  .\32xl\:hover\:translate-x-3:hover{
    --tw-translate-x: 0.75rem !important;
  }

  .\32xl\:hover\:translate-x-4:hover{
    --tw-translate-x: 1rem !important;
  }

  .\32xl\:hover\:translate-x-5:hover{
    --tw-translate-x: 1.25rem !important;
  }

  .\32xl\:hover\:translate-x-6:hover{
    --tw-translate-x: 1.5rem !important;
  }

  .\32xl\:hover\:translate-x-7:hover{
    --tw-translate-x: 1.75rem !important;
  }

  .\32xl\:hover\:translate-x-8:hover{
    --tw-translate-x: 2rem !important;
  }

  .\32xl\:hover\:translate-x-9:hover{
    --tw-translate-x: 2.25rem !important;
  }

  .\32xl\:hover\:translate-x-10:hover{
    --tw-translate-x: 2.5rem !important;
  }

  .\32xl\:hover\:translate-x-11:hover{
    --tw-translate-x: 2.75rem !important;
  }

  .\32xl\:hover\:translate-x-12:hover{
    --tw-translate-x: 3rem !important;
  }

  .\32xl\:hover\:translate-x-14:hover{
    --tw-translate-x: 3.5rem !important;
  }

  .\32xl\:hover\:translate-x-16:hover{
    --tw-translate-x: 4rem !important;
  }

  .\32xl\:hover\:translate-x-20:hover{
    --tw-translate-x: 5rem !important;
  }

  .\32xl\:hover\:translate-x-24:hover{
    --tw-translate-x: 6rem !important;
  }

  .\32xl\:hover\:translate-x-28:hover{
    --tw-translate-x: 7rem !important;
  }

  .\32xl\:hover\:translate-x-32:hover{
    --tw-translate-x: 8rem !important;
  }

  .\32xl\:hover\:translate-x-36:hover{
    --tw-translate-x: 9rem !important;
  }

  .\32xl\:hover\:translate-x-40:hover{
    --tw-translate-x: 10rem !important;
  }

  .\32xl\:hover\:translate-x-44:hover{
    --tw-translate-x: 11rem !important;
  }

  .\32xl\:hover\:translate-x-48:hover{
    --tw-translate-x: 12rem !important;
  }

  .\32xl\:hover\:translate-x-52:hover{
    --tw-translate-x: 13rem !important;
  }

  .\32xl\:hover\:translate-x-56:hover{
    --tw-translate-x: 14rem !important;
  }

  .\32xl\:hover\:translate-x-60:hover{
    --tw-translate-x: 15rem !important;
  }

  .\32xl\:hover\:translate-x-64:hover{
    --tw-translate-x: 16rem !important;
  }

  .\32xl\:hover\:translate-x-72:hover{
    --tw-translate-x: 18rem !important;
  }

  .\32xl\:hover\:translate-x-80:hover{
    --tw-translate-x: 20rem !important;
  }

  .\32xl\:hover\:translate-x-96:hover{
    --tw-translate-x: 24rem !important;
  }

  .\32xl\:hover\:translate-x-px:hover{
    --tw-translate-x: 1px !important;
  }

  .\32xl\:hover\:translate-x-0\.5:hover{
    --tw-translate-x: 0.125rem !important;
  }

  .\32xl\:hover\:translate-x-1\.5:hover{
    --tw-translate-x: 0.375rem !important;
  }

  .\32xl\:hover\:translate-x-2\.5:hover{
    --tw-translate-x: 0.625rem !important;
  }

  .\32xl\:hover\:translate-x-3\.5:hover{
    --tw-translate-x: 0.875rem !important;
  }

  .\32xl\:hover\:-translate-x-0:hover{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:hover\:-translate-x-1:hover{
    --tw-translate-x: -0.25rem !important;
  }

  .\32xl\:hover\:-translate-x-2:hover{
    --tw-translate-x: -0.5rem !important;
  }

  .\32xl\:hover\:-translate-x-3:hover{
    --tw-translate-x: -0.75rem !important;
  }

  .\32xl\:hover\:-translate-x-4:hover{
    --tw-translate-x: -1rem !important;
  }

  .\32xl\:hover\:-translate-x-5:hover{
    --tw-translate-x: -1.25rem !important;
  }

  .\32xl\:hover\:-translate-x-6:hover{
    --tw-translate-x: -1.5rem !important;
  }

  .\32xl\:hover\:-translate-x-7:hover{
    --tw-translate-x: -1.75rem !important;
  }

  .\32xl\:hover\:-translate-x-8:hover{
    --tw-translate-x: -2rem !important;
  }

  .\32xl\:hover\:-translate-x-9:hover{
    --tw-translate-x: -2.25rem !important;
  }

  .\32xl\:hover\:-translate-x-10:hover{
    --tw-translate-x: -2.5rem !important;
  }

  .\32xl\:hover\:-translate-x-11:hover{
    --tw-translate-x: -2.75rem !important;
  }

  .\32xl\:hover\:-translate-x-12:hover{
    --tw-translate-x: -3rem !important;
  }

  .\32xl\:hover\:-translate-x-14:hover{
    --tw-translate-x: -3.5rem !important;
  }

  .\32xl\:hover\:-translate-x-16:hover{
    --tw-translate-x: -4rem !important;
  }

  .\32xl\:hover\:-translate-x-20:hover{
    --tw-translate-x: -5rem !important;
  }

  .\32xl\:hover\:-translate-x-24:hover{
    --tw-translate-x: -6rem !important;
  }

  .\32xl\:hover\:-translate-x-28:hover{
    --tw-translate-x: -7rem !important;
  }

  .\32xl\:hover\:-translate-x-32:hover{
    --tw-translate-x: -8rem !important;
  }

  .\32xl\:hover\:-translate-x-36:hover{
    --tw-translate-x: -9rem !important;
  }

  .\32xl\:hover\:-translate-x-40:hover{
    --tw-translate-x: -10rem !important;
  }

  .\32xl\:hover\:-translate-x-44:hover{
    --tw-translate-x: -11rem !important;
  }

  .\32xl\:hover\:-translate-x-48:hover{
    --tw-translate-x: -12rem !important;
  }

  .\32xl\:hover\:-translate-x-52:hover{
    --tw-translate-x: -13rem !important;
  }

  .\32xl\:hover\:-translate-x-56:hover{
    --tw-translate-x: -14rem !important;
  }

  .\32xl\:hover\:-translate-x-60:hover{
    --tw-translate-x: -15rem !important;
  }

  .\32xl\:hover\:-translate-x-64:hover{
    --tw-translate-x: -16rem !important;
  }

  .\32xl\:hover\:-translate-x-72:hover{
    --tw-translate-x: -18rem !important;
  }

  .\32xl\:hover\:-translate-x-80:hover{
    --tw-translate-x: -20rem !important;
  }

  .\32xl\:hover\:-translate-x-96:hover{
    --tw-translate-x: -24rem !important;
  }

  .\32xl\:hover\:-translate-x-px:hover{
    --tw-translate-x: -1px !important;
  }

  .\32xl\:hover\:-translate-x-0\.5:hover{
    --tw-translate-x: -0.125rem !important;
  }

  .\32xl\:hover\:-translate-x-1\.5:hover{
    --tw-translate-x: -0.375rem !important;
  }

  .\32xl\:hover\:-translate-x-2\.5:hover{
    --tw-translate-x: -0.625rem !important;
  }

  .\32xl\:hover\:-translate-x-3\.5:hover{
    --tw-translate-x: -0.875rem !important;
  }

  .\32xl\:hover\:translate-x-1\/2:hover{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:hover\:translate-x-1\/3:hover{
    --tw-translate-x: 33.333333% !important;
  }

  .\32xl\:hover\:translate-x-2\/3:hover{
    --tw-translate-x: 66.666667% !important;
  }

  .\32xl\:hover\:translate-x-1\/4:hover{
    --tw-translate-x: 25% !important;
  }

  .\32xl\:hover\:translate-x-2\/4:hover{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:hover\:translate-x-3\/4:hover{
    --tw-translate-x: 75% !important;
  }

  .\32xl\:hover\:translate-x-full:hover{
    --tw-translate-x: 100% !important;
  }

  .\32xl\:hover\:-translate-x-1\/2:hover{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:hover\:-translate-x-1\/3:hover{
    --tw-translate-x: -33.333333% !important;
  }

  .\32xl\:hover\:-translate-x-2\/3:hover{
    --tw-translate-x: -66.666667% !important;
  }

  .\32xl\:hover\:-translate-x-1\/4:hover{
    --tw-translate-x: -25% !important;
  }

  .\32xl\:hover\:-translate-x-2\/4:hover{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:hover\:-translate-x-3\/4:hover{
    --tw-translate-x: -75% !important;
  }

  .\32xl\:hover\:-translate-x-full:hover{
    --tw-translate-x: -100% !important;
  }

  .\32xl\:hover\:translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:hover\:translate-y-1:hover{
    --tw-translate-y: 0.25rem !important;
  }

  .\32xl\:hover\:translate-y-2:hover{
    --tw-translate-y: 0.5rem !important;
  }

  .\32xl\:hover\:translate-y-3:hover{
    --tw-translate-y: 0.75rem !important;
  }

  .\32xl\:hover\:translate-y-4:hover{
    --tw-translate-y: 1rem !important;
  }

  .\32xl\:hover\:translate-y-5:hover{
    --tw-translate-y: 1.25rem !important;
  }

  .\32xl\:hover\:translate-y-6:hover{
    --tw-translate-y: 1.5rem !important;
  }

  .\32xl\:hover\:translate-y-7:hover{
    --tw-translate-y: 1.75rem !important;
  }

  .\32xl\:hover\:translate-y-8:hover{
    --tw-translate-y: 2rem !important;
  }

  .\32xl\:hover\:translate-y-9:hover{
    --tw-translate-y: 2.25rem !important;
  }

  .\32xl\:hover\:translate-y-10:hover{
    --tw-translate-y: 2.5rem !important;
  }

  .\32xl\:hover\:translate-y-11:hover{
    --tw-translate-y: 2.75rem !important;
  }

  .\32xl\:hover\:translate-y-12:hover{
    --tw-translate-y: 3rem !important;
  }

  .\32xl\:hover\:translate-y-14:hover{
    --tw-translate-y: 3.5rem !important;
  }

  .\32xl\:hover\:translate-y-16:hover{
    --tw-translate-y: 4rem !important;
  }

  .\32xl\:hover\:translate-y-20:hover{
    --tw-translate-y: 5rem !important;
  }

  .\32xl\:hover\:translate-y-24:hover{
    --tw-translate-y: 6rem !important;
  }

  .\32xl\:hover\:translate-y-28:hover{
    --tw-translate-y: 7rem !important;
  }

  .\32xl\:hover\:translate-y-32:hover{
    --tw-translate-y: 8rem !important;
  }

  .\32xl\:hover\:translate-y-36:hover{
    --tw-translate-y: 9rem !important;
  }

  .\32xl\:hover\:translate-y-40:hover{
    --tw-translate-y: 10rem !important;
  }

  .\32xl\:hover\:translate-y-44:hover{
    --tw-translate-y: 11rem !important;
  }

  .\32xl\:hover\:translate-y-48:hover{
    --tw-translate-y: 12rem !important;
  }

  .\32xl\:hover\:translate-y-52:hover{
    --tw-translate-y: 13rem !important;
  }

  .\32xl\:hover\:translate-y-56:hover{
    --tw-translate-y: 14rem !important;
  }

  .\32xl\:hover\:translate-y-60:hover{
    --tw-translate-y: 15rem !important;
  }

  .\32xl\:hover\:translate-y-64:hover{
    --tw-translate-y: 16rem !important;
  }

  .\32xl\:hover\:translate-y-72:hover{
    --tw-translate-y: 18rem !important;
  }

  .\32xl\:hover\:translate-y-80:hover{
    --tw-translate-y: 20rem !important;
  }

  .\32xl\:hover\:translate-y-96:hover{
    --tw-translate-y: 24rem !important;
  }

  .\32xl\:hover\:translate-y-px:hover{
    --tw-translate-y: 1px !important;
  }

  .\32xl\:hover\:translate-y-0\.5:hover{
    --tw-translate-y: 0.125rem !important;
  }

  .\32xl\:hover\:translate-y-1\.5:hover{
    --tw-translate-y: 0.375rem !important;
  }

  .\32xl\:hover\:translate-y-2\.5:hover{
    --tw-translate-y: 0.625rem !important;
  }

  .\32xl\:hover\:translate-y-3\.5:hover{
    --tw-translate-y: 0.875rem !important;
  }

  .\32xl\:hover\:-translate-y-0:hover{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:hover\:-translate-y-1:hover{
    --tw-translate-y: -0.25rem !important;
  }

  .\32xl\:hover\:-translate-y-2:hover{
    --tw-translate-y: -0.5rem !important;
  }

  .\32xl\:hover\:-translate-y-3:hover{
    --tw-translate-y: -0.75rem !important;
  }

  .\32xl\:hover\:-translate-y-4:hover{
    --tw-translate-y: -1rem !important;
  }

  .\32xl\:hover\:-translate-y-5:hover{
    --tw-translate-y: -1.25rem !important;
  }

  .\32xl\:hover\:-translate-y-6:hover{
    --tw-translate-y: -1.5rem !important;
  }

  .\32xl\:hover\:-translate-y-7:hover{
    --tw-translate-y: -1.75rem !important;
  }

  .\32xl\:hover\:-translate-y-8:hover{
    --tw-translate-y: -2rem !important;
  }

  .\32xl\:hover\:-translate-y-9:hover{
    --tw-translate-y: -2.25rem !important;
  }

  .\32xl\:hover\:-translate-y-10:hover{
    --tw-translate-y: -2.5rem !important;
  }

  .\32xl\:hover\:-translate-y-11:hover{
    --tw-translate-y: -2.75rem !important;
  }

  .\32xl\:hover\:-translate-y-12:hover{
    --tw-translate-y: -3rem !important;
  }

  .\32xl\:hover\:-translate-y-14:hover{
    --tw-translate-y: -3.5rem !important;
  }

  .\32xl\:hover\:-translate-y-16:hover{
    --tw-translate-y: -4rem !important;
  }

  .\32xl\:hover\:-translate-y-20:hover{
    --tw-translate-y: -5rem !important;
  }

  .\32xl\:hover\:-translate-y-24:hover{
    --tw-translate-y: -6rem !important;
  }

  .\32xl\:hover\:-translate-y-28:hover{
    --tw-translate-y: -7rem !important;
  }

  .\32xl\:hover\:-translate-y-32:hover{
    --tw-translate-y: -8rem !important;
  }

  .\32xl\:hover\:-translate-y-36:hover{
    --tw-translate-y: -9rem !important;
  }

  .\32xl\:hover\:-translate-y-40:hover{
    --tw-translate-y: -10rem !important;
  }

  .\32xl\:hover\:-translate-y-44:hover{
    --tw-translate-y: -11rem !important;
  }

  .\32xl\:hover\:-translate-y-48:hover{
    --tw-translate-y: -12rem !important;
  }

  .\32xl\:hover\:-translate-y-52:hover{
    --tw-translate-y: -13rem !important;
  }

  .\32xl\:hover\:-translate-y-56:hover{
    --tw-translate-y: -14rem !important;
  }

  .\32xl\:hover\:-translate-y-60:hover{
    --tw-translate-y: -15rem !important;
  }

  .\32xl\:hover\:-translate-y-64:hover{
    --tw-translate-y: -16rem !important;
  }

  .\32xl\:hover\:-translate-y-72:hover{
    --tw-translate-y: -18rem !important;
  }

  .\32xl\:hover\:-translate-y-80:hover{
    --tw-translate-y: -20rem !important;
  }

  .\32xl\:hover\:-translate-y-96:hover{
    --tw-translate-y: -24rem !important;
  }

  .\32xl\:hover\:-translate-y-px:hover{
    --tw-translate-y: -1px !important;
  }

  .\32xl\:hover\:-translate-y-0\.5:hover{
    --tw-translate-y: -0.125rem !important;
  }

  .\32xl\:hover\:-translate-y-1\.5:hover{
    --tw-translate-y: -0.375rem !important;
  }

  .\32xl\:hover\:-translate-y-2\.5:hover{
    --tw-translate-y: -0.625rem !important;
  }

  .\32xl\:hover\:-translate-y-3\.5:hover{
    --tw-translate-y: -0.875rem !important;
  }

  .\32xl\:hover\:translate-y-1\/2:hover{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:hover\:translate-y-1\/3:hover{
    --tw-translate-y: 33.333333% !important;
  }

  .\32xl\:hover\:translate-y-2\/3:hover{
    --tw-translate-y: 66.666667% !important;
  }

  .\32xl\:hover\:translate-y-1\/4:hover{
    --tw-translate-y: 25% !important;
  }

  .\32xl\:hover\:translate-y-2\/4:hover{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:hover\:translate-y-3\/4:hover{
    --tw-translate-y: 75% !important;
  }

  .\32xl\:hover\:translate-y-full:hover{
    --tw-translate-y: 100% !important;
  }

  .\32xl\:hover\:-translate-y-1\/2:hover{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:hover\:-translate-y-1\/3:hover{
    --tw-translate-y: -33.333333% !important;
  }

  .\32xl\:hover\:-translate-y-2\/3:hover{
    --tw-translate-y: -66.666667% !important;
  }

  .\32xl\:hover\:-translate-y-1\/4:hover{
    --tw-translate-y: -25% !important;
  }

  .\32xl\:hover\:-translate-y-2\/4:hover{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:hover\:-translate-y-3\/4:hover{
    --tw-translate-y: -75% !important;
  }

  .\32xl\:hover\:-translate-y-full:hover{
    --tw-translate-y: -100% !important;
  }

  .\32xl\:focus\:translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:focus\:translate-x-1:focus{
    --tw-translate-x: 0.25rem !important;
  }

  .\32xl\:focus\:translate-x-2:focus{
    --tw-translate-x: 0.5rem !important;
  }

  .\32xl\:focus\:translate-x-3:focus{
    --tw-translate-x: 0.75rem !important;
  }

  .\32xl\:focus\:translate-x-4:focus{
    --tw-translate-x: 1rem !important;
  }

  .\32xl\:focus\:translate-x-5:focus{
    --tw-translate-x: 1.25rem !important;
  }

  .\32xl\:focus\:translate-x-6:focus{
    --tw-translate-x: 1.5rem !important;
  }

  .\32xl\:focus\:translate-x-7:focus{
    --tw-translate-x: 1.75rem !important;
  }

  .\32xl\:focus\:translate-x-8:focus{
    --tw-translate-x: 2rem !important;
  }

  .\32xl\:focus\:translate-x-9:focus{
    --tw-translate-x: 2.25rem !important;
  }

  .\32xl\:focus\:translate-x-10:focus{
    --tw-translate-x: 2.5rem !important;
  }

  .\32xl\:focus\:translate-x-11:focus{
    --tw-translate-x: 2.75rem !important;
  }

  .\32xl\:focus\:translate-x-12:focus{
    --tw-translate-x: 3rem !important;
  }

  .\32xl\:focus\:translate-x-14:focus{
    --tw-translate-x: 3.5rem !important;
  }

  .\32xl\:focus\:translate-x-16:focus{
    --tw-translate-x: 4rem !important;
  }

  .\32xl\:focus\:translate-x-20:focus{
    --tw-translate-x: 5rem !important;
  }

  .\32xl\:focus\:translate-x-24:focus{
    --tw-translate-x: 6rem !important;
  }

  .\32xl\:focus\:translate-x-28:focus{
    --tw-translate-x: 7rem !important;
  }

  .\32xl\:focus\:translate-x-32:focus{
    --tw-translate-x: 8rem !important;
  }

  .\32xl\:focus\:translate-x-36:focus{
    --tw-translate-x: 9rem !important;
  }

  .\32xl\:focus\:translate-x-40:focus{
    --tw-translate-x: 10rem !important;
  }

  .\32xl\:focus\:translate-x-44:focus{
    --tw-translate-x: 11rem !important;
  }

  .\32xl\:focus\:translate-x-48:focus{
    --tw-translate-x: 12rem !important;
  }

  .\32xl\:focus\:translate-x-52:focus{
    --tw-translate-x: 13rem !important;
  }

  .\32xl\:focus\:translate-x-56:focus{
    --tw-translate-x: 14rem !important;
  }

  .\32xl\:focus\:translate-x-60:focus{
    --tw-translate-x: 15rem !important;
  }

  .\32xl\:focus\:translate-x-64:focus{
    --tw-translate-x: 16rem !important;
  }

  .\32xl\:focus\:translate-x-72:focus{
    --tw-translate-x: 18rem !important;
  }

  .\32xl\:focus\:translate-x-80:focus{
    --tw-translate-x: 20rem !important;
  }

  .\32xl\:focus\:translate-x-96:focus{
    --tw-translate-x: 24rem !important;
  }

  .\32xl\:focus\:translate-x-px:focus{
    --tw-translate-x: 1px !important;
  }

  .\32xl\:focus\:translate-x-0\.5:focus{
    --tw-translate-x: 0.125rem !important;
  }

  .\32xl\:focus\:translate-x-1\.5:focus{
    --tw-translate-x: 0.375rem !important;
  }

  .\32xl\:focus\:translate-x-2\.5:focus{
    --tw-translate-x: 0.625rem !important;
  }

  .\32xl\:focus\:translate-x-3\.5:focus{
    --tw-translate-x: 0.875rem !important;
  }

  .\32xl\:focus\:-translate-x-0:focus{
    --tw-translate-x: 0px !important;
  }

  .\32xl\:focus\:-translate-x-1:focus{
    --tw-translate-x: -0.25rem !important;
  }

  .\32xl\:focus\:-translate-x-2:focus{
    --tw-translate-x: -0.5rem !important;
  }

  .\32xl\:focus\:-translate-x-3:focus{
    --tw-translate-x: -0.75rem !important;
  }

  .\32xl\:focus\:-translate-x-4:focus{
    --tw-translate-x: -1rem !important;
  }

  .\32xl\:focus\:-translate-x-5:focus{
    --tw-translate-x: -1.25rem !important;
  }

  .\32xl\:focus\:-translate-x-6:focus{
    --tw-translate-x: -1.5rem !important;
  }

  .\32xl\:focus\:-translate-x-7:focus{
    --tw-translate-x: -1.75rem !important;
  }

  .\32xl\:focus\:-translate-x-8:focus{
    --tw-translate-x: -2rem !important;
  }

  .\32xl\:focus\:-translate-x-9:focus{
    --tw-translate-x: -2.25rem !important;
  }

  .\32xl\:focus\:-translate-x-10:focus{
    --tw-translate-x: -2.5rem !important;
  }

  .\32xl\:focus\:-translate-x-11:focus{
    --tw-translate-x: -2.75rem !important;
  }

  .\32xl\:focus\:-translate-x-12:focus{
    --tw-translate-x: -3rem !important;
  }

  .\32xl\:focus\:-translate-x-14:focus{
    --tw-translate-x: -3.5rem !important;
  }

  .\32xl\:focus\:-translate-x-16:focus{
    --tw-translate-x: -4rem !important;
  }

  .\32xl\:focus\:-translate-x-20:focus{
    --tw-translate-x: -5rem !important;
  }

  .\32xl\:focus\:-translate-x-24:focus{
    --tw-translate-x: -6rem !important;
  }

  .\32xl\:focus\:-translate-x-28:focus{
    --tw-translate-x: -7rem !important;
  }

  .\32xl\:focus\:-translate-x-32:focus{
    --tw-translate-x: -8rem !important;
  }

  .\32xl\:focus\:-translate-x-36:focus{
    --tw-translate-x: -9rem !important;
  }

  .\32xl\:focus\:-translate-x-40:focus{
    --tw-translate-x: -10rem !important;
  }

  .\32xl\:focus\:-translate-x-44:focus{
    --tw-translate-x: -11rem !important;
  }

  .\32xl\:focus\:-translate-x-48:focus{
    --tw-translate-x: -12rem !important;
  }

  .\32xl\:focus\:-translate-x-52:focus{
    --tw-translate-x: -13rem !important;
  }

  .\32xl\:focus\:-translate-x-56:focus{
    --tw-translate-x: -14rem !important;
  }

  .\32xl\:focus\:-translate-x-60:focus{
    --tw-translate-x: -15rem !important;
  }

  .\32xl\:focus\:-translate-x-64:focus{
    --tw-translate-x: -16rem !important;
  }

  .\32xl\:focus\:-translate-x-72:focus{
    --tw-translate-x: -18rem !important;
  }

  .\32xl\:focus\:-translate-x-80:focus{
    --tw-translate-x: -20rem !important;
  }

  .\32xl\:focus\:-translate-x-96:focus{
    --tw-translate-x: -24rem !important;
  }

  .\32xl\:focus\:-translate-x-px:focus{
    --tw-translate-x: -1px !important;
  }

  .\32xl\:focus\:-translate-x-0\.5:focus{
    --tw-translate-x: -0.125rem !important;
  }

  .\32xl\:focus\:-translate-x-1\.5:focus{
    --tw-translate-x: -0.375rem !important;
  }

  .\32xl\:focus\:-translate-x-2\.5:focus{
    --tw-translate-x: -0.625rem !important;
  }

  .\32xl\:focus\:-translate-x-3\.5:focus{
    --tw-translate-x: -0.875rem !important;
  }

  .\32xl\:focus\:translate-x-1\/2:focus{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:focus\:translate-x-1\/3:focus{
    --tw-translate-x: 33.333333% !important;
  }

  .\32xl\:focus\:translate-x-2\/3:focus{
    --tw-translate-x: 66.666667% !important;
  }

  .\32xl\:focus\:translate-x-1\/4:focus{
    --tw-translate-x: 25% !important;
  }

  .\32xl\:focus\:translate-x-2\/4:focus{
    --tw-translate-x: 50% !important;
  }

  .\32xl\:focus\:translate-x-3\/4:focus{
    --tw-translate-x: 75% !important;
  }

  .\32xl\:focus\:translate-x-full:focus{
    --tw-translate-x: 100% !important;
  }

  .\32xl\:focus\:-translate-x-1\/2:focus{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:focus\:-translate-x-1\/3:focus{
    --tw-translate-x: -33.333333% !important;
  }

  .\32xl\:focus\:-translate-x-2\/3:focus{
    --tw-translate-x: -66.666667% !important;
  }

  .\32xl\:focus\:-translate-x-1\/4:focus{
    --tw-translate-x: -25% !important;
  }

  .\32xl\:focus\:-translate-x-2\/4:focus{
    --tw-translate-x: -50% !important;
  }

  .\32xl\:focus\:-translate-x-3\/4:focus{
    --tw-translate-x: -75% !important;
  }

  .\32xl\:focus\:-translate-x-full:focus{
    --tw-translate-x: -100% !important;
  }

  .\32xl\:focus\:translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:focus\:translate-y-1:focus{
    --tw-translate-y: 0.25rem !important;
  }

  .\32xl\:focus\:translate-y-2:focus{
    --tw-translate-y: 0.5rem !important;
  }

  .\32xl\:focus\:translate-y-3:focus{
    --tw-translate-y: 0.75rem !important;
  }

  .\32xl\:focus\:translate-y-4:focus{
    --tw-translate-y: 1rem !important;
  }

  .\32xl\:focus\:translate-y-5:focus{
    --tw-translate-y: 1.25rem !important;
  }

  .\32xl\:focus\:translate-y-6:focus{
    --tw-translate-y: 1.5rem !important;
  }

  .\32xl\:focus\:translate-y-7:focus{
    --tw-translate-y: 1.75rem !important;
  }

  .\32xl\:focus\:translate-y-8:focus{
    --tw-translate-y: 2rem !important;
  }

  .\32xl\:focus\:translate-y-9:focus{
    --tw-translate-y: 2.25rem !important;
  }

  .\32xl\:focus\:translate-y-10:focus{
    --tw-translate-y: 2.5rem !important;
  }

  .\32xl\:focus\:translate-y-11:focus{
    --tw-translate-y: 2.75rem !important;
  }

  .\32xl\:focus\:translate-y-12:focus{
    --tw-translate-y: 3rem !important;
  }

  .\32xl\:focus\:translate-y-14:focus{
    --tw-translate-y: 3.5rem !important;
  }

  .\32xl\:focus\:translate-y-16:focus{
    --tw-translate-y: 4rem !important;
  }

  .\32xl\:focus\:translate-y-20:focus{
    --tw-translate-y: 5rem !important;
  }

  .\32xl\:focus\:translate-y-24:focus{
    --tw-translate-y: 6rem !important;
  }

  .\32xl\:focus\:translate-y-28:focus{
    --tw-translate-y: 7rem !important;
  }

  .\32xl\:focus\:translate-y-32:focus{
    --tw-translate-y: 8rem !important;
  }

  .\32xl\:focus\:translate-y-36:focus{
    --tw-translate-y: 9rem !important;
  }

  .\32xl\:focus\:translate-y-40:focus{
    --tw-translate-y: 10rem !important;
  }

  .\32xl\:focus\:translate-y-44:focus{
    --tw-translate-y: 11rem !important;
  }

  .\32xl\:focus\:translate-y-48:focus{
    --tw-translate-y: 12rem !important;
  }

  .\32xl\:focus\:translate-y-52:focus{
    --tw-translate-y: 13rem !important;
  }

  .\32xl\:focus\:translate-y-56:focus{
    --tw-translate-y: 14rem !important;
  }

  .\32xl\:focus\:translate-y-60:focus{
    --tw-translate-y: 15rem !important;
  }

  .\32xl\:focus\:translate-y-64:focus{
    --tw-translate-y: 16rem !important;
  }

  .\32xl\:focus\:translate-y-72:focus{
    --tw-translate-y: 18rem !important;
  }

  .\32xl\:focus\:translate-y-80:focus{
    --tw-translate-y: 20rem !important;
  }

  .\32xl\:focus\:translate-y-96:focus{
    --tw-translate-y: 24rem !important;
  }

  .\32xl\:focus\:translate-y-px:focus{
    --tw-translate-y: 1px !important;
  }

  .\32xl\:focus\:translate-y-0\.5:focus{
    --tw-translate-y: 0.125rem !important;
  }

  .\32xl\:focus\:translate-y-1\.5:focus{
    --tw-translate-y: 0.375rem !important;
  }

  .\32xl\:focus\:translate-y-2\.5:focus{
    --tw-translate-y: 0.625rem !important;
  }

  .\32xl\:focus\:translate-y-3\.5:focus{
    --tw-translate-y: 0.875rem !important;
  }

  .\32xl\:focus\:-translate-y-0:focus{
    --tw-translate-y: 0px !important;
  }

  .\32xl\:focus\:-translate-y-1:focus{
    --tw-translate-y: -0.25rem !important;
  }

  .\32xl\:focus\:-translate-y-2:focus{
    --tw-translate-y: -0.5rem !important;
  }

  .\32xl\:focus\:-translate-y-3:focus{
    --tw-translate-y: -0.75rem !important;
  }

  .\32xl\:focus\:-translate-y-4:focus{
    --tw-translate-y: -1rem !important;
  }

  .\32xl\:focus\:-translate-y-5:focus{
    --tw-translate-y: -1.25rem !important;
  }

  .\32xl\:focus\:-translate-y-6:focus{
    --tw-translate-y: -1.5rem !important;
  }

  .\32xl\:focus\:-translate-y-7:focus{
    --tw-translate-y: -1.75rem !important;
  }

  .\32xl\:focus\:-translate-y-8:focus{
    --tw-translate-y: -2rem !important;
  }

  .\32xl\:focus\:-translate-y-9:focus{
    --tw-translate-y: -2.25rem !important;
  }

  .\32xl\:focus\:-translate-y-10:focus{
    --tw-translate-y: -2.5rem !important;
  }

  .\32xl\:focus\:-translate-y-11:focus{
    --tw-translate-y: -2.75rem !important;
  }

  .\32xl\:focus\:-translate-y-12:focus{
    --tw-translate-y: -3rem !important;
  }

  .\32xl\:focus\:-translate-y-14:focus{
    --tw-translate-y: -3.5rem !important;
  }

  .\32xl\:focus\:-translate-y-16:focus{
    --tw-translate-y: -4rem !important;
  }

  .\32xl\:focus\:-translate-y-20:focus{
    --tw-translate-y: -5rem !important;
  }

  .\32xl\:focus\:-translate-y-24:focus{
    --tw-translate-y: -6rem !important;
  }

  .\32xl\:focus\:-translate-y-28:focus{
    --tw-translate-y: -7rem !important;
  }

  .\32xl\:focus\:-translate-y-32:focus{
    --tw-translate-y: -8rem !important;
  }

  .\32xl\:focus\:-translate-y-36:focus{
    --tw-translate-y: -9rem !important;
  }

  .\32xl\:focus\:-translate-y-40:focus{
    --tw-translate-y: -10rem !important;
  }

  .\32xl\:focus\:-translate-y-44:focus{
    --tw-translate-y: -11rem !important;
  }

  .\32xl\:focus\:-translate-y-48:focus{
    --tw-translate-y: -12rem !important;
  }

  .\32xl\:focus\:-translate-y-52:focus{
    --tw-translate-y: -13rem !important;
  }

  .\32xl\:focus\:-translate-y-56:focus{
    --tw-translate-y: -14rem !important;
  }

  .\32xl\:focus\:-translate-y-60:focus{
    --tw-translate-y: -15rem !important;
  }

  .\32xl\:focus\:-translate-y-64:focus{
    --tw-translate-y: -16rem !important;
  }

  .\32xl\:focus\:-translate-y-72:focus{
    --tw-translate-y: -18rem !important;
  }

  .\32xl\:focus\:-translate-y-80:focus{
    --tw-translate-y: -20rem !important;
  }

  .\32xl\:focus\:-translate-y-96:focus{
    --tw-translate-y: -24rem !important;
  }

  .\32xl\:focus\:-translate-y-px:focus{
    --tw-translate-y: -1px !important;
  }

  .\32xl\:focus\:-translate-y-0\.5:focus{
    --tw-translate-y: -0.125rem !important;
  }

  .\32xl\:focus\:-translate-y-1\.5:focus{
    --tw-translate-y: -0.375rem !important;
  }

  .\32xl\:focus\:-translate-y-2\.5:focus{
    --tw-translate-y: -0.625rem !important;
  }

  .\32xl\:focus\:-translate-y-3\.5:focus{
    --tw-translate-y: -0.875rem !important;
  }

  .\32xl\:focus\:translate-y-1\/2:focus{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:focus\:translate-y-1\/3:focus{
    --tw-translate-y: 33.333333% !important;
  }

  .\32xl\:focus\:translate-y-2\/3:focus{
    --tw-translate-y: 66.666667% !important;
  }

  .\32xl\:focus\:translate-y-1\/4:focus{
    --tw-translate-y: 25% !important;
  }

  .\32xl\:focus\:translate-y-2\/4:focus{
    --tw-translate-y: 50% !important;
  }

  .\32xl\:focus\:translate-y-3\/4:focus{
    --tw-translate-y: 75% !important;
  }

  .\32xl\:focus\:translate-y-full:focus{
    --tw-translate-y: 100% !important;
  }

  .\32xl\:focus\:-translate-y-1\/2:focus{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:focus\:-translate-y-1\/3:focus{
    --tw-translate-y: -33.333333% !important;
  }

  .\32xl\:focus\:-translate-y-2\/3:focus{
    --tw-translate-y: -66.666667% !important;
  }

  .\32xl\:focus\:-translate-y-1\/4:focus{
    --tw-translate-y: -25% !important;
  }

  .\32xl\:focus\:-translate-y-2\/4:focus{
    --tw-translate-y: -50% !important;
  }

  .\32xl\:focus\:-translate-y-3\/4:focus{
    --tw-translate-y: -75% !important;
  }

  .\32xl\:focus\:-translate-y-full:focus{
    --tw-translate-y: -100% !important;
  }

  .\32xl\:rotate-0{
    --tw-rotate: 0deg !important;
  }

  .\32xl\:rotate-1{
    --tw-rotate: 1deg !important;
  }

  .\32xl\:rotate-2{
    --tw-rotate: 2deg !important;
  }

  .\32xl\:rotate-3{
    --tw-rotate: 3deg !important;
  }

  .\32xl\:rotate-6{
    --tw-rotate: 6deg !important;
  }

  .\32xl\:rotate-12{
    --tw-rotate: 12deg !important;
  }

  .\32xl\:rotate-45{
    --tw-rotate: 45deg !important;
  }

  .\32xl\:rotate-90{
    --tw-rotate: 90deg !important;
  }

  .\32xl\:rotate-180{
    --tw-rotate: 180deg !important;
  }

  .\32xl\:-rotate-180{
    --tw-rotate: -180deg !important;
  }

  .\32xl\:-rotate-90{
    --tw-rotate: -90deg !important;
  }

  .\32xl\:-rotate-45{
    --tw-rotate: -45deg !important;
  }

  .\32xl\:-rotate-12{
    --tw-rotate: -12deg !important;
  }

  .\32xl\:-rotate-6{
    --tw-rotate: -6deg !important;
  }

  .\32xl\:-rotate-3{
    --tw-rotate: -3deg !important;
  }

  .\32xl\:-rotate-2{
    --tw-rotate: -2deg !important;
  }

  .\32xl\:-rotate-1{
    --tw-rotate: -1deg !important;
  }

  .\32xl\:hover\:rotate-0:hover{
    --tw-rotate: 0deg !important;
  }

  .\32xl\:hover\:rotate-1:hover{
    --tw-rotate: 1deg !important;
  }

  .\32xl\:hover\:rotate-2:hover{
    --tw-rotate: 2deg !important;
  }

  .\32xl\:hover\:rotate-3:hover{
    --tw-rotate: 3deg !important;
  }

  .\32xl\:hover\:rotate-6:hover{
    --tw-rotate: 6deg !important;
  }

  .\32xl\:hover\:rotate-12:hover{
    --tw-rotate: 12deg !important;
  }

  .\32xl\:hover\:rotate-45:hover{
    --tw-rotate: 45deg !important;
  }

  .\32xl\:hover\:rotate-90:hover{
    --tw-rotate: 90deg !important;
  }

  .\32xl\:hover\:rotate-180:hover{
    --tw-rotate: 180deg !important;
  }

  .\32xl\:hover\:-rotate-180:hover{
    --tw-rotate: -180deg !important;
  }

  .\32xl\:hover\:-rotate-90:hover{
    --tw-rotate: -90deg !important;
  }

  .\32xl\:hover\:-rotate-45:hover{
    --tw-rotate: -45deg !important;
  }

  .\32xl\:hover\:-rotate-12:hover{
    --tw-rotate: -12deg !important;
  }

  .\32xl\:hover\:-rotate-6:hover{
    --tw-rotate: -6deg !important;
  }

  .\32xl\:hover\:-rotate-3:hover{
    --tw-rotate: -3deg !important;
  }

  .\32xl\:hover\:-rotate-2:hover{
    --tw-rotate: -2deg !important;
  }

  .\32xl\:hover\:-rotate-1:hover{
    --tw-rotate: -1deg !important;
  }

  .\32xl\:focus\:rotate-0:focus{
    --tw-rotate: 0deg !important;
  }

  .\32xl\:focus\:rotate-1:focus{
    --tw-rotate: 1deg !important;
  }

  .\32xl\:focus\:rotate-2:focus{
    --tw-rotate: 2deg !important;
  }

  .\32xl\:focus\:rotate-3:focus{
    --tw-rotate: 3deg !important;
  }

  .\32xl\:focus\:rotate-6:focus{
    --tw-rotate: 6deg !important;
  }

  .\32xl\:focus\:rotate-12:focus{
    --tw-rotate: 12deg !important;
  }

  .\32xl\:focus\:rotate-45:focus{
    --tw-rotate: 45deg !important;
  }

  .\32xl\:focus\:rotate-90:focus{
    --tw-rotate: 90deg !important;
  }

  .\32xl\:focus\:rotate-180:focus{
    --tw-rotate: 180deg !important;
  }

  .\32xl\:focus\:-rotate-180:focus{
    --tw-rotate: -180deg !important;
  }

  .\32xl\:focus\:-rotate-90:focus{
    --tw-rotate: -90deg !important;
  }

  .\32xl\:focus\:-rotate-45:focus{
    --tw-rotate: -45deg !important;
  }

  .\32xl\:focus\:-rotate-12:focus{
    --tw-rotate: -12deg !important;
  }

  .\32xl\:focus\:-rotate-6:focus{
    --tw-rotate: -6deg !important;
  }

  .\32xl\:focus\:-rotate-3:focus{
    --tw-rotate: -3deg !important;
  }

  .\32xl\:focus\:-rotate-2:focus{
    --tw-rotate: -2deg !important;
  }

  .\32xl\:focus\:-rotate-1:focus{
    --tw-rotate: -1deg !important;
  }

  .\32xl\:skew-x-0{
    --tw-skew-x: 0deg !important;
  }

  .\32xl\:skew-x-1{
    --tw-skew-x: 1deg !important;
  }

  .\32xl\:skew-x-2{
    --tw-skew-x: 2deg !important;
  }

  .\32xl\:skew-x-3{
    --tw-skew-x: 3deg !important;
  }

  .\32xl\:skew-x-6{
    --tw-skew-x: 6deg !important;
  }

  .\32xl\:skew-x-12{
    --tw-skew-x: 12deg !important;
  }

  .\32xl\:-skew-x-12{
    --tw-skew-x: -12deg !important;
  }

  .\32xl\:-skew-x-6{
    --tw-skew-x: -6deg !important;
  }

  .\32xl\:-skew-x-3{
    --tw-skew-x: -3deg !important;
  }

  .\32xl\:-skew-x-2{
    --tw-skew-x: -2deg !important;
  }

  .\32xl\:-skew-x-1{
    --tw-skew-x: -1deg !important;
  }

  .\32xl\:skew-y-0{
    --tw-skew-y: 0deg !important;
  }

  .\32xl\:skew-y-1{
    --tw-skew-y: 1deg !important;
  }

  .\32xl\:skew-y-2{
    --tw-skew-y: 2deg !important;
  }

  .\32xl\:skew-y-3{
    --tw-skew-y: 3deg !important;
  }

  .\32xl\:skew-y-6{
    --tw-skew-y: 6deg !important;
  }

  .\32xl\:skew-y-12{
    --tw-skew-y: 12deg !important;
  }

  .\32xl\:-skew-y-12{
    --tw-skew-y: -12deg !important;
  }

  .\32xl\:-skew-y-6{
    --tw-skew-y: -6deg !important;
  }

  .\32xl\:-skew-y-3{
    --tw-skew-y: -3deg !important;
  }

  .\32xl\:-skew-y-2{
    --tw-skew-y: -2deg !important;
  }

  .\32xl\:-skew-y-1{
    --tw-skew-y: -1deg !important;
  }

  .\32xl\:hover\:skew-x-0:hover{
    --tw-skew-x: 0deg !important;
  }

  .\32xl\:hover\:skew-x-1:hover{
    --tw-skew-x: 1deg !important;
  }

  .\32xl\:hover\:skew-x-2:hover{
    --tw-skew-x: 2deg !important;
  }

  .\32xl\:hover\:skew-x-3:hover{
    --tw-skew-x: 3deg !important;
  }

  .\32xl\:hover\:skew-x-6:hover{
    --tw-skew-x: 6deg !important;
  }

  .\32xl\:hover\:skew-x-12:hover{
    --tw-skew-x: 12deg !important;
  }

  .\32xl\:hover\:-skew-x-12:hover{
    --tw-skew-x: -12deg !important;
  }

  .\32xl\:hover\:-skew-x-6:hover{
    --tw-skew-x: -6deg !important;
  }

  .\32xl\:hover\:-skew-x-3:hover{
    --tw-skew-x: -3deg !important;
  }

  .\32xl\:hover\:-skew-x-2:hover{
    --tw-skew-x: -2deg !important;
  }

  .\32xl\:hover\:-skew-x-1:hover{
    --tw-skew-x: -1deg !important;
  }

  .\32xl\:hover\:skew-y-0:hover{
    --tw-skew-y: 0deg !important;
  }

  .\32xl\:hover\:skew-y-1:hover{
    --tw-skew-y: 1deg !important;
  }

  .\32xl\:hover\:skew-y-2:hover{
    --tw-skew-y: 2deg !important;
  }

  .\32xl\:hover\:skew-y-3:hover{
    --tw-skew-y: 3deg !important;
  }

  .\32xl\:hover\:skew-y-6:hover{
    --tw-skew-y: 6deg !important;
  }

  .\32xl\:hover\:skew-y-12:hover{
    --tw-skew-y: 12deg !important;
  }

  .\32xl\:hover\:-skew-y-12:hover{
    --tw-skew-y: -12deg !important;
  }

  .\32xl\:hover\:-skew-y-6:hover{
    --tw-skew-y: -6deg !important;
  }

  .\32xl\:hover\:-skew-y-3:hover{
    --tw-skew-y: -3deg !important;
  }

  .\32xl\:hover\:-skew-y-2:hover{
    --tw-skew-y: -2deg !important;
  }

  .\32xl\:hover\:-skew-y-1:hover{
    --tw-skew-y: -1deg !important;
  }

  .\32xl\:focus\:skew-x-0:focus{
    --tw-skew-x: 0deg !important;
  }

  .\32xl\:focus\:skew-x-1:focus{
    --tw-skew-x: 1deg !important;
  }

  .\32xl\:focus\:skew-x-2:focus{
    --tw-skew-x: 2deg !important;
  }

  .\32xl\:focus\:skew-x-3:focus{
    --tw-skew-x: 3deg !important;
  }

  .\32xl\:focus\:skew-x-6:focus{
    --tw-skew-x: 6deg !important;
  }

  .\32xl\:focus\:skew-x-12:focus{
    --tw-skew-x: 12deg !important;
  }

  .\32xl\:focus\:-skew-x-12:focus{
    --tw-skew-x: -12deg !important;
  }

  .\32xl\:focus\:-skew-x-6:focus{
    --tw-skew-x: -6deg !important;
  }

  .\32xl\:focus\:-skew-x-3:focus{
    --tw-skew-x: -3deg !important;
  }

  .\32xl\:focus\:-skew-x-2:focus{
    --tw-skew-x: -2deg !important;
  }

  .\32xl\:focus\:-skew-x-1:focus{
    --tw-skew-x: -1deg !important;
  }

  .\32xl\:focus\:skew-y-0:focus{
    --tw-skew-y: 0deg !important;
  }

  .\32xl\:focus\:skew-y-1:focus{
    --tw-skew-y: 1deg !important;
  }

  .\32xl\:focus\:skew-y-2:focus{
    --tw-skew-y: 2deg !important;
  }

  .\32xl\:focus\:skew-y-3:focus{
    --tw-skew-y: 3deg !important;
  }

  .\32xl\:focus\:skew-y-6:focus{
    --tw-skew-y: 6deg !important;
  }

  .\32xl\:focus\:skew-y-12:focus{
    --tw-skew-y: 12deg !important;
  }

  .\32xl\:focus\:-skew-y-12:focus{
    --tw-skew-y: -12deg !important;
  }

  .\32xl\:focus\:-skew-y-6:focus{
    --tw-skew-y: -6deg !important;
  }

  .\32xl\:focus\:-skew-y-3:focus{
    --tw-skew-y: -3deg !important;
  }

  .\32xl\:focus\:-skew-y-2:focus{
    --tw-skew-y: -2deg !important;
  }

  .\32xl\:focus\:-skew-y-1:focus{
    --tw-skew-y: -1deg !important;
  }

  .\32xl\:scale-0{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .\32xl\:scale-50{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .\32xl\:scale-75{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .\32xl\:scale-90{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .\32xl\:scale-95{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .\32xl\:scale-100{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .\32xl\:scale-105{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:scale-110{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:scale-125{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:scale-150{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:hover\:scale-0:hover{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .\32xl\:hover\:scale-50:hover{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .\32xl\:hover\:scale-75:hover{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .\32xl\:hover\:scale-90:hover{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .\32xl\:hover\:scale-95:hover{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .\32xl\:hover\:scale-100:hover{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .\32xl\:hover\:scale-105:hover{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:hover\:scale-110:hover{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:hover\:scale-125:hover{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:hover\:scale-150:hover{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:focus\:scale-0:focus{
    --tw-scale-x: 0 !important;
    --tw-scale-y: 0 !important;
  }

  .\32xl\:focus\:scale-50:focus{
    --tw-scale-x: .5 !important;
    --tw-scale-y: .5 !important;
  }

  .\32xl\:focus\:scale-75:focus{
    --tw-scale-x: .75 !important;
    --tw-scale-y: .75 !important;
  }

  .\32xl\:focus\:scale-90:focus{
    --tw-scale-x: .9 !important;
    --tw-scale-y: .9 !important;
  }

  .\32xl\:focus\:scale-95:focus{
    --tw-scale-x: .95 !important;
    --tw-scale-y: .95 !important;
  }

  .\32xl\:focus\:scale-100:focus{
    --tw-scale-x: 1 !important;
    --tw-scale-y: 1 !important;
  }

  .\32xl\:focus\:scale-105:focus{
    --tw-scale-x: 1.05 !important;
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:focus\:scale-110:focus{
    --tw-scale-x: 1.1 !important;
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:focus\:scale-125:focus{
    --tw-scale-x: 1.25 !important;
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:focus\:scale-150:focus{
    --tw-scale-x: 1.5 !important;
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:scale-x-0{
    --tw-scale-x: 0 !important;
  }

  .\32xl\:scale-x-50{
    --tw-scale-x: .5 !important;
  }

  .\32xl\:scale-x-75{
    --tw-scale-x: .75 !important;
  }

  .\32xl\:scale-x-90{
    --tw-scale-x: .9 !important;
  }

  .\32xl\:scale-x-95{
    --tw-scale-x: .95 !important;
  }

  .\32xl\:scale-x-100{
    --tw-scale-x: 1 !important;
  }

  .\32xl\:scale-x-105{
    --tw-scale-x: 1.05 !important;
  }

  .\32xl\:scale-x-110{
    --tw-scale-x: 1.1 !important;
  }

  .\32xl\:scale-x-125{
    --tw-scale-x: 1.25 !important;
  }

  .\32xl\:scale-x-150{
    --tw-scale-x: 1.5 !important;
  }

  .\32xl\:scale-y-0{
    --tw-scale-y: 0 !important;
  }

  .\32xl\:scale-y-50{
    --tw-scale-y: .5 !important;
  }

  .\32xl\:scale-y-75{
    --tw-scale-y: .75 !important;
  }

  .\32xl\:scale-y-90{
    --tw-scale-y: .9 !important;
  }

  .\32xl\:scale-y-95{
    --tw-scale-y: .95 !important;
  }

  .\32xl\:scale-y-100{
    --tw-scale-y: 1 !important;
  }

  .\32xl\:scale-y-105{
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:scale-y-110{
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:scale-y-125{
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:scale-y-150{
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:hover\:scale-x-0:hover{
    --tw-scale-x: 0 !important;
  }

  .\32xl\:hover\:scale-x-50:hover{
    --tw-scale-x: .5 !important;
  }

  .\32xl\:hover\:scale-x-75:hover{
    --tw-scale-x: .75 !important;
  }

  .\32xl\:hover\:scale-x-90:hover{
    --tw-scale-x: .9 !important;
  }

  .\32xl\:hover\:scale-x-95:hover{
    --tw-scale-x: .95 !important;
  }

  .\32xl\:hover\:scale-x-100:hover{
    --tw-scale-x: 1 !important;
  }

  .\32xl\:hover\:scale-x-105:hover{
    --tw-scale-x: 1.05 !important;
  }

  .\32xl\:hover\:scale-x-110:hover{
    --tw-scale-x: 1.1 !important;
  }

  .\32xl\:hover\:scale-x-125:hover{
    --tw-scale-x: 1.25 !important;
  }

  .\32xl\:hover\:scale-x-150:hover{
    --tw-scale-x: 1.5 !important;
  }

  .\32xl\:hover\:scale-y-0:hover{
    --tw-scale-y: 0 !important;
  }

  .\32xl\:hover\:scale-y-50:hover{
    --tw-scale-y: .5 !important;
  }

  .\32xl\:hover\:scale-y-75:hover{
    --tw-scale-y: .75 !important;
  }

  .\32xl\:hover\:scale-y-90:hover{
    --tw-scale-y: .9 !important;
  }

  .\32xl\:hover\:scale-y-95:hover{
    --tw-scale-y: .95 !important;
  }

  .\32xl\:hover\:scale-y-100:hover{
    --tw-scale-y: 1 !important;
  }

  .\32xl\:hover\:scale-y-105:hover{
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:hover\:scale-y-110:hover{
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:hover\:scale-y-125:hover{
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:hover\:scale-y-150:hover{
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:focus\:scale-x-0:focus{
    --tw-scale-x: 0 !important;
  }

  .\32xl\:focus\:scale-x-50:focus{
    --tw-scale-x: .5 !important;
  }

  .\32xl\:focus\:scale-x-75:focus{
    --tw-scale-x: .75 !important;
  }

  .\32xl\:focus\:scale-x-90:focus{
    --tw-scale-x: .9 !important;
  }

  .\32xl\:focus\:scale-x-95:focus{
    --tw-scale-x: .95 !important;
  }

  .\32xl\:focus\:scale-x-100:focus{
    --tw-scale-x: 1 !important;
  }

  .\32xl\:focus\:scale-x-105:focus{
    --tw-scale-x: 1.05 !important;
  }

  .\32xl\:focus\:scale-x-110:focus{
    --tw-scale-x: 1.1 !important;
  }

  .\32xl\:focus\:scale-x-125:focus{
    --tw-scale-x: 1.25 !important;
  }

  .\32xl\:focus\:scale-x-150:focus{
    --tw-scale-x: 1.5 !important;
  }

  .\32xl\:focus\:scale-y-0:focus{
    --tw-scale-y: 0 !important;
  }

  .\32xl\:focus\:scale-y-50:focus{
    --tw-scale-y: .5 !important;
  }

  .\32xl\:focus\:scale-y-75:focus{
    --tw-scale-y: .75 !important;
  }

  .\32xl\:focus\:scale-y-90:focus{
    --tw-scale-y: .9 !important;
  }

  .\32xl\:focus\:scale-y-95:focus{
    --tw-scale-y: .95 !important;
  }

  .\32xl\:focus\:scale-y-100:focus{
    --tw-scale-y: 1 !important;
  }

  .\32xl\:focus\:scale-y-105:focus{
    --tw-scale-y: 1.05 !important;
  }

  .\32xl\:focus\:scale-y-110:focus{
    --tw-scale-y: 1.1 !important;
  }

  .\32xl\:focus\:scale-y-125:focus{
    --tw-scale-y: 1.25 !important;
  }

  .\32xl\:focus\:scale-y-150:focus{
    --tw-scale-y: 1.5 !important;
  }

  .\32xl\:animate-none{
    animation: none !important;
  }

  .\32xl\:animate-spin{
    animation: spin 1s linear infinite !important;
  }

  .\32xl\:animate-ping{
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
  }

  .\32xl\:animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
  }

  .\32xl\:animate-bounce{
    animation: bounce 1s infinite !important;
  }

  .\32xl\:cursor-auto{
    cursor: auto !important;
  }

  .\32xl\:cursor-default{
    cursor: default !important;
  }

  .\32xl\:cursor-pointer{
    cursor: pointer !important;
  }

  .\32xl\:cursor-wait{
    cursor: wait !important;
  }

  .\32xl\:cursor-text{
    cursor: text !important;
  }

  .\32xl\:cursor-move{
    cursor: move !important;
  }

  .\32xl\:cursor-help{
    cursor: help !important;
  }

  .\32xl\:cursor-not-allowed{
    cursor: not-allowed !important;
  }

  .\32xl\:select-none{
    -webkit-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .\32xl\:select-text{
    -webkit-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .\32xl\:select-all{
    -webkit-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .\32xl\:select-auto{
    -webkit-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .\32xl\:resize-none{
    resize: none !important;
  }

  .\32xl\:resize-y{
    resize: vertical !important;
  }

  .\32xl\:resize-x{
    resize: horizontal !important;
  }

  .\32xl\:resize{
    resize: both !important;
  }

  .\32xl\:list-inside{
    list-style-position: inside !important;
  }

  .\32xl\:list-outside{
    list-style-position: outside !important;
  }

  .\32xl\:list-none{
    list-style-type: none !important;
  }

  .\32xl\:list-disc{
    list-style-type: disc !important;
  }

  .\32xl\:list-decimal{
    list-style-type: decimal !important;
  }

  .\32xl\:appearance-none{
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .\32xl\:auto-cols-auto{
    grid-auto-columns: auto !important;
  }

  .\32xl\:auto-cols-min{
    grid-auto-columns: -webkit-min-content !important;
    grid-auto-columns: min-content !important;
  }

  .\32xl\:auto-cols-max{
    grid-auto-columns: -webkit-max-content !important;
    grid-auto-columns: max-content !important;
  }

  .\32xl\:auto-cols-fr{
    grid-auto-columns: minmax(0, 1fr) !important;
  }

  .\32xl\:grid-flow-row{
    grid-auto-flow: row !important;
  }

  .\32xl\:grid-flow-col{
    grid-auto-flow: column !important;
  }

  .\32xl\:grid-flow-row-dense{
    grid-auto-flow: row dense !important;
  }

  .\32xl\:grid-flow-col-dense{
    grid-auto-flow: column dense !important;
  }

  .\32xl\:auto-rows-auto{
    grid-auto-rows: auto !important;
  }

  .\32xl\:auto-rows-min{
    grid-auto-rows: -webkit-min-content !important;
    grid-auto-rows: min-content !important;
  }

  .\32xl\:auto-rows-max{
    grid-auto-rows: -webkit-max-content !important;
    grid-auto-rows: max-content !important;
  }

  .\32xl\:auto-rows-fr{
    grid-auto-rows: minmax(0, 1fr) !important;
  }

  .\32xl\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-6{
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-7{
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-8{
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-9{
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-10{
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-11{
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-12{
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-cols-none{
    grid-template-columns: none !important;
  }

  .\32xl\:grid-rows-1{
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-rows-2{
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-rows-3{
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-rows-4{
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-rows-5{
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-rows-6{
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .\32xl\:grid-rows-none{
    grid-template-rows: none !important;
  }

  .\32xl\:flex-row{
    flex-direction: row !important;
  }

  .\32xl\:flex-row-reverse{
    flex-direction: row-reverse !important;
  }

  .\32xl\:flex-col{
    flex-direction: column !important;
  }

  .\32xl\:flex-col-reverse{
    flex-direction: column-reverse !important;
  }

  .\32xl\:flex-wrap{
    flex-wrap: wrap !important;
  }

  .\32xl\:flex-wrap-reverse{
    flex-wrap: wrap-reverse !important;
  }

  .\32xl\:flex-nowrap{
    flex-wrap: nowrap !important;
  }

  .\32xl\:place-content-center{
    place-content: center !important;
  }

  .\32xl\:place-content-start{
    place-content: start !important;
  }

  .\32xl\:place-content-end{
    place-content: end !important;
  }

  .\32xl\:place-content-between{
    place-content: space-between !important;
  }

  .\32xl\:place-content-around{
    place-content: space-around !important;
  }

  .\32xl\:place-content-evenly{
    place-content: space-evenly !important;
  }

  .\32xl\:place-content-stretch{
    place-content: stretch !important;
  }

  .\32xl\:place-items-start{
    place-items: start !important;
  }

  .\32xl\:place-items-end{
    place-items: end !important;
  }

  .\32xl\:place-items-center{
    place-items: center !important;
  }

  .\32xl\:place-items-stretch{
    place-items: stretch !important;
  }

  .\32xl\:content-center{
    align-content: center !important;
  }

  .\32xl\:content-start{
    align-content: flex-start !important;
  }

  .\32xl\:content-end{
    align-content: flex-end !important;
  }

  .\32xl\:content-between{
    align-content: space-between !important;
  }

  .\32xl\:content-around{
    align-content: space-around !important;
  }

  .\32xl\:content-evenly{
    align-content: space-evenly !important;
  }

  .\32xl\:items-start{
    align-items: flex-start !important;
  }

  .\32xl\:items-end{
    align-items: flex-end !important;
  }

  .\32xl\:items-center{
    align-items: center !important;
  }

  .\32xl\:items-baseline{
    align-items: baseline !important;
  }

  .\32xl\:items-stretch{
    align-items: stretch !important;
  }

  .\32xl\:justify-start{
    justify-content: flex-start !important;
  }

  .\32xl\:justify-end{
    justify-content: flex-end !important;
  }

  .\32xl\:justify-center{
    justify-content: center !important;
  }

  .\32xl\:justify-between{
    justify-content: space-between !important;
  }

  .\32xl\:justify-around{
    justify-content: space-around !important;
  }

  .\32xl\:justify-evenly{
    justify-content: space-evenly !important;
  }

  .\32xl\:justify-items-start{
    justify-items: start !important;
  }

  .\32xl\:justify-items-end{
    justify-items: end !important;
  }

  .\32xl\:justify-items-center{
    justify-items: center !important;
  }

  .\32xl\:justify-items-stretch{
    justify-items: stretch !important;
  }

  .\32xl\:gap-0{
    gap: 0px !important;
  }

  .\32xl\:gap-1{
    gap: 0.25rem !important;
  }

  .\32xl\:gap-2{
    gap: 0.5rem !important;
  }

  .\32xl\:gap-3{
    gap: 0.75rem !important;
  }

  .\32xl\:gap-4{
    gap: 1rem !important;
  }

  .\32xl\:gap-5{
    gap: 1.25rem !important;
  }

  .\32xl\:gap-6{
    gap: 1.5rem !important;
  }

  .\32xl\:gap-7{
    gap: 1.75rem !important;
  }

  .\32xl\:gap-8{
    gap: 2rem !important;
  }

  .\32xl\:gap-9{
    gap: 2.25rem !important;
  }

  .\32xl\:gap-10{
    gap: 2.5rem !important;
  }

  .\32xl\:gap-11{
    gap: 2.75rem !important;
  }

  .\32xl\:gap-12{
    gap: 3rem !important;
  }

  .\32xl\:gap-14{
    gap: 3.5rem !important;
  }

  .\32xl\:gap-16{
    gap: 4rem !important;
  }

  .\32xl\:gap-20{
    gap: 5rem !important;
  }

  .\32xl\:gap-24{
    gap: 6rem !important;
  }

  .\32xl\:gap-28{
    gap: 7rem !important;
  }

  .\32xl\:gap-32{
    gap: 8rem !important;
  }

  .\32xl\:gap-36{
    gap: 9rem !important;
  }

  .\32xl\:gap-40{
    gap: 10rem !important;
  }

  .\32xl\:gap-44{
    gap: 11rem !important;
  }

  .\32xl\:gap-48{
    gap: 12rem !important;
  }

  .\32xl\:gap-52{
    gap: 13rem !important;
  }

  .\32xl\:gap-56{
    gap: 14rem !important;
  }

  .\32xl\:gap-60{
    gap: 15rem !important;
  }

  .\32xl\:gap-64{
    gap: 16rem !important;
  }

  .\32xl\:gap-72{
    gap: 18rem !important;
  }

  .\32xl\:gap-80{
    gap: 20rem !important;
  }

  .\32xl\:gap-96{
    gap: 24rem !important;
  }

  .\32xl\:gap-px{
    gap: 1px !important;
  }

  .\32xl\:gap-0\.5{
    gap: 0.125rem !important;
  }

  .\32xl\:gap-1\.5{
    gap: 0.375rem !important;
  }

  .\32xl\:gap-2\.5{
    gap: 0.625rem !important;
  }

  .\32xl\:gap-3\.5{
    gap: 0.875rem !important;
  }

  .\32xl\:gap-x-0{
    -webkit-column-gap: 0px !important;
            column-gap: 0px !important;
  }

  .\32xl\:gap-x-1{
    -webkit-column-gap: 0.25rem !important;
            column-gap: 0.25rem !important;
  }

  .\32xl\:gap-x-2{
    -webkit-column-gap: 0.5rem !important;
            column-gap: 0.5rem !important;
  }

  .\32xl\:gap-x-3{
    -webkit-column-gap: 0.75rem !important;
            column-gap: 0.75rem !important;
  }

  .\32xl\:gap-x-4{
    -webkit-column-gap: 1rem !important;
            column-gap: 1rem !important;
  }

  .\32xl\:gap-x-5{
    -webkit-column-gap: 1.25rem !important;
            column-gap: 1.25rem !important;
  }

  .\32xl\:gap-x-6{
    -webkit-column-gap: 1.5rem !important;
            column-gap: 1.5rem !important;
  }

  .\32xl\:gap-x-7{
    -webkit-column-gap: 1.75rem !important;
            column-gap: 1.75rem !important;
  }

  .\32xl\:gap-x-8{
    -webkit-column-gap: 2rem !important;
            column-gap: 2rem !important;
  }

  .\32xl\:gap-x-9{
    -webkit-column-gap: 2.25rem !important;
            column-gap: 2.25rem !important;
  }

  .\32xl\:gap-x-10{
    -webkit-column-gap: 2.5rem !important;
            column-gap: 2.5rem !important;
  }

  .\32xl\:gap-x-11{
    -webkit-column-gap: 2.75rem !important;
            column-gap: 2.75rem !important;
  }

  .\32xl\:gap-x-12{
    -webkit-column-gap: 3rem !important;
            column-gap: 3rem !important;
  }

  .\32xl\:gap-x-14{
    -webkit-column-gap: 3.5rem !important;
            column-gap: 3.5rem !important;
  }

  .\32xl\:gap-x-16{
    -webkit-column-gap: 4rem !important;
            column-gap: 4rem !important;
  }

  .\32xl\:gap-x-20{
    -webkit-column-gap: 5rem !important;
            column-gap: 5rem !important;
  }

  .\32xl\:gap-x-24{
    -webkit-column-gap: 6rem !important;
            column-gap: 6rem !important;
  }

  .\32xl\:gap-x-28{
    -webkit-column-gap: 7rem !important;
            column-gap: 7rem !important;
  }

  .\32xl\:gap-x-32{
    -webkit-column-gap: 8rem !important;
            column-gap: 8rem !important;
  }

  .\32xl\:gap-x-36{
    -webkit-column-gap: 9rem !important;
            column-gap: 9rem !important;
  }

  .\32xl\:gap-x-40{
    -webkit-column-gap: 10rem !important;
            column-gap: 10rem !important;
  }

  .\32xl\:gap-x-44{
    -webkit-column-gap: 11rem !important;
            column-gap: 11rem !important;
  }

  .\32xl\:gap-x-48{
    -webkit-column-gap: 12rem !important;
            column-gap: 12rem !important;
  }

  .\32xl\:gap-x-52{
    -webkit-column-gap: 13rem !important;
            column-gap: 13rem !important;
  }

  .\32xl\:gap-x-56{
    -webkit-column-gap: 14rem !important;
            column-gap: 14rem !important;
  }

  .\32xl\:gap-x-60{
    -webkit-column-gap: 15rem !important;
            column-gap: 15rem !important;
  }

  .\32xl\:gap-x-64{
    -webkit-column-gap: 16rem !important;
            column-gap: 16rem !important;
  }

  .\32xl\:gap-x-72{
    -webkit-column-gap: 18rem !important;
            column-gap: 18rem !important;
  }

  .\32xl\:gap-x-80{
    -webkit-column-gap: 20rem !important;
            column-gap: 20rem !important;
  }

  .\32xl\:gap-x-96{
    -webkit-column-gap: 24rem !important;
            column-gap: 24rem !important;
  }

  .\32xl\:gap-x-px{
    -webkit-column-gap: 1px !important;
            column-gap: 1px !important;
  }

  .\32xl\:gap-x-0\.5{
    -webkit-column-gap: 0.125rem !important;
            column-gap: 0.125rem !important;
  }

  .\32xl\:gap-x-1\.5{
    -webkit-column-gap: 0.375rem !important;
            column-gap: 0.375rem !important;
  }

  .\32xl\:gap-x-2\.5{
    -webkit-column-gap: 0.625rem !important;
            column-gap: 0.625rem !important;
  }

  .\32xl\:gap-x-3\.5{
    -webkit-column-gap: 0.875rem !important;
            column-gap: 0.875rem !important;
  }

  .\32xl\:gap-y-0{
    row-gap: 0px !important;
  }

  .\32xl\:gap-y-1{
    row-gap: 0.25rem !important;
  }

  .\32xl\:gap-y-2{
    row-gap: 0.5rem !important;
  }

  .\32xl\:gap-y-3{
    row-gap: 0.75rem !important;
  }

  .\32xl\:gap-y-4{
    row-gap: 1rem !important;
  }

  .\32xl\:gap-y-5{
    row-gap: 1.25rem !important;
  }

  .\32xl\:gap-y-6{
    row-gap: 1.5rem !important;
  }

  .\32xl\:gap-y-7{
    row-gap: 1.75rem !important;
  }

  .\32xl\:gap-y-8{
    row-gap: 2rem !important;
  }

  .\32xl\:gap-y-9{
    row-gap: 2.25rem !important;
  }

  .\32xl\:gap-y-10{
    row-gap: 2.5rem !important;
  }

  .\32xl\:gap-y-11{
    row-gap: 2.75rem !important;
  }

  .\32xl\:gap-y-12{
    row-gap: 3rem !important;
  }

  .\32xl\:gap-y-14{
    row-gap: 3.5rem !important;
  }

  .\32xl\:gap-y-16{
    row-gap: 4rem !important;
  }

  .\32xl\:gap-y-20{
    row-gap: 5rem !important;
  }

  .\32xl\:gap-y-24{
    row-gap: 6rem !important;
  }

  .\32xl\:gap-y-28{
    row-gap: 7rem !important;
  }

  .\32xl\:gap-y-32{
    row-gap: 8rem !important;
  }

  .\32xl\:gap-y-36{
    row-gap: 9rem !important;
  }

  .\32xl\:gap-y-40{
    row-gap: 10rem !important;
  }

  .\32xl\:gap-y-44{
    row-gap: 11rem !important;
  }

  .\32xl\:gap-y-48{
    row-gap: 12rem !important;
  }

  .\32xl\:gap-y-52{
    row-gap: 13rem !important;
  }

  .\32xl\:gap-y-56{
    row-gap: 14rem !important;
  }

  .\32xl\:gap-y-60{
    row-gap: 15rem !important;
  }

  .\32xl\:gap-y-64{
    row-gap: 16rem !important;
  }

  .\32xl\:gap-y-72{
    row-gap: 18rem !important;
  }

  .\32xl\:gap-y-80{
    row-gap: 20rem !important;
  }

  .\32xl\:gap-y-96{
    row-gap: 24rem !important;
  }

  .\32xl\:gap-y-px{
    row-gap: 1px !important;
  }

  .\32xl\:gap-y-0\.5{
    row-gap: 0.125rem !important;
  }

  .\32xl\:gap-y-1\.5{
    row-gap: 0.375rem !important;
  }

  .\32xl\:gap-y-2\.5{
    row-gap: 0.625rem !important;
  }

  .\32xl\:gap-y-3\.5{
    row-gap: 0.875rem !important;
  }

  .\32xl\:space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .\32xl\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-px > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
  }

  .\32xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 1 !important;
  }

  .\32xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 1 !important;
  }

  .\32xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:divide-x > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .\32xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:divide-y > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  }

  .\32xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-y-reverse: 1 !important;
  }

  .\32xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]){
    --tw-divide-x-reverse: 1 !important;
  }

  .\32xl\:divide-solid > :not([hidden]) ~ :not([hidden]){
    border-style: solid !important;
  }

  .\32xl\:divide-dashed > :not([hidden]) ~ :not([hidden]){
    border-style: dashed !important;
  }

  .\32xl\:divide-dotted > :not([hidden]) ~ :not([hidden]){
    border-style: dotted !important;
  }

  .\32xl\:divide-double > :not([hidden]) ~ :not([hidden]){
    border-style: double !important;
  }

  .\32xl\:divide-none > :not([hidden]) ~ :not([hidden]){
    border-style: none !important;
  }

  .\32xl\:divide-transparent > :not([hidden]) ~ :not([hidden]){
    border-color: transparent !important;
  }

  .\32xl\:divide-current > :not([hidden]) ~ :not([hidden]){
    border-color: currentColor !important;
  }

  .\32xl\:divide-black > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-white > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-qipGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-portalGreen > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-divide-opacity)) !important;
  }

  .\32xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0 !important;
  }

  .\32xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.05 !important;
  }

  .\32xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.1 !important;
  }

  .\32xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.2 !important;
  }

  .\32xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.25 !important;
  }

  .\32xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.3 !important;
  }

  .\32xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.4 !important;
  }

  .\32xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.5 !important;
  }

  .\32xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.6 !important;
  }

  .\32xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.7 !important;
  }

  .\32xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.75 !important;
  }

  .\32xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.8 !important;
  }

  .\32xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.9 !important;
  }

  .\32xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 0.95 !important;
  }

  .\32xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]){
    --tw-divide-opacity: 1 !important;
  }

  .\32xl\:place-self-auto{
    place-self: auto !important;
  }

  .\32xl\:place-self-start{
    place-self: start !important;
  }

  .\32xl\:place-self-end{
    place-self: end !important;
  }

  .\32xl\:place-self-center{
    place-self: center !important;
  }

  .\32xl\:place-self-stretch{
    place-self: stretch !important;
  }

  .\32xl\:self-auto{
    align-self: auto !important;
  }

  .\32xl\:self-start{
    align-self: flex-start !important;
  }

  .\32xl\:self-end{
    align-self: flex-end !important;
  }

  .\32xl\:self-center{
    align-self: center !important;
  }

  .\32xl\:self-stretch{
    align-self: stretch !important;
  }

  .\32xl\:self-baseline{
    align-self: baseline !important;
  }

  .\32xl\:justify-self-auto{
    justify-self: auto !important;
  }

  .\32xl\:justify-self-start{
    justify-self: start !important;
  }

  .\32xl\:justify-self-end{
    justify-self: end !important;
  }

  .\32xl\:justify-self-center{
    justify-self: center !important;
  }

  .\32xl\:justify-self-stretch{
    justify-self: stretch !important;
  }

  .\32xl\:overflow-auto{
    overflow: auto !important;
  }

  .\32xl\:overflow-hidden{
    overflow: hidden !important;
  }

  .\32xl\:overflow-visible{
    overflow: visible !important;
  }

  .\32xl\:overflow-scroll{
    overflow: scroll !important;
  }

  .\32xl\:overflow-x-auto{
    overflow-x: auto !important;
  }

  .\32xl\:overflow-y-auto{
    overflow-y: auto !important;
  }

  .\32xl\:overflow-x-hidden{
    overflow-x: hidden !important;
  }

  .\32xl\:overflow-y-hidden{
    overflow-y: hidden !important;
  }

  .\32xl\:overflow-x-visible{
    overflow-x: visible !important;
  }

  .\32xl\:overflow-y-visible{
    overflow-y: visible !important;
  }

  .\32xl\:overflow-x-scroll{
    overflow-x: scroll !important;
  }

  .\32xl\:overflow-y-scroll{
    overflow-y: scroll !important;
  }

  .\32xl\:overscroll-auto{
    -ms-scroll-chaining: chained !important;
        overscroll-behavior: auto !important;
  }

  .\32xl\:overscroll-contain{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: contain !important;
  }

  .\32xl\:overscroll-none{
    -ms-scroll-chaining: none !important;
        overscroll-behavior: none !important;
  }

  .\32xl\:overscroll-y-auto{
    overscroll-behavior-y: auto !important;
  }

  .\32xl\:overscroll-y-contain{
    overscroll-behavior-y: contain !important;
  }

  .\32xl\:overscroll-y-none{
    overscroll-behavior-y: none !important;
  }

  .\32xl\:overscroll-x-auto{
    overscroll-behavior-x: auto !important;
  }

  .\32xl\:overscroll-x-contain{
    overscroll-behavior-x: contain !important;
  }

  .\32xl\:overscroll-x-none{
    overscroll-behavior-x: none !important;
  }

  .\32xl\:truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .\32xl\:overflow-ellipsis{
    text-overflow: ellipsis !important;
  }

  .\32xl\:overflow-clip{
    text-overflow: clip !important;
  }

  .\32xl\:whitespace-normal{
    white-space: normal !important;
  }

  .\32xl\:whitespace-nowrap{
    white-space: nowrap !important;
  }

  .\32xl\:whitespace-pre{
    white-space: pre !important;
  }

  .\32xl\:whitespace-pre-line{
    white-space: pre-line !important;
  }

  .\32xl\:whitespace-pre-wrap{
    white-space: pre-wrap !important;
  }

  .\32xl\:break-normal{
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .\32xl\:break-words{
    overflow-wrap: break-word !important;
  }

  .\32xl\:break-all{
    word-break: break-all !important;
  }

  .\32xl\:rounded-none{
    border-radius: 0px !important;
  }

  .\32xl\:rounded-sm{
    border-radius: 0.125rem !important;
  }

  .\32xl\:rounded{
    border-radius: 0.25rem !important;
  }

  .\32xl\:rounded-md{
    border-radius: 0.375rem !important;
  }

  .\32xl\:rounded-lg{
    border-radius: 0.5rem !important;
  }

  .\32xl\:rounded-xl{
    border-radius: 0.75rem !important;
  }

  .\32xl\:rounded-2xl{
    border-radius: 1rem !important;
  }

  .\32xl\:rounded-3xl{
    border-radius: 1.5rem !important;
  }

  .\32xl\:rounded-full{
    border-radius: 9999px !important;
  }

  .\32xl\:rounded-t-none{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .\32xl\:rounded-t-sm{
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .\32xl\:rounded-t{
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .\32xl\:rounded-t-md{
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .\32xl\:rounded-t-lg{
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .\32xl\:rounded-t-xl{
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }

  .\32xl\:rounded-t-2xl{
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .\32xl\:rounded-t-3xl{
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .\32xl\:rounded-t-full{
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .\32xl\:rounded-r-none{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .\32xl\:rounded-r-sm{
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .\32xl\:rounded-r{
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .\32xl\:rounded-r-md{
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .\32xl\:rounded-r-lg{
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .\32xl\:rounded-r-xl{
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }

  .\32xl\:rounded-r-2xl{
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .\32xl\:rounded-r-3xl{
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .\32xl\:rounded-r-full{
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .\32xl\:rounded-b-none{
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .\32xl\:rounded-b-sm{
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .\32xl\:rounded-b{
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .\32xl\:rounded-b-md{
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .\32xl\:rounded-b-lg{
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .\32xl\:rounded-b-xl{
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .\32xl\:rounded-b-2xl{
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .\32xl\:rounded-b-3xl{
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .\32xl\:rounded-b-full{
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .\32xl\:rounded-l-none{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .\32xl\:rounded-l-sm{
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .\32xl\:rounded-l{
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .\32xl\:rounded-l-md{
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .\32xl\:rounded-l-lg{
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .\32xl\:rounded-l-xl{
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }

  .\32xl\:rounded-l-2xl{
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .\32xl\:rounded-l-3xl{
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .\32xl\:rounded-l-full{
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .\32xl\:rounded-tl-none{
    border-top-left-radius: 0px !important;
  }

  .\32xl\:rounded-tl-sm{
    border-top-left-radius: 0.125rem !important;
  }

  .\32xl\:rounded-tl{
    border-top-left-radius: 0.25rem !important;
  }

  .\32xl\:rounded-tl-md{
    border-top-left-radius: 0.375rem !important;
  }

  .\32xl\:rounded-tl-lg{
    border-top-left-radius: 0.5rem !important;
  }

  .\32xl\:rounded-tl-xl{
    border-top-left-radius: 0.75rem !important;
  }

  .\32xl\:rounded-tl-2xl{
    border-top-left-radius: 1rem !important;
  }

  .\32xl\:rounded-tl-3xl{
    border-top-left-radius: 1.5rem !important;
  }

  .\32xl\:rounded-tl-full{
    border-top-left-radius: 9999px !important;
  }

  .\32xl\:rounded-tr-none{
    border-top-right-radius: 0px !important;
  }

  .\32xl\:rounded-tr-sm{
    border-top-right-radius: 0.125rem !important;
  }

  .\32xl\:rounded-tr{
    border-top-right-radius: 0.25rem !important;
  }

  .\32xl\:rounded-tr-md{
    border-top-right-radius: 0.375rem !important;
  }

  .\32xl\:rounded-tr-lg{
    border-top-right-radius: 0.5rem !important;
  }

  .\32xl\:rounded-tr-xl{
    border-top-right-radius: 0.75rem !important;
  }

  .\32xl\:rounded-tr-2xl{
    border-top-right-radius: 1rem !important;
  }

  .\32xl\:rounded-tr-3xl{
    border-top-right-radius: 1.5rem !important;
  }

  .\32xl\:rounded-tr-full{
    border-top-right-radius: 9999px !important;
  }

  .\32xl\:rounded-br-none{
    border-bottom-right-radius: 0px !important;
  }

  .\32xl\:rounded-br-sm{
    border-bottom-right-radius: 0.125rem !important;
  }

  .\32xl\:rounded-br{
    border-bottom-right-radius: 0.25rem !important;
  }

  .\32xl\:rounded-br-md{
    border-bottom-right-radius: 0.375rem !important;
  }

  .\32xl\:rounded-br-lg{
    border-bottom-right-radius: 0.5rem !important;
  }

  .\32xl\:rounded-br-xl{
    border-bottom-right-radius: 0.75rem !important;
  }

  .\32xl\:rounded-br-2xl{
    border-bottom-right-radius: 1rem !important;
  }

  .\32xl\:rounded-br-3xl{
    border-bottom-right-radius: 1.5rem !important;
  }

  .\32xl\:rounded-br-full{
    border-bottom-right-radius: 9999px !important;
  }

  .\32xl\:rounded-bl-none{
    border-bottom-left-radius: 0px !important;
  }

  .\32xl\:rounded-bl-sm{
    border-bottom-left-radius: 0.125rem !important;
  }

  .\32xl\:rounded-bl{
    border-bottom-left-radius: 0.25rem !important;
  }

  .\32xl\:rounded-bl-md{
    border-bottom-left-radius: 0.375rem !important;
  }

  .\32xl\:rounded-bl-lg{
    border-bottom-left-radius: 0.5rem !important;
  }

  .\32xl\:rounded-bl-xl{
    border-bottom-left-radius: 0.75rem !important;
  }

  .\32xl\:rounded-bl-2xl{
    border-bottom-left-radius: 1rem !important;
  }

  .\32xl\:rounded-bl-3xl{
    border-bottom-left-radius: 1.5rem !important;
  }

  .\32xl\:rounded-bl-full{
    border-bottom-left-radius: 9999px !important;
  }

  .\32xl\:border-0{
    border-width: 0px !important;
  }

  .\32xl\:border-2{
    border-width: 2px !important;
  }

  .\32xl\:border-4{
    border-width: 4px !important;
  }

  .\32xl\:border-8{
    border-width: 8px !important;
  }

  .\32xl\:border{
    border-width: 1px !important;
  }

  .\32xl\:border-t-0{
    border-top-width: 0px !important;
  }

  .\32xl\:border-t-2{
    border-top-width: 2px !important;
  }

  .\32xl\:border-t-4{
    border-top-width: 4px !important;
  }

  .\32xl\:border-t-8{
    border-top-width: 8px !important;
  }

  .\32xl\:border-t{
    border-top-width: 1px !important;
  }

  .\32xl\:border-r-0{
    border-right-width: 0px !important;
  }

  .\32xl\:border-r-2{
    border-right-width: 2px !important;
  }

  .\32xl\:border-r-4{
    border-right-width: 4px !important;
  }

  .\32xl\:border-r-8{
    border-right-width: 8px !important;
  }

  .\32xl\:border-r{
    border-right-width: 1px !important;
  }

  .\32xl\:border-b-0{
    border-bottom-width: 0px !important;
  }

  .\32xl\:border-b-2{
    border-bottom-width: 2px !important;
  }

  .\32xl\:border-b-4{
    border-bottom-width: 4px !important;
  }

  .\32xl\:border-b-8{
    border-bottom-width: 8px !important;
  }

  .\32xl\:border-b{
    border-bottom-width: 1px !important;
  }

  .\32xl\:border-l-0{
    border-left-width: 0px !important;
  }

  .\32xl\:border-l-2{
    border-left-width: 2px !important;
  }

  .\32xl\:border-l-4{
    border-left-width: 4px !important;
  }

  .\32xl\:border-l-8{
    border-left-width: 8px !important;
  }

  .\32xl\:border-l{
    border-left-width: 1px !important;
  }

  .\32xl\:border-solid{
    border-style: solid !important;
  }

  .\32xl\:border-dashed{
    border-style: dashed !important;
  }

  .\32xl\:border-dotted{
    border-style: dotted !important;
  }

  .\32xl\:border-double{
    border-style: double !important;
  }

  .\32xl\:border-none{
    border-style: none !important;
  }

  .\32xl\:border-transparent{
    border-color: transparent !important;
  }

  .\32xl\:border-current{
    border-color: currentColor !important;
  }

  .\32xl\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-transparent{
    border-color: transparent !important;
  }

  .group:hover .\32xl\:group-hover\:border-current{
    border-color: currentColor !important;
  }

  .group:hover .\32xl\:group-hover\:border-black{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-white{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-gray-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-red-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-yellow-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-green-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-blue-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-indigo-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-purple-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-50{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-100{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-200{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-300{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-400{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-500{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-600{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-700{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-800{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-pink-900{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-qipGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:border-portalGreen{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-transparent:focus-within{
    border-color: transparent !important;
  }

  .\32xl\:focus-within\:border-current:focus-within{
    border-color: currentColor !important;
  }

  .\32xl\:focus-within\:border-black:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-white:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-gray-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-red-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-yellow-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-green-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-blue-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-indigo-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-purple-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-50:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-100:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-200:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-300:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-400:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-500:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-600:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-700:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-800:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-pink-900:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-qipGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus-within\:border-portalGreen:focus-within{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-transparent:hover{
    border-color: transparent !important;
  }

  .\32xl\:hover\:border-current:hover{
    border-color: currentColor !important;
  }

  .\32xl\:hover\:border-black:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-white:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-gray-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-red-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-yellow-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-green-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-blue-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-indigo-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-purple-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-50:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-100:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-200:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-300:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-400:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-500:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-600:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-700:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-800:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-pink-900:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-qipGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:hover\:border-portalGreen:hover{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-transparent:focus{
    border-color: transparent !important;
  }

  .\32xl\:focus\:border-current:focus{
    border-color: currentColor !important;
  }

  .\32xl\:focus\:border-black:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-white:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-gray-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-red-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-yellow-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-green-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-blue-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-indigo-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-purple-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-50:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-100:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-200:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-300:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-400:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-500:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-600:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-700:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-800:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-pink-900:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-qipGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(139, 195, 52, var(--tw-border-opacity)) !important;
  }

  .\32xl\:focus\:border-portalGreen:focus{
    --tw-border-opacity: 1 !important;
    border-color: rgba(138, 192, 74, var(--tw-border-opacity)) !important;
  }

  .\32xl\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-0{
    --tw-border-opacity: 0 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-5{
    --tw-border-opacity: 0.05 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-10{
    --tw-border-opacity: 0.1 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-20{
    --tw-border-opacity: 0.2 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-25{
    --tw-border-opacity: 0.25 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-30{
    --tw-border-opacity: 0.3 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-40{
    --tw-border-opacity: 0.4 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-50{
    --tw-border-opacity: 0.5 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-60{
    --tw-border-opacity: 0.6 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-70{
    --tw-border-opacity: 0.7 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-75{
    --tw-border-opacity: 0.75 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-80{
    --tw-border-opacity: 0.8 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-90{
    --tw-border-opacity: 0.9 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-95{
    --tw-border-opacity: 0.95 !important;
  }

  .group:hover .\32xl\:group-hover\:border-opacity-100{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:focus-within\:border-opacity-0:focus-within{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:focus-within\:border-opacity-5:focus-within{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:border-opacity-10:focus-within{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:border-opacity-20:focus-within{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:border-opacity-25:focus-within{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:border-opacity-30:focus-within{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:border-opacity-40:focus-within{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:border-opacity-50:focus-within{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:border-opacity-60:focus-within{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:border-opacity-70:focus-within{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:border-opacity-75:focus-within{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:border-opacity-80:focus-within{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:border-opacity-90:focus-within{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:border-opacity-95:focus-within{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:border-opacity-100:focus-within{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:hover\:border-opacity-0:hover{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:hover\:border-opacity-5:hover{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:hover\:border-opacity-10:hover{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:hover\:border-opacity-20:hover{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:hover\:border-opacity-25:hover{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:hover\:border-opacity-30:hover{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:hover\:border-opacity-40:hover{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:hover\:border-opacity-50:hover{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:hover\:border-opacity-60:hover{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:hover\:border-opacity-70:hover{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:hover\:border-opacity-75:hover{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:hover\:border-opacity-80:hover{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:hover\:border-opacity-90:hover{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:hover\:border-opacity-95:hover{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:hover\:border-opacity-100:hover{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:focus\:border-opacity-0:focus{
    --tw-border-opacity: 0 !important;
  }

  .\32xl\:focus\:border-opacity-5:focus{
    --tw-border-opacity: 0.05 !important;
  }

  .\32xl\:focus\:border-opacity-10:focus{
    --tw-border-opacity: 0.1 !important;
  }

  .\32xl\:focus\:border-opacity-20:focus{
    --tw-border-opacity: 0.2 !important;
  }

  .\32xl\:focus\:border-opacity-25:focus{
    --tw-border-opacity: 0.25 !important;
  }

  .\32xl\:focus\:border-opacity-30:focus{
    --tw-border-opacity: 0.3 !important;
  }

  .\32xl\:focus\:border-opacity-40:focus{
    --tw-border-opacity: 0.4 !important;
  }

  .\32xl\:focus\:border-opacity-50:focus{
    --tw-border-opacity: 0.5 !important;
  }

  .\32xl\:focus\:border-opacity-60:focus{
    --tw-border-opacity: 0.6 !important;
  }

  .\32xl\:focus\:border-opacity-70:focus{
    --tw-border-opacity: 0.7 !important;
  }

  .\32xl\:focus\:border-opacity-75:focus{
    --tw-border-opacity: 0.75 !important;
  }

  .\32xl\:focus\:border-opacity-80:focus{
    --tw-border-opacity: 0.8 !important;
  }

  .\32xl\:focus\:border-opacity-90:focus{
    --tw-border-opacity: 0.9 !important;
  }

  .\32xl\:focus\:border-opacity-95:focus{
    --tw-border-opacity: 0.95 !important;
  }

  .\32xl\:focus\:border-opacity-100:focus{
    --tw-border-opacity: 1 !important;
  }

  .\32xl\:bg-transparent{
    background-color: transparent !important;
  }

  .\32xl\:bg-current{
    background-color: currentColor !important;
  }

  .\32xl\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-transparent{
    background-color: transparent !important;
  }

  .group:hover .\32xl\:group-hover\:bg-current{
    background-color: currentColor !important;
  }

  .group:hover .\32xl\:group-hover\:bg-black{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-white{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-gray-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-red-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-yellow-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-green-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-blue-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-indigo-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-purple-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-50{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-100{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-200{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-300{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-400{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-500{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-600{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-700{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-800{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-pink-900{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-qipGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:bg-portalGreen{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-transparent:focus-within{
    background-color: transparent !important;
  }

  .\32xl\:focus-within\:bg-current:focus-within{
    background-color: currentColor !important;
  }

  .\32xl\:focus-within\:bg-black:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-white:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-gray-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-red-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-yellow-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-green-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-blue-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-indigo-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-purple-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-50:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-100:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-200:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-300:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-400:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-500:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-600:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-700:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-800:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-pink-900:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-qipGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus-within\:bg-portalGreen:focus-within{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-transparent:hover{
    background-color: transparent !important;
  }

  .\32xl\:hover\:bg-current:hover{
    background-color: currentColor !important;
  }

  .\32xl\:hover\:bg-black:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-white:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-gray-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-red-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-yellow-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-green-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-blue-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-indigo-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-purple-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-50:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-100:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-200:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-300:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-400:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-500:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-600:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-700:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-800:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-pink-900:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-qipGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:hover\:bg-portalGreen:hover{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-transparent:focus{
    background-color: transparent !important;
  }

  .\32xl\:focus\:bg-current:focus{
    background-color: currentColor !important;
  }

  .\32xl\:focus\:bg-black:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-white:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-gray-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-red-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-yellow-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-green-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-blue-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-indigo-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-purple-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-50:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-100:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-200:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-300:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-400:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-500:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-600:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-700:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-800:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-pink-900:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-qipGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(139, 195, 52, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:focus\:bg-portalGreen:focus{
    --tw-bg-opacity: 1 !important;
    background-color: rgba(138, 192, 74, var(--tw-bg-opacity)) !important;
  }

  .\32xl\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-0{
    --tw-bg-opacity: 0 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-5{
    --tw-bg-opacity: 0.05 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-10{
    --tw-bg-opacity: 0.1 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-20{
    --tw-bg-opacity: 0.2 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-25{
    --tw-bg-opacity: 0.25 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-30{
    --tw-bg-opacity: 0.3 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-40{
    --tw-bg-opacity: 0.4 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-50{
    --tw-bg-opacity: 0.5 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-60{
    --tw-bg-opacity: 0.6 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-70{
    --tw-bg-opacity: 0.7 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-75{
    --tw-bg-opacity: 0.75 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-80{
    --tw-bg-opacity: 0.8 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-90{
    --tw-bg-opacity: 0.9 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-95{
    --tw-bg-opacity: 0.95 !important;
  }

  .group:hover .\32xl\:group-hover\:bg-opacity-100{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:focus-within\:bg-opacity-0:focus-within{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:focus-within\:bg-opacity-5:focus-within{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:bg-opacity-10:focus-within{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:bg-opacity-20:focus-within{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:bg-opacity-25:focus-within{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:bg-opacity-30:focus-within{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:bg-opacity-40:focus-within{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:bg-opacity-50:focus-within{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:bg-opacity-60:focus-within{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:bg-opacity-70:focus-within{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:bg-opacity-75:focus-within{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:bg-opacity-80:focus-within{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:bg-opacity-90:focus-within{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:bg-opacity-95:focus-within{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:bg-opacity-100:focus-within{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:hover\:bg-opacity-0:hover{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:hover\:bg-opacity-5:hover{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:hover\:bg-opacity-10:hover{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:hover\:bg-opacity-20:hover{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:hover\:bg-opacity-25:hover{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:hover\:bg-opacity-30:hover{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:hover\:bg-opacity-40:hover{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:hover\:bg-opacity-50:hover{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:hover\:bg-opacity-60:hover{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:hover\:bg-opacity-70:hover{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:hover\:bg-opacity-75:hover{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:hover\:bg-opacity-80:hover{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:hover\:bg-opacity-90:hover{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:hover\:bg-opacity-95:hover{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:hover\:bg-opacity-100:hover{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:focus\:bg-opacity-0:focus{
    --tw-bg-opacity: 0 !important;
  }

  .\32xl\:focus\:bg-opacity-5:focus{
    --tw-bg-opacity: 0.05 !important;
  }

  .\32xl\:focus\:bg-opacity-10:focus{
    --tw-bg-opacity: 0.1 !important;
  }

  .\32xl\:focus\:bg-opacity-20:focus{
    --tw-bg-opacity: 0.2 !important;
  }

  .\32xl\:focus\:bg-opacity-25:focus{
    --tw-bg-opacity: 0.25 !important;
  }

  .\32xl\:focus\:bg-opacity-30:focus{
    --tw-bg-opacity: 0.3 !important;
  }

  .\32xl\:focus\:bg-opacity-40:focus{
    --tw-bg-opacity: 0.4 !important;
  }

  .\32xl\:focus\:bg-opacity-50:focus{
    --tw-bg-opacity: 0.5 !important;
  }

  .\32xl\:focus\:bg-opacity-60:focus{
    --tw-bg-opacity: 0.6 !important;
  }

  .\32xl\:focus\:bg-opacity-70:focus{
    --tw-bg-opacity: 0.7 !important;
  }

  .\32xl\:focus\:bg-opacity-75:focus{
    --tw-bg-opacity: 0.75 !important;
  }

  .\32xl\:focus\:bg-opacity-80:focus{
    --tw-bg-opacity: 0.8 !important;
  }

  .\32xl\:focus\:bg-opacity-90:focus{
    --tw-bg-opacity: 0.9 !important;
  }

  .\32xl\:focus\:bg-opacity-95:focus{
    --tw-bg-opacity: 0.95 !important;
  }

  .\32xl\:focus\:bg-opacity-100:focus{
    --tw-bg-opacity: 1 !important;
  }

  .\32xl\:bg-none{
    background-image: none !important;
  }

  .\32xl\:bg-gradient-to-t{
    background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-tr{
    background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-b{
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-bl{
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-l{
    background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:bg-gradient-to-tl{
    background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
  }

  .\32xl\:from-transparent{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:from-current{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:from-black{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:from-white{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:from-gray-50{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:from-gray-100{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:from-gray-200{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:from-gray-300{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:from-gray-400{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:from-gray-500{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:from-gray-600{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:from-gray-700{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:from-gray-800{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:from-gray-900{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:from-red-50{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:from-red-100{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:from-red-200{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:from-red-300{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:from-red-400{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:from-red-500{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:from-red-600{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:from-red-700{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:from-red-800{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:from-red-900{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:from-yellow-50{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:from-yellow-100{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:from-yellow-200{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:from-yellow-300{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:from-yellow-400{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:from-yellow-500{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:from-yellow-600{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:from-yellow-700{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:from-yellow-800{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:from-yellow-900{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:from-green-50{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:from-green-100{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:from-green-200{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:from-green-300{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:from-green-400{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:from-green-500{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:from-green-600{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:from-green-700{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:from-green-800{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:from-green-900{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:from-blue-50{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:from-blue-100{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:from-blue-200{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:from-blue-300{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:from-blue-400{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:from-blue-500{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:from-blue-600{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:from-blue-700{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:from-blue-800{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:from-blue-900{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:from-indigo-50{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:from-indigo-100{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:from-indigo-200{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:from-indigo-300{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:from-indigo-400{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:from-indigo-500{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:from-indigo-600{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:from-indigo-700{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:from-indigo-800{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:from-indigo-900{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:from-purple-50{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:from-purple-100{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:from-purple-200{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:from-purple-300{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:from-purple-400{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:from-purple-500{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:from-purple-600{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:from-purple-700{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:from-purple-800{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:from-purple-900{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:from-pink-50{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:from-pink-100{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:from-pink-200{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:from-pink-300{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:from-pink-400{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:from-pink-500{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:from-pink-600{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:from-pink-700{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:from-pink-800{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:from-pink-900{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:from-qipGreen{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .\32xl\:from-portalGreen{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .\32xl\:hover\:from-transparent:hover{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:from-current:hover{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:from-black:hover{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:from-white:hover{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:from-gray-50:hover{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:hover\:from-gray-100:hover{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:hover\:from-gray-200:hover{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:hover\:from-gray-300:hover{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:hover\:from-gray-400:hover{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:hover\:from-gray-500:hover{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:hover\:from-gray-600:hover{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:hover\:from-gray-700:hover{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:hover\:from-gray-800:hover{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:hover\:from-gray-900:hover{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:hover\:from-red-50:hover{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:hover\:from-red-100:hover{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:hover\:from-red-200:hover{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:hover\:from-red-300:hover{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:hover\:from-red-400:hover{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:hover\:from-red-500:hover{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:hover\:from-red-600:hover{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:hover\:from-red-700:hover{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:hover\:from-red-800:hover{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:hover\:from-red-900:hover{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-50:hover{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-100:hover{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-200:hover{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-300:hover{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-400:hover{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-500:hover{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-600:hover{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-700:hover{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-800:hover{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:hover\:from-yellow-900:hover{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:hover\:from-green-50:hover{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:hover\:from-green-100:hover{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:hover\:from-green-200:hover{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:hover\:from-green-300:hover{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:hover\:from-green-400:hover{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:hover\:from-green-500:hover{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:hover\:from-green-600:hover{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:hover\:from-green-700:hover{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:hover\:from-green-800:hover{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:hover\:from-green-900:hover{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:hover\:from-blue-50:hover{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:hover\:from-blue-100:hover{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:hover\:from-blue-200:hover{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:hover\:from-blue-300:hover{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:hover\:from-blue-400:hover{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:hover\:from-blue-500:hover{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:hover\:from-blue-600:hover{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:hover\:from-blue-700:hover{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:hover\:from-blue-800:hover{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:hover\:from-blue-900:hover{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-50:hover{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-100:hover{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-200:hover{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-300:hover{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-400:hover{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-500:hover{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-600:hover{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-700:hover{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-800:hover{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:hover\:from-indigo-900:hover{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:hover\:from-purple-50:hover{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:hover\:from-purple-100:hover{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:hover\:from-purple-200:hover{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:hover\:from-purple-300:hover{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:hover\:from-purple-400:hover{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:hover\:from-purple-500:hover{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:hover\:from-purple-600:hover{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:hover\:from-purple-700:hover{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:hover\:from-purple-800:hover{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:hover\:from-purple-900:hover{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:hover\:from-pink-50:hover{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:hover\:from-pink-100:hover{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:hover\:from-pink-200:hover{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:hover\:from-pink-300:hover{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:hover\:from-pink-400:hover{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:hover\:from-pink-500:hover{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:hover\:from-pink-600:hover{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:hover\:from-pink-700:hover{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:hover\:from-pink-800:hover{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:hover\:from-pink-900:hover{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:hover\:from-qipGreen:hover{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .\32xl\:hover\:from-portalGreen:hover{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .\32xl\:focus\:from-transparent:focus{
    --tw-gradient-from: transparent !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:from-current:focus{
    --tw-gradient-from: currentColor !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:from-black:focus{
    --tw-gradient-from: #000 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:from-white:focus{
    --tw-gradient-from: #fff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:from-gray-50:focus{
    --tw-gradient-from: #f9fafb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:focus\:from-gray-100:focus{
    --tw-gradient-from: #f3f4f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:focus\:from-gray-200:focus{
    --tw-gradient-from: #e5e7eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:focus\:from-gray-300:focus{
    --tw-gradient-from: #d1d5db !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:focus\:from-gray-400:focus{
    --tw-gradient-from: #9ca3af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:focus\:from-gray-500:focus{
    --tw-gradient-from: #6b7280 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:focus\:from-gray-600:focus{
    --tw-gradient-from: #4b5563 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:focus\:from-gray-700:focus{
    --tw-gradient-from: #374151 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:focus\:from-gray-800:focus{
    --tw-gradient-from: #1f2937 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:focus\:from-gray-900:focus{
    --tw-gradient-from: #111827 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:focus\:from-red-50:focus{
    --tw-gradient-from: #fef2f2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:focus\:from-red-100:focus{
    --tw-gradient-from: #fee2e2 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:focus\:from-red-200:focus{
    --tw-gradient-from: #fecaca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:focus\:from-red-300:focus{
    --tw-gradient-from: #fca5a5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:focus\:from-red-400:focus{
    --tw-gradient-from: #f87171 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:focus\:from-red-500:focus{
    --tw-gradient-from: #ef4444 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:focus\:from-red-600:focus{
    --tw-gradient-from: #dc2626 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:focus\:from-red-700:focus{
    --tw-gradient-from: #b91c1c !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:focus\:from-red-800:focus{
    --tw-gradient-from: #991b1b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:focus\:from-red-900:focus{
    --tw-gradient-from: #7f1d1d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-50:focus{
    --tw-gradient-from: #fffbeb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-100:focus{
    --tw-gradient-from: #fef3c7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-200:focus{
    --tw-gradient-from: #fde68a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-300:focus{
    --tw-gradient-from: #fcd34d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-400:focus{
    --tw-gradient-from: #fbbf24 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-500:focus{
    --tw-gradient-from: #f59e0b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-600:focus{
    --tw-gradient-from: #d97706 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-700:focus{
    --tw-gradient-from: #b45309 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-800:focus{
    --tw-gradient-from: #92400e !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:focus\:from-yellow-900:focus{
    --tw-gradient-from: #78350f !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:focus\:from-green-50:focus{
    --tw-gradient-from: #ecfdf5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:focus\:from-green-100:focus{
    --tw-gradient-from: #d1fae5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:focus\:from-green-200:focus{
    --tw-gradient-from: #a7f3d0 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:focus\:from-green-300:focus{
    --tw-gradient-from: #6ee7b7 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:focus\:from-green-400:focus{
    --tw-gradient-from: #34d399 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:focus\:from-green-500:focus{
    --tw-gradient-from: #10b981 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:focus\:from-green-600:focus{
    --tw-gradient-from: #059669 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:focus\:from-green-700:focus{
    --tw-gradient-from: #047857 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:focus\:from-green-800:focus{
    --tw-gradient-from: #065f46 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:focus\:from-green-900:focus{
    --tw-gradient-from: #064e3b !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:focus\:from-blue-50:focus{
    --tw-gradient-from: #eff6ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:focus\:from-blue-100:focus{
    --tw-gradient-from: #dbeafe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:focus\:from-blue-200:focus{
    --tw-gradient-from: #bfdbfe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:focus\:from-blue-300:focus{
    --tw-gradient-from: #93c5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:focus\:from-blue-400:focus{
    --tw-gradient-from: #60a5fa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:focus\:from-blue-500:focus{
    --tw-gradient-from: #3b82f6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:focus\:from-blue-600:focus{
    --tw-gradient-from: #2563eb !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:focus\:from-blue-700:focus{
    --tw-gradient-from: #1d4ed8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:focus\:from-blue-800:focus{
    --tw-gradient-from: #1e40af !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:focus\:from-blue-900:focus{
    --tw-gradient-from: #1e3a8a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-50:focus{
    --tw-gradient-from: #eef2ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-100:focus{
    --tw-gradient-from: #e0e7ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-200:focus{
    --tw-gradient-from: #c7d2fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-300:focus{
    --tw-gradient-from: #a5b4fc !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-400:focus{
    --tw-gradient-from: #818cf8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-500:focus{
    --tw-gradient-from: #6366f1 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-600:focus{
    --tw-gradient-from: #4f46e5 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-700:focus{
    --tw-gradient-from: #4338ca !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-800:focus{
    --tw-gradient-from: #3730a3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:focus\:from-indigo-900:focus{
    --tw-gradient-from: #312e81 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:focus\:from-purple-50:focus{
    --tw-gradient-from: #f5f3ff !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:focus\:from-purple-100:focus{
    --tw-gradient-from: #ede9fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:focus\:from-purple-200:focus{
    --tw-gradient-from: #ddd6fe !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:focus\:from-purple-300:focus{
    --tw-gradient-from: #c4b5fd !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:focus\:from-purple-400:focus{
    --tw-gradient-from: #a78bfa !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:focus\:from-purple-500:focus{
    --tw-gradient-from: #8b5cf6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:focus\:from-purple-600:focus{
    --tw-gradient-from: #7c3aed !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:focus\:from-purple-700:focus{
    --tw-gradient-from: #6d28d9 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:focus\:from-purple-800:focus{
    --tw-gradient-from: #5b21b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:focus\:from-purple-900:focus{
    --tw-gradient-from: #4c1d95 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:focus\:from-pink-50:focus{
    --tw-gradient-from: #fdf2f8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:focus\:from-pink-100:focus{
    --tw-gradient-from: #fce7f3 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:focus\:from-pink-200:focus{
    --tw-gradient-from: #fbcfe8 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:focus\:from-pink-300:focus{
    --tw-gradient-from: #f9a8d4 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:focus\:from-pink-400:focus{
    --tw-gradient-from: #f472b6 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:focus\:from-pink-500:focus{
    --tw-gradient-from: #ec4899 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:focus\:from-pink-600:focus{
    --tw-gradient-from: #db2777 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:focus\:from-pink-700:focus{
    --tw-gradient-from: #be185d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:focus\:from-pink-800:focus{
    --tw-gradient-from: #9d174d !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:focus\:from-pink-900:focus{
    --tw-gradient-from: #831843 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:focus\:from-qipGreen:focus{
    --tw-gradient-from: #8bc334 !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .\32xl\:focus\:from-portalGreen:focus{
    --tw-gradient-from: #8ac04a !important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .\32xl\:via-transparent{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:via-current{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:via-black{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:via-white{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:via-gray-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:via-gray-100{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:via-gray-200{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:via-gray-300{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:via-gray-400{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:via-gray-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:via-gray-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:via-gray-700{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:via-gray-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:via-gray-900{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:via-red-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:via-red-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:via-red-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:via-red-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:via-red-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:via-red-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:via-red-600{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:via-red-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:via-red-800{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:via-red-900{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:via-yellow-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:via-yellow-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:via-yellow-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:via-yellow-300{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:via-yellow-400{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:via-yellow-500{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:via-yellow-600{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:via-yellow-700{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:via-yellow-800{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:via-yellow-900{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:via-green-50{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:via-green-100{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:via-green-200{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:via-green-300{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:via-green-400{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:via-green-500{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:via-green-600{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:via-green-700{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:via-green-800{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:via-green-900{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:via-blue-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:via-blue-100{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:via-blue-200{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:via-blue-300{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:via-blue-400{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:via-blue-500{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:via-blue-600{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:via-blue-700{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:via-blue-800{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:via-blue-900{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:via-indigo-50{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:via-indigo-100{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:via-indigo-200{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:via-indigo-300{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:via-indigo-400{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:via-indigo-500{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:via-indigo-600{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:via-indigo-700{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:via-indigo-800{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:via-indigo-900{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:via-purple-50{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:via-purple-100{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:via-purple-200{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:via-purple-300{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:via-purple-400{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:via-purple-500{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:via-purple-600{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:via-purple-700{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:via-purple-800{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:via-purple-900{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:via-pink-50{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:via-pink-100{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:via-pink-200{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:via-pink-300{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:via-pink-400{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:via-pink-500{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:via-pink-600{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:via-pink-700{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:via-pink-800{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:via-pink-900{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:via-qipGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .\32xl\:via-portalGreen{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .\32xl\:hover\:via-transparent:hover{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:via-current:hover{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:via-black:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:hover\:via-white:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:hover\:via-gray-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:hover\:via-gray-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:hover\:via-gray-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:hover\:via-gray-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:hover\:via-gray-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:hover\:via-gray-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:hover\:via-gray-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:hover\:via-gray-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:hover\:via-gray-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:hover\:via-gray-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:hover\:via-red-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:hover\:via-red-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:hover\:via-red-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:hover\:via-red-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:hover\:via-red-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:hover\:via-red-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:hover\:via-red-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:hover\:via-red-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:hover\:via-red-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:hover\:via-red-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:hover\:via-yellow-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:hover\:via-green-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:hover\:via-green-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:hover\:via-green-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:hover\:via-green-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:hover\:via-green-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:hover\:via-green-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:hover\:via-green-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:hover\:via-green-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:hover\:via-green-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:hover\:via-green-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:hover\:via-blue-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:hover\:via-blue-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:hover\:via-blue-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:hover\:via-blue-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:hover\:via-blue-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:hover\:via-blue-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:hover\:via-blue-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:hover\:via-blue-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:hover\:via-blue-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:hover\:via-blue-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:hover\:via-indigo-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:hover\:via-purple-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:hover\:via-purple-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:hover\:via-purple-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:hover\:via-purple-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:hover\:via-purple-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:hover\:via-purple-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:hover\:via-purple-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:hover\:via-purple-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:hover\:via-purple-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:hover\:via-purple-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:hover\:via-pink-50:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:hover\:via-pink-100:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:hover\:via-pink-200:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:hover\:via-pink-300:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:hover\:via-pink-400:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:hover\:via-pink-500:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:hover\:via-pink-600:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:hover\:via-pink-700:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:hover\:via-pink-800:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:hover\:via-pink-900:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:hover\:via-qipGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .\32xl\:hover\:via-portalGreen:hover{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .\32xl\:focus\:via-transparent:focus{
    --tw-gradient-stops: var(--tw-gradient-from), transparent, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:via-current:focus{
    --tw-gradient-stops: var(--tw-gradient-from), currentColor, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:via-black:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #000, var(--tw-gradient-to, rgba(0, 0, 0, 0)) !important;
  }

  .\32xl\:focus\:via-white:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to, rgba(255, 255, 255, 0)) !important;
  }

  .\32xl\:focus\:via-gray-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb, var(--tw-gradient-to, rgba(249, 250, 251, 0)) !important;
  }

  .\32xl\:focus\:via-gray-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6, var(--tw-gradient-to, rgba(243, 244, 246, 0)) !important;
  }

  .\32xl\:focus\:via-gray-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb, var(--tw-gradient-to, rgba(229, 231, 235, 0)) !important;
  }

  .\32xl\:focus\:via-gray-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db, var(--tw-gradient-to, rgba(209, 213, 219, 0)) !important;
  }

  .\32xl\:focus\:via-gray-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af, var(--tw-gradient-to, rgba(156, 163, 175, 0)) !important;
  }

  .\32xl\:focus\:via-gray-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280, var(--tw-gradient-to, rgba(107, 114, 128, 0)) !important;
  }

  .\32xl\:focus\:via-gray-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563, var(--tw-gradient-to, rgba(75, 85, 99, 0)) !important;
  }

  .\32xl\:focus\:via-gray-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #374151, var(--tw-gradient-to, rgba(55, 65, 81, 0)) !important;
  }

  .\32xl\:focus\:via-gray-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937, var(--tw-gradient-to, rgba(31, 41, 55, 0)) !important;
  }

  .\32xl\:focus\:via-gray-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #111827, var(--tw-gradient-to, rgba(17, 24, 39, 0)) !important;
  }

  .\32xl\:focus\:via-red-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2, var(--tw-gradient-to, rgba(254, 242, 242, 0)) !important;
  }

  .\32xl\:focus\:via-red-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2, var(--tw-gradient-to, rgba(254, 226, 226, 0)) !important;
  }

  .\32xl\:focus\:via-red-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca, var(--tw-gradient-to, rgba(254, 202, 202, 0)) !important;
  }

  .\32xl\:focus\:via-red-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5, var(--tw-gradient-to, rgba(252, 165, 165, 0)) !important;
  }

  .\32xl\:focus\:via-red-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f87171, var(--tw-gradient-to, rgba(248, 113, 113, 0)) !important;
  }

  .\32xl\:focus\:via-red-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444, var(--tw-gradient-to, rgba(239, 68, 68, 0)) !important;
  }

  .\32xl\:focus\:via-red-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626, var(--tw-gradient-to, rgba(220, 38, 38, 0)) !important;
  }

  .\32xl\:focus\:via-red-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c, var(--tw-gradient-to, rgba(185, 28, 28, 0)) !important;
  }

  .\32xl\:focus\:via-red-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b, var(--tw-gradient-to, rgba(153, 27, 27, 0)) !important;
  }

  .\32xl\:focus\:via-red-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d, var(--tw-gradient-to, rgba(127, 29, 29, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb, var(--tw-gradient-to, rgba(255, 251, 235, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7, var(--tw-gradient-to, rgba(254, 243, 199, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a, var(--tw-gradient-to, rgba(253, 230, 138, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d, var(--tw-gradient-to, rgba(252, 211, 77, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24, var(--tw-gradient-to, rgba(251, 191, 36, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b, var(--tw-gradient-to, rgba(245, 158, 11, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d97706, var(--tw-gradient-to, rgba(217, 119, 6, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #b45309, var(--tw-gradient-to, rgba(180, 83, 9, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #92400e, var(--tw-gradient-to, rgba(146, 64, 14, 0)) !important;
  }

  .\32xl\:focus\:via-yellow-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #78350f, var(--tw-gradient-to, rgba(120, 53, 15, 0)) !important;
  }

  .\32xl\:focus\:via-green-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5, var(--tw-gradient-to, rgba(236, 253, 245, 0)) !important;
  }

  .\32xl\:focus\:via-green-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5, var(--tw-gradient-to, rgba(209, 250, 229, 0)) !important;
  }

  .\32xl\:focus\:via-green-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0, var(--tw-gradient-to, rgba(167, 243, 208, 0)) !important;
  }

  .\32xl\:focus\:via-green-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7, var(--tw-gradient-to, rgba(110, 231, 183, 0)) !important;
  }

  .\32xl\:focus\:via-green-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #34d399, var(--tw-gradient-to, rgba(52, 211, 153, 0)) !important;
  }

  .\32xl\:focus\:via-green-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #10b981, var(--tw-gradient-to, rgba(16, 185, 129, 0)) !important;
  }

  .\32xl\:focus\:via-green-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #059669, var(--tw-gradient-to, rgba(5, 150, 105, 0)) !important;
  }

  .\32xl\:focus\:via-green-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #047857, var(--tw-gradient-to, rgba(4, 120, 87, 0)) !important;
  }

  .\32xl\:focus\:via-green-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #065f46, var(--tw-gradient-to, rgba(6, 95, 70, 0)) !important;
  }

  .\32xl\:focus\:via-green-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b, var(--tw-gradient-to, rgba(6, 78, 59, 0)) !important;
  }

  .\32xl\:focus\:via-blue-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff, var(--tw-gradient-to, rgba(239, 246, 255, 0)) !important;
  }

  .\32xl\:focus\:via-blue-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe, var(--tw-gradient-to, rgba(219, 234, 254, 0)) !important;
  }

  .\32xl\:focus\:via-blue-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe, var(--tw-gradient-to, rgba(191, 219, 254, 0)) !important;
  }

  .\32xl\:focus\:via-blue-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd, var(--tw-gradient-to, rgba(147, 197, 253, 0)) !important;
  }

  .\32xl\:focus\:via-blue-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa, var(--tw-gradient-to, rgba(96, 165, 250, 0)) !important;
  }

  .\32xl\:focus\:via-blue-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6, var(--tw-gradient-to, rgba(59, 130, 246, 0)) !important;
  }

  .\32xl\:focus\:via-blue-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb, var(--tw-gradient-to, rgba(37, 99, 235, 0)) !important;
  }

  .\32xl\:focus\:via-blue-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8, var(--tw-gradient-to, rgba(29, 78, 216, 0)) !important;
  }

  .\32xl\:focus\:via-blue-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af, var(--tw-gradient-to, rgba(30, 64, 175, 0)) !important;
  }

  .\32xl\:focus\:via-blue-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a, var(--tw-gradient-to, rgba(30, 58, 138, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff, var(--tw-gradient-to, rgba(238, 242, 255, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff, var(--tw-gradient-to, rgba(224, 231, 255, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe, var(--tw-gradient-to, rgba(199, 210, 254, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc, var(--tw-gradient-to, rgba(165, 180, 252, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8, var(--tw-gradient-to, rgba(129, 140, 248, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1, var(--tw-gradient-to, rgba(99, 102, 241, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5, var(--tw-gradient-to, rgba(79, 70, 229, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca, var(--tw-gradient-to, rgba(67, 56, 202, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3, var(--tw-gradient-to, rgba(55, 48, 163, 0)) !important;
  }

  .\32xl\:focus\:via-indigo-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #312e81, var(--tw-gradient-to, rgba(49, 46, 129, 0)) !important;
  }

  .\32xl\:focus\:via-purple-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff, var(--tw-gradient-to, rgba(245, 243, 255, 0)) !important;
  }

  .\32xl\:focus\:via-purple-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe, var(--tw-gradient-to, rgba(237, 233, 254, 0)) !important;
  }

  .\32xl\:focus\:via-purple-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe, var(--tw-gradient-to, rgba(221, 214, 254, 0)) !important;
  }

  .\32xl\:focus\:via-purple-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd, var(--tw-gradient-to, rgba(196, 181, 253, 0)) !important;
  }

  .\32xl\:focus\:via-purple-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa, var(--tw-gradient-to, rgba(167, 139, 250, 0)) !important;
  }

  .\32xl\:focus\:via-purple-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6, var(--tw-gradient-to, rgba(139, 92, 246, 0)) !important;
  }

  .\32xl\:focus\:via-purple-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed, var(--tw-gradient-to, rgba(124, 58, 237, 0)) !important;
  }

  .\32xl\:focus\:via-purple-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9, var(--tw-gradient-to, rgba(109, 40, 217, 0)) !important;
  }

  .\32xl\:focus\:via-purple-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6, var(--tw-gradient-to, rgba(91, 33, 182, 0)) !important;
  }

  .\32xl\:focus\:via-purple-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95, var(--tw-gradient-to, rgba(76, 29, 149, 0)) !important;
  }

  .\32xl\:focus\:via-pink-50:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8, var(--tw-gradient-to, rgba(253, 242, 248, 0)) !important;
  }

  .\32xl\:focus\:via-pink-100:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3, var(--tw-gradient-to, rgba(252, 231, 243, 0)) !important;
  }

  .\32xl\:focus\:via-pink-200:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8, var(--tw-gradient-to, rgba(251, 207, 232, 0)) !important;
  }

  .\32xl\:focus\:via-pink-300:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4, var(--tw-gradient-to, rgba(249, 168, 212, 0)) !important;
  }

  .\32xl\:focus\:via-pink-400:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6, var(--tw-gradient-to, rgba(244, 114, 182, 0)) !important;
  }

  .\32xl\:focus\:via-pink-500:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899, var(--tw-gradient-to, rgba(236, 72, 153, 0)) !important;
  }

  .\32xl\:focus\:via-pink-600:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #db2777, var(--tw-gradient-to, rgba(219, 39, 119, 0)) !important;
  }

  .\32xl\:focus\:via-pink-700:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #be185d, var(--tw-gradient-to, rgba(190, 24, 93, 0)) !important;
  }

  .\32xl\:focus\:via-pink-800:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d, var(--tw-gradient-to, rgba(157, 23, 77, 0)) !important;
  }

  .\32xl\:focus\:via-pink-900:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #831843, var(--tw-gradient-to, rgba(131, 24, 67, 0)) !important;
  }

  .\32xl\:focus\:via-qipGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8bc334, var(--tw-gradient-to, rgba(139, 195, 52, 0)) !important;
  }

  .\32xl\:focus\:via-portalGreen:focus{
    --tw-gradient-stops: var(--tw-gradient-from), #8ac04a, var(--tw-gradient-to, rgba(138, 192, 74, 0)) !important;
  }

  .\32xl\:to-transparent{
    --tw-gradient-to: transparent !important;
  }

  .\32xl\:to-current{
    --tw-gradient-to: currentColor !important;
  }

  .\32xl\:to-black{
    --tw-gradient-to: #000 !important;
  }

  .\32xl\:to-white{
    --tw-gradient-to: #fff !important;
  }

  .\32xl\:to-gray-50{
    --tw-gradient-to: #f9fafb !important;
  }

  .\32xl\:to-gray-100{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .\32xl\:to-gray-200{
    --tw-gradient-to: #e5e7eb !important;
  }

  .\32xl\:to-gray-300{
    --tw-gradient-to: #d1d5db !important;
  }

  .\32xl\:to-gray-400{
    --tw-gradient-to: #9ca3af !important;
  }

  .\32xl\:to-gray-500{
    --tw-gradient-to: #6b7280 !important;
  }

  .\32xl\:to-gray-600{
    --tw-gradient-to: #4b5563 !important;
  }

  .\32xl\:to-gray-700{
    --tw-gradient-to: #374151 !important;
  }

  .\32xl\:to-gray-800{
    --tw-gradient-to: #1f2937 !important;
  }

  .\32xl\:to-gray-900{
    --tw-gradient-to: #111827 !important;
  }

  .\32xl\:to-red-50{
    --tw-gradient-to: #fef2f2 !important;
  }

  .\32xl\:to-red-100{
    --tw-gradient-to: #fee2e2 !important;
  }

  .\32xl\:to-red-200{
    --tw-gradient-to: #fecaca !important;
  }

  .\32xl\:to-red-300{
    --tw-gradient-to: #fca5a5 !important;
  }

  .\32xl\:to-red-400{
    --tw-gradient-to: #f87171 !important;
  }

  .\32xl\:to-red-500{
    --tw-gradient-to: #ef4444 !important;
  }

  .\32xl\:to-red-600{
    --tw-gradient-to: #dc2626 !important;
  }

  .\32xl\:to-red-700{
    --tw-gradient-to: #b91c1c !important;
  }

  .\32xl\:to-red-800{
    --tw-gradient-to: #991b1b !important;
  }

  .\32xl\:to-red-900{
    --tw-gradient-to: #7f1d1d !important;
  }

  .\32xl\:to-yellow-50{
    --tw-gradient-to: #fffbeb !important;
  }

  .\32xl\:to-yellow-100{
    --tw-gradient-to: #fef3c7 !important;
  }

  .\32xl\:to-yellow-200{
    --tw-gradient-to: #fde68a !important;
  }

  .\32xl\:to-yellow-300{
    --tw-gradient-to: #fcd34d !important;
  }

  .\32xl\:to-yellow-400{
    --tw-gradient-to: #fbbf24 !important;
  }

  .\32xl\:to-yellow-500{
    --tw-gradient-to: #f59e0b !important;
  }

  .\32xl\:to-yellow-600{
    --tw-gradient-to: #d97706 !important;
  }

  .\32xl\:to-yellow-700{
    --tw-gradient-to: #b45309 !important;
  }

  .\32xl\:to-yellow-800{
    --tw-gradient-to: #92400e !important;
  }

  .\32xl\:to-yellow-900{
    --tw-gradient-to: #78350f !important;
  }

  .\32xl\:to-green-50{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .\32xl\:to-green-100{
    --tw-gradient-to: #d1fae5 !important;
  }

  .\32xl\:to-green-200{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .\32xl\:to-green-300{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .\32xl\:to-green-400{
    --tw-gradient-to: #34d399 !important;
  }

  .\32xl\:to-green-500{
    --tw-gradient-to: #10b981 !important;
  }

  .\32xl\:to-green-600{
    --tw-gradient-to: #059669 !important;
  }

  .\32xl\:to-green-700{
    --tw-gradient-to: #047857 !important;
  }

  .\32xl\:to-green-800{
    --tw-gradient-to: #065f46 !important;
  }

  .\32xl\:to-green-900{
    --tw-gradient-to: #064e3b !important;
  }

  .\32xl\:to-blue-50{
    --tw-gradient-to: #eff6ff !important;
  }

  .\32xl\:to-blue-100{
    --tw-gradient-to: #dbeafe !important;
  }

  .\32xl\:to-blue-200{
    --tw-gradient-to: #bfdbfe !important;
  }

  .\32xl\:to-blue-300{
    --tw-gradient-to: #93c5fd !important;
  }

  .\32xl\:to-blue-400{
    --tw-gradient-to: #60a5fa !important;
  }

  .\32xl\:to-blue-500{
    --tw-gradient-to: #3b82f6 !important;
  }

  .\32xl\:to-blue-600{
    --tw-gradient-to: #2563eb !important;
  }

  .\32xl\:to-blue-700{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .\32xl\:to-blue-800{
    --tw-gradient-to: #1e40af !important;
  }

  .\32xl\:to-blue-900{
    --tw-gradient-to: #1e3a8a !important;
  }

  .\32xl\:to-indigo-50{
    --tw-gradient-to: #eef2ff !important;
  }

  .\32xl\:to-indigo-100{
    --tw-gradient-to: #e0e7ff !important;
  }

  .\32xl\:to-indigo-200{
    --tw-gradient-to: #c7d2fe !important;
  }

  .\32xl\:to-indigo-300{
    --tw-gradient-to: #a5b4fc !important;
  }

  .\32xl\:to-indigo-400{
    --tw-gradient-to: #818cf8 !important;
  }

  .\32xl\:to-indigo-500{
    --tw-gradient-to: #6366f1 !important;
  }

  .\32xl\:to-indigo-600{
    --tw-gradient-to: #4f46e5 !important;
  }

  .\32xl\:to-indigo-700{
    --tw-gradient-to: #4338ca !important;
  }

  .\32xl\:to-indigo-800{
    --tw-gradient-to: #3730a3 !important;
  }

  .\32xl\:to-indigo-900{
    --tw-gradient-to: #312e81 !important;
  }

  .\32xl\:to-purple-50{
    --tw-gradient-to: #f5f3ff !important;
  }

  .\32xl\:to-purple-100{
    --tw-gradient-to: #ede9fe !important;
  }

  .\32xl\:to-purple-200{
    --tw-gradient-to: #ddd6fe !important;
  }

  .\32xl\:to-purple-300{
    --tw-gradient-to: #c4b5fd !important;
  }

  .\32xl\:to-purple-400{
    --tw-gradient-to: #a78bfa !important;
  }

  .\32xl\:to-purple-500{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .\32xl\:to-purple-600{
    --tw-gradient-to: #7c3aed !important;
  }

  .\32xl\:to-purple-700{
    --tw-gradient-to: #6d28d9 !important;
  }

  .\32xl\:to-purple-800{
    --tw-gradient-to: #5b21b6 !important;
  }

  .\32xl\:to-purple-900{
    --tw-gradient-to: #4c1d95 !important;
  }

  .\32xl\:to-pink-50{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .\32xl\:to-pink-100{
    --tw-gradient-to: #fce7f3 !important;
  }

  .\32xl\:to-pink-200{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .\32xl\:to-pink-300{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .\32xl\:to-pink-400{
    --tw-gradient-to: #f472b6 !important;
  }

  .\32xl\:to-pink-500{
    --tw-gradient-to: #ec4899 !important;
  }

  .\32xl\:to-pink-600{
    --tw-gradient-to: #db2777 !important;
  }

  .\32xl\:to-pink-700{
    --tw-gradient-to: #be185d !important;
  }

  .\32xl\:to-pink-800{
    --tw-gradient-to: #9d174d !important;
  }

  .\32xl\:to-pink-900{
    --tw-gradient-to: #831843 !important;
  }

  .\32xl\:to-qipGreen{
    --tw-gradient-to: #8bc334 !important;
  }

  .\32xl\:to-portalGreen{
    --tw-gradient-to: #8ac04a !important;
  }

  .\32xl\:hover\:to-transparent:hover{
    --tw-gradient-to: transparent !important;
  }

  .\32xl\:hover\:to-current:hover{
    --tw-gradient-to: currentColor !important;
  }

  .\32xl\:hover\:to-black:hover{
    --tw-gradient-to: #000 !important;
  }

  .\32xl\:hover\:to-white:hover{
    --tw-gradient-to: #fff !important;
  }

  .\32xl\:hover\:to-gray-50:hover{
    --tw-gradient-to: #f9fafb !important;
  }

  .\32xl\:hover\:to-gray-100:hover{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .\32xl\:hover\:to-gray-200:hover{
    --tw-gradient-to: #e5e7eb !important;
  }

  .\32xl\:hover\:to-gray-300:hover{
    --tw-gradient-to: #d1d5db !important;
  }

  .\32xl\:hover\:to-gray-400:hover{
    --tw-gradient-to: #9ca3af !important;
  }

  .\32xl\:hover\:to-gray-500:hover{
    --tw-gradient-to: #6b7280 !important;
  }

  .\32xl\:hover\:to-gray-600:hover{
    --tw-gradient-to: #4b5563 !important;
  }

  .\32xl\:hover\:to-gray-700:hover{
    --tw-gradient-to: #374151 !important;
  }

  .\32xl\:hover\:to-gray-800:hover{
    --tw-gradient-to: #1f2937 !important;
  }

  .\32xl\:hover\:to-gray-900:hover{
    --tw-gradient-to: #111827 !important;
  }

  .\32xl\:hover\:to-red-50:hover{
    --tw-gradient-to: #fef2f2 !important;
  }

  .\32xl\:hover\:to-red-100:hover{
    --tw-gradient-to: #fee2e2 !important;
  }

  .\32xl\:hover\:to-red-200:hover{
    --tw-gradient-to: #fecaca !important;
  }

  .\32xl\:hover\:to-red-300:hover{
    --tw-gradient-to: #fca5a5 !important;
  }

  .\32xl\:hover\:to-red-400:hover{
    --tw-gradient-to: #f87171 !important;
  }

  .\32xl\:hover\:to-red-500:hover{
    --tw-gradient-to: #ef4444 !important;
  }

  .\32xl\:hover\:to-red-600:hover{
    --tw-gradient-to: #dc2626 !important;
  }

  .\32xl\:hover\:to-red-700:hover{
    --tw-gradient-to: #b91c1c !important;
  }

  .\32xl\:hover\:to-red-800:hover{
    --tw-gradient-to: #991b1b !important;
  }

  .\32xl\:hover\:to-red-900:hover{
    --tw-gradient-to: #7f1d1d !important;
  }

  .\32xl\:hover\:to-yellow-50:hover{
    --tw-gradient-to: #fffbeb !important;
  }

  .\32xl\:hover\:to-yellow-100:hover{
    --tw-gradient-to: #fef3c7 !important;
  }

  .\32xl\:hover\:to-yellow-200:hover{
    --tw-gradient-to: #fde68a !important;
  }

  .\32xl\:hover\:to-yellow-300:hover{
    --tw-gradient-to: #fcd34d !important;
  }

  .\32xl\:hover\:to-yellow-400:hover{
    --tw-gradient-to: #fbbf24 !important;
  }

  .\32xl\:hover\:to-yellow-500:hover{
    --tw-gradient-to: #f59e0b !important;
  }

  .\32xl\:hover\:to-yellow-600:hover{
    --tw-gradient-to: #d97706 !important;
  }

  .\32xl\:hover\:to-yellow-700:hover{
    --tw-gradient-to: #b45309 !important;
  }

  .\32xl\:hover\:to-yellow-800:hover{
    --tw-gradient-to: #92400e !important;
  }

  .\32xl\:hover\:to-yellow-900:hover{
    --tw-gradient-to: #78350f !important;
  }

  .\32xl\:hover\:to-green-50:hover{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .\32xl\:hover\:to-green-100:hover{
    --tw-gradient-to: #d1fae5 !important;
  }

  .\32xl\:hover\:to-green-200:hover{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .\32xl\:hover\:to-green-300:hover{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .\32xl\:hover\:to-green-400:hover{
    --tw-gradient-to: #34d399 !important;
  }

  .\32xl\:hover\:to-green-500:hover{
    --tw-gradient-to: #10b981 !important;
  }

  .\32xl\:hover\:to-green-600:hover{
    --tw-gradient-to: #059669 !important;
  }

  .\32xl\:hover\:to-green-700:hover{
    --tw-gradient-to: #047857 !important;
  }

  .\32xl\:hover\:to-green-800:hover{
    --tw-gradient-to: #065f46 !important;
  }

  .\32xl\:hover\:to-green-900:hover{
    --tw-gradient-to: #064e3b !important;
  }

  .\32xl\:hover\:to-blue-50:hover{
    --tw-gradient-to: #eff6ff !important;
  }

  .\32xl\:hover\:to-blue-100:hover{
    --tw-gradient-to: #dbeafe !important;
  }

  .\32xl\:hover\:to-blue-200:hover{
    --tw-gradient-to: #bfdbfe !important;
  }

  .\32xl\:hover\:to-blue-300:hover{
    --tw-gradient-to: #93c5fd !important;
  }

  .\32xl\:hover\:to-blue-400:hover{
    --tw-gradient-to: #60a5fa !important;
  }

  .\32xl\:hover\:to-blue-500:hover{
    --tw-gradient-to: #3b82f6 !important;
  }

  .\32xl\:hover\:to-blue-600:hover{
    --tw-gradient-to: #2563eb !important;
  }

  .\32xl\:hover\:to-blue-700:hover{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .\32xl\:hover\:to-blue-800:hover{
    --tw-gradient-to: #1e40af !important;
  }

  .\32xl\:hover\:to-blue-900:hover{
    --tw-gradient-to: #1e3a8a !important;
  }

  .\32xl\:hover\:to-indigo-50:hover{
    --tw-gradient-to: #eef2ff !important;
  }

  .\32xl\:hover\:to-indigo-100:hover{
    --tw-gradient-to: #e0e7ff !important;
  }

  .\32xl\:hover\:to-indigo-200:hover{
    --tw-gradient-to: #c7d2fe !important;
  }

  .\32xl\:hover\:to-indigo-300:hover{
    --tw-gradient-to: #a5b4fc !important;
  }

  .\32xl\:hover\:to-indigo-400:hover{
    --tw-gradient-to: #818cf8 !important;
  }

  .\32xl\:hover\:to-indigo-500:hover{
    --tw-gradient-to: #6366f1 !important;
  }

  .\32xl\:hover\:to-indigo-600:hover{
    --tw-gradient-to: #4f46e5 !important;
  }

  .\32xl\:hover\:to-indigo-700:hover{
    --tw-gradient-to: #4338ca !important;
  }

  .\32xl\:hover\:to-indigo-800:hover{
    --tw-gradient-to: #3730a3 !important;
  }

  .\32xl\:hover\:to-indigo-900:hover{
    --tw-gradient-to: #312e81 !important;
  }

  .\32xl\:hover\:to-purple-50:hover{
    --tw-gradient-to: #f5f3ff !important;
  }

  .\32xl\:hover\:to-purple-100:hover{
    --tw-gradient-to: #ede9fe !important;
  }

  .\32xl\:hover\:to-purple-200:hover{
    --tw-gradient-to: #ddd6fe !important;
  }

  .\32xl\:hover\:to-purple-300:hover{
    --tw-gradient-to: #c4b5fd !important;
  }

  .\32xl\:hover\:to-purple-400:hover{
    --tw-gradient-to: #a78bfa !important;
  }

  .\32xl\:hover\:to-purple-500:hover{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .\32xl\:hover\:to-purple-600:hover{
    --tw-gradient-to: #7c3aed !important;
  }

  .\32xl\:hover\:to-purple-700:hover{
    --tw-gradient-to: #6d28d9 !important;
  }

  .\32xl\:hover\:to-purple-800:hover{
    --tw-gradient-to: #5b21b6 !important;
  }

  .\32xl\:hover\:to-purple-900:hover{
    --tw-gradient-to: #4c1d95 !important;
  }

  .\32xl\:hover\:to-pink-50:hover{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .\32xl\:hover\:to-pink-100:hover{
    --tw-gradient-to: #fce7f3 !important;
  }

  .\32xl\:hover\:to-pink-200:hover{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .\32xl\:hover\:to-pink-300:hover{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .\32xl\:hover\:to-pink-400:hover{
    --tw-gradient-to: #f472b6 !important;
  }

  .\32xl\:hover\:to-pink-500:hover{
    --tw-gradient-to: #ec4899 !important;
  }

  .\32xl\:hover\:to-pink-600:hover{
    --tw-gradient-to: #db2777 !important;
  }

  .\32xl\:hover\:to-pink-700:hover{
    --tw-gradient-to: #be185d !important;
  }

  .\32xl\:hover\:to-pink-800:hover{
    --tw-gradient-to: #9d174d !important;
  }

  .\32xl\:hover\:to-pink-900:hover{
    --tw-gradient-to: #831843 !important;
  }

  .\32xl\:hover\:to-qipGreen:hover{
    --tw-gradient-to: #8bc334 !important;
  }

  .\32xl\:hover\:to-portalGreen:hover{
    --tw-gradient-to: #8ac04a !important;
  }

  .\32xl\:focus\:to-transparent:focus{
    --tw-gradient-to: transparent !important;
  }

  .\32xl\:focus\:to-current:focus{
    --tw-gradient-to: currentColor !important;
  }

  .\32xl\:focus\:to-black:focus{
    --tw-gradient-to: #000 !important;
  }

  .\32xl\:focus\:to-white:focus{
    --tw-gradient-to: #fff !important;
  }

  .\32xl\:focus\:to-gray-50:focus{
    --tw-gradient-to: #f9fafb !important;
  }

  .\32xl\:focus\:to-gray-100:focus{
    --tw-gradient-to: #f3f4f6 !important;
  }

  .\32xl\:focus\:to-gray-200:focus{
    --tw-gradient-to: #e5e7eb !important;
  }

  .\32xl\:focus\:to-gray-300:focus{
    --tw-gradient-to: #d1d5db !important;
  }

  .\32xl\:focus\:to-gray-400:focus{
    --tw-gradient-to: #9ca3af !important;
  }

  .\32xl\:focus\:to-gray-500:focus{
    --tw-gradient-to: #6b7280 !important;
  }

  .\32xl\:focus\:to-gray-600:focus{
    --tw-gradient-to: #4b5563 !important;
  }

  .\32xl\:focus\:to-gray-700:focus{
    --tw-gradient-to: #374151 !important;
  }

  .\32xl\:focus\:to-gray-800:focus{
    --tw-gradient-to: #1f2937 !important;
  }

  .\32xl\:focus\:to-gray-900:focus{
    --tw-gradient-to: #111827 !important;
  }

  .\32xl\:focus\:to-red-50:focus{
    --tw-gradient-to: #fef2f2 !important;
  }

  .\32xl\:focus\:to-red-100:focus{
    --tw-gradient-to: #fee2e2 !important;
  }

  .\32xl\:focus\:to-red-200:focus{
    --tw-gradient-to: #fecaca !important;
  }

  .\32xl\:focus\:to-red-300:focus{
    --tw-gradient-to: #fca5a5 !important;
  }

  .\32xl\:focus\:to-red-400:focus{
    --tw-gradient-to: #f87171 !important;
  }

  .\32xl\:focus\:to-red-500:focus{
    --tw-gradient-to: #ef4444 !important;
  }

  .\32xl\:focus\:to-red-600:focus{
    --tw-gradient-to: #dc2626 !important;
  }

  .\32xl\:focus\:to-red-700:focus{
    --tw-gradient-to: #b91c1c !important;
  }

  .\32xl\:focus\:to-red-800:focus{
    --tw-gradient-to: #991b1b !important;
  }

  .\32xl\:focus\:to-red-900:focus{
    --tw-gradient-to: #7f1d1d !important;
  }

  .\32xl\:focus\:to-yellow-50:focus{
    --tw-gradient-to: #fffbeb !important;
  }

  .\32xl\:focus\:to-yellow-100:focus{
    --tw-gradient-to: #fef3c7 !important;
  }

  .\32xl\:focus\:to-yellow-200:focus{
    --tw-gradient-to: #fde68a !important;
  }

  .\32xl\:focus\:to-yellow-300:focus{
    --tw-gradient-to: #fcd34d !important;
  }

  .\32xl\:focus\:to-yellow-400:focus{
    --tw-gradient-to: #fbbf24 !important;
  }

  .\32xl\:focus\:to-yellow-500:focus{
    --tw-gradient-to: #f59e0b !important;
  }

  .\32xl\:focus\:to-yellow-600:focus{
    --tw-gradient-to: #d97706 !important;
  }

  .\32xl\:focus\:to-yellow-700:focus{
    --tw-gradient-to: #b45309 !important;
  }

  .\32xl\:focus\:to-yellow-800:focus{
    --tw-gradient-to: #92400e !important;
  }

  .\32xl\:focus\:to-yellow-900:focus{
    --tw-gradient-to: #78350f !important;
  }

  .\32xl\:focus\:to-green-50:focus{
    --tw-gradient-to: #ecfdf5 !important;
  }

  .\32xl\:focus\:to-green-100:focus{
    --tw-gradient-to: #d1fae5 !important;
  }

  .\32xl\:focus\:to-green-200:focus{
    --tw-gradient-to: #a7f3d0 !important;
  }

  .\32xl\:focus\:to-green-300:focus{
    --tw-gradient-to: #6ee7b7 !important;
  }

  .\32xl\:focus\:to-green-400:focus{
    --tw-gradient-to: #34d399 !important;
  }

  .\32xl\:focus\:to-green-500:focus{
    --tw-gradient-to: #10b981 !important;
  }

  .\32xl\:focus\:to-green-600:focus{
    --tw-gradient-to: #059669 !important;
  }

  .\32xl\:focus\:to-green-700:focus{
    --tw-gradient-to: #047857 !important;
  }

  .\32xl\:focus\:to-green-800:focus{
    --tw-gradient-to: #065f46 !important;
  }

  .\32xl\:focus\:to-green-900:focus{
    --tw-gradient-to: #064e3b !important;
  }

  .\32xl\:focus\:to-blue-50:focus{
    --tw-gradient-to: #eff6ff !important;
  }

  .\32xl\:focus\:to-blue-100:focus{
    --tw-gradient-to: #dbeafe !important;
  }

  .\32xl\:focus\:to-blue-200:focus{
    --tw-gradient-to: #bfdbfe !important;
  }

  .\32xl\:focus\:to-blue-300:focus{
    --tw-gradient-to: #93c5fd !important;
  }

  .\32xl\:focus\:to-blue-400:focus{
    --tw-gradient-to: #60a5fa !important;
  }

  .\32xl\:focus\:to-blue-500:focus{
    --tw-gradient-to: #3b82f6 !important;
  }

  .\32xl\:focus\:to-blue-600:focus{
    --tw-gradient-to: #2563eb !important;
  }

  .\32xl\:focus\:to-blue-700:focus{
    --tw-gradient-to: #1d4ed8 !important;
  }

  .\32xl\:focus\:to-blue-800:focus{
    --tw-gradient-to: #1e40af !important;
  }

  .\32xl\:focus\:to-blue-900:focus{
    --tw-gradient-to: #1e3a8a !important;
  }

  .\32xl\:focus\:to-indigo-50:focus{
    --tw-gradient-to: #eef2ff !important;
  }

  .\32xl\:focus\:to-indigo-100:focus{
    --tw-gradient-to: #e0e7ff !important;
  }

  .\32xl\:focus\:to-indigo-200:focus{
    --tw-gradient-to: #c7d2fe !important;
  }

  .\32xl\:focus\:to-indigo-300:focus{
    --tw-gradient-to: #a5b4fc !important;
  }

  .\32xl\:focus\:to-indigo-400:focus{
    --tw-gradient-to: #818cf8 !important;
  }

  .\32xl\:focus\:to-indigo-500:focus{
    --tw-gradient-to: #6366f1 !important;
  }

  .\32xl\:focus\:to-indigo-600:focus{
    --tw-gradient-to: #4f46e5 !important;
  }

  .\32xl\:focus\:to-indigo-700:focus{
    --tw-gradient-to: #4338ca !important;
  }

  .\32xl\:focus\:to-indigo-800:focus{
    --tw-gradient-to: #3730a3 !important;
  }

  .\32xl\:focus\:to-indigo-900:focus{
    --tw-gradient-to: #312e81 !important;
  }

  .\32xl\:focus\:to-purple-50:focus{
    --tw-gradient-to: #f5f3ff !important;
  }

  .\32xl\:focus\:to-purple-100:focus{
    --tw-gradient-to: #ede9fe !important;
  }

  .\32xl\:focus\:to-purple-200:focus{
    --tw-gradient-to: #ddd6fe !important;
  }

  .\32xl\:focus\:to-purple-300:focus{
    --tw-gradient-to: #c4b5fd !important;
  }

  .\32xl\:focus\:to-purple-400:focus{
    --tw-gradient-to: #a78bfa !important;
  }

  .\32xl\:focus\:to-purple-500:focus{
    --tw-gradient-to: #8b5cf6 !important;
  }

  .\32xl\:focus\:to-purple-600:focus{
    --tw-gradient-to: #7c3aed !important;
  }

  .\32xl\:focus\:to-purple-700:focus{
    --tw-gradient-to: #6d28d9 !important;
  }

  .\32xl\:focus\:to-purple-800:focus{
    --tw-gradient-to: #5b21b6 !important;
  }

  .\32xl\:focus\:to-purple-900:focus{
    --tw-gradient-to: #4c1d95 !important;
  }

  .\32xl\:focus\:to-pink-50:focus{
    --tw-gradient-to: #fdf2f8 !important;
  }

  .\32xl\:focus\:to-pink-100:focus{
    --tw-gradient-to: #fce7f3 !important;
  }

  .\32xl\:focus\:to-pink-200:focus{
    --tw-gradient-to: #fbcfe8 !important;
  }

  .\32xl\:focus\:to-pink-300:focus{
    --tw-gradient-to: #f9a8d4 !important;
  }

  .\32xl\:focus\:to-pink-400:focus{
    --tw-gradient-to: #f472b6 !important;
  }

  .\32xl\:focus\:to-pink-500:focus{
    --tw-gradient-to: #ec4899 !important;
  }

  .\32xl\:focus\:to-pink-600:focus{
    --tw-gradient-to: #db2777 !important;
  }

  .\32xl\:focus\:to-pink-700:focus{
    --tw-gradient-to: #be185d !important;
  }

  .\32xl\:focus\:to-pink-800:focus{
    --tw-gradient-to: #9d174d !important;
  }

  .\32xl\:focus\:to-pink-900:focus{
    --tw-gradient-to: #831843 !important;
  }

  .\32xl\:focus\:to-qipGreen:focus{
    --tw-gradient-to: #8bc334 !important;
  }

  .\32xl\:focus\:to-portalGreen:focus{
    --tw-gradient-to: #8ac04a !important;
  }

  .\32xl\:decoration-slice{
    -webkit-box-decoration-break: slice !important;
            box-decoration-break: slice !important;
  }

  .\32xl\:decoration-clone{
    -webkit-box-decoration-break: clone !important;
            box-decoration-break: clone !important;
  }

  .\32xl\:bg-auto{
    background-size: auto !important;
  }

  .\32xl\:bg-cover{
    background-size: cover !important;
  }

  .\32xl\:bg-contain{
    background-size: contain !important;
  }

  .\32xl\:bg-fixed{
    background-attachment: fixed !important;
  }

  .\32xl\:bg-local{
    background-attachment: local !important;
  }

  .\32xl\:bg-scroll{
    background-attachment: scroll !important;
  }

  .\32xl\:bg-clip-border{
    background-clip: border-box !important;
  }

  .\32xl\:bg-clip-padding{
    background-clip: padding-box !important;
  }

  .\32xl\:bg-clip-content{
    background-clip: content-box !important;
  }

  .\32xl\:bg-clip-text{
    -webkit-background-clip: text !important;
            background-clip: text !important;
  }

  .\32xl\:bg-bottom{
    background-position: bottom !important;
  }

  .\32xl\:bg-center{
    background-position: center !important;
  }

  .\32xl\:bg-left{
    background-position: left !important;
  }

  .\32xl\:bg-left-bottom{
    background-position: left bottom !important;
  }

  .\32xl\:bg-left-top{
    background-position: left top !important;
  }

  .\32xl\:bg-right{
    background-position: right !important;
  }

  .\32xl\:bg-right-bottom{
    background-position: right bottom !important;
  }

  .\32xl\:bg-right-top{
    background-position: right top !important;
  }

  .\32xl\:bg-top{
    background-position: top !important;
  }

  .\32xl\:bg-repeat{
    background-repeat: repeat !important;
  }

  .\32xl\:bg-no-repeat{
    background-repeat: no-repeat !important;
  }

  .\32xl\:bg-repeat-x{
    background-repeat: repeat-x !important;
  }

  .\32xl\:bg-repeat-y{
    background-repeat: repeat-y !important;
  }

  .\32xl\:bg-repeat-round{
    background-repeat: round !important;
  }

  .\32xl\:bg-repeat-space{
    background-repeat: space !important;
  }

  .\32xl\:bg-origin-border{
    background-origin: border-box !important;
  }

  .\32xl\:bg-origin-padding{
    background-origin: padding-box !important;
  }

  .\32xl\:bg-origin-content{
    background-origin: content-box !important;
  }

  .\32xl\:fill-current{
    fill: currentColor !important;
  }

  .\32xl\:stroke-current{
    stroke: currentColor !important;
  }

  .\32xl\:stroke-0{
    stroke-width: 0 !important;
  }

  .\32xl\:stroke-1{
    stroke-width: 1 !important;
  }

  .\32xl\:stroke-2{
    stroke-width: 2 !important;
  }

  .\32xl\:object-contain{
    object-fit: contain !important;
  }

  .\32xl\:object-cover{
    object-fit: cover !important;
  }

  .\32xl\:object-fill{
    object-fit: fill !important;
  }

  .\32xl\:object-none{
    object-fit: none !important;
  }

  .\32xl\:object-scale-down{
    object-fit: scale-down !important;
  }

  .\32xl\:object-bottom{
    object-position: bottom !important;
  }

  .\32xl\:object-center{
    object-position: center !important;
  }

  .\32xl\:object-left{
    object-position: left !important;
  }

  .\32xl\:object-left-bottom{
    object-position: left bottom !important;
  }

  .\32xl\:object-left-top{
    object-position: left top !important;
  }

  .\32xl\:object-right{
    object-position: right !important;
  }

  .\32xl\:object-right-bottom{
    object-position: right bottom !important;
  }

  .\32xl\:object-right-top{
    object-position: right top !important;
  }

  .\32xl\:object-top{
    object-position: top !important;
  }

  .\32xl\:p-0{
    padding: 0px !important;
  }

  .\32xl\:p-1{
    padding: 0.25rem !important;
  }

  .\32xl\:p-2{
    padding: 0.5rem !important;
  }

  .\32xl\:p-3{
    padding: 0.75rem !important;
  }

  .\32xl\:p-4{
    padding: 1rem !important;
  }

  .\32xl\:p-5{
    padding: 1.25rem !important;
  }

  .\32xl\:p-6{
    padding: 1.5rem !important;
  }

  .\32xl\:p-7{
    padding: 1.75rem !important;
  }

  .\32xl\:p-8{
    padding: 2rem !important;
  }

  .\32xl\:p-9{
    padding: 2.25rem !important;
  }

  .\32xl\:p-10{
    padding: 2.5rem !important;
  }

  .\32xl\:p-11{
    padding: 2.75rem !important;
  }

  .\32xl\:p-12{
    padding: 3rem !important;
  }

  .\32xl\:p-14{
    padding: 3.5rem !important;
  }

  .\32xl\:p-16{
    padding: 4rem !important;
  }

  .\32xl\:p-20{
    padding: 5rem !important;
  }

  .\32xl\:p-24{
    padding: 6rem !important;
  }

  .\32xl\:p-28{
    padding: 7rem !important;
  }

  .\32xl\:p-32{
    padding: 8rem !important;
  }

  .\32xl\:p-36{
    padding: 9rem !important;
  }

  .\32xl\:p-40{
    padding: 10rem !important;
  }

  .\32xl\:p-44{
    padding: 11rem !important;
  }

  .\32xl\:p-48{
    padding: 12rem !important;
  }

  .\32xl\:p-52{
    padding: 13rem !important;
  }

  .\32xl\:p-56{
    padding: 14rem !important;
  }

  .\32xl\:p-60{
    padding: 15rem !important;
  }

  .\32xl\:p-64{
    padding: 16rem !important;
  }

  .\32xl\:p-72{
    padding: 18rem !important;
  }

  .\32xl\:p-80{
    padding: 20rem !important;
  }

  .\32xl\:p-96{
    padding: 24rem !important;
  }

  .\32xl\:p-px{
    padding: 1px !important;
  }

  .\32xl\:p-0\.5{
    padding: 0.125rem !important;
  }

  .\32xl\:p-1\.5{
    padding: 0.375rem !important;
  }

  .\32xl\:p-2\.5{
    padding: 0.625rem !important;
  }

  .\32xl\:p-3\.5{
    padding: 0.875rem !important;
  }

  .\32xl\:px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .\32xl\:px-1{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .\32xl\:px-2{
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .\32xl\:px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .\32xl\:px-4{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .\32xl\:px-5{
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .\32xl\:px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .\32xl\:px-7{
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }

  .\32xl\:px-8{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .\32xl\:px-9{
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .\32xl\:px-10{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .\32xl\:px-11{
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .\32xl\:px-12{
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .\32xl\:px-14{
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .\32xl\:px-16{
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .\32xl\:px-20{
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .\32xl\:px-24{
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .\32xl\:px-28{
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .\32xl\:px-32{
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .\32xl\:px-36{
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .\32xl\:px-40{
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .\32xl\:px-44{
    padding-left: 11rem !important;
    padding-right: 11rem !important;
  }

  .\32xl\:px-48{
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .\32xl\:px-52{
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }

  .\32xl\:px-56{
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .\32xl\:px-60{
    padding-left: 15rem !important;
    padding-right: 15rem !important;
  }

  .\32xl\:px-64{
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .\32xl\:px-72{
    padding-left: 18rem !important;
    padding-right: 18rem !important;
  }

  .\32xl\:px-80{
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }

  .\32xl\:px-96{
    padding-left: 24rem !important;
    padding-right: 24rem !important;
  }

  .\32xl\:px-px{
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .\32xl\:px-0\.5{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }

  .\32xl\:px-1\.5{
    padding-left: 0.375rem !important;
    padding-right: 0.375rem !important;
  }

  .\32xl\:px-2\.5{
    padding-left: 0.625rem !important;
    padding-right: 0.625rem !important;
  }

  .\32xl\:px-3\.5{
    padding-left: 0.875rem !important;
    padding-right: 0.875rem !important;
  }

  .\32xl\:py-0{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .\32xl\:py-1{
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .\32xl\:py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .\32xl\:py-3{
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .\32xl\:py-4{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .\32xl\:py-5{
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .\32xl\:py-6{
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .\32xl\:py-7{
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .\32xl\:py-8{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .\32xl\:py-9{
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .\32xl\:py-10{
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .\32xl\:py-11{
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .\32xl\:py-12{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .\32xl\:py-14{
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .\32xl\:py-16{
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .\32xl\:py-20{
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .\32xl\:py-24{
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .\32xl\:py-28{
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .\32xl\:py-32{
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .\32xl\:py-36{
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .\32xl\:py-40{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .\32xl\:py-44{
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }

  .\32xl\:py-48{
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .\32xl\:py-52{
    padding-top: 13rem !important;
    padding-bottom: 13rem !important;
  }

  .\32xl\:py-56{
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .\32xl\:py-60{
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }

  .\32xl\:py-64{
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .\32xl\:py-72{
    padding-top: 18rem !important;
    padding-bottom: 18rem !important;
  }

  .\32xl\:py-80{
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }

  .\32xl\:py-96{
    padding-top: 24rem !important;
    padding-bottom: 24rem !important;
  }

  .\32xl\:py-px{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .\32xl\:py-0\.5{
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }

  .\32xl\:py-1\.5{
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }

  .\32xl\:py-2\.5{
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }

  .\32xl\:py-3\.5{
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }

  .\32xl\:pt-0{
    padding-top: 0px !important;
  }

  .\32xl\:pt-1{
    padding-top: 0.25rem !important;
  }

  .\32xl\:pt-2{
    padding-top: 0.5rem !important;
  }

  .\32xl\:pt-3{
    padding-top: 0.75rem !important;
  }

  .\32xl\:pt-4{
    padding-top: 1rem !important;
  }

  .\32xl\:pt-5{
    padding-top: 1.25rem !important;
  }

  .\32xl\:pt-6{
    padding-top: 1.5rem !important;
  }

  .\32xl\:pt-7{
    padding-top: 1.75rem !important;
  }

  .\32xl\:pt-8{
    padding-top: 2rem !important;
  }

  .\32xl\:pt-9{
    padding-top: 2.25rem !important;
  }

  .\32xl\:pt-10{
    padding-top: 2.5rem !important;
  }

  .\32xl\:pt-11{
    padding-top: 2.75rem !important;
  }

  .\32xl\:pt-12{
    padding-top: 3rem !important;
  }

  .\32xl\:pt-14{
    padding-top: 3.5rem !important;
  }

  .\32xl\:pt-16{
    padding-top: 4rem !important;
  }

  .\32xl\:pt-20{
    padding-top: 5rem !important;
  }

  .\32xl\:pt-24{
    padding-top: 6rem !important;
  }

  .\32xl\:pt-28{
    padding-top: 7rem !important;
  }

  .\32xl\:pt-32{
    padding-top: 8rem !important;
  }

  .\32xl\:pt-36{
    padding-top: 9rem !important;
  }

  .\32xl\:pt-40{
    padding-top: 10rem !important;
  }

  .\32xl\:pt-44{
    padding-top: 11rem !important;
  }

  .\32xl\:pt-48{
    padding-top: 12rem !important;
  }

  .\32xl\:pt-52{
    padding-top: 13rem !important;
  }

  .\32xl\:pt-56{
    padding-top: 14rem !important;
  }

  .\32xl\:pt-60{
    padding-top: 15rem !important;
  }

  .\32xl\:pt-64{
    padding-top: 16rem !important;
  }

  .\32xl\:pt-72{
    padding-top: 18rem !important;
  }

  .\32xl\:pt-80{
    padding-top: 20rem !important;
  }

  .\32xl\:pt-96{
    padding-top: 24rem !important;
  }

  .\32xl\:pt-px{
    padding-top: 1px !important;
  }

  .\32xl\:pt-0\.5{
    padding-top: 0.125rem !important;
  }

  .\32xl\:pt-1\.5{
    padding-top: 0.375rem !important;
  }

  .\32xl\:pt-2\.5{
    padding-top: 0.625rem !important;
  }

  .\32xl\:pt-3\.5{
    padding-top: 0.875rem !important;
  }

  .\32xl\:pr-0{
    padding-right: 0px !important;
  }

  .\32xl\:pr-1{
    padding-right: 0.25rem !important;
  }

  .\32xl\:pr-2{
    padding-right: 0.5rem !important;
  }

  .\32xl\:pr-3{
    padding-right: 0.75rem !important;
  }

  .\32xl\:pr-4{
    padding-right: 1rem !important;
  }

  .\32xl\:pr-5{
    padding-right: 1.25rem !important;
  }

  .\32xl\:pr-6{
    padding-right: 1.5rem !important;
  }

  .\32xl\:pr-7{
    padding-right: 1.75rem !important;
  }

  .\32xl\:pr-8{
    padding-right: 2rem !important;
  }

  .\32xl\:pr-9{
    padding-right: 2.25rem !important;
  }

  .\32xl\:pr-10{
    padding-right: 2.5rem !important;
  }

  .\32xl\:pr-11{
    padding-right: 2.75rem !important;
  }

  .\32xl\:pr-12{
    padding-right: 3rem !important;
  }

  .\32xl\:pr-14{
    padding-right: 3.5rem !important;
  }

  .\32xl\:pr-16{
    padding-right: 4rem !important;
  }

  .\32xl\:pr-20{
    padding-right: 5rem !important;
  }

  .\32xl\:pr-24{
    padding-right: 6rem !important;
  }

  .\32xl\:pr-28{
    padding-right: 7rem !important;
  }

  .\32xl\:pr-32{
    padding-right: 8rem !important;
  }

  .\32xl\:pr-36{
    padding-right: 9rem !important;
  }

  .\32xl\:pr-40{
    padding-right: 10rem !important;
  }

  .\32xl\:pr-44{
    padding-right: 11rem !important;
  }

  .\32xl\:pr-48{
    padding-right: 12rem !important;
  }

  .\32xl\:pr-52{
    padding-right: 13rem !important;
  }

  .\32xl\:pr-56{
    padding-right: 14rem !important;
  }

  .\32xl\:pr-60{
    padding-right: 15rem !important;
  }

  .\32xl\:pr-64{
    padding-right: 16rem !important;
  }

  .\32xl\:pr-72{
    padding-right: 18rem !important;
  }

  .\32xl\:pr-80{
    padding-right: 20rem !important;
  }

  .\32xl\:pr-96{
    padding-right: 24rem !important;
  }

  .\32xl\:pr-px{
    padding-right: 1px !important;
  }

  .\32xl\:pr-0\.5{
    padding-right: 0.125rem !important;
  }

  .\32xl\:pr-1\.5{
    padding-right: 0.375rem !important;
  }

  .\32xl\:pr-2\.5{
    padding-right: 0.625rem !important;
  }

  .\32xl\:pr-3\.5{
    padding-right: 0.875rem !important;
  }

  .\32xl\:pb-0{
    padding-bottom: 0px !important;
  }

  .\32xl\:pb-1{
    padding-bottom: 0.25rem !important;
  }

  .\32xl\:pb-2{
    padding-bottom: 0.5rem !important;
  }

  .\32xl\:pb-3{
    padding-bottom: 0.75rem !important;
  }

  .\32xl\:pb-4{
    padding-bottom: 1rem !important;
  }

  .\32xl\:pb-5{
    padding-bottom: 1.25rem !important;
  }

  .\32xl\:pb-6{
    padding-bottom: 1.5rem !important;
  }

  .\32xl\:pb-7{
    padding-bottom: 1.75rem !important;
  }

  .\32xl\:pb-8{
    padding-bottom: 2rem !important;
  }

  .\32xl\:pb-9{
    padding-bottom: 2.25rem !important;
  }

  .\32xl\:pb-10{
    padding-bottom: 2.5rem !important;
  }

  .\32xl\:pb-11{
    padding-bottom: 2.75rem !important;
  }

  .\32xl\:pb-12{
    padding-bottom: 3rem !important;
  }

  .\32xl\:pb-14{
    padding-bottom: 3.5rem !important;
  }

  .\32xl\:pb-16{
    padding-bottom: 4rem !important;
  }

  .\32xl\:pb-20{
    padding-bottom: 5rem !important;
  }

  .\32xl\:pb-24{
    padding-bottom: 6rem !important;
  }

  .\32xl\:pb-28{
    padding-bottom: 7rem !important;
  }

  .\32xl\:pb-32{
    padding-bottom: 8rem !important;
  }

  .\32xl\:pb-36{
    padding-bottom: 9rem !important;
  }

  .\32xl\:pb-40{
    padding-bottom: 10rem !important;
  }

  .\32xl\:pb-44{
    padding-bottom: 11rem !important;
  }

  .\32xl\:pb-48{
    padding-bottom: 12rem !important;
  }

  .\32xl\:pb-52{
    padding-bottom: 13rem !important;
  }

  .\32xl\:pb-56{
    padding-bottom: 14rem !important;
  }

  .\32xl\:pb-60{
    padding-bottom: 15rem !important;
  }

  .\32xl\:pb-64{
    padding-bottom: 16rem !important;
  }

  .\32xl\:pb-72{
    padding-bottom: 18rem !important;
  }

  .\32xl\:pb-80{
    padding-bottom: 20rem !important;
  }

  .\32xl\:pb-96{
    padding-bottom: 24rem !important;
  }

  .\32xl\:pb-px{
    padding-bottom: 1px !important;
  }

  .\32xl\:pb-0\.5{
    padding-bottom: 0.125rem !important;
  }

  .\32xl\:pb-1\.5{
    padding-bottom: 0.375rem !important;
  }

  .\32xl\:pb-2\.5{
    padding-bottom: 0.625rem !important;
  }

  .\32xl\:pb-3\.5{
    padding-bottom: 0.875rem !important;
  }

  .\32xl\:pl-0{
    padding-left: 0px !important;
  }

  .\32xl\:pl-1{
    padding-left: 0.25rem !important;
  }

  .\32xl\:pl-2{
    padding-left: 0.5rem !important;
  }

  .\32xl\:pl-3{
    padding-left: 0.75rem !important;
  }

  .\32xl\:pl-4{
    padding-left: 1rem !important;
  }

  .\32xl\:pl-5{
    padding-left: 1.25rem !important;
  }

  .\32xl\:pl-6{
    padding-left: 1.5rem !important;
  }

  .\32xl\:pl-7{
    padding-left: 1.75rem !important;
  }

  .\32xl\:pl-8{
    padding-left: 2rem !important;
  }

  .\32xl\:pl-9{
    padding-left: 2.25rem !important;
  }

  .\32xl\:pl-10{
    padding-left: 2.5rem !important;
  }

  .\32xl\:pl-11{
    padding-left: 2.75rem !important;
  }

  .\32xl\:pl-12{
    padding-left: 3rem !important;
  }

  .\32xl\:pl-14{
    padding-left: 3.5rem !important;
  }

  .\32xl\:pl-16{
    padding-left: 4rem !important;
  }

  .\32xl\:pl-20{
    padding-left: 5rem !important;
  }

  .\32xl\:pl-24{
    padding-left: 6rem !important;
  }

  .\32xl\:pl-28{
    padding-left: 7rem !important;
  }

  .\32xl\:pl-32{
    padding-left: 8rem !important;
  }

  .\32xl\:pl-36{
    padding-left: 9rem !important;
  }

  .\32xl\:pl-40{
    padding-left: 10rem !important;
  }

  .\32xl\:pl-44{
    padding-left: 11rem !important;
  }

  .\32xl\:pl-48{
    padding-left: 12rem !important;
  }

  .\32xl\:pl-52{
    padding-left: 13rem !important;
  }

  .\32xl\:pl-56{
    padding-left: 14rem !important;
  }

  .\32xl\:pl-60{
    padding-left: 15rem !important;
  }

  .\32xl\:pl-64{
    padding-left: 16rem !important;
  }

  .\32xl\:pl-72{
    padding-left: 18rem !important;
  }

  .\32xl\:pl-80{
    padding-left: 20rem !important;
  }

  .\32xl\:pl-96{
    padding-left: 24rem !important;
  }

  .\32xl\:pl-px{
    padding-left: 1px !important;
  }

  .\32xl\:pl-0\.5{
    padding-left: 0.125rem !important;
  }

  .\32xl\:pl-1\.5{
    padding-left: 0.375rem !important;
  }

  .\32xl\:pl-2\.5{
    padding-left: 0.625rem !important;
  }

  .\32xl\:pl-3\.5{
    padding-left: 0.875rem !important;
  }

  .\32xl\:text-left{
    text-align: left !important;
  }

  .\32xl\:text-center{
    text-align: center !important;
  }

  .\32xl\:text-right{
    text-align: right !important;
  }

  .\32xl\:text-justify{
    text-align: justify !important;
  }

  .\32xl\:align-baseline{
    vertical-align: baseline !important;
  }

  .\32xl\:align-top{
    vertical-align: top !important;
  }

  .\32xl\:align-middle{
    vertical-align: middle !important;
  }

  .\32xl\:align-bottom{
    vertical-align: bottom !important;
  }

  .\32xl\:align-text-top{
    vertical-align: text-top !important;
  }

  .\32xl\:align-text-bottom{
    vertical-align: text-bottom !important;
  }

  .\32xl\:font-sans{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }

  .\32xl\:font-serif{
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
  }

  .\32xl\:font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }

  .\32xl\:text-xs{
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }

  .\32xl\:text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }

  .\32xl\:text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  .\32xl\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .\32xl\:text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .\32xl\:text-2xl{
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .\32xl\:text-3xl{
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .\32xl\:text-4xl{
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .\32xl\:text-5xl{
    font-size: 3rem !important;
    line-height: 1 !important;
  }

  .\32xl\:text-6xl{
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }

  .\32xl\:text-7xl{
    font-size: 4.5rem !important;
    line-height: 1 !important;
  }

  .\32xl\:text-8xl{
    font-size: 6rem !important;
    line-height: 1 !important;
  }

  .\32xl\:text-9xl{
    font-size: 8rem !important;
    line-height: 1 !important;
  }

  .\32xl\:font-thin{
    font-weight: 100 !important;
  }

  .\32xl\:font-extralight{
    font-weight: 200 !important;
  }

  .\32xl\:font-light{
    font-weight: 300 !important;
  }

  .\32xl\:font-normal{
    font-weight: 400 !important;
  }

  .\32xl\:font-medium{
    font-weight: 500 !important;
  }

  .\32xl\:font-semibold{
    font-weight: 600 !important;
  }

  .\32xl\:font-bold{
    font-weight: 700 !important;
  }

  .\32xl\:font-extrabold{
    font-weight: 800 !important;
  }

  .\32xl\:font-black{
    font-weight: 900 !important;
  }

  .\32xl\:uppercase{
    text-transform: uppercase !important;
  }

  .\32xl\:lowercase{
    text-transform: lowercase !important;
  }

  .\32xl\:capitalize{
    text-transform: capitalize !important;
  }

  .\32xl\:normal-case{
    text-transform: none !important;
  }

  .\32xl\:italic{
    font-style: italic !important;
  }

  .\32xl\:not-italic{
    font-style: normal !important;
  }

  .\32xl\:ordinal, .\32xl\:slashed-zero, .\32xl\:lining-nums, .\32xl\:oldstyle-nums, .\32xl\:proportional-nums, .\32xl\:tabular-nums, .\32xl\:diagonal-fractions, .\32xl\:stacked-fractions{
    --tw-ordinal: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-slashed-zero: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-figure: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-spacing: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-numeric-fraction: var(--tw-empty,/*!*/ /*!*/) !important;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
  }

  .\32xl\:normal-nums{
    font-variant-numeric: normal !important;
  }

  .\32xl\:ordinal{
    --tw-ordinal: ordinal !important;
  }

  .\32xl\:slashed-zero{
    --tw-slashed-zero: slashed-zero !important;
  }

  .\32xl\:lining-nums{
    --tw-numeric-figure: lining-nums !important;
  }

  .\32xl\:oldstyle-nums{
    --tw-numeric-figure: oldstyle-nums !important;
  }

  .\32xl\:proportional-nums{
    --tw-numeric-spacing: proportional-nums !important;
  }

  .\32xl\:tabular-nums{
    --tw-numeric-spacing: tabular-nums !important;
  }

  .\32xl\:diagonal-fractions{
    --tw-numeric-fraction: diagonal-fractions !important;
  }

  .\32xl\:stacked-fractions{
    --tw-numeric-fraction: stacked-fractions !important;
  }

  .\32xl\:leading-3{
    line-height: .75rem !important;
  }

  .\32xl\:leading-4{
    line-height: 1rem !important;
  }

  .\32xl\:leading-5{
    line-height: 1.25rem !important;
  }

  .\32xl\:leading-6{
    line-height: 1.5rem !important;
  }

  .\32xl\:leading-7{
    line-height: 1.75rem !important;
  }

  .\32xl\:leading-8{
    line-height: 2rem !important;
  }

  .\32xl\:leading-9{
    line-height: 2.25rem !important;
  }

  .\32xl\:leading-10{
    line-height: 2.5rem !important;
  }

  .\32xl\:leading-none{
    line-height: 1 !important;
  }

  .\32xl\:leading-tight{
    line-height: 1.25 !important;
  }

  .\32xl\:leading-snug{
    line-height: 1.375 !important;
  }

  .\32xl\:leading-normal{
    line-height: 1.5 !important;
  }

  .\32xl\:leading-relaxed{
    line-height: 1.625 !important;
  }

  .\32xl\:leading-loose{
    line-height: 2 !important;
  }

  .\32xl\:tracking-tighter{
    letter-spacing: -0.05em !important;
  }

  .\32xl\:tracking-tight{
    letter-spacing: -0.025em !important;
  }

  .\32xl\:tracking-normal{
    letter-spacing: 0em !important;
  }

  .\32xl\:tracking-wide{
    letter-spacing: 0.025em !important;
  }

  .\32xl\:tracking-wider{
    letter-spacing: 0.05em !important;
  }

  .\32xl\:tracking-widest{
    letter-spacing: 0.1em !important;
  }

  .\32xl\:text-transparent{
    color: transparent !important;
  }

  .\32xl\:text-current{
    color: currentColor !important;
  }

  .\32xl\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-transparent{
    color: transparent !important;
  }

  .group:hover .\32xl\:group-hover\:text-current{
    color: currentColor !important;
  }

  .group:hover .\32xl\:group-hover\:text-black{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-white{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-50{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-100{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-200{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-300{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-400{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-500{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-600{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-700{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-800{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-gray-900{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-50{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-200{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-400{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-500{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-600{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-700{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-800{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-red-900{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-50{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-100{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-200{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-300{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-400{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-500{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-600{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-700{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-800{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-yellow-900{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-50{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-100{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-200{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-300{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-400{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-500{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-600{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-700{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-800{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-green-900{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-50{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-100{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-200{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-300{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-400{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-500{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-600{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-700{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-800{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-blue-900{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-50{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-100{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-200{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-300{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-400{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-500{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-600{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-700{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-800{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-indigo-900{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-50{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-100{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-200{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-300{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-400{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-500{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-600{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-700{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-800{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-purple-900{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-50{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-100{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-200{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-300{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-400{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-500{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-600{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-700{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-800{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-pink-900{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-qipGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .group:hover .\32xl\:group-hover\:text-portalGreen{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-transparent:focus-within{
    color: transparent !important;
  }

  .\32xl\:focus-within\:text-current:focus-within{
    color: currentColor !important;
  }

  .\32xl\:focus-within\:text-black:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-white:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-gray-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-red-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-yellow-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-green-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-blue-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-indigo-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-purple-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-50:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-100:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-200:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-300:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-400:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-500:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-600:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-700:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-800:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-pink-900:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-qipGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus-within\:text-portalGreen:focus-within{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-transparent:hover{
    color: transparent !important;
  }

  .\32xl\:hover\:text-current:hover{
    color: currentColor !important;
  }

  .\32xl\:hover\:text-black:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-white:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-gray-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-red-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-yellow-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-green-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-blue-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-indigo-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-purple-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-50:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-100:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-200:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-300:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-400:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-500:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-600:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-700:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-800:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-pink-900:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-qipGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:hover\:text-portalGreen:hover{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-transparent:focus{
    color: transparent !important;
  }

  .\32xl\:focus\:text-current:focus{
    color: currentColor !important;
  }

  .\32xl\:focus\:text-black:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-white:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-gray-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-red-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-yellow-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-green-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-blue-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-indigo-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-purple-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-50:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-100:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-200:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-300:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-400:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-500:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-600:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-700:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-800:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-pink-900:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-qipGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-text-opacity)) !important;
  }

  .\32xl\:focus\:text-portalGreen:focus{
    --tw-text-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-text-opacity)) !important;
  }

  .\32xl\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-0{
    --tw-text-opacity: 0 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-5{
    --tw-text-opacity: 0.05 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-10{
    --tw-text-opacity: 0.1 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-20{
    --tw-text-opacity: 0.2 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-25{
    --tw-text-opacity: 0.25 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-30{
    --tw-text-opacity: 0.3 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-40{
    --tw-text-opacity: 0.4 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-50{
    --tw-text-opacity: 0.5 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-60{
    --tw-text-opacity: 0.6 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-70{
    --tw-text-opacity: 0.7 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-75{
    --tw-text-opacity: 0.75 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-80{
    --tw-text-opacity: 0.8 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-90{
    --tw-text-opacity: 0.9 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-95{
    --tw-text-opacity: 0.95 !important;
  }

  .group:hover .\32xl\:group-hover\:text-opacity-100{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:focus-within\:text-opacity-0:focus-within{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:focus-within\:text-opacity-5:focus-within{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:text-opacity-10:focus-within{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:text-opacity-20:focus-within{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:text-opacity-25:focus-within{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:text-opacity-30:focus-within{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:text-opacity-40:focus-within{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:text-opacity-50:focus-within{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:text-opacity-60:focus-within{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:text-opacity-70:focus-within{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:text-opacity-75:focus-within{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:text-opacity-80:focus-within{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:text-opacity-90:focus-within{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:text-opacity-95:focus-within{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:text-opacity-100:focus-within{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:hover\:text-opacity-0:hover{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:hover\:text-opacity-5:hover{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:hover\:text-opacity-10:hover{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:hover\:text-opacity-20:hover{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:hover\:text-opacity-25:hover{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:hover\:text-opacity-30:hover{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:hover\:text-opacity-40:hover{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:hover\:text-opacity-50:hover{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:hover\:text-opacity-60:hover{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:hover\:text-opacity-70:hover{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:hover\:text-opacity-75:hover{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:hover\:text-opacity-80:hover{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:hover\:text-opacity-90:hover{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:hover\:text-opacity-95:hover{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:hover\:text-opacity-100:hover{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:focus\:text-opacity-0:focus{
    --tw-text-opacity: 0 !important;
  }

  .\32xl\:focus\:text-opacity-5:focus{
    --tw-text-opacity: 0.05 !important;
  }

  .\32xl\:focus\:text-opacity-10:focus{
    --tw-text-opacity: 0.1 !important;
  }

  .\32xl\:focus\:text-opacity-20:focus{
    --tw-text-opacity: 0.2 !important;
  }

  .\32xl\:focus\:text-opacity-25:focus{
    --tw-text-opacity: 0.25 !important;
  }

  .\32xl\:focus\:text-opacity-30:focus{
    --tw-text-opacity: 0.3 !important;
  }

  .\32xl\:focus\:text-opacity-40:focus{
    --tw-text-opacity: 0.4 !important;
  }

  .\32xl\:focus\:text-opacity-50:focus{
    --tw-text-opacity: 0.5 !important;
  }

  .\32xl\:focus\:text-opacity-60:focus{
    --tw-text-opacity: 0.6 !important;
  }

  .\32xl\:focus\:text-opacity-70:focus{
    --tw-text-opacity: 0.7 !important;
  }

  .\32xl\:focus\:text-opacity-75:focus{
    --tw-text-opacity: 0.75 !important;
  }

  .\32xl\:focus\:text-opacity-80:focus{
    --tw-text-opacity: 0.8 !important;
  }

  .\32xl\:focus\:text-opacity-90:focus{
    --tw-text-opacity: 0.9 !important;
  }

  .\32xl\:focus\:text-opacity-95:focus{
    --tw-text-opacity: 0.95 !important;
  }

  .\32xl\:focus\:text-opacity-100:focus{
    --tw-text-opacity: 1 !important;
  }

  .\32xl\:underline{
    text-decoration: underline !important;
  }

  .\32xl\:line-through{
    text-decoration: line-through !important;
  }

  .\32xl\:no-underline{
    text-decoration: none !important;
  }

  .group:hover .\32xl\:group-hover\:underline{
    text-decoration: underline !important;
  }

  .group:hover .\32xl\:group-hover\:line-through{
    text-decoration: line-through !important;
  }

  .group:hover .\32xl\:group-hover\:no-underline{
    text-decoration: none !important;
  }

  .\32xl\:focus-within\:underline:focus-within{
    text-decoration: underline !important;
  }

  .\32xl\:focus-within\:line-through:focus-within{
    text-decoration: line-through !important;
  }

  .\32xl\:focus-within\:no-underline:focus-within{
    text-decoration: none !important;
  }

  .\32xl\:hover\:underline:hover{
    text-decoration: underline !important;
  }

  .\32xl\:hover\:line-through:hover{
    text-decoration: line-through !important;
  }

  .\32xl\:hover\:no-underline:hover{
    text-decoration: none !important;
  }

  .\32xl\:focus\:underline:focus{
    text-decoration: underline !important;
  }

  .\32xl\:focus\:line-through:focus{
    text-decoration: line-through !important;
  }

  .\32xl\:focus\:no-underline:focus{
    text-decoration: none !important;
  }

  .\32xl\:antialiased{
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  .\32xl\:subpixel-antialiased{
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }

  .\32xl\:placeholder-transparent::-webkit-input-placeholder{
    color: transparent !important;
  }

  .\32xl\:placeholder-transparent:-ms-input-placeholder{
    color: transparent !important;
  }

  .\32xl\:placeholder-transparent::placeholder{
    color: transparent !important;
  }

  .\32xl\:placeholder-current::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .\32xl\:placeholder-current:-ms-input-placeholder{
    color: currentColor !important;
  }

  .\32xl\:placeholder-current::placeholder{
    color: currentColor !important;
  }

  .\32xl\:placeholder-black::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-black:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-black::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-white::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-white:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-white::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-gray-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-red-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-yellow-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-green-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-blue-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-indigo-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-purple-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-50::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-200::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-200:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-200::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-300::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-300:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-300::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-400::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-400:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-400::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-500::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-500:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-500::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-600::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-600:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-600::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-700::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-700:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-700::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-800::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-800:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-800::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-900::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-900:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-pink-900::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-qipGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-qipGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-qipGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-portalGreen::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-portalGreen:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-portalGreen::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-transparent:focus::-webkit-input-placeholder{
    color: transparent !important;
  }

  .\32xl\:focus\:placeholder-transparent:focus:-ms-input-placeholder{
    color: transparent !important;
  }

  .\32xl\:focus\:placeholder-transparent:focus::placeholder{
    color: transparent !important;
  }

  .\32xl\:focus\:placeholder-current:focus::-webkit-input-placeholder{
    color: currentColor !important;
  }

  .\32xl\:focus\:placeholder-current:focus:-ms-input-placeholder{
    color: currentColor !important;
  }

  .\32xl\:focus\:placeholder-current:focus::placeholder{
    color: currentColor !important;
  }

  .\32xl\:focus\:placeholder-black:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-black:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-black:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-white:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-white:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-white:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-gray-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-red-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-yellow-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-green-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-blue-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-indigo-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-purple-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-50:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-200:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-200:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-200:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-300:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-300:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-300:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-400:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-400:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-400:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-500:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-500:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-500:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-600:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-600:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-600:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-700:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-700:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-700:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-800:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-800:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-800:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-900:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-900:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-pink-900:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-qipGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-qipGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-qipGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(139, 195, 52, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-portalGreen:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-portalGreen:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:focus\:placeholder-portalGreen:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
    color: rgba(138, 192, 74, var(--tw-placeholder-opacity)) !important;
  }

  .\32xl\:placeholder-opacity-0::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:placeholder-opacity-0:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:placeholder-opacity-0::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:placeholder-opacity-5::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:placeholder-opacity-5:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:placeholder-opacity-5::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:placeholder-opacity-10::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:placeholder-opacity-10:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:placeholder-opacity-10::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:placeholder-opacity-20::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:placeholder-opacity-20:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:placeholder-opacity-20::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:placeholder-opacity-25::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:placeholder-opacity-25:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:placeholder-opacity-25::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:placeholder-opacity-30::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:placeholder-opacity-30:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:placeholder-opacity-30::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:placeholder-opacity-40::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:placeholder-opacity-40:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:placeholder-opacity-40::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:placeholder-opacity-50::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:placeholder-opacity-50:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:placeholder-opacity-50::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:placeholder-opacity-60::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:placeholder-opacity-60:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:placeholder-opacity-60::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:placeholder-opacity-70::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:placeholder-opacity-70:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:placeholder-opacity-70::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:placeholder-opacity-75::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:placeholder-opacity-75:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:placeholder-opacity-75::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:placeholder-opacity-80::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:placeholder-opacity-80:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:placeholder-opacity-80::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:placeholder-opacity-90::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:placeholder-opacity-90:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:placeholder-opacity-90::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:placeholder-opacity-95::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:placeholder-opacity-95:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:placeholder-opacity-95::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:placeholder-opacity-100::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:placeholder-opacity-100:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:placeholder-opacity-100::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:focus\:placeholder-opacity-0:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:focus\:placeholder-opacity-0:focus::placeholder{
    --tw-placeholder-opacity: 0 !important;
  }

  .\32xl\:focus\:placeholder-opacity-5:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:focus\:placeholder-opacity-5:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:focus\:placeholder-opacity-5:focus::placeholder{
    --tw-placeholder-opacity: 0.05 !important;
  }

  .\32xl\:focus\:placeholder-opacity-10:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:focus\:placeholder-opacity-10:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:focus\:placeholder-opacity-10:focus::placeholder{
    --tw-placeholder-opacity: 0.1 !important;
  }

  .\32xl\:focus\:placeholder-opacity-20:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:focus\:placeholder-opacity-20:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:focus\:placeholder-opacity-20:focus::placeholder{
    --tw-placeholder-opacity: 0.2 !important;
  }

  .\32xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:focus\:placeholder-opacity-25:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:focus\:placeholder-opacity-25:focus::placeholder{
    --tw-placeholder-opacity: 0.25 !important;
  }

  .\32xl\:focus\:placeholder-opacity-30:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:focus\:placeholder-opacity-30:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:focus\:placeholder-opacity-30:focus::placeholder{
    --tw-placeholder-opacity: 0.3 !important;
  }

  .\32xl\:focus\:placeholder-opacity-40:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:focus\:placeholder-opacity-40:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:focus\:placeholder-opacity-40:focus::placeholder{
    --tw-placeholder-opacity: 0.4 !important;
  }

  .\32xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:focus\:placeholder-opacity-50:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:focus\:placeholder-opacity-50:focus::placeholder{
    --tw-placeholder-opacity: 0.5 !important;
  }

  .\32xl\:focus\:placeholder-opacity-60:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:focus\:placeholder-opacity-60:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:focus\:placeholder-opacity-60:focus::placeholder{
    --tw-placeholder-opacity: 0.6 !important;
  }

  .\32xl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:focus\:placeholder-opacity-70:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:focus\:placeholder-opacity-70:focus::placeholder{
    --tw-placeholder-opacity: 0.7 !important;
  }

  .\32xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:focus\:placeholder-opacity-75:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:focus\:placeholder-opacity-75:focus::placeholder{
    --tw-placeholder-opacity: 0.75 !important;
  }

  .\32xl\:focus\:placeholder-opacity-80:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:focus\:placeholder-opacity-80:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:focus\:placeholder-opacity-80:focus::placeholder{
    --tw-placeholder-opacity: 0.8 !important;
  }

  .\32xl\:focus\:placeholder-opacity-90:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:focus\:placeholder-opacity-90:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:focus\:placeholder-opacity-90:focus::placeholder{
    --tw-placeholder-opacity: 0.9 !important;
  }

  .\32xl\:focus\:placeholder-opacity-95:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:focus\:placeholder-opacity-95:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:focus\:placeholder-opacity-95:focus::placeholder{
    --tw-placeholder-opacity: 0.95 !important;
  }

  .\32xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:focus\:placeholder-opacity-100:focus:-ms-input-placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:focus\:placeholder-opacity-100:focus::placeholder{
    --tw-placeholder-opacity: 1 !important;
  }

  .\32xl\:opacity-0{
    opacity: 0 !important;
  }

  .\32xl\:opacity-5{
    opacity: 0.05 !important;
  }

  .\32xl\:opacity-10{
    opacity: 0.1 !important;
  }

  .\32xl\:opacity-20{
    opacity: 0.2 !important;
  }

  .\32xl\:opacity-25{
    opacity: 0.25 !important;
  }

  .\32xl\:opacity-30{
    opacity: 0.3 !important;
  }

  .\32xl\:opacity-40{
    opacity: 0.4 !important;
  }

  .\32xl\:opacity-50{
    opacity: 0.5 !important;
  }

  .\32xl\:opacity-60{
    opacity: 0.6 !important;
  }

  .\32xl\:opacity-70{
    opacity: 0.7 !important;
  }

  .\32xl\:opacity-75{
    opacity: 0.75 !important;
  }

  .\32xl\:opacity-80{
    opacity: 0.8 !important;
  }

  .\32xl\:opacity-90{
    opacity: 0.9 !important;
  }

  .\32xl\:opacity-95{
    opacity: 0.95 !important;
  }

  .\32xl\:opacity-100{
    opacity: 1 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-0{
    opacity: 0 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-5{
    opacity: 0.05 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-10{
    opacity: 0.1 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-20{
    opacity: 0.2 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-25{
    opacity: 0.25 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-30{
    opacity: 0.3 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-40{
    opacity: 0.4 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-50{
    opacity: 0.5 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-60{
    opacity: 0.6 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-70{
    opacity: 0.7 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-75{
    opacity: 0.75 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-80{
    opacity: 0.8 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-90{
    opacity: 0.9 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-95{
    opacity: 0.95 !important;
  }

  .group:hover .\32xl\:group-hover\:opacity-100{
    opacity: 1 !important;
  }

  .\32xl\:focus-within\:opacity-0:focus-within{
    opacity: 0 !important;
  }

  .\32xl\:focus-within\:opacity-5:focus-within{
    opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:opacity-10:focus-within{
    opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:opacity-20:focus-within{
    opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:opacity-25:focus-within{
    opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:opacity-30:focus-within{
    opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:opacity-40:focus-within{
    opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:opacity-50:focus-within{
    opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:opacity-60:focus-within{
    opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:opacity-70:focus-within{
    opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:opacity-75:focus-within{
    opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:opacity-80:focus-within{
    opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:opacity-90:focus-within{
    opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:opacity-95:focus-within{
    opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:opacity-100:focus-within{
    opacity: 1 !important;
  }

  .\32xl\:hover\:opacity-0:hover{
    opacity: 0 !important;
  }

  .\32xl\:hover\:opacity-5:hover{
    opacity: 0.05 !important;
  }

  .\32xl\:hover\:opacity-10:hover{
    opacity: 0.1 !important;
  }

  .\32xl\:hover\:opacity-20:hover{
    opacity: 0.2 !important;
  }

  .\32xl\:hover\:opacity-25:hover{
    opacity: 0.25 !important;
  }

  .\32xl\:hover\:opacity-30:hover{
    opacity: 0.3 !important;
  }

  .\32xl\:hover\:opacity-40:hover{
    opacity: 0.4 !important;
  }

  .\32xl\:hover\:opacity-50:hover{
    opacity: 0.5 !important;
  }

  .\32xl\:hover\:opacity-60:hover{
    opacity: 0.6 !important;
  }

  .\32xl\:hover\:opacity-70:hover{
    opacity: 0.7 !important;
  }

  .\32xl\:hover\:opacity-75:hover{
    opacity: 0.75 !important;
  }

  .\32xl\:hover\:opacity-80:hover{
    opacity: 0.8 !important;
  }

  .\32xl\:hover\:opacity-90:hover{
    opacity: 0.9 !important;
  }

  .\32xl\:hover\:opacity-95:hover{
    opacity: 0.95 !important;
  }

  .\32xl\:hover\:opacity-100:hover{
    opacity: 1 !important;
  }

  .\32xl\:focus\:opacity-0:focus{
    opacity: 0 !important;
  }

  .\32xl\:focus\:opacity-5:focus{
    opacity: 0.05 !important;
  }

  .\32xl\:focus\:opacity-10:focus{
    opacity: 0.1 !important;
  }

  .\32xl\:focus\:opacity-20:focus{
    opacity: 0.2 !important;
  }

  .\32xl\:focus\:opacity-25:focus{
    opacity: 0.25 !important;
  }

  .\32xl\:focus\:opacity-30:focus{
    opacity: 0.3 !important;
  }

  .\32xl\:focus\:opacity-40:focus{
    opacity: 0.4 !important;
  }

  .\32xl\:focus\:opacity-50:focus{
    opacity: 0.5 !important;
  }

  .\32xl\:focus\:opacity-60:focus{
    opacity: 0.6 !important;
  }

  .\32xl\:focus\:opacity-70:focus{
    opacity: 0.7 !important;
  }

  .\32xl\:focus\:opacity-75:focus{
    opacity: 0.75 !important;
  }

  .\32xl\:focus\:opacity-80:focus{
    opacity: 0.8 !important;
  }

  .\32xl\:focus\:opacity-90:focus{
    opacity: 0.9 !important;
  }

  .\32xl\:focus\:opacity-95:focus{
    opacity: 0.95 !important;
  }

  .\32xl\:focus\:opacity-100:focus{
    opacity: 1 !important;
  }

  .\32xl\:bg-blend-normal{
    background-blend-mode: normal !important;
  }

  .\32xl\:bg-blend-multiply{
    background-blend-mode: multiply !important;
  }

  .\32xl\:bg-blend-screen{
    background-blend-mode: screen !important;
  }

  .\32xl\:bg-blend-overlay{
    background-blend-mode: overlay !important;
  }

  .\32xl\:bg-blend-darken{
    background-blend-mode: darken !important;
  }

  .\32xl\:bg-blend-lighten{
    background-blend-mode: lighten !important;
  }

  .\32xl\:bg-blend-color-dodge{
    background-blend-mode: color-dodge !important;
  }

  .\32xl\:bg-blend-color-burn{
    background-blend-mode: color-burn !important;
  }

  .\32xl\:bg-blend-hard-light{
    background-blend-mode: hard-light !important;
  }

  .\32xl\:bg-blend-soft-light{
    background-blend-mode: soft-light !important;
  }

  .\32xl\:bg-blend-difference{
    background-blend-mode: difference !important;
  }

  .\32xl\:bg-blend-exclusion{
    background-blend-mode: exclusion !important;
  }

  .\32xl\:bg-blend-hue{
    background-blend-mode: hue !important;
  }

  .\32xl\:bg-blend-saturation{
    background-blend-mode: saturation !important;
  }

  .\32xl\:bg-blend-color{
    background-blend-mode: color !important;
  }

  .\32xl\:bg-blend-luminosity{
    background-blend-mode: luminosity !important;
  }

  .\32xl\:mix-blend-normal{
    mix-blend-mode: normal !important;
  }

  .\32xl\:mix-blend-multiply{
    mix-blend-mode: multiply !important;
  }

  .\32xl\:mix-blend-screen{
    mix-blend-mode: screen !important;
  }

  .\32xl\:mix-blend-overlay{
    mix-blend-mode: overlay !important;
  }

  .\32xl\:mix-blend-darken{
    mix-blend-mode: darken !important;
  }

  .\32xl\:mix-blend-lighten{
    mix-blend-mode: lighten !important;
  }

  .\32xl\:mix-blend-color-dodge{
    mix-blend-mode: color-dodge !important;
  }

  .\32xl\:mix-blend-color-burn{
    mix-blend-mode: color-burn !important;
  }

  .\32xl\:mix-blend-hard-light{
    mix-blend-mode: hard-light !important;
  }

  .\32xl\:mix-blend-soft-light{
    mix-blend-mode: soft-light !important;
  }

  .\32xl\:mix-blend-difference{
    mix-blend-mode: difference !important;
  }

  .\32xl\:mix-blend-exclusion{
    mix-blend-mode: exclusion !important;
  }

  .\32xl\:mix-blend-hue{
    mix-blend-mode: hue !important;
  }

  .\32xl\:mix-blend-saturation{
    mix-blend-mode: saturation !important;
  }

  .\32xl\:mix-blend-color{
    mix-blend-mode: color !important;
  }

  .\32xl\:mix-blend-luminosity{
    mix-blend-mode: luminosity !important;
  }

  .\32xl\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-md{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-xl{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-2xl{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-inner{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .group:hover .\32xl\:group-hover\:shadow-none{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-sm:focus-within{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow:focus-within{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-md:focus-within{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-lg:focus-within{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-xl:focus-within{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-2xl:focus-within{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-inner:focus-within{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus-within\:shadow-none:focus-within{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-sm:hover{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow:hover{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-md:hover{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-lg:hover{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-xl:hover{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-2xl:hover{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-inner:hover{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:hover\:shadow-none:hover{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-sm:focus{
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow:focus{
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-md:focus{
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-lg:focus{
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-xl:focus{
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-2xl:focus{
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-inner:focus{
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:focus\:shadow-none:focus{
    --tw-shadow: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }

  .\32xl\:outline-none{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .\32xl\:outline-white{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .\32xl\:outline-black{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus-within\:outline-none:focus-within{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus-within\:outline-white:focus-within{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus-within\:outline-black:focus-within{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus\:outline-none:focus{
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus\:outline-white:focus{
    outline: 2px dotted white !important;
    outline-offset: 2px !important;
  }

  .\32xl\:focus\:outline-black:focus{
    outline: 2px dotted black !important;
    outline-offset: 2px !important;
  }

  .\32xl\:ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:ring-1{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:ring-4{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:ring-8{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:ring{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:ring-0:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:ring-1:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:ring-4:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:ring-8:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus-within\:ring:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:ring-8:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:focus\:ring:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  }

  .\32xl\:ring-inset{
    --tw-ring-inset: inset !important;
  }

  .\32xl\:focus-within\:ring-inset:focus-within{
    --tw-ring-inset: inset !important;
  }

  .\32xl\:focus\:ring-inset:focus{
    --tw-ring-inset: inset !important;
  }

  .\32xl\:ring-transparent{
    --tw-ring-color: transparent !important;
  }

  .\32xl\:ring-current{
    --tw-ring-color: currentColor !important;
  }

  .\32xl\:ring-black{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-white{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-gray-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-red-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-yellow-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-green-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-blue-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-indigo-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-purple-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-50{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-100{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-200{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-300{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-400{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-500{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-600{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-700{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-800{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-pink-900{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-qipGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-portalGreen{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-transparent:focus-within{
    --tw-ring-color: transparent !important;
  }

  .\32xl\:focus-within\:ring-current:focus-within{
    --tw-ring-color: currentColor !important;
  }

  .\32xl\:focus-within\:ring-black:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-white:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-gray-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-red-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-yellow-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-green-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-blue-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-indigo-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-purple-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-50:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-100:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-200:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-300:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-400:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-500:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-600:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-700:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-800:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-pink-900:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-qipGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus-within\:ring-portalGreen:focus-within{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-transparent:focus{
    --tw-ring-color: transparent !important;
  }

  .\32xl\:focus\:ring-current:focus{
    --tw-ring-color: currentColor !important;
  }

  .\32xl\:focus\:ring-black:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-white:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-gray-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-red-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-yellow-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-green-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-blue-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-indigo-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-purple-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-50:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-100:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-200:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-300:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-400:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-500:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-600:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-700:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-800:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-pink-900:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-qipGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(139, 195, 52, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:focus\:ring-portalGreen:focus{
    --tw-ring-opacity: 1 !important;
    --tw-ring-color: rgba(138, 192, 74, var(--tw-ring-opacity)) !important;
  }

  .\32xl\:ring-opacity-0{
    --tw-ring-opacity: 0 !important;
  }

  .\32xl\:ring-opacity-5{
    --tw-ring-opacity: 0.05 !important;
  }

  .\32xl\:ring-opacity-10{
    --tw-ring-opacity: 0.1 !important;
  }

  .\32xl\:ring-opacity-20{
    --tw-ring-opacity: 0.2 !important;
  }

  .\32xl\:ring-opacity-25{
    --tw-ring-opacity: 0.25 !important;
  }

  .\32xl\:ring-opacity-30{
    --tw-ring-opacity: 0.3 !important;
  }

  .\32xl\:ring-opacity-40{
    --tw-ring-opacity: 0.4 !important;
  }

  .\32xl\:ring-opacity-50{
    --tw-ring-opacity: 0.5 !important;
  }

  .\32xl\:ring-opacity-60{
    --tw-ring-opacity: 0.6 !important;
  }

  .\32xl\:ring-opacity-70{
    --tw-ring-opacity: 0.7 !important;
  }

  .\32xl\:ring-opacity-75{
    --tw-ring-opacity: 0.75 !important;
  }

  .\32xl\:ring-opacity-80{
    --tw-ring-opacity: 0.8 !important;
  }

  .\32xl\:ring-opacity-90{
    --tw-ring-opacity: 0.9 !important;
  }

  .\32xl\:ring-opacity-95{
    --tw-ring-opacity: 0.95 !important;
  }

  .\32xl\:ring-opacity-100{
    --tw-ring-opacity: 1 !important;
  }

  .\32xl\:focus-within\:ring-opacity-0:focus-within{
    --tw-ring-opacity: 0 !important;
  }

  .\32xl\:focus-within\:ring-opacity-5:focus-within{
    --tw-ring-opacity: 0.05 !important;
  }

  .\32xl\:focus-within\:ring-opacity-10:focus-within{
    --tw-ring-opacity: 0.1 !important;
  }

  .\32xl\:focus-within\:ring-opacity-20:focus-within{
    --tw-ring-opacity: 0.2 !important;
  }

  .\32xl\:focus-within\:ring-opacity-25:focus-within{
    --tw-ring-opacity: 0.25 !important;
  }

  .\32xl\:focus-within\:ring-opacity-30:focus-within{
    --tw-ring-opacity: 0.3 !important;
  }

  .\32xl\:focus-within\:ring-opacity-40:focus-within{
    --tw-ring-opacity: 0.4 !important;
  }

  .\32xl\:focus-within\:ring-opacity-50:focus-within{
    --tw-ring-opacity: 0.5 !important;
  }

  .\32xl\:focus-within\:ring-opacity-60:focus-within{
    --tw-ring-opacity: 0.6 !important;
  }

  .\32xl\:focus-within\:ring-opacity-70:focus-within{
    --tw-ring-opacity: 0.7 !important;
  }

  .\32xl\:focus-within\:ring-opacity-75:focus-within{
    --tw-ring-opacity: 0.75 !important;
  }

  .\32xl\:focus-within\:ring-opacity-80:focus-within{
    --tw-ring-opacity: 0.8 !important;
  }

  .\32xl\:focus-within\:ring-opacity-90:focus-within{
    --tw-ring-opacity: 0.9 !important;
  }

  .\32xl\:focus-within\:ring-opacity-95:focus-within{
    --tw-ring-opacity: 0.95 !important;
  }

  .\32xl\:focus-within\:ring-opacity-100:focus-within{
    --tw-ring-opacity: 1 !important;
  }

  .\32xl\:focus\:ring-opacity-0:focus{
    --tw-ring-opacity: 0 !important;
  }

  .\32xl\:focus\:ring-opacity-5:focus{
    --tw-ring-opacity: 0.05 !important;
  }

  .\32xl\:focus\:ring-opacity-10:focus{
    --tw-ring-opacity: 0.1 !important;
  }

  .\32xl\:focus\:ring-opacity-20:focus{
    --tw-ring-opacity: 0.2 !important;
  }

  .\32xl\:focus\:ring-opacity-25:focus{
    --tw-ring-opacity: 0.25 !important;
  }

  .\32xl\:focus\:ring-opacity-30:focus{
    --tw-ring-opacity: 0.3 !important;
  }

  .\32xl\:focus\:ring-opacity-40:focus{
    --tw-ring-opacity: 0.4 !important;
  }

  .\32xl\:focus\:ring-opacity-50:focus{
    --tw-ring-opacity: 0.5 !important;
  }

  .\32xl\:focus\:ring-opacity-60:focus{
    --tw-ring-opacity: 0.6 !important;
  }

  .\32xl\:focus\:ring-opacity-70:focus{
    --tw-ring-opacity: 0.7 !important;
  }

  .\32xl\:focus\:ring-opacity-75:focus{
    --tw-ring-opacity: 0.75 !important;
  }

  .\32xl\:focus\:ring-opacity-80:focus{
    --tw-ring-opacity: 0.8 !important;
  }

  .\32xl\:focus\:ring-opacity-90:focus{
    --tw-ring-opacity: 0.9 !important;
  }

  .\32xl\:focus\:ring-opacity-95:focus{
    --tw-ring-opacity: 0.95 !important;
  }

  .\32xl\:focus\:ring-opacity-100:focus{
    --tw-ring-opacity: 1 !important;
  }

  .\32xl\:ring-offset-0{
    --tw-ring-offset-width: 0px !important;
  }

  .\32xl\:ring-offset-1{
    --tw-ring-offset-width: 1px !important;
  }

  .\32xl\:ring-offset-2{
    --tw-ring-offset-width: 2px !important;
  }

  .\32xl\:ring-offset-4{
    --tw-ring-offset-width: 4px !important;
  }

  .\32xl\:ring-offset-8{
    --tw-ring-offset-width: 8px !important;
  }

  .\32xl\:focus-within\:ring-offset-0:focus-within{
    --tw-ring-offset-width: 0px !important;
  }

  .\32xl\:focus-within\:ring-offset-1:focus-within{
    --tw-ring-offset-width: 1px !important;
  }

  .\32xl\:focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px !important;
  }

  .\32xl\:focus-within\:ring-offset-4:focus-within{
    --tw-ring-offset-width: 4px !important;
  }

  .\32xl\:focus-within\:ring-offset-8:focus-within{
    --tw-ring-offset-width: 8px !important;
  }

  .\32xl\:focus\:ring-offset-0:focus{
    --tw-ring-offset-width: 0px !important;
  }

  .\32xl\:focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px !important;
  }

  .\32xl\:focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px !important;
  }

  .\32xl\:focus\:ring-offset-4:focus{
    --tw-ring-offset-width: 4px !important;
  }

  .\32xl\:focus\:ring-offset-8:focus{
    --tw-ring-offset-width: 8px !important;
  }

  .\32xl\:ring-offset-transparent{
    --tw-ring-offset-color: transparent !important;
  }

  .\32xl\:ring-offset-current{
    --tw-ring-offset-color: currentColor !important;
  }

  .\32xl\:ring-offset-black{
    --tw-ring-offset-color: #000 !important;
  }

  .\32xl\:ring-offset-white{
    --tw-ring-offset-color: #fff !important;
  }

  .\32xl\:ring-offset-gray-50{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .\32xl\:ring-offset-gray-100{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .\32xl\:ring-offset-gray-200{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .\32xl\:ring-offset-gray-300{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .\32xl\:ring-offset-gray-400{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .\32xl\:ring-offset-gray-500{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .\32xl\:ring-offset-gray-600{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .\32xl\:ring-offset-gray-700{
    --tw-ring-offset-color: #374151 !important;
  }

  .\32xl\:ring-offset-gray-800{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .\32xl\:ring-offset-gray-900{
    --tw-ring-offset-color: #111827 !important;
  }

  .\32xl\:ring-offset-red-50{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .\32xl\:ring-offset-red-100{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .\32xl\:ring-offset-red-200{
    --tw-ring-offset-color: #fecaca !important;
  }

  .\32xl\:ring-offset-red-300{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .\32xl\:ring-offset-red-400{
    --tw-ring-offset-color: #f87171 !important;
  }

  .\32xl\:ring-offset-red-500{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .\32xl\:ring-offset-red-600{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .\32xl\:ring-offset-red-700{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .\32xl\:ring-offset-red-800{
    --tw-ring-offset-color: #991b1b !important;
  }

  .\32xl\:ring-offset-red-900{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .\32xl\:ring-offset-yellow-50{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .\32xl\:ring-offset-yellow-100{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .\32xl\:ring-offset-yellow-200{
    --tw-ring-offset-color: #fde68a !important;
  }

  .\32xl\:ring-offset-yellow-300{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .\32xl\:ring-offset-yellow-400{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .\32xl\:ring-offset-yellow-500{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .\32xl\:ring-offset-yellow-600{
    --tw-ring-offset-color: #d97706 !important;
  }

  .\32xl\:ring-offset-yellow-700{
    --tw-ring-offset-color: #b45309 !important;
  }

  .\32xl\:ring-offset-yellow-800{
    --tw-ring-offset-color: #92400e !important;
  }

  .\32xl\:ring-offset-yellow-900{
    --tw-ring-offset-color: #78350f !important;
  }

  .\32xl\:ring-offset-green-50{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .\32xl\:ring-offset-green-100{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .\32xl\:ring-offset-green-200{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .\32xl\:ring-offset-green-300{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .\32xl\:ring-offset-green-400{
    --tw-ring-offset-color: #34d399 !important;
  }

  .\32xl\:ring-offset-green-500{
    --tw-ring-offset-color: #10b981 !important;
  }

  .\32xl\:ring-offset-green-600{
    --tw-ring-offset-color: #059669 !important;
  }

  .\32xl\:ring-offset-green-700{
    --tw-ring-offset-color: #047857 !important;
  }

  .\32xl\:ring-offset-green-800{
    --tw-ring-offset-color: #065f46 !important;
  }

  .\32xl\:ring-offset-green-900{
    --tw-ring-offset-color: #064e3b !important;
  }

  .\32xl\:ring-offset-blue-50{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .\32xl\:ring-offset-blue-100{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .\32xl\:ring-offset-blue-200{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .\32xl\:ring-offset-blue-300{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .\32xl\:ring-offset-blue-400{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .\32xl\:ring-offset-blue-500{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .\32xl\:ring-offset-blue-600{
    --tw-ring-offset-color: #2563eb !important;
  }

  .\32xl\:ring-offset-blue-700{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .\32xl\:ring-offset-blue-800{
    --tw-ring-offset-color: #1e40af !important;
  }

  .\32xl\:ring-offset-blue-900{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .\32xl\:ring-offset-indigo-50{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .\32xl\:ring-offset-indigo-100{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .\32xl\:ring-offset-indigo-200{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .\32xl\:ring-offset-indigo-300{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .\32xl\:ring-offset-indigo-400{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .\32xl\:ring-offset-indigo-500{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .\32xl\:ring-offset-indigo-600{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .\32xl\:ring-offset-indigo-700{
    --tw-ring-offset-color: #4338ca !important;
  }

  .\32xl\:ring-offset-indigo-800{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .\32xl\:ring-offset-indigo-900{
    --tw-ring-offset-color: #312e81 !important;
  }

  .\32xl\:ring-offset-purple-50{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .\32xl\:ring-offset-purple-100{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .\32xl\:ring-offset-purple-200{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .\32xl\:ring-offset-purple-300{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .\32xl\:ring-offset-purple-400{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .\32xl\:ring-offset-purple-500{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .\32xl\:ring-offset-purple-600{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .\32xl\:ring-offset-purple-700{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .\32xl\:ring-offset-purple-800{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .\32xl\:ring-offset-purple-900{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .\32xl\:ring-offset-pink-50{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .\32xl\:ring-offset-pink-100{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .\32xl\:ring-offset-pink-200{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .\32xl\:ring-offset-pink-300{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .\32xl\:ring-offset-pink-400{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .\32xl\:ring-offset-pink-500{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .\32xl\:ring-offset-pink-600{
    --tw-ring-offset-color: #db2777 !important;
  }

  .\32xl\:ring-offset-pink-700{
    --tw-ring-offset-color: #be185d !important;
  }

  .\32xl\:ring-offset-pink-800{
    --tw-ring-offset-color: #9d174d !important;
  }

  .\32xl\:ring-offset-pink-900{
    --tw-ring-offset-color: #831843 !important;
  }

  .\32xl\:ring-offset-qipGreen{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .\32xl\:ring-offset-portalGreen{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .\32xl\:focus-within\:ring-offset-transparent:focus-within{
    --tw-ring-offset-color: transparent !important;
  }

  .\32xl\:focus-within\:ring-offset-current:focus-within{
    --tw-ring-offset-color: currentColor !important;
  }

  .\32xl\:focus-within\:ring-offset-black:focus-within{
    --tw-ring-offset-color: #000 !important;
  }

  .\32xl\:focus-within\:ring-offset-white:focus-within{
    --tw-ring-offset-color: #fff !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-50:focus-within{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-100:focus-within{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-200:focus-within{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-300:focus-within{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-400:focus-within{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-500:focus-within{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-600:focus-within{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-700:focus-within{
    --tw-ring-offset-color: #374151 !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-800:focus-within{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .\32xl\:focus-within\:ring-offset-gray-900:focus-within{
    --tw-ring-offset-color: #111827 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-50:focus-within{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-100:focus-within{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-200:focus-within{
    --tw-ring-offset-color: #fecaca !important;
  }

  .\32xl\:focus-within\:ring-offset-red-300:focus-within{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-400:focus-within{
    --tw-ring-offset-color: #f87171 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-500:focus-within{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-600:focus-within{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .\32xl\:focus-within\:ring-offset-red-700:focus-within{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .\32xl\:focus-within\:ring-offset-red-800:focus-within{
    --tw-ring-offset-color: #991b1b !important;
  }

  .\32xl\:focus-within\:ring-offset-red-900:focus-within{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-50:focus-within{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-100:focus-within{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-200:focus-within{
    --tw-ring-offset-color: #fde68a !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-300:focus-within{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-400:focus-within{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-500:focus-within{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-600:focus-within{
    --tw-ring-offset-color: #d97706 !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-700:focus-within{
    --tw-ring-offset-color: #b45309 !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-800:focus-within{
    --tw-ring-offset-color: #92400e !important;
  }

  .\32xl\:focus-within\:ring-offset-yellow-900:focus-within{
    --tw-ring-offset-color: #78350f !important;
  }

  .\32xl\:focus-within\:ring-offset-green-50:focus-within{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-100:focus-within{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-200:focus-within{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-300:focus-within{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-400:focus-within{
    --tw-ring-offset-color: #34d399 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-500:focus-within{
    --tw-ring-offset-color: #10b981 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-600:focus-within{
    --tw-ring-offset-color: #059669 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-700:focus-within{
    --tw-ring-offset-color: #047857 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-800:focus-within{
    --tw-ring-offset-color: #065f46 !important;
  }

  .\32xl\:focus-within\:ring-offset-green-900:focus-within{
    --tw-ring-offset-color: #064e3b !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-50:focus-within{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-100:focus-within{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-200:focus-within{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-300:focus-within{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-400:focus-within{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-500:focus-within{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-600:focus-within{
    --tw-ring-offset-color: #2563eb !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-700:focus-within{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-800:focus-within{
    --tw-ring-offset-color: #1e40af !important;
  }

  .\32xl\:focus-within\:ring-offset-blue-900:focus-within{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-50:focus-within{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-100:focus-within{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-200:focus-within{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-300:focus-within{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-400:focus-within{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-500:focus-within{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-600:focus-within{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-700:focus-within{
    --tw-ring-offset-color: #4338ca !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-800:focus-within{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .\32xl\:focus-within\:ring-offset-indigo-900:focus-within{
    --tw-ring-offset-color: #312e81 !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-50:focus-within{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-100:focus-within{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-200:focus-within{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-300:focus-within{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-400:focus-within{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-500:focus-within{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-600:focus-within{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-700:focus-within{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-800:focus-within{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .\32xl\:focus-within\:ring-offset-purple-900:focus-within{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-50:focus-within{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-100:focus-within{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-200:focus-within{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-300:focus-within{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-400:focus-within{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-500:focus-within{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-600:focus-within{
    --tw-ring-offset-color: #db2777 !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-700:focus-within{
    --tw-ring-offset-color: #be185d !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-800:focus-within{
    --tw-ring-offset-color: #9d174d !important;
  }

  .\32xl\:focus-within\:ring-offset-pink-900:focus-within{
    --tw-ring-offset-color: #831843 !important;
  }

  .\32xl\:focus-within\:ring-offset-qipGreen:focus-within{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .\32xl\:focus-within\:ring-offset-portalGreen:focus-within{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .\32xl\:focus\:ring-offset-transparent:focus{
    --tw-ring-offset-color: transparent !important;
  }

  .\32xl\:focus\:ring-offset-current:focus{
    --tw-ring-offset-color: currentColor !important;
  }

  .\32xl\:focus\:ring-offset-black:focus{
    --tw-ring-offset-color: #000 !important;
  }

  .\32xl\:focus\:ring-offset-white:focus{
    --tw-ring-offset-color: #fff !important;
  }

  .\32xl\:focus\:ring-offset-gray-50:focus{
    --tw-ring-offset-color: #f9fafb !important;
  }

  .\32xl\:focus\:ring-offset-gray-100:focus{
    --tw-ring-offset-color: #f3f4f6 !important;
  }

  .\32xl\:focus\:ring-offset-gray-200:focus{
    --tw-ring-offset-color: #e5e7eb !important;
  }

  .\32xl\:focus\:ring-offset-gray-300:focus{
    --tw-ring-offset-color: #d1d5db !important;
  }

  .\32xl\:focus\:ring-offset-gray-400:focus{
    --tw-ring-offset-color: #9ca3af !important;
  }

  .\32xl\:focus\:ring-offset-gray-500:focus{
    --tw-ring-offset-color: #6b7280 !important;
  }

  .\32xl\:focus\:ring-offset-gray-600:focus{
    --tw-ring-offset-color: #4b5563 !important;
  }

  .\32xl\:focus\:ring-offset-gray-700:focus{
    --tw-ring-offset-color: #374151 !important;
  }

  .\32xl\:focus\:ring-offset-gray-800:focus{
    --tw-ring-offset-color: #1f2937 !important;
  }

  .\32xl\:focus\:ring-offset-gray-900:focus{
    --tw-ring-offset-color: #111827 !important;
  }

  .\32xl\:focus\:ring-offset-red-50:focus{
    --tw-ring-offset-color: #fef2f2 !important;
  }

  .\32xl\:focus\:ring-offset-red-100:focus{
    --tw-ring-offset-color: #fee2e2 !important;
  }

  .\32xl\:focus\:ring-offset-red-200:focus{
    --tw-ring-offset-color: #fecaca !important;
  }

  .\32xl\:focus\:ring-offset-red-300:focus{
    --tw-ring-offset-color: #fca5a5 !important;
  }

  .\32xl\:focus\:ring-offset-red-400:focus{
    --tw-ring-offset-color: #f87171 !important;
  }

  .\32xl\:focus\:ring-offset-red-500:focus{
    --tw-ring-offset-color: #ef4444 !important;
  }

  .\32xl\:focus\:ring-offset-red-600:focus{
    --tw-ring-offset-color: #dc2626 !important;
  }

  .\32xl\:focus\:ring-offset-red-700:focus{
    --tw-ring-offset-color: #b91c1c !important;
  }

  .\32xl\:focus\:ring-offset-red-800:focus{
    --tw-ring-offset-color: #991b1b !important;
  }

  .\32xl\:focus\:ring-offset-red-900:focus{
    --tw-ring-offset-color: #7f1d1d !important;
  }

  .\32xl\:focus\:ring-offset-yellow-50:focus{
    --tw-ring-offset-color: #fffbeb !important;
  }

  .\32xl\:focus\:ring-offset-yellow-100:focus{
    --tw-ring-offset-color: #fef3c7 !important;
  }

  .\32xl\:focus\:ring-offset-yellow-200:focus{
    --tw-ring-offset-color: #fde68a !important;
  }

  .\32xl\:focus\:ring-offset-yellow-300:focus{
    --tw-ring-offset-color: #fcd34d !important;
  }

  .\32xl\:focus\:ring-offset-yellow-400:focus{
    --tw-ring-offset-color: #fbbf24 !important;
  }

  .\32xl\:focus\:ring-offset-yellow-500:focus{
    --tw-ring-offset-color: #f59e0b !important;
  }

  .\32xl\:focus\:ring-offset-yellow-600:focus{
    --tw-ring-offset-color: #d97706 !important;
  }

  .\32xl\:focus\:ring-offset-yellow-700:focus{
    --tw-ring-offset-color: #b45309 !important;
  }

  .\32xl\:focus\:ring-offset-yellow-800:focus{
    --tw-ring-offset-color: #92400e !important;
  }

  .\32xl\:focus\:ring-offset-yellow-900:focus{
    --tw-ring-offset-color: #78350f !important;
  }

  .\32xl\:focus\:ring-offset-green-50:focus{
    --tw-ring-offset-color: #ecfdf5 !important;
  }

  .\32xl\:focus\:ring-offset-green-100:focus{
    --tw-ring-offset-color: #d1fae5 !important;
  }

  .\32xl\:focus\:ring-offset-green-200:focus{
    --tw-ring-offset-color: #a7f3d0 !important;
  }

  .\32xl\:focus\:ring-offset-green-300:focus{
    --tw-ring-offset-color: #6ee7b7 !important;
  }

  .\32xl\:focus\:ring-offset-green-400:focus{
    --tw-ring-offset-color: #34d399 !important;
  }

  .\32xl\:focus\:ring-offset-green-500:focus{
    --tw-ring-offset-color: #10b981 !important;
  }

  .\32xl\:focus\:ring-offset-green-600:focus{
    --tw-ring-offset-color: #059669 !important;
  }

  .\32xl\:focus\:ring-offset-green-700:focus{
    --tw-ring-offset-color: #047857 !important;
  }

  .\32xl\:focus\:ring-offset-green-800:focus{
    --tw-ring-offset-color: #065f46 !important;
  }

  .\32xl\:focus\:ring-offset-green-900:focus{
    --tw-ring-offset-color: #064e3b !important;
  }

  .\32xl\:focus\:ring-offset-blue-50:focus{
    --tw-ring-offset-color: #eff6ff !important;
  }

  .\32xl\:focus\:ring-offset-blue-100:focus{
    --tw-ring-offset-color: #dbeafe !important;
  }

  .\32xl\:focus\:ring-offset-blue-200:focus{
    --tw-ring-offset-color: #bfdbfe !important;
  }

  .\32xl\:focus\:ring-offset-blue-300:focus{
    --tw-ring-offset-color: #93c5fd !important;
  }

  .\32xl\:focus\:ring-offset-blue-400:focus{
    --tw-ring-offset-color: #60a5fa !important;
  }

  .\32xl\:focus\:ring-offset-blue-500:focus{
    --tw-ring-offset-color: #3b82f6 !important;
  }

  .\32xl\:focus\:ring-offset-blue-600:focus{
    --tw-ring-offset-color: #2563eb !important;
  }

  .\32xl\:focus\:ring-offset-blue-700:focus{
    --tw-ring-offset-color: #1d4ed8 !important;
  }

  .\32xl\:focus\:ring-offset-blue-800:focus{
    --tw-ring-offset-color: #1e40af !important;
  }

  .\32xl\:focus\:ring-offset-blue-900:focus{
    --tw-ring-offset-color: #1e3a8a !important;
  }

  .\32xl\:focus\:ring-offset-indigo-50:focus{
    --tw-ring-offset-color: #eef2ff !important;
  }

  .\32xl\:focus\:ring-offset-indigo-100:focus{
    --tw-ring-offset-color: #e0e7ff !important;
  }

  .\32xl\:focus\:ring-offset-indigo-200:focus{
    --tw-ring-offset-color: #c7d2fe !important;
  }

  .\32xl\:focus\:ring-offset-indigo-300:focus{
    --tw-ring-offset-color: #a5b4fc !important;
  }

  .\32xl\:focus\:ring-offset-indigo-400:focus{
    --tw-ring-offset-color: #818cf8 !important;
  }

  .\32xl\:focus\:ring-offset-indigo-500:focus{
    --tw-ring-offset-color: #6366f1 !important;
  }

  .\32xl\:focus\:ring-offset-indigo-600:focus{
    --tw-ring-offset-color: #4f46e5 !important;
  }

  .\32xl\:focus\:ring-offset-indigo-700:focus{
    --tw-ring-offset-color: #4338ca !important;
  }

  .\32xl\:focus\:ring-offset-indigo-800:focus{
    --tw-ring-offset-color: #3730a3 !important;
  }

  .\32xl\:focus\:ring-offset-indigo-900:focus{
    --tw-ring-offset-color: #312e81 !important;
  }

  .\32xl\:focus\:ring-offset-purple-50:focus{
    --tw-ring-offset-color: #f5f3ff !important;
  }

  .\32xl\:focus\:ring-offset-purple-100:focus{
    --tw-ring-offset-color: #ede9fe !important;
  }

  .\32xl\:focus\:ring-offset-purple-200:focus{
    --tw-ring-offset-color: #ddd6fe !important;
  }

  .\32xl\:focus\:ring-offset-purple-300:focus{
    --tw-ring-offset-color: #c4b5fd !important;
  }

  .\32xl\:focus\:ring-offset-purple-400:focus{
    --tw-ring-offset-color: #a78bfa !important;
  }

  .\32xl\:focus\:ring-offset-purple-500:focus{
    --tw-ring-offset-color: #8b5cf6 !important;
  }

  .\32xl\:focus\:ring-offset-purple-600:focus{
    --tw-ring-offset-color: #7c3aed !important;
  }

  .\32xl\:focus\:ring-offset-purple-700:focus{
    --tw-ring-offset-color: #6d28d9 !important;
  }

  .\32xl\:focus\:ring-offset-purple-800:focus{
    --tw-ring-offset-color: #5b21b6 !important;
  }

  .\32xl\:focus\:ring-offset-purple-900:focus{
    --tw-ring-offset-color: #4c1d95 !important;
  }

  .\32xl\:focus\:ring-offset-pink-50:focus{
    --tw-ring-offset-color: #fdf2f8 !important;
  }

  .\32xl\:focus\:ring-offset-pink-100:focus{
    --tw-ring-offset-color: #fce7f3 !important;
  }

  .\32xl\:focus\:ring-offset-pink-200:focus{
    --tw-ring-offset-color: #fbcfe8 !important;
  }

  .\32xl\:focus\:ring-offset-pink-300:focus{
    --tw-ring-offset-color: #f9a8d4 !important;
  }

  .\32xl\:focus\:ring-offset-pink-400:focus{
    --tw-ring-offset-color: #f472b6 !important;
  }

  .\32xl\:focus\:ring-offset-pink-500:focus{
    --tw-ring-offset-color: #ec4899 !important;
  }

  .\32xl\:focus\:ring-offset-pink-600:focus{
    --tw-ring-offset-color: #db2777 !important;
  }

  .\32xl\:focus\:ring-offset-pink-700:focus{
    --tw-ring-offset-color: #be185d !important;
  }

  .\32xl\:focus\:ring-offset-pink-800:focus{
    --tw-ring-offset-color: #9d174d !important;
  }

  .\32xl\:focus\:ring-offset-pink-900:focus{
    --tw-ring-offset-color: #831843 !important;
  }

  .\32xl\:focus\:ring-offset-qipGreen:focus{
    --tw-ring-offset-color: #8bc334 !important;
  }

  .\32xl\:focus\:ring-offset-portalGreen:focus{
    --tw-ring-offset-color: #8ac04a !important;
  }

  .\32xl\:filter{
    --tw-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
            filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
  }

  .\32xl\:filter-none{
    -webkit-filter: none !important;
            filter: none !important;
  }

  .\32xl\:blur-0{
    --tw-blur: blur(0) !important;
  }

  .\32xl\:blur-none{
    --tw-blur: blur(0) !important;
  }

  .\32xl\:blur-sm{
    --tw-blur: blur(4px) !important;
  }

  .\32xl\:blur{
    --tw-blur: blur(8px) !important;
  }

  .\32xl\:blur-md{
    --tw-blur: blur(12px) !important;
  }

  .\32xl\:blur-lg{
    --tw-blur: blur(16px) !important;
  }

  .\32xl\:blur-xl{
    --tw-blur: blur(24px) !important;
  }

  .\32xl\:blur-2xl{
    --tw-blur: blur(40px) !important;
  }

  .\32xl\:blur-3xl{
    --tw-blur: blur(64px) !important;
  }

  .\32xl\:brightness-0{
    --tw-brightness: brightness(0) !important;
  }

  .\32xl\:brightness-50{
    --tw-brightness: brightness(.5) !important;
  }

  .\32xl\:brightness-75{
    --tw-brightness: brightness(.75) !important;
  }

  .\32xl\:brightness-90{
    --tw-brightness: brightness(.9) !important;
  }

  .\32xl\:brightness-95{
    --tw-brightness: brightness(.95) !important;
  }

  .\32xl\:brightness-100{
    --tw-brightness: brightness(1) !important;
  }

  .\32xl\:brightness-105{
    --tw-brightness: brightness(1.05) !important;
  }

  .\32xl\:brightness-110{
    --tw-brightness: brightness(1.1) !important;
  }

  .\32xl\:brightness-125{
    --tw-brightness: brightness(1.25) !important;
  }

  .\32xl\:brightness-150{
    --tw-brightness: brightness(1.5) !important;
  }

  .\32xl\:brightness-200{
    --tw-brightness: brightness(2) !important;
  }

  .\32xl\:contrast-0{
    --tw-contrast: contrast(0) !important;
  }

  .\32xl\:contrast-50{
    --tw-contrast: contrast(.5) !important;
  }

  .\32xl\:contrast-75{
    --tw-contrast: contrast(.75) !important;
  }

  .\32xl\:contrast-100{
    --tw-contrast: contrast(1) !important;
  }

  .\32xl\:contrast-125{
    --tw-contrast: contrast(1.25) !important;
  }

  .\32xl\:contrast-150{
    --tw-contrast: contrast(1.5) !important;
  }

  .\32xl\:contrast-200{
    --tw-contrast: contrast(2) !important;
  }

  .\32xl\:drop-shadow-sm{
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0,0,0,0.05)) !important;
  }

  .\32xl\:drop-shadow{
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06)) !important;
  }

  .\32xl\:drop-shadow-md{
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06)) !important;
  }

  .\32xl\:drop-shadow-lg{
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1)) !important;
  }

  .\32xl\:drop-shadow-xl{
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08)) !important;
  }

  .\32xl\:drop-shadow-2xl{
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15)) !important;
  }

  .\32xl\:drop-shadow-none{
    --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  }

  .\32xl\:grayscale-0{
    --tw-grayscale: grayscale(0) !important;
  }

  .\32xl\:grayscale{
    --tw-grayscale: grayscale(100%) !important;
  }

  .\32xl\:hue-rotate-0{
    --tw-hue-rotate: hue-rotate(0deg) !important;
  }

  .\32xl\:hue-rotate-15{
    --tw-hue-rotate: hue-rotate(15deg) !important;
  }

  .\32xl\:hue-rotate-30{
    --tw-hue-rotate: hue-rotate(30deg) !important;
  }

  .\32xl\:hue-rotate-60{
    --tw-hue-rotate: hue-rotate(60deg) !important;
  }

  .\32xl\:hue-rotate-90{
    --tw-hue-rotate: hue-rotate(90deg) !important;
  }

  .\32xl\:hue-rotate-180{
    --tw-hue-rotate: hue-rotate(180deg) !important;
  }

  .\32xl\:-hue-rotate-180{
    --tw-hue-rotate: hue-rotate(-180deg) !important;
  }

  .\32xl\:-hue-rotate-90{
    --tw-hue-rotate: hue-rotate(-90deg) !important;
  }

  .\32xl\:-hue-rotate-60{
    --tw-hue-rotate: hue-rotate(-60deg) !important;
  }

  .\32xl\:-hue-rotate-30{
    --tw-hue-rotate: hue-rotate(-30deg) !important;
  }

  .\32xl\:-hue-rotate-15{
    --tw-hue-rotate: hue-rotate(-15deg) !important;
  }

  .\32xl\:invert-0{
    --tw-invert: invert(0) !important;
  }

  .\32xl\:invert{
    --tw-invert: invert(100%) !important;
  }

  .\32xl\:saturate-0{
    --tw-saturate: saturate(0) !important;
  }

  .\32xl\:saturate-50{
    --tw-saturate: saturate(.5) !important;
  }

  .\32xl\:saturate-100{
    --tw-saturate: saturate(1) !important;
  }

  .\32xl\:saturate-150{
    --tw-saturate: saturate(1.5) !important;
  }

  .\32xl\:saturate-200{
    --tw-saturate: saturate(2) !important;
  }

  .\32xl\:sepia-0{
    --tw-sepia: sepia(0) !important;
  }

  .\32xl\:sepia{
    --tw-sepia: sepia(100%) !important;
  }

  .\32xl\:backdrop-filter{
    --tw-backdrop-blur: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-brightness: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-contrast: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-grayscale: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-hue-rotate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-invert: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-opacity: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-saturate: var(--tw-empty,/*!*/ /*!*/) !important;
    --tw-backdrop-sepia: var(--tw-empty,/*!*/ /*!*/) !important;
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
  }

  .\32xl\:backdrop-filter-none{
    -webkit-backdrop-filter: none !important;
            backdrop-filter: none !important;
  }

  .\32xl\:backdrop-blur-0{
    --tw-backdrop-blur: blur(0) !important;
  }

  .\32xl\:backdrop-blur-none{
    --tw-backdrop-blur: blur(0) !important;
  }

  .\32xl\:backdrop-blur-sm{
    --tw-backdrop-blur: blur(4px) !important;
  }

  .\32xl\:backdrop-blur{
    --tw-backdrop-blur: blur(8px) !important;
  }

  .\32xl\:backdrop-blur-md{
    --tw-backdrop-blur: blur(12px) !important;
  }

  .\32xl\:backdrop-blur-lg{
    --tw-backdrop-blur: blur(16px) !important;
  }

  .\32xl\:backdrop-blur-xl{
    --tw-backdrop-blur: blur(24px) !important;
  }

  .\32xl\:backdrop-blur-2xl{
    --tw-backdrop-blur: blur(40px) !important;
  }

  .\32xl\:backdrop-blur-3xl{
    --tw-backdrop-blur: blur(64px) !important;
  }

  .\32xl\:backdrop-brightness-0{
    --tw-backdrop-brightness: brightness(0) !important;
  }

  .\32xl\:backdrop-brightness-50{
    --tw-backdrop-brightness: brightness(.5) !important;
  }

  .\32xl\:backdrop-brightness-75{
    --tw-backdrop-brightness: brightness(.75) !important;
  }

  .\32xl\:backdrop-brightness-90{
    --tw-backdrop-brightness: brightness(.9) !important;
  }

  .\32xl\:backdrop-brightness-95{
    --tw-backdrop-brightness: brightness(.95) !important;
  }

  .\32xl\:backdrop-brightness-100{
    --tw-backdrop-brightness: brightness(1) !important;
  }

  .\32xl\:backdrop-brightness-105{
    --tw-backdrop-brightness: brightness(1.05) !important;
  }

  .\32xl\:backdrop-brightness-110{
    --tw-backdrop-brightness: brightness(1.1) !important;
  }

  .\32xl\:backdrop-brightness-125{
    --tw-backdrop-brightness: brightness(1.25) !important;
  }

  .\32xl\:backdrop-brightness-150{
    --tw-backdrop-brightness: brightness(1.5) !important;
  }

  .\32xl\:backdrop-brightness-200{
    --tw-backdrop-brightness: brightness(2) !important;
  }

  .\32xl\:backdrop-contrast-0{
    --tw-backdrop-contrast: contrast(0) !important;
  }

  .\32xl\:backdrop-contrast-50{
    --tw-backdrop-contrast: contrast(.5) !important;
  }

  .\32xl\:backdrop-contrast-75{
    --tw-backdrop-contrast: contrast(.75) !important;
  }

  .\32xl\:backdrop-contrast-100{
    --tw-backdrop-contrast: contrast(1) !important;
  }

  .\32xl\:backdrop-contrast-125{
    --tw-backdrop-contrast: contrast(1.25) !important;
  }

  .\32xl\:backdrop-contrast-150{
    --tw-backdrop-contrast: contrast(1.5) !important;
  }

  .\32xl\:backdrop-contrast-200{
    --tw-backdrop-contrast: contrast(2) !important;
  }

  .\32xl\:backdrop-grayscale-0{
    --tw-backdrop-grayscale: grayscale(0) !important;
  }

  .\32xl\:backdrop-grayscale{
    --tw-backdrop-grayscale: grayscale(100%) !important;
  }

  .\32xl\:backdrop-hue-rotate-0{
    --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  }

  .\32xl\:backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  }

  .\32xl\:backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  }

  .\32xl\:backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  }

  .\32xl\:backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  }

  .\32xl\:backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  }

  .\32xl\:-backdrop-hue-rotate-180{
    --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  }

  .\32xl\:-backdrop-hue-rotate-90{
    --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  }

  .\32xl\:-backdrop-hue-rotate-60{
    --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  }

  .\32xl\:-backdrop-hue-rotate-30{
    --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  }

  .\32xl\:-backdrop-hue-rotate-15{
    --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  }

  .\32xl\:backdrop-invert-0{
    --tw-backdrop-invert: invert(0) !important;
  }

  .\32xl\:backdrop-invert{
    --tw-backdrop-invert: invert(100%) !important;
  }

  .\32xl\:backdrop-opacity-0{
    --tw-backdrop-opacity: opacity(0) !important;
  }

  .\32xl\:backdrop-opacity-5{
    --tw-backdrop-opacity: opacity(0.05) !important;
  }

  .\32xl\:backdrop-opacity-10{
    --tw-backdrop-opacity: opacity(0.1) !important;
  }

  .\32xl\:backdrop-opacity-20{
    --tw-backdrop-opacity: opacity(0.2) !important;
  }

  .\32xl\:backdrop-opacity-25{
    --tw-backdrop-opacity: opacity(0.25) !important;
  }

  .\32xl\:backdrop-opacity-30{
    --tw-backdrop-opacity: opacity(0.3) !important;
  }

  .\32xl\:backdrop-opacity-40{
    --tw-backdrop-opacity: opacity(0.4) !important;
  }

  .\32xl\:backdrop-opacity-50{
    --tw-backdrop-opacity: opacity(0.5) !important;
  }

  .\32xl\:backdrop-opacity-60{
    --tw-backdrop-opacity: opacity(0.6) !important;
  }

  .\32xl\:backdrop-opacity-70{
    --tw-backdrop-opacity: opacity(0.7) !important;
  }

  .\32xl\:backdrop-opacity-75{
    --tw-backdrop-opacity: opacity(0.75) !important;
  }

  .\32xl\:backdrop-opacity-80{
    --tw-backdrop-opacity: opacity(0.8) !important;
  }

  .\32xl\:backdrop-opacity-90{
    --tw-backdrop-opacity: opacity(0.9) !important;
  }

  .\32xl\:backdrop-opacity-95{
    --tw-backdrop-opacity: opacity(0.95) !important;
  }

  .\32xl\:backdrop-opacity-100{
    --tw-backdrop-opacity: opacity(1) !important;
  }

  .\32xl\:backdrop-saturate-0{
    --tw-backdrop-saturate: saturate(0) !important;
  }

  .\32xl\:backdrop-saturate-50{
    --tw-backdrop-saturate: saturate(.5) !important;
  }

  .\32xl\:backdrop-saturate-100{
    --tw-backdrop-saturate: saturate(1) !important;
  }

  .\32xl\:backdrop-saturate-150{
    --tw-backdrop-saturate: saturate(1.5) !important;
  }

  .\32xl\:backdrop-saturate-200{
    --tw-backdrop-saturate: saturate(2) !important;
  }

  .\32xl\:backdrop-sepia-0{
    --tw-backdrop-sepia: sepia(0) !important;
  }

  .\32xl\:backdrop-sepia{
    --tw-backdrop-sepia: sepia(100%) !important;
  }

  .\32xl\:transition-none{
    transition-property: none !important;
  }

  .\32xl\:transition-all{
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:transition{
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-filter, -webkit-backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:transition-colors{
    transition-property: background-color, border-color, color, fill, stroke !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:transition-opacity{
    transition-property: opacity !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:transition-shadow{
    transition-property: box-shadow !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:transition-transform{
    transition-property: transform !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
  }

  .\32xl\:delay-75{
    transition-delay: 75ms !important;
  }

  .\32xl\:delay-100{
    transition-delay: 100ms !important;
  }

  .\32xl\:delay-150{
    transition-delay: 150ms !important;
  }

  .\32xl\:delay-200{
    transition-delay: 200ms !important;
  }

  .\32xl\:delay-300{
    transition-delay: 300ms !important;
  }

  .\32xl\:delay-500{
    transition-delay: 500ms !important;
  }

  .\32xl\:delay-700{
    transition-delay: 700ms !important;
  }

  .\32xl\:delay-1000{
    transition-delay: 1000ms !important;
  }

  .\32xl\:duration-75{
    transition-duration: 75ms !important;
  }

  .\32xl\:duration-100{
    transition-duration: 100ms !important;
  }

  .\32xl\:duration-150{
    transition-duration: 150ms !important;
  }

  .\32xl\:duration-200{
    transition-duration: 200ms !important;
  }

  .\32xl\:duration-300{
    transition-duration: 300ms !important;
  }

  .\32xl\:duration-500{
    transition-duration: 500ms !important;
  }

  .\32xl\:duration-700{
    transition-duration: 700ms !important;
  }

  .\32xl\:duration-1000{
    transition-duration: 1000ms !important;
  }

  .\32xl\:ease-linear{
    transition-timing-function: linear !important;
  }

  .\32xl\:ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
  }

  .\32xl\:ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  }

  .\32xl\:ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  }
}

