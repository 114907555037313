.modalContainer {
  display: flex;
  padding: 4rem 5rem;
  flex-direction: column;
}

.headerText {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  /* or 135% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.buttonGroup {
  display: flex;
  align-self: center;
}

.nextTipButton {
  border: none;
  margin-right: 1rem;
  border-radius: 5px;
  padding: 1rem 3rem;
  color: #8bc334;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  background-color: #f1f8e7;
  outline: none;
}

.nextTipButton:hover {
  color: #ffffff;
  background-color: #8bc334;
}

.closeButton {
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 0 !important;
  margin-right: 0 !important;
  color: #f56666 !important;
  background-color: #feeded !important;
}

.closeButton:hover {
  color: #ffffff !important;
  background-color: #f56666 !important;
}
