.mainContainer {
  height: 50px;
  width: 100%;
  background: #8f5fe8;
}

.container {
  width: 1500px;
}

.infoIcon {
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;

  color: #ffffff;
}

.infoText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.logoutButton {
  border: none;
  cursor: pointer;
  color: #ffffff !important;
  width: 102px !important;
  height: 36px !important;
  font-size: 17px !important;

  border-radius: 5px;
  text-decoration: none;
  background: #a57fed;
  outline: none;
}

.closeButton {
  border: none;
  cursor: pointer;
  color: #ffffff !important;
  width: 86px !important;
  height: 36px !important;
  font-size: 17px !important;

  border-radius: 5px;
  text-decoration: none;
  background: #ee6565;
  outline: none;
}

.buttonText {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.buttonIcon {
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
}
