.inputContainer {
  width: 50%;
  border: 1px solid #dedddd;
  box-sizing: border-box;
  border-radius: 3px;
}

.inputSearch {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  border: none !important;

  color: #222222;
}

.inputSearch::placeholder {
  color: #b0b0b0;
}

.searchContainer {
  width: 48px;
  height: 48px;
  background: #8ac04a;
  border-radius: 2px;
}

.searchContainer:hover {
	opacity: .8;
}

.search {
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.cancelContainer {
  width: 48px;
  height: 48px;
  background: rgb(34, 34, 34, 0.05);
  border-radius: 9999px;
}

.cancelContainer:hover {
	color: #ffffff;
	background: #8ac04a;
}

.cancel {
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #222222;
}
